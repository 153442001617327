import { pick } from 'lodash';

import { UpdatePublisherInput } from '__ssp-generated__/globalTypes';

export const formatStagedPublisherSettingsData = (stagedData: any, id: number, businessProfileId: string): any => {
  const formattedObject: any = {
    id: id.toString(10),
    businessProfileId,
    defaultFilledIconId: stagedData.defaultFilledIconId,
    defaultNumSnaps: stagedData.defaultNumSnaps,
    description: stagedData.description,
    dynamicEditionAutoCreateStoryTimeUTC: stagedData.dynamicEditionAutoCreateStoryTimeUTC,
    horizontalIconId: stagedData.horizontalIconId,
    primaryColor: stagedData.primaryColor,
    secondaryColor: stagedData.secondaryColor,
    squareHeroImageBitmojiTemplateId: stagedData.squareHeroImageBitmojiTemplateId,
    squareHeroImageId: stagedData.squareHeroImageId,
    tags: {
      SCC: stagedData.tags.SCC,
      WIKI: stagedData.tags.WIKI,
      MANUAL: stagedData.tags.MANUAL,
    },
    targetSnapLengthMs: stagedData.targetSnapLengthMs,
    tileLogos: [],
    websiteUrl: stagedData.websiteUrl,
  } as UpdatePublisherInput;
  stagedData.tileLogos.forEach((item: { mediaId: string; color: string }) => {
    const tileLogoInput = { mediaId: item.mediaId, color: item.color };
    formattedObject.tileLogos.push(tileLogoInput);
  });

  return formattedObject;
};

export const formatStagedSuperAdminSettingsData = (
  stagedData: any,
  id: number,
  businessProfileId: String
): UpdatePublisherInput => {
  const formattedObject = pick(
    {
      id,
      businessProfileId,
      ...stagedData,
      contentAccessLists: {
        DISCOVER_FEED: {
          isAllowlist: stagedData.contentAccessLists.DISCOVER_FEED.isAllowlist,
          list: stagedData.contentAccessLists.DISCOVER_FEED.list,
        },
        SEARCH: {
          isAllowlist: stagedData.contentAccessLists.SEARCH.isAllowlist,
          list: stagedData.contentAccessLists.SEARCH.list,
        },
        SNAPCODE: {
          isAllowlist: stagedData.contentAccessLists.SNAPCODE.isAllowlist,
          list: stagedData.contentAccessLists.SNAPCODE.list,
        },
        SHARE: {
          isAllowlist: stagedData.contentAccessLists.SHARE.isAllowlist,
          list: stagedData.contentAccessLists.SHARE.list,
        },
      },
    },
    [
      'adSetting',
      'adsPreRollEnabled',
      'advancedAdsEnabled',
      'advancedAdsLatestFirstSlot',
      'advancedAdsMaxSlots',
      'advancedAdsMinSlots',
      'advancedAdsMinSpacing',
      'advertisingEnabled',
      'ageGate',
      'allowlistCountry',
      'allowlistDistributionCountry',
      'allowlistLanguage',
      'articleCSS',
      'audioClassification',
      'blocklistCountry',
      'blocklistDistributionCountry',
      'blocklistLanguage',
      'businessProfileId',
      'contentAccessLists',
      'defaultAdSlots',
      'defaultAdSlotsMillis',
      'defaultShareOption',
      'defaultSubtitlesLanguage',
      'dynamicAdsEnabled',
      'dynamicEditionAutoCreateStoryTimeUTC',
      'editorialApproval',
      'editorialRationale',
      'formalName',
      'geofenceIds',
      'homeCountry',
      'id',
      'isComScoreIntegrationEnabled',
      'isEmployeeOnly',
      'isOurStories',
      'localContent',
      'managerEmail',
      'managerName',
      'moderationLevel',
      'mutablePublisherName',
      'nicknames',
      'orgId',
      'permitAutoPublishFromFeed',
      'primaryLanguage',
      'publisherFeatures',
      'publishingApprovals',
      'publishingEnabled',
      'regions',
      'rollingNewsEnabled',
      'searchable',
      'subscribable',
      'tier',
      'topsnapLimit',
      'type',
      'urlSafeFormalName',
      'videoModeEnabled',
      'websiteUrl',
      'webviewLimit',
    ]
  ) as UpdatePublisherInput;

  return formattedObject;
};
