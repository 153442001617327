import type { ReactNode } from 'react';
import React from 'react';

import { AnalyticsAttachmentType } from 'config/constants';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { printDuration } from 'utils/numberFormatter';

import AnalyticsChapterPreview from 'views/analytics/components/AnalyticsChapterPreview/AnalyticsChapterPreview';
import AnalyticsV2ImagePreview from 'views/analytics/components/AnalyticsV2ImagePreview/AnalyticsV2ImagePreview';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { Spinner, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import DeletedStoryTopsnapPreview from 'views/publisherStoryEditor/containers/DeletedSnapCarouselItem/DeletedStoryTopsnapPreview';
import StoryTopsnapPreview from 'views/publisherStoryEditor/containers/StoryTopsnapPreview/StoryTopsnapPreview';

import style from './AnalyticsTopsnapPreview.scss';

import type { PreviewFrame, ProcessedSnapMetricResults, ProcessedSnapsMetrics } from 'types/analytics';
import type { SnapId } from 'types/common';
import { ChapterSummary } from 'types/singleAssetStoryEditor';
import type { Snap } from 'types/snaps';
import { CameoSnapModel, SnapRelationship } from 'types/snaps';

type Props = {
  editionId: number;
  processedSnaps: ProcessedSnapsMetrics | AnalyticsV2Snap[];
  previewFrames?: PreviewFrame[];
  snaps?: {
    [k in SnapId | string]: Snap | AnalyticsV2Snap;
  };
  chapters?: ChapterSummary[];
  isAnalyticsV2Enabled: boolean;
  isImagePreviewLoaded?: boolean;
};

export type SnapPreviewMetadata = {
  snapId: string;
  title: string;
  mediaUrl: string;
  seekPoint: string;
  duration: number;
  chapterId?: string;
  isContentDeleted?: boolean;
};

export type AnalyticsV2Snap = {
  id: string | undefined | null;
  remoteUrl?: string;
  shareOption?: string;
  createdAt?: string;
  assetId?: string;
  videoAssetId?: string;
  sourceMediaId?: string;
  overlayImageAssetId?: string;
  creativeId?: string | null;
  type?: string;
  isContentDeleted?: boolean;
  cameoSnapModel?: CameoSnapModel | null;
  relatedSnaps?: {
    [SnapRelationship.TOP]: AnalyticsV2Snap | null;
    [SnapRelationship.BOTTOM]: AnalyticsV2Snap | null;
  };
  longformMediaType?: string | undefined | null;
  mediaName?: string | undefined | null;
  snapIndex?: number | undefined | null;
  segmentId?: string | undefined | null;
  snapId: string | undefined | null;
  runtime: number | undefined | null;
  mediaUrl?: string | undefined | null;
  seekPoint?: string | undefined | null;
  duration?: number | undefined | null;
  chapterId?: string | undefined | null;
};

type SegmentEntry = {
  key: string;
  tabKey: string;
  title: string | number | null;
  attachmentType: string;
  notes: string | undefined | null;
  snap: Snap | AnalyticsV2Snap | undefined | null;
  chapter?: ChapterSummary;
  startFrame?: number;
  mediaUrl?: string;
  seekPoint?: string;
  duration?: number;
  logoUrl?: string;
  tileTitle?: string;
};

export class AnalyticsTopsnapPreview extends React.PureComponent<Props> {
  getSnapTitle = (
    processedSnap: ProcessedSnapMetricResults | AnalyticsV2Snap,
    chapter: ChapterSummary | undefined,
    index: number
  ): string | number | null => {
    if (processedSnap.longformMediaType === AnalyticsAttachmentType.SUBSCRIBE) {
      return getMessageFromId('story-end-snap-header') || '';
    }
    if (chapter) {
      return printDuration(chapter.startTimeMs);
    }
    return getMessageFromId('snap-index-in-edition', { index });
  };

  getSnapMetadata = (processedSnap: ProcessedSnapMetricResults | AnalyticsV2Snap, index: number): SegmentEntry => {
    const attachmentType = processedSnap.longformMediaType || '';
    const tabKey = `AnalyticsEditionView-snap-${processedSnap.snapIndex || index + 1}`;
    const notes = processedSnap.mediaName;
    const mediaUrl = processedSnap.mediaUrl || '';
    const seekPoint = processedSnap.seekPoint || '00:00:00';
    const duration = processedSnap.duration || 0;
    const key = `snap-${processedSnap.snapIndex || index + 1}`;
    const snap = this.props.snaps ? this.props.snaps[processedSnap.snapId!] : null;
    const chapter = this.props.chapters ? this.props.chapters[index] : undefined;
    const title = this.getSnapTitle(processedSnap, chapter, processedSnap.snapIndex || index + 1);
    return {
      attachmentType,
      tabKey,
      key,
      notes,
      snap,
      chapter,
      title,
      mediaUrl,
      seekPoint,
      duration,
    };
  };

  getSegmentedSnaps = (processedSnaps: ProcessedSnapMetricResults[] | AnalyticsV2Snap[]) => {
    // Using named object as Typescript doesn't understand tuples in reduce.
    type Accumulator = { previousSegmentId: string; segmentedSnaps: SegmentEntry[][] };
    const NO_SEGMENT = 'NO_SEGMENT';

    return (processedSnaps as ProcessedSnapMetricResults[]).reduce(
      (
        { previousSegmentId, segmentedSnaps }: Accumulator,
        snap: ProcessedSnapMetricResults | AnalyticsV2Snap,
        i: number
      ) => {
        const { segmentId } = snap;
        // Start a new segment if previousSegmentId differs.
        if (previousSegmentId !== segmentId || segmentId === null) {
          segmentedSnaps.push([]);
        }
        // Add snap to the last segment.
        segmentedSnaps[segmentedSnaps.length - 1]?.push(this.getSnapMetadata(snap, i));
        return {
          previousSegmentId: segmentId || NO_SEGMENT,
          segmentedSnaps,
        };
      },
      { previousSegmentId: NO_SEGMENT, segmentedSnaps: [] }
    ).segmentedSnaps;
  };

  renderSnap = (segment: SegmentEntry) => {
    if (segment.mediaUrl && this.props.isAnalyticsV2Enabled) {
      return (
        <AnalyticsV2ImagePreview
          mediaUrl={segment.mediaUrl}
          logoUrl={segment.logoUrl}
          title={segment.tileTitle}
          seekPoint={segment.seekPoint}
        />
      );
    }
    if (!segment.snap && segment.chapter) {
      return <AnalyticsChapterPreview storyId={this.props.editionId} chapterId={segment.chapter.id} />;
    }

    if (!segment.snap) {
      return (
        <div className={style.spinnerContainer}>
          <Spinner className={(style as any).spinner} loading size={SpinnerSizes.LARGE} />
        </div>
      );
    }

    if (segment.snap.isContentDeleted) {
      return <DeletedStoryTopsnapPreview />;
    }
    return (
      <StoryTopsnapPreview
        snap={segment.snap}
        editionId={this.props.editionId}
        isAnySnapBeingDragged={false}
        muted
        data-test="storySnapListItem.preview"
      />
    );
  };

  renderSnapContainer = (segmentEntry: SegmentEntry) => {
    return (
      <div data-test={segmentEntry.tabKey}>
        <div className={style.snapContainer}>{this.renderSnap(segmentEntry)}</div>
        <div className={style.snapHeadline}>{this.renderSnapTitle(segmentEntry)}</div>
      </div>
    );
  };

  renderSegment = (segment: SegmentEntry[], index: number) => {
    return (
      <div key={index} className={style.segmentContainer}>
        {segment.map((segmentEntry, segmentIndex) => {
          let clonedSegmentEntry: SegmentEntry = { ...segmentEntry };
          if (this.props.isAnalyticsV2Enabled && this.props?.previewFrames?.length) {
            const mediaUrl = this.props?.previewFrames[index]?.imgSrc;
            const logoUrl = this.props?.previewFrames[index]?.publisherLogoSrc;
            const tileTitle = this.props?.previewFrames[index]?.title;
            clonedSegmentEntry = { ...clonedSegmentEntry, ...{ mediaUrl, src: mediaUrl, logoUrl, tileTitle } };
          }
          return (
            <div key={clonedSegmentEntry.key} className={style.segmentSnapContainer}>
              <div data-test={clonedSegmentEntry.tabKey}>{this.renderSnapContainer(clonedSegmentEntry)}</div>
            </div>
          );
        })}
      </div>
    );
  };

  renderSnapTitle = (segment: SegmentEntry) => {
    if (segment.notes) {
      return (
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.TOP}
          title={segment.notes}
          id={`analytics-topsnap-preview-${segment.key}`}
        >
          <span className={style.snapTitle}>{segment.title}</span>
        </SDSTooltip>
      );
    }
    return <span className={style.snapTitle}>{segment.title}</span>;
  };

  render(): ReactNode {
    if (!this.props?.isImagePreviewLoaded && this.props?.isAnalyticsV2Enabled) {
      return this.renderImagePreview();
    }
    const { processedSnaps } = this.props;
    if (!processedSnaps || !processedSnaps.length) {
      return null;
    }
    const segmentedSnaps = this.getSegmentedSnaps(processedSnaps);
    return segmentedSnaps.map((segment: SegmentEntry[], index: number) => {
      return this.renderSegment(segment, index);
    });
  }

  private renderImagePreview() {
    return (
      <div className={style.imagePreviewLoader}>
        <Spinner className={(style as any).spinner} loading size={SpinnerSizes.LARGE} />
      </div>
    );
  }
}

export default AnalyticsTopsnapPreview;
