import { get } from 'lodash';
import React from 'react';

import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import { UploadPurpose } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isColorLight } from 'utils/media/colorUtils';

import SDSPanel from 'views/common/components/SDSPanel/SDSPanel';
import PublisherColorRow from 'views/onboarding/components/PublisherColorRow/PublisherColorRow';
import PublisherDetailImageRow from 'views/onboarding/components/PublisherDetailImageRow/PublisherDetailImageRow';
import PublisherLogoPreview from 'views/onboarding/components/PublisherLogoPreview/PublisherLogoPreview';

import style from './SimpleStylingEditor.scss';

import { MediaLoading, PropertyKeys } from 'types/publisherSettings';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';

type ExternalProps = {
  publisherId: PublisherID;
  disabled?: boolean;
  onChange: (property: string) => (value: string | number | null | undefined) => Promise<void>;
  isLoading: MediaLoading;
};

type StateProps = {
  data: {} | undefined | null;
};

export const mapStateToProps = (state: RootState, props: ExternalProps): StateProps => {
  return {
    data: stagesSelectors.getData(state)(props.publisherId),
  };
};

const mapDispatchToProps = {
  updateProperties: stagesActions.updateProperties,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;

export class SimpleStylingEditor extends React.Component<Props> {
  handleChange = (property: string) => (value: unknown) => {
    this.props.updateProperties(this.props.publisherId, { [property]: value });
  };

  handlePrimaryColorChange = (value: string) => {
    this.handleChange(PropertyKeys.PRIMARY_COLOR)(value);

    // Compute how bright the color is. If it's quite light, use black as the secondary color. If it's darker, use white as
    // the secondary color. This makes text (which uses the secondary color) easier to read when on top of the primary color
    const secondaryColor = isColorLight(value) ? '#000000' : '#FFFFFF';
    this.handleChange(PropertyKeys.SECONDARY_COLOR)(secondaryColor);
  };

  render() {
    return (
      <SDSPanel.Row>
        <SDSPanel.Column>
          <div className={style.logosUploadRow} data-test="simpleStylingEditor.logoUploadRow">
            <PublisherDetailImageRow
              title={getMessageFromId('publisher-details-square-icon')}
              onChange={this.props.onChange(PropertyKeys.SQUARE_ICON_BLOB)}
              key={PropertyKeys.SQUARE_ICON_BLOB}
              uploadType={UploadPurpose.SQUARE_ICON}
              defaultValue={get(this.props.data, PropertyKeys.SQUARE_ICON_BLOB) || ''}
              disabled={this.props.disabled}
              isLoading={this.props.isLoading.squareIcon}
            />
            <PublisherDetailImageRow
              title={getMessageFromId('publisher-details-horizontal-icon')}
              onChange={this.props.onChange(PropertyKeys.HORIZONTAL_ICON_BLOB)}
              key={PropertyKeys.HORIZONTAL_ICON_BLOB}
              uploadType={UploadPurpose.TILE_LOGO}
              defaultValue={get(this.props.data, PropertyKeys.HORIZONTAL_ICON_BLOB) || ''}
              disabled={this.props.disabled}
              isLoading={this.props.isLoading.horizontalIcon}
            />
          </div>
          <div className={style.colorPicker} data-test="simpleStylingEditor.colorPicker">
            <PublisherColorRow
              title={getMessageFromId('publisher-details-publishing-color')}
              info={getMessageFromId('publisher-details-publishing-color-description')}
              onChange={this.handlePrimaryColorChange}
              key={PropertyKeys.PRIMARY_COLOR}
              defaultValue={get(this.props.data, PropertyKeys.PRIMARY_COLOR) || ''}
              disabled={this.props.disabled}
            />
          </div>
        </SDSPanel.Column>
        <SDSPanel.Column>
          <PublisherLogoPreview
            title={getMessageFromId('publisher-details-preview')}
            horizontalLogo={get(this.props.data, PropertyKeys.HORIZONTAL_ICON_BLOB)}
            squareLogo={get(this.props.data, PropertyKeys.SQUARE_ICON_BLOB)}
            color={get(this.props.data, PropertyKeys.PRIMARY_COLOR)}
            secondaryColor={get(this.props.data, PropertyKeys.SECONDARY_COLOR)}
            name={get(this.props.data, PropertyKeys.FORMAL_NAME) || ''}
            description={get(this.props.data, PropertyKeys.DESCRIPTION) || ''}
            isLoading={this.props.isLoading}
          />
        </SDSPanel.Column>
      </SDSPanel.Row>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SimpleStylingEditor);
