/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdSetting {
  NON_SKIPPABLE = "NON_SKIPPABLE",
  SKIPPABLE = "SKIPPABLE",
  UNSET = "UNSET",
}

export enum AudioClassification {
  DIALOGUE_HEAVY = "DIALOGUE_HEAVY",
  DIALOGUE_IMPACT_BURN = "DIALOGUE_IMPACT_BURN",
  DIALOGUE_SUBTITLE_BURN = "DIALOGUE_SUBTITLE_BURN",
  NORMAL_DIALOGUE = "NORMAL_DIALOGUE",
  NO_DIALOGUE = "NO_DIALOGUE",
  UNSET = "UNSET",
}

export enum ContentAgeGateLevel {
  EXPLICIT = "EXPLICIT",
  UNRESTRICTED = "UNRESTRICTED",
  UNSET = "UNSET",
}

export enum CountryCode {
  AB = "AB",
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  UNSET = "UNSET",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum DiscoverRegion {
  ALL = "ALL",
  AU = "AU",
  CA = "CA",
  DEV_TEST = "DEV_TEST",
  INTERNATIONAL = "INTERNATIONAL",
  QA = "QA",
  SUB = "SUB",
  UK = "UK",
  UNSET = "UNSET",
  US = "US",
}

export enum EditionCountry {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  ALL = "ALL",
  AM = "AM",
  AO = "AO",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  EU = "EU",
  FI = "FI",
  FJ = "FJ",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GT = "GT",
  GU = "GU",
  GY = "GY",
  HK = "HK",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KM = "KM",
  KN = "KN",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SC = "SC",
  SE = "SE",
  SG = "SG",
  SI = "SI",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UK = "UK",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum EditorialApproval {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  REQUESTED = "REQUESTED",
  TO_BE_REVIEWED = "TO_BE_REVIEWED",
}

export enum LogoDisplay {
  BOTTOM = "BOTTOM",
  OFF = "OFF",
  UNSET = "UNSET",
}

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum ModerationLevel {
  NEWS = "NEWS",
  STANDARD = "STANDARD",
  UNSET = "UNSET",
}

export enum ModerationRejectionReason {
  ABUSIVE_LANGUAGE = "ABUSIVE_LANGUAGE",
  ABUSIVE_MENTION_CHECKER = "ABUSIVE_MENTION_CHECKER",
  ALCOHOL = "ALCOHOL",
  ANIMAL_VIOLENCE = "ANIMAL_VIOLENCE",
  APP_VERSION_MISSING_MULTI_SNAP_META = "APP_VERSION_MISSING_MULTI_SNAP_META",
  BAD_WORD = "BAD_WORD",
  BODILY_FLUIDS = "BODILY_FLUIDS",
  BODY_IMAGERY = "BODY_IMAGERY",
  CLICKBAIT = "CLICKBAIT",
  COMMERCIAL_PROMOTION_AND_SOLICITATION = "COMMERCIAL_PROMOTION_AND_SOLICITATION",
  COMMERCIAL_SALES_AND_SOLICITATIONS = "COMMERCIAL_SALES_AND_SOLICITATIONS",
  COMMERCIAL_SPAM = "COMMERCIAL_SPAM",
  CONTAIN_LOGO = "CONTAIN_LOGO",
  CONTAIN_SNAPCHAT_LOGO = "CONTAIN_SNAPCHAT_LOGO",
  CONTAIN_URL = "CONTAIN_URL",
  CREATIVE_TOOLS_AND_QUALITY = "CREATIVE_TOOLS_AND_QUALITY",
  CREATOR_SCORE_CHECKER = "CREATOR_SCORE_CHECKER",
  DANGEROUS_ACTIVITIES = "DANGEROUS_ACTIVITIES",
  DANGEROUS_DRIVING = "DANGEROUS_DRIVING",
  DANGEROUS_OR_HARMFUL_ACTIVITIES = "DANGEROUS_OR_HARMFUL_ACTIVITIES",
  DRUGS = "DRUGS",
  EDITORIAL_QUALITY = "EDITORIAL_QUALITY",
  FRAUD_AND_SPAM = "FRAUD_AND_SPAM",
  HARASSMENT = "HARASSMENT",
  HARMFUL_FALSE_INFORMATION = "HARMFUL_FALSE_INFORMATION",
  HATE_SPEECH = "HATE_SPEECH",
  HIVE_GENERAL_NSFW = "HIVE_GENERAL_NSFW",
  HIVE_GUN_IN_HAND = "HIVE_GUN_IN_HAND",
  HIVE_NSFW_CHECKER = "HIVE_NSFW_CHECKER",
  HIVE_NUDITY = "HIVE_NUDITY",
  HIVE_OTHER_BLOOD = "HIVE_OTHER_BLOOD",
  HIVE_SELF_HARM = "HIVE_SELF_HARM",
  HIVE_SEXUAL_ACTIVITY = "HIVE_SEXUAL_ACTIVITY",
  HIVE_SEXUAL_INTENT = "HIVE_SEXUAL_INTENT",
  HIVE_SMOKING = "HIVE_SMOKING",
  HIVE_VERY_BLOODY = "HIVE_VERY_BLOODY",
  HUMAN_OR_ANIMAL_VIOLENCE = "HUMAN_OR_ANIMAL_VIOLENCE",
  HUMAN_VIOLENCE = "HUMAN_VIOLENCE",
  ILLEGAL_GOODS_AND_ACTIVITIES = "ILLEGAL_GOODS_AND_ACTIVITIES",
  IMAGE = "IMAGE",
  INTELLECTUAL_PROPERTY = "INTELLECTUAL_PROPERTY",
  INVASION_OF_PRIVACY = "INVASION_OF_PRIVACY",
  LOW_CONTENT_QUALITY = "LOW_CONTENT_QUALITY",
  MARIJUANA = "MARIJUANA",
  MEME = "MEME",
  MINORS_UNDER_13_YEARS_OLD = "MINORS_UNDER_13_YEARS_OLD",
  ML_REJECT = "ML_REJECT",
  NON_VERTICAL = "NON_VERTICAL",
  NO_MEDIA = "NO_MEDIA",
  NSFW = "NSFW",
  PIE_CHECKS = "PIE_CHECKS",
  POLITICAL_CONTENT = "POLITICAL_CONTENT",
  PRIVACY_INVASION = "PRIVACY_INVASION",
  PROFANITY = "PROFANITY",
  PUBLIC_PROFILE_MODERATION_REJECTED = "PUBLIC_PROFILE_MODERATION_REJECTED",
  PUBLIC_PROFILE_MODERATION_RESTRICTED_DISTRIBUTION = "PUBLIC_PROFILE_MODERATION_RESTRICTED_DISTRIBUTION",
  PUBLIC_PROFILE_MODERATION_RESTRICTED_DISTRIBUTION_NON_ALD = "PUBLIC_PROFILE_MODERATION_RESTRICTED_DISTRIBUTION_NON_ALD",
  QUALITY_ASPECT_RATIO = "QUALITY_ASPECT_RATIO",
  QUALITY_COLOR_CROPPABLE_RATIO = "QUALITY_COLOR_CROPPABLE_RATIO",
  QUALITY_CROPPABLE_RATIO = "QUALITY_CROPPABLE_RATIO",
  QUALITY_DRIVING = "QUALITY_DRIVING",
  QUALITY_FPS = "QUALITY_FPS",
  QUALITY_HORIZONTAL = "QUALITY_HORIZONTAL",
  QUALITY_LUMINANCE = "QUALITY_LUMINANCE",
  QUALITY_MOTION = "QUALITY_MOTION",
  QUALITY_RISK_ACCOUNT = "QUALITY_RISK_ACCOUNT",
  QUALITY_SHARPNESS = "QUALITY_SHARPNESS",
  REASON_UNSET = "REASON_UNSET",
  REGULATED_GOODS_AND_ACTIVITIES = "REGULATED_GOODS_AND_ACTIVITIES",
  RELATIONSHIPS_INTIMACY = "RELATIONSHIPS_INTIMACY",
  RL_ATTACHMENT = "RL_ATTACHMENT",
  SELF_HARM_AND_SUICIDE = "SELF_HARM_AND_SUICIDE",
  SELF_HARM_SUICIDE = "SELF_HARM_SUICIDE",
  SEXUALIZED_OBJECTS = "SEXUALIZED_OBJECTS",
  SEXUAL_CAPTION = "SEXUAL_CAPTION",
  SEXUAL_CONTENT_REASON = "SEXUAL_CONTENT_REASON",
  SEXUAL_SOLICITATION = "SEXUAL_SOLICITATION",
  SEX_ACTS = "SEX_ACTS",
  SIMILAR_CONTENT = "SIMILAR_CONTENT",
  SNAP_DELETION = "SNAP_DELETION",
  TERRORISM_AND_VIOLENT_EXTREMISM_CONTENT = "TERRORISM_AND_VIOLENT_EXTREMISM_CONTENT",
  TERRORIST = "TERRORIST",
  TEXT_MODERATION = "TEXT_MODERATION",
  TEXT_MODERATION_OCR = "TEXT_MODERATION_OCR",
  THREATS = "THREATS",
  TIMESFORMER = "TIMESFORMER",
  TOBACCO = "TOBACCO",
  UNSAFE_URL = "UNSAFE_URL",
  URL_ATTACHMENT = "URL_ATTACHMENT",
  URL_CHECKER = "URL_CHECKER",
  USER_AGE_GATING = "USER_AGE_GATING",
  USER_REPORT_AGE_GATING = "USER_REPORT_AGE_GATING",
  VIDEO_LONG_LENGTH = "VIDEO_LONG_LENGTH",
  VIDEO_NO_SOUND = "VIDEO_NO_SOUND",
  VIDEO_SHORT_LENGTH = "VIDEO_SHORT_LENGTH",
  VIOLENT_OR_DISTURBING_CONTENT = "VIOLENT_OR_DISTURBING_CONTENT",
  WEAPONS = "WEAPONS",
}

export enum ModerationStatus {
  LIMITED_DISTRIBUTION = "LIMITED_DISTRIBUTION",
  LIVE = "LIVE",
  STATUS_UNSET = "STATUS_UNSET",
  SUBMITTED = "SUBMITTED",
}

export enum PublisherFeatures {
  DISCOVER_CHANNEL = "DISCOVER_CHANNEL",
  DISCOVER_CHANNEL_ELIGIBLE = "DISCOVER_CHANNEL_ELIGIBLE",
  EVERGREEN = "EVERGREEN",
  EXCLUSIVE_SHOW = "EXCLUSIVE_SHOW",
  FIXED_PAYMENTS = "FIXED_PAYMENTS",
  FRIENDS_CAROUSEL = "FRIENDS_CAROUSEL",
  INTERNAL_CURATOR = "INTERNAL_CURATOR",
  MADE_FOR_US = "MADE_FOR_US",
  ONBOARDING_WIZARD = "ONBOARDING_WIZARD",
  ORIGINAL_SHOW = "ORIGINAL_SHOW",
  PARTNER_CURATOR = "PARTNER_CURATOR",
  SUBSCRIBER_ONLY = "SUBSCRIBER_ONLY",
  TEAM_SNAPCHAT = "TEAM_SNAPCHAT",
  TIMELY = "TIMELY",
  UNKNOWN = "UNKNOWN",
  USE_BUNDLE = "USE_BUNDLE",
}

export enum PublisherFlags {
  ADDED_PUBLISHER_DETAILS = "ADDED_PUBLISHER_DETAILS",
  AUTO_ENABLED_PUBLISHING = "AUTO_ENABLED_PUBLISHING",
  NONE = "NONE",
  NOTIFICATION_EDITORIAL_APPROVAL = "NOTIFICATION_EDITORIAL_APPROVAL",
  NOTIFICATION_EDITORIAL_REJECTED = "NOTIFICATION_EDITORIAL_REJECTED",
  NOTIFICATION_PROFILE_COMPLETED = "NOTIFICATION_PROFILE_COMPLETED",
  NOTIFICATION_PROFILE_ENABLED = "NOTIFICATION_PROFILE_ENABLED",
  NOTIFICATION_REVIEW_REQUESTED = "NOTIFICATION_REVIEW_REQUESTED",
  ORGANISATION_ONBOARDING = "ORGANISATION_ONBOARDING",
  PROFILE_ONBOARDING = "PROFILE_ONBOARDING",
}

export enum PublisherType {
  NORMAL = "NORMAL",
  TEST = "TEST",
  UNSET = "UNSET",
}

export enum PublishingApproval {
  COMPLIANCE = "COMPLIANCE",
  PROGRAMMING = "PROGRAMMING",
  SPM = "SPM",
  UNSET = "UNSET",
}

export enum RoleType {
  ADMIN = "ADMIN",
  ANALYST = "ANALYST",
  BUSINESS_ACCOUNT_COLLABORATOR = "BUSINESS_ACCOUNT_COLLABORATOR",
  BUSINESS_ACCOUNT_DATA_ANALYST = "BUSINESS_ACCOUNT_DATA_ANALYST",
  BUSINESS_ACCOUNT_MANAGER = "BUSINESS_ACCOUNT_MANAGER",
  BUSINESS_ACCOUNT_MODERATED_STORY_CONTRIBUTOR = "BUSINESS_ACCOUNT_MODERATED_STORY_CONTRIBUTOR",
  BUSINESS_ACCOUNT_STORY_CONTRIBUTOR = "BUSINESS_ACCOUNT_STORY_CONTRIBUTOR",
  BUSINESS_ADMIN = "BUSINESS_ADMIN",
  CONTRIBUTOR = "CONTRIBUTOR",
  CURATOR = "CURATOR",
  CURATOR_READER = "CURATOR_READER",
  DEFAULT_NO_PERMISSION = "DEFAULT_NO_PERMISSION",
  MONETIZATION_ANALYST = "MONETIZATION_ANALYST",
  ORG_ADMIN = "ORG_ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  VIEWER = "VIEWER",
}

export enum ScheduledStoryStatus {
  ERROR = "ERROR",
  SCHEDULED = "SCHEDULED",
}

export enum ShareOption {
  NO_SHARE = "NO_SHARE",
  SHARE_LIVE = "SHARE_LIVE",
  SHARE_LIVE_ARCHIVED = "SHARE_LIVE_ARCHIVED",
}

export enum StorySnapType {
  OUR_STORY = "OUR_STORY",
  PUBLIC_STORY = "PUBLIC_STORY",
}

export enum StoryType {
  PUBLIC_STORY = "PUBLIC_STORY",
  SPOTLIGHT_STORY = "SPOTLIGHT_STORY",
}

export enum TierLevel {
  CURATION = "CURATION",
  DYNAMIC_EDITIONS = "DYNAMIC_EDITIONS",
  HAPPENING_NOW = "HAPPENING_NOW",
  PREMIUM = "PREMIUM",
  SHOW = "SHOW",
}

export interface AppealTileModerationsInput {
  readonly appeals: ReadonlyArray<TileModerationAppeal>;
  readonly storyId: any;
}

export interface ContentAccessListInput {
  readonly isAllowlist: boolean;
  readonly list: ReadonlyArray<string>;
}

/**
 * import "../../types/extensions/scalar.graphqls"
 * import "../../types/publisher/enums.graphqls"
 */
export interface ContentAccessListsInput {
  readonly DISCOVER_FEED: ContentAccessListInput;
  readonly SEARCH: ContentAccessListInput;
  readonly SHARE: ContentAccessListInput;
  readonly SNAPCODE: ContentAccessListInput;
}

/**
 * import "../schema.graphqls"
 * import "../types/creator/creator.graphqls"
 */
export interface CreatorRequest {
  readonly username: string;
}

export interface DeleteStoryDraftingSnapsInput {
  readonly hostUserId: string;
  readonly snapIds: ReadonlyArray<string>;
}

export interface DeleteStorySnapInput {
  readonly hostUserId: string;
  readonly snapIds: ReadonlyArray<string>;
  readonly storyId?: string | null;
  readonly type: StorySnapType;
}

export interface ExternalUpdatePublisherInput {
  readonly businessProfileId?: string | null;
  readonly defaultFilledIconId?: string | null;
  readonly defaultNumSnaps?: number | null;
  readonly description?: string | null;
  readonly dynamicEditionAutoCreateStoryTimeUTC?: HourMinInput | null;
  readonly horizontalIcon?: string | null;
  readonly horizontalIconId?: string | null;
  readonly id: string;
  readonly primaryColor?: string | null;
  readonly primaryColorRGB?: ReadonlyArray<number> | null;
  readonly secondaryColor?: string | null;
  readonly secondaryColorRGB?: ReadonlyArray<number> | null;
  readonly squareHeroImageBitmojiTemplateId?: string | null;
  readonly squareHeroImageId?: string | null;
  readonly squareHeroImageUrl?: string | null;
  readonly tags?: SnapTagsInput | null;
  readonly targetSnapLengthMs?: number | null;
  readonly tileLogos?: ReadonlyArray<(TileLogosInput | null)> | null;
  readonly websiteUrl?: string | null;
}

export interface HourMinInput {
  readonly hour: number;
  readonly minute: number;
}

export interface Media {
  readonly contentObjectBase64: string;
  readonly description?: string | null;
  readonly durationMs: number;
  readonly hasSound: boolean;
  readonly height: number;
  readonly ivBase64: string;
  readonly keyBase64: string;
  readonly mediaCaptureTimestampMs: number;
  readonly mediaType: MediaType;
  readonly topics?: ReadonlyArray<string> | null;
  readonly width: number;
}

export interface PostStorySnapInput {
  readonly disableRemix?: boolean | null;
  readonly goLiveTsMs?: any | null;
  readonly locale: string;
  readonly media?: ReadonlyArray<Media> | null;
  readonly postingTargets: ReadonlyArray<PostingTarget>;
  readonly saveToProfile?: boolean | null;
}

export interface PostingTarget {
  readonly businessProfileId: string;
  readonly hostUserId?: string | null;
  readonly storyType: StoryType;
}

export interface ProfileAnalyticsInput {
  readonly end: any;
  readonly hostUserId: any;
  readonly start: any;
}

export interface RevenueInput {
  readonly end: any;
  readonly hostUserId: any;
  readonly start: any;
}

export interface SavedStoryInput {
  readonly country?: EditionCountry | null;
  readonly end?: any | null;
  readonly hostUserId: any;
  readonly start?: any | null;
}

export interface SnapTagsInput {
  readonly MANUAL: ReadonlyArray<string>;
  readonly SCC: ReadonlyArray<string>;
  readonly WIKI: ReadonlyArray<string>;
}

export interface StoryAnalyticsInput {
  readonly country?: EditionCountry | null;
  readonly end?: any | null;
  readonly hostUserId: any;
  readonly start?: any | null;
  readonly storyId: string;
}

export interface StoryDraftingSnapsRequest {
  readonly businessProfileId?: string | null;
  readonly cursor: string;
  readonly hostUserId: string;
  readonly limit?: number | null;
  readonly scheduledStoryStatus: ScheduledStoryStatus;
  readonly storySnapType: StorySnapType;
}

export interface TileLogosInput {
  readonly color: string;
  readonly mediaId: string;
}

export interface TileModerationAppeal {
  readonly id: string;
  readonly justification: string;
}

export interface TopicRequest {
  readonly businessProfileId: string;
  readonly prefix: string;
}

export interface UpdatePublisherInput {
  readonly adSetting?: AdSetting | null;
  readonly adsPreRollEnabled?: boolean | null;
  readonly advancedAdsEnabled?: boolean | null;
  readonly advancedAdsLatestFirstSlot?: number | null;
  readonly advancedAdsMaxSlots?: number | null;
  readonly advancedAdsMinSlots?: number | null;
  readonly advancedAdsMinSpacing?: number | null;
  readonly advertisingEnabled?: boolean | null;
  readonly ageGate?: ContentAgeGateLevel | null;
  readonly allowlistCountry?: ReadonlyArray<string> | null;
  readonly allowlistDistributionCountry?: ReadonlyArray<string> | null;
  readonly allowlistLanguage?: ReadonlyArray<string> | null;
  readonly articleCSS?: string | null;
  readonly audioClassification?: AudioClassification | null;
  readonly blocklistCountry?: ReadonlyArray<string> | null;
  readonly blocklistDistributionCountry?: ReadonlyArray<string> | null;
  readonly blocklistLanguage?: ReadonlyArray<string> | null;
  readonly businessProfileId?: string | null;
  readonly contentAccessLists?: ContentAccessListsInput | null;
  readonly defaultAdSlots?: ReadonlyArray<number> | null;
  readonly defaultAdSlotsMillis?: ReadonlyArray<number> | null;
  readonly defaultFilledIcon?: string | null;
  readonly defaultFilledIconId?: string | null;
  readonly defaultNumSnaps?: number | null;
  readonly defaultShareOption?: ShareOption | null;
  readonly defaultSubtitlesLanguage?: string | null;
  readonly description?: string | null;
  readonly displayName?: string | null;
  readonly dynamicAdsEnabled?: boolean | null;
  readonly dynamicEditionAutoCreateStoryTimeUTC?: HourMinInput | null;
  readonly editorialApproval?: EditorialApproval | null;
  readonly editorialRationale?: string | null;
  readonly formalName?: string | null;
  readonly geofenceIds?: ReadonlyArray<any> | null;
  readonly homeCountry?: CountryCode | null;
  readonly horizontalIcon?: string | null;
  readonly horizontalIconId?: string | null;
  readonly hostUsername?: string | null;
  readonly id: string;
  readonly isComScoreIntegrationEnabled?: boolean | null;
  readonly isEmployeeOnly?: boolean | null;
  readonly isOurStories?: boolean | null;
  readonly localContent?: ReadonlyArray<string> | null;
  readonly managerEmail?: string | null;
  readonly managerName?: string | null;
  readonly moderationLevel?: ModerationLevel | null;
  readonly mutablePublisherName?: string | null;
  readonly nicknames?: ReadonlyArray<string> | null;
  readonly orgId?: string | null;
  readonly permitAutoPublishFromFeed?: boolean | null;
  readonly primaryColor?: string | null;
  readonly primaryColorRGB?: ReadonlyArray<number> | null;
  readonly primaryLanguage?: string | null;
  readonly profileLogoDisplay?: LogoDisplay | null;
  readonly publisherFeatures?: ReadonlyArray<PublisherFeatures> | null;
  readonly publisherFlags?: ReadonlyArray<PublisherFlags> | null;
  readonly publishingApprovals?: ReadonlyArray<PublishingApproval> | null;
  readonly publishingEnabled?: boolean | null;
  readonly regions?: ReadonlyArray<DiscoverRegion> | null;
  readonly rollingNewsEnabled?: boolean | null;
  readonly searchable?: boolean | null;
  readonly secondaryColor?: string | null;
  readonly secondaryColorRGB?: ReadonlyArray<number> | null;
  readonly squareHeroImageBitmojiTemplateId?: string | null;
  readonly squareHeroImageId?: string | null;
  readonly squareHeroImageUrl?: string | null;
  readonly subscribable?: boolean | null;
  readonly tags?: SnapTagsInput | null;
  readonly targetSnapLengthMs?: number | null;
  readonly tier?: TierLevel | null;
  readonly tileLogos?: ReadonlyArray<(TileLogosInput | null)> | null;
  readonly timeUntilAutoPublishFeedStory?: number | null;
  readonly topsnapLimit?: number | null;
  readonly type?: PublisherType | null;
  readonly urlSafeFormalName?: string | null;
  readonly videoModeEnabled?: boolean | null;
  readonly websiteUrl?: string | null;
  readonly webviewLimit?: number | null;
}

export interface UpdateStoryDraftingSnapsInput {
  readonly businessProfileId?: string | null;
  readonly goLiveTsMs: any;
  readonly hostUserId: string;
  readonly snapId: string;
}

export interface UploadUrlRequest {
  readonly country: string;
  readonly mediaFormat: string;
}

export interface UserInfoInput {
  readonly getAllProfiles?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
