import { useQuery } from '@apollo/client';

import { GetSavedStoryAnalytics } from 'gql/queries/analytics/__ssp-generated__/GetSavedStoryAnalytics';
import { GET_SAVED_STORIES_ANALYTICS } from 'gql/queries/analytics/savedStoriesAnalyticsQuery';

export const useGetSavedStoriesAnalytics = (start: string, end: string, hostUserId: string, country: string) => {
  const { data, loading } = useQuery<GetSavedStoryAnalytics>(GET_SAVED_STORIES_ANALYTICS, {
    variables: {
      input: {
        start,
        end,
        hostUserId,
        country,
      },
    },
  });
  return { data, loading };
};
