import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getEditionById } from 'state/editions/selectors/editionsSelectors';
import { unlinkEpisode } from 'state/episodes/actions/episodesActions';
import { isSeasonsEpisodesDisabled } from 'state/features/selectors/featuresSelectors';
import { getPresentationalTileForEdition } from 'state/publisherTools/selectors/publisherToolsSelectors';
import { getActivePublisherBusinessProfileId } from 'state/publishers/selectors/publishersSelectors';
import { goToPublisherStoryEditor } from 'state/router/actions/routerActions';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import { trash } from 'icons/SDS/allIcons';
import { stopEventPropagation } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import StoryStateIcon from 'views/common/components/StoryStateIcon/StoryStateIcon';

import style from './EpisodePreview.scss';

import { StoryState } from 'types/editions';
import { ExtractDispatchProps } from 'types/redux';
import type { State as ReduxState } from 'types/rootState';
import type { EditableEpisodeProperties, Episode, ShowID } from 'types/shows';
import type { Tile } from 'types/tiles';

type ExternalProps = {
  episode: Episode;
  showId: ShowID;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

function mapStateToProps(state: ReduxState, props: ExternalProps) {
  const firstTile: Tile | undefined | null = getPresentationalTileForEdition(state)(props.episode.id);
  const edition = getEditionById(state)(props.episode.id);
  const storyState = edition?.state;

  return {
    publisherId: getActivePublisherId(state),
    businessProfileId: getActivePublisherBusinessProfileId(state),
    storyState,
    mainTitle: firstTile && firstTile.headline ? firstTile.headline : '-',
    secondaryTitle: props.episode.title || (edition && edition.title) || '-',
    isSeasonsEpisodesDisabled: isSeasonsEpisodesDisabled(state),
  };
}

const mapDispatchToProps = {
  goToStoryEditor: goToPublisherStoryEditor,
  unlinkEpisodeFromSeason: unlinkEpisode,
};

type Props = ExternalProps & StateProps & DispatchProps;

function getEditableEpisodeProperties(episode: Episode, showId: ShowID): EditableEpisodeProperties {
  return {
    ...episode,
    showId,
    id: episode.id.toString(),
  };
}

export function EpisodePreview(props: Props) {
  const {
    publisherId,
    secondaryTitle,
    episode,
    storyState,
    businessProfileId,
    showId,
    unlinkEpisodeFromSeason,
    goToStoryEditor,
  } = props;
  // We allow to remove the episode metadata for stories in draft and in unavailible state
  //  - We do not allow users to remove episode metadata from stories that are in live or scheduled state so that
  //    we don't risk to propagate stories without shows metadata downstram.
  const isEpisodeRemovable = storyState === StoryState.NEW || storyState === StoryState.HIDDEN;
  const [isHovering, setIsHovering] = useState(false);
  const [isUnlinkInProgress, setUnlinkInProgress] = useState(false);

  const handleOnMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const handleOnClick = useCallback(() => {
    goToStoryEditor({
      publisherId,
      editionId: episode.id,
      overwriteHistory: false,
    });
  }, [goToStoryEditor, episode.id, publisherId]);

  const handleUnlinkClick = useCallback(
    (e: Event) => {
      stopEventPropagation(e);
      if (!publisherId || !businessProfileId) {
        return;
      }
      setUnlinkInProgress(true);
      unlinkEpisodeFromSeason(publisherId, businessProfileId, getEditableEpisodeProperties(episode, showId)).finally(
        () => {
          setUnlinkInProgress(false);
        }
      );
    },
    [unlinkEpisodeFromSeason, publisherId, businessProfileId, episode, showId]
  );

  const episodeText = getMessageFromId('episode-with-number', { episodeNumber: episode.episodeNumber });

  return (
    <div
      data-test="shows.episodePreview.container"
      className={style.episodeContainer}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className={style.episode}>
        <div data-test="shows.episodePreview.episode.status">
          <StoryStateIcon storyState={storyState} />
        </div>
        <div className={style.episodeTitles}>
          {!props.isSeasonsEpisodesDisabled && (
            <div data-test="shows.episodePreview.episode.text" className={style.subTitle}>
              {episodeText}
            </div>
          )}
          <div data-test="shows.episodePreview.episode.title" className={style.mainTitle}>
            {props.mainTitle}
          </div>
          <div data-test="shows.episodePreview.episode.secondaryTitle" className={style.subTitle}>
            {secondaryTitle}
          </div>
        </div>
      </div>
      <div>
        {!props.isSeasonsEpisodesDisabled && isEpisodeRemovable && isHovering ? (
          <SDSTooltip
            // @ts-expect-error ts-migrate(2769)
            placement={TooltipPosition.TOP_LEFT}
            title={
              <FormattedMessage
                id="remove-episode-from-season-tooltip"
                description="Tooltip for button to remove episode from season"
                defaultMessage="Remove episode from season"
              />
            }
          >
            <SDSButton
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              inlineIcon={trash}
              data-test="shows.episodePreview.unlinkEpisode.button"
              disabled={isUnlinkInProgress}
              onClick={handleUnlinkClick}
            />
          </SDSTooltip>
        ) : null}
      </div>
    </div>
  );
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(EpisodePreview);
