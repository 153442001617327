import _ from 'lodash';
import React from 'react';
import { intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';

import type { PollQuestion, StorySnapMetrics } from 'types/analytics';

type Props = {
  isLoading: boolean;
  snap: StorySnapMetrics;
};

export class AnalyticsMultiQuestionQuizGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getQuizConfig = () => {
    const legendMessage = getLocalisedMessageFromId(this.context, 'publisher-story-quiz-legend');
    return { key: 'votes', name: legendMessage, fill: '#FF8A00' };
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, question } = payload;
    return <AnalyticsTooltip title={name} pollMetadata={question} />;
  };

  render() {
    if (!this.props.snap.poll) {
      return null;
    }

    const { poll } = this.props.snap;
    // create the data points for all the questions
    const barChartData = poll.questions.map((question: PollQuestion, index: number) => {
      const name = getLocalisedMessageFromId(this.context, 'publisher-story-quiz-question', {
        questionIndex: index + 1,
      });
      const votes = question.total_votes;
      return { name, votes, question };
    });

    // add the outcome data point
    const outcomeName = getLocalisedMessageFromId(this.context, 'publisher-story-quiz-outcome');
    const outcome = {
      name: outcomeName,
      votes: poll.outcomes.total_votes,
      question: poll.outcomes,
      color: '#FF8EE5',
    };
    barChartData.push(outcome);

    return (
      <AnalyticsBarChart
        barChartData={barChartData}
        barChartTitle={getMessageFromId('publisher-story-quiz-question-graph', { questionTitle: this.props.snap.name })}
        bars={[this.getQuizConfig()]}
        isLoading={this.props.isLoading}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={numberFormatter.prettyPrintNumber}
      />
    );
  }
}

export default AnalyticsMultiQuestionQuizGraph;
