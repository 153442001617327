// discover-cms/no-snapnet
import {
  Row,
  Col,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/snapnet'; // discover-cms/no-snapnet
import numeral from 'numeral';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePermissionCheck } from 'gql/hooks/usePermissionCheck';
import { help } from 'icons/SDS/allIcons';
import { formatTimeViewed, printSeconds } from 'utils/numberFormatter';

import CheetahAnalyticsTilePreview from 'views/analytics/containers/CheetahAnalyticsTilePreview/CheetahAnalyticsTilePreview';
import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './TileVariantTable.scss';

import type { TileStats } from 'types/analytics';
import { TileStat } from 'types/analytics';
import { Claim } from 'types/permissions';

function renderTooltip(textNode: any) {
  if (!textNode) {
    return null;
  }

  return (
    <SDSTooltip
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      placement={TooltipPosition.TOP}
      title={textNode}
      id="tooltip"
    >
      <Icon inlineIcon={help} className={style.infoIcon} />
    </SDSTooltip>
  );
}

const HEADER = [
  <div className={style.titleContainer}>
    <FormattedMessage
      id="tile-variant-analytics-header-tile"
      description="Tile variant analytics header - tile"
      defaultMessage="Tile"
    />
    <HelpCenterLink
      destination={HelpCenterDestination.TILE_OPTIMIZER}
      data-test="TileVariantTable.helpCenterLink.tileOptimizer"
    />
  </div>,
  <div className={style.titleContainer}>
    <FormattedMessage
      id="tile-variant-analytics-header-indexed-ctr"
      description="Tile variant analytics header - indexed ctr"
      defaultMessage="Indexed CTR"
    />
    {renderTooltip(
      <FormattedMessage
        id="tile-variant-analytics-header-indexed-ctr-tooltip"
        description="Tile variant analytics header - indexed ctr tooltip"
        defaultMessage={
          'The Click Through Rate (CTR) shows how tappable a tile is by dividing clicks' +
          ' by impressions. CTRs are indexed vs the story average.'
        }
      />
    )}
  </div>,
  <div className={style.titleContainer}>
    <FormattedMessage
      id="tile-variant-analytics-header-avg-time-viewed"
      description="Tile variant analytics header - avg time viewed"
      defaultMessage="Time Viewed"
    />
    {renderTooltip(
      <FormattedMessage
        id="tile-variant-analytics-header-avg-time-viewed-info"
        description="Tile variant analytics header - avg time viewed tooltip"
        defaultMessage="The average amount of time Snapchatters spent viewing the story after opening it from the given tile in the Discover feed"
      />
    )}
  </div>,
  <div className={style.titleContainer}>
    <FormattedMessage
      id="tile-variant-analytics-header-unique-topsnaps-per-user"
      description="Tile variant analytics header - unique topsnaps per user"
      defaultMessage="Snaps Viewed"
    />
    {renderTooltip(
      <FormattedMessage
        id="tile-variant-analytics-header-unique-topsnaps-per-user-info"
        description="Tile variant analytics header - unique topsnaps per user tooltip"
        defaultMessage="The average number of unique topsnaps Snapchatters viewed when opening the story from the given tile in the Discover feed"
      />
    )}
  </div>,
];

type Props = {
  orderedTileStats: TileStats;
  editionId: string;
  useAnalyticsV2?: boolean;
};

export default function TileVariantTable(props: Props) {
  const isTileOptimizerImpressionViewer = usePermissionCheck(Claim.TILE_OPTIMIZER_IMPRESSIONS_VIEWER);
  const tileStats = props.orderedTileStats;

  const renderRow = (cols: ReactNode[], key: string | number, headerRow: boolean = false) => {
    const IMPRESSIONS_COLUMN_INDEX = 2;

    return (
      <Row className={headerRow ? style.headerRow : style.row} key={key}>
        {cols.map((col, index) => {
          const colProps = {
            className: index === 0 && style.firstCol,
            xs: 12,
            key: index,
          };
          const shouldRemoveImpressionsCol = index === IMPRESSIONS_COLUMN_INDEX && !isTileOptimizerImpressionViewer;
          return shouldRemoveImpressionsCol ? null : <Col {...colProps}>{col}</Col>;
        })}
      </Row>
    );
  };

  const tileStatsExistsAndHasItems = tileStats && tileStats.length > 0;

  if (!tileStatsExistsAndHasItems) {
    return null;
  }

  // Previously this was sorted by percentage of impressions
  tileStats.sort((a: TileStat, b: TileStat) => parseFloat(b.indexedCtr) - parseFloat(a.indexedCtr));

  return (
    <div>
      <div className={style.notification}>
        <FormattedMessage
          id="tile-variant-analytics-notification"
          description="Tile variant analytics notification message"
          defaultMessage={
            'Please note: Tile Optimizer results will not be accurate if you delete or modify ' +
            'tile images once the edition has been published. Tile Optimizer metrics will be accurate if you make ' +
            'adjustments to tile headlines after publishing.'
          }
        />
      </div>
      {renderRow(HEADER, 0, true)}
      {tileStats.map((tile: TileStat) => {
        const { editionId, tileId, headline } = tile;
        const editionPreviewConfig = {
          editionId,
          headline,
          tileId,
        };
        return renderRow(
          [
            <CheetahAnalyticsTilePreview
              edition={editionPreviewConfig}
              tileLogoUrl={tile.tileLogoUrl}
              tileImageUrl={tile.tileImageUrl}
              tileHeadline={headline}
            />,
            `${numeral(tile.indexedCtr).format('0.0')}x`,
            props.useAnalyticsV2
              ? formatTimeViewed(parseFloat(tile.avgTotalTimeViewed))
              : printSeconds(parseFloat(tile.avgTotalTimeViewed)),
            numeral(tile.uniqueTopsnapsPerUser).format('0.0'),
          ],
          tileId
        );
      })}
    </div>
  );
}
