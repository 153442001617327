import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { goToPublisherStoryEditor } from 'state/router/actions/routerActions';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import { pencil } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import { AnalyticsEditionCSVButton } from 'views/analytics/components/AnalyticsEditionCSVButton/AnalyticsEditionCSVButton';
import {
  EDITION_COUNTRY_OPTIONS,
  EditionCountry,
} from 'views/analytics/components/AnalyticsEditionHeader/EditionCountry';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import AdAccountPicker from 'views/common/containers/AdAccountPicker/AdAccountPicker';
import CalendarButton from 'views/homepage/containers/CalendarButton/CalendarButton';
import PayToPromoteButton from 'views/payToPromote/components/PayToPromoteButton/PayToPromoteButton';

import style from './AnalyticsEditionHeader.scss';

import { StoryState } from 'types/editions';

type Props = {
  from: moment.Moment | null;
  to: moment.Moment | null;
  setDateRange: (from?: moment.Moment | null, to?: moment.Moment | null) => void;
  setCountry: (country: EditionCountry) => void;
  country: EditionCountry;
  storyState: string | undefined | null;
  editionId?: number;
  setIsExporting: (isExporting: boolean) => void;
};

export function AnalyticsEditionHeader({
  from,
  to,
  setDateRange,
  storyState,
  country,
  setCountry,
  editionId,
  setIsExporting,
}: Props) {
  const dispatch = useDispatch();
  const currentPublisherId = useSelector(getActivePublisherId);
  const onCountryChange = useCallback(
    (updatedCountry: EditionCountry) => {
      setCountry(updatedCountry);
    },
    [setCountry]
  );

  const onDateRangeUpdated = useCallback(
    (fromMoment?: moment.Moment | null, toMoment?: moment.Moment | null) => {
      setDateRange(fromMoment, toMoment);
    },
    [setDateRange]
  );

  const viewStory = useCallback(() => {
    if (currentPublisherId && editionId) {
      dispatch(
        goToPublisherStoryEditor({
          editionId: editionId!,
          publisherId: currentPublisherId,
          overwriteHistory: false,
        })
      );
    }
  }, [currentPublisherId, dispatch, editionId]);

  const renderDatePicker = () => {
    const noDateText = (
      <FormattedMessage
        defaultMessage="All-Time"
        id="analytics-edition-no-date-range"
        description="Default text for the analytics edition date range button when no range has been selected"
      />
    );
    return (
      <CalendarButton
        className={style.datePickerButton}
        onUpdate={onDateRangeUpdated}
        defaultText={noDateText}
        from={from}
        to={to}
      />
    );
  };

  return (
    <div className={style.controlGroup} data-test="editionAnalyticsControlGroup">
      <div className={style.expand} />
      {storyState !== StoryState.HIDDEN && (
        <PayToPromoteButton
          buttonType={ButtonType.SECONDARY}
          storyId={editionId}
          data-test="AnalyticsEditionView.payToPromoteButton"
        />
      )}
      <div className={style.buttonContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          onClick={viewStory}
          inlineIcon={pencil}
          data-test="analytics.edition.viewStory.button"
        >
          {getMessageFromId('view-story-button')}
        </SDSButton>
      </div>
      {renderDatePicker()}
      <div className={style.countryDropdown}>
        <SDSDropdown
          type={DropdownType.GREY}
          size={DropdownSize.SMALL}
          value={country}
          data-test="AnalyticsEditionCSVModal.breakdownDropdown"
          onChange={onCountryChange}
        >
          {createSDSDropdownOptions(EDITION_COUNTRY_OPTIONS)}
        </SDSDropdown>
      </div>
      <div className={style.buttonContainer}>
        <AnalyticsEditionCSVButton
          editionId={editionId}
          from={from}
          to={to}
          setIsExporting={setIsExporting}
          country={country}
        />
      </div>
      <AdAccountPicker data-test="AnalyticsEditionView.adAccountPicker" />
    </div>
  );
}
