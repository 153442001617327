import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { registerIntlMessage, getMessageFromId } from 'utils/intlMessages/intlMessages';

import style from './AudienceCategories.scss';

type Props = {
  tags: string[];
  dataAgeRanges: any;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-tags-categories"
      description="Header of the tags categories component on the analytics insights report"
      defaultMessage="Content Categories"
    />
  ),
  params: [],
});

const MAX_TAG_NUMBER = 3;

export class AudienceCategories extends React.Component<Props> {
  getTopAgeGroup() {
    const sortedAgeGroups = this.props.dataAgeRanges.sort((a: any, b: any) => b.percent - a.percent);
    return sortedAgeGroups[0].group;
  }

  getTags = () => {
    let tags;
    if (this.props.tags) {
      tags = _.map(this.props.tags, (name, index) => {
        if (index < MAX_TAG_NUMBER) {
          return this.renderTag(name);
        }
        return null;
      });
    }
    return tags;
  };

  renderTag = (name: string) => {
    return (
      <div className={style.tag} key={`tag_${name}`}>
        <p>{name}</p>
      </div>
    );
  };

  render() {
    return (
      <div className={style.audienceCategories}>
        <div className={style.audienceCategoriesLifestyle}>
          <p className={style.audienceCategoriesHeader}>
            {getMessageFromId('analytics-insights-report-tags-categories')}
          </p>
          <div className={style.tagsContainer}>
            <div className={style.tags}>{this.getTags()}</div>
          </div>
        </div>
        <div className={style.audienceCategoriesAgeGroup}>
          <p className={style.audienceCategoriesHeader}>
            {getMessageFromId('analytics-insights-report-top-age-group')}
          </p>
          <div className={style.audienceCategoriesAgeGroupItem}>{this.getTopAgeGroup()}</div>
        </div>
      </div>
    );
  }
}

export default AudienceCategories;
