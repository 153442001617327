import _ from 'lodash';
import React from 'react';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publisherToolsSelectors from 'state/publisherTools/selectors/publisherToolsSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import MobileTilesView from 'views/common/containers//MobileTilesView/MobileTilesView';
import AnalyticsCarousel from 'views/common/containers/AnalyticsCarousel/AnalyticsCarousel';
import PreviewableStoryButton from 'views/common/containers/PreviewableStoryButton/PreviewableStoryButton';

import style from './MobileStoryView.scss';

import type { Edition } from 'types/editions';
import type { Tile } from 'types/tiles';

type Props = {
  activeEdition: Edition | undefined | null;
  tile: Tile | undefined | null;
};

const mapStateToProps = (state: State) => {
  const activeEdition = publisherStoryEditorSelectors.getActiveEdition(state);
  const tile = activeEdition ? publisherToolsSelectors.getPresentationalTileForEdition(state)(activeEdition.id) : null;
  return {
    activeEdition,
    tile,
  };
};

export class MobileStoryView extends React.Component<Props> {
  renderTopbar = () => {
    const { tile, activeEdition } = this.props;
    if (!activeEdition) {
      return null;
    }

    const editionTitle = _.get(activeEdition, 'title');
    const storyTitle = _.get(tile, 'headline', editionTitle);

    return (
      <div className={style.storyViewTopbar}>
        <div className={style.title}>{storyTitle}</div>
        <div>
          <PreviewableStoryButton editionId={activeEdition.id} />
        </div>
      </div>
    );
  };

  renderTilePreviews = () => {
    if (!this.props.activeEdition || !this.props.activeEdition.snapIds) {
      return null;
    }

    return _.map(this.props.activeEdition.snapIds, (snapId, index) => (
      <MobileTilesView key={snapId} snapId={snapId} snapIndex={index + 1} />
    ));
  };

  render() {
    if (!this.props.activeEdition) {
      return null;
    }

    return (
      <div className={style.storyViewContainer}>
        {this.renderTopbar()}
        <div className={style.storyViewBody}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ activeEdition: Edition; }' is not assignab... Remove this comment to see the full error message */}
          <AnalyticsCarousel activeEdition={this.props.activeEdition} />
          <div>{this.renderTilePreviews()}</div>
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(MobileStoryView);
