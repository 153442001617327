import invariant from 'invariant';
import _ from 'lodash';
import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import * as editorActions from 'state/editor/actions/editorActions';
import * as showsActions from 'state/shows/actions/showsActions';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';

import { buildHashForExistingKeys, buildHashForProperties, buildShallowDiff } from 'utils/apis/partialUpdateUtil';
import * as scsAPI from 'utils/apis/scsAPI';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';

import type { BusinessProfileID, PublisherID } from 'types/publishers';
import type { GetState } from 'types/redux';
import type { Season, SeasonUpdate, ShowID } from 'types/shows';

export const UPDATE_SEASON = 'seasons/UPDATE_SEASON';
type UpdatesWithHash = {
  updates: SeasonUpdate[];
  hash: string;
};
const getUpdateDiffsAndHashForSeasons = (
  existingSeasons: Season[],
  updatedSeasons: SeasonUpdate[]
): UpdatesWithHash => {
  const seasonHashes = [];
  const seasonUpdates = [];
  for (let i = 0; i < updatedSeasons.length; i++) {
    const updatedSeason = updatedSeasons[i];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const existingSeason = _.find(existingSeasons, season => season.id === updatedSeason.id);
    invariant(existingSeason, 'the season must exist');
    // the existing season doesn't have an episodeId property, it stores
    // the episodes in a separate property. Transform the ids into an
    // episodeIds property so we can calculate the hash correctly
    const existingSeasonWithEpisodeIds = {
      ...existingSeason,
      episodeIds: existingSeason.episodes.map(e => e.id),
    };
    const diff = buildShallowDiff(existingSeason, updatedSeason, ['id', 'episodeIds']);
    const hash = buildHashForExistingKeys(existingSeasonWithEpisodeIds, diff, [], ['id', 'episodeIds']);
    const diffWithLastUpdatedAt = { ...diff, lastUpdatedAt: existingSeason.lastUpdatedAt };
    seasonHashes.push(hash);
    seasonUpdates.push(diffWithLastUpdatedAt);
  }
  const hash = buildHashForProperties({ seasons: seasonHashes }, []);
  return { updates: seasonUpdates, hash };
};
export const updateSeasons = (
  publisherId: PublisherID,
  businessProfileId: BusinessProfileID,
  seasons: SeasonUpdate[]
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const shows = showsSelectors.getShows(getState())(publisherId);
    const existingShow = shows && shows.length > 0 && shows[0];
    invariant(existingShow, `could not find show for publisher with id: ${publisherId}`);
    const { updates, hash } = getUpdateDiffsAndHashForSeasons(existingShow.seasons, seasons);
    await (dispatch(
      createCallAction(
        {
          type: UPDATE_SEASON,
          params: {
            publisherId,
          },
        },
        {
          method: 'PUT',
          endpoint: scsAPI.shows.updateSeasons({ publisherId, hash }),
          body: updates,
        }
      )
    ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.UPDATE_SEASONS));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => CallActi... Remove this comment to see the full error message
    return dispatch(showsActions.loadShows(publisherId, businessProfileId));
  };
};
export const updateSeasonsWithSaving = (
  publisherId: PublisherID,
  businessProfileId: BusinessProfileID,
  showId: ShowID,
  seasons: SeasonUpdate[]
) => {
  return async (dispatch: Dispatch) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
    await dispatch(editorActions.updateShowEditorState(showId, { isSaving: true }));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
    const savingCompleteAction = () => dispatch(editorActions.updateShowEditorState(showId, { isSaving: false }));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
    return dispatch(updateSeasons(publisherId, businessProfileId, seasons)).then(
      savingCompleteAction,
      savingCompleteAction
    );
  };
};
