import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { CrossOrigin } from 'config/constants';
import { createAssetUrl } from 'utils/media/assetUtils';
import { printPercentage } from 'utils/numberFormatter';

import style from './GraphTooltip.scss';

type Props = {
  questionProvider?: (...args: any[]) => any;
  d?: any;
  snapPreviewFrames?: any[];
};

/**
 * This component can be used to decorate a tooltip with results from a question (ie some kind of poll snap).
 * props.questionProvider should be set to return the question given the NVD3 data in props.d
 */
export default class QuestionResults extends React.Component<Props> {
  getChoiceRows(question: any) {
    const { choices } = question;
    const highlightedIndex =
      question.correctAnswer !== undefined ? question.correctAnswer : getIndexOfWinningChoice(choices);
    return choices.map((choice: any, i: any) => {
      const description = choice.publisher_text ? choice.publisher_text : choice.choice_id;
      const title = `${description}: `;
      let percentage;
      if (choice.displayPercentage !== undefined) {
        percentage = choice.displayPercentage;
      } else {
        const count = choice.vote_count;
        percentage = printPercentage(count / question.total_votes);
      }

      const isHighlightedChoice = highlightedIndex === i;
      const keyClassName = isHighlightedChoice ? style.highlightedTitle : style.nonHighlightedTitle;
      const valueClassName = isHighlightedChoice ? style.highlightedPercentage : style.nonHighlightedPercentage;

      return (
        <tr key={choice.choice_id} data-test="question-results-choice">
          <td />
          <td className={keyClassName} data-test="question-results-choice-key">
            {title}
          </td>
          <td className={valueClassName} data-test="question-results-choice-value">
            {percentage}
          </td>
          <td>
            <span className={style.choiceThumbnail}>
              <img
                src={createAssetUrl(choice.assetId)}
                crossOrigin={CrossOrigin.USE_CREDENTIALS}
                alt={title}
                data-test="question-results-choice-img"
              />
            </span>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { questionProvider } = this.props;
    const question = questionProvider && questionProvider(this.props.d, this.props.snapPreviewFrames);
    if (!question) {
      return null;
    }
    const tableClassName = classNames(
      style.pollTableBase,
      question.correctAnswer !== undefined ? style.withCorrectAnswer : null
    );

    return (
      <table className={tableClassName} data-test="question-results">
        <thead>
          <tr>
            <td />
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
            <td colSpan="2" className={style.pollTextBold}>
              {question.title || ''}
            </td>
          </tr>
          <tr>
            <td />
            <td className={style.voteTitle}>
              <FormattedMessage
                id="poll-total-votes-title"
                description="The total number of responses in a poll"
                defaultMessage="Total response: "
              />
            </td>
            <td className={style.voteCount}>{question.total_votes}</td>
          </tr>
          <tr>
            <td className={style.divider} />
          </tr>
          <tr>
            <td />
            <td className={style.pollTextBold}>
              <FormattedMessage
                id="poll-results-title"
                description="The title for the poll results"
                defaultMessage="Results:"
              />
            </td>
          </tr>
        </thead>
        <tbody>{this.getChoiceRows(question)}</tbody>
      </table>
    );
  }
}

function getIndexOfWinningChoice(choices: any) {
  let max = 0;
  let index = 0;

  choices.forEach((choice: any, i: any) => {
    const count = parseInt(choice.vote_count, 10);
    if (count > max) {
      index = i;
      max = count;
    }
  });

  return index;
}
