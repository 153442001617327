import React from 'react';
import { FormattedMessage } from 'react-intl';

import { showModal } from 'state/modals/actions/modalsActions';
import { getActivePublisherId, hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { hidden, gear } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { ModalType } from 'utils/modalConfig';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import Icon from 'views/common/components/Icon/Icon';
import SDSTabs from 'views/common/components/SDSTabs/SDSTabs';

import style from './SettingsView.scss';
import { SETTINGS_TABS, TABS_CONFIG } from './tabs/settingsTabsConfig';
import type { SettingsTabsEnum } from './tabs/settingsTabsConfig';

import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';

type StateProps = ReturnType<typeof mapStateToProps>;

type State = {
  settingsTabName: SettingsTabsEnum;
  tabHasActiveChanges: boolean;
};

export const mapStateToProps = (state: RootState) => {
  const publisherId = getActivePublisherId(state);

  return {
    publisherId,
    isSuperPublisherSettingsEditor: hasClaimForActivePublisher(state, Claim.SUPER_PUBLISHER_SETTINGS_EDITOR),
  };
};

const mapDispatchToProps = {
  showModal,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps & StateProps;

const TabsConfig = {
  [SETTINGS_TABS.PUBLISHER]: {
    text: (
      <FormattedMessage
        id="publisher-settings-tab-header"
        description="Text for Publisher tab header"
        defaultMessage="Publisher"
      />
    ),
    tabKey: 'publisher',
  },
  [SETTINGS_TABS.SUPER_ADMIN]: {
    text: (
      <FormattedMessage
        id="super-admin-settings-tab-header"
        description="Text for Super Admin tab header"
        defaultMessage="Super Admin"
      />
    ),
    tabKey: 'admin',
    icon: hidden,
    toolTipMessage: (
      <FormattedMessage
        id="regular-users-cannot-see-this-only"
        description="Tooltip for admin content only"
        defaultMessage="Regular users cannot see this"
      />
    ),
  },
  [SETTINGS_TABS.FEATURES]: {
    text: (
      <FormattedMessage
        id="features-settings-tab-header"
        description="Text for Features tab header"
        defaultMessage="Features"
      />
    ),
    tabKey: 'features',
    icon: hidden,
    toolTipMessage: (
      <FormattedMessage
        id="regular-users-cannot-see-this-only"
        description="Tooltip for admin content only"
        defaultMessage="Regular users cannot see this"
      />
    ),
  },
};

export class SettingsView extends React.Component<Props, State> {
  state = {
    settingsTabName: SETTINGS_TABS.PUBLISHER,
    tabHasActiveChanges: false,
  };

  setTabHasActiveChanges = (hasChanges: boolean) => {
    this.setState({ tabHasActiveChanges: hasChanges });
  };

  onTabChange = (settingsTabIndex: number) => {
    const settingsTabName = Object.keys(TabsConfig)[settingsTabIndex];
    if (!settingsTabName) {
      return;
    }

    if (this.state.tabHasActiveChanges) {
      const modalMessage = (
        <FormattedMessage
          id="settings-view-unsaved-changes"
          description="Message asking the user if they want to continue without saving their changes"
          defaultMessage="You have unsaved changes. Do you want to continue without saving?"
        />
      );
      const modalConfig: DialogModalOptions = {
        visible: true,
        isBodyCentered: true,
        onConfirm: () => {
          this.setState({ settingsTabName, tabHasActiveChanges: false });
        },
        onCancel: () => {},
        body: modalMessage,
      };
      this.props.showModal(ModalType.DIALOG, 'SettingsView', modalConfig);
    } else {
      this.setState({ settingsTabName });
    }
  };

  renderToolTipMessage = () => {
    return (
      <FormattedMessage
        id="regular-users-cannot-see-this-only"
        description="Tooltip for admin content only"
        defaultMessage="Regular users cannot see this"
      />
    );
  };

  renderNavTabs() {
    if (!this.props.isSuperPublisherSettingsEditor) {
      return null;
    }

    return (
      <div className={style.tabsContainer}>
        <SDSTabs
          selectedTab={Object.keys(TabsConfig).indexOf(this.state.settingsTabName)}
          handleTabSelected={this.onTabChange}
          tabs={Object.values(TabsConfig)}
        />
      </div>
    );
  }

  render() {
    const TabContent = TABS_CONFIG[this.state.settingsTabName]?.content;

    return (
      <div className={style.parent}>
        <div className={style.header}>
          <div className={style.headerText}>
            <Icon inlineIcon={gear} className={style.gearIcon} alt="settings icon" />
            {getMessageFromId('publisher-details-settings-header')}
          </div>
          {this.renderNavTabs()}
          <div className={style.saveButtonPlaceholder} />
        </div>
        <div className={style.content}>
          {TabContent && (
            <TabContent publisherId={this.props.publisherId} setTabHasActiveChanges={this.setTabHasActiveChanges} />
          )}
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SettingsView);
