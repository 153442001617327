import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { getActiveCreatorHostUserId, hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { useGetRevenue } from 'gql/hooks/useGetRevenue';
import { isRevenueDashboardEnabled } from 'utils/storytellerMonetizationUtils';
import { TIME_OPTIONS } from 'utils/time/analyticsTimeUtils';

import { RevenueGraph } from 'views/analytics/components/RevenueGraph/RevenueGraph';
import { RevenueHeader } from 'views/analytics/components/RevenueHeader/RevenueHeader';
import { RevenueKPIs } from 'views/analytics/components/RevenueKPIs/RevenueKPIs';
import { RevenueTable } from 'views/analytics/components/RevenueTable/RevenueTable';
import { getDatesFromSearchParams, updateDatesInSearchParams } from 'views/analytics/utils/searchParamsUtil';

import style from './RevenueView.scss';

import { RevenueData, RevenueMetric } from 'types/analytics';
import { Claim } from 'types/permissions';
import { State } from 'types/rootState';

export default function RevenueView() {
  const history = useHistory();
  const location = useLocation();

  const hostUserId = useSelector(getActiveCreatorHostUserId) || '';
  const activePublisher = useSelector(getActivePublisherDetails) || null;
  const isMonetizationAnalyst = useSelector((state: State) =>
    hasClaimForActivePublisher(state, Claim.MONETIZATION_ANALYST_VIEWER)
  );
  const initialTimeOption = TIME_OPTIONS[3];
  const initialFrom = getDatesFromSearchParams(location).from || initialTimeOption!.from();
  const initialTo = getDatesFromSearchParams(location).to || moment().subtract(5, 'd');

  const [start, setStart] = useState<moment.Moment>(initialFrom);
  const [end, setEnd] = useState<moment.Moment>(initialTo);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeMetric, setActiveMetric] = useState<RevenueMetric | null>(null);

  const { data, loading, fetchMore } = useGetRevenue(
    start.format('YYYY-MM-DD'),
    end.format('YYYY-MM-DD'),
    hostUserId,
    10
  );
  const onDateRangeUpdated = useCallback(
    async (from: moment.Moment, to: moment.Moment) => {
      setStart(from);
      setEnd(to);
      setCurrentPage(0);
      updateDatesInSearchParams(history, from, to);
      await fetchMore({
        variables: {
          input: {
            start: from.format('YYYY-MM-DD'),
            end: to.format('YYYY-MM-DD'),
            hostUserId,
          },
        },
      });
    },
    [history, fetchMore, hostUserId]
  );

  let revenueData: RevenueData | null = null;
  if (data?.revenue.__typename === 'Revenue') {
    revenueData = data.revenue.unfiltered;
  }

  const renderRevenueKPIs = () => {
    if (!revenueData) {
      return null;
    }
    return (
      <RevenueKPIs
        activeMetric={activeMetric}
        revenueSummary={revenueData.profileRevenue.aggregate}
        setActiveMetric={setActiveMetric}
      />
    );
  };

  const renderRevenueGraph = () => {
    if (!revenueData || !activeMetric) {
      return null;
    }
    return <RevenueGraph activeMetric={activeMetric} profileRevenue={revenueData.profileRevenue} />;
  };

  const renderRevenueTable = () => {
    if (!revenueData?.bundlesRevenue) {
      return null;
    }
    return (
      <RevenueTable
        bundlesRevenue={revenueData.bundlesRevenue}
        pageSize={10}
        loading={loading}
        fetchMore={fetchMore}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    );
  };

  if (!isRevenueDashboardEnabled(isMonetizationAnalyst, activePublisher)) {
    return null;
  }

  return (
    <div className={style.content}>
      <RevenueHeader from={start} to={end} setDateRange={onDateRangeUpdated} maxEndDate={moment().subtract(5, 'd')} />
      <div className={style.mainContent}>
        {renderRevenueKPIs()}
        {renderRevenueGraph()}
        {renderRevenueTable()}
      </div>
    </div>
  );
}
