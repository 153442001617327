import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as analyticsActions from 'state/analytics/actions/analyticsActions';
import { getCountryCodes, getInsights } from 'state/analytics/selectors/analyticsSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import { intlConnect } from 'utils/connectUtils';
import { withRouter } from 'utils/routerUtils';

import AnalyticsFooter from 'views/analytics/components/AnalyticsFooter/AnalyticsFooter';
import { AnalyticsInsightsLifestyleCategoryCarrousel } from 'views/analytics/components/AnalyticsInsightsLifestyleCategoryCarrousel/AnalyticsInsightsLifestyleCategoryCarrousel';
import AnalyticsInsightsTopPerformingStoriesView from 'views/analytics/components/AnalyticsInsightsTopPerformingStoriesView/AnalyticsInsightsTopPerformingStoriesView';
import AnalyticsInsightsTopPerformingTilesView from 'views/analytics/components/AnalyticsInsightsTopPerformingTilesView/AnalyticsInsightsTopPerformingTilesView';
import AnalyticsInsightsSettingsButton from 'views/analytics/containers/AnalyticsInsightsSettingsButton/AnalyticsInsightsSettingsButton';
import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';

import style from './AnalyticsInsightsView.scss';

import type { InsightsAnalytics } from 'types/analytics';
import { Claim } from 'types/permissions';
import type { Publisher, PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';

type OwnProps = {
  publisherId: PublisherID;
  breadcrumbs: any;
  history: any;
  location: any;
  match: any;
};

type StateProps = {
  publisher: Publisher | undefined | null;
  pickedCountryCodes: string | Array<string>;
  analytics: InsightsAnalytics;
};

type DispatchProps = {
  fetchInsightsLifestyleCategoryStats: typeof analyticsActions.fetchInsightsLifestyleCategoryStats;
  clearInsightsLifestyleCategoryStats: typeof analyticsActions.clearInsightsLifestyleCategoryStats;
  fetchInsightsTopPerformingTilesStats: typeof analyticsActions.fetchInsightsTopPerformingTilesStats;
  clearInsightsTopPerformingTilesStats: typeof analyticsActions.clearInsightsTopPerformingTilesStats;
  fetchInsightsTopPerformingStoriesStats: typeof analyticsActions.fetchInsightsTopPerformingStoriesStats;
  clearInsightsTopPerformingStoriesStats: typeof analyticsActions.clearInsightsTopPerformingStoriesStats;
};

type Props = StateProps & DispatchProps & OwnProps;

type OwnState = {
  isLoadingMetrics: boolean;
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  analytics: getInsights(state),
  pickedCountryCodes: getCountryCodes(state),
  publisher: publishersSelectors.getPublisherDetailsDataById(state)(ownProps.publisherId),
});
const mapDispatchToProps = {
  fetchInsightsLifestyleCategoryStats: analyticsActions.fetchInsightsLifestyleCategoryStats,
  clearInsightsLifestyleCategoryStats: analyticsActions.clearInsightsLifestyleCategoryStats,
  fetchInsightsTopPerformingTilesStats: analyticsActions.fetchInsightsTopPerformingTilesStats,
  clearInsightsTopPerformingTilesStats: analyticsActions.clearInsightsTopPerformingTilesStats,
  fetchInsightsTopPerformingStoriesStats: analyticsActions.fetchInsightsTopPerformingStoriesStats,
  clearInsightsTopPerformingStoriesStats: analyticsActions.clearInsightsTopPerformingStoriesStats,
};

const COUNTRY_CODE_SEPARATOR = '_';

// Content Insights client use different country codes
const HOME_COUNTRY_OVERRIDES = {
  GB: 'UK',
};
const getCorrectHomeCountry = (homeCountryString: string): string => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return HOME_COUNTRY_OVERRIDES[homeCountryString] || homeCountryString;
};

export class AnalyticsInsightsView extends React.Component<Props, OwnState> {
  static path = '/publisher/:publisherId/analytics/insights';

  componentDidMount() {
    this.getAnalyticsInsightsData();
  }

  componentWillUnmount() {
    this.clearAnalyticsInsightsData();
  }

  getAnalyticsInsightsData = () => {
    this.clearAnalyticsInsightsData();

    const countries = Array.isArray(this.props.pickedCountryCodes)
      ? encodeURI(this.props.pickedCountryCodes.join(COUNTRY_CODE_SEPARATOR))
      : null;

    const homeCountryString = getCorrectHomeCountry(_.get(this.props.publisher, ['homeCountry'], ''));
    const lifestyleCategoriesArgs = {
      publisherId: this.props.publisherId,
      countries: [homeCountryString],
    };
    const topPerformingTilesArgs = {
      publisherId: this.props.publisherId,
    };
    const topPerformingStoriesArgs = {
      publisherId: this.props.publisherId,
      geoType: 'GLOBAL',
      countries,
      startDate: '',
      endDate: '',
    };

    Promise.all([
      this.props.fetchInsightsLifestyleCategoryStats({ ...lifestyleCategoriesArgs }),
      this.props.fetchInsightsTopPerformingTilesStats({ ...topPerformingTilesArgs }),
      this.props.fetchInsightsTopPerformingStoriesStats({ ...topPerformingStoriesArgs }),
    ]);
  };

  clearAnalyticsInsightsData = () => {
    this.props.clearInsightsLifestyleCategoryStats();
    this.props.clearInsightsTopPerformingTilesStats();
    this.props.clearInsightsTopPerformingStoriesStats();
  };

  renderHeaderMessage() {
    return (
      <div className={classNames(style.headerMessageContainer)} data-test="AnalyticsInsightsView.headerMessage">
        <div className={classNames(style.headerMessage)}>
          <FormattedMessage
            id="insights-header"
            description="Header text for Insights page"
            defaultMessage="Metrics from the last 30 days"
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <ClaimGatedView requiredClaim={Claim.ANALYTICS_VIEWER}>
        <AnalyticsInsightsSettingsButton />
        {this.renderHeaderMessage()}
        <AnalyticsInsightsLifestyleCategoryCarrousel slcs={this.props.analytics.slcs} />
        <AnalyticsInsightsTopPerformingTilesView topPerformingTiles={this.props.analytics.topPerformingTiles} />
        <AnalyticsInsightsTopPerformingStoriesView topPerformingStories={this.props.analytics.topPerformingStories} />
        <AnalyticsFooter />
      </ClaimGatedView>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsInsightsView));
