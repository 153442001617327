import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import StoryStateIcon, { StoryIcon } from 'views/common/components/StoryStateIcon/StoryStateIcon';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './StoryStatusLabel.scss';

type Props = {
  status?: string;
  statusIdToMessageId: any;
  isAnalytics?: boolean;
  isPublishingDisabled?: boolean;
};

type State = any;

export class StoryStatusLabel extends React.Component<Props, State> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  statusIdToIcon = {
    NEW: StoryIcon.DRAFT,
    IN_PROGRESS: StoryIcon.DRAFT,
    READY: StoryIcon.DRAFT,

    SCHEDULED: StoryIcon.SCHEDULED,
    PUBLISHING: StoryIcon.SCHEDULED,

    ARCHIVED: StoryIcon.AVAILABLE,
    AVAILABLE: StoryIcon.AVAILABLE,
    LIVE: StoryIcon.AVAILABLE,
    UNPUBLISHING: StoryIcon.AVAILABLE,

    HIDDEN: StoryIcon.UNAVAILABLE,
    UNAVAILABLE: StoryIcon.UNAVAILABLE,
    ENDED: StoryIcon.UNAVAILABLE,
  };

  wrapWithTooltip = ({ overlayDataTest, tooltipId, tooltipText, childElement, delay = 0 }: any) => {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <SDSTooltip placement={TooltipPosition.TOP_LEFT} id={tooltipId} title={tooltipText} data-test={overlayDataTest}>
        {childElement}
      </SDSTooltip>
    );
  };

  renderStatus = (status: any) => {
    const statusText = classNames(style.statusText, {
      [style.disabledText]: this.props.isPublishingDisabled,
    });

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const icon = this.statusIdToIcon[status];
    return (
      <div className={style.statusLabel}>
        <StoryStateIcon icon={icon} isDisabled={this.props.isPublishingDisabled} />
        <div className={statusText} data-test="story-status-label">
          {getMessageFromId(this.props.statusIdToMessageId[status])}
        </div>
      </div>
    );
  };

  render() {
    const { status } = this.props;

    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (!this.statusIdToIcon[status]) {
      return null;
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (editionEntityHelpers.isStateAvailable(status) && this.props.isPublishingDisabled) {
      return this.wrapWithTooltip({
        overlayDataTest: 'homepage-tooltip-available-disabled',
        tooltipId: 'story-status-label-disabled',
        tooltipText: getMessageFromId('publishing-disabled-stories-not-visible'),
        childElement: this.renderStatus(status),
      });
    }

    return this.renderStatus(status);
  }
}

export default StoryStatusLabel;
