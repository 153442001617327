import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { hideModal } from 'state/modals/actions/modalsActions';
import { createOrgOnboarding, deleteOrgOnboarding } from 'state/orgOnboardings/actions/orgOnboardingsActions';
import { getOrgOnboardingById, isOrgOnboardingsLoading } from 'state/orgOnboardings/selectors/orgOnboardingsSelectors';

import { Manager } from 'src/constants/managers';
import { intlConnect } from 'utils/connectUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import ManagerDropdown from 'views/snapAdmin/components/ManagerDropdown/ManagerDropdown';

import style from './OrgOnboardingModal.scss';

import { OrgOnboardingId } from 'types/orgOnboarding';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

type Options = {
  onboardingId?: OrgOnboardingId;
};

type OwnProps = {
  modalId: string;
  options: Options;
};

const mapStateToProps = (state: State, props: OwnProps) => {
  const { onboardingId } = props.options;
  return {
    isLoading: isOrgOnboardingsLoading(state),
    existingOnboarding: onboardingId ? getOrgOnboardingById(state)(onboardingId) : null,
  };
};

const mapDispatchToProps = {
  hideModal,
  createOrgOnboarding,
  deleteOrgOnboarding,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;
type OwnState = {
  mutableUsername: string;
  userEmail: string;
  managerEmail: string;
  managerName: string;
};

const translations = new Map<keyof OwnState, JSX.Element>([
  [
    'mutableUsername',
    <FormattedMessage
      id="org-onboarding-modal-username"
      defaultMessage="Snap Username"
      description="Title for snap username input"
    />,
  ],
  [
    'userEmail',
    <FormattedMessage
      id="org-onboarding-modal-useremail"
      defaultMessage="User Email"
      description="Title for user email input"
    />,
  ],
  [
    'managerEmail',
    <FormattedMessage
      id="org-onboarding-modal-manager"
      defaultMessage="Partner Manager"
      description="Title for partner manager dropdown"
    />,
  ],
]);

export class OrgOnboardingModal extends React.Component<Props, OwnState> {
  state: OwnState = {
    mutableUsername: this.props.existingOnboarding?.mutableUsername || '',
    userEmail: this.isEditingExisting() ? 'N/A' : '',
    managerEmail: this.props.existingOnboarding?.managerEmail || '',
    managerName: this.props.existingOnboarding?.managerName || '',
  };

  handleOnTextInputChange = _.memoize((property: keyof OwnState) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [property]: value }));
  });

  isEditingExisting() {
    return Boolean(this.props.options.onboardingId);
  }

  handleOnHide = () => {
    this.props.hideModal(this.props.modalId);
  };

  handleOnSubmit = () => {
    const { mutableUsername, userEmail, managerEmail, managerName } = this.state;
    return this.props
      .createOrgOnboarding({
        mutableUsername: mutableUsername.trim(),
        userEmail: userEmail.trim(),
        managerEmail: managerEmail.trim(),
        managerName: managerName.trim(),
      })
      .then(() => this.props.hideModal(this.props.modalId))
      .catch(() => {
        // The api error handler already displays an error so we can do nothing.
      });
  };

  handleOnDelete = () => {
    const { onboardingId } = this.props.options;
    if (!onboardingId) {
      this.props.hideModal(this.props.modalId);
      return Promise.resolve();
    }

    return this.props
      .deleteOrgOnboarding(onboardingId)
      .then(() => this.props.hideModal(this.props.modalId))
      .catch(() => {
        // The api error handler already displays an error so we can do nothing.
      });
  };

  renderTextInput(property: keyof OwnState) {
    const isDisabled = this.isEditingExisting();
    return (
      <SDSInput
        className={style.input}
        labelTitle={translations.get(property)}
        value={this.state[property]}
        onChange={this.handleOnTextInputChange(property)}
        disabled={isDisabled}
        data-test={`snapadmin.components.orgonboardingmodal.${property}`}
      />
    );
  }

  handleOnManagerChange = (manager: Manager) => {
    this.setState({ managerEmail: manager.email, managerName: manager.name });
  };

  renderManagerDropdown() {
    const isDisabled = this.isEditingExisting();
    return (
      <SDSLabel labelTitle={translations.get('managerEmail')}>
        <ManagerDropdown
          dropdownWidthMatchContent
          type={DropdownType.GREY}
          size={DropdownSize.MEDIUM}
          managerEmail={this.state.managerEmail}
          onManagerChange={this.handleOnManagerChange}
          disabled={isDisabled}
          data-test="snapadmin.components.orgonboardingmodal.managerEmail"
        />
      </SDSLabel>
    );
  }

  renderHeader() {
    return this.isEditingExisting() ? (
      <FormattedMessage
        id="org-onboarding-modal-title-existing"
        defaultMessage="Edit Organisation Onboarding"
        description="Title for a modal to edit existing organisation onboarding"
      />
    ) : (
      <FormattedMessage
        id="org-onboarding-modal-title-new"
        defaultMessage="Add Organisation Onboarding"
        description="Title for a modal to add a new organisation onboarding"
      />
    );
  }

  renderBody() {
    return (
      <div className={style.form}>
        {this.renderTextInput('mutableUsername')}
        {this.renderTextInput('userEmail')}
        {this.renderManagerDropdown()}
      </div>
    );
  }

  renderSubmit() {
    const { mutableUsername, userEmail, managerEmail } = this.state;
    const isSubmitEnabled =
      !this.props.isLoading &&
      Boolean(mutableUsername.trim()) &&
      Boolean(userEmail.trim()) &&
      Boolean(managerEmail.trim());

    return (
      <SDSButton
        type={ButtonType.PRIMARY}
        onClick={this.handleOnSubmit}
        data-test="snapadmin.components.orgonboardingmodal.submit"
        disabled={!isSubmitEnabled}
      >
        <FormattedMessage
          id="org-onboarding-modal-submit"
          defaultMessage="Submit"
          description="Button to submit and create a new organisation onboarding"
        />
      </SDSButton>
    );
  }

  renderDelete() {
    const isDeleteEnabled = !this.props.isLoading;
    return (
      <SDSButton
        type={ButtonType.PRIMARY}
        onClick={this.handleOnDelete}
        data-test="snapadmin.components.orgonboardingmodal.delete"
        disabled={!isDeleteEnabled}
      >
        <FormattedMessage
          id="org-onboarding-modal-delete"
          defaultMessage="Delete"
          description="Button to delete an existing organisation onboarding"
        />
      </SDSButton>
    );
  }

  renderExistingOnboardingButtons() {
    return <>{this.renderDelete()}</>;
  }

  renderNewOnboardingButtons() {
    return <>{this.renderSubmit()}</>;
  }

  renderFooter() {
    return this.isEditingExisting() ? this.renderExistingOnboardingButtons() : this.renderNewOnboardingButtons();
  }

  render() {
    return (
      <SDSCustomModal
        visible
        title={this.renderHeader()}
        footer={this.renderFooter()}
        onClose={this.handleOnHide}
        data-test="orgOnboarding.modal"
      >
        {this.renderBody()}
      </SDSCustomModal>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(OrgOnboardingModal);
