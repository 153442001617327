import classNames from 'classnames';
import React from 'react';

import { CrossOrigin } from 'config/constants';

import LineLimitedContentEditable from 'views/common/components/LineLimitedContentEditable/LineLimitedContentEditable';
import { Spinner, SpinnerSizes } from 'views/common/components/Spinner/Spinner';

import style from './AnalyticsV2ImagePreview.scss';

type Props = {
  mediaUrl?: string;
  videoUrl?: string;
  seekPoint?: string;
  logoUrl?: string;
  title?: string;
};

export function AnalyticsV2ImagePreview({ mediaUrl, videoUrl, seekPoint, logoUrl, title }: Props) {
  const renderTileLogo = (url: string) => {
    const iconClass = classNames(style.publisherIcon, style.top);
    return (
      <img
        src={url}
        alt=""
        crossOrigin={CrossOrigin.NONE}
        className={iconClass}
        data-test="cheetahTilePreview.dummyLogo"
      />
    );
  };

  const renderTileTitle = (tileTitle: string) => {
    const iconClass = classNames(style.publisherIcon, style.bottom);
    const LINE_LIMIT = 3;
    const CHAR_LIMIT = 200;
    return (
      <div className={classNames(style.headline, 'tile-headline-override')}>
        <LineLimitedContentEditable
          text={tileTitle}
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          className={iconClass}
          lineLimit={LINE_LIMIT}
          maxLength={CHAR_LIMIT}
          disabled
        />
      </div>
    );
  };

  if (!mediaUrl) {
    return (
      <div className={style.previewContainer}>
        <Spinner loading size={SpinnerSizes.LARGE} />
      </div>
    );
  }

  return (
    <div className={style.root}>
      <div className={style.previewContainer}>
        <img className={style.image} src={mediaUrl} alt="Preview" data-test="AnalyticsV2ImagePreview.Image" />
      </div>
      {renderTileLogo(logoUrl || '')}
      {renderTileTitle(title || '')}
    </div>
  );
}

export default AnalyticsV2ImagePreview;
