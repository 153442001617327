import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import { Column, SortDirection } from 'react-virtualized';

import * as analyticsActions from 'state/analytics/actions/analyticsActions';
import * as storiesAnalyticsSelectors from 'state/analytics/selectors/storiesAnalyticsSelectors';
import type { AuthState } from 'state/auth/authState';
import { getSnapPreviews } from 'state/previews/selectors/previewsSelectors';
import { openSnapEditor } from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { getActivePublisher, userId } from 'state/user/selectors/userSelectors';

import type {
  CheetahStoriesAnalyticsSortableColumnsEnum,
  CheetahStoryStateEnum,
  SortDirectionEnum,
} from 'config/constants';
import { CheetahStoriesAnalyticsSortableColumns, CheetahStoryState, CrossOrigin } from 'config/constants';
import { pencil } from 'icons/SDS/allIcons';
import tooltipStyle from 'styles/tooltip.scss';
import { intlConnect } from 'utils/connectUtils';
import { isMomentStartOfEpoch } from 'utils/dateUtils';
import { getLocalisedMessageFromId, getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as assetUtils from 'utils/media/assetUtils';
import { privacyNumberFormat, printSeconds, formatTimeViewed } from 'utils/numberFormatter';
import { buildBitmojiImageUrl } from 'utils/tileUtils';

import MultiSelectPopover from 'views/common/components/MultiSelectPopover/MultiSelectPopover';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import TableWithCustomFilter from 'views/common/components/TableWithCustomFilter/TableWithCustomFilter';
import { StoryStatusLabel } from 'views/homepage/components/StoryStatusLabel/StoryStatusLabel';
import availabilityMessages, {
  formatWithDateAndTime,
  formatWithDate,
} from 'views/homepage/containers/AvailabilityTime/AvailabilityTime.messages';

import style from './CheetahAnalyticsStoriesView.scss';

import type { CheetahAnalyticsStories, CheetahAnalyticsStory } from 'types/analytics';
import type { SnapId } from 'types/common';
import type { ActivePublisher } from 'types/publishers';
import type { State } from 'types/rootState';

type RowIndex = {
  index: number;
};
type RowEntry = {
  rowData: CheetahAnalyticsStory;
  rowIndex: number;
};
type SortingSetting = {
  sortBy: string;
  sortDirection: SortDirectionEnum;
};
type StateProps = {
  match: unknown; // TODO flow: this is coming from react-router-dom, but can't seem to import it?,
  activePublisher: ActivePublisher;
  auth: AuthState;
  stories: CheetahAnalyticsStories;
  storiesV2: CheetahAnalyticsStories;
  isAnalyticsV2Enabled: boolean;
  isStoriesLoading: boolean;
  storiesLoading: number;
  userId: string;
  isLoadingStories: boolean;
  isStoriesV2Loading: boolean;
  primaryLanguage: string;
  sortedColumn: CheetahStoriesAnalyticsSortableColumnsEnum;
  sortDirection: SortDirectionEnum;
  snapPreviews: {
    [key in SnapId]: string;
  };
};
type DispatchProps = {
  goToStoryAnalytics: typeof routerActions.goToStoryAnalytics;
  sortStories: typeof analyticsActions.sortStories;
  setSortOrder: typeof analyticsActions.setSortOrder;
  openSnapEditor: typeof openSnapEditor;
};
type OwnState = {
  stories: CheetahAnalyticsStories;
  allStatuses: string[];
  selectedStatuses: string[];
  sortBy: string;
};
type Props = StateProps & DispatchProps;
const mapStateToProps = (state: State) => {
  const activePublisher = getActivePublisher(state);
  const isLoadingStories = state.analytics.storyListLoading > 0;
  const primaryLanguage = publishersSelectors.getActivePublisherPrimaryLanguage(state);
  const sortedColumn = storiesAnalyticsSelectors.getSortedColumn(state);
  const sortDirection = storiesAnalyticsSelectors.getSortOrder(state);
  const snapPreviews = getSnapPreviews(state);
  return {
    stories: storiesAnalyticsSelectors.getSortedAllStories(state),
    storiesLoading: state.analytics.storyListLoading,
    auth: state.auth,
    activePublisher,
    userId: userId(state),
    isLoadingStories,
    primaryLanguage,
    sortedColumn,
    sortDirection,
    snapPreviews,
  };
};
const mapDispatchToProps = {
  goToStoryAnalytics: routerActions.goToStoryAnalytics,
  sortStories: analyticsActions.sortStories,
  setSortOrder: analyticsActions.setSortOrder,
  openSnapEditor,
};
const cheetahStatusIdToMessage: {
  [key in CheetahStoryStateEnum]: string;
} = {
  [CheetahStoryState.AVAILABLE]: 'available-status',
  [CheetahStoryState.UNAVAILABLE]: 'unavailable-status',
};
const tableColumnToSortableColumn: {
  [key: string]: CheetahStoriesAnalyticsSortableColumnsEnum;
} = {
  availability: CheetahStoriesAnalyticsSortableColumns.AVAILABILITY,
  status: CheetahStoriesAnalyticsSortableColumns.STATUS,
  subscribers: CheetahStoriesAnalyticsSortableColumns.SUBSCRIBERS,
  'time-viewed': CheetahStoriesAnalyticsSortableColumns.TIME_VIEWED,
  'unique-viewers': CheetahStoriesAnalyticsSortableColumns.UNIQUE_VIEWERS,
};
const tableColumnToSortableColumnV2: {
  [key: string]: string;
} = {
  availability: 'postTime',
  status: 'subscribers',
  subscribers: 'subscribers',
  'time-viewed': 'avgTimeViewed',
  'unique-viewers': 'uniqueDau',
};
export class CheetahAnalyticsStoriesView extends React.Component<Props, OwnState> {
  static path = '/publisher/:publisherId/analytics/stories';

  static contextTypes = {
    intl: intlShape,
  };

  static toggleIcons = {
    true: <div className={`icon icon-filter ${style.filterSelected}`} />,
    false: <div className="icon icon-filter" />,
  };

  state = {
    stories: this.props.isAnalyticsV2Enabled ? this.props.storiesV2 : this.props.stories,
    allStatuses: [],
    selectedStatuses: [],
    sortBy: this.props.sortedColumn,
  };

  UNSAFE_componentWillMount() {
    this.updateStatuses(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const stories = nextProps.isAnalyticsV2Enabled ? nextProps.storiesV2 : nextProps.stories;
    if (nextProps.stories !== this.props.stories) {
      this.setState({
        stories: this.filterStories(this.state.selectedStatuses, stories),
      });
    } else if (this.props.stories.length === 0) {
      this.setState({
        stories,
      });
    }
  }

  getRowStyle = ({ index }: RowIndex) => {
    if (this.isLoadingRow(index) || index < 0) {
      return style.headerRow;
    }
    return classNames(style.tableRow, { [style.clickable]: true });
  };

  getSortDirection = (current: string, shouldSwitch: boolean) => {
    if (shouldSwitch) {
      return current === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    }
    return current;
  };

  async updateStatuses(props: Props) {
    const allStatuses: string[] = [CheetahStoryState.AVAILABLE, CheetahStoryState.UNAVAILABLE].map(storyState =>
      this.cheetahStoryStatusIdToMessage(props, storyState)
    );
    this.setState({ allStatuses, selectedStatuses: allStatuses });
  }

  cheetahStoryStatusIdToMessage = (props: Props, statusId: CheetahStoryStateEnum) => {
    const messageId = cheetahStatusIdToMessage[statusId];
    return messageId ? getLocalisedMessageFromId(this.context, messageId) : '';
  };

  sort = ({ sortBy, sortDirection }: SortingSetting) => {
    // the filter column doesn't really sort but it seems weird to switch
    // from filtering to sorting and not see a change in direct
    const switchSortDirection = this.state.sortBy !== sortBy;
    this.setState({
      sortBy,
    });
    const sortByColumn = tableColumnToSortableColumn[sortBy]!;
    const newSortDirection = this.getSortDirection(sortDirection, switchSortDirection);

    if (this.props.isAnalyticsV2Enabled && sortByColumn != null) {
      const stories = this.state.stories;
      const sortByColumnV2 = tableColumnToSortableColumnV2[sortBy]!;
      const sortedStories = stories.sort((a, b) => {
        // @ts-ignore
        const aVal = a.metadata[sortByColumnV2] || a.stats[sortByColumnV2];

        // @ts-ignore
        const bVal = b.metadata[sortByColumnV2] || b.stats[sortByColumnV2];
        if (aVal < bVal) {
          return newSortDirection === SortDirection.ASC ? -1 : 1;
        }
        if (aVal > bVal) {
          return newSortDirection === SortDirection.ASC ? 1 : -1;
        }
        return 0;
      });

      this.setState({
        stories: sortedStories,
      });
      return;
    }

    this.props.setSortOrder(newSortDirection);
    this.props.sortStories(sortByColumn);
  };

  rowGetter = (row: RowIndex) => {
    if (this.isLoadingRow(row.index)) {
      return {};
    }
    return this.state.stories[row.index];
  };

  goToStoryAnalytics = (row: RowIndex) => {
    if (this.isLoadingRow(row.index)) {
      return;
    }
    const story = this.rowGetter(row);
    const storyId = (story as any).metadata.editionId;
    const publisherId = parseInt(_.get(this.props, ['match', 'params', 'publisherId']), 10);
    this.props.goToStoryAnalytics({ publisherId, editionId: storyId });
  };

  isLoadingRow = (index: number) => {
    if (this.props.isAnalyticsV2Enabled) {
      return this.props.isStoriesV2Loading && index >= this.props.storiesV2.length;
    }
    return this.props.isLoadingStories && index >= this.state.stories.length;
  };

  filterStories = (selectedStatuses: string[], stories: CheetahAnalyticsStories) => {
    const selectedStatusesSet = new Set(selectedStatuses);
    return _.filter(stories, story => {
      const state = storiesAnalyticsSelectors.getAvailability(story);
      return selectedStatusesSet.has(this.cheetahStoryStatusIdToMessage(this.props, state));
    });
  };

  changeStatusFilter = async (selectedStatuses: string[]) => {
    this.setState({
      selectedStatuses,
      stories: this.filterStories(selectedStatuses, this.props.stories),
    });
  };

  storyNameHeaderRenderer = () => {
    return (
      <div className={style.columnHeader}>
        <FormattedMessage
          id="story-name-column-header"
          description="Column Header with label Story on stories analytics table"
          defaultMessage="Story Name"
        />
      </div>
    );
  };

  arrowRenderer = () => {
    if (this.props.sortDirection === SortDirection.ASC) {
      return <div className={`icon icon-arrow-up ${style.caret}`} />;
    }
    return <div className={`icon icon-arrow-down ${style.caret}`} />;
  };

  availabilityRenderer = () => {
    return (
      <div className={style.columnHeader}>
        <div className={style.columnMessage}>
          <FormattedMessage
            id="analytics-availability-column-header"
            description="Column Header with label Availability on stories analytics table"
            defaultMessage="Availability"
          />
        </div>
        {this.arrowRenderer()}
      </div>
    );
  };

  uniqueViewersRenderer = () => {
    return (
      <div className={style.columnHeader}>
        <div className={style.columnMessage}>
          <FormattedMessage
            id="analytics-unique-viewers-column-header"
            description="Column Header with label Unique Viewers on stories analytics table"
            defaultMessage="Unique Viewers"
          />
        </div>
        {this.arrowRenderer()}
      </div>
    );
  };

  timeViewedRenderer = () => {
    return (
      <div className={style.columnHeader}>
        <div className={style.columnMessage}>
          <FormattedMessage
            id="analytics-time-viewed-column-header"
            description="Column Header with label Time Viewed on stories analytics table"
            defaultMessage="Time Viewed"
          />
        </div>
        {this.arrowRenderer()}
      </div>
    );
  };

  subscribersRenderer = () => {
    return (
      <div className={style.columnHeader}>
        <div className={style.columnMessage}>
          <FormattedMessage
            id="analytics-followers-column-header"
            description="Column Header with label Followers on stories analytics table"
            defaultMessage="Followers"
          />
        </div>
        {this.arrowRenderer()}
      </div>
    );
  };

  statusHeaderRenderer = () => {
    return (
      <div className={style.headerStyle}>
        <MultiSelectPopover
          selectedItems={this.state.selectedStatuses}
          allValues={this.state.allStatuses}
          style={classNames(style.popoverHeader, style.multiSelectPopover)}
          statusLabelId="status-column-header"
          hideSelectedItems
          id="status"
          onChange={this.changeStatusFilter}
          toggleIcons={CheetahAnalyticsStoriesView.toggleIcons}
        />
      </div>
    );
  };

  renderSnapPreview = (snapId: SnapId) => {
    return (
      <div className={style.snapPreviewContainer}>
        <img src={this.props.snapPreviews[snapId]} crossOrigin={CrossOrigin.USE_CREDENTIALS} alt="" />
      </div>
    );
  };

  renderTile = ({ rowData, rowIndex }: RowEntry) => {
    if (this.isLoadingRow(rowIndex)) {
      return <SpinnerIcon className={style.spinnerIcon} />;
    }
    let tileRender;
    const { metadata } = rowData;

    if (this.props.isAnalyticsV2Enabled) {
      const { tileImageURL } = metadata;
      return <img className={style.tile} src={tileImageURL} alt="" />;
    }

    const { tileId } = metadata;
    if (tileId) {
      const bitmojiTileTemplateId = _.get(metadata, 'tileCover.bitmojiTileTemplateId') || null;
      const tileImageAssetId = _.get(metadata, 'tileCover.croppedImageAssetId') || null;
      const croppedImage = bitmojiTileTemplateId
        ? buildBitmojiImageUrl(bitmojiTileTemplateId)
        : assetUtils.getImagePreviewUrl(tileImageAssetId);

      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      tileRender = <img className={style.tile} src={croppedImage} crossOrigin={CrossOrigin.USE_CREDENTIALS} alt="" />;
    } else {
      tileRender = <div className={style.tilePlaceholder} />;
    }
    return tileRender;
  };

  renderStoryName = ({ rowData, rowIndex }: RowEntry) => {
    if (this.isLoadingRow(rowIndex)) {
      return (
        <div className={style.loadingMessage}>
          <FormattedMessage
            id="loading-story-analytics"
            description="Message indicating that stories are loading in the story analytics"
            defaultMessage="Loading stories..."
          />
        </div>
      );
    }
    const story = rowData;
    const numSnaps = rowData.metadata.storySnapsCount;
    const numSnapsMessage = (
      <FormattedMessage
        id="num-snaps-on-story"
        description="Displays the number of snaps in a story"
        defaultMessage={'{numSnaps} snaps'}
        values={{ numSnaps }}
      />
    );
    return (
      <div>
        <div className={style.storyTitle}>
          <div>{story.metadata.headline}</div>
        </div>
        <div className={style.storySnapsCount}>{numSnapsMessage}</div>
      </div>
    );
  };

  renderAvailabilityText = ({ rowData, rowIndex }: RowEntry) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    const startDate = rowData.metadata.postTime;
    // Some archive stories have the start of the epoch as their start date
    // In their case, we do not display their start date as a relative time.
    if (!startDate || isMomentStartOfEpoch(startDate)) {
      return <div className={style.cellText}>---</div>;
    }
    return (
      <SDSTooltip
        title={formatWithDateAndTime(availabilityMessages.tooltipStoryTimeAvailable, startDate)}
        data-basetime={Date.parse(startDate.format())}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP_LEFT}
        overlayClassName={tooltipStyle.tooltip}
      >
        <span>{formatWithDate(availabilityMessages.storyTimeAvailable, startDate)}</span>
      </SDSTooltip>
    );
  };

  renderUniqueViewersText = ({ rowData, rowIndex }: RowEntry) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    return <div className={style.cellText}>{privacyNumberFormat(rowData.stats.uniqueDau)}</div>;
  };

  renderTimeViewedText = ({ rowData, rowIndex }: RowEntry) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    const timeViewed = rowData.stats.avgTimeViewed;

    if (this.props.isAnalyticsV2Enabled) {
      return <div className={style.cellText}>{formatTimeViewed(timeViewed)}</div>;
    }

    return <div className={style.cellText}>{printSeconds(timeViewed)}</div>;
  };

  renderSubscribersText = ({ rowData, rowIndex }: RowEntry) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    return <div className={style.cellText}>{privacyNumberFormat(rowData.stats.editionSubscribeCount)}</div>;
  };

  renderStatus = ({ rowData, rowIndex }: RowEntry) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }

    if (this.props.isAnalyticsV2Enabled) {
      return (
        <StoryStatusLabel
          status={CheetahStoryState.AVAILABLE}
          statusIdToMessageId={cheetahStatusIdToMessage}
          isAnalytics
        />
      );
    }
    const status = storiesAnalyticsSelectors.getAvailability(rowData);
    return <StoryStatusLabel status={status} statusIdToMessageId={cheetahStatusIdToMessage} isAnalytics />;
  };

  viewStoryButtonHandler = _.memoize(
    (publisherId, storyId, snapId) => () => {
      this.props.openSnapEditor({
        publisherId,
        editionId: storyId,
        snapId,
        overwriteHistory: false,
      });
    },
    (publisherId, storyId, snapId) => `${publisherId},${storyId},${snapId}`
  );

  renderViewStoryButton = ({ rowData, rowIndex }: RowEntry) => {
    const publisherId = parseInt(_.get(this.props, ['match', 'params', 'publisherId']), 10);
    const { metadata } = rowData;
    const { editionId, firstSnapId } = metadata;
    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        inlineIcon={pencil}
        onClick={this.viewStoryButtonHandler(publisherId, editionId, firstSnapId)}
        data-test="analytics.stories.viewStory.button"
      >
        {getMessageFromId('view-story-button')}
      </SDSButton>
    );
  };

  render() {
    const { stories } = this.state;
    const rowCount = stories.length;
    return (
      <TableWithCustomFilter
        ref="Table"
        rowGetter={this.rowGetter}
        rowCount={rowCount}
        rowHeight={96}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        sort={this.sort}
        onRowClick={this.goToStoryAnalytics}
        rowClassName={this.getRowStyle}
        sortBy={this.state.sortBy}
        sortDirection={this.props.sortDirection}
      >
        <Column style={{ marginRight: '0px' }} disableSort dataKey="tiles" width={56} cellRenderer={this.renderTile} />
        <Column
          headerRenderer={this.storyNameHeaderRenderer}
          disableSort
          dataKey="title"
          flexGrow={1}
          minWidth={200}
          width={400}
          cellRenderer={this.renderStoryName}
        />
        <Column
          headerRenderer={this.statusHeaderRenderer}
          disableSort
          dataKey="status"
          flexGrow={1}
          width={100}
          minWidth={80}
          maxWidth={220}
          cellRenderer={this.renderStatus}
        />
        <Column
          headerRenderer={this.availabilityRenderer}
          dataKey="availability"
          flexGrow={1}
          width={100}
          minWidth={80}
          maxWidth={160}
          cellRenderer={this.renderAvailabilityText}
        />
        <Column
          headerRenderer={this.uniqueViewersRenderer}
          dataKey="unique-viewers"
          flexGrow={1}
          width={100}
          minWidth={80}
          maxWidth={160}
          cellRenderer={this.renderUniqueViewersText}
        />
        <Column
          headerRenderer={this.timeViewedRenderer}
          dataKey="time-viewed"
          flexGrow={1}
          width={100}
          minWidth={80}
          maxWidth={160}
          cellRenderer={this.renderTimeViewedText}
        />
        <Column
          headerRenderer={this.subscribersRenderer}
          dataKey="subscribers"
          flexGrow={1}
          width={100}
          minWidth={80}
          maxWidth={160}
          cellRenderer={this.renderSubscribersText}
        />
      </TableWithCustomFilter>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(CheetahAnalyticsStoriesView);
