import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { GetRevenue, GetRevenueVariables } from 'gql/queries/analytics/__ssp-generated__/GetRevenue';
import { getFormattedFullTimestampString } from 'utils/dateUtils';
import { printDollars, privacyNumberFormat } from 'utils/numberFormatter';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTable from 'views/common/components/SDSTable/SDSTable';

import style from './RevenueTable.scss';

import { BundlesRevenue } from 'types/analytics';
import { FetchMoreFunc } from 'types/apollo';

const COLUMN_WIDTH = 40;
const REVENUE_TABLE_COLUMNS = [
  {
    title: (
      <FormattedMessage
        id="revenue-table-name-column"
        defaultMessage="Name"
        description="Table header for name column in revenue table"
      />
    ),
    dataIndex: 'name',
    key: 'name',
    width: COLUMN_WIDTH,
  },
  {
    title: (
      <FormattedMessage
        id="revenue-table-launch-date-column"
        defaultMessage="Launch Date"
        description="Table header for launch date column in revenue table"
      />
    ),
    dataIndex: 'launchDate',
    key: 'launchDate',
    width: COLUMN_WIDTH,
  },
  {
    title: (
      <FormattedMessage
        id="revenue-table-revenue-column"
        defaultMessage="Revenue"
        description="Table header for revenue column in revenue table"
      />
    ),
    dataIndex: 'revenue',
    key: 'revenue',
    width: COLUMN_WIDTH,
    render: printDollars,
  },
  {
    title: (
      <FormattedMessage
        id="revenue-table-impressions-column"
        defaultMessage="Impressions"
        description="Table header for impressions column in revenue table"
      />
    ),
    dataIndex: 'impressions',
    key: 'impressions',
    width: COLUMN_WIDTH,
    render: privacyNumberFormat,
  },
  {
    title: (
      <FormattedMessage
        id="revenue-table-ecpm-column"
        defaultMessage="eCPM"
        description="Table header for eCPM column in revenue table"
      />
    ),
    dataIndex: 'ecpm',
    key: 'ecpm',
    width: COLUMN_WIDTH,
    render: printDollars,
  },
] as const;

// Helper types to make sure underlying data type is correct.
type Columns = typeof REVENUE_TABLE_COLUMNS[number]['key'];
type Row = { [column in Columns]: string | number | undefined };
type Record = Row & { key: string | undefined };

type Props = {
  bundlesRevenue: BundlesRevenue;
  pageSize: number;
  loading: boolean;
  fetchMore: FetchMoreFunc<GetRevenue, GetRevenueVariables>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
};

export function RevenueTable({ bundlesRevenue, loading, fetchMore, pageSize, currentPage, setCurrentPage }: Props) {
  // Transform bundle revenue edges into table data source.
  const edges = (bundlesRevenue?.edges || []).filter(edge => !!edge?.node?.creationBundle);

  const records: Record[] = edges.map(edge => ({
    key: edge?.node?.id,
    name: edge?.node?.creationBundle?.title,
    launchDate: getFormattedFullTimestampString(edge?.node?.creationBundle?.firstLiveDate),
    revenue: edge?.node?.summary.revenue,
    impressions: edge?.node?.summary.impressions,
    ecpm: edge?.node?.summary.ecpm,
  }));

  // Select pageSize records based on the currently selected page.
  const dataSource = records.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

  // Pagination can be local from already loaded items or may require additional fetch.
  const pageInfo = bundlesRevenue?.pageInfo;
  const hasPreviousPage = currentPage > 0;
  // Next page data can be already loaded if we navigated back to a previous page.
  const hasNextPageLoaded = (currentPage + 1) * pageSize < records.length;
  const hasNextPage = hasNextPageLoaded || Boolean(pageInfo?.hasNextPage);

  const onPreviousClick = useCallback(() => {
    if (!hasPreviousPage) {
      return;
    }
    setCurrentPage(page => page - 1);
  }, [hasPreviousPage, setCurrentPage]);

  const onNextClick = useCallback(async () => {
    if (!hasNextPage) {
      return;
    }
    if (!hasNextPageLoaded) {
      await fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      });
    }
    setCurrentPage(page => page + 1);
  }, [hasNextPage, hasNextPageLoaded, setCurrentPage, fetchMore, pageInfo]);

  return (
    <div className={style.tableContainer}>
      <SDSTable columns={REVENUE_TABLE_COLUMNS} dataSource={dataSource} pagination={false} loading={loading} />
      <div className={style.pagination}>
        <SDSButton
          type={ButtonType.PRIMARY}
          disabled={!hasPreviousPage || loading}
          onClick={onPreviousClick}
          data-test="RevenueTable.Pagination.Previous"
        >
          <FormattedMessage
            id="revenue-table-pagination-previous"
            description="Revenue page navigation previous page button"
            defaultMessage="Previous page"
          />
        </SDSButton>
        <div data-test="RevenueTable.Pagination.Page" className={style.page}>
          <FormattedMessage
            id="revenue-table-pagination-page"
            defaultMessage="Page {page}"
            description="Page number in revenue table pagination"
            values={{ page: currentPage + 1 }}
          />
        </div>
        <SDSButton
          type={ButtonType.PRIMARY}
          disabled={!hasNextPage || loading}
          onClick={onNextClick}
          data-test="RevenueTable.Pagination.Next"
        >
          <FormattedMessage
            id="revenue-table-pagination-next"
            description="Revenue page navigation next page button"
            defaultMessage="Next page"
          />
        </SDSButton>
      </div>
    </div>
  );
}
