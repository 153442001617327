// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Col } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import _ from 'lodash';
import React from 'react';

import { getStoryRunTime, getStorySnapCount } from 'state/analytics/selectors/analyticsSelectors';
import { isAdvancedCurationEnabled } from 'state/features/selectors/featuresSelectors';
import { activePublisherIsShow } from 'state/publishers/selectors/publishersSelectors';

import { intlConnect } from 'utils/connectUtils';

import { getHiddenMetricsSet } from 'views/analytics/utils/analyticsUtil';
import {
  DEFAULT_EDITION_KPI_METRICS,
  makeEditionKpiConfig,
  makeEditionKpiConfigV2,
  SHOW_EDITION_KPI_METRICS,
} from 'views/analytics/utils/editionKpi';
import { EDITION_KPI_MAX_VALUE_CONFIG, EDITION_KPI_MAX_VALUE_CONFIG_V2 } from 'views/analytics/utils/kpiConfigs';
import KPIWidget from 'views/common/components/KPIWidget/KPIWidget';
import type { KPIMaxValue, KPIMetricInput } from 'views/common/components/KPIWidget/KPIWidget';

import type { StoryMetrics } from 'types/analytics';
import type { ActivePublisher } from 'types/publishers';
import type { State } from 'types/rootState';

type OwnProps = {
  activePublisher: ActivePublisher;
  showAdvancedAnalytics: boolean;
  storyAnalytics: StoryMetrics;
  useAnalyticsV2?: boolean;
  storySnapCountV2?: number;
  storyRuntimeV2?: number;
  uniqueSubscriberViewersV2?: string;
};

type StateProps = {
  storySnapCount: number;
  storyRuntime: number;
  isShow: boolean;
  isAdvancedCurationEnabled: boolean;
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  storyRuntime: getStoryRunTime(state),
  isShow: activePublisherIsShow(state),
  isAdvancedCurationEnabled: isAdvancedCurationEnabled(state),
  storySnapCount: getStorySnapCount(state),
});

export class AnalyticsStoryKPIs extends React.PureComponent<Props> {
  /*
Only for shows we display total time of story alongside avgTotalTimeViewed.
(we can't display for the rest because the avgTotalTimeViewed includes
 significant attachment view time which isn't bounded)
 */
  getMaxValue = (kpi: KPIMetricInput): KPIMaxValue | undefined | null => {
    const maxValueConfig = this.props.useAnalyticsV2
      ? // @ts-ignore
        EDITION_KPI_MAX_VALUE_CONFIG_V2[kpi.metricId]
      : // @ts-ignore
        EDITION_KPI_MAX_VALUE_CONFIG[kpi.metricId];
    const maxValueValue = this.getMaxValueValue(kpi.metricId);

    if (!maxValueConfig || maxValueValue === null || this.shouldSuppressMaxValue(maxValueConfig)) {
      return undefined;
    }

    return {
      ...maxValueConfig,
      value: maxValueValue,
    };
  };

  isStoryShowOrCuratedStory = (): boolean => {
    return this.props.isShow || this.props.isAdvancedCurationEnabled;
  };

  shouldSuppressMaxValue = (maxValueConfig: any): boolean => {
    const result = maxValueConfig.isVisibleOnlyForShows && !this.isStoryShowOrCuratedStory();

    return !!result;
  };

  getMaxValueValue = (metricId: string) => {
    switch (metricId) {
      case 'avgTotalTimeViewed':
        return this.props.useAnalyticsV2 ? this.props.storyRuntimeV2 : this.props.storyRuntime;
      case 'uniqueTopSnapViewPerUser':
        return this.props.useAnalyticsV2 ? this.props.storySnapCountV2 : this.props.storySnapCount;
      case 'uniqueDau':
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'StoryMetricResults | undefined' ... Remove this comment to see the full error message
        return parseFloat(this.props.storyAnalytics.uniqueSubscriberViewers);
      default:
        return null;
    }
  };

  render() {
    const { storyAnalytics, isShow } = this.props;
    const isLoading = _.isEmpty(storyAnalytics);
    const analyticsParams = {
      storyRuntime: this.props.useAnalyticsV2 ? this.props.storyRuntimeV2 : this.props.storyRuntime,
    };

    // Filter out KPIs we don't want to display, then for each filtered KPI find the associated
    // KPI value in the story analytics
    const hiddenMetricsSet = getHiddenMetricsSet(this.props);
    const kpiConfig = this.props.useAnalyticsV2
      ? makeEditionKpiConfigV2(isShow ? SHOW_EDITION_KPI_METRICS : DEFAULT_EDITION_KPI_METRICS)
      : makeEditionKpiConfig(isShow ? SHOW_EDITION_KPI_METRICS : DEFAULT_EDITION_KPI_METRICS);

    const filteredKpis = kpiConfig
      .filter(kpi => kpi && !hiddenMetricsSet.has(kpi.metricId))
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      .filter(kpi => kpi.findValue(storyAnalytics, analyticsParams));
    return filteredKpis.map(kpi => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const kpiValue = kpi.findValue(storyAnalytics, analyticsParams);
      // In story analytics we don't compare time ranges to get a delta (we only do for daily analytics KPIs),
      // therefore we pass null as the previousValue
      const previousValue = null;
      return (
        <Col xs={12} md={6} lg={3} key={`${kpi?.metricId}Column`} data-test={`${kpi?.metricId}Column`}>
          <KPIWidget
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            metric={kpi}
            previousValue={previousValue}
            value={kpiValue}
            isLoading={isLoading}
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ metricId: string; name: Elemen... Remove this comment to see the full error message
            maxValue={this.getMaxValue(kpi)}
          />
        </Col>
      );
    });
  }
}

export default intlConnect(mapStateToProps, {})(AnalyticsStoryKPIs);
