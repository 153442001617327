import classNames from 'classnames';
import { memoize, get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoSizer, Grid, InfiniteLoader } from 'react-virtualized';

import * as homepageActions from 'state/homepage/actions/homepageActions';
import * as homepageSelectors from 'state/homepage/selectors/homepageSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import { SortDirection } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import EditionTilePreview, {
  DEFAULT_EDITION_HEIGHT,
  DEFAULT_EDITION_WIDTH,
} from 'views/common/containers/EditionTilePreview/EditionTilePreview';

import style from './MobileHomepageView.scss';

import type { EditionID } from 'types/editionID';
import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import type { Tile } from 'types/tiles';
import type { RenderedSection, CellRendererParams, InfiniteLoaderIndexes } from 'types/virtualized';

type $Call1<F extends (...args: any) => any, A> = F extends (a: A, ...args: any) => infer R ? R : never;

// Insanely big number - If the data goes pass that number, infinite scroll will stop polling
const STORY_COUNT = 10000000;
export const CELL_SPACING = 10;
export const COLUMN_COUNT = 2;

const mapStateToProps = (state: State) => {
  return {
    publisherId: getActivePublisherId(state),
    stories: homepageSelectors.getOrderedStories(state) || [],
    selectedStates: homepageSelectors.getSelectedStates(state),
  };
};

const mapDispatchToProps = {
  getDisplayStories: homepageActions.getDisplayStories,
  goToPublisherStoryEditor: routerActions.goToPublisherStoryEditor,
};

type Props = {
  publisherId: PublisherID;
  stories: {
    story: Edition;
    tile: Tile | undefined | null;
  }[];
  selectedStates: StoryState[];
  getDisplayStories: $Call1<typeof homepageActions.getDisplayStories, {}>;
  goToPublisherStoryEditor: $Call1<typeof routerActions.goToPublisherStoryEditor, EditionID>;
};

export class MobileHomepageView extends React.Component<Props> {
  onSectionRendered = ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }: RenderedSection) => {
    const startIndex = rowStartIndex * COLUMN_COUNT + columnStartIndex;
    const stopIndex = rowStopIndex * COLUMN_COUNT + columnStopIndex;

    this._onRowsRendered({ startIndex, stopIndex });
  };

  getDisplayStories = (batchSize: number) => {
    const fetchOptions = {
      sortBy: homepageSelectors.HomepageSortBy.CREATED_AT,
      sortDirection: SortDirection.DESCENDING,
      state: this.props.selectedStates,
    };
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    return this.props.getDisplayStories({
      publisherId: this.props.publisherId,
      fetchOptions,
      batchSize,
      keepExistingStories: true,
    });
  };

  getEditionWidth = (cellWidth: number): number => {
    // Since the width of each cell needs to be set dynamically, we need to take into account
    // the space between two cells. So the actual editionWidth needs to be subtracted by
    // CELL_SPACING / 2, since this is by how much each cell will get shortened by the spacing.
    return cellWidth - CELL_SPACING / 2;
  };

  // The function here is necessary cause it informs the user that the user is scrolling, thus
  // more rows need to be loaded
  // @ts-expect-error ts-migrate(2564) FIXME: Property '_onRowsRendered' has no initializer and ... Remove this comment to see the full error message
  _onRowsRendered: (params: InfiniteLoaderIndexes) => void;

  loadMoreRows = ({ startIndex, stopIndex }: InfiniteLoaderIndexes) => {
    // Virtualized may suggest loading more rows if the user scrolls faster
    // However, we don't want to make requests for just 1 or 2 items either
    const suggestedBatchSize = stopIndex - startIndex;
    const batchSize = Math.max(suggestedBatchSize, homepageActions.DEFAULT_BATCH_SIZE);
    return this.getDisplayStories(batchSize);
  };

  isRowLoaded = ({ index }: { index: number }) => {
    return index < this.props.stories.length;
  };

  cellRenderer = ({ rowIndex, columnIndex, ...rest }: CellRendererParams) => {
    const storyIndex = rowIndex * COLUMN_COUNT + columnIndex;
    if (storyIndex >= this.props.stories.length) {
      return null;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'story' does not exist on type '{ story: ... Remove this comment to see the full error message
    const { story, tile } = this.props.stories[storyIndex];

    return this.rowRenderer({
      story,
      tile,
      columnIndex,
      ...rest,
    });
  };

  rowRenderer = ({
    story,
    tile,
    key,
    style: rowStyle,
    columnIndex,
  }: Partial<
    {
      story: Edition;
      tile: Tile | undefined | null;
    } & CellRendererParams
  >) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const width = this.getEditionWidth(rowStyle.width);
    const className = classNames(style.row, { [style.lastColumn]: columnIndex === COLUMN_COUNT - 1 });

    // We can't use CSS padding in the container or we will have a weird padding when scrolling up.
    // We increase the top by half of the CELL_SPACING because all cells already have a natural padding of
    // (CELL_SPACING / 2), thus to make it consistent we add another half to have exactly CELL_SPACING
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ top: number; height?: number | undefined; ... Remove this comment to see the full error message
      <div style={{ ...rowStyle, top: rowStyle.top + CELL_SPACING / 2 }} key={key}>
        {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Edition | undefined' is not assi... Remove this comment to see the full error message */}
        {this.renderEditionTilePreview(story, tile, width, className)}
      </div>
    );
  };

  goToEdition = (editionId: EditionID) => () => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    this.props.goToPublisherStoryEditor({
      publisherId: this.props.publisherId,
      editionId,
      overwriteHistory: false,
    });
  };

  renderEditionTilePreview = memoize(
    (edition: Edition, tile: Tile | undefined | null, width: number, className: string) => {
      return (
        <EditionTilePreview
          onClick={this.goToEdition(edition.id)}
          className={className}
          edition={edition}
          tile={tile}
          scaleToWidth={width}
        />
      );
    },
    (edition, tile) => `${edition.id}-${get(tile, 'id')}`
  );

  renderGrid = ({
    height,
    width,
    registerChild,
  }: {
    height: number;
    width: number;
    registerChild: (a?: Element | null) => void;
  }) => {
    const { stories } = this.props;
    // Since the width of the screen is dynamically set and we have no control over
    // how each edition will be rendered (Grid and InifniteLoader will render this dynamically)
    // we need to calculate the width of each cell on the fly. We know that we have exactly `COLUMN_COUNT`
    // cells per row, thus the width of each cell is equal to `width / COLUMN_COUNT`
    const cellWidth = width / COLUMN_COUNT;
    const editionWidth = this.getEditionWidth(cellWidth);

    // We also don't know the height of each cell, cause this will be scaled dynamically by the
    // the EditionTilePreview component. We know that the default width is equals to `DEFAULT_EDITION_WIDTH`
    // and that the width will scale to `editionWidth`, thus the default height `DEFAULT_EDITION_HEIGHT`
    // will scale by a scale factor that is equals to `cellWidth / DEFAULT_EDITION_WIDTH`.
    const scaleFactor = editionWidth / DEFAULT_EDITION_WIDTH;
    const editionHeight = DEFAULT_EDITION_HEIGHT * scaleFactor;

    // In order for us to have the proper vertical spacing between two cells of `CELL_SPACING`
    // we need to push down the cell bellow exactly by `CELL_SPACING`, thus the actual cell height
    // will be equal to `editionHeight + CELL_SPACING`.
    const cellHeight = editionHeight + CELL_SPACING;

    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <Grid
        ref={registerChild}
        stories={stories}
        rowCount={Math.ceil(stories.length / COLUMN_COUNT)}
        columnCount={COLUMN_COUNT}
        height={height}
        width={width}
        rowHeight={cellHeight}
        columnWidth={cellWidth}
        cellRenderer={this.cellRenderer}
        onSectionRendered={this.onSectionRendered}
      />
    );
  };

  renderGridWithAutosizer = (registerChild: (a?: Element | null) => void) => {
    return <AutoSizer>{({ height, width }) => this.renderGrid({ height, width, registerChild })}</AutoSizer>;
  };

  renderNoStoriesAvailable = () => {
    return (
      <div className={style.noStoriesContainer}>
        <div className={style.noStoriesTitle}>
          <FormattedMessage
            id="no-stories-available-text"
            description="Text to show that there are no stories available"
            defaultMessage="No stories available"
          />
        </div>
        <div className={style.noStoriesSubtitle}>
          <FormattedMessage
            id="no-stories-available-description"
            description="Description to show when there are no stories available"
            defaultMessage="You will be able to see in-depth analytics and on-phone previews for each of your stories"
          />
        </div>
      </div>
    );
  };

  render() {
    if (this.props.stories.length === 0) {
      return this.renderNoStoriesAvailable();
    }

    return (
      <div className={style.container}>
        <InfiniteLoader isRowLoaded={this.isRowLoaded} loadMoreRows={this.loadMoreRows} rowCount={STORY_COUNT}>
          {({ onRowsRendered, registerChild }) => {
            // onRowsRendered needs to be called by onSectionRendered to let InfiniteLoader knows that
            // the user is scrolling, thus it needs to load more data. This is declared here to avoid creating a new
            // wrapper function each time this component re-renders. This is according to their official documentation
            // https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md
            this._onRowsRendered = onRowsRendered;
            return this.renderGridWithAutosizer(registerChild);
          }}
        </InfiniteLoader>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(MobileHomepageView);
