import { gql } from '@apollo/client';

export const GET_REVENUE = gql`
  query GetRevenue($input: RevenueInput!, $first: Int, $after: String) {
    revenue(input: $input) {
      ... on Revenue {
        unfiltered {
          profileRevenue {
            aggregate {
              impressions
              revenue
              ecpm
            }
            timeSeries {
              timestamp
              summary {
                impressions
                revenue
                ecpm
              }
            }
          }
          bundlesRevenue(first: $first, after: $after) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              cursor
              node {
                id
                summary {
                  impressions
                  revenue
                  ecpm
                }
                creationBundle {
                  title
                  firstLiveDate
                }
              }
            }
          }
        }
      }
      ... on InvalidArgumentError {
        field
        message
      }
    }
  }
`;
