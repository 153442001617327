// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { DateRangePicker } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import moment from 'moment-timezone';
import React from 'react';
import 'react-select/scss/default.scss';
import { FormattedMessage } from 'react-intl';

import SDSCustomModal from '../../components/SDSCustomModal/SDSCustomModal';

import { cross } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './DateRangePickerModal.scss';

type DisabledDays = { before?: Date; after?: Date };

type Props = {
  onHide: () => void;
  onUpdate: (from?: moment.Moment | null, to?: moment.Moment | null) => void;
  from: moment.Moment | undefined | null;
  to: moment.Moment | undefined | null;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  shouldShowClearButton?: boolean;
  shortcuts?: Set<CalendarShortcut>;
  maxDateRangeDuration?: number;
};

type OwnState = {
  from: Date | undefined | null;
  to: Date | undefined | null;
  disabledDays?: DisabledDays[] | undefined | null;
};
export enum CalendarShortcut {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_SEVEN_DAYS = 'LAST_SEVEN_DAYS',
  LAST_THIRTY_DAYS = 'LAST_THIRTY_DAYS',
  LAST_CALENDAR_MONTH = 'LAST_CALENDAR_MONTH',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
}

export default class DateRangePickerModal extends React.Component<Props, OwnState> {
  /* eslint-disable react/sort-comp */

  static timeZone = moment.tz.guess();
  /* eslint-enable react/sort-comp */

  state = {
    from: this.props.from ? this.props.from.toDate() : null,
    to: this.props.to ? this.props.to.toDate() : null,
    disabledDays: null,
  };

  onSubmitClick = () => {
    // if the user only input one date, set from and to to the same value
    let { from } = this.state;
    let { to } = this.state;
    if (!from && to) {
      from = to;
    }
    if (!to && from) {
      to = from;
    }

    if (from && to) {
      this.props.onUpdate(moment(from), moment(to));
      this.props.onHide();
    }
  };

  onClearClick = () => {
    this.props.onUpdate(null, null);
    this.props.onHide();
  };

  handleChange = (date: [Date | undefined | null, Date | undefined | null]) => {
    this.setState({ from: date[0], to: date[1] });

    const from = date[0];
    const to = date[1];
    const maxDateRangeDuration = this.props.maxDateRangeDuration;

    if (!maxDateRangeDuration || maxDateRangeDuration <= 0) {
      return;
    }

    if (from && !to) {
      // When only the start date is selected, disable all dates more than maxDateRangeDuration days from start
      const maxDate = moment(from).add(maxDateRangeDuration, 'd').toDate();
      const minDate = moment(from).subtract(maxDateRangeDuration, 'd').toDate();
      this.setState({ disabledDays: [{ after: maxDate }, { before: minDate }] });
    } else if (to && !from) {
      // When only the end date is selected, disable all dates more than maxDateRangeDuration days before end
      const maxDate = moment(to).add(maxDateRangeDuration, 'd').toDate();
      const minDate = moment(to).subtract(maxDateRangeDuration, 'd').toDate();
      this.setState({ disabledDays: [{ before: minDate }, { after: maxDate }] });
    } else {
      // When both dates are selected or no date is selected, don't disable any dates
      this.setState({ disabledDays: [] });
    }
  };

  generateShortcuts = () => {
    const ONEDAY = 24 * 60 * 60 * 1000;
    const today = new Date();

    const yesterday = new Date();
    yesterday.setTime(today.getTime() - ONEDAY);
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setTime(today.getTime() - ONEDAY * 6);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setTime(today.getTime() - ONEDAY * 29);
    const lastCalendarMonthStart = new Date();
    lastCalendarMonthStart.setMonth(today.getMonth() - 1, 1);
    const lastCalendarMonthEnd = new Date();
    lastCalendarMonthEnd.setMonth(today.getMonth(), 0);
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setTime(today.getTime() - ONEDAY * 89);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);
    const lastYear = new Date();
    lastYear.setFullYear(today.getFullYear() - 1);

    return [
      { name: CalendarShortcut.TODAY, label: 'Today', dateRange: [today, today] },
      { name: CalendarShortcut.YESTERDAY, label: 'Yesterday', dateRange: [yesterday, yesterday] },
      { name: CalendarShortcut.LAST_SEVEN_DAYS, label: 'Last 7 Days', dateRange: [sevenDaysAgo, today] },
      { name: CalendarShortcut.LAST_THIRTY_DAYS, label: 'Last 30 Days', dateRange: [thirtyDaysAgo, today] },
      {
        name: CalendarShortcut.LAST_CALENDAR_MONTH,
        label: 'Last Calendar Month',
        dateRange: [lastCalendarMonthStart, lastCalendarMonthEnd],
      },
      { name: CalendarShortcut.LAST_THREE_MONTHS, label: 'Last 3 Months', dateRange: [threeMonthsAgo, today] },
      { name: CalendarShortcut.LAST_SIX_MONTHS, label: 'Last 6 Months', dateRange: [sixMonthsAgo, today] },
      { name: CalendarShortcut.LAST_YEAR, label: 'Last Year', dateRange: [lastYear, today] },
      { name: CalendarShortcut.CUSTOM, label: 'Custom', dateRange: [null, null] },
    ];
  };

  renderCalendarPicker = () => {
    const { from, to } = this.state;
    const shortcuts = this.generateShortcuts();
    return (
      <DateRangePicker
        className={style.datePicker}
        defaultValue={[from, to]}
        value={[from, to]}
        onChange={this.handleChange}
        minDate={this.props.minDate?.toDate() ?? new Date('2015-01-01')}
        maxDate={this.props.maxDate?.toDate() ?? new Date()}
        dayPickerProps={
          this.props.maxDateRangeDuration
            ? {
                disabledDays: this.state.disabledDays,
              }
            : {}
        }
        shortcuts={
          this.props.shortcuts ? shortcuts.filter(shortcut => this.props.shortcuts?.has(shortcut.name)) : shortcuts
        }
      />
    );
  };

  renderSubmitButton = () => {
    return (
      <div className={style.buttonContainer}>
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={this.onSubmitClick}
          data-test="common.dateRangePicker.modal.apply.button"
        >
          {getMessageFromId('date-range-picker-apply-button-text')}
        </SDSButton>
      </div>
    );
  };

  renderClearButton = () => {
    return (
      <SDSButton
        type={ButtonType.WHITE}
        inlineIcon={cross}
        onClick={this.onClearClick}
        data-test="common.dateRangePicker.modal.clear.button"
      >
        {getMessageFromId('date-range-picker-clear-button-text')}
      </SDSButton>
    );
  };

  render() {
    return (
      <SDSCustomModal
        visible
        onClose={this.props.onHide}
        title={
          <FormattedMessage
            id="date-range-picker-text"
            defaultMessage="Select date"
            description="Title of the date range picker modal"
          />
        }
        footer={
          <>
            {this.renderSubmitButton()}
            {this.props.shouldShowClearButton && (this.props.from || this.props.to) ? this.renderClearButton() : null}
          </>
        }
        width={970}
        data-test="dateRangePickerModal."
      >
        {this.renderCalendarPicker()}
      </SDSCustomModal>
    );
  }
}
