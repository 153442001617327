import { Input } from 'antd/lib';
import classNames from 'classnames';
// eslint-disable-next-line import/order
import React, { ChangeEventHandler, FocusEventHandler } from 'react';

const { TextArea } = Input;
import type { ReactNode, ChangeEvent } from 'react';

import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

import style from './SDSTextArea.scss';

type Props = {
  labelTitle?: ReactNode;
  placeholder?: ReactNode;
  value?: string;
  defaultValue?: string;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  'data-test': string;
  disabled?: boolean;
  disableResize?: boolean;
};

export type OwnState = {
  inputValue?: string | null;
};

class SDSTextArea extends React.Component<Props, OwnState> {
  state = {
    inputValue: null,
  };

  onChangeHandler = (event: ChangeEvent<EventTarget>) => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    const changeEvent = event as ChangeEvent<HTMLTextAreaElement>;
    const inputValue = changeEvent.target.value;
    this.setState({ inputValue });

    onChange(changeEvent);
  };

  getInputValue = () => {
    const { value } = this.props;
    return this.state.inputValue ?? value;
  };

  renderInput() {
    const { placeholder, maxLength, disabled, onBlur, defaultValue } = this.props;
    return (
      <TextArea
        className={style.input}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placeholder={placeholder}
        onChange={this.onChangeHandler}
        onBlur={onBlur}
        data-test={`${this.props['data-test']}.SDSTextArea`}
        value={this.getInputValue()}
        defaultValue={defaultValue}
        maxLength={maxLength}
        disabled={disabled}
      />
    );
  }

  render() {
    const { labelTitle, disableResize } = this.props;
    return (
      <div className={classNames(style.parent, { [style.disableResize]: disableResize })}>
        {labelTitle ? (
          <SDSLabel data-test={`${this.props['data-test']}.label`} labelTitle={labelTitle}>
            {this.renderInput()}
          </SDSLabel>
        ) : (
          this.renderInput()
        )}
      </div>
    );
  }
}

export default SDSTextArea;
