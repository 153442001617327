import is from 'is_js';
import { memoize } from 'lodash';
import moment from 'moment-timezone';

import { DAILY_KPI_V3_CONFIG } from 'views/analytics/utils/kpiConfigs';

import type { Kpis, DailyMetrics } from 'types/analytics';

type HiddenMetricsProps = {};
export const getHiddenMetricsSet = (props?: HiddenMetricsProps) => {
  const hiddenSet: Set<string> = new Set();
  hiddenSet.add('completionRate');
  // for CSV
  hiddenSet.add('completion_rate');
  hiddenSet.add('completion_rate_25');
  hiddenSet.add('completion_rate_50');
  hiddenSet.add('completion_rate_75');
  hiddenSet.add('storiesPublished');
  if (!props) {
    hiddenSet.add('dauSubscribers');
    hiddenSet.add('mauSubscribers');
  }
  return hiddenSet;
};
const metricKeys = ['previousValueDescription', 'subtitle', 'previousValueDescriptionLong'];
type Props = {
  currentRange: Kpis;
  previousRange: Kpis;
  startDate: string | undefined | null;
  endDate: string | undefined | null;
  totalDays: number;
  minDate: boolean;
};
export const getMetricsAndKPIs = (props: Props) => {
  const hiddenMetricsSet = getHiddenMetricsSet(props);
  const dateFormatFn = (dateInput: string) => moment(dateInput, 'YYYY-MM-DD').format('LL');
  return DAILY_KPI_V3_CONFIG.filter(kpi => !hiddenMetricsSet.has(kpi.metricId)).map(kpiConfig => {
    const kpiCurrentValue = (kpiConfig as any).findValue(props.currentRange);
    const kpiPreviousValue = (kpiConfig as any).findValue(props.previousRange);
    const { endDate } = props;
    const { startDate } = props;
    const { totalDays } = props;
    const { minDate } = props;
    const metric = {
      ...kpiConfig,
    };
    metricKeys.forEach(metricKey => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (is.function(metric[metricKey])) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        metric[metricKey] = metric[metricKey]({
          startDate,
          endDate,
          minDate,
          totalDays,
          dateFormat: dateFormatFn,
        });
      }
    });
    return { metric, kpiCurrentValue, kpiPreviousValue };
  });
};
export const getFirstDate = memoize((dailyMetrics?: DailyMetrics | null) => {
  if (!dailyMetrics) {
    return null;
  }
  const dates = Object.keys(dailyMetrics);
  if (!dates.length) {
    return null;
  }
  return dates.sort().shift();
});
export const getLastDate = memoize((dailyMetrics?: DailyMetrics | null) => {
  if (!dailyMetrics) {
    return null;
  }
  const dates = Object.keys(dailyMetrics);
  if (!dates.length) {
    return null;
  }
  return dates.sort().pop();
});
export const getTotalDays = memoize((dailyMetrics?: DailyMetrics | null) => {
  if (!dailyMetrics) {
    return null;
  }
  return Object.keys(dailyMetrics).length;
});
