import moment from 'moment';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';

import { getEditionById } from 'state/editions/selectors/editionsSelectors';
import { getPresentationalTilesForEdition } from 'state/publisherTools/selectors/publisherToolsSelectors';

import { AnalyticsInsightsTableType, PT_TIME_ZONE } from 'config/constants';
import type { AnalyticsInsightsTableTypeEnum } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import { constructURL } from 'utils/linkUtils';

import { AnalyticsEditionView } from 'views/analytics/containers/AnalyticsEditionView/AnalyticsEditionView';
import CheetahAnalyticsTilePreview from 'views/analytics/containers/CheetahAnalyticsTilePreview/CheetahAnalyticsTilePreview';

import style from './AnalyticsInsightsTilePreview.scss';

import type { Edition, EditionID } from 'types/editions';
import type { State } from 'types/rootState';
import type { Tile } from 'types/tiles';

type EditionPreviewConfig = {
  editionId: EditionID;
  headline: string;
  tileId: string;
};

type OwnProps = {
  editionPreviewConfig: EditionPreviewConfig;
  analyticsInsightsTableType: AnalyticsInsightsTableTypeEnum;
};

type StateProps = {
  editionPresentationalTiles: Tile[] | undefined | null;
  edition: Edition | undefined | null;
};

type Props = OwnProps & StateProps;

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-tile-preview-subtitles-text-top-stories-case"
      defaultMessage="{numberOfSnaps} snaps {firstPublishedDateOptional}"
      description="Subtitle text for the top performing stories case"
    />
  ),
  params: ['numberOfSnaps', 'firstPublishedDateOptional'],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-tile-preview-subtitles-text-top-tiles-case"
      defaultMessage="First Snap of Story, Tile {tilePosition} of {numberOfTiles} {firstPublishedDateOptional}"
      description="Subtitle text for the top performing tiles case"
    />
  ),
  params: ['tilePosition', 'numberOfTiles', 'firstPublishedDateOptional'],
});

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const { editionId } = ownProps.editionPreviewConfig;
  return {
    editionPresentationalTiles: getPresentationalTilesForEdition(state)(editionId),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    edition: getEditionById(state)(parseInt(editionId, 10)),
  };
};

export class AnalyticsInsightsTilePreview extends React.PureComponent<Props> {
  getTitleText = () => {
    switch (this.props.analyticsInsightsTableType) {
      case AnalyticsInsightsTableType.TOP_PERFORMING_TILES:
        return this.getTitleTextForTileCase();
      case AnalyticsInsightsTableType.TOP_PERFORMING_STORIES:
        return this.getTitleTextForStoryCase();
      default:
        return this.getTitleTextDefault();
    }
  };

  getTitleTextForTileCase = () => {
    return this.props.editionPreviewConfig.headline;
  };

  getTitleTextForStoryCase = () => {
    if (this.props.edition) {
      return this.props.edition.title;
    }
    return this.getTitleTextDefault();
  };

  getTitleTextDefault = () => {
    const { editionId } = this.props.editionPreviewConfig;
    return getMessageFromId('analytics-insights-top-tile-view-default-title', { editionId });
  };

  getStoryTitle = () => {
    if (!this.props.edition) {
      return this.getTitleText();
    }
    const analyticsURL = constructURL(AnalyticsEditionView.path, {
      params: {
        editionId: this.props.edition.id,
        publisherId: this.props.edition.publisherId,
      },
    });
    return <Link to={analyticsURL}>{this.getTitleText()}</Link>;
  };

  getDateTimeValue(time: moment.Moment) {
    const timestamp = time.unix() * 1000;
    return {
      date: <FormattedDate value={timestamp} timeZone={PT_TIME_ZONE} />,
      time: (
        <FormattedTime value={timestamp} hour="numeric" minute="numeric" timeZone={PT_TIME_ZONE} timeZoneName="short" />
      ),
    };
  }

  getStoryFirstPublishedDate = () => {
    if (this.props.edition && this.props.edition.firstLiveDate) {
      const publishedAt = moment(this.props.edition.firstLiveDate);
      return getMessageFromId('analytics-insights-media-preview-story-published-time-optional', {
        ...this.getDateTimeValue(publishedAt),
        timeAgo: publishedAt.fromNow(),
      });
    }
    return '';
  };

  getNumberOfSnaps = () => {
    if (!this.props.edition) {
      return 0;
    }
    return this.props.edition.snapIds.length;
  };

  getNumberOfTiles = () => {
    if (!this.props.editionPresentationalTiles) {
      return 0;
    }
    return this.props.editionPresentationalTiles.length;
  };

  getTilePosition = () => {
    if (!this.props.editionPresentationalTiles) {
      return 0;
    }
    const tileIdArray = this.props.editionPresentationalTiles.map(tile =>
      tile.scsId != null ? tile.scsId : tile.croppedImageAssetId
    );
    return tileIdArray.map(tileId => String(tileId)).indexOf(this.props.editionPreviewConfig.tileId) + 1;
  };

  getSubtitleForTopPerformingStoriesCase = () => {
    if (!this.props.editionPresentationalTiles) {
      return null;
    }
    return getMessageFromId('analytics-insights-tile-preview-subtitles-text-top-stories-case', {
      numberOfSnaps: this.getNumberOfSnaps(),
      firstPublishedDateOptional: this.getStoryFirstPublishedDate(),
    });
  };

  getSubtitleForTopPerformingTilesCase = () => {
    if (!this.props.editionPresentationalTiles) {
      return null;
    }
    return getMessageFromId('analytics-insights-tile-preview-subtitles-text-top-tiles-case', {
      tilePosition: this.getTilePosition(),
      numberOfTiles: this.getNumberOfTiles(),
      firstPublishedDateOptional: this.getStoryFirstPublishedDate(),
    });
  };

  getSubtitle = () => {
    switch (this.props.analyticsInsightsTableType) {
      case AnalyticsInsightsTableType.TOP_PERFORMING_TILES:
        return this.getSubtitleForTopPerformingTilesCase();
      case AnalyticsInsightsTableType.TOP_PERFORMING_STORIES:
        return this.getSubtitleForTopPerformingStoriesCase();
      default:
        return null;
    }
  };

  renderTileImage = () => {
    switch (this.props.analyticsInsightsTableType) {
      case AnalyticsInsightsTableType.TOP_PERFORMING_TILES:
        return <CheetahAnalyticsTilePreview edition={this.props.editionPreviewConfig} omitHeadline />;
      case AnalyticsInsightsTableType.TOP_PERFORMING_STORIES:
        return <CheetahAnalyticsTilePreview edition={this.props.editionPreviewConfig} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <span className={style.tileAndTextPositions}>
        {this.renderTileImage()}
        <span className={style.titleAndSubtitlePositions}>
          <span className={style.storyTitleText}>{this.getStoryTitle()}</span>
          <span className={style.subtitle}>{this.getSubtitle()}</span>
        </span>
      </span>
    );
  }
}

export default intlConnect(mapStateToProps, null)(AnalyticsInsightsTilePreview);
