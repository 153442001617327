import classNames from 'classnames';
import * as React from 'react';
import InlineSVG from 'svg-inline-react';

import {
  availableStory,
  deactivate,
  storyDraft,
  scheduledStory,
  chart,
  blocker,
  warningTriangle,
} from 'icons/SDS/allIcons';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import styles from './StoryStateIcon.scss';

import { StoryState } from 'types/editions';

export const StoryIcon = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  SCHEDULED: 'SCHEDULED',
  BLOCKED: 'BLOCKED',
  DRAFT: 'DRAFT',
  ANALYTICS: 'ANALYTICS',
  WARNING: 'WARNING',
};
type StoryIconEnum = typeof StoryIcon[keyof typeof StoryIcon];
type Props = {
  icon?: StoryIconEnum;
  isDisabled?: boolean;
  hasAction?: boolean;
  storyState?: StoryState;
};
const stateToIcon = {
  [StoryState.LIVE]: StoryIcon.AVAILABLE,
  [StoryState.ARCHIVED]: StoryIcon.AVAILABLE,
  [StoryState.IN_PROGRESS]: StoryIcon.AVAILABLE,
  [StoryState.UNPUBLISHING]: StoryIcon.AVAILABLE,
  [StoryState.HIDDEN]: StoryIcon.UNAVAILABLE,
  [StoryState.SCHEDULED]: StoryIcon.SCHEDULED,
  [StoryState.PUBLISHING]: StoryIcon.SCHEDULED,
  [StoryState.SCHEDULED_PENDING_APPROVAL]: StoryIcon.SCHEDULED,
  [StoryState.PENDING_APPROVAL]: StoryIcon.SCHEDULED,
  [StoryState.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL]: StoryIcon.SCHEDULED,
  [StoryState.LIVE_EDIT_PENDING_APPROVAL]: StoryIcon.SCHEDULED,
  [StoryState.NEW]: StoryIcon.DRAFT,
  [StoryState.READY]: StoryIcon.DRAFT,
  [StoryState.BIB_REJECTED]: StoryIcon.UNAVAILABLE,
  [StoryState.BIB_REVISION_NEEDED]: StoryIcon.WARNING,
};
const iconToImage = {
  [StoryIcon.AVAILABLE]: availableStory,
  [StoryIcon.UNAVAILABLE]: deactivate,
  [StoryIcon.SCHEDULED]: scheduledStory,
  [StoryIcon.BLOCKED]: blocker,
  [StoryIcon.DRAFT]: storyDraft,
  [StoryIcon.ANALYTICS]: chart,
  [StoryIcon.WARNING]: warningTriangle,
};
const iconToClass = {
  [StoryIcon.DRAFT]: styles.draft,
  [StoryIcon.SCHEDULED]: styles.scheduled,
  [StoryIcon.AVAILABLE]: styles.available,
  [StoryIcon.UNAVAILABLE]: styles.unavailable,
  [StoryIcon.ANALYTICS]: styles.analytics,
  [StoryIcon.BLOCKED]: (styles as any).blocked,
  [StoryIcon.WARNING]: styles.warning,
};
type State = any;
export default class StoryStateIcon extends React.Component<Props, State> {
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  getIcon() {
    if (this.props.icon) {
      return this.props.icon;
    }
    if (this.props.storyState) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return stateToIcon[this.props.storyState];
    }
    return null;
  }

  render() {
    const potentialIcon = this.getIcon();
    if (potentialIcon) {
      const icon: StoryIconEnum = potentialIcon;
      const svgStyle = classNames(styles.dims, styles.icon, iconToClass[icon], {
        [styles.svgDisabled]: this.props.isDisabled,
        [styles.clickableSvg]: this.props.hasAction, // clickable icons in homepage that dispatch actions are black
      });
      return <InlineSVG className={svgStyle} src={iconToImage[icon]} data-test={`storyStateIcon${icon}`} />;
    }
    return null;
  }
}
