import React from 'react';
import { FormattedMessage } from 'react-intl';

import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';

export default function NoPermissionView() {
  const message = (
    <>
      <FormattedMessage
        id="no-permission-line-1"
        defaultMessage="You don't have permissions for this page."
        description="Displayed when the user doesn't have permissions to see a page"
      />
      &nbsp;
      <FormattedMessage
        id="no-permission-line-2"
        defaultMessage="Please select another page or different Publisher."
        description="Displayed when the user doesn't have permissions to see a page"
      />
      &nbsp;
      <FormattedMessage
        id="no-permission-line-3"
        defaultMessage="If you believe you should have permissions for this page, please contact the Administrator within your organization." // eslint-disable-line max-len
        description="Displayed when the user doesn't have permissions to see a page"
      />
    </>
  );

  return <BlankPageWithText message={message} />;
}
