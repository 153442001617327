import { ModerationFilterEnum } from 'types/moderation';

export enum StorySnapType {
  OUR_STORY = 'OUR_STORY',
}

type EncryptedMedia = {
  url?: string;
  iv?: string;
  key?: string;
};

type SpotlightSnapAnalytics = {
  subscribes: number;
  totalFavourite: number;
  totalShares: number;
  totalViews: number;
};

export enum ModerationStatus {
  SUBMITTED = 'SUBMITTED',
  LIVE = 'LIVE',
  LIMITED_DISTRIBUTION = 'LIMITED_DISTRIBUTION',
  STATUS_UNSET = 'STATUS_UNSET',
}

export type StorySnap = {
  id: string;
  video?: EncryptedMedia;
  posterUrl?: string;
  hashtags?: string[];
  description?: string;
  moderationRejectionReasons?: string[];
  moderationStatus?: ModerationStatus;
  analytics?: SpotlightSnapAnalytics;
  creationTimestampMs?: number;
  goLiveTsMs?: number;
};

export type StorySnapResponse = {
  storySnaps: StorySnap[];
  queryCursor: string;
};

type OurStoryFilter = {
  moderationFilters: ModerationFilterEnum[];
};

export interface StorySnapInput {
  hostUserId: string;
  limit: number;
  queryCursor: string;
  type: StorySnapType;
  ourStoryFilter?: OurStoryFilter;
}

export interface StorySnapsData {
  storySnap: StorySnapResponse;
}
