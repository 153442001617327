import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CrossOrigin } from 'config/constants';

import style from './CameoTilePreview.scss';

const isMp4Url = (src: string): boolean => {
  return /^https:\/\/.*\.mp4\?/.test(src);
};

type Props = {
  src: string;
  className?: string;
};

export class CameoTilePreview extends React.Component<Props> {
  renderVideo() {
    if (this.props.src && isMp4Url(this.props.src)) {
      return (
        <video
          className={classNames(this.props.className, style.tile)}
          draggable="false"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; className: string; drag... Remove this comment to see the full error message
          alt="Cameo Tile"
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          preload="auto"
        >
          <source src={this.props.src} type="video/mp4" />
        </video>
      );
    }
    return (
      <div className={classNames(this.props.className, style.noCameo)}>
        <FormattedMessage
          id="cameo-tile-data-error"
          description="Error shown when the url to the mp4 is bad"
          defaultMessage="Bad Cameo tile"
        />
      </div>
    );
  }

  render() {
    return (
      <div className={classNames(style.cameoWrap)} data-test="cameoVideoPreview">
        {this.renderVideo()}
      </div>
    );
  }
}

export default CameoTilePreview;
