import React from 'react';
import type { ReactNode } from 'react';
import { Legend, Pie, PieChart, Tooltip } from 'recharts';

import AnalyticsGraphContainer from 'views/analytics/components/AnalyticsGraphContainer/AnalyticsGraphContainer';

const RADIAN = Math.PI / 180;

type GraphPie = {
  name: string | undefined | null;
  value: number | undefined | null;
  fill: string;
};

type Props = {
  graphTooltip?: string;
  isLoading: boolean;
  pieChartTitle: string | undefined | null;
  pies: GraphPie[];
  tooltipRenderer: () => ReactNode;
};

type LabelRendererOptions = {
  cx: number;
  cy: number;
  index: number;
  innerRadius: number;
  midAngle: number;
  outerRadius: number;
  percent: number;
};

export class AnalyticsPieChart extends React.PureComponent<Props> {
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: LabelRendererOptions) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderPieChart = () => {
    return (
      <PieChart>
        <Legend verticalAlign="top" align="right" iconType="circle" height={30} />
        <Pie
          dataKey="value"
          data={this.props.pies}
          isAnimationActive={false}
          labelLine={false}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          label={this.renderCustomizedLabel}
        />
        <Tooltip
          content={this.props.tooltipRenderer}
          cursor={false}
          wrapperStyle={{
            background: 'white',
            border: '1px',
            borderColor: 'gray',
            borderRadius: '5px',
            padding: '10px',
            borderStyle: 'solid',
          }}
        />
      </PieChart>
    );
  };

  render() {
    return (
      <AnalyticsGraphContainer
        chartTitle={this.props.pieChartTitle}
        isLoading={this.props.isLoading}
        tooltip={this.props.graphTooltip}
        isToggled={false}
      >
        {this.renderPieChart()}
      </AnalyticsGraphContainer>
    );
  }
}

export default AnalyticsPieChart;
