import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import { Tab as ReactTab, TabList as ReactTabList, TabPanel as ReactTabPanel, Tabs as ReactTabs } from 'react-tabs';

import style from './OriginalSDSTabs.scss';

type TabsProps = {
  /**
   * The Tab components to be rendered by Tabs
   */
  children?: ReactNode;
  /**
   * classname that will be used in the main top-level Tab component
   */
  className?: string;
  /**
   * Additional non-tab content to render next to tabs
   */
  headerContent?: ReactNode;
  /**
   * classname that will be added to the layer that controls the tabs
   */
  tabsClassName?: string;
  /**
   * classname that will be added to the layer that controls the tabs content
   */
  tabPanelClassName?: string;
  /**
   * The index (0-based) of the tab that should be selected by default
   */
  defaultIndex?: number;
  /**
   * The index (0-based) of the tab that is currently selected.
   * This enabled controlled mode, which also requires onTabSelected to be set.
   */
  selectedIndex?: number;
  /**
   * onTabSelected callback is called every time a tab is about to change.
   * It will be called with the index that it will be changed to and the lastIndex
   * which was selected before.
   * If index and lastIndex are the same, it means that the user clicked on the
   * currently active tab
   * @param index Index that it will be changed to
   * @param lastIndex Index which was selected before
   */
  onTabSelected?: (index: number, lastIndex: number) => void;
};
function OriginalSDSTabs({
  children,
  className,
  headerContent,
  tabsClassName,
  tabPanelClassName,
  defaultIndex,
  selectedIndex,
  onTabSelected,
}: TabsProps) {
  // Extract the title out of the Tab component to create the tabs list
  const tabs = React.Children.map(
    children,
    (child): ReactNode => {
      if (!child) {
        return null;
      }
      const tabClassNames = classNames(style.tabStyle, (child as any).props.tabClassName);
      return (
        <ReactTab className={tabClassNames} selectedClassName={style.tabSelectedStyle}>
          {(child as any).props.title}
        </ReactTab>
      );
    }
  );
  const tabsContent = React.Children.map(children, child => {
    if (!child) {
      return null;
    }
    return (
      <ReactTabPanel
        className={classNames(tabPanelClassName, style.tabPanelStyle)}
        selectedClassName={classNames(style.tabPanelSelectedStyle)}
      >
        {child}
      </ReactTabPanel>
    );
  });
  return (
    <ReactTabs className={className} defaultIndex={defaultIndex} selectedIndex={selectedIndex} onSelect={onTabSelected}>
      <ReactTabList className={classNames(style.tabListStyle, tabsClassName)}>
        {tabs}
        {headerContent}
      </ReactTabList>
      {tabsContent}
    </ReactTabs>
  );
}
export default OriginalSDSTabs;
