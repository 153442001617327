import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getActiveCreatorHostUserId } from 'state/user/selectors/userSelectors';

import { UPDATE_STORY_DRAFTING_SNAPS } from 'gql/mutations/spotlight/updateStoryDraftingSnaps';
import { StorySnap } from 'gql/queries/spotlight/storySnaps';
import { useProxyMutation } from 'utils/apis/graphQLUtils';

export function useUpdateStoryDraftingSnaps() {
  const [updateStoryDraftingSnaps] = useProxyMutation(UPDATE_STORY_DRAFTING_SNAPS);
  const hostUserId = useSelector(getActiveCreatorHostUserId);

  const updateStoryDraftingSnapSchedule = useCallback(
    async (snap: StorySnap, updatedGoLiveTsMs: number) => {
      const snapId = snap.id;
      return updateStoryDraftingSnaps({
        variables: {
          input: {
            hostUserId,
            snapId,
            goLiveTsMs: updatedGoLiveTsMs,
          },
        },
      });
    },
    [updateStoryDraftingSnaps, hostUserId]
  );

  return { updateStoryDraftingSnapSchedule };
}
