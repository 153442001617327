import classNames from 'classnames';
import type { ReactNode } from 'react';
import React, { memo } from 'react';

import Icon from 'views/common/components/Icon/Icon';

import style from './Header.scss';

type Props = {
  titleIcon?: string;
  title: ReactNode;
  middleElements?: ReactNode;
  rightElements?: ReactNode;
  positionStatic?: boolean;
};

function Header({ titleIcon, middleElements, rightElements, title, positionStatic }: Props) {
  return (
    <div className={classNames(style.container, { [style.staticContainer]: positionStatic })}>
      <div className={style.titleContainer}>
        {titleIcon && <Icon className={style.titleIcon} inlineIcon={titleIcon} />}
        <span className={style.title}>{title}</span>
      </div>
      <div>{middleElements}</div>
      {rightElements}
    </div>
  );
}

export default memo(Header);
