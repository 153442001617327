import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { showSpotlightRejectionModerationReason } from 'state/features/selectors/featuresSelectors';
import { showModal } from 'state/modals/actions/modalsActions';

import { useDeleteStoryDraftingSnaps } from 'gql/hooks/useDeleteStoryDraftingSnaps';
import { useDeleteStorySnaps } from 'gql/hooks/useDeleteStorySnap';
import { usePermissionCheck } from 'gql/hooks/usePermissionCheck';
import { useUpdateStoryDraftingSnaps } from 'gql/hooks/useUpdateStoryDraftingSnaps';
import { ModerationStatus, StorySnap } from 'gql/queries/spotlight/storySnaps';
import { download, heartFilled, send, subscribe, trash, visible } from 'icons/SDS/allIcons';
import { zipDownLoadEncryptedMedia } from 'utils/files/downloadFilesUtil';
import { decryptMedia } from 'utils/media/crypto';
import { ModalType } from 'utils/modalConfig';
import { prettyPrintNumber } from 'utils/numberFormatter';
import { formatDateAndTime, formatDateShortMonth } from 'utils/timezoneUtils';

import HelpCenterLink from 'views/common/components/HelpCenterLink/HelpCenterLink';
import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSModal from 'views/common/components/SDSModal/SDSModal';
import { ModerationStatusWidget } from 'views/common/components/StorySnapCell/StorySnapCell';
import {
  getModerationReasonFromString,
  getMultipleViolationMessage,
  getRejectionParamFromString,
  SpotlightModerationRejectionContent,
  SpotlightModerationRejectionMessage,
  SpotlightModerationRejectionReason,
} from 'views/modals/components/SpotlightDetailsModal/SpotlightModeration/SpotlightModerationRejectionReason';
import ScheduleCard from 'views/storySnapUploader/components/SnapUploaderMenuCards/ScheduleCard/ScheduleCard';
import StorySnapUploaderPreviewCard from 'views/storySnapUploader/components/StorySnapCards/StorySnapUploadPreviewCard/StorySnapUploadPreviewCard';

import style from './SpotlightDetailsModal.scss';

import { ModalId } from 'types/modal';
import { Claim } from 'types/permissions';

type Props = {
  modalId: ModalId;
  hideModal: (modalId: string) => void;
  options: {
    snap: StorySnap;
    handleRemoveSnap: (snap: StorySnap) => void;
    handleUpdateSnapGoLiveTime: (snap: StorySnap, updatedGoLiveTime: number) => void;
  };
};

export default function SpotlightDetailsModal(props: Props) {
  const dispatch = useDispatch();

  const showSpotlightRejectionModerationMessage = useSelector(showSpotlightRejectionModerationReason);
  const canUserDeleteContent = usePermissionCheck(Claim.STORY_DELETER);

  const [showUpdateScheduleMenu, setShowUpdateScheduleMenu] = useState(false);
  const [goLiveTsMs, setGoLiveTsMs] = useState(props.options.snap.goLiveTsMs);
  const [updatedGoLiveTsMs, setUpdatedGoLiveTsMs] = useState<number | undefined>();

  const { deleteSnaps } = useDeleteStorySnaps();
  const { deleteDraftingSnaps } = useDeleteStoryDraftingSnaps();
  const { updateStoryDraftingSnapSchedule } = useUpdateStoryDraftingSnaps();

  const [decryptedMediaUrl, setDecryptedMediaUrl] = useState<string | undefined>('');

  useEffect(() => {
    const decryptedMedia = decryptMedia(props.options.snap);
    decryptedMedia.then(result => {
      setDecryptedMediaUrl(result?.snapUrl);
    });
  }, [props.options.snap]);

  const closeModal = useCallback(() => {
    props.hideModal(props.modalId);
  }, [props]);

  const onConfirm = useCallback(() => {
    const deleteFunc = props.options.snap?.goLiveTsMs == null ? deleteSnaps : deleteDraftingSnaps;
    deleteFunc([props.options.snap])
      .then(() => {
        props.options.handleRemoveSnap(props.options.snap);
      })
      .then(() => {
        // Close the modal when the snap is deleted and removed from the store successfully
        closeModal();
      });
  }, [closeModal, deleteDraftingSnaps, deleteSnaps, props.options]);

  const handleDeleteSnap = useCallback(() => {
    const options = {
      visible: true,
      onConfirm,
      okText: (
        <FormattedMessage
          id="spotlight-terms-delete-confirm"
          defaultMessage="Delete"
          description="Button caption to accept deleting"
        />
      ),
      cancelText: (
        <FormattedMessage
          id="spotlight-delete-prompt-cancel"
          defaultMessage="Cancel"
          description="Button caption to dismiss the delete modal"
        />
      ),
      title: (
        <FormattedMessage
          id="spotlight-delete-prompt-title"
          defaultMessage="Are you sure?"
          description="Confirm the user is happy with action"
        />
      ),
      body: (
        <>
          <FormattedMessage
            id="spotlight-delete-prompt-body"
            defaultMessage="This snap will be permanently deleted. This will also remove your snap from any Highlights on your public profile. This will not affect any other users' content created using your snap and the sound will remain usable."
            description="text for the delete spotlight modal"
          />
        </>
      ),
    };

    return dispatch(showModal(ModalType.DIALOG, 'SpotlightDetailDeleteConfirmation', options));
  }, [dispatch, onConfirm]);

  const handleDownloadMedia = useCallback(() => {
    zipDownLoadEncryptedMedia([props.options.snap]);
  }, [props]);

  const handleEditSchedule = useCallback(() => {
    setShowUpdateScheduleMenu(true);
  }, []);

  const handleSaveUpdatedSchedule = useCallback(() => {
    updateStoryDraftingSnapSchedule(props.options.snap, updatedGoLiveTsMs!)
      .then(() => {
        setGoLiveTsMs(updatedGoLiveTsMs);
        setShowUpdateScheduleMenu(false);
      })
      .then(() => {
        props.options.handleUpdateSnapGoLiveTime(props.options.snap, updatedGoLiveTsMs!);
      });
  }, [props.options, updateStoryDraftingSnapSchedule, updatedGoLiveTsMs]);

  function renderSpotlightAnalyticsSection() {
    const sectionData = [
      {
        icon: visible,
        sectionText: (
          <FormattedMessage
            id="spotlight-views-header"
            description="Header that shows nuber of spotlight views."
            defaultMessage="Views"
          />
        ),
        sectionData: prettyPrintNumber(props.options.snap.analytics?.totalViews),
      },
      {
        icon: subscribe,
        sectionText: (
          <FormattedMessage
            id="spotlight-followers-header"
            description="Header that shows number of followers."
            defaultMessage="Followers"
          />
        ),
        sectionData: prettyPrintNumber(props.options.snap.analytics?.subscribes),
      },
      {
        icon: heartFilled,
        sectionText: (
          <FormattedMessage
            id="spotlight-favorite-header"
            description="Header that shows nuber of favorites."
            defaultMessage="Favorites"
          />
        ),
        sectionData: prettyPrintNumber(props.options.snap.analytics?.totalFavourite),
      },
      {
        icon: send,
        sectionText: (
          <FormattedMessage
            id="spotlight-shares-header"
            description="Header that shows nuber of shares."
            defaultMessage="Shares"
          />
        ),
        sectionData: prettyPrintNumber(props.options.snap.analytics?.totalShares),
      },
    ];
    const analyticsSection = (
      <div data-test="SpotlightAnalyticsModal.AnalyticsSection" className={style.analyticsDataSection}>
        {sectionData.map(section => {
          return (
            <div className={style.listItem}>
              <div className={style.listIcon}>
                <Icon inlineIcon={section.icon} className={style.icon} />
              </div>
              <div className={style.listContent}>
                <div className={style.listContentSubText}>{section.sectionText}</div>
                <div className={style.listContentSubText}>{section.sectionData}</div>
              </div>
              <div className={style.listDivider} />
            </div>
          );
        })}
      </div>
    );

    return (
      <div className={style.analyticsDataSection}>
        <div className={style.header}>
          <FormattedMessage
            id="spotlight-analytics-section"
            description="Section that shows snap status"
            defaultMessage="Analytics"
          />
        </div>
        {analyticsSection}
      </div>
    );
  }

  function renderModerationStatusSection() {
    if (!showSpotlightRejectionModerationMessage) {
      return null;
    }

    if (props.options.snap.moderationStatus) {
      return (
        <div className={style.statusSection}>
          <div className={style.storyStatus}>
            <ModerationStatusWidget moderationStatus={props.options.snap.moderationStatus} useBadge />
          </div>
        </div>
      );
    }
    return null;
  }

  function formatRejectionList(strings: string[]): string {
    if (strings.length === 0) {
      return '';
    }
    if (strings.length === 1) {
      return strings[0]!;
    }
    if (strings.length === 2) {
      return `${strings[0]} and ${strings[1]}`;
    }
    const last = strings.pop();
    return `${strings.join(', ')} and ${last}`;
  }

  function getModerationRejectionMessages(rejectionReasons: string[]): SpotlightModerationRejectionContent {
    if (rejectionReasons.length > 1) {
      const rejectionParameter = rejectionReasons
        .filter(reason => Object.values(SpotlightModerationRejectionReason).some(reasonEnum => reasonEnum === reason))
        .map(reason => Object.entries(SpotlightModerationRejectionReason).find(([key, value]) => value === reason)![0]);

      if (!rejectionParameter.length) {
        return SpotlightModerationRejectionMessage[SpotlightModerationRejectionReason.NoRejectionReasonGiven];
      }

      const rejectionParameters: string[] = rejectionParameter.map(reason => getRejectionParamFromString(reason));
      const formattedRejectionParameter = formatRejectionList(rejectionParameters);
      return {
        message: getMultipleViolationMessage(formattedRejectionParameter),
        link: SpotlightModerationRejectionMessage[SpotlightModerationRejectionReason.MultipleViolations].link,
      };
    }

    const rejectionReason = getModerationReasonFromString(rejectionReasons[0] || '');
    return SpotlightModerationRejectionMessage[rejectionReason];
  }

  function renderPublishedDateSection() {
    if (!showSpotlightRejectionModerationMessage) {
      return null;
    }

    const publishedDate = formatDateShortMonth(props.options.snap.creationTimestampMs!);
    return (
      <div className={style.statusSection}>
        <FormattedMessage
          id={'spotlight-published-date'}
          defaultMessage={'Published {date}'}
          description={'Published date for spotlight snap'}
          values={{ date: publishedDate }}
        />
      </div>
    );
  }

  function renderScheduledDateSection() {
    if (!goLiveTsMs) {
      return null;
    }
    const scheduledDate = formatDateAndTime(goLiveTsMs);
    return (
      <div className={style.schedulingMenuContainer} data-test="SpotlightDetailsModal.SpotlightScheduledMenu">
        <div className={style.statusSection} data-test="SpotlightDetailsModal.SpotlightScheduledDateSection">
          <FormattedMessage
            id={'spotlight-scheduled-date'}
            defaultMessage={'Scheduled for {date}'}
            description={'Scheduled date for spotlight snap to go live'}
            values={{ date: scheduledDate }}
          />
          {!showUpdateScheduleMenu && (
            <SDSButton
              className={style.smallButton}
              data-test="SpotlightDetailsModal.EditScheduleButton"
              type={ButtonType.SECONDARY}
              onClick={handleEditSchedule}
            >
              <FormattedMessage
                id="spotlight-edit-schedule-button"
                description="Button to edit Spotlight's schedule time"
                defaultMessage="Edit"
              />
            </SDSButton>
          )}
        </div>
        {showUpdateScheduleMenu && (
          <ScheduleCard
            initialGoLiveTsMs={goLiveTsMs}
            showUpdateScheduleMenu={showUpdateScheduleMenu}
            setGoLiveTsMs={setUpdatedGoLiveTsMs}
            saveUpdatedSchedule={handleSaveUpdatedSchedule}
          />
        )}
      </div>
    );
  }

  function renderModerationReasonsSection() {
    const canShowRejectionReason =
      showSpotlightRejectionModerationMessage && props.options.snap.moderationStatus?.length;
    if (!canShowRejectionReason || props.options.snap.moderationStatus === ModerationStatus.LIVE) {
      return null;
    }

    if (props.options.snap.moderationStatus === ModerationStatus.SUBMITTED) {
      return (
        <div className={style.statusSection}>
          <div>
            {
              <FormattedMessage
                id="spotlight-submitted-message"
                defaultMessage="Your Snap is submitted! It's currently being reviewed before we can promote it to Snapchatters on Spotlight."
                description="Learn more about rejection reasons"
              />
            }
          </div>
        </div>
      );
    }

    const rejectionReasons = props.options.snap.moderationRejectionReasons || [];
    const moderationRejection = getModerationRejectionMessages(rejectionReasons);
    const reasonMessage = moderationRejection.message;
    const reasonLink = moderationRejection.link;

    return (
      <div className={style.statusSection}>
        <div>{reasonMessage}</div>
        <div>
          <HelpCenterLink
            customLink={reasonLink}
            clearDefaultPadding
            data-test="SpotlightDetailsModal.RejectionReasonLink"
            customLabel={
              <FormattedMessage
                id="spotlight-rejection-reason-link"
                defaultMessage="View guidelines"
                description="Learn more about rejection reasons"
              />
            }
          />
        </div>
      </div>
    );
  }

  function renderSnapButtonSection() {
    return (
      <div className={style.buttonSection}>
        <SDSButton
          className={style.wrappedButton}
          data-test="SpotlightDetailsModal.DownloadMedia"
          type={ButtonType.SECONDARY}
          inlineIcon={download}
          onClick={handleDownloadMedia}
          disabled={!decryptedMediaUrl?.length} // if the media hasn't finished decrypting block download
        >
          <FormattedMessage
            id="spotlight-download-button"
            description="Button to download snap"
            defaultMessage="Download"
          />
        </SDSButton>
        <SDSButton
          type={ButtonType.WARNING_RED}
          inlineIcon={trash}
          data-test="SpotlightDetailsModal.Delete"
          className={style.wrappedButton}
          onClick={handleDeleteSnap}
          disabled={!canUserDeleteContent}
        >
          <FormattedMessage id="spotlight-delete-button" description="Button to delete snap" defaultMessage="Delete" />
        </SDSButton>
      </div>
    );
  }

  return (
    <SDSModal visible width={900} closable footer={false} onCancel={closeModal}>
      <div className={style.modalBodyWrapper}>
        <StorySnapUploaderPreviewCard
          src={decryptedMediaUrl}
          posterUrl={props.options.snap.posterUrl}
          snapDescription={props.options.snap.description}
        />
        <div className={style.spotlightDetails}>
          {props.options.snap.analytics && renderSpotlightAnalyticsSection()}
          {renderModerationStatusSection()}
          {renderModerationReasonsSection()}
          {props.options.snap.goLiveTsMs == null ? renderPublishedDateSection() : renderScheduledDateSection()}
          {renderSnapButtonSection()}
        </div>
      </div>
    </SDSModal>
  );
}
