import {
  // discover-cms/no-snapnet
  PageHeader,
  Panel,
  PanelGroup,
  ListGroup,
  ListGroupItem,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/snapnet';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactJson from 'react-json-view';

import type { VideoRef, ImageRef, LinkRef, JsonRef, Value, Section, Item } from 'state/debug/debugState';

import style from './DebugPage.scss';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';

const DebugPageVideo = ({ src }: VideoRef) => <video src={src} className={style.debugSnap} controls />;

const DebugPageLink = ({ href, label }: LinkRef) => (
  <a href={href} className={style.clickHere}>
    {label || href}
  </a>
);

const DebugPageImage = ({ src }: ImageRef) => <img src={src} alt="" className={style.debugSnap} />;

const DebugPageJson = ({ object, isExpanded = () => false }: JsonRef) => (
  <ReactJson src={object} collapsed={!isExpanded} name={null} quotesOnKeys={false} />
);

const DebugPageMedia = ({ media }: { media: Value }) => {
  if (Array.isArray(media)) {
    const mediaArray: Value[] = media;
    return <span>{mediaArray.map(singleMedia => DebugPageMedia({ media: singleMedia }))}</span>;
  }

  if (typeof media !== 'string') {
    if (media.type === 'image') {
      return <DebugPageImage {...media} />;
    }
    if (media.type === 'video') {
      return <DebugPageVideo {...media} />;
    }
    if (media.type === 'link') {
      return <DebugPageLink {...media} />;
    }
    if (media.type === 'json') {
      return <DebugPageJson {...media} />;
    }
  }

  return <span>{String(media)}</span>;
};

export const DebugPageItem = ({ name, value }: { name: string; value: Value }) => {
  /* eslint-disable react/no-array-index-key */
  return (
    <li>
      <h4>{name}:</h4>
      <div className={style.propertyListValue}>
        {Array.isArray(value) ? (
          <ul>
            {value.map((element: Value, index) => (
              <li key={index}>
                <DebugPageMedia media={element} />
              </li>
            ))}
          </ul>
        ) : (
          <DebugPageMedia media={value} />
        )}
      </div>
    </li>
  );
  /* eslint-enable react/no-array-index-key */
};

const DebugPageItems = ({ items }: { items: Item[] }) => {
  const elements = items.map((item, index) => {
    const [name, value] = item;
    return value && <DebugPageItem key={name} name={name} value={value} />;
  });
  return <ul>{elements.filter(x => x)}</ul>;
};

export const DebugPageSection = ({ title, subsections }: Section) => (
  /* eslint-disable react/no-array-index-key */
  <ListGroup fill className={style.propertyList}>
    {subsections.map((items, index) => (
      <ListGroupItem key={index}>
        <DebugPageItems items={items} />
      </ListGroupItem>
    ))}
  </ListGroup>
  /* eslint-enable react/no-array-index-key */
);

const DebugPageDetail = ({ sections }: { sections: Section[] }) => (
  <PanelGroup accordion>
    <p className={style.expandNotice}>Click each section below to expand</p>
    {sections.map((section, index) => (
      <Panel header={<h3>{section.title}</h3>} eventKey={index} key={section.title}>
        <DebugPageSection {...section} />
      </Panel>
    ))}
  </PanelGroup>
);

export const DebugLoading = () => (
  <span className={style.loadingMessage}>
    <FormattedMessage id="debug-loading" description="Loading message" defaultMessage="Loading..." />
  </span>
);

type Props = {
  href: string;
  id: SnapId | EditionID;
  sections: Section[] | undefined | null;
  type: string;
};

/* eslint-disable react/prop-types */

export class DebugPage extends React.Component<Props> {
  render() {
    const { type, id, href, sections } = this.props;

    return (
      <div>
        <PageHeader
          title={
            <span>
              {type} {id}
            </span>
          }
          status={<a href={href}>Raw JSON</a>}
          className={style.pageHeader}
        />
        {!sections ? <DebugLoading /> : <DebugPageDetail sections={sections} />}
      </div>
    );
  }
}
