import moment from 'moment-timezone';
import React from 'react';
import { intlShape } from 'react-intl';

import { getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';
import { printSeconds, privacyNumberFormat } from 'utils/numberFormatter';

import style from './StatItem.scss';

type Props = {
  name: string;
  value: number;
  context: string;
  roundType: string;
  reportStartDate: moment.Moment;
  reportEndDate: moment.Moment;
};

const mapKeyToValueFormatter = {
  time: printSeconds,
  number: privacyNumberFormat,
  rawNumber: (x: any) => x,
};

export class StatItem extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getRoundedValue() {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return mapKeyToValueFormatter[this.props.roundType](this.props.value);
  }

  getContextMessage() {
    return getLocalisedMessageFromId(this.context, 'analytics-insights-report-kpi-days', {
      nbOfDays: `${moment(this.props.reportEndDate).diff(
        moment(this.props.reportStartDate).subtract(1, 'days'),
        'days'
      )}`,
    });
  }

  render() {
    return (
      <div className={style.statItem}>
        <p className={style.statHeader}>{this.props.name}</p>
        <p className={style.statValue}>{this.getRoundedValue()}</p>
        <p className={style.statContext}>{this.getContextMessage()}</p>
      </div>
    );
  }
}

export default StatItem;
