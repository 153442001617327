import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import type { ReactNode } from 'react';

import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { CheetahStoryState, CrossOrigin } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import * as assetUtils from 'utils/media/assetUtils';
import { createAssetUrl } from 'utils/media/assetUtils';
import { buildBitmojiImageUrl } from 'utils/tileUtils';

import StoryStateIcon, { StoryIcon } from 'views/common/components/StoryStateIcon/StoryStateIcon';

import style from './EditionTilePreview.scss';

import type { Edition } from 'types/editions';
import type { State as RootState } from 'types/rootState';
import type { Tile } from 'types/tiles';

export const DEFAULT_EDITION_HEIGHT = 240;
export const DEFAULT_EDITION_WIDTH = 144;
const statusIdToIcon = {
  [CheetahStoryState.AVAILABLE]: StoryIcon.AVAILABLE,
  [CheetahStoryState.UNAVAILABLE]: StoryIcon.UNAVAILABLE,
};
type StateProps = {
  horizontalIcon: string;
  publisherName: string;
};
type OwnProps = {
  edition?: Partial<Edition>;
  tile: Tile | undefined | null;
  onClick: () => void;
  bottomComponent?: ReactNode;
  className?: string;
  scaleToHeight: number;
  scaleToWidth?: number;
};
const mapStateToProps = (state: RootState): StateProps => {
  const activePublisher = userSelectors.getActivePublisher(state);
  return {
    horizontalIcon: activePublisher?.horizontalIconId ? createAssetUrl(activePublisher.horizontalIconId) : '',
    publisherName: activePublisher?.mutablePublisherName ?? '',
  };
};
type Props = (OwnProps & typeof EditionTilePreview.defaultProps) & StateProps;
export class EditionTilePreview extends React.PureComponent<Props> {
  static defaultSize = {
    height: DEFAULT_EDITION_HEIGHT,
    width: DEFAULT_EDITION_WIDTH,
  };

  static defaultProps = {
    publisherName: '',
    scaleToHeight: DEFAULT_EDITION_HEIGHT,
  };

  getScaleFactor = (): number => {
    const { scaleToHeight, scaleToWidth } = this.props;
    return scaleToWidth
      ? scaleToWidth / EditionTilePreview.defaultSize.width
      : scaleToHeight / EditionTilePreview.defaultSize.height;
  };

  getEditionStyle = (): {
    height: string;
    width: string;
  } => {
    const scaleFactor = this.getScaleFactor();
    return {
      height: `${EditionTilePreview.defaultSize.height * scaleFactor}px`,
      width: `${EditionTilePreview.defaultSize.width * scaleFactor}px`,
    };
  };

  getHeadlineText = (): string => {
    const editionTitle = get(this.props.edition, 'title');
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    return get(this.props.tile, 'headline', editionTitle);
  };

  getTileImage = () => {
    const { tile } = this.props;
    if (!tile || !tile.croppedImageAssetId) {
      return null;
    }
    const bitmojiTemplateId = get(tile, 'bitmojiTileTemplateId') || null;
    return bitmojiTemplateId
      ? buildBitmojiImageUrl(bitmojiTemplateId)
      : assetUtils.getImagePreviewUrl(tile.croppedImageAssetId);
  };

  getLogoImage = () => {
    if (!this.props.tile) {
      return this.props.horizontalIcon;
    }
    return assetUtils.getImagePreviewUrl(this.props.tile.logoImageAssetId);
  };

  renderOptionalTileImage() {
    const tileImage = this.getTileImage();
    if (tileImage) {
      return (
        <img
          src={tileImage}
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          className={style.tileImage}
          alt={this.getHeadlineText()}
        />
      );
    }
    return <div className={style.blackBackground} />;
  }

  renderBottomComponent = () => {
    const { bottomComponent, edition } = this.props;
    if (bottomComponent) {
      return bottomComponent;
    }
    if (!edition) {
      return null;
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Partial<Edition>' is not assigna... Remove this comment to see the full error message
    const availableState = editionEntityHelpers.isAvailable(edition)
      ? CheetahStoryState.AVAILABLE
      : CheetahStoryState.UNAVAILABLE;
    return (
      <div className={style.dateRow}>
        <StoryStateIcon icon={statusIdToIcon[availableState]} />
        <div className={style.date}>{edition.firstLiveDate && moment(edition.firstLiveDate).format('M/D')}</div>
      </div>
    );
  };

  renderLogoImage = () => {
    if (this.props.tile && !this.props.tile.isLogoEnabled) {
      return null;
    }
    return (
      <img
        src={this.getLogoImage()!}
        crossOrigin={CrossOrigin.USE_CREDENTIALS}
        alt={this.props.publisherName}
        className={style.horizontalIcon}
      />
    );
  };

  render() {
    const className = classNames(style.editionTilePreview, this.props.className, {
      [style.missingTile]: !this.getTileImage(),
    });
    return (
      <div
        onClick={this.props.onClick}
        className={className}
        data-test="EditionTilePreview.container"
        style={this.getEditionStyle()}
      >
        <div className={style.overlays}>
          <div className={style.horizontalIconRow}>{this.renderLogoImage()}</div>
          <div className={style.headlineOverlay}>{this.getHeadlineText()}</div>
          <div className={style.gradientOverlay} />
        </div>
        {this.renderOptionalTileImage()}
        {this.renderBottomComponent()}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, null)(EditionTilePreview);
