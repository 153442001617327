import AntdTimePicker from 'antd/lib/time-picker';
import type { TimePickerProps } from 'antd/lib/time-picker';
import React, { memo } from 'react';

import style from './SDSTimePicker.scss';

function SDSTimePicker(props: TimePickerProps) {
  return <AntdTimePicker {...props} className={style.timePicker} popupClassName={style.timePickerPopup} />;
}

export default memo(SDSTimePicker);
