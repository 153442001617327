import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import * as analyticsActions from 'state/analytics/actions/analyticsActions';
import { getDaily, getInsights, getPublisherEditionHistoryInfo } from 'state/analytics/selectors/analyticsSelectors';
import * as autocompleteActions from 'state/autocomplete/actions/autocompleteActions';
import * as autocompleteSelectors from 'state/autocomplete/selectors/autocompleteSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapcodesActions from 'state/snapcodes/actions/snapcodesActions';
import { getDecoratedSnapcodesPerPublisherId } from 'state/snapcodes/selectors/snapcodesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import {
  ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT,
  AnalyticsCountryType,
  DailyMetricType,
  SnapTag,
} from 'config/constants';
import { chart, cross, printer } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { registerIntlMessage } from 'utils/intlMessages/intlMessages';
import { printWindow } from 'utils/locationUtils';
import { withRouter } from 'utils/routerUtils';
import { getSnapcodeImageUrl } from 'utils/snapcodeUtils';
import { toAnalyticsTimeStamp } from 'utils/time/analyticsTimeUtils';

import { versionCounter } from 'views/analytics/containers/AnalyticsDailyView/analyticsVersionCounter';
import LoadingView from 'views/common/components/LoadingView/LoadingView';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';

import AnalyticsInsightsPublisherReport from './AnalyticsInsightsPublisherReport';
import style from './AnalyticsInsightsReportWrapper.scss';

import type { DailyAnalytics, InsightsAnalytics } from 'types/analytics';
import { Claim } from 'types/permissions';
import type { Publisher, PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';
import type { Snapcode } from 'types/snapcodes';

type ExternalProps = {
  publisherId: PublisherID;
};

type StateProps = {
  publisherId: number | null | undefined;
  publisher: Publisher | undefined | null;
  insightsAnalytics: InsightsAnalytics;
  dailyAnalytics: DailyAnalytics | undefined | null;
  sccTagsMap: {
    [x: string]: string;
  };
  snapcodes: Snapcode[];
  isShow: boolean;
  publisherEditionHistoryInfo: {
    startDate?: string;
    count?: number;
  };
};

type OwnProps = {
  breadcrumbs: any;
  history: any;
  location: any;
  match: any;
};

type OwnState = {
  from: moment.Moment;
  to: moment.Moment;
  isLoadingDailyMetrics: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-loading-view-message"
      description="Spinner Text that appears when loading the report"
      defaultMessage="Loading Report"
    />
  ),
  params: [],
});

const HEADER_TITLE_MESSAGE = (
  <FormattedMessage
    id="analytics-insights-report-header-title"
    description="Title that goes on the header of the the Analytics Insights Report page"
    defaultMessage="Analytics Insights Report"
  />
);

const mapStateToProps = (state: State, props: OwnProps): StateProps => {
  const publisherId = userSelectors.getActivePublisherId(state) || userSelectors.getActiveCreator(state)?.publisherId;

  return {
    publisherId,
    publisher: publisherId ? publishersSelectors.getPublisherDetailsDataById(state)(publisherId) : null,
    sccTagsMap: autocompleteSelectors.getSccCodeToTextMapping(state),
    dailyAnalytics: getDaily(state),
    insightsAnalytics: getInsights(state),
    snapcodes: getDecoratedSnapcodesPerPublisherId(state)(publisherId),
    publisherEditionHistoryInfo: getPublisherEditionHistoryInfo(state),
    isShow: publishersSelectors.activePublisherIsShow(state),
  };
};

const mapDispatchToProps = {
  fetchDailyStats: analyticsActions.fetchDailyStats,
  loadSCCTags: autocompleteActions.autocompleteSCCGetTags,
  loadSnapcodes: snapcodesActions.loadPublisherSnapcodes,
  fetchInsightsTopPerformingStoriesStats: analyticsActions.fetchInsightsTopPerformingStoriesStats,
  fetchPublisherEditionHistoryInfo: analyticsActions.fetchPublisherEditionHistoryInfo,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = DispatchProps & ExternalProps & StateProps & OwnProps;
/*
This in the (near) future will be a button which returns the rendered document as download when all components are loaded)
For the moment as we're developing we just display the document (the path to this is unaccessible to non-super users)
 */
export class AnalyticsInsightsReportWrapper extends React.Component<Props, OwnState> {
  static path = '/publisher/:publisherId/analytics/insights/report';

  state = {
    isLoadingDailyMetrics: true,
    from: toAnalyticsTimeStamp(moment()).date(0).startOf('month'),
    to: toAnalyticsTimeStamp(moment()).date(0).endOf('month'),
  };

  componentDidMount() {
    const publisherId = this.props.publisherId;
    this.props.loadSnapcodes(publisherId);
    this.getAnalyticsInsightsTopStoriesData(publisherId, this.state.from, this.state.to);
    this.getAnalyticsDailyData(publisherId, this.state.from, this.state.to);
    if (this.props.publisher && this.props.publisher.tags) {
      this.props.loadSCCTags();
    }
    this.props.fetchPublisherEditionHistoryInfo({ publisherId });
  }

  getAnalyticsDailyData = (publisherId: PublisherID, from: moment.Moment, to: moment.Moment) => {
    const commonArgs = {
      version: versionCounter.version,
      publisherId,
      startDate: from.format('YYYY-MM-DD'),
      endDate: to.format('YYYY-MM-DD'),
      type: AnalyticsCountryType.GLOBAL,
      countries: '',
    };
    Promise.all(
      Object.values(DailyMetricType).map(metric => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ metric: string; version: numbe... Remove this comment to see the full error message
        return this.props.fetchDailyStats({ ...commonArgs, metric });
      })
    ).then(() => {
      this.setState({ isLoadingDailyMetrics: false });
    });
  };

  getAnalyticsInsightsTopStoriesData = (publisherId: PublisherID, from: moment.Moment, to: moment.Moment) => {
    const topPerformingStoriesArgs = {
      publisherId,
      geoType: 'GLOBAL',
      startDate: from.format('YYYY-MM-DD'),
      endDate: to.format('YYYY-MM-DD'),
      countries: '',
    };
    Promise.all([this.props.fetchInsightsTopPerformingStoriesStats({ ...topPerformingStoriesArgs })]).then(() => {
      this.props.insightsAnalytics.topPerformingStories.storyStats.map(p => p.metadata.editionId);
    });
  };

  getTopPerformingStoriesStats = () => {
    return _.sortBy(
      this.props.insightsAnalytics.topPerformingStories.storyStats,
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      analyticsStory => parseInt(analyticsStory.stats.avgTimeViewed, 10)
    ).reverse();
  };

  getTags = () => {
    if (this.props.publisher && this.props.publisher.tags) {
      const publisherTags = _.map(
        this.props.publisher.tags[SnapTag.SCC],
        (tag: string) => this.props.sccTagsMap[tag]
      ).filter(Boolean);
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      publisherTags.sort((a, b) => a.length - b.length);
      return publisherTags;
    }

    return [];
  };

  getSnapcodeURL = () => {
    const { snapcodes } = this.props;
    // no snapcodes has loaded yet or there are none
    if (!snapcodes || snapcodes.length === 0) {
      return null;
    }
    // We provide the publisher with the latest snapcode created
    const snapcode = snapcodes[snapcodes.length - 1];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Snapcode | undefined' is not ass... Remove this comment to see the full error message
    return getSnapcodeImageUrl(snapcode);
  };

  goBackButtonHandler = () => {
    const {
      history: { goBack },
    } = this.props;
    goBack();
  };

  renderDocument = () => {
    if (
      !this.props.publisher ||
      this.state.isLoadingDailyMetrics ||
      !this.props.dailyAnalytics ||
      !this.props.insightsAnalytics ||
      !this.props.publisherEditionHistoryInfo ||
      this.props.insightsAnalytics.topPerformingStories === ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT ||
      !this.props.snapcodes
    ) {
      return <LoadingView messageId="analytics-insights-report-loading-view-message" />;
    }
    const numStories = this.props.publisherEditionHistoryInfo.count;
    const startDate = this.props.publisherEditionHistoryInfo.startDate;
    const firstPublish = startDate ? moment(startDate) : null;
    const snapcodeURL = this.getSnapcodeURL();
    const dailyAnalytics = _.get(this.props, ['dailyAnalytics']);
    const publisherFormalName = _.get(this.props.publisher, ['formalName']);
    const publisherDescription = _.get(this.props.publisher, ['description']);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
    const totalViewers = parseInt(_.get(this.props.dailyAnalytics, ['kpiComparison', 'currentRange', 'mau']), 10);
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <AnalyticsInsightsPublisherReport
        isShow={this.props.isShow}
        publisherName={publisherFormalName}
        storiesPublished={numStories}
        firstPublish={firstPublish}
        topPerformingStoriesStats={this.getTopPerformingStoriesStats()}
        publisherDescription={publisherDescription}
        reportStartDate={this.state.from}
        reportEndDate={this.state.to}
        totalViewers={totalViewers}
        publisherTags={this.getTags()}
        snapcodeURL={snapcodeURL}
        publisherId={this.props.publisherId}
        dailyAnalytics={dailyAnalytics}
      />
    );
  };

  renderPrintButton() {
    return (
      <div className={style.buttonContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          onClick={printWindow}
          inlineIcon={printer}
          data-test="analytics.insightsPublisher.report.save.button"
        />
      </div>
    );
  }

  renderGoBackButton() {
    return (
      <div className={style.buttonContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          onClick={this.goBackButtonHandler}
          inlineIcon={cross}
          data-test="analytics.insightsPublisher.report.close.button"
        />
      </div>
    );
  }

  render() {
    return (
      <ClaimGatedView requiredClaim={Claim.ANALYTICS_VIEWER}>
        <div className={style.parent}>
          <div className={style.header}>
            <div className={style.headerText}>
              <InlineSVG src={chart} className={style.analyticsIcon} alt="" />
              {HEADER_TITLE_MESSAGE}
            </div>
            <div className={style.headerButtons}>
              {this.renderPrintButton()}
              {this.renderGoBackButton()}
            </div>
          </div>
          <div className={style.content}>
            <div className={classNames(style.pageDirection, style.pageSizeTransform)}>{this.renderDocument()}</div>
          </div>
        </div>
      </ClaimGatedView>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsInsightsReportWrapper));
