import classNames from 'classnames';
import * as React from 'react';

import { CrossOrigin } from 'config/constants';
import { createAssetUrl } from 'utils/media/assetUtils';

import GraphTooltip from './GraphTooltip';
import style from './GraphTooltip.scss';
import HeaderText from './HeaderText';

type Props = {
  pollTimeline?: any;
  d?: any;
  valueFormatter?: (...args: any[]) => any;
};

/**
 * Tooltip for use specifically on poll timeline graphs. Shows the question and choices from the poll plus
 * the vote count at the time under the cursor.
 */
export default class PollTimelineTooltip extends React.Component<Props> {
  getSeriesRows() {
    const { series } = this.props.d;
    const maxValue = Math.max(...series.map((s: any) => s.value));
    const question = this.props.pollTimeline.snap.questions[0];
    return series.map((s: any, index: any) => {
      const option = question.options[index];
      const isMaxValue = s.value === maxValue;
      const keyClass = isMaxValue ? style.highlightedTitle : style.nonHighlightedTitle;
      const valueClass = isMaxValue ? style.highlightedPercentage : style.nonHighlightedPercentage;
      return (
        <tr key={option.id} data-test="poll-timeline-option">
          <td className="legend-color-guide">
            <div style={{ backgroundColor: s.color }} />
          </td>
          <td className={classNames('key', keyClass)}>{option.description}</td>
          {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
          <td className={classNames('value', valueClass)}>{this.props.valueFormatter(s.value)}</td>
          <td>
            <span className={style.choiceThumbnail}>
              <img src={createAssetUrl(option.imageAssetId)} crossOrigin={CrossOrigin.USE_CREDENTIALS} alt="choice" />
            </span>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <GraphTooltip {...this.props}>
        <div>
          <table>
            <thead>
              <HeaderText {...this.props} />
            </thead>
            <tbody>{this.getSeriesRows()}</tbody>
          </table>
        </div>
      </GraphTooltip>
    );
  }
}
