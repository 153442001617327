import { ApolloError } from '@apollo/client';
import React, { ReactNode } from 'react';

import { useAppealTileModerations } from 'gql/hooks/__ssp-generated__/useAppealTileModerations';
import { useAppealTileModerationsMutation } from 'gql/hooks/useAppealTileModerationsMutation';

type Props = {
  children: ReactNode;
};

export interface WithAppealTileModerationsMutationProps {
  appealTileModerations: ReturnType<typeof useAppealTileModerationsMutation>['appealTileModerations'];
  appealTileModerationsData?: useAppealTileModerations;
  appealTileModerationsError?: ApolloError;
}

export default function withAppealTileModerationsMutation(Component: typeof React.Component) {
  function WrappedComponent(props: Props) {
    const { appealTileModerations, data, error } = useAppealTileModerationsMutation();
    return (
      <Component
        {...props}
        appealTileModerations={appealTileModerations}
        appealTileModerationsData={data}
        appealTileModerationsError={error}
        data-test={'withAppealTileModerationsMutation.Component'}
      >
        {props.children}
      </Component>
    );
  }
  return WrappedComponent;
}
