import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import reactCSS from 'reactcss';
import InlineSVG from 'svg-inline-react';

import { CrossOrigin } from 'config/constants';
import logoArticlePreviewExample from 'images/logoArticlePreviewExample.png';
import logoSearchPreviewExample from 'images/logoSearchPreviewExample.png';
import logoSnapcodePreviewExample from 'images/logoSnapcodePreviewExample.png';
import logoTilePreviewExample from 'images/logoTilePreviewExample.png';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as colorUtils from 'utils/media/colorUtils';

import { DotsProgress } from 'views/common/components/DotsProgress/DotsProgress';
import { Spinner, SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/arrow_up.svg.inline' or ... Remove this comment to see the full error message
import arrowUp from 'icons/arrow_up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/subscribe.svg.inline' or... Remove this comment to see the full error message
import subscribe from 'icons/subscribe.svg.inline';

import style from './PublisherLogoPreview.scss';

import { MediaLoading } from 'types/publisherSettings';

const CurrentPreview = enumObject({
  TILE_PREVIEW: 'TILE_PREVIEW',
  SEARCH_PREVIEW: 'SEARCH_PREVIEW',
  SUBSCRIBE_PREVIEW: 'SUBSCRIBE_PREVIEW',
  SNAPCODE_PREVIEW: 'SNAPCODE_PREVIEW',
});

type CurrentPreviewType = Enum<typeof CurrentPreview>;

type Props = {
  title: string | ReactNode;
  className?: string;
  horizontalLogo: string | undefined | null;
  squareLogo: string | undefined | null;
  color: string | undefined | null;
  secondaryColor: string | undefined | null;
  name: string;
  description: string;
  isLoading: MediaLoading;
};

type State = {
  currentPreview: CurrentPreviewType;
};

export class PublisherLogoPreview extends React.PureComponent<Props, State> {
  state = {
    currentPreview: CurrentPreview.TILE_PREVIEW,
  };

  getCurrentIndex = () => {
    let currentIndex = 0;
    for (let i = 0; i < this.previewOrder.length; i++) {
      if (this.previewOrder[i] === this.state.currentPreview) {
        currentIndex = i;
        break;
      }
    }
    return currentIndex;
  };

  previewOrder = [
    CurrentPreview.TILE_PREVIEW,
    CurrentPreview.SEARCH_PREVIEW,
    CurrentPreview.SUBSCRIBE_PREVIEW,
    CurrentPreview.SNAPCODE_PREVIEW,
  ];

  nextPreview = () => {
    const { length } = this.previewOrder;
    const nextIndex = (this.getCurrentIndex() + length + 1) % length;
    const currentPreview = this.previewOrder[nextIndex];
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.setState({ currentPreview });
  };

  lastPreview = () => {
    const { length } = this.previewOrder;
    const prevIndex = (this.getCurrentIndex() + length - 1) % length;
    const currentPreview = this.previewOrder[prevIndex];
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.setState({ currentPreview });
  };

  gotoPreview = (currentPreview: CurrentPreviewType) => {
    this.setState({ currentPreview });
  };

  renderLoadingSpinner = (isSnapcodeView?: any) => {
    if (isSnapcodeView) {
      return (
        <>
          <div className={style.spinnerContainer}>
            <Spinner loading message={SpinnerLabels.UPLOADING} size={SpinnerSizes.MEDIUM} className={style.logo} />
          </div>
          <img
            src={logoSearchPreviewExample}
            className={style.background}
            crossOrigin={CrossOrigin.USE_CREDENTIALS}
            alt="Search background"
            data-test="publisherLogopreview.searchBackGround"
          />
        </>
      );
    }

    return (
      <div className={style.spinnerContainer}>
        <Spinner loading message={SpinnerLabels.UPLOADING} size={SpinnerSizes.MEDIUM} className={style.logo} />
      </div>
    );
  };

  render() {
    const rootClasses = classNames(style.parent, this.props.className);

    const tilePreviewClasses = classNames({
      [style.preview]: true,
      [style.enabled]: this.state.currentPreview === CurrentPreview.TILE_PREVIEW,
    });

    const colorRGBA = colorUtils.hexToRgbaStyle(this.props.color || '', 0.5);
    const colorClear = colorUtils.hexToRgbaStyle(this.props.color || '', 0);
    const gradientStyle = reactCSS({
      default: {
        overlay: {
          backgroundImage: `linear-gradient(to bottom, ${colorRGBA} 0%, ${colorClear} 50%)`,
        },
      },
    });

    const primaryColorStyle = reactCSS({
      default: {
        background: {
          backgroundColor: this.props.color,
        },
      },
    });

    const searchPreviewClasses = classNames({
      [style.preview]: true,
      [style.enabled]: this.state.currentPreview === CurrentPreview.SEARCH_PREVIEW,
    });

    const subscribePreviewClasses = classNames({
      [style.preview]: true,
      [style.enabled]: this.state.currentPreview === CurrentPreview.SUBSCRIBE_PREVIEW,
    });

    const subscribeButtonClasses = classNames({
      [style.subscribeButton]: true,
      [style.black]: this.props.secondaryColor === '#000000',
    });

    const snapcodePreviewClasses = classNames({
      [style.preview]: true,
      [style.enabled]: this.state.currentPreview === CurrentPreview.SNAPCODE_PREVIEW,
    });

    return (
      <div className={rootClasses}>
        <div className={style.previewWithArrows} data-test="publisherLogopreview.arrow">
          <InlineSVG onClick={this.lastPreview} className={classNames(style.arrowButton, style.back)} src={arrowUp} />
          <div className={style.previewContainer}>
            <div className={tilePreviewClasses} data-test="publisherLogopreview.container">
              {this.props.isLoading.horizontalIcon ? (
                this.renderLoadingSpinner()
              ) : (
                <>
                  {this.props.color ? <div className={style.gradientOverlay} style={gradientStyle.overlay} /> : ''}
                  {this.props.horizontalLogo ? (
                    <img
                      src={this.props.horizontalLogo}
                      crossOrigin={CrossOrigin.USE_CREDENTIALS}
                      className={style.logo}
                      alt="Tile logo"
                      data-test="publisherLogopreview.tileImage"
                    />
                  ) : (
                    ''
                  )}
                </>
              )}
              <img
                src={logoTilePreviewExample}
                className={style.background}
                crossOrigin={CrossOrigin.USE_CREDENTIALS}
                alt="Tile background"
                data-test="publisherLogopreview.tileImageBg"
              />
            </div>
            <div className={searchPreviewClasses}>
              <div className={style.searchOverlay}>
                {this.props.isLoading.squareIcon ? (
                  this.renderLoadingSpinner()
                ) : (
                  <>
                    <div className={style.searchText}>{this.props.name.toLowerCase()}</div>
                    <div className={style.searchResults}>
                      {this.props.squareLogo ? (
                        <img
                          src={this.props.squareLogo}
                          crossOrigin={CrossOrigin.USE_CREDENTIALS}
                          className={style.logo}
                          alt="Tile logo"
                        />
                      ) : (
                        ''
                      )}
                      <div className={style.resultsText}>
                        <div className={style.resultsTitle}>{this.props.name}</div>
                        <div className={style.resultsDescription}>{this.props.description}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <img
                src={logoSearchPreviewExample}
                crossOrigin={CrossOrigin.USE_CREDENTIALS}
                className={style.background}
                alt="Search background"
                data-test="publisherLogopreview.searchBackGround"
              />
            </div>
            <div className={subscribePreviewClasses}>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ backgroundColor: string | null | undefined... Remove this comment to see the full error message */}
              <div className={style.articleBackground} style={primaryColorStyle.background}>
                {this.props.isLoading.horizontalIcon ? (
                  this.renderLoadingSpinner()
                ) : (
                  <>
                    {this.props.horizontalLogo ? (
                      <img
                        src={this.props.horizontalLogo}
                        crossOrigin={CrossOrigin.USE_CREDENTIALS}
                        className={style.logo}
                        alt="Tile logo"
                      />
                    ) : (
                      ''
                    )}
                    <div className={subscribeButtonClasses}>
                      <InlineSVG className={style.subscribeIcon} src={subscribe} />
                      {getMessageFromId('subscribe')}
                    </div>
                  </>
                )}
              </div>
              <img
                src={logoArticlePreviewExample}
                crossOrigin={CrossOrigin.USE_CREDENTIALS}
                className={style.background}
                alt="Tile background"
                data-test="publisherLogopreview.tileBackGround"
              />
            </div>
            <div className={snapcodePreviewClasses}>
              {this.props.isLoading.squareIcon ? (
                this.renderLoadingSpinner(true)
              ) : (
                <>
                  {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ backgroundColor: string | null | undefined... Remove this comment to see the full error message */}
                  <div className={style.snapcodeInside} style={primaryColorStyle.background}>
                    {this.props.squareLogo && (
                      <img
                        src={this.props.squareLogo}
                        crossOrigin={CrossOrigin.USE_CREDENTIALS}
                        className={style.logo}
                        alt="Tile logo"
                      />
                    )}
                  </div>
                  <img
                    src={logoSnapcodePreviewExample}
                    className={style.snapcodeBackground}
                    alt="Snapcode background"
                    data-test="publisherLogopreview.snapCodeBackGround"
                  />
                </>
              )}
            </div>
          </div>
          <InlineSVG onClick={this.nextPreview} className={style.arrowButton} src={arrowUp} />
        </div>
        <DotsProgress
          className={style.dotsProgress}
          value={this.state.currentPreview}
          options={this.previewOrder}
          onChange={this.gotoPreview}
        />
      </div>
    );
  }
}

export default PublisherLogoPreview;
