import React from 'react';

import style from '../storySnapMenuCardStyle.scss';

import { upload, trash } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';

type Props = {
  name: string;
  clearMedia: () => void;
};

export default function MediaCard({ name, clearMedia }: Props) {
  return (
    <div className={style.cardContainer}>
      <div className={style.cardInfo}>
        <Icon inlineIcon={upload} className={style.icon} />
        <div className={style.cardTitle}>
          <div className={style.heading}>{name}</div>
        </div>
      </div>
      <div className={style.toggleWrapper}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          data-test="MediaCard.delete.button"
          onClick={clearMedia}
          inlineIcon={trash}
        />
      </div>
    </div>
  );
}
