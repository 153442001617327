import { get } from 'lodash';

import { createDynamicKeySelector, createKeySelector } from 'state/common/selectorFactories';

import { EMPTY_OBJECT } from 'config/constants';

import type { Org } from 'types/organisations';
import type { State } from 'types/rootState';

function getOrganisations(state: State) {
  return get(state, ['organisations']) || {};
}

export const getOrgsList = createKeySelector(getOrganisations, 'orgsList', []);
export const isOrgLoading = createKeySelector(getOrganisations, 'isLoadingOrg', false);
export const getOrgByBusinessProfileIdMap = createKeySelector(getOrganisations, 'byBusinessProfileId', EMPTY_OBJECT);
export const getOrgByBusinessProfileId = createDynamicKeySelector<Org | undefined | null, string>(
  getOrgByBusinessProfileIdMap,
  null
);
