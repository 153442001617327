import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getActivePublisherOrgId } from 'state/publishers/selectors/publishersSelectors';
import { setSelectedAdAccountId } from 'state/user/actions/userActions';
import { getActiveCreatorBusinessProfileId, getSelectedAdAccountId } from 'state/user/selectors/userSelectors';

import SDSDropdown, { DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import { useProfileAdAccountsQuery } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';

import style from './AdAccountPicker.scss';

import { DropdownOption } from 'types/SDSDropdown';

const filterDropdownOption = (
  inputValue: string,
  dropdownOption: {
    props: {
      children: string;
    };
  }
): boolean => {
  const text = dropdownOption.props.children.toLocaleLowerCase();
  return text.includes(inputValue.toLocaleLowerCase());
};

const AdAccountPicker: FC = () => {
  const orgId = useSelector(getActivePublisherOrgId);
  const bizProfileId = useSelector(getActiveCreatorBusinessProfileId);
  const selectedAdAccountId = useSelector(getSelectedAdAccountId);

  const dispatch = useDispatch();

  const { adAccounts = [] } = useProfileAdAccountsQuery(orgId, bizProfileId);

  const onChange = useCallback(
    (newAdAccountId: string) => {
      dispatch(setSelectedAdAccountId(newAdAccountId));
    },
    [dispatch]
  );

  const options = useMemo(
    () => createSDSDropdownOptions(adAccounts.map(({ id, name }): DropdownOption => ({ label: name, value: id }))),
    [adAccounts]
  );

  // no need to show the dropdown if there's only one ad account (or none)
  if (adAccounts.length <= 1) {
    return null;
  }

  return (
    <div className={style.root}>
      <SDSDropdown
        onChange={onChange}
        data-test="adAccountPicker.dropDown"
        value={selectedAdAccountId ?? adAccounts[0]!.id}
        filterOption={filterDropdownOption}
        type={DropdownType.GREY}
        showSearch
        disableClear
        dropdownWidthMatchContent
      >
        {options}
      </SDSDropdown>
    </div>
  );
};

export default AdAccountPicker;

AdAccountPicker.displayName = 'AdAccountPicker';
