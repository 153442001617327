import { ColumnProps } from 'antd/lib/table/interface';
import _, { get } from 'lodash';
import moment from 'moment-timezone';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { showModal } from 'state/modals/actions/modalsActions';
import { getOrgOnboardingsList } from 'state/orgOnboardings/selectors/orgOnboardingsSelectors';

import { plus, search } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { ModalType } from 'utils/modalConfig';
import { guessTimezone } from 'utils/timezoneUtils';

import Header from 'views/common/components/Header/Header';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSTable from 'views/common/components/SDSTable/SDSTable';

import style from './OrgOnboardingsManagement.scss';

import { OrgOnboarding } from 'types/orgOnboarding';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

const mapStateToProps = (state: State) => {
  return {
    orgOnboardingsList: getOrgOnboardingsList(state),
  };
};

const mapDispatchToProps = {
  showModal,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;
type OwnState = {
  query: string;
  sortKey: string;
  sortOrder: 'desc' | 'asc';
};

export class OrgOnboardingsManagement extends React.PureComponent<Props, OwnState> {
  state: OwnState = {
    query: '',
    sortKey: 'createdAt',
    sortOrder: 'desc',
  };

  getColumns = (): Array<ColumnProps<OrgOnboarding>> => {
    return [
      {
        title: (
          <FormattedMessage
            id="org-onboarding-management-username"
            defaultMessage="Username"
            description="Username column title on the organisation onboarding management page"
          />
        ),
        dataIndex: 'mutableUsername',
        key: 'mutableUsername',
      },
      {
        title: (
          <FormattedMessage
            id="org-onboarding-management-user-id"
            defaultMessage="User Identifier"
            description="User Identifier column title on the organisation onboarding management page"
          />
        ),
        dataIndex: 'userId',
        key: 'userId',
      },
      {
        title: (
          <FormattedMessage
            id="org-onboarding-management-manager-email"
            defaultMessage="Manager Email"
            description="Manager Email column title on the organisation onboarding management page"
          />
        ),
        dataIndex: 'managerEmail',
        key: 'managerEmail',
      },
      {
        title: (
          <FormattedMessage
            id="org-onboarding-management-added-at"
            defaultMessage="Added At"
            description="Added At column title on the organisation onboarding management page"
          />
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt: Date) => (
          <span>{moment.tz(createdAt, guessTimezone().name).format('YYYY-MM-DD HH:mm:ss z')}</span>
        ),
      },
    ];
  };

  onSearchChange = (event: ChangeEvent<EventTarget>) => {
    const query = get(event, 'target.value', '');
    this.setState({ query });
  };

  createOrgOnboarding = () => {
    this.props.showModal(ModalType.ORG_ONBOARDING, 'organisationOnboarding', {});
  };

  editOrgOnboarding = (onboarding: OrgOnboarding) => {
    this.props.showModal(ModalType.ORG_ONBOARDING, 'organisationOnboarding', { onboardingId: onboarding.id });
  };

  onRow = (onboarding: OrgOnboarding) => ({
    onClick: () => this.editOrgOnboarding(onboarding),
  });

  renderRightElements() {
    return (
      <div className={style.rightElements}>
        <div className={style.searchInput}>
          <SDSInput
            value={this.state.query}
            onChange={this.onSearchChange}
            inlineIcon={search}
            autoFocus
            data-test="views.snapAdmin.OrgOnboardingsManagement.search"
          />
        </div>
        <SDSButton
          inlineIcon={plus}
          type={ButtonType.PRIMARY}
          onClick={this.createOrgOnboarding}
          data-test="views.snapAdmin.OrgOnboardingsManagement.add"
        >
          <FormattedMessage
            id="org-onboarding-management-add-user"
            defaultMessage="Add User"
            description="Add User for organisation onboarding button"
          />
        </SDSButton>
      </div>
    );
  }

  getDataSource() {
    const query = this.state.query.toLowerCase();
    const filtered = this.props.orgOnboardingsList.filter((onboarding: OrgOnboarding) =>
      onboarding.mutableUsername?.toLowerCase().includes(query)
    );
    return _.orderBy(filtered, [this.state.sortKey], [this.state.sortOrder]);
  }

  render() {
    return (
      <section>
        <Header
          title={
            <FormattedMessage
              id="org-onboarding-management-title"
              defaultMessage="Users allowed for organisation onboarding"
              description="Title for organisation onboarding management page"
            />
          }
          rightElements={this.renderRightElements()}
          positionStatic
        />
        <div className={style.container}>
          <SDSTable
            columns={this.getColumns()}
            dataSource={this.getDataSource()}
            rowKey="id"
            pagination={false}
            onRow={this.onRow}
          />
        </div>
      </section>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(OrgOnboardingsManagement);
