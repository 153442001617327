import classNames from 'classnames';
import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { renderViolations } from 'state/buildStatus/schema/moderationHelpers';
import { getTileId } from 'state/tiles/schema/tilesIdUtils';
import { getUserBitmojiAvatarId } from 'state/user/selectors/userSelectors';

import { BITMOJI_TEMPLATES, DEFAULT_BITMOJI_AVATAR, getBitmojiUrl } from 'config/bitmojiConfig';
import { TileFormat, TileFormatConfig } from 'config/tileConfig';
import withAppealTileModerationsMutation, {
  WithAppealTileModerationsMutationProps,
} from 'gql/hocs/withAppealTileModerationsMutation';
import { intlConnect } from 'utils/connectUtils';

import Icon from 'views/common/components/Icon/Icon';
import SDSCheckbox, { CheckboxEvent } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSModal from 'views/common/components/SDSModal/SDSModal';
import SDSTextArea from 'views/common/components/SDSTextArea/SDSTextArea';
import { TilePreview } from 'views/editor/containers/TilePreview/TilePreview';

import style from './TileViolaionAppealModal.scss';

import { EditionID } from 'types/editionID';
import { ModalId } from 'types/modal';
import { ModerationViolationEnum } from 'types/moderation';
import { State } from 'types/rootState';
import { Tile, TileID } from 'types/tiles';

type TileWithModeration = {
  tile: Tile;
  moderationViolations: Array<ModerationViolationEnum>;
};

type OwnProps = {
  hideModal: (modalId: ModalId) => void;
  modalId: ModalId;
  options: {
    storyId: EditionID;
    tilesWithModeration: Array<TileWithModeration>;
  };
};

const mapStateToProps = (state: State) => {
  return {
    bitmojiAvatarId: getUserBitmojiAvatarId(state) || DEFAULT_BITMOJI_AVATAR,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & WithAppealTileModerationsMutationProps & StateProps;

export enum AppealStep {
  SELECTION,
  JUSTIFICATION,
  CONFIRMATION,
}

// Minimum/Maximum length of the appeal justification for each tile.
const JUSTIFICATION_LENGTH_MIN = 150;
const JUSTIFICATION_LENGTH_MAX = 300;

const modalTitles = {
  [AppealStep.SELECTION]: {
    title: (
      <span data-test="TileViolationAppealModal.Title.Selection">
        <FormattedMessage
          id="violation-appeal-modal-selection-title"
          description="Title for the Selection section of violation appeal modal"
          defaultMessage="Violation Appeal"
        />
      </span>
    ),
    subtitle: (
      <span data-test="TileViolationAppealModal.SubTitle.Selection">
        <FormattedMessage
          id="violation-appeal-modal-selection-subtitle"
          description="Subtitle for the Selection section of violation appeal modal"
          defaultMessage="Please select tiles you wish to start an appeal for."
        />
      </span>
    ),
  },
  [AppealStep.JUSTIFICATION]: {
    title: (
      <span data-test="TileViolationAppealModal.Title.Justification">
        <FormattedMessage
          id="violation-appeal-modal-justification-title"
          description="Title for the Justification section of violation appeal modal"
          defaultMessage="Appeal Justification"
        />
      </span>
    ),
    subtitle: (
      <span data-test="TileViolationAppealModal.SubTitle.Justification">
        <FormattedMessage
          id="violation-appeal-modal-justification-subtitle"
          description="Subtitle for the Justification section of violation appeal modal"
          defaultMessage="Please enter a brief justification for the tile below."
        />
      </span>
    ),
  },
  [AppealStep.CONFIRMATION]: {
    title: (
      <span data-test="TileViolationAppealModal.Title.Confirmation">
        <FormattedMessage
          id="violation-appeal-modal-confirmation-title"
          description="Title for the Confirmation section of violation appeal modal"
          defaultMessage="All Set!"
        />
      </span>
    ),
    // We need an empty subtitles so the modal is the same size on the confirmation.
    subtitle: <span data-test="TileViolationAppealModal.SubTitle.Confirmation">&nbsp;</span>,
  },
};

type TileMap<T> = {
  [key in TileID]: T;
};

type OwnState = {
  step: AppealStep;
  selectedTiles: TileMap<boolean>;
  justificationStep: number;
  justifications: TileMap<string>;
  isSubmitting: boolean;
};

export class TileViolationAppealModal extends React.Component<Props, OwnState> {
  state: OwnState = {
    step: AppealStep.SELECTION,
    selectedTiles: {},
    justificationStep: 0,
    justifications: {},
    isSubmitting: false,
  };

  onBackClick = () => {
    const { step, justificationStep } = this.state;
    if (step === AppealStep.SELECTION) {
      this.props.hideModal(this.props.modalId);
    } else if (step === AppealStep.JUSTIFICATION && justificationStep > 0) {
      this.setState({ justificationStep: justificationStep - 1 });
    } else {
      this.setState({ step: step - 1 });
    }
  };

  onNextClick = async () => {
    const { step, justificationStep, selectedTiles } = this.state;
    const appealedTiles = this.getAppealedTiles(selectedTiles);
    if (step === AppealStep.CONFIRMATION) {
      this.props.hideModal(this.props.modalId);
    } else if (step === AppealStep.JUSTIFICATION && justificationStep < appealedTiles.length - 1) {
      this.setState({ justificationStep: justificationStep + 1 });
    } else if (step === AppealStep.JUSTIFICATION) {
      await this.onSubmit().then(() => this.setState({ step: step + 1 }));
    } else {
      this.setState({ step: step + 1 });
    }
  };

  isBackDisabled = () => {
    const { step, isSubmitting } = this.state;
    return step === AppealStep.CONFIRMATION || isSubmitting;
  };

  isNextDisabled = () => {
    const { step, justificationStep, selectedTiles, justifications, isSubmitting } = this.state;
    const appealedTiles = this.getAppealedTiles(selectedTiles);
    // At least one tile needs to be selected during the selection step.
    if (step === AppealStep.SELECTION) {
      return appealedTiles.length < 1;
    }
    // Each tile needs to have a justification with a required min length.
    if (step === AppealStep.JUSTIFICATION) {
      const appealedTile = appealedTiles[justificationStep];
      if (!appealedTile) {
        return false;
      }
      const tileJustification = justifications[appealedTile.tile.id];
      return !tileJustification || tileJustification.length < JUSTIFICATION_LENGTH_MIN;
    }
    // Next should be disabled when submitting.
    return isSubmitting;
  };

  getBackText() {
    const { step } = this.state;
    if (step === AppealStep.SELECTION) {
      return (
        <span data-test="TileViolationAppealModal.BackText.Cancel">
          <FormattedMessage
            id="violation-appeal-modal-cancel-button"
            description="Action button text to the cancel the violation appeal"
            defaultMessage="Cancel"
          />
        </span>
      );
    }
    return (
      <span data-test="TileViolationAppealModal.BackText.Back">
        <FormattedMessage
          id="violation-appeal-modal-back-button"
          description="Action button text to the previous step of violation appeal"
          defaultMessage="Back"
        />
      </span>
    );
  }

  getNextText() {
    const { step, justificationStep, selectedTiles } = this.state;
    const appealedTiles = this.getAppealedTiles(selectedTiles);
    if (step === AppealStep.CONFIRMATION) {
      return (
        <span data-test="TileViolationAppealModal.NextText.Close">
          <FormattedMessage
            id="violation-appeal-modal-close-button"
            description="Action button text to close the appeal modal"
            defaultMessage="Close"
          />
        </span>
      );
    }
    if (step === AppealStep.JUSTIFICATION && justificationStep === appealedTiles.length - 1) {
      return (
        <span data-test="TileViolationAppealModal.NextText.Submit">
          <FormattedMessage
            id="violation-appeal-modal-submit-button"
            description="Action button text to submit the appeal"
            defaultMessage="Submit"
          />
        </span>
      );
    }
    return (
      <span data-test="TileViolationAppealModal.NextText.Next">
        <FormattedMessage
          id="violation-appeal-modal-next-button"
          description="Action button to proceed to the next step of violation appeal"
          defaultMessage="Next"
        />
      </span>
    );
  }

  getAppealedTiles = _.memoize((selectedTiles: TileMap<boolean>) => {
    const { tilesWithModeration } = this.props.options;
    return tilesWithModeration.filter(({ tile }) => Boolean(selectedTiles[tile.id]));
  });

  getAppeal() {
    const { storyId } = this.props.options;
    const { selectedTiles, justifications } = this.state;
    const appealedTiles = this.getAppealedTiles(selectedTiles);
    const appeals = appealedTiles.flatMap(appealedTile => {
      const id = getTileId(appealedTile.tile);
      const justification = justifications[appealedTile.tile.id];
      if (!id || !justification) {
        return []; // This should never happen.
      }
      return [{ id, justification }];
    });
    return {
      storyId,
      appeals,
    };
  }

  onSubmit() {
    const { appealTileModerations } = this.props;
    const input = this.getAppeal();

    this.setState({ isSubmitting: true });
    return appealTileModerations({ variables: { input } }).finally(() => this.setState({ isSubmitting: false }));
  }

  handleTileSelection = _.memoize((tileId: TileID) => {
    return (event: CheckboxEvent<boolean>) => {
      const { selectedTiles } = this.state;
      this.setState({
        selectedTiles: {
          ...selectedTiles,
          [tileId]: event.target.checked,
        },
      });
    };
  });

  renderTileSelection(tile: Tile) {
    const { selectedTiles } = this.state;
    const isSelected = Boolean(selectedTiles[tile.id]);
    return (
      <div className={style.tilePreview} key={tile.id} data-test="TileViolationAppealModal.Step.Selection.Tile">
        <TilePreview format={TileFormatConfig[TileFormat.CHEETAH_MEDIUM]} scale={0.5} tile={tile} />
        <div className={style.tilePreviewCheckbox}>
          <SDSCheckbox
            data-test={`TileViolationAppealModal.Step.Selection.Tile.${tile.id}.Checkbox`}
            checked={isSelected}
            onChange={this.handleTileSelection(tile.id)}
          />
        </div>
      </div>
    );
  }

  renderSelection() {
    const { tilesWithModeration } = this.props.options;
    return (
      <section
        className={classNames(style.modalSection, style.modalSectionSelection)}
        data-test="TileViolationAppealModal.Step.Selection"
      >
        {tilesWithModeration.map(({ tile }) => this.renderTileSelection(tile))}
      </section>
    );
  }

  renderJustificationCounter(step: number, stepsCount: number) {
    return (
      <div className={style.justificationCounter} data-test="TileViolationAppealModal.Step.Justification.Counter">
        <FormattedMessage
          id="violation-appeal-modal-justification-step"
          description="Counter showing how many justifications are left to write and how"
          defaultMessage="{step} of {stepsCount}"
          values={{
            step,
            stepsCount,
          }}
        />
      </div>
    );
  }

  renderJustificationViolations(violations: ModerationViolationEnum[]) {
    return (
      <div data-test="TileViolationAppealModal.Step.Justification.Violations" className={style.justificationViolations}>
        <FormattedMessage
          id="violation-appeal-modal-justification-violation-category"
          description="Violation category of the appealed tile"
          defaultMessage="Violation Category: {violations}"
          values={{
            violations: <strong>{renderViolations(violations)}</strong>,
          }}
        />
      </div>
    );
  }

  renderJustificationInputLimits() {
    return (
      <div data-test="TileViolationAppealModal.Step.Justification.Limits" className={style.justificationTextLengths}>
        <FormattedMessage
          id="violation-appeal-modal-justification-input-limits"
          description="Limits for justification input length"
          defaultMessage="{min} / {max}"
          values={{
            min: JUSTIFICATION_LENGTH_MIN,
            max: JUSTIFICATION_LENGTH_MAX,
          }}
        />
      </div>
    );
  }

  handleJustificationInput = _.memoize((tileId: TileID) => {
    return (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { justifications } = this.state;
      this.setState({
        justifications: {
          ...justifications,
          [tileId]: event.target.value,
        },
      });
    };
  });

  renderJustification() {
    const { selectedTiles, justificationStep, justifications } = this.state;
    const appealedTiles = this.getAppealedTiles(selectedTiles);
    const appealedTile = appealedTiles[justificationStep];
    if (!appealedTile) {
      return null; // There will always be a tile here but check for type safety.
    }
    const tileJustification = justifications[appealedTile.tile.id];
    return (
      <section
        className={classNames(style.modalSection, style.modalSectionJustification)}
        data-test="TileViolationAppealModal.Step.Justification"
      >
        <TilePreview
          data-test="TileViolationAppealModal.Step.Justification.Preview"
          format={TileFormatConfig[TileFormat.CHEETAH_MEDIUM]}
          scale={0.5}
          tile={appealedTile.tile}
        />
        {this.renderJustificationCounter(justificationStep + 1, appealedTiles.length)}
        {this.renderJustificationViolations(appealedTile.moderationViolations)}
        <div className={style.justificationText}>
          <SDSTextArea
            data-test="TileViolationAppealModal.Step.Justification.TextArea"
            value={tileJustification}
            onChange={this.handleJustificationInput(appealedTile.tile.id)}
            disableResize
            maxLength={JUSTIFICATION_LENGTH_MAX}
          />
        </div>
        {this.renderJustificationInputLimits()}
      </section>
    );
  }

  renderConfirmation() {
    const { bitmojiAvatarId } = this.props;
    return (
      <section
        className={classNames(style.modalSection, style.modalSectionConfirmation)}
        data-test="TileViolationAppealModal.Step.Confirmation"
      >
        <div
          className={style.confirmationDescription}
          data-test="TileViolationAppealModal.Step.Confirmation.Description"
        >
          <FormattedMessage
            id="violation-appeal-modal-confirmation-subtitle"
            description="Subtitle for the Confirmation section of violation appeal modal"
            defaultMessage="Thank you for submitting your appeal. A member of our team will review your request. You will be notified via email of the outcome."
          />
        </div>
        <div className={style.confirmationAvatar}>
          <Icon
            data-test="TileViolationAppealModal.Step.Confirmation.Icon"
            className={style.confirmationAvatarIcon}
            img={getBitmojiUrl(BITMOJI_TEMPLATES.USER_CHECK, bitmojiAvatarId)}
          />
        </div>
      </section>
    );
  }

  renderStep() {
    switch (this.state.step) {
      case AppealStep.SELECTION:
        return this.renderSelection();
      case AppealStep.JUSTIFICATION:
        return this.renderJustification();
      case AppealStep.CONFIRMATION:
        return this.renderConfirmation();
      default:
        return null;
    }
  }

  render() {
    const { title, subtitle } = modalTitles[this.state.step];
    return (
      <SDSModal
        visible
        width={640}
        closable
        title={title}
        centerTitle
        subtitle={subtitle}
        onCancel={this.onBackClick}
        cancelText={this.getBackText()}
        cancelButtonProps={{
          disabled: this.isBackDisabled(),
        }}
        onOk={this.onNextClick}
        okText={this.getNextText()}
        okButtonProps={{
          disabled: this.isNextDisabled(),
        }}
      >
        <div>{this.renderStep()}</div>
      </SDSModal>
    );
  }
}

export default intlConnect(mapStateToProps, null)(withAppealTileModerationsMutation(TileViolationAppealModal));
