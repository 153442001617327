import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import type { ReactNode, ElementType } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import NVD3Chart from 'react-nvd3';

import { EMPTY_OBJECT } from 'config/constants';
import { help } from 'icons/SDS/allIcons';
import * as numberFormatter from 'utils/numberFormatter';

import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import 'nvd3/build/nv.d3.css';
import style from './Graph.scss';

type Props = {
  additionalProps?: {};
  chartType: string;
  data: unknown[];
  graphName: ReactNode;
  isLoading?: boolean;
  showPoints?: boolean;
  tooltip?: ReactNode;
  store?: {};
  snapPreviewFrames?: any;
  tooltipComponent: ElementType;
  elementClick?: (a: unknown) => void;
};

type Dispatcher = {
  on: (b: string, a: unknown) => void;
};

type ChartSubType = {
  dispatch?: Dispatcher;
};

type Chart = {
  multibar?: ChartSubType;
  pie?: ChartSubType;
  lines?: ChartSubType;
  stacked?: ChartSubType;
  dispatch?: Dispatcher;
};

export default class Graph extends React.Component<Props> {
  static contextTypes = {
    store: PropTypes.object,
  };

  onConfigure = (chart: Chart) => {
    // elementClick is implemented in the master branch of react-nvd3 but not in the latest release tag.
    // So implement it here.
    let dispatcher;
    let clickFuncName = 'elementClick';

    const { chartType } = this.props;
    switch (chartType) {
      case 'multiBarChart':
        dispatcher = chart.multibar && chart.multibar.dispatch;
        break;
      case 'pieChart':
        dispatcher = chart.pie && chart.pie.dispatch;
        break;
      case 'lineChart':
      case 'linePlusBarChart':
        dispatcher = chart.lines && chart.lines.dispatch;
        break;
      case 'stackedAreaChart':
        dispatcher = chart.stacked && chart.stacked.dispatch;
        clickFuncName = 'areaClick';
        break;
      default:
        dispatcher = chart.dispatch;
        break;
    }

    if (dispatcher && dispatcher.on) {
      dispatcher.on(clickFuncName, (e: any) => {
        if (this.props.elementClick) {
          this.props.elementClick(e);
        }
      });
    }
  };

  renderTooltip(msg?: unknown | null) {
    if (!msg) {
      return null;
    }
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <SDSTooltip placement={TooltipPosition.RIGHT} title={msg} id="tooltip">
        <Icon inlineIcon={help} className={style.infoIcon} />
      </SDSTooltip>
    );
  }

  render() {
    function getX(d: any) {
      if (d.x !== undefined) {
        return d.x;
      }
      return d[0];
    }

    function getY(d: any) {
      if (d.y !== undefined) {
        return d.y;
      }
      return d[1];
    }

    const props = _.defaultsDeep({}, this.props.additionalProps || EMPTY_OBJECT);
    return (
      <div className={classNames('card-box', style.root, { [style.showPoints]: this.props.showPoints })}>
        <h4 className={style.label}>
          {this.props.graphName}
          {this.renderTooltip(this.props.tooltip)}
        </h4>
        {this.props.isLoading ? (
          <SpinnerIcon className={style.loadingIcon} />
        ) : (
          <NVD3Chart
            ref="chart"
            type={this.props.chartType}
            datum={this.props.data}
            configure={this.onConfigure}
            x={getX}
            y={getY}
            xAxis={{ tickFormat: numberFormatter.showAsDate }}
            yAxis={{ tickFormat: numberFormatter.prettyPrintNumber }}
            forceY={[0]}
            reduceXTicks="true"
            useInteractiveGuideline="true"
            legend={{ maxKeyLength: 50 }}
            noData="Loading Data..."
            {...props}
            margin={{ right: 40 }}
            bars={{ forceY: [0] }}
            lines={{ forceY: [0, 1] }}
          />
        )}
      </div>
    );
  }
}
