import { useQuery } from '@apollo/client';

import { GetRevenue } from 'gql/queries/analytics/__ssp-generated__/GetRevenue';
import { GET_REVENUE } from 'gql/queries/analytics/revenuePublisherQuery';

export const useGetRevenue = (start: string, end: string, hostUserId: string, first: number, after: string = '') => {
  const { data, loading, fetchMore } = useQuery<GetRevenue>(GET_REVENUE, {
    variables: {
      input: {
        start,
        end,
        hostUserId,
      },
      first,
      after,
    },
    // We want fetchMore to set `loading` to true when invoked with new parameters.
    notifyOnNetworkStatusChange: true,
  });
  return { data, loading, fetchMore };
};
