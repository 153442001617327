import Switch from 'antd/lib/switch';
import type { SwitchProps } from 'antd/lib/switch';
import classNames from 'classnames';
import * as React from 'react';

import smallToggleStyle from 'views/common/components/SDSSwitch/SmallToggleSwitch.scss';

import style from './SDSSwitch.scss';

type Props = SwitchProps & {
  'data-test': string;
  isReadOnly?: boolean;
  isSmallToggle?: boolean;
  hasError?: boolean;
  value: boolean;
};

const SDSSwitch = (props: Props) => {
  const { value, isReadOnly = false, isSmallToggle = false, hasError = false } = props;

  return (
    <div
      className={classNames(style.root, {
        [smallToggleStyle.smallerToggle]: isSmallToggle,
        [style.disabled]: isReadOnly,
        [style.error]: hasError,
      })}
      data-test={props['data-test']}
    >
      <Switch
        checked={value}
        onChange={props.onChange}
        disabled={isReadOnly}
        data-test={`${props['data-test']}.SDSSwitch`}
      />
    </div>
  );
};

export default SDSSwitch;
