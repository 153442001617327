import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { getEdition } from 'state/editions/actions/getEditionActions';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';

import tooltipStyle from 'styles/tooltip.scss';
import StoryStateUpdater from 'utils/StoryStateUpdater';
import { intlConnect } from 'utils/connectUtils';
import * as dateUtils from 'utils/dateUtils';

import RelativeTime from 'views/common/components/RelativeTime/RelativeTime';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import messages, { formatWithDate, formatWithDateAndTime } from './AvailabilityTime.messages';
import style from './AvailabilityTime.scss';

import type { Edition, EditionID } from 'types/editions';

const mapDispatchToProps = {
  getStory: getEdition,
};

type Props = {
  story: Edition;
  getStory: (a: { editionId: EditionID }) => Promise<unknown>;
};

export class AvailabilityTime extends React.PureComponent<Props> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'storyUpdater' has no initializer and is ... Remove this comment to see the full error message
  storyUpdater: StoryStateUpdater; // eslint-disable-line react/sort-comp

  UNSAFE_componentWillMount() {
    this.storyUpdater = new StoryStateUpdater(this.props.story, this.props.getStory);
  }

  componentWillUnmount() {
    this.storyUpdater.clear();
  }

  componentDidUpdate() {
    this.storyUpdater.updateStory(this.props.story);
  }

  shouldShowTime = (startDate: number, endDate: number, now: number) => {
    let shouldShowTime = true;
    // Some archive stories have the start of the epoch as their start date
    // In their case, we do not display their start date as a relative time.
    if (!startDate) {
      shouldShowTime = false;
    }
    return shouldShowTime;
  };

  renderBlankPlaceholder = () => {
    return (
      <span data-test="homepage.availabilityTime.blank" className={style.relativeTime}>
        <FormattedMessage id="story-time-blank" description="Do not show a time" defaultMessage="---" />
      </span>
    );
  };

  renderRelativeTime = (date: number, scheduleType: string, title: React.ReactNode) => {
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP_LEFT}
        title={title}
        overlayClassName={tooltipStyle.tooltip}
      >
        <RelativeTime
          baseTime={date}
          className={style.relativeTime}
          refreshIntervalMs={1000}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          formatTimeFunc={scheduleType === 'live' ? dateUtils.formatRelativeTime : dateUtils.formatTimeLeft}
          data-test={`homepage.availabilityTime.schedule.${scheduleType}`}
        />
      </SDSTooltip>
    );
  };

  renderTimeWhenDraft = () => {
    return this.renderBlankPlaceholder();
  };

  renderTimeWhenScheduleLive = (startDate: number) => {
    // when the story is scheduled to go live,
    // show how many hours or days are left until it goes live
    const scheduleType = 'live';
    const title = formatWithDateAndTime(messages.tooltipStoryTimeScheduledLive, startDate);

    return this.renderRelativeTime(startDate, scheduleType, title);
  };

  renderTimeWhenScheduleUnavailable = (endDate: number) => {
    // when the story is scheduled to become unavailable,
    // show how many hours or days are left until it goes unavailable
    const scheduleType = 'unavailable';
    const title = formatWithDateAndTime(messages.tooltipStoryTimeScheduledUnavailable, endDate);

    return this.renderRelativeTime(endDate, scheduleType, title);
  };

  renderTimeWhenUnavailable = (endDate: number) => {
    // when a story is unavailable,
    // show blank and in the tooltip the date when it became unavailable
    return (
      <SDSTooltip
        title={formatWithDateAndTime(messages.tooltipStoryTimeUnavailable, endDate)}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP_LEFT}
        overlayClassName={tooltipStyle.tooltip}
      >
        {this.renderBlankPlaceholder()}
      </SDSTooltip>
    );
  };

  renderTimeWhenAvailable = (startDate: number) => {
    // When a story is live, show the date when went live
    return (
      <SDSTooltip
        title={formatWithDateAndTime(messages.tooltipStoryTimeAvailable, startDate)}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP_LEFT}
        overlayClassName={tooltipStyle.tooltip}
      >
        <span data-test="homepage.availabilityTime.available" className={style.relativeTime}>
          {formatWithDate(messages.storyTimeAvailable, startDate)}
        </span>
      </SDSTooltip>
    );
  };

  renderStoryTime = (startDate: number, endDate: number, now: number) => {
    const { story } = this.props;
    if (editionEntityHelpers.isDraft(story) || !this.shouldShowTime(startDate, endDate, now)) {
      return this.renderTimeWhenDraft();
    }
    if (editionEntityHelpers.isScheduleToLive(story) && this.shouldShowTime(startDate, endDate, now)) {
      return this.renderTimeWhenScheduleLive(startDate);
    }
    if (endDate && editionEntityHelpers.isAvailable(story) && this.shouldShowTime(startDate, endDate, now)) {
      return this.renderTimeWhenScheduleUnavailable(endDate);
    }
    if (!editionEntityHelpers.isAvailable(story)) {
      return this.renderTimeWhenUnavailable(endDate);
    }

    return this.renderTimeWhenAvailable(startDate);
  };

  render() {
    const { story } = this.props;
    const startDate = (story.startDate && Date.parse(story.startDate)) || 0;
    const endDate = (story.endDate && Date.parse(story.endDate)) || 0;
    const now = Date.now();
    return <>{this.renderStoryTime(startDate, endDate, now)}</>;
  }
}

export default intlConnect(() => ({}), mapDispatchToProps)(AvailabilityTime);
