import React from 'react';
import { useSelector } from 'react-redux';

import { isStoryAnalyticsV2Enabled } from 'state/features/selectors/featuresSelectors';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import AnalyticsEditionView from 'views/analytics/containers/AnalyticsEditionView/AnalyticsEditionView';
import AnalyticsEditionViewV2 from 'views/analytics/containers/AnalyticsEditionView/AnalyticsEditionViewV2/AnalyticsEditionViewV2';

import { Claim } from 'types/permissions';
import { State } from 'types/rootState';

export default function AnalyticsEditionSwitch() {
  const isEditionAnalyticsV2Enabled = useSelector(isStoryAnalyticsV2Enabled);
  const isAnalyticsViewer = useSelector((state: State) => hasClaimForActivePublisher(state, Claim.ANALYTICS_VIEWER));
  if (isEditionAnalyticsV2Enabled) {
    if (!isAnalyticsViewer) {
      return null;
    }
    return <AnalyticsEditionViewV2 />;
  }
  return <AnalyticsEditionView />;
}
