import classNames from 'classnames';
import React, { useCallback } from 'react';

import KPIWidget, { KPIMetricInput } from 'views/common/components/KPIWidget/KPIWidget';
import SDSColumn from 'views/common/components/SDSColumn/SDSColumn';

import style from './ProfileKPI.scss';

import { ProfileMetric, StoriesMetric } from 'types/analytics';

export type AnalyticMetric = ProfileMetric | StoriesMetric;

type Props = {
  isActive: boolean;
  metric: KPIMetricInput<ProfileMetric | StoriesMetric>;
  value: number;
  previousValue: number;
  onMetricClicked: (metric: AnalyticMetric) => void;
  isLoading: boolean;
  tabSize?: number;
};
export function ProfileKPI(props: Props) {
  const { isActive, metric, value, previousValue, onMetricClicked, isLoading } = props;
  const columnSize = props.tabSize || 4;
  const handleMetricClick = useCallback(() => {
    onMetricClicked(metric.metricId!);
  }, [metric, onMetricClicked]);
  return (
    <SDSColumn
      onClick={handleMetricClick}
      xs={columnSize}
      md={columnSize}
      lg={columnSize}
      data-test={`ProfileKPI.${metric.metricId}.Column`}
    >
      <KPIWidget
        metric={metric}
        value={value}
        isLoading={isLoading}
        previousValue={previousValue}
        data-test={`ProfileKPI.${metric.metricId}.Widget`}
        className={classNames({
          [style.activeKpi]: isActive,
          [style.kpiTab]: !isActive,
        })}
      />
    </SDSColumn>
  );
}
