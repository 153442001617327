import { Mention } from 'antd';
import { MentionProps } from 'antd/lib/mention';
import classNames from 'classnames';
import React from 'react';

import style from './SDSMentions.scss';

export type OwnState = {
  isError?: boolean;
};

// Note that ant documentation for this component is no good, see the demos for better explanations: https://github.com/ant-design/ant-design/tree/3.14.1/components/mention/demo
export default function SDSMention(props: MentionProps & OwnState) {
  return (
    <Mention
      {...props}
      className={classNames(style.mentionStyle, {
        [style.failedValidation]: props.isError,
      })}
    />
  );
}
