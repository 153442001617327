import { gql } from '@apollo/client';
import log from 'loglevel';

import { SwipeUpAttributionWindow, ViewAttributionWindow } from '__agw-generated__/globalTypes';
import { useAdsQuery } from 'utils/apis/graphQLUtils';
import { enumObject } from 'utils/enum';

import {
  ProfileStoryAdsStatsQueryVariables,
  ProfileStoryAdsStatsQuery,
} from 'views/payToPromote/hooks/useProfileStoryAdsStatsQuery/__agw-generated__/ProfileStoryAdsStatsQuery';

import { EditionDateRange } from 'types/analytics';
import { EditionID } from 'types/editionID';

type AdStats = Readonly<Array<Record<string, any>>>;

type StoryAdsStatsQueryResult = {
  profileStoryAdsStatsError?: string;
  adsStats?: AdStats;
  loading?: boolean;
};

export const AdsStatsFields = enumObject({
  PAID_ECPM: 'PAID_ECPM',
  PAID_IMPRESSIONS: 'PAID_IMPRESSIONS',
  SPEND: 'SPEND',
  SWIPES: 'SWIPES',
});

export const GET_PROFILE_STORY_ADS_STATS = gql`
  query ProfileStoryAdsStatsQuery(
    $organizationId: ID!
    $profileId: ID!
    $storyId: ID!
    $statsInput: StatsInput!
    $adAccountId: String
  ) {
    profileStory(organizationId: $organizationId, profileId: $profileId, storyId: $storyId) {
      ... on Story {
        ads(adAccountId: $adAccountId) {
          ... on AdConnection {
            aggregatedStats(input: $statsInput) {
              ... on StatsResponse {
                stats {
                  fieldName
                  value
                }
              }
              ... on GenericError {
                message
              }
            }
          }
          ... on GenericError {
            message
          }
        }
      }
      ... on GenericError {
        message
      }
    }
  }
`;

export const GET_PROFILE_STORY_ADS_STATS_INPUT = {
  swipeUpAttributionWindow: SwipeUpAttributionWindow.DAY_1,
  viewAttributionWindow: ViewAttributionWindow.DAY_1,
  fields: [
    { field: AdsStatsFields.PAID_ECPM },
    { field: AdsStatsFields.PAID_IMPRESSIONS },
    { field: AdsStatsFields.SPEND },
    { field: AdsStatsFields.SWIPES },
  ],
};

function getDateRange(editionDateRange: EditionDateRange) {
  return {
    startDate: editionDateRange.from?.startOf('day'),
    endDate: editionDateRange.to?.endOf('day'),
  };
}

export function useProfileStoryAdsStatsQuery(
  storyId: EditionID,
  orgId: string | undefined | null,
  bizProfileId: string | null,
  adAccountId: string | null,
  editionDateRange: EditionDateRange,
  isStoryPromoted: boolean
): StoryAdsStatsQueryResult {
  const { error, data, loading } = useAdsQuery<ProfileStoryAdsStatsQuery, ProfileStoryAdsStatsQueryVariables>(
    GET_PROFILE_STORY_ADS_STATS,
    {
      variables: {
        storyId: storyId ? storyId.toString() : '',
        organizationId: orgId || '',
        profileId: bizProfileId || '',
        adAccountId: adAccountId ?? undefined,
        statsInput:
          editionDateRange.from && editionDateRange.to
            ? { ...GET_PROFILE_STORY_ADS_STATS_INPUT, dateRange: getDateRange(editionDateRange) }
            : GET_PROFILE_STORY_ADS_STATS_INPUT,
      },
      skip: !isStoryPromoted,
    }
  );

  if (error) {
    log.error('Failed to fetch ProfileStoryAdsStats', error.message);
    return {
      profileStoryAdsStatsError: error.message,
    };
  }

  if (data?.profileStory?.__typename === 'GenericError') {
    log.error('Failed to fetch ProfileStoryAdsStats data: profileStory', data.profileStory.message);
    return {
      profileStoryAdsStatsError: data.profileStory.message,
    };
  }

  if (data?.profileStory?.ads.__typename === 'GenericError') {
    log.error('Failed to fetch ProfileStoryAdsStats data: ads', data.profileStory.ads.message);
    return {
      profileStoryAdsStatsError: data.profileStory.ads.message,
    };
  }

  if (data?.profileStory?.ads.aggregatedStats.__typename === 'GenericError') {
    log.error(
      'Failed to fetch ProfileStoryAdsStats data: aggregatedStats',
      data.profileStory.ads.aggregatedStats.message
    );
    return {
      profileStoryAdsStatsError: data.profileStory.ads.aggregatedStats.message,
    };
  }

  return {
    adsStats: data?.profileStory?.ads?.aggregatedStats?.stats || [],
    loading,
  };
}
