import classNames from 'classnames';
import is from 'is_js';
import _ from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';

import { help } from 'icons/SDS/allIcons';
import { percentChanged, signOfValue, nanAsZero, NumberSigns } from 'utils/mathUtils';
import { printAbsPercentageDecimal } from 'utils/numberFormatter';

import { KPI_NO_METRIC_INFO_MESSAGE } from 'views/analytics/utils/kpiConfigs';
import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './KPIWidget.scss';

export type KPIMetricInput<T = string> = {
  name: ReactNode;
  subtitle?: ReactNode;
  tooltip: ReactNode;
  previousValueDescription?: ReactNode | undefined | null;
  previousValueDescriptionLong?: ReactNode | undefined | null;
  formatFunc?: (a: any) => any;
  percentChanged?: (b: number, a: number) => number;
  metricId: T;
};

export type KPIMaxValue = {
  description: ReactNode;
  descriptionLong: ReactNode;
  value: number;
  formatFunc: (a: any) => any;
};

type Props<T = string> = {
  metric: KPIMetricInput<T>;
  className?: string;
  previousValue?: number | null;
  value: number | undefined | null;
  isLoading: boolean | undefined | null;
  maxValue?: KPIMaxValue | null;
};

type OwnState = {
  derivedValues: any;
};

export class KPIWidget<T = string> extends React.PureComponent<Props<T>, OwnState> {
  static valueSignIcons = {
    [NumberSigns.POSITIVE]: 'caret-up',
    [NumberSigns.NEGATIVE]: 'caret-down',
    [NumberSigns.ZERO]: '',
    [NumberSigns.INVALID]: '',
  };

  static valueSignText = {
    [NumberSigns.POSITIVE]: '+',
    [NumberSigns.NEGATIVE]: '-',
    [NumberSigns.ZERO]: '',
    [NumberSigns.INVALID]: '',
  };

  derivedValues = null; // eslint-disable-line react/sort-comp

  UNSAFE_componentWillMount() {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ hasPreviousValue: any; percentChangeValue:... Remove this comment to see the full error message
    this.derivedValues = this.recalculateDerivedValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ hasPreviousValue: any; percentChangeValue:... Remove this comment to see the full error message
    this.derivedValues = this.recalculateDerivedValues(nextProps);
  }

  recalculateDerivedValues(props: Props) {
    const { value } = props;
    const { previousValue } = props;
    const { metric } = props;
    const formatFunc = metric.formatFunc || String;

    const hasPreviousValue = is.number(props.previousValue);

    const percentChangedFunc = metric.percentChanged || percentChanged;

    const percentChangeValue = nanAsZero(
      hasPreviousValue && value && previousValue ? percentChangedFunc(value, previousValue) : 0
    );
    let percentFormatted = printAbsPercentageDecimal(percentChangeValue);

    let valueSign = signOfValue(percentChangeValue);
    if (percentFormatted === '0.0%') {
      // Ignoring signs of values less then a tenth of a percent of change.
      valueSign = NumberSigns.ZERO;
    }

    percentFormatted = KPIWidget.valueSignText[valueSign] + percentFormatted;
    const valueSignIcon = KPIWidget.valueSignIcons[valueSign];

    const inUnits = value === null ? '-' : formatFunc(value);

    return {
      hasPreviousValue,
      percentChangeValue,
      percentFormatted,
      valueSign,
      valueSignIcon,
      inUnits,
    };
  }

  renderMaxValue = () => {
    if (!this.props.maxValue || this.props.isLoading) {
      return null;
    }

    return (
      <div className={style.metricMaxValueContainer}>
        <div className={style.metricMaxValueRow}>
          <span className={style.metricMaxValue}>
            {`/ ${this.props.maxValue.formatFunc(this.props.maxValue.value)}`}
          </span>
        </div>
        <div className={style.previousDescription}>
          <SDSTooltip
            id={style.kpiPreviousTooltip}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            placement={TooltipPosition.BOTTOM}
            title={this.props.maxValue ? this.props.maxValue.descriptionLong : null}
          >
            <span>{_.get(this.props, ['maxValue', 'description'])}</span>
          </SDSTooltip>
        </div>
      </div>
    );
  };

  renderOptionalPreviousValue() {
    if (this.props.isLoading) {
      return null;
    }

    const { metric } = this.props;
    const { derivedValues } = this;
    if (!_.get(derivedValues, ['hasPreviousValue'])) {
      return [];
    }

    return (
      <div className={style.recentChangeValue}>
        <div className={style.percentChangeRow}>
          <span
            className={classNames(style.percentChangeValue, {
              [style.negativeValue]: _.get(derivedValues, ['valueSign']) === NumberSigns.NEGATIVE,
              [style.positiveValue]: _.get(derivedValues, ['valueSign']) === NumberSigns.POSITIVE,
              [style.neutralValue]: _.get(derivedValues, ['valueSign']) === NumberSigns.ZERO,
            })}
            data-test="analytics.kpi.percentChangeValue"
          >
            {_.get(derivedValues, ['percentFormatted'])}
          </span>
        </div>
        <div className={style.previousDescription} data-test="analytics.kpi.previousDescription">
          <SDSTooltip
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            placement={TooltipPosition.BOTTOM}
            id={style.kpiPreviousTooltip}
            title={metric.previousValueDescriptionLong}
          >
            <span>{metric.previousValueDescription}</span>
          </SDSTooltip>
        </div>
      </div>
    );
  }

  renderSubtitle = () => {
    return (
      <div className={style.subtitle} data-test="analytics.kpi.subtitle">
        {this.props.metric.subtitle}
      </div>
    );
  };

  render() {
    const { className } = this.props;
    const { derivedValues } = this;
    const { props } = this;
    const { metric } = props;

    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div
        className={classNames(style.kpiParent, 'card-box', className)}
        data-test={`analytics.kpi.${metric.metricId}`}
      >
        <div className={style.kpiTitlePadding}>
          <label className={style.kpiTitleRow}>
            <div className={style.kpiTitle} data-test="analytics.kpi.title">
              <span className={style.metricName}>{metric.name}</span>
              <SDSTooltip
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                placement={TooltipPosition.TOP}
                title={metric.tooltip}
                id={style.kpiTooltip}
              >
                <div data-test="analytics.kpi.infoButton">
                  <Icon inlineIcon={help} className={style.infoButton} />
                </div>
              </SDSTooltip>
            </div>
          </label>
        </div>
        <div className={style.allValues}>
          <SDSTooltip
            id={style.kpiPreviousTooltip}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            placement={TooltipPosition.TOP_LEFT}
            title={this.props.value || this.props.isLoading ? null : KPI_NO_METRIC_INFO_MESSAGE}
          >
            <div>
              <div className={style.metricValue} data-test="analytics.kpi.metricValue">
                {this.props.isLoading ? (
                  <SpinnerIcon className={style.loadingIcon} />
                ) : (
                  _.get(derivedValues, ['inUnits'])
                )}
              </div>
              {this.renderSubtitle()}
            </div>
          </SDSTooltip>
          {this.props.maxValue ? this.renderMaxValue() : this.renderOptionalPreviousValue()}
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/label-has-for */
  }
}

export default KPIWidget;
