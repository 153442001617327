import React, { ReactNode } from 'react';

import { useArchivePublisher } from 'gql/hooks/useArchivePublisher';

type OwnProps = {
  children?: ReactNode;
};

type Props = OwnProps;

export default function withArchivePublisherMutation(Component: typeof React.Component) {
  function WrappedComponent(props: Props) {
    const { archivePublisher, data, error } = useArchivePublisher();

    return (
      <Component
        {...props}
        archivePublisher={archivePublisher}
        archivePublisherData={data}
        archivePublisherError={error}
        data-test="withArchivePublisherMutation.component"
      >
        {props.children}
      </Component>
    );
  }

  return WrappedComponent;
}
