import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';

import MultiSelectInput from 'views/common/components/MultiInput/MultiSelectInput';

import style from './MultiSelectTagFilter.scss';

export type TagOptions = {
  [x: string]: {
    defaultTags: string[];
    tagMapping: {
      [x: string]: string;
    };
    suggestions?: string[];
  };
};

type ExternalProps = {
  tagOptions: TagOptions;
  tagTypeOrder: string[];
  onChange: (a: { [x: string]: string[] }) => void;
  onSearch: (a?: string | null) => void;
  className?: string;
};

type OwnProps = ExternalProps;

type State = {
  suggestions: {
    [x: string]: string[];
  };
  selectedTags: {
    [x: string]: string[];
  };
  query: string | undefined | null;
};

type Props = OwnProps & typeof MultiSelectTagFilter.defaultProps;

export default class MultiSelectTagFilter extends React.Component<Props, State> {
  static defaultProps = {
    onSearch: (query?: string | null) => {},
  };

  state = {
    suggestions: {},
    selectedTags: {},
    query: '',
  };

  UNSAFE_componentWillMount() {
    const selectedTags = Object.keys(this.props.tagOptions).reduce((acc, key) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[key] = this.props.tagOptions[key].defaultTags;
      return acc;
    }, {});
    this.setState({ selectedTags }, () => this.searchItems(null));
  }

  componentDidUpdate(prevProps: Props) {
    let shouldUpdate = false;
    Object.keys(this.props.tagOptions).forEach((key: string) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (!isEqual(this.props.tagOptions[key].suggestions, prevProps.tagOptions[key].suggestions)) {
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      this.searchItems(this.state.query);
    }
  }

  onChange = (selectedTags: { [x: string]: string[] }) => {
    this.setState({ selectedTags }, () => this.searchItems(null));
    this.props.onChange(selectedTags);
  };

  searchItems = (query?: string | null) => {
    const lowerCaseQuery = query ? query.toLowerCase() : '';
    const suggestions = {};
    Object.keys(this.props.tagOptions).forEach((key: string) => {
      const tagOption = this.props.tagOptions[key];
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'tagMapping' does not exist on type '{ de... Remove this comment to see the full error message
      const { tagMapping } = tagOption;
      if (!query) {
        // Nothing is being searched for, so we want to show all selected tags at the top of the list
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const selectedTags = this.state.selectedTags[key] || [];
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestions[key] = selectedTags;
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestions[key] = suggestions[key].concat(
          Object.keys(tagMapping).filter(tagValue => selectedTags.indexOf(tagValue) === -1)
        );
      } else {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestions[key] =
          tagMapping &&
          Object.keys(tagMapping).filter(tagValue => tagMapping[tagValue].toLowerCase().indexOf(lowerCaseQuery) !== -1);
      }
    });
    this.setState({ suggestions });

    if (this.state.query !== query) {
      this.props.onSearch(query);
      this.setState({ query });
    }
  };

  render() {
    const tagOptions = {};

    Object.keys(this.props.tagOptions).forEach((key: string) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      tagOptions[key] = this.props.tagOptions[key];
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      tagOptions[key].suggestions = this.state.suggestions[key] || Object.keys(tagOptions[key].tagMapping);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      tagOptions[key].className = tagOptions[key].className || style.tag;
    });
    return (
      <div className={classNames(style.parent, this.props.className)}>
        <MultiSelectInput
          className={style.multiSelectInput}
          tagOptions={tagOptions}
          tagTypeOrder={this.props.tagTypeOrder}
          onChange={this.onChange}
          searchItems={this.searchItems}
          maxSelectedToDisplay={5}
          addSearchTermOnEnter
          openOnFocus
          showResultsIfNoSearchQuery
          showSelectedInSuggestions
        />
      </div>
    );
  }
}
