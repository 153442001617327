import PropTypes from 'prop-types';
import React from 'react';

import { makeStoriesUnavailable } from 'state/editions/actions/editionsActions';
import { isUnpublishing } from 'state/editions/schema/editionEntityHelpers';
import { getEditionById } from 'state/editions/selectors/editionsSelectors';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip from 'views/common/components/SDSTooltip/SDSTooltip';
import HomepageButton, { HomepageButtonTypes } from 'views/homepage/components/HomepageButton/HomepageButton';

import type { EditionID } from 'types/editions';
import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';

type StateProps = {
  isUnpublishing: boolean;
  isReadOnly: boolean;
};

const mapStateToProps = (state: State, ownProps: any) => {
  const story = getEditionById(state)(ownProps.storyId);
  const isStoryPublisher = hasClaimForActivePublisher(state, Claim.STORY_PUBLISHER);
  return {
    isUnpublishing: story && isUnpublishing(story),
    isReadOnly: !isStoryPublisher, // if the user does not have the permission (eg. has a role of 'Viewer') the button is read-only.
  };
};

const mapDispatchToProps = {
  makeStoriesUnavailable,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = {
  storyId: EditionID;
  isHomepageButton: boolean;
  'data-test': string;
  scheduleImmediately?: boolean;
} & DispatchProps &
  StateProps;

export class UnavailableButton extends React.PureComponent<Props> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  onClick = (e: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.props.makeStoriesUnavailable([this.props.storyId], this.props.scheduleImmediately);
  };

  renderLabelTitle = () => {
    if (this.props.isReadOnly) {
      return getMessageFromId('story-status-is-read-only');
    }
    if (this.props.isUnpublishing) {
      return getMessageFromId('story-state-unpublishing');
    }
    if (this.props.scheduleImmediately) {
      return getMessageFromId('make-unavailable-label-schedule-modal');
    }
    return getMessageFromId('story-status-unavailable');
  };

  renderHomepageButton = () => {
    return (
      <SDSTooltip
        placement="topRight"
        title={this.renderLabelTitle()}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        delayShow={300}
        id="unavailable-story-status"
      >
        <HomepageButton
          disabled={this.props.isUnpublishing || this.props.isReadOnly}
          onClick={this.onClick}
          type={HomepageButtonTypes.UNAVAILABLE}
        />
      </SDSTooltip>
    );
  };

  render() {
    if (this.props.isHomepageButton) {
      return this.renderHomepageButton();
    }

    return (
      <SDSButton
        onClick={this.onClick}
        type={ButtonType.WHITE_ON_GREY}
        data-test={this.props['data-test']}
        disabled={this.props.isUnpublishing || this.props.isReadOnly}
      >
        {getMessageFromId('make-unavailable-label-schedule-modal')}
      </SDSButton>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(UnavailableButton);
