import { get } from 'lodash';
import { createSelector } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';
import { OrgOnboardingsMap } from 'state/orgOnboardings/orgOnboardingsState';

import { EMPTY_OBJECT } from 'config/constants';

import { OrgOnboardingId } from 'types/orgOnboarding';
import { State } from 'types/rootState';

const getOrgOnboardingsState = (state: State) => get(state, ['orgOnboardings']) || EMPTY_OBJECT;
const getOrgOnboardings: (state: State) => OrgOnboardingsMap = createKeySelector(
  getOrgOnboardingsState,
  'onboardings',
  EMPTY_OBJECT
);
const getOrgOnboardingIsLoading = createKeySelector(getOrgOnboardingsState, 'isLoading', 0);

export const getOrgOnboardingsList = createSelector(getOrgOnboardings, onboardings => Object.values(onboardings));
export const getOrgOnboardingById = createSelector(getOrgOnboardings, onboardings => (id: OrgOnboardingId) =>
  onboardings[id] || null
);
export const isOrgOnboardingsLoading = createSelector(getOrgOnboardingIsLoading, isLoading => isLoading > 0);
