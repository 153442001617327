import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { CrossOrigin } from 'config/constants';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';
import { printPercentage } from 'utils/numberFormatter';

import style from './AnalyticsPollToolTip.scss';

import type { Poll, PollQuestion } from 'types/analytics';

type Props = {
  pollMetadata: Poll | PollQuestion;
};
export class AnalyticsPollToolTip extends React.PureComponent<Props> {
  getOutcomeOrQuestion = (pollMetadata: any) => {
    if (pollMetadata.questions) {
      return pollMetadata.outcomes || pollMetadata.questions[0];
    }
    return pollMetadata;
  };

  getChoiceRows = (question: PollQuestion) => {
    const { choices } = question;
    const highlightedIndex =
      question.correctAnswer !== undefined ? question.correctAnswer : this.getIndexOfWinningChoice(choices);
    return _.map(choices, (choice, i) => {
      const description = choice.publisher_text ? choice.publisher_text : choice.choice_id;
      const title = `${description}: `;
      let percentage;
      if (choice.displayPercentage !== undefined) {
        percentage = choice.displayPercentage;
      } else {
        const count = choice.vote_count;
        percentage = printPercentage(count / question.total_votes);
      }
      const isHighlightedChoice = highlightedIndex === i;
      const keyClassName = isHighlightedChoice ? style.highlightedTitle : style.nonHighlightedTitle;
      const valueClassName = isHighlightedChoice ? style.highlightedPercentage : style.nonHighlightedPercentage;
      return (
        <div key={choice.choice_id} data-test="question-results-choice">
          <div className={style.pollChoicesContainer}>
            <span className={classNames(style.choiceDescription, keyClassName)} data-test="question-results-choice-key">
              {title}
            </span>
            <span className={valueClassName} data-test="question-results-choice-value">
              {percentage}
            </span>
            <span className={style.choiceThumbnail}>
              <img
                src={createAssetUrl(choice.assetId)}
                crossOrigin={CrossOrigin.USE_CREDENTIALS}
                alt={title}
                data-test="question-results-choice-img"
              />
            </span>
          </div>
        </div>
      );
    });
  };

  getIndexOfWinningChoice = (choices: any) => {
    let max = 0;
    let index = 0;
    choices.forEach((choice: any, i: any) => {
      const count = parseInt(choice.vote_count, 10);
      if (count > max) {
        index = i;
        max = count;
      }
    });
    return index;
  };

  render() {
    const { pollMetadata } = this.props;
    const question = this.getOutcomeOrQuestion(pollMetadata);
    const className = question.correctAnswer !== undefined ? style.withCorrectAnswer : null;
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      <div className={className} data-test="question-results">
        <div className={style.pollTitle}>
          <h4>{question.title || getMessageFromId('results-title')}</h4>
          <div className={style.metricContainer}>
            <span className={style.metricName}>{getMessageFromId('poll-total-votes-title')}</span>
            <span className={(style as any).metricValue}>{question.total_votes}</span>
          </div>
        </div>
        <h4>{getMessageFromId('results-title')}</h4>
        {this.getChoiceRows(question)}
      </div>
    );
  }
}
export default AnalyticsPollToolTip;
