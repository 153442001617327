import React from 'react';
import type { ReactNode } from 'react';

import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip from 'views/common/components/SDSTooltip/SDSTooltip';

import OriginalSDSIndividualTab from './OriginalSDSTabs/OriginalSDSIndividualTab';
import OriginalSDSTabs from './OriginalSDSTabs/OriginalSDSTabs';
import style from './SDSTabs.scss';

export type TabHeader = {
  text: ReactNode;
  toolTipMessage?: ReactNode; // if a tab should have a tooltip pass the message using this prop,
  icon?: string;
  dotStatus?: ReactNode;
  isActive?: boolean | ((a: any) => boolean);
  tabKey?: string;
};

type Props = {
  tabs: TabHeader[];
  selectedTab?: number;
  handleTabSelected: (tabIndex: number) => void;
  defaultTab?: number;
  'data-test'?: string;
};

function SDSTabs(props: Props) {
  const { tabs, selectedTab, handleTabSelected, defaultTab } = props;

  const renderTabTitle = ({ icon, dotStatus, text, tabKey }: TabHeader) => {
    return (
      <div
        data-test={tabKey ? `common.SDSTabs.tab.title.${tabKey}` : 'common.SDSTabs.tab.title'}
        className={style.tabTitle}
      >
        {icon && !dotStatus && <Icon inlineIcon={icon} />}
        {dotStatus && <div className={style.dotStatusComponent}>{dotStatus}</div>}
        {text}
      </div>
    );
  };

  const renderTab = (tab: TabHeader) => {
    if (tab.toolTipMessage) {
      return (
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          id="tab-tooltip"
          placement="bottom"
          title={tab.toolTipMessage}
        >
          {renderTabTitle(tab)}
        </SDSTooltip>
      );
    }

    return renderTabTitle(tab);
  };

  const renderTabContainer = (tab: TabHeader, key: number) => {
    return <OriginalSDSIndividualTab tabClassName={style.indvTabContainer} title={renderTab(tab)} key={key} />;
  };

  return (
    <OriginalSDSTabs
      onTabSelected={handleTabSelected}
      className={style.tabsWrapper}
      tabsClassName={style.tabsContainer}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; onTabSelected: (tabIn... Remove this comment to see the full error message
      tabClassName={style.tabContainer}
      defaultIndex={defaultTab}
      selectedIndex={selectedTab}
      data-test={props['data-test'] ? `${props['data-test']}.SDSTabs` : 'SDSTabs'}
    >
      {tabs.map((tab, key) => renderTabContainer(tab, key))}
    </OriginalSDSTabs>
  );
}

export default SDSTabs;
