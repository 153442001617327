// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Grid } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';

import sadGhost from './icons/ghost.svg';
import happyGhost from './icons/ghost2.svg';

import style from './BlankPageWithText.scss';

type Props = {
  happyState?: boolean;
  helpCenterDestination?: HelpCenterDestination;
  message?: React.ReactElement;
};

export class BlankPageWithText extends React.Component<Props> {
  renderGhostImage() {
    const ghost = this.props.happyState ? happyGhost : sadGhost;
    return <img className={style.ghost} src={ghost} alt={ghost} draggable={false} />;
  }

  render() {
    return (
      <Grid className={style.blankPage} fluid>
        {this.renderGhostImage()}
        <div className={style.message}>{this.props.message}</div>
        {this.props.helpCenterDestination ? (
          <div className={style.helpCenterWrapper}>
            <HelpCenterLink destination={this.props.helpCenterDestination} data-test="BlankPageWithText.message" />
          </div>
        ) : null}
      </Grid>
    );
  }
}

export default BlankPageWithText;
