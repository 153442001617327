import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Textfit } from 'react-textfit';

import { registerIntlMessage, getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';

import style from './ReportHeader.scss';

type Props = {
  publisherName: string;
  storiesPublished: number | undefined | null;
  firstPublish: moment.Moment | undefined | null;
  description: string;
  isShow: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-header-byline-date-of-first-story-published-show-publisher-case"
      description="Text appearing on header byline on the Analytics Insights Report showing the date of first episode published"
      defaultMessage={'First episode published on {dateFirstPublishedStory}'}
    />
  ),
  params: ['dateFirstPublishedStory'],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-header-byline-stories-published-show-publisher-case"
      description="Text appearing on header byline on the Analytics Insights Report showing the number of episodes published"
      defaultMessage={'{numberOfStoriesPublished} episodes published'}
    />
  ),
  params: ['numberOfStoriesPublished'],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-header-byline-case-no-data-show-publisher-case"
      description="Text appearing between publisher name and description on the Analytics Insights Report in case of no episodes"
      defaultMessage="No episodes published to Discover"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-header-byline-date-of-first-story-published-regular-publisher-case"
      description="Text appearing on header byline on the Analytics Insights Report showing date of first edition published"
      defaultMessage={'First edition published on {dateFirstPublishedStory}'}
    />
  ),
  params: ['dateFirstPublishedStory'],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-header-byline-stories-published-regular-publisher-case"
      description="Text appearing on header byline on the Analytics Insights Report showing the number of editions published"
      defaultMessage={'{numberOfStoriesPublished} editions published'}
    />
  ),
  params: ['numberOfStoriesPublished'],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-header-byline-case-no-data-regular-publisher-case"
      description="Text appearing between publisher name and description on the Analytics Insights Report in case of no editions"
      defaultMessage="No editions published to Discover"
    />
  ),
  params: [],
});

export class ReportHeader extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getByline = () => {
    let firstPublishDate;
    let numStoriesPublished;

    const publisherType = this.props.isShow ? 'show' : 'regular';

    if (this.props.firstPublish) {
      firstPublishDate = getLocalisedMessageFromId(
        this.context,
        `analytics-insights-report-header-byline-date-of-first-story-published-${publisherType}-publisher-case`,
        {
          dateFirstPublishedStory: moment(this.props.firstPublish).format('MMM D, YYYY'),
        }
      );
    }

    if (this.props.storiesPublished && this.props.storiesPublished > 0) {
      numStoriesPublished = getLocalisedMessageFromId(
        this.context,
        `analytics-insights-report-header-byline-stories-published-${publisherType}-publisher-case`,
        {
          numberOfStoriesPublished: this.props.storiesPublished > 1000 ? '1000+' : this.props.storiesPublished,
        }
      );
    } else {
      numStoriesPublished = getLocalisedMessageFromId(
        this.context,
        `analytics-insights-report-header-byline-case-no-data-${publisherType}-publisher-case`,
        {}
      );
    }

    return `${firstPublishDate || ''}${firstPublishDate && numStoriesPublished ? ' • ' : ''}${
      numStoriesPublished || ''
    }`;
  };

  render() {
    return (
      <div className={style.reportHeader}>
        <div className={style.headline}>
          <Textfit mode="single" max={60}>
            <p className={style.textMainHeader}>{this.props.publisherName}</p>
          </Textfit>
        </div>
        <p className={style.byline}>{this.getByline()}</p>
        <p className={style.description}>{this.props.description}</p>
      </div>
    );
  }
}

export default ReportHeader;
