import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import InlineSVG from 'svg-inline-react';

import { getDaily, getCountryCodes } from 'state/analytics/selectors/analyticsSelectors';
import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import { isStoryAnalyticsV2Enabled } from 'state/features/selectors/featuresSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { userId } from 'state/user/selectors/userSelectors';

import { DEFAULT_ANALYTICS_GEO, AnalyticsCsvDetailLevel } from 'config/constants';
import { gear } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import { exportDailyCSV, exportEditionListCSV } from 'views/analytics/utils/exportCSV';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/download.svg.inline' or ... Remove this comment to see the full error message
import downloadIcon from 'icons/download.svg.inline';

import style from './AnalyticsSettingsButton.scss';

import { AnalyticsStatsType } from 'types/analytics';
import type { State as RootState } from 'types/rootState';

const COUNTRY_CODE_SEPARATOR = '_';

type OwnProps = {
  from: moment.Moment;
  to: moment.Moment;
  className?: string;
};

type State = {
  isDownloadingExport: boolean;
  isDownloadingGlobalSummaryExport: boolean;
  isDownloadingGlobalStoryOnlySummaryExport: boolean;
};

export const mapStateToProps = (state: RootState) => {
  const activePublisher = getActivePublisherDetails(state);
  const activeUserId = userId(state);
  return {
    activePublisher,
    authToken: getToken(state),
    authType: getAuthType(state),
    analytics: getDaily(state),
    pickedCountryCodes: getCountryCodes(state),
    isStoryAnalyticsV2Enabled: isStoryAnalyticsV2Enabled(state),
    activeUserId,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

export class AnalyticsSettingsButton extends React.Component<Props, State> {
  state = {
    isDownloadingExport: false,
    isDownloadingGlobalSummaryExport: false,
    isDownloadingGlobalStoryOnlySummaryExport: false,
  };

  handleExportAudienceCSV = () => {
    if (!this.props.analytics || _.isEmpty(this.props.pickedCountryCodes)) {
      return;
    }

    incrementCounterByPublisher(this.props.activePublisher || null, 'analytics.csv.audience');
    const dailyMetrics = _.get(this.props.analytics, ['dailyMetrics']);
    exportDailyCSV(this.props.pickedCountryCodes, dailyMetrics, this.props.activePublisher, true);
  };

  handleExportBehaviorCSV = () => {
    if (!this.props.analytics || _.isEmpty(this.props.pickedCountryCodes)) {
      return;
    }

    incrementCounterByPublisher(this.props.activePublisher || null, 'analytics.csv.behavior');
    const dailyMetrics = _.get(this.props.analytics, ['dailyMetrics']);
    exportDailyCSV(this.props.pickedCountryCodes, dailyMetrics, this.props.activePublisher, false);
  };

  handleExportStoryCSV = () => {
    if (this.state.isDownloadingExport) {
      return;
    }

    this.setState({ isDownloadingExport: true });
    const startDate = this.props.from.format('YYYY-MM-DD');
    const endDate = this.props.to.format('YYYY-MM-DD');

    const { pickedCountryCodes } = this.props;
    const countries = Array.isArray(pickedCountryCodes)
      ? pickedCountryCodes.join(COUNTRY_CODE_SEPARATOR)
      : DEFAULT_ANALYTICS_GEO;

    incrementCounterByPublisher(this.props.activePublisher || null, 'analytics.csv.story');
    exportEditionListCSV({
      isGlobalSummary: false,
      publisherName: this.props.activePublisher?.mutablePublisherName,
      publisherId: this.props.activePublisher?.id,
      startDate,
      endDate,
      token: this.props.authToken,
      countries,
      authType: this.props.authType,
      csvDetailLevel: AnalyticsCsvDetailLevel.STORIES_AND_SNAPS,
      analyticsStatsType: AnalyticsStatsType.ALL,
    }).then(() => {
      this.setState({ isDownloadingExport: false });
    });
  };

  handleExportStoryGlobalSummaryCSV = () => {
    if (this.state.isDownloadingGlobalSummaryExport) {
      return;
    }

    this.setState({ isDownloadingGlobalSummaryExport: true });
    const startDate = this.props.from.format('YYYY-MM-DD');
    const endDate = this.props.to.format('YYYY-MM-DD');

    incrementCounterByPublisher(this.props.activePublisher || null, 'analytics.csv.story.global');
    exportEditionListCSV({
      isGlobalSummary: true,
      publisherName: this.props.activePublisher?.mutablePublisherName,
      publisherId: this.props.activePublisher?.id,
      startDate,
      endDate,
      token: this.props.authToken,
      countries: '',
      authType: this.props.authType,
      csvDetailLevel: AnalyticsCsvDetailLevel.STORIES_AND_SNAPS,
      analyticsStatsType: AnalyticsStatsType.ALL,
    }).then(() => {
      this.setState({ isDownloadingGlobalSummaryExport: false });
    });
  };

  handleExportStoryGlobalSummaryStoryOnlyCSV = () => {
    if (this.state.isDownloadingGlobalStoryOnlySummaryExport) {
      return;
    }

    this.setState({ isDownloadingGlobalStoryOnlySummaryExport: true });
    const startDate = this.props.from.format('YYYY-MM-DD');
    const endDate = this.props.to.format('YYYY-MM-DD');

    incrementCounterByPublisher(this.props.activePublisher || null, 'analytics.csv.story.global.story.only');
    exportEditionListCSV({
      isGlobalSummary: true,
      publisherName: this.props.activePublisher?.mutablePublisherName,
      publisherId: this.props.activePublisher?.id,
      startDate,
      endDate,
      token: this.props.authToken,
      countries: '',
      authType: this.props.authType,
      csvDetailLevel: AnalyticsCsvDetailLevel.STORIES_ONLY,
      analyticsStatsType: AnalyticsStatsType.ALL,
    }).then(() => {
      this.setState({ isDownloadingGlobalStoryOnlySummaryExport: false });
    });
  };

  renderDownloadButtonIcon = (isDownloadingExportFlag: boolean) => {
    if (!isDownloadingExportFlag) {
      return <InlineSVG src={downloadIcon} className={style.downloadIcon} />;
    }
    return <SpinnerIcon className={style.loadingIcon} />;
  };

  renderDownloadStoryGlobalSummaryButton = () => {
    // @ts-expect-error TS2367: This condition will always return 'true' since...
    if (this.props.pickedCountryCodes !== DEFAULT_ANALYTICS_GEO) {
      return null;
    }
    return (
      <div
        onClick={this.handleExportStoryGlobalSummaryCSV}
        className={style.menuItem}
        data-test="analyticsSettingsButton.toolTipSection.storyGlobal"
      >
        {this.renderDownloadButtonIcon(this.state.isDownloadingGlobalSummaryExport)}
        {getMessageFromId('analytics-stories-data-global-only')}
      </div>
    );
  };

  renderDownloadStoryGlobalSummaryStoryOnlyButton = () => {
    return (
      <div
        onClick={this.handleExportStoryGlobalSummaryStoryOnlyCSV}
        className={style.menuItem}
        data-test="analyticsSettingsButton.toolTipSection.storyGlobalStoryOnly"
      >
        {this.renderDownloadButtonIcon(this.state.isDownloadingGlobalStoryOnlySummaryExport)}
        {getMessageFromId('analytics-stories-data-global-only-story-only')}
      </div>
    );
  };

  renderDownloadStoryButton = () => {
    return (
      <div
        onClick={this.handleExportStoryCSV}
        className={style.menuItem}
        data-test="analyticsSettingsButton.toolTipSection.storyAllCountries"
      >
        {this.renderDownloadButtonIcon(this.state.isDownloadingExport)}
        {
          // @ts-expect-error TS2367: This condition will always return 'true' since...
          this.props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO
            ? getMessageFromId('analytics-stories-data-all-countries')
            : getMessageFromId('analytics-stories-data')
        }
      </div>
    );
  };

  renderTooltip = () => {
    return (
      <div data-test="analytics.analyticsSettings.button.tooltip.title">
        {!this.props.isStoryAnalyticsV2Enabled && (
          <div
            onClick={this.handleExportAudienceCSV}
            className={style.menuItem}
            data-test="analyticsSettingsButton.toolTipSection.audience"
          >
            <InlineSVG src={downloadIcon} className={style.downloadIcon} />{' '}
            {getMessageFromId('analytics-audience-data')}
          </div>
        )}
        {!this.props.isStoryAnalyticsV2Enabled && (
          <div
            onClick={this.handleExportBehaviorCSV}
            className={style.menuItem}
            data-test="analyticsSettingsButton.toolTipSection.behaviour"
          >
            <InlineSVG src={downloadIcon} className={style.downloadIcon} />{' '}
            {getMessageFromId('analytics-behavior-data')}
          </div>
        )}
        {this.renderDownloadStoryButton()}
        {this.renderDownloadStoryGlobalSummaryButton()}
        {this.renderDownloadStoryGlobalSummaryStoryOnlyButton()}
      </div>
    );
  };

  render() {
    return (
      <div className={style.settingsButtonContainer}>
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.BOTTOM_RIGHT}
          title={this.renderTooltip()}
          trigger="click"
          id="analytics-tooltip"
        >
          <SDSButton
            type={ButtonType.SECONDARY}
            shape={ButtonShape.CIRCLE}
            inlineIcon={gear}
            data-test="analytics.settings.button"
          />
        </SDSTooltip>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(AnalyticsSettingsButton);
