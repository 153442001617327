import React from 'react';
import { DropTarget as makeDropTarget } from 'react-dnd';

import makeSortableExpandableListItem from 'views/common/components/SortableExpandableListItem/SortableExpandableListItem';

type Props = {
  type: string;
  listId: string;
  updateOrder?: (...args: any[]) => any;
  saveOrder?: (...args: any[]) => any;
  sortable: boolean;
  saveExpanded?: (...args: any[]) => any;
  connectDropTarget: (...args: any[]) => any;
  showPlaceholder?: boolean;
  emptyText?: string;
};
export class NestableExpandableList extends React.Component<Props> {
  ListItemComponent: any;

  UNSAFE_componentWillMount() {
    // NOTE: It's important that the list item component is defined once and then reused, rather
    //       than being redefined whenever the component is rendered. This has small performance
    //       benefit, but the more important issue is that re-defining the component used for
    //       rendering list items makes React incapable of tracking whether the list items represent
    //       new objects or existing objects when the list is re-ordered.
    this.ListItemComponent = makeSortableExpandableListItem(this.props.type);
  }

  createEmptyNode = () => {
    const updateState = this.props.updateOrder;
    const saveState = this.props.saveOrder;
    const { saveExpanded } = this.props;
    const header = this.createEmptyHeader();
    const item = <div />;
    const mergedProps = {
      updateState,
      saveState,
      saveExpanded,
      header,
      item,
      index: (this.props.children as any).length,
      key: '__EMPTY_NODE_PLACEHOLDER__',
      listId: this.props.listId,
    };
    const { ListItemComponent } = this;
    return <ListItemComponent {...mergedProps} />;
  };

  createEmptyHeader = () => {
    return <div>{this.props.emptyText}</div>;
  };

  render() {
    const { connectDropTarget } = this.props;
    let listItems = [];
    const { ListItemComponent } = this;
    if (this.props.children && (this.props.children as any).length > 0) {
      listItems = (this.props.children as any).map((item: any, i: any) => {
        const updateState = this.props.updateOrder;
        const saveState = this.props.saveOrder;
        const { saveExpanded } = this.props;
        const mergedProps = {
          ...item.props,
          item,
          updateState,
          saveState,
          saveExpanded,
          sortable: this.props.sortable,
          index: i,
          key: item.key,
          listId: this.props.listId,
        };
        return <ListItemComponent {...mergedProps} />;
      });
    }
    if (this.props.showPlaceholder) {
      listItems.push(this.createEmptyNode());
    }
    return connectDropTarget(<div>{listItems}</div>);
  }
}
const listItemTarget = {
  drop(props: any) {
    const { listId } = props;
    return {
      listId,
    };
  },
};
export default (type: any) => {
  return makeDropTarget(type, listItemTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }))(NestableExpandableList);
};
