import React from 'react';
import { FormattedMessage } from 'react-intl';

import { intlConnect } from 'utils/connectUtils';

import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import MultiSelectTagFilter from 'views/onboarding/containers/SettingsView/tabs/SuperAdminTab/MultiSelectTagFilter/MultiSelectTagFilter';

import style from './InventoryDropdown.scss';

import { INVENTORY_BUNDLE_TYPE_NEW, INVENTORY_BUNDLE_TYPE_EXISTING } from 'types/inventory';

type ExternalProps = {
  activeInventoryBundlesByIdMap: any;
  inventoryBundleItemMappingsByBundleIdMap: any;
  isLoading: boolean;
  onChange: (bundles: { [tag: string]: string[] }) => void;
  tagTypeOrder: string[];
  dataTest: string;
};

type Props = ExternalProps;

type State = {
  currentBundleQuery?: string;
};

const inventoryLabelTitle = (
  <FormattedMessage
    id="superadmin.advanced-ads-inventory-bundles"
    defaultMessage="Advertising inventory bundles"
    description="Advertising inventory bundles"
  />
);

class InventoryDropdown extends React.Component<Props, State> {
  state: State = {
    currentBundleQuery: undefined,
  };

  handleInventoryBundleSearch = (currentBundleNameQuery?: string) => {
    this.setState({ currentBundleQuery: currentBundleNameQuery });
  };

  render() {
    if (this.props.isLoading) {
      return (
        <div className={style.bundleList}>
          <SDSLabel labelTitle={inventoryLabelTitle}>
            <SpinnerIcon className={style.bundleLoading} data-test="superAdmin.inventory.drppdown.loadingIcon" />
          </SDSLabel>
        </div>
      );
    }

    const newBundleTagMapping: { [tag: string]: string } = {};
    if (this.state.currentBundleQuery) {
      newBundleTagMapping[this.state.currentBundleQuery] = `Create new bundle "${this.state.currentBundleQuery}"`;
    }

    const bundleOptions = Object.keys(this.props.activeInventoryBundlesByIdMap).reduce((acc, id: string) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[id] = this.props.activeInventoryBundlesByIdMap[id].displayName;
      return acc;
    }, {});

    const defaultBundles = Object.keys(this.props.inventoryBundleItemMappingsByBundleIdMap);

    const tagOptions = {
      [INVENTORY_BUNDLE_TYPE_NEW]: {
        defaultTags: [],
        tagMapping: newBundleTagMapping,
        className: style.newBundleTag,
      },
      [INVENTORY_BUNDLE_TYPE_EXISTING]: {
        defaultTags: defaultBundles,
        tagMapping: bundleOptions,
        className: style.defaultBundleTag,
      },
    };

    return (
      <div className={style.bundleList}>
        <SDSLabel labelTitle={inventoryLabelTitle}>
          <MultiSelectTagFilter
            className={style.bundleListDropdown}
            tagOptions={tagOptions}
            tagTypeOrder={[INVENTORY_BUNDLE_TYPE_NEW, INVENTORY_BUNDLE_TYPE_EXISTING]}
            onChange={this.props.onChange}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            onSearch={this.handleInventoryBundleSearch}
            data-test={this.props.dataTest}
          />
        </SDSLabel>
      </div>
    );
  }
}

export { InventoryDropdown };
export default intlConnect(null, null)(InventoryDropdown);
