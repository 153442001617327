import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { showModal } from 'state/modals/actions/modalsActions';

import { ModalType } from 'utils/modalConfig';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

type Props = {
  from: moment.Moment;
  to: moment.Moment;
};
export function RevenueCSVButton({ from, to }: Props) {
  const dispatch = useDispatch();

  const displayRevenueCSVExportModal = useCallback(() => {
    return dispatch(showModal(ModalType.REVENUE_CSV_MODAL, 'RevenueCSV', { from, to }));
  }, [dispatch, from, to]);

  return (
    <SDSButton type={ButtonType.PRIMARY} onClick={displayRevenueCSVExportModal}>
      {
        <FormattedMessage
          id="revenue-csv-export-button"
          description="Label for revenue csv export button"
          defaultMessage="Export"
        />
      }
    </SDSButton>
  );
}
