import invariant from 'invariant';
import { get, noop } from 'lodash';
import React from 'react';

import { getActiveUploadCountsForComponentIdByPurpose } from 'state/media/selectors/mediaSelectors';
import { getActiveEditionId } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getSnapById } from 'state/snaps/selectors/snapsSelectors';
import { getSubtitlesActiveLanguageCode } from 'state/subtitles/selectors/subtitlesSelectors';

import type { UploadPurpose, DropzoneTypeEnum } from 'config/constants';
import { buildComponentIdForSnapId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { State } from 'types/rootState';

type ExternalProps = {
  purpose: UploadPurpose;
  dropzoneType: DropzoneTypeEnum;
  isReadOnly: boolean;
  snapId?: SnapId;
  'data-test'?: string;
  onUploadFinished?: () => void;
};

type StateProps = {
  storyId: EditionID;
  activeSubtitlesLanguage: string;
  uploading: boolean;
  snapVideoAssetId: AssetID | undefined | null;
};

type OwnProps = ExternalProps & StateProps;

function mapStateToProps(state: State, props: ExternalProps): StateProps {
  const componentId = props.snapId ? buildComponentIdForSnapId(props.snapId) : '';
  const uploading = Boolean(getActiveUploadCountsForComponentIdByPurpose(state)(componentId, props.purpose));
  const storyId = getActiveEditionId(state);

  const snapVideoAssetId = props.snapId ? get(getSnapById(state)(props.snapId), 'videoAssetId') : undefined;
  invariant(storyId, 'storyId is not set');

  return {
    activeSubtitlesLanguage: getSubtitlesActiveLanguageCode(state),
    storyId,
    uploading,
    snapVideoAssetId,
  };
}

type Props = OwnProps & typeof UploadSubtitlesButton.defaultProps;

export class UploadSubtitlesButton extends React.PureComponent<Props> {
  static defaultProps = {
    onUploadFinished: noop,
  };

  handleUploadComplete = () => {
    const { purpose, dropzoneType, activeSubtitlesLanguage, onUploadFinished } = this.props;
    grapheneUtils.incrementCounter('subtitles.uploadComplete', {
      click: `${purpose}-${dropzoneType}`,
      language: activeSubtitlesLanguage,
    });
    (onUploadFinished || noop)();
  };

  render() {
    return (
      <MediaUploader
        isReadOnly={this.props.isReadOnly}
        purpose={this.props.purpose}
        dropzoneType={this.props.dropzoneType}
        editionId={this.props.storyId}
        snapId={this.props.snapId}
        onUploadComplete={this.handleUploadComplete}
        isUploading={this.props.uploading}
        subtitlesLanguage={this.props.activeSubtitlesLanguage}
        assetId={this.props.snapVideoAssetId}
        data-test={this.props['data-test']}
      />
    );
  }
}

export default intlConnect(mapStateToProps, {})(UploadSubtitlesButton);
