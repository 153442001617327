import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getActiveCreator } from 'state/user/selectors/userSelectors';

import { useGetSpotlightAnalytics } from 'gql/hooks/useGetSpotlightAnalytics';

import SpotlightAnalyticsGraph from 'views/analytics/components/SpotlightAnalyticsGraph/SpotlightAnalyticsGraph';
import { SpotlightAnalyticsHeader } from 'views/analytics/components/SpotlightAnalyticsHeader/SpotlightAnalyticsHeader';
import { SpotlightKPIs } from 'views/analytics/components/SpotlightKPIs/SpotlightKPIs';
import TopSpotlightAnalytics from 'views/analytics/components/TopSpotlightAnalytics/TopSpotlightAnalytics';
import { AnalyticsReportRange } from 'views/analytics/utils/kpiConfigs';

import style from './SpotlightAnalyticsView.scss';

import { SpotlightMetric } from 'types/analytics';

export default function SpotlightAnalyticsView() {
  const [activeMetric, setActiveMetric] = useState<SpotlightMetric | null>('totalViewers');
  const [reportRange, setReportRange] = useState<AnalyticsReportRange>(AnalyticsReportRange.WEEK);
  const activePublisherBusinessProfileId = useSelector(getActiveCreator)?.businessProfileId || '';

  const { data, loading, fetchMore } = useGetSpotlightAnalytics(activePublisherBusinessProfileId, '');

  const renderSpotlightKPIsSection = () => {
    return (
      <SpotlightKPIs
        activeMetric={activeMetric}
        reportRange={reportRange}
        setActiveMetric={setActiveMetric}
        spotlightAnalyticsSummary={data?.spotlightAnalytics.profileSpotlightAnalytics.aggregate}
        isLoading={loading}
      />
    );
  };

  const renderSpotlightKPIGraphSection = () => {
    return (
      <SpotlightAnalyticsGraph
        activeMetric={activeMetric!}
        reportRange={reportRange}
        profileSpotlightAnalytics={data?.spotlightAnalytics.profileSpotlightAnalytics}
        isLoading={loading}
      />
    );
  };

  const renderTopSpotlightContentSection = () => {
    return (
      <div className={style.tableContainer}>
        <div className={style.tableTitleContainer}>
          <span className={style.topTilesTitle}>
            <FormattedMessage
              id="spotlight-insights-top-performing-title"
              description="Header of the top performing spotlight component on the insights page"
              defaultMessage="Spotlight"
            />
          </span>
        </div>
        <TopSpotlightAnalytics
          fetchMore={fetchMore}
          isLoading={loading}
          spotlightAnalytics={data?.spotlightAnalytics.spotlightAnalyticsForPage}
        />
      </div>
    );
  };

  return (
    <div>
      <div className={style.datedropdown}>
        <SpotlightAnalyticsHeader currentRange={reportRange} setReportRange={setReportRange} />
      </div>
      <div className={style.content}>
        {renderSpotlightKPIsSection()}
        {renderSpotlightKPIGraphSection()}
        {renderTopSpotlightContentSection()}
      </div>
    </div>
  );
}
