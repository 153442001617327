import React from 'react';
import { FormattedMessage } from 'react-intl';

import BasicTooltip from 'views/common/components/Graph/BasicTooltip';
import Graph from 'views/common/components/Graph/Graph';

import { ProfileRevenue, RevenueMetric } from 'types/analytics';

type Props = {
  activeMetric: RevenueMetric;
  profileRevenue: ProfileRevenue;
};

export function RevenueGraph({ activeMetric, profileRevenue }: Props) {
  const data = [
    {
      color: '#0055FF',
      key: activeMetric,
      values: profileRevenue.timeSeries.map(point => ({
        x: Date.parse(point.timestamp),
        y: point.summary[activeMetric],
      })),
    },
  ];

  return (
    <Graph
      data={data}
      isLoading={false}
      chartType={'lineChart'}
      graphName={
        <FormattedMessage
          id="revenue-graph-header"
          description="Revenue graph header"
          defaultMessage={
            'The figures represented are estimates only, actualized impressions will be provided through the monthly revenue share statements'
          }
        />
      }
      tooltipComponent={BasicTooltip}
    />
  );
}
