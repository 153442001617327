import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { rocket } from 'icons/SDS/allIcons';

import { BadgeType } from 'views/badges/components/Badge/Badge';

export enum BadgeId {
  PAY_TO_PROMOTE = 'PAY_TO_PROMOTE',
  PRODUCT_PLACEMENT = 'PRODUCT_PLACEMENT',
  SPOTLIGHT = 'SPOTLIGHT',
}

export type BadgeOptionsType = {
  id: BadgeId;
  name: ReactNode;
  'data-test': string;
  icon?: string;
  badgeType?: BadgeType;
};

export const BadgeConfig: BadgeOptionsType[] = [
  {
    id: BadgeId.PAY_TO_PROMOTE,
    name: (
      <FormattedMessage
        id="pay-to-promote-promoted"
        description="Message Pay To Promote promoted badge"
        defaultMessage="PROMOTED"
      />
    ),
    'data-test': 'payToPromote',
    icon: rocket,
  },
  {
    id: BadgeId.PRODUCT_PLACEMENT,
    name: (
      <FormattedMessage id="sponsored-story" description="Message Sponsored Story badge" defaultMessage="SPONSORED" />
    ),
    'data-test': 'sponsored',
    icon: rocket,
  },
];
