import moment from 'moment-timezone';
import React from 'react';

import { Divider } from 'views/analytics/components/AnalyticsInsightsPublisherReport/subcomponents/Divider';
import { ReportHeader } from 'views/analytics/components/AnalyticsInsightsPublisherReport/subcomponents/ReportHeader';
import TopStories from 'views/analytics/components/AnalyticsInsightsPublisherReport/subcomponents/TopStories';
import { ToplineStats } from 'views/analytics/components/AnalyticsInsightsPublisherReport/subcomponents/ToplineStats';

import style from './AnalyticsInsightsPublisherReport.scss';
import AudienceBreakdown from './subcomponents/AudienceBreakdown';
import ReportSnapcode from './subcomponents/ReportSnapcode';

import type { DailyAnalytics, CheetahAnalyticsStories } from 'types/analytics';
import type { PublisherID } from 'types/publishers';

type Props = {
  // header data
  publisherName: string;
  storiesPublished: number | undefined | null;
  firstPublish: moment.Moment | undefined | null;
  publisherDescription: string;
  topPerformingStoriesStats: CheetahAnalyticsStories;
  // divider data
  reportStartDate: moment.Moment;
  reportEndDate: moment.Moment;
  // topline
  totalViewers: number;
  // snapcode data
  snapcodeURL: string | undefined | null;
  // tags data
  publisherTags: string[];
  publisherId: PublisherID;
  // bars data
  dailyAnalytics: DailyAnalytics;
  // wording (episode for shows / edition for regular publishers)
  isShow: boolean;
};

/*
This is the actual report to be rendered inside a pdf
 */
export class AnalyticsInsightsPublisherReport extends React.PureComponent<Props> {
  render() {
    return (
      <div className={style.report}>
        <div className={style.reportTopSection}>
          <ReportHeader
            isShow={this.props.isShow}
            publisherName={this.props.publisherName}
            storiesPublished={this.props.storiesPublished}
            firstPublish={this.props.firstPublish}
            description={this.props.publisherDescription}
          />
          <ReportSnapcode
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            tags={this.props.publisherTags}
            snapcodeURL={this.props.snapcodeURL}
          />
        </div>
        <Divider startDate={this.props.reportStartDate} endDate={this.props.reportEndDate} />
        <ToplineStats
          isShow={this.props.isShow}
          topPerformingStoriesStats={this.props.topPerformingStoriesStats}
          reportStartDate={this.props.reportStartDate}
          reportEndDate={this.props.reportEndDate}
          totalViewers={this.props.totalViewers}
        />
        <TopStories topPerformingStoriesStats={this.props.topPerformingStoriesStats} />
        <AudienceBreakdown dailyAnalytics={this.props.dailyAnalytics} tags={this.props.publisherTags} />
      </div>
    );
  }
}

export default AnalyticsInsightsPublisherReport;
