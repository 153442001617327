import type { ReactNode } from 'react';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import type { DropzoneTypeEnum, UploadPurpose } from 'config/constants';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import { DisplayRange } from 'views/common/components/SubtitlesPreview/components/SubtitlesBody/SubtitlesBody';
import GenerateSubtitlesButton from 'views/subtitles/GenerateSubtitlesButton/GenerateSubtitlesButton';
import NewSubtitlesButton from 'views/subtitles/NewSubtitlesButton/NewSubtitlesButton';
import UploadSubtitlesButton from 'views/subtitles/UploadSubtitlesButton/UploadSubtitlesButton';

import style from './MultiLanguageSubtitlesManager.scss';

import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';

type Props = {
  purpose: UploadPurpose;
  dropzoneType: DropzoneTypeEnum;
  optionsPopover?: ReactNode;
  isReadOnly: boolean;
  dataTestPrefix: string;
  hasSubtitles: boolean;
  showInfo: boolean;
  subtitlesDropdown: ReactNode;
  snapId?: SnapId | null;
  hideHeader?: boolean;
  onGenerationFinished?: () => void;
  onUploadFinished?: () => void;
  uploadDisabled?: boolean;
  generateDisabled?: boolean;
  videoAssetId?: AssetID;
  displayRange?: DisplayRange;
};

function MultiLanguageSubtitlesManager({
  purpose,
  dropzoneType,
  isReadOnly,
  dataTestPrefix,
  hasSubtitles,
  optionsPopover,
  subtitlesDropdown,
  snapId,
  hideHeader,
  onGenerationFinished,
  onUploadFinished,
  showInfo,
  uploadDisabled,
  generateDisabled,
  videoAssetId,
  displayRange,
}: Props) {
  return (
    <div className={style.rootContainer} data-test="MultiLanguageSubtitlesManager.root">
      {!hideHeader && (
        <div className={style.title}>
          {getMessageFromId('subtitles-title')}
          <HelpCenterLink
            data-test="MultiLanguageSubtitlesManager.title"
            destination={HelpCenterDestination.SUBTITLES}
          />
        </div>
      )}
      {showInfo && (
        <div className={style.description}>
          <FormattedMessage
            id="single-asset-subtitles-description"
            description="General description of client facing subtitles functionality"
            defaultMessage={
              'Subtitles will be shown to users if their volume is low, if they have subtitles enabled in their phone' +
              ' accessibility settings, or if they have enabled subtitles in Snapchat’s settings.'
            }
            data-test={`${dataTestPrefix}.subtitlesDescription`}
          />
        </div>
      )}
      {subtitlesDropdown}
      <div className={style.subtitleStoryControlsContainer}>
        <UploadSubtitlesButton
          purpose={purpose}
          dropzoneType={dropzoneType}
          isReadOnly={isReadOnly || uploadDisabled}
          data-test={`${dataTestPrefix}.uploadSubtitlesButton`}
          snapId={snapId}
          onUploadFinished={onUploadFinished}
        />
        <GenerateSubtitlesButton
          hasSubtitles={hasSubtitles}
          purpose={purpose}
          dropzoneType={dropzoneType}
          isReadOnly={isReadOnly || generateDisabled}
          data-test={`${dataTestPrefix}.generateSubtitlesButton`}
          snapId={snapId}
          onGenerationFinished={onGenerationFinished}
        />
        {hasSubtitles || (
          <NewSubtitlesButton
            snapId={snapId}
            videoAssetId={videoAssetId}
            purpose={purpose}
            isReadOnly={isReadOnly || generateDisabled || uploadDisabled}
            displayRange={displayRange}
          />
        )}
        {optionsPopover}
      </div>
    </div>
  );
}

export default memo(MultiLanguageSubtitlesManager);
