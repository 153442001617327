import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getActiveCreatorHostUserId } from 'state/user/selectors/userSelectors';

import { DELETE_STORY_SNAPS } from '../mutations/spotlight/deleteStorySnap';
import { StorySnap, StorySnapType } from '../queries/spotlight/storySnaps';

import { useProxyMutation } from 'utils/apis/graphQLUtils';

export function getSnapIds(snaps: StorySnap[]): Array<string> {
  return snaps.map((snap: StorySnap) => snap.id);
}

// Hook returns the callback to be triggered on deletion
export function useDeleteStorySnaps() {
  const [deleteStorySnaps] = useProxyMutation(DELETE_STORY_SNAPS);
  const hostUserId = useSelector(getActiveCreatorHostUserId);

  const deleteSnaps = useCallback(
    async (snaps: StorySnap[]) => {
      const snapIds = getSnapIds(snaps);
      return deleteStorySnaps({
        variables: {
          input: {
            hostUserId,
            snapIds,
            type: StorySnapType.OUR_STORY,
          },
        },
      });
    },
    [deleteStorySnaps, hostUserId]
  );

  return { deleteSnaps };
}
