import * as React from 'react';

import DefaultHeader from './DefaultHeader';
import GraphTooltip from './GraphTooltip';

/**
 * This just wraps around a DefaultHeader component. Set your graph to use this if you want
 * similar appearance to the default NVD3 library tooltip.
 */
export default class BasicTooltip extends React.Component {
  render() {
    return (
      <GraphTooltip {...this.props}>
        <DefaultHeader {...this.props} />
      </GraphTooltip>
    );
  }
}
