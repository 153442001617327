import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from '../storySnapMenuCardStyle.scss';

import { visible, hidden } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';

type Props = {
  saveToProfile: boolean;
  toggleSaveToProfile: () => void;
};

export default function SaveToProfileCard({ saveToProfile, toggleSaveToProfile }: Props) {
  return (
    <div className={style.cardContainer}>
      <div className={style.cardInfo}>
        <Icon inlineIcon={saveToProfile ? visible : hidden} className={style.icon} />
        <div className={style.cardTitle}>
          <div className={style.heading}>
            <FormattedMessage
              defaultMessage="Save to Profile"
              description="Save spotlight snap to public profile"
              id="save-to-profile"
            />
          </div>

          <FormattedMessage
            defaultMessage="Show this snap on the public Profile page"
            description="Explanation of what saving to profile means to user"
            id="save-to-profile-descripton"
          />
        </div>
      </div>
      <div className={style.toggleWrapper}>
        <SDSSwitch data-test="SaveToProfileCard.toggle" onChange={toggleSaveToProfile} value={saveToProfile} />
      </div>
    </div>
  );
}
