/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionReportTime {
  CONVERSION = "CONVERSION",
  EXPOSURE = "EXPOSURE",
}

/**
 * AM control a FG to populate this parameter for ad accounts that should see estimated conversions.
 * https: // snapchat.quip.com/FVmXAmyj40Pc
 */
export enum ConversionCalc {
  AC = "AC",
  EC = "EC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/**
 * Conversion source type used exclusively by ad stats.
 */
export enum ConversionSourceType {
  APP = "APP",
  NATIVE_PROFILE = "NATIVE_PROFILE",
  NATIVE_PROMOTION = "NATIVE_PROMOTION",
  NATIVE_STORE = "NATIVE_STORE",
  OFFLINE = "OFFLINE",
  TOTAL_CONVERSIONS = "TOTAL_CONVERSIONS",
  TOTAL_OFF_PLATFORM = "TOTAL_OFF_PLATFORM",
  TOTAL_ON_PLATFORM = "TOTAL_ON_PLATFORM",
  UNKNOWN_CONVERSION_SOURCE = "UNKNOWN_CONVERSION_SOURCE",
  WEB = "WEB",
}

export enum SwipeUpAttributionWindow {
  DAY_1 = "DAY_1",
  DAY_28 = "DAY_28",
  DAY_7 = "DAY_7",
}

export enum ViewAttributionWindow {
  DAY_1 = "DAY_1",
  DAY_28 = "DAY_28",
  DAY_7 = "DAY_7",
  HOUR_1 = "HOUR_1",
  HOUR_3 = "HOUR_3",
  HOUR_6 = "HOUR_6",
  NONE = "NONE",
}

export interface DateRangeInput {
  readonly startDate: any;
  readonly endDate: any;
}

export interface StatFieldInput {
  readonly field: string;
  readonly conversionSourceType?: ConversionSourceType | null;
}

export interface StatsInput {
  readonly fields: ReadonlyArray<StatFieldInput>;
  readonly dateRange?: DateRangeInput | null;
  readonly swipeUpAttributionWindow: SwipeUpAttributionWindow;
  readonly viewAttributionWindow: ViewAttributionWindow;
  readonly actionReportTime?: ActionReportTime | null;
  readonly isBetaReach?: boolean | null;
  readonly conversionCalc?: ConversionCalc | null;
  readonly previousStartDate?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
