import moment from 'moment-timezone';
import React from 'react';

import style from './Divider.scss';

type Props = {
  startDate: moment.Moment;
  endDate: moment.Moment;
};

export class Divider extends React.Component<Props> {
  getDividerText() {
    return `${moment(this.props.startDate).format('MMM D, YYYY')} –  ${moment(this.props.endDate).format(
      'MMM D, YYYY'
    )}`;
  }

  render() {
    return (
      <div className={style.divider}>
        <div className={style.dividerLine} />
        <p className={style.dividerHeader}>{this.getDividerText()}</p>
        <div className={style.dividerLine} />
      </div>
    );
  }
}

export default Divider;
