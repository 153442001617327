import React from 'react';
import { FormattedMessage } from 'react-intl';

import BitmojiAvatar from '../BitmojiAvatar/BitmojiAvatar';

import { BITMOJI_TEMPLATES } from 'config/bitmojiConfig';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SubmittedPostSection.scss';

type Props = {
  isError?: boolean;
  isScheduling?: boolean;
  handleClearEditor: () => void;
};

export default function SubmittedPostSection({ isError, handleClearEditor, isScheduling }: Props) {
  const successMessage = isScheduling ? (
    <FormattedMessage
      defaultMessage="Yay! Your post is now scheduled"
      description="Successful schedule title"
      id="schedule-story-snap-success-title"
    />
  ) : (
    <FormattedMessage
      defaultMessage="Yay! Your post is now submitted"
      description="Successful post title"
      id="post-story-snap-success-title"
    />
  );
  const successSubheading = isScheduling ? (
    <FormattedMessage
      defaultMessage="You can edit your scheduled post on the homepage."
      description="Successful schedule subheading"
      id="schedule-story-snap-success-subheading"
    />
  ) : (
    <FormattedMessage
      defaultMessage="It can take a few seconds for your post to appear in Snapchat."
      description="Successful post subheading"
      id="post-story-snap-success-subheading"
    />
  );
  return (
    <div data-test="SubmittedPostSection.wrapper" className={style.container}>
      <div className={style.bitmojiWrapper}>
        <BitmojiAvatar
          data-test="SubmittedPostSection.bitmoji"
          bitmojiTemplateId={isError ? BITMOJI_TEMPLATES.USER_ERROR : BITMOJI_TEMPLATES.USER_CORRECT}
        />
      </div>
      <div className={style.headingWrapper}>
        <div className={style.heading}>
          {isError ? (
            <FormattedMessage
              defaultMessage="Your post failed to post"
              description="Failed Post to snapchat title"
              id="post-story-snap-failed-title"
            />
          ) : (
            successMessage
          )}
        </div>
        <div className={style.subheading}>
          {isError ? (
            <FormattedMessage
              defaultMessage="Please check your connection"
              description="Failed Post to snapchat subtitle"
              id="post-story-snap-failed-subheading"
            />
          ) : (
            successSubheading
          )}
        </div>
      </div>

      <div className={style.clearButtonWrapper}>
        <SDSButton
          data-test="SubmittedPostSection.startAgain.button"
          type={ButtonType.PRIMARY}
          onClick={handleClearEditor}
        >
          <FormattedMessage defaultMessage="Start again" description="Start again label" id="start-again-spotlight" />
        </SDSButton>
      </div>
    </div>
  );
}
