import Select from 'antd/lib/select';
import React from 'react';
import type { ReactNode } from 'react';

import type { OptionGroup, DropdownOption } from 'types/SDSDropdown';

export function createSDSDropdownOptionGroups(optionGroups?: Array<OptionGroup>): ReactNode {
  if (!optionGroups) {
    return null;
  }
  return optionGroups.map((optionGroup: OptionGroup) => (
    <Select.OptGroup key={optionGroup.key} label={optionGroup.label}>
      {optionGroup.children.map((option: DropdownOption) => (
        <Select.Option key={`SDSDropdownOption.${option.value}`} value={option.value} disabled={option.disabled}>
          {option.label}
        </Select.Option>
      ))}
    </Select.OptGroup>
  ));
}
