import moment from 'moment-timezone';
import React, { useCallback } from 'react';

import { getRangeMessage } from 'utils/intlMessages/intlMessages';

import { RevenueCSVButton } from 'views/analytics/components/RevenueCSVButton/RevenueCSVButton';
import { CalendarShortcut } from 'views/common/containers/DateRangePickerModal/DateRangePickerModal';
import CalendarButton from 'views/homepage/containers/CalendarButton/CalendarButton';

import style from './RevenueHeader.scss';

type Props = {
  from: moment.Moment;
  to: moment.Moment;
  setDateRange: (from: moment.Moment, to: moment.Moment) => void;
  maxEndDate: moment.Moment;
};

export function RevenueHeader({ from, to, setDateRange, maxEndDate }: Props) {
  const onDateRangeUpdated = useCallback(
    (fromMoment?: moment.Moment | null, toMoment?: moment.Moment | null) => {
      setDateRange(fromMoment || maxEndDate, toMoment || maxEndDate);
    },
    [maxEndDate, setDateRange]
  );

  const renderDateFilterButton = () => {
    const range = getRangeMessage(from, to, 'MM/DD');

    return (
      <div className={style.headerContainer}>
        <div className={style.revenueHeaderButton}>
          <CalendarButton
            onUpdate={onDateRangeUpdated}
            defaultText={range}
            from={from}
            to={to}
            shouldShowDropDownButton
            maxDateRangeDuration={90}
            maxDate={maxEndDate}
            shortcuts={
              new Set<CalendarShortcut>([
                CalendarShortcut.CUSTOM,
                CalendarShortcut.LAST_CALENDAR_MONTH,
                CalendarShortcut.LAST_THIRTY_DAYS,
                CalendarShortcut.LAST_SEVEN_DAYS,
              ])
            }
          />
        </div>
        <RevenueCSVButton from={from} to={to} />
      </div>
    );
  };

  return <div className={style.headerButtonContainer}>{renderDateFilterButton()}</div>;
}
