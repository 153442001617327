import { EDITION_KPI_CONFIG, EDITION_KPI_CONFIG_V2 } from 'views/analytics/utils/kpiConfigs';

export enum EditionMetricId {
  PLAYS = 'plays',
  UNIQUE_DAU = 'uniqueDau',
  PUBLISHER_SUBSCRIBE_COUNT = 'publisherSubscribeCount',
  DERIVED_TOTAL_VIEWS = 'derivedTotalViews',
  COMPLETION_RATE = 'completionRate',
  UNIQUE_TOP_SNAP_VIEW_PER_USER = 'uniqueTopSnapViewPerUser',
  AVG_TOTAL_TIME_VIEWED = 'avgTotalTimeViewed',
  ATTACHMENT_CONVERSION = 'longformConversionRate',
  TOTAL_SHARE_COUNT = 'totalShareCount',
  TOTAL_SCREENSHOT_COUNT = 'totalScreenshotCount',
  AVG_VIEW_PERCENTAGE = 'avgViewedPercentage',
}

export const makeEditionKpiConfig = (metricIds: Array<EditionMetricId>) => {
  return metricIds.map(metricId => EDITION_KPI_CONFIG.find(metric => metric.metricId === metricId)).filter(kpi => kpi);
};

export const makeEditionKpiConfigV2 = (metricIds: Array<EditionMetricId>) => {
  return metricIds
    .map(metricId => EDITION_KPI_CONFIG_V2.find(metric => metric.metricId === metricId))
    .filter(kpi => kpi);
};

export const DEFAULT_EDITION_KPI_METRICS = [
  EditionMetricId.PLAYS,
  EditionMetricId.UNIQUE_DAU,
  EditionMetricId.PUBLISHER_SUBSCRIBE_COUNT,
  EditionMetricId.DERIVED_TOTAL_VIEWS,
  EditionMetricId.COMPLETION_RATE,
  EditionMetricId.UNIQUE_TOP_SNAP_VIEW_PER_USER,
  EditionMetricId.AVG_TOTAL_TIME_VIEWED,
  EditionMetricId.ATTACHMENT_CONVERSION,
  EditionMetricId.TOTAL_SHARE_COUNT,
  EditionMetricId.TOTAL_SCREENSHOT_COUNT,
];

export const SHOW_EDITION_KPI_METRICS = [
  EditionMetricId.PLAYS,
  EditionMetricId.UNIQUE_DAU,
  EditionMetricId.AVG_TOTAL_TIME_VIEWED,
  EditionMetricId.AVG_VIEW_PERCENTAGE,
  EditionMetricId.PUBLISHER_SUBSCRIBE_COUNT,
  EditionMetricId.COMPLETION_RATE,
  EditionMetricId.UNIQUE_TOP_SNAP_VIEW_PER_USER,
  EditionMetricId.ATTACHMENT_CONVERSION,
];
