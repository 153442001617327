import { gql } from '@apollo/client';
import { sortBy } from 'lodash';
import log from 'loglevel';
import { useMemo } from 'react';

import { useAdsQuery } from 'utils/apis/graphQLUtils';

import {
  ProfileAdAccountsQuery,
  ProfileAdAccountsQueryVariables,
} from 'views/payToPromote/hooks/useProfileAdAccountsQuery/__agw-generated__/ProfileAdAccountsQuery';

type AdAccountId = string;

interface AdAccount {
  id: string;
  name: string;
}

export type ProfileAdAccountsQueryResult = {
  profileAdAccountsError?: string;
  adAccountId?: AdAccountId;
  adAccounts?: AdAccount[];
  promotedStoriesIds?: Set<string>;
};

export const GET_PROFILE_AD_ACCOUNTS = gql`
  query ProfileAdAccountsQuery($organizationId: ID!, $profileId: ID!) {
    profileAdAccounts: profileAdAccounts(
      organizationId: $organizationId
      profileId: $profileId
      adAccountFilterInput: { accessibleForAdCreation: true }
    ) {
      ... on AdAccountConnection {
        edges {
          node {
            id
            name
          }
        }
      }
      ... on GenericError {
        message
      }
    }
    profileAds: profileAdAccounts(organizationId: $organizationId, profileId: $profileId) {
      ... on AdAccountConnection {
        edges {
          node {
            ads {
              ... on AdConnection {
                edges {
                  node {
                    creative {
                      publisherStoryProperties {
                        story {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ... on GenericError {
        message
      }
    }
  }
`;

export function useProfileAdAccountsQuery(
  orgId: string | undefined | null,
  bizProfileId: string | null
): ProfileAdAccountsQueryResult {
  const { error, data } = useAdsQuery<ProfileAdAccountsQuery, ProfileAdAccountsQueryVariables>(
    GET_PROFILE_AD_ACCOUNTS,
    {
      variables: { organizationId: orgId!, profileId: bizProfileId! },
      skip: !orgId || !bizProfileId, // skip when either one of these are empty or nil, otherwise server error
    }
  );

  return useMemo(() => {
    if (error) {
      log.error('Failed to fetch ProfileAdAccounts', error.message);
      return {
        profileAdAccountsError: error.message,
      };
    }

    // Ad Accounts
    if (data?.profileAdAccounts?.__typename === 'GenericError') {
      log.error('Failed to fetch ProfileAdAccounts data: profileAdAccounts', data.profileAdAccounts.message);
      return {
        profileAdAccountsError: data.profileAdAccounts.message,
      };
    }

    const adAccounts = sortBy(
      (data?.profileAdAccounts?.edges || [])
        .filter(edge => edge?.node?.name && edge.node.id)
        .map(edge => {
          const node = edge!.node!; // note: AGW edge and node are always defined here
          return {
            name: node.name ?? '',
            id: node.id,
          };
        }),
      adAccount => adAccount.name.toLocaleLowerCase()
    );

    // Ads
    if (data?.profileAds?.__typename === 'GenericError') {
      log.error('Failed to fetch ProfileAdAccounts data: profileAds', data.profileAds.message);
      return {
        profileAdAccountsError: data.profileAds.message,
      };
    }

    const promotedStoriesIds: Set<string> = new Set();
    (data?.profileAds?.edges || []).forEach(adAccountEdge => {
      if (adAccountEdge?.node?.ads?.__typename !== 'GenericError') {
        (adAccountEdge?.node?.ads?.edges || []).forEach(adEdge => {
          const storyId = adEdge?.node?.creative?.publisherStoryProperties?.story?.id;
          if (storyId) {
            promotedStoriesIds.add(storyId);
          }
        });
      }
    });

    return {
      adAccountId: adAccounts[0]?.id,
      adAccounts,
      promotedStoriesIds,
    };
  }, [data, error]);
}
