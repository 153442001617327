import classNames from 'classnames';
import React from 'react';

import { isProductPlacementEnabled } from 'state/features/selectors/featuresSelectors';

import { intlConnect } from 'utils/connectUtils';

import Badge from 'views/badges/components/Badge/Badge';
import { BadgeId, BadgeConfig, BadgeOptionsType } from 'views/badges/utils/badgesConfig';

import style from './BadgesContainer.scss';

import { State } from 'types/rootState';

export enum BadgesAlignmentType {
  ROW = 'row',
  COLUMN = 'column',
}
type StyleOptions = {
  direction?: BadgesAlignmentType;
};
type FeaturesOptions = {
  isStoryPromoted?: boolean;
  isStorySponsored?: boolean;
};
type OwnProps = {
  featuresOptions: FeaturesOptions;
  styleOptions?: StyleOptions;
};

type StateProps = {
  isProductPlacementEnabled?: boolean;
};
const mapStateToProps = (state: State): StateProps => {
  return {
    isProductPlacementEnabled: isProductPlacementEnabled(state),
  };
};

type Props = OwnProps & StateProps;

export function BadgesContainer(props: Props) {
  const isStorySponsored = () => props.featuresOptions.isStorySponsored && props.isProductPlacementEnabled;

  const isBadgeEnabled = (badgeId: BadgeId) => {
    switch (badgeId) {
      case BadgeId.PAY_TO_PROMOTE:
        return props.featuresOptions.isStoryPromoted;
      case BadgeId.PRODUCT_PLACEMENT:
        return isStorySponsored();
      default:
        return false;
    }
  };

  return (
    <div
      className={classNames(style.badgesContainer, {
        [style.row]: props.styleOptions?.direction !== BadgesAlignmentType.COLUMN,
        [style.column]: props.styleOptions?.direction === BadgesAlignmentType.COLUMN,
      })}
    >
      {BadgeConfig.map((badge: BadgeOptionsType) => {
        return isBadgeEnabled(badge.id) ? <Badge {...badge} key={badge['data-test']} /> : undefined;
      })}
    </div>
  );
}
export default intlConnect(mapStateToProps, null)(BadgesContainer);
