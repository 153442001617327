import _ from 'lodash';
import React from 'react';
import type { ChangeEvent } from 'react';
import { Column } from 'react-virtualized';

import * as featuresActions from 'state/features/admin/actions/featuresActions';
import * as featuresAdminSelectors from 'state/features/admin/selectors/featuresAdminSelectors';
import { getPublisherDetailsDataById } from 'state/publishers/selectors/publishersSelectors';

import { search } from 'icons/SDS/allIcons';
import { UIFeature } from 'src/state/features/admin/featuresAdminState';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import type { CheckboxEvent } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import { SDSCheckbox } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import TableWithCustomFilter from 'views/common/components/TableWithCustomFilter/TableWithCustomFilter';
import SaveSettingsButton from 'views/onboarding/containers/SettingsView/save/SaveSettingsButton';

import style from './FeaturesAdminTab.scss';

import type { FeatureId } from 'types/features';
import type { PublisherID } from 'types/publishers';

type ExternalProps = {
  publisherId: PublisherID;
};
type StateProps = {
  businessProfileId: string;
  features: Array<UIFeature>;
  isRequestInProgress: boolean;
};
type DispatchProps = {
  loadFeaturesForPublisher: typeof featuresActions.loadFeaturesForPublisher;
  saveAllFeaturesForPublisher: typeof featuresActions.saveAllFeaturesForPublisher;
};
type Props = ExternalProps & StateProps & DispatchProps;
type OwnState = {
  query: string;
  filteredFeatures: Array<UIFeature>;
  changes: {
    [key in FeatureId]: boolean;
  };
};
type RowIndex = {
  index: number;
};
type RowRender = {
  rowData: UIFeature;
};
function mapStateToProps(state: State, props: ExternalProps): StateProps {
  const features = featuresAdminSelectors.getFeaturesList(state);
  const isRequestInProgress = featuresAdminSelectors.isRequestInProgress(state);
  const publisher = getPublisherDetailsDataById(state)(props.publisherId);
  const businessProfileId = (publisher && publisher.businessProfileId) || '';
  return {
    businessProfileId,
    features,
    isRequestInProgress,
  };
}
const mapDispatchToProps = {
  loadFeaturesForPublisher: featuresActions.loadFeaturesForPublisher,
  saveAllFeaturesForPublisher: featuresActions.saveAllFeaturesForPublisher,
};
export class FeaturesAdminTab extends React.PureComponent<Props, OwnState> {
  state = {
    query: '',
    filteredFeatures: [],
    changes: {},
  };

  UNSAFE_componentWillMount() {
    this.props.loadFeaturesForPublisher(this.props.businessProfileId);
  }

  componentDidMount() {
    this.filterFeatures(this.props.features, this.state.query);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.filterFeatures(nextProps.features, this.state.query);
  }

  onSearchChange = (event: ChangeEvent<EventTarget>) => {
    const query = _.get(event, 'target.value', '');
    this.filterFeatures(this.props.features, query);
  };

  filterFeatures(features: Array<UIFeature>, query: string) {
    if (this.props.features === features && query === this.state.query) {
      return;
    }
    let filteredFeatures = features;
    if (query && query !== '') {
      filteredFeatures = features.filter(feature => {
        const featureId = feature.id.toLowerCase();
        const featureDescription = feature.description.toLowerCase();
        const queryLowerCase = query.toLowerCase();
        return featureId.includes(queryLowerCase) || featureDescription.includes(queryLowerCase);
      });
    }
    this.setState({ query, filteredFeatures });
  }

  hasChanges() {
    return Object.keys(this.state.changes).length > 0;
  }

  handleOnSave = () => {
    const featuresToChange = Object.keys(this.state.changes).map(feature => ({
      feature,
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      enable: this.state.changes[feature],
    }));
    (this.props.saveAllFeaturesForPublisher(
      this.props.publisherId,
      this.props.businessProfileId,
      featuresToChange,
      this.props.features
    ) as any).then(() => this.setState({ changes: {} }));
  };

  handleFeatureCheckChanged = (event: CheckboxEvent<void>) => {
    const id = _.get(event, 'target.id', '');
    const enabled = _.get(event, 'target.checked', false);
    this.updateChangedFeature(id, enabled);
  };

  updateChangedFeature(featureId: FeatureId, enabled: boolean) {
    this.setState(prevState => {
      const { [featureId]: isEnabled, ...changes } = prevState.changes;
      return typeof isEnabled === 'undefined' ? { changes: { [featureId]: enabled, ...changes } } : { changes };
    });
  }

  rowGetter = ({ index }: RowIndex) => {
    const { filteredFeatures } = this.state;
    if (filteredFeatures && filteredFeatures[index]) {
      return filteredFeatures[index];
    }
    return null;
  };

  hasFeatureChanged(feature: FeatureId) {
    return feature in this.state.changes;
  }

  renderFeature = ({ rowData: { id, description } }: RowRender) => {
    return (
      <div className={style.featurePreview}>
        <div className={style.featureName}>{id}</div>
        <div className={style.featureDescription}>{description}</div>
      </div>
    );
  };

  renderCheckbox = ({ rowData }: RowRender) => {
    const featureId = rowData.id;
    const featureChanged = this.hasFeatureChanged(featureId);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const checked = (featureChanged && this.state.changes[featureId]) || (!featureChanged && rowData.enabled);
    return (
      <SDSCheckbox
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        id={rowData.id}
        checked={checked}
        className={style.checkbox}
        onChange={this.handleFeatureCheckChanged}
      />
    );
  };

  renderFeaturesTable() {
    return (
      <div className={style.table}>
        <TableWithCustomFilter
          ref="Table"
          rowGetter={this.rowGetter}
          rowCount={this.state.filteredFeatures.length}
          rowHeight={64}
        >
          <Column label="Feature" disableSort dataKey="id" width={500} cellRenderer={this.renderFeature} />
          <Column
            label="Owner"
            className={style.owner}
            headerClassName={style.owner}
            disableSort
            dataKey="owner"
            width={100}
            flexGrow={1}
          />
          <Column
            label="Enabled"
            dataKey="enabled"
            disableSort
            width={100}
            flexGrow={0}
            cellRenderer={this.renderCheckbox}
          />
        </TableWithCustomFilter>
      </div>
    );
  }

  renderHeaderPanel = () => {
    return (
      <div className={style.rootPanel}>
        <div className={style.searchBoxContainer}>
          <SDSInput
            value={this.state.query}
            onChange={this.onSearchChange}
            inlineIcon={search}
            autoFocus
            data-test="common.userManagement.searchBox.input"
          />
          <SaveSettingsButton onClick={this.handleOnSave} isSaving={false} isActive={this.hasChanges()} />
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.features) {
      return null;
    }
    return (
      <div className={style.root}>
        {this.renderHeaderPanel()}
        {this.props.isRequestInProgress ? null : this.renderFeaturesTable()}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(FeaturesAdminTab);
