import { gql } from '@apollo/client';

export const DELETE_STORY_DRAFTING_SNAPS = gql`
  mutation DeleteStoryDraftingSnaps($input: DeleteStoryDraftingSnapsInput) {
    deleteStoryDraftingSnaps(input: $input) {
      __typename
      ... on DeleteStoryDraftingSnapsSuccess {
        deletedSnapIds
      }
      ... on DeleteStorySnapError {
        message
      }
    }
  }
`;
