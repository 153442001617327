import React from 'react';
import type { SyntheticEvent } from 'react';
import { defineMessages, intlShape } from 'react-intl';

import { incrementCounter } from 'utils/grapheneUtils';

import SDSInput from 'views/common/components/SDSInput/SDSInput';

type Props = {
  defaultValue?: string;
  onChange: (a: string) => void;
};

type State = {
  value: string;
};

const messages = defineMessages({
  searchPlaceholder: {
    id: 'search-stories-placeholder-label',
    description: 'Search Stories placeholder label',
    defaultMessage: 'Search Stories',
  },
});

export default class HomepageSearchBox extends React.PureComponent<Props, State> {
  static contextTypes = {
    intl: intlShape,
  };

  state = {
    value: this.props.defaultValue ? this.props.defaultValue : '',
  };

  runOnChangeTimer: null | NodeJS.Timeout = null;

  handleOnFocus = () => {
    incrementCounter('Homepage.SearchBox.focus');
  };

  handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value });
    if (this.runOnChangeTimer) {
      clearTimeout(this.runOnChangeTimer);
    }
    this.runOnChangeTimer = setTimeout(this.runOnChange, 200);
  };

  runOnChange = () => {
    this.props.onChange(this.state.value);
    if (this.runOnChangeTimer) {
      clearTimeout(this.runOnChangeTimer);
    }
  };

  render() {
    return (
      <div>
        <SDSInput
          value={this.state.value || ''}
          placeholder={this.context.intl.formatMessage(messages.searchPlaceholder)}
          onChange={this.handleOnChange}
          data-test="HomepageSearchbox.input"
          onFocus={this.handleOnFocus}
        />
      </div>
    );
  }
}
