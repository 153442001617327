import { Moment } from 'moment';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { getStoryChapters } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import {
  getActivePublisherPrimaryLanguage,
  isActivePublisherSubscribable,
} from 'state/publishers/selectors/publishersSelectors';
import { getChaptersSummary } from 'state/snaps/schema/snapEntityHelpers';
import { getActiveCreator, getActiveCreatorHostUserId } from 'state/user/selectors/userSelectors';

import { DEFAULT_ANALYTICS_GEO } from 'config/constants';
import { useGetStoryPageAnalytics } from 'gql/hooks/useGetStoryPageAnalytics';
import { usePermissionCheck } from 'gql/hooks/usePermissionCheck';
import {
  formatSnapMetrics,
  formatStoryMetricNonPaid,
  formatStoryMetricPaid,
  getPreviewSnapAnalytics,
  getProcessedSnap,
} from 'gql/mocks/useGetStoryAnalyticsMocks';
import { GetStoryPageAnalytics } from 'gql/queries/analytics/__ssp-generated__/GetStoryPageAnalytics';
import { extractFirstFrames, extractFrame, FFmpegLib } from 'utils/ffmpeg/FFmpegLib';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { printSecondsToTimeFormat } from 'utils/numberFormatter';
import { isStoryPromoted } from 'utils/payToPromoteUtils';

import AnalyticsContentInfo, {
  EditionData,
} from 'views/analytics/components/AnalyticsContentInfo/AnalyticsContentInfo';
import { AnalyticsEditionHeader } from 'views/analytics/components/AnalyticsEditionHeader/AnalyticsEditionHeader';
import { EditionCountry, getEnumKey } from 'views/analytics/components/AnalyticsEditionHeader/EditionCountry';
import AnalyticsFooter from 'views/analytics/components/AnalyticsFooter/AnalyticsFooter';
import AnalyticsStoryGraphs from 'views/analytics/components/AnalyticsStoryGraphs/AnalyticsStoryGraphs';
import AnalyticsStoryKPIs from 'views/analytics/components/AnalyticsStoryKPIs/AnalyticsStoryKPIs';
import AnalyticsTopsnapPreview from 'views/analytics/components/AnalyticsTopsnapPreview/AnalyticsTopsnapPreview';
import TileVariantTable from 'views/analytics/components/TileVariantTable/TileVariantTable';
import { TabsConfig } from 'views/analytics/containers/AnalyticsEditionView/AnalyticsEditionView';
import { glossaryOfAnalytics } from 'views/analytics/containers/AnalyticsEditionView/AnalyticsEditionViewIntlMessage';
import style from 'views/analytics/containers/AnalyticsEditionView/AnalyticsEditionViewV2/AnalyticsEditionViewV2.scss';
import { ANALYTICS_TABS } from 'views/analytics/containers/AnalyticsEditionView/tabs/analyticsTabsConfig';
import AnalyticsPayToPromoteKPIs from 'views/analytics/containers/AnalyticsPayToPromoteKPIs/AnalyticsPayToPromoteKPIs';
import { AnalyticsTabsEnum } from 'views/analytics/containers/AnalyticsView/analyticsTabsConfig';
import { getDatesFromSearchParams, updateDatesInSearchParams } from 'views/analytics/utils/searchParamsUtil';
import BadgesContainer, { BadgesAlignmentType } from 'views/badges/containers/BadgesContainer/BadgesContainer';
import SDSAlert from 'views/common/components/SDSAlert/SDSAlert';
import SDSRow from 'views/common/components/SDSRow/SDSRow';
import SDSTabs, { TabHeader } from 'views/common/components/SDSTabs/SDSTabs';
import { Spinner, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import SnapPublisherPreCache from 'views/editor/components/SnapPublisher/SnapPublisherPreCache';

import type {
  SnapAnalyticsResult,
  StoryAnalyticsResult,
  TileAnalyticsMetric,
  TileAnalyticsResult,
  TileStats,
} from 'types/analytics';
import { AnalyticsStatsType } from 'types/analytics';
import { Claim } from 'types/permissions';

function getStoryAnalyticsData(data?: GetStoryPageAnalytics) {
  let storyAnalyticsData: StoryAnalyticsResult | null = null;
  if (data?.storyAnalytics.__typename === 'StoryAnalyticsResult') {
    storyAnalyticsData = data?.storyAnalytics.profileStoryAnalytics;
  }
  return storyAnalyticsData;
}

function getTileAnalyticsDataEntries(data?: GetStoryPageAnalytics) {
  const tileAnalyticsResponse = data?.tileAnalytics;
  const tileAnalyticsDataEntries: TileAnalyticsResult = tileAnalyticsResponse?.tileAnalytics || [];
  return tileAnalyticsDataEntries;
}

function getSnapAnalyticsDataEntries(data?: GetStoryPageAnalytics) {
  const snapAnalyticsResponse = data?.snapAnalytics;
  const snapAnalyticsDataEntries: SnapAnalyticsResult = snapAnalyticsResponse?.snapAnalytics?.analyticsEntries || [];
  return snapAnalyticsDataEntries;
}

function getTileStat(tile: TileAnalyticsMetric, editionId: string) {
  return {
    tileId: tile?.id || '',
    avgTotalTimeViewed: tile?.tileAverageViewTime.toString() || '',
    editionId: editionId || '',
    headline: tile?.headline || '',
    indexedCtr: tile?.indexedCtr.toString() || '',
    impressionPercentage: tile?.impressionPercentage.toString() || '',
    uniqueTopsnapsPerUser: tile?.uniqueTopsnapsPerUser.toString() || '',
    tileImageUrl: tile?.tileImageUrl || '',
    tileLogoUrl: tile?.tileLogoUrl || '',
  };
}

function getCountryKey(country: EditionCountry) {
  return country === EditionCountry.ALL ? getEnumKey(country) : country;
}

function renderSpinnerLoader() {
  return (
    <div className={style.spinnerContainer}>
      <Spinner className={(style as any).spinner} loading size={SpinnerSizes.LARGE} />
    </div>
  );
}

export type PreviewFrame = {
  src: string;
};

interface DailyMetrics {
  [date: string]: {
    editionDau: number; // Change the type according to the actual type of entry.value
  };
}

export const AnalyticsEditionViewV2 = () => {
  const history = useHistory();
  const location = useLocation();
  type EditionAnalyticsParams = { editionId: string };
  const editionAnalyticsParams = useParams<EditionAnalyticsParams | null>();
  const editionId = editionAnalyticsParams?.editionId;
  const canAccessAnalytics = usePermissionCheck(Claim.ANALYTICS_VIEWER);
  const [isZeroCountriesAlertModalVisible, setIsZeroCountriesAlertModalVisible] = useState(false);
  const [analyticsTabName, setAnalyticsTabName] = useState<AnalyticsTabsEnum>(ANALYTICS_TABS.OVERVIEW);
  const activePublisher = useSelector(getActiveCreator);
  const primaryLanguage = useSelector(getActivePublisherPrimaryLanguage);
  const isSubscribable = useSelector(isActivePublisherSubscribable);
  const [isCSVExporting, setIsCSVExporting] = useState(false);
  const [previewFrames, setPreviewFrames] = useState<string[]>([]);
  const [isImagePreviewLoaded, setImagePreviewLoaded] = useState(false);

  const storyChaptersSelector = useSelector(getStoryChapters);
  const chapters = getChaptersSummary(storyChaptersSelector(parseInt(editionId!, 10)));

  const [isGraphDataLoading] = useState(false);
  const initialFrom = getDatesFromSearchParams(location).from || null;
  const initialTo = getDatesFromSearchParams(location).to || null;
  const [fromDate, setFromDate] = useState<moment.Moment | null>(initialFrom);
  const [toDate, setToDate] = useState<moment.Moment | null>(initialTo);
  const [country, setCountry] = useState(EditionCountry.ALL);

  const hostUserId = useSelector(getActiveCreatorHostUserId) || '';

  const { data, loading } = useGetStoryPageAnalytics(
    fromDate?.format('YYYY-MM-DD'),
    toDate?.format('YYYY-MM-DD'),
    hostUserId,
    editionId!,
    getCountryKey(country)
  );

  const storyAnalyticsData = getStoryAnalyticsData(data);
  const tileAnalyticsDataEntries = getTileAnalyticsDataEntries(data);
  const snapAnalyticsDataEntries = getSnapAnalyticsDataEntries(data);
  const [pickedCountryCodes] = useState<string | string[]>(DEFAULT_ANALYTICS_GEO);
  const [edition] = useState({});
  const [analyticsStatsType, setAnalyticsStatType] = useState(AnalyticsStatsType.ALL);

  const getOrderedTileStats = (): TileStats => {
    if (pickedCountryCodes === DEFAULT_ANALYTICS_GEO) {
      return tileAnalyticsDataEntries.map(tile => {
        return getTileStat(tile!, editionId!);
      });
    }

    return [];
  };

  const getStoryRuntimeMs = () => {
    const hasChapterId = snapAnalyticsDataEntries?.every(snapPreview => snapPreview?.hasChapterId);
    if (hasChapterId && snapAnalyticsDataEntries.length > 0) {
      return snapAnalyticsDataEntries[0]?.duration;
    }
    return snapAnalyticsDataEntries.reduce((acc, snap) => acc + (snap?.duration || 0), 0);
  };

  async function getMediaFirstPreviewFrames() {
    const mediaURL = snapAnalyticsDataEntries[0]?.mediaUrl || '';
    const seekPoints = snapAnalyticsDataEntries?.map(snapPreview => printSecondsToTimeFormat(snapPreview?.seekPoint));
    return extractFrame(mediaURL, seekPoints || []);
  }

  useEffect(() => {
    const frameUrls: string[] = [];
    if (snapAnalyticsDataEntries.length > 0 && !isImagePreviewLoaded) {
      const fetchPreviewFrames = async () => {
        const mediaPreviewUrls = snapAnalyticsDataEntries?.map(snapPreview => snapPreview?.mediaUrl || '');
        const ffmpegLib = new FFmpegLib();
        await ffmpegLib.triggerLoad();

        const hasChapter = snapAnalyticsDataEntries?.some(snapPreview => snapPreview?.seekPoint !== null);

        if (!hasChapter) {
          const firstFrames = await extractFirstFrames(mediaPreviewUrls || []);
          frameUrls.push(...firstFrames);
        } else {
          const firstFrames = await getMediaFirstPreviewFrames();
          frameUrls.push(...firstFrames);
        }
        setPreviewFrames(frameUrls);
        setImagePreviewLoaded(true);
      };

      if (snapAnalyticsDataEntries.length > 0) {
        fetchPreviewFrames();
      }
    }

    return () => {
      // Release preview frames as it takes up a lot of memory for the long stories.
      frameUrls.map(frame => URL.revokeObjectURL(frame));
      setPreviewFrames([]);
      setImagePreviewLoaded(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapAnalyticsDataEntries.length]);

  const getActiveTabs = (): TabHeader[] => {
    const activeTabs = [TabsConfig[ANALYTICS_TABS.OVERVIEW]!];
    activeTabs.push(TabsConfig[ANALYTICS_TABS.TILES]!);
    return [...activeTabs, TabsConfig[ANALYTICS_TABS.PROMOTION]!];
  };

  const onTabSelected = (index: number): void => {
    const selectedTab = getActiveTabs()[index];
    const currentTabName = Object.keys(TabsConfig).filter(key => TabsConfig[key] === selectedTab)[0]!;
    incrementCounter(GrafanaMetrics.STORY_ANALYTICS, {
      type: 'tabChange',
      action: currentTabName?.toLowerCase(),
    });

    if (currentTabName === ANALYTICS_TABS.PROMOTION) {
      setAnalyticsStatType(AnalyticsStatsType.PAID);
    } else if (currentTabName === ANALYTICS_TABS.OVERVIEW) {
      setAnalyticsStatType(AnalyticsStatsType.ALL);
    }
    setAnalyticsTabName(currentTabName);
  };

  const onDateRangeUpdated = useCallback(
    async (from?: Moment | null, to?: Moment | null) => {
      incrementCounter(GrafanaMetrics.STORY_ANALYTICS, {
        type: 'dateFilter',
      });
      setFromDate(from!);
      setToDate(to!);
      updateDatesInSearchParams(history, from!, to!);
    },
    [history]
  );

  const onCountryChange = useCallback((updatedCountry: EditionCountry) => {
    incrementCounter(GrafanaMetrics.STORY_ANALYTICS, {
      type: 'countryFilter',
      action: updatedCountry?.toLowerCase(),
    });
    setCountry(updatedCountry);
  }, []);

  const getTitle = () => {
    if (tileAnalyticsDataEntries) {
      const tileAnalyticsData = tileAnalyticsDataEntries[0];
      if (tileAnalyticsData) {
        return tileAnalyticsData?.headline;
      }
    }
    return (
      <FormattedMessage
        id="analytics-edition-view-default-title"
        description="Title for page of analytics about editions"
        defaultMessage="Publisher Story #{editionId}"
        values={{ editionId }}
      />
    );
  };

  const renderStoryInfo = () => {
    if (!edition) {
      return null;
    }
    const durationMs = chapters ? chapters.reduce((ms, chapter) => ms + chapter.durationMs, 0) : undefined;
    const editionData: EditionData = {
      firstLiveDate: moment(storyAnalyticsData?.firstLiveDate || moment.now()),
      ageGate: storyAnalyticsData?.ageGate || false,
    };
    return (
      <AnalyticsContentInfo
        edition={editionData}
        // We do not have the affected by moderation field for editions yet
        reachAffectedByModeration={false}
        title={glossaryOfAnalytics}
        subtitle={getMessageFromId('analytics-edition-story-info')}
        snapCount={snapAnalyticsDataEntries.length}
        videoDurationMs={durationMs}
        tileCount={tileAnalyticsDataEntries.length}
        editionMetrics={storyAnalyticsData}
        isAnalyticsV2Enabled
      />
    );
  };

  const renderSnapListContainer = () => {
    const storyId = Number(editionId);
    const processedSnaps = snapAnalyticsDataEntries.map((snapAnalytics, index) =>
      getProcessedSnap(snapAnalytics, index)
    );
    const firstTile = tileAnalyticsDataEntries[0];
    return (
      <div className={style.snapListContainer}>
        {renderStoryInfo()}
        <AnalyticsTopsnapPreview
          editionId={storyId}
          processedSnaps={processedSnaps}
          previewFrames={getPreviewSnapAnalytics(snapAnalyticsDataEntries, previewFrames, firstTile)}
          chapters={chapters}
          isAnalyticsV2Enabled
          isImagePreviewLoaded={isImagePreviewLoaded}
        />
      </div>
    );
  };

  const renderTopSnapPreview = () => {
    return (
      <SDSRow>
        <div className={style.topsnapPreviewContainer}>
          <div className={style.cardBox}>{renderSnapListContainer()}</div>
        </div>
      </SDSRow>
    );
  };

  function getTimeSeriesDailyMetrics() {
    return {
      ALL: {
        dailyMetrics: storyAnalyticsData?.uniqueViewersForTimestampEntries?.reduce(
          (accumulator: DailyMetrics, entry) => {
            const date = moment(entry.timeStamp).format('YYYY-MM-DD');
            accumulator[date] = {
              editionDau: entry.value,
            };
            return accumulator;
          },
          {}
        ),
      },
      PAID: {
        dailyMetrics: storyAnalyticsData?.uniqueViewersPaidForTimestampEntries?.reduce(
          (accumulator: DailyMetrics, entry) => {
            const date = moment(entry.timeStamp).format('YYYY-MM-DD');
            accumulator[date] = {
              editionDau: entry.value,
            };
            return accumulator;
          },
          {}
        ),
      },
    };
  }

  const renderGraphs = () => {
    const editionAnalyticsState = { editionId, ...storyAnalyticsData };
    const snapMetrics = snapAnalyticsDataEntries.map(analyticEntry => ({
      [analyticEntry?.snapId!]: analyticEntry?.analytics,
    }));
    const isPaidImpression = analyticsStatsType === AnalyticsStatsType.PAID;
    const formattedSnapMetrics = snapAnalyticsDataEntries.map((analyticEntry, index) => {
      const mediaUrl = previewFrames[index] || '';
      return formatSnapMetrics(
        index,
        analyticEntry?.snapId,
        analyticEntry?.duration,
        mediaUrl,
        analyticEntry?.seekPoint,
        isPaidImpression,
        analyticEntry?.analytics,
        snapAnalyticsDataEntries
      );
    });

    const analytics = {
      edition: editionAnalyticsState,
      snaps: snapMetrics,
      editionDaily: getTimeSeriesDailyMetrics(),
      editionMetrics: storyAnalyticsData,
      editionId,
    };

    return (
      <AnalyticsStoryGraphs
        analytics={analytics}
        chapters={chapters}
        isGlobalGeo={pickedCountryCodes === DEFAULT_ANALYTICS_GEO}
        isShowingAllTimeStats={!fromDate && !toDate}
        isShowingSingleDayStats={fromDate?.isSame(toDate)}
        isSubscribable={isSubscribable}
        primaryLanguage={primaryLanguage}
        analyticsStatsType={analyticsStatsType}
        storySnapMetricsV2={snapAnalyticsDataEntries}
        formattedSnapMetrics={formattedSnapMetrics}
        storyMetricsV2={storyAnalyticsData}
        isGraphDataLoading={isGraphDataLoading}
        snapPreviewFrames={previewFrames}
        useAnalyticsV2
      />
    );
  };

  const renderPromotionTab = () => {
    const storyMetrics = formatStoryMetricPaid(storyAnalyticsData!);
    return (
      <div data-test="AnalyticsEditionView.promotionTab">
        {
          <AnalyticsPayToPromoteKPIs
            data-test="AnalyticsEditionView.analyticsPayToPromoteKPIs"
            storyAnalytics={storyMetrics}
            storyId={editionId!}
            isStoryPromoted={isStoryPromoted(+editionId!)}
            isAnalyticsV2
          />
        }
        {renderTopSnapPreview()}
        {renderGraphs()}
      </div>
    );
  };

  const renderEditionKPIs = () => {
    const storyMetrics = formatStoryMetricNonPaid(storyAnalyticsData!);
    return (
      <SDSRow>
        <AnalyticsStoryKPIs
          activePublisher={activePublisher}
          storyAnalytics={storyMetrics}
          data-test="AnalyticsEditionView.analyticsStoryKPIs"
          storySnapCountV2={snapAnalyticsDataEntries.length}
          useAnalyticsV2
          storyRuntimeV2={getStoryRuntimeMs()}
        />
      </SDSRow>
    );
  };

  const renderDefaultTab = () => {
    return (
      <div data-test="editionAnalyticsDefaultTab">
        {renderEditionKPIs()}
        {renderTopSnapPreview()}
        {renderGraphs()}
      </div>
    );
  };

  function renderContent() {
    switch (analyticsTabName) {
      case ANALYTICS_TABS.TILES:
        return <TileVariantTable editionId={editionId!} useAnalyticsV2 orderedTileStats={getOrderedTileStats()} />;
      case ANALYTICS_TABS.PROMOTION:
        return renderPromotionTab();
      default:
      case ANALYTICS_TABS.OVERVIEW:
        return renderDefaultTab();
    }
  }

  function renderPage() {
    if (!edition) {
      return null;
    }

    const activeTabs = getActiveTabs();
    return (
      <div className={style.containerFluid}>
        <div data-test="analytics.analyticsEditionview.gridPage" className={style.header}>
          <div className={style.headerText}>
            {getTitle()}
            <div className={style.badgeContainer}>
              <BadgesContainer
                styleOptions={{ direction: BadgesAlignmentType.ROW }}
                featuresOptions={{
                  isStoryPromoted: isStoryPromoted(+editionId!),
                  isStorySponsored: storyAnalyticsData?.isSponsored,
                }}
              />
            </div>
          </div>
          {activeTabs.length > 1 && (
            <div className={style.tabsContainer}>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <SDSTabs defaultTab={0} handleTabSelected={onTabSelected} tabs={activeTabs} />
            </div>
          )}
          <AnalyticsEditionHeader
            from={fromDate}
            to={toDate}
            country={country}
            setCountry={onCountryChange}
            setDateRange={onDateRangeUpdated}
            storyState={storyAnalyticsData?.storyState}
            editionId={Number(editionId)}
            setIsExporting={setIsCSVExporting}
          />
        </div>
        <div className={style.content}>{renderContent()}</div>
        <AnalyticsFooter />
      </div>
    );
  }

  const renderZeroCountriesAlertModal = () => {
    return (
      <SDSAlert
        visible={isZeroCountriesAlertModalVisible}
        /* eslint-disable-next-line react/jsx-no-bind */
        onConfirm={() => setIsZeroCountriesAlertModalVisible(false)}
        data-test={'analyticsEditionView.sdsAlert'}
      >
        <FormattedMessage
          id="alert-zero-countries"
          description="Alert message for not selecting any country"
          defaultMessage="Please select at least one country."
        />
      </SDSAlert>
    );
  };

  const pageContent = (
    <>
      {renderPage()}
      {renderZeroCountriesAlertModal()}
      {chapters && <SnapPublisherPreCache handshake />}
    </>
  );

  if (loading || isCSVExporting) {
    return renderSpinnerLoader();
  }

  return <> {canAccessAnalytics && pageContent} </>;
};

export default AnalyticsEditionViewV2;
