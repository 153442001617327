import classnames from 'classnames';
import React, { memo } from 'react';
import type { ReactNode } from 'react';

import style from './InfoBox.scss';

type Props = {
  title?: ReactNode;
  className?: string;
  children: ReactNode;
  'data-test'?: string;
};

function InfoBox({ title, className, children, 'data-test': dataTest }: Props) {
  return (
    <div className={classnames(style.infoBox, className)} data-test={dataTest}>
      {title && <label className={style.infoTitle}>{title}</label>}
      <div className={style.infoContent}>{children}</div>
    </div>
  );
}

export default memo(InfoBox);
