import { get } from 'lodash';

import type { LogoPositionEnum } from 'utils/logoConfig';
import * as assetUtils from 'utils/media/assetUtils';

import type { AssetID } from 'types/assets';
import type { Tile } from 'types/tiles';

export function buildPreviewImageUrl(tile: Tile) {
  if (tile && tile.baseImageAssetId && tile.imageCropPositions) {
    const croppedImage = assetUtils.getImagePreviewUrl(tile.croppedImageAssetId);
    return get(tile, 'generatedBlobURI', croppedImage);
  }

  return null;
}

export function getLogoAssetId(tile: Tile): AssetID | undefined | null {
  return tile.logoImageAssetId;
}

export function getLogoPosition(tile: Tile): LogoPositionEnum | undefined | null {
  return tile.logoPosition || null;
}

// Warning: This URL is for internal use only - don't serve to users!
export function buildBitmojiImageUrl(templateId: string): string {
  return `https://render.bitstrips.com/render/panel/${templateId}-206514888_999-s5-v1.png`;
}
