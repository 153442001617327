import { useQuery } from '@apollo/client';

import { ScheduledStoryStatus, StorySnapType } from '__ssp-generated__/globalTypes';
import { GetStoryDraftingSnaps } from 'gql/queries/spotlight/__ssp-generated__/GetStoryDraftingSnaps';
import { GET_STORY_DRAFTING_SNAPS } from 'gql/queries/spotlight/storyDraftingSnaps';

export const useGetScheduledSpotlightPosts = (businessProfileId: string, hostUserId: string, cursor: string) => {
  const { data, loading, fetchMore } = useQuery<GetStoryDraftingSnaps>(GET_STORY_DRAFTING_SNAPS, {
    variables: {
      input: {
        businessProfileId,
        hostUserId,
        cursor,
        limit: 10,
        scheduledStoryStatus: ScheduledStoryStatus.SCHEDULED,
        storySnapType: StorySnapType.OUR_STORY,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  return { data, loading, fetchMore };
};
