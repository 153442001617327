import is from 'is_js';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ANALYTICS_UTC_OFFSET = -8 * 60;

/**
 *
 * @param {time stamp in Moment format} m
 */
export function toAnalyticsTimeStamp(m: any) {
  return m.utcOffset(ANALYTICS_UTC_OFFSET).startOf('day');
}

export function getSpecifiedTime(number: any, length: any) {
  const result = toAnalyticsTimeStamp(moment());
  if (is.number(number) && is.string(length)) {
    result.subtract(number, length);
  }
  return result;
}

/**
 * eg. 2017-01-01 7am UTC => 2017-01-01 7am PST
 *
 * @param {time stamp in Moment format} time
 * @param {https://momentjs.com/docs/#/manipulating/utc-offset/} utcOffset
 */
export function withNewTimezone(time: any, utcOffset = ANALYTICS_UTC_OFFSET) {
  // create a new moment based on the original one
  const newTime = time.clone();

  // change the offset of the new moment
  newTime.utcOffset(utcOffset);

  // shift the moment by the difference in offsets
  newTime.add(time.utcOffset() - newTime.utcOffset(), 'minutes');

  return newTime;
}

/**
 *
 * @param {time stamp in Moment format} m
 */
export function momentToEpoch(m: any) {
  return m.format('X');
}

export const TIME_OPTIONS = [
  {
    key: '1 year',
    name: <FormattedMessage id="one-year" description="1 year" defaultMessage="1 Year" />,
    min: () => getSpecifiedTime(1, 'years'),
    max: () => getSpecifiedTime(0, 'days'),
    from: () => getSpecifiedTime(1, 'years'),
    to: () => getSpecifiedTime(0, 'days'),
  },
  {
    key: '6 months',
    name: <FormattedMessage id="six-months" description="6 months" defaultMessage="6 Months" />,
    min: () => getSpecifiedTime(6, 'months'),
    max: () => getSpecifiedTime(0, 'days'),
    from: () => getSpecifiedTime(6, 'months'),
    to: () => getSpecifiedTime(0, 'days'),
  },
  {
    key: '3 months',
    name: <FormattedMessage id="three-months" description="3 months" defaultMessage="3 Months" />,
    min: () => getSpecifiedTime(3, 'months'),
    max: () => getSpecifiedTime(0, 'days'),
    from: () => getSpecifiedTime(3, 'months'),
    to: () => getSpecifiedTime(0, 'days'),
  },
  {
    key: '30 days',
    name: <FormattedMessage id="thirty-days" description="30 days" defaultMessage="30 Days" />,
    min: () => getSpecifiedTime(31, 'days'),
    max: () => getSpecifiedTime(0, 'days'),
    from: () => getSpecifiedTime(31, 'days'),
    to: () => getSpecifiedTime(0, 'days'),
  },
];
