import _ from 'lodash';

export type Manager = {
  email: string;
  name: string;
};

// The database of all media partner managers so we can display a nice select in the UI.
export const MANAGERS_LIST: Array<Manager> = _.sortBy(
  [
    {
      name: 'Unassigned',
      email: 'newpublisher@snap.com',
    },
    {
      name: 'Test',
      email: 'testchannel@snapchat.com',
    },
    {
      name: 'Jordan Burt',
      email: 'burt@snapchat.com',
    },
    {
      name: 'Christina Lim',
      email: 'clim@snapchat.com',
    },
    {
      name: 'Content Creation',
      email: 'content-creation@snapchat.com',
    },
    {
      name: 'Discover Partner Support',
      email: 'psol@snapchat.com',
    },
    {
      name: 'Emma Wakely',
      email: 'emma.wakely@snapchat.com',
    },
    {
      name: 'Emma Jane Porteous',
      email: 'eporteous@snapchat.com',
    },
    {
      name: 'Internal Team',
      email: 'internal@snapchat.com',
    },
    {
      name: 'Jacquelyn Villani',
      email: 'jacquelyn.villani@snapchat.com',
    },
    {
      name: 'Jean-Philippe Louis',
      email: 'jlouis@snapchat.com',
    },
    {
      name: 'Kahlen Macaulay',
      email: 'kahlen@snap.com',
    },
    {
      name: 'Lauren Leo',
      email: 'lleo@snapchat.com',
    },
    {
      name: 'Maia Knudsen-Schule',
      email: 'maia@snapchat.com',
    },
    {
      name: 'Rashmi Daryanani',
      email: 'rdaryanani@snapchat.com',
    },
    {
      name: 'Sara Abu Zahra',
      email: 'saz@snap.com',
    },
    {
      name: 'Tara Carey',
      email: 'tara.carey@snapchat.com',
    },
    {
      name: 'Jorrit Eringa',
      email: 'jeringa@snapchat.com',
    },
    {
      name: 'Nader Mohamed',
      email: 'nmohamed3@snapchat.com',
    },
    {
      name: 'Ignacio De Los Reyes Mora',
      email: 'idelosreyesmora@snapchat.com',
    },
    {
      name: 'Lucy Luke',
      email: 'lluke@snapchat.com',
    },
    {
      name: 'Hamilton King',
      email: 'hamilton@snapchat.com',
    },
    {
      name: 'Samantha Lux',
      email: 'samantha.lux@snap.com',
    },
    {
      name: 'Jordyn Fischer',
      email: 'jfischer@snap.com',
    },
    {
      name: 'Patrick Connolly',
      email: 'patrick.connolly@snap.com',
    },
    {
      name: 'Hriday Ganatra',
      email: 'hganatra@c.snap.com',
    },
    {
      name: 'Kanishk Khanna',
      email: 'kanishk@snap.com',
    },
    {
      name: 'Starr Nathan',
      email: 'snathan@snapchat.com',
    },
    {
      name: 'Roxanne Chinoy',
      email: 'rchinoy@snapchat.com',
    },
  ],
  ['name', 'email']
);

export const MANAGERS_BY_EMAIL = new Map(MANAGERS_LIST.map(manager => [manager.email, manager]));
