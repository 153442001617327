import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from './AnalyticsFooter.scss';

export class AnalyticsFooter extends React.PureComponent {
  renderDisclaimer() {
    return (
      <div className={classNames(style.analyticsDisclaimer)}>
        <FormattedMessage
          id="analytics-disclaimer"
          description="Analytics legal disclaimer"
          defaultMessage={
            'These metrics are provided for informational purposes only and determined based on what Snap Inc. believes ' +
            'to be reasonable estimates for the applicable period of measurement. Please note that there are inherent challenges ' +
            'in measuring user activity and changes in our products may impact the ability to accurately provide these metrics.'
          }
        />
      </div>
    );
  }

  render() {
    return <div className={classNames(style.analyticsFooter)}>{this.renderDisclaimer()}</div>;
  }
}

export default AnalyticsFooter;
