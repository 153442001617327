import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

import FeaturesAdminTab from 'views/snapAdmin/containers/Features/FeaturesAdminTab';

import PublisherSettingsTab from './PublisherSettingsTab/PublisherSettingsTab';
import SuperAdminTab from './SuperAdminTab/SuperAdminTab';

export const SETTINGS_TABS = enumObject({
  PUBLISHER: 'PUBLISHER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  FEATURES: 'FEATURES',
});

export type SettingsTabsEnum = Enum<typeof SETTINGS_TABS>;

export const TABS_CONFIG = {
  [SETTINGS_TABS.PUBLISHER]: {
    content: PublisherSettingsTab,
  },
  [SETTINGS_TABS.SUPER_ADMIN]: {
    content: SuperAdminTab,
  },
  [SETTINGS_TABS.FEATURES]: {
    content: FeaturesAdminTab,
  },
};
