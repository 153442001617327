import * as React from 'react';

type Props = {
  headerTextProvider?: (...args: any[]) => any;
  headerFormatter?: (...args: any[]) => any;
  d?: any;
  snapPreviewFrames?: any[];
};

/**
 * Simple text header for a tooltip. props.headerTextProvider can be set to provide a context-specific title,
 * otherwise the default NVD3-style headerFormatter is used.
 */
export default class HeaderText extends React.Component<Props> {
  render() {
    const headerText = this.props.headerTextProvider
      ? this.props.headerTextProvider(this.props.d, this.props.snapPreviewFrames)
      : // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        this.props.headerFormatter(this.props.d.value);
    return (
      <tr>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <td colSpan="3">
          <strong className="x-value" style={{ overflowWrap: 'break-word' }}>
            {headerText}
          </strong>
        </td>
      </tr>
    );
  }
}
