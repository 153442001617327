import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { showModal } from 'state/modals/actions/modalsActions';

import Icon from '../Icon/Icon';

import { ModerationStatus, StorySnap } from 'gql/queries/spotlight/storySnaps';
import { calendar, timer, warningCircle } from 'icons/SDS/allIcons';
import { decryptMedia } from 'utils/media/crypto';
import { ModalType } from 'utils/modalConfig';
import { formatDate, formatDateAndTime } from 'utils/timezoneUtils';

import Badge, { BadgeType } from 'views/badges/components/Badge/Badge';
import { BadgeId } from 'views/badges/utils/badgesConfig';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/ghost_missing.svg.inlin... Remove this comment to see the full error message
import ghostMissingSVG from 'images/ghost_missing.svg.inline';

import style from './StorySnapCell.scss';

type Props = {
  snap: StorySnap;
  useBadgeContent?: boolean;
  handleRemoveSnap: (snap: StorySnap) => void;
  handleUpdateSnapGoLiveTime: (snap: StorySnap, updatedGoLiveTime: number) => void;
};

type ModerationStatusProps = {
  moderationStatus?: ModerationStatus;
  iconFill?: IconFill;
  useBadge?: boolean;
};

export enum IconFill {
  WHITE,
  BLACK,
}

function renderSubmittedStatus(useBadge?: boolean) {
  const content = (
    <FormattedMessage
      id="moderation-label-submitted"
      description="Story is submitted to moderation"
      defaultMessage="Submitted"
    />
  );

  if (useBadge) {
    return (
      <Badge
        id={BadgeId.SPOTLIGHT}
        icon={timer}
        name={content}
        data-test="submitted-datattest"
        badgeType={BadgeType.WHITE_ON_GREY}
      />
    );
  }
  return (
    <div data-test="StorySnapCell.ModerationStatusOverlay.Submitted">
      <Icon inlineIcon={timer} className={style.icon} />
      {content}
    </div>
  );
}

function renderLimitedReachStatus(useBadge?: boolean) {
  const content = (
    <FormattedMessage
      id="moderation-label-limited-reach"
      description="Story has limited reach"
      defaultMessage="Limited Reach"
    />
  );

  if (useBadge) {
    return (
      <Badge
        id={BadgeId.SPOTLIGHT}
        icon={warningCircle}
        name={content}
        data-test="submitted-datattest"
        badgeType={BadgeType.WHITE_ON_GREY}
      />
    );
  }
  return (
    <div data-test="StorySnapCell.ModerationStatusOverlay.LimitedReach">
      <Icon inlineIcon={warningCircle} className={style.icon} />
      {content}
    </div>
  );
}

export function ModerationStatusWidget({ moderationStatus, useBadge }: ModerationStatusProps) {
  switch (moderationStatus) {
    case ModerationStatus.SUBMITTED:
      return renderSubmittedStatus(useBadge);
    case ModerationStatus.LIMITED_DISTRIBUTION:
      return renderLimitedReachStatus(useBadge);
    default:
      return null;
  }
}

export default function StorySnapCell({ snap, handleRemoveSnap, handleUpdateSnapGoLiveTime }: Props) {
  const dispatch = useDispatch();

  const videoPlayer = useRef<HTMLVideoElement>(null);

  const [decryptedMediaUrl, setDecryptedMediaUrl] = useState<string | undefined>();

  const displaySpotlightDetailsModal = useCallback(() => {
    return dispatch(
      showModal(ModalType.SPOTLIGHT_DETAILS, 'SpotlightDetails', { snap, handleRemoveSnap, handleUpdateSnapGoLiveTime })
    );
  }, [dispatch, handleRemoveSnap, handleUpdateSnapGoLiveTime, snap]);

  const handleOnClick = useCallback(() => {
    displaySpotlightDetailsModal();
  }, [displaySpotlightDetailsModal]);

  // Scheduled snaps do not have thumbnail, therefore we need to decrypt video to preload first frame as poster.
  useEffect(() => {
    if (snap?.goLiveTsMs != null) {
      const decryptedMedia = decryptMedia(snap);
      decryptedMedia.then(result => {
        setDecryptedMediaUrl(result?.snapUrl);
      });
    }
  }, [snap]);

  const renderCreationDate = () => {
    if (snap?.creationTimestampMs) {
      return (
        <div data-test={'StorySnapCell.creationDate'} className={style.creationDate}>
          <Icon inlineIcon={calendar} className={style.icon} />
          <span>{formatDate(snap.creationTimestampMs)}</span>
        </div>
      );
    }
    return <></>;
  };

  const renderGoLiveDate = () => {
    if (snap?.goLiveTsMs) {
      return (
        <div data-test={'StorySnapCell.goLiveDate'} className={style.creationDate}>
          <Icon inlineIcon={calendar} className={style.icon} />
          <span>
            <FormattedMessage
              id="story-snap-cell-scheduled-label"
              description="Label to indicate snap is in scheduled state"
              defaultMessage="Scheduled"
            />
          </span>
          <br />
          <span>{formatDateAndTime(snap.goLiveTsMs)}</span>
        </div>
      );
    }
    return <></>;
  };

  const renderSnapOverlay = () => (
    <div className={style.overlayWrapper}>
      {<ModerationStatusWidget moderationStatus={snap.moderationStatus} />}
      {renderCreationDate()}
      {renderGoLiveDate()}
    </div>
  );

  return (
    <div className={style.cellWrapper}>
      <video
        ref={videoPlayer}
        className={style.video}
        src={snap.goLiveTsMs == null ? snap.video?.url : `${decryptedMediaUrl}#t=0.1`}
        poster={snap.posterUrl}
        preload="metadata"
        crossOrigin="anonymous"
        muted // https://github.com/testing-library/react-testing-library/issues/470
        loop
        playsInline
        onClick={handleOnClick}
      />
      {renderSnapOverlay()}
    </div>
  );
}
