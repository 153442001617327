import { memoize } from 'lodash';
import React from 'react';

import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { fetchEditionPreviewSnapcode } from 'state/snapcodes/actions/snapcodesActions';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as locationUtils from 'utils/locationUtils';
import { getActionUrl } from 'utils/snapcodeUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import type { EditionID } from 'types/editionID';
import { BuildSettingByQuality, Quality } from 'types/snapcodes';

type $Call2<F extends (...args: any) => any, A, B> = F extends (a: A, b: B, ...args: any) => infer R ? R : never;

type OwnProps = {
  editionId: EditionID;
};

type StateProps = {
  publisherName: string;
  businessProfileId: string;
};

type DispatchProps = {
  fetchEditionPreviewSnapcode: $Call2<typeof fetchEditionPreviewSnapcode, EditionID, {}>;
};

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
  loading: boolean;
};

const mapStateToProps = (state: State) => {
  const publisher = getActivePublisherDetails(state);
  return {
    publisherName: publisher?.mutablePublisherName,
    businessProfileId: publisher?.businessProfileId,
  };
};

const mapDispatchToProps = {
  fetchEditionPreviewSnapcode,
};

export class PreviewableStoryButton extends React.Component<Props, OwnState> {
  state = { loading: false };

  // Since we only need to call this method once, we memoize to avoid unnecessary extra calls

  fetchEditionPreviewSnapcode = memoize(async (editionId: EditionID) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    return this.props.fetchEditionPreviewSnapcode(editionId, BuildSettingByQuality[Quality.HIGH]);
  });

  goToStoryDeeplink = async (editionId: EditionID) => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });
    // Even though we don't need to call the preview snapcode endpoint to know the deeplink URL
    // there is still a need to call it so that it creates the Deeplink on the backend
    // We can cache this call, since it's not gonna matter if we call it after the first time
    await this.fetchEditionPreviewSnapcode(editionId);
    this.setState({ loading: false });

    const actionUrl = getActionUrl(this.props.publisherName, editionId, this.props.businessProfileId);
    locationUtils.replaceLocation(actionUrl);
  };

  handleStoryClick = (editionId: EditionID) => () => {
    return this.goToStoryDeeplink(editionId);
  };

  render() {
    return (
      <SDSButton
        loading={this.state.loading}
        disabled={this.state.loading}
        type={ButtonType.PRIMARY}
        onClick={this.handleStoryClick(this.props.editionId)}
        data-test="common.previewableStory.button"
      >
        {getMessageFromId('preview-button-label')}
      </SDSButton>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(PreviewableStoryButton);
