// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Row, Col } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import _ from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';
import { intlShape } from 'react-intl';

import type { AnalyticsState } from 'state/analytics/analyticsState';
import { getStoryDemographicsMetrics, getStorySnapMetrics } from 'state/analytics/selectors/analyticsSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import { StoryGraph } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { printDuration } from 'utils/numberFormatter';

import AnalyticsDemographicsGraph from 'views/analytics/components/AnalyticsDemographicsGraph/AnalyticsDemographicsGraph';
import AnalyticsGenderGraph from 'views/analytics/components/AnalyticsGenderGraph/AnalyticsGenderGraph';
import AnalyticsMultiQuestionQuizGraph from 'views/analytics/components/AnalyticsMultiQuestionQuizGraph/AnalyticsMultiQuestionQuizGraph';
import AnalyticsStoryBasicPerSnapGraphs from 'views/analytics/components/AnalyticsStoryBasicPerSnapGraphs/AnalyticsStoryBasicPerSnapGraphs';
import * as chartConfigs from 'views/analytics/utils/chartConfigs';
import BasicTooltip from 'views/common/components/Graph/BasicTooltip';
import Graph from 'views/common/components/Graph/Graph';
import PollTimelineTooltip from 'views/common/components/Graph/PollTimelineTooltip';

import type {
  AnalyticsStatsTypeEnum,
  StoryDemographMetrics,
  StoryMetricResults,
  StorySnapMetrics,
} from 'types/analytics';
import { AnalyticsStatsType, ProcessedSnapMetricResults } from 'types/analytics';
import type { State } from 'types/rootState';
import { ChapterSummary } from 'types/singleAssetStoryEditor';

type OwnProps = {
  analytics: AnalyticsState;
  chapters: ChapterSummary[] | undefined;
  snapPreviewFrames: any;
  isGlobalGeo: boolean;
  isShowingAllTimeStats: boolean;
  isShowingSingleDayStats: boolean;
  isSubscribable: boolean;
  primaryLanguage: string;
  analyticsStatsType: string;
  storyGraphSnapFilter?: (a: StorySnapMetrics) => boolean;
  storySnapMetricsV2?: ProcessedSnapMetricResults[];
  storyDemographicMetricsV2?: StoryDemographMetrics[];
  storyMetricsV2?: StoryMetricResults;
  isGraphDataLoading: boolean;
  useAnalyticsV2: boolean;
};

type StateProps = {
  storySnapMetrics?: StorySnapMetrics[];
  storyDemographicMetrics?: StoryDemographMetrics[];
  analyticsStatsType: AnalyticsStatsTypeEnum;
};
type ChartConfig = {
  dataFunc: (
    analytics: AnalyticsState,
    a?: {
      isSubscribable: boolean;
    } | null
  ) => unknown[];
  props: {
    graphId: string;
    graphName: ReactNode;
    chartType: string;
    tooltip: ReactNode;
    showPoints: boolean;
    additionalProps?: {};
  };
};
// Split into two parts to insert demographic graphs in the middle.
const SHOWS_STORY_GRAPH_LIST_BEFORE = [
  StoryGraph.UNIQUE_VIEWERS,
  StoryGraph.SNAP_DROP_OFF,
  StoryGraph.TOPSNAP_TIME_SPENT,
];
const SHOWS_STORY_GRAPH_LIST_AFTER = [
  StoryGraph.ATTACHMENT_CONVERSION,
  StoryGraph.ATTACHMENT_TIME_SPENT,
  StoryGraph.SCREENSHOTS_SHARES,
];

// Equivalent of `item[key] || defaultValue` but only if item[key] is undefined, keeping other falsy values (e.g. 0).
function extractKeyOrDefault<T, K extends keyof T>(item: T | undefined, key: K, defaultValue: T[K]) {
  // @ts-ignore
  return item === undefined ? defaultValue : item[key];
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const primaryLanguage = publishersSelectors.getActivePublisherPrimaryLanguage(state);
  let metrics = getStorySnapMetrics(state)(primaryLanguage);

  // If chapters summary is provided we want to change the metrics display properties.
  const { chapters } = props;
  if (chapters !== undefined && chapters.length + 1 === metrics.length) {
    const subscribeSnapStartTimeMs = chapters.reduce((x, chapter) => x + chapter.durationMs, 0);
    metrics = metrics.map((metric, i) => ({
      ...metric,
      name: printDuration(extractKeyOrDefault(chapters[i], 'startTimeMs', subscribeSnapStartTimeMs)),
      runTime: Math.floor(extractKeyOrDefault(chapters[i], 'durationMs', 0) / 1000),
    }));
  }
  const metricsV2 = props.storySnapMetricsV2?.length ? props.storySnapMetricsV2 : [];
  return {
    storySnapMetrics: props.useAnalyticsV2 ? metricsV2 : metrics,
    storyDemographicMetrics: getStoryDemographicsMetrics(state)(primaryLanguage),
  };
};
type Props = OwnProps & StateProps;

export class AnalyticsStoryGraphs extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  isLoading24HourMetrics = () => {
    return !_.get(this.props, ['analytics', 'edition24Hours', this.props.analyticsStatsType, 'hourlyMetrics'], false);
  };

  isLoadingAnalytics = () => {
    return !_.get(this.props, 'analytics', false);
  };

  isLoadingStoryDailyMetrics = () => {
    return !_.get(this.props, ['analytics', 'editionDaily', this.props.analyticsStatsType, 'dailyMetrics'], false);
  };

  isLoadingStoryMetrics = () => {
    return !_.get(this.props, ['analytics', 'edition', this.props.analyticsStatsType, 'editionMetrics'], false);
  };

  graphConfigIntlFunc = (id: string) => {
    return intlMessages.getLocalisedMessageFromId(this.context, id, {});
  };

  renderPollTimelineGraphs() {
    if (this.props.isGlobalGeo) {
      /* eslint-disable react/no-array-index-key */
      const isLoading = this.props.useAnalyticsV2 ? this.props.isGraphDataLoading : this.isLoadingStoryMetrics();
      const data = this.props.useAnalyticsV2 ? this.props.storyMetricsV2 : this.props.analytics;
      return chartConfigs.EDITION_POLL_TIMELINE_GRAPHS.getPollTimelineGraphs(data).map((chart, chartIndex) => (
        <Row key={`row-${chartIndex}`} data-test="pollTimelineGraph">
          <Col xs={12}>
            <Graph
              data={(chart as any).dataFunc(data)}
              isLoading={isLoading}
              tooltipComponent={PollTimelineTooltip}
              {...chart.props}
            />
          </Col>
        </Row>
      ));
      /* eslint-enable react/no-array-index-key */
    }
    return null;
  }

  renderBasicPerSnapGraphsBeforeDemographics = () => {
    const isOnPromotionTab = this.props.analyticsStatsType === AnalyticsStatsType.PAID;
    return (
      <AnalyticsStoryBasicPerSnapGraphs
        isOnPromotionTab={isOnPromotionTab}
        storyGraphList={SHOWS_STORY_GRAPH_LIST_BEFORE}
        {...this.props}
      />
    );
  };

  renderBasicPerSnapGraphsAfterDemographics = () => {
    const isOnPromotionTab = this.props.analyticsStatsType === AnalyticsStatsType.PAID;
    return (
      <AnalyticsStoryBasicPerSnapGraphs
        isOnPromotionTab={isOnPromotionTab}
        storyGraphList={SHOWS_STORY_GRAPH_LIST_AFTER}
        {...this.props}
      />
    );
  };

  renderDemoGraphs = () => {
    const isOnPromotionTab = this.props.analyticsStatsType === AnalyticsStatsType.PAID;
    const isLoading = this.props.useAnalyticsV2 ? this.props.isGraphDataLoading : this.isLoadingStoryMetrics();

    if (this.props.useAnalyticsV2 && isOnPromotionTab) {
      return null;
    }

    return (
      <Row>
        <Col xs={9}>
          <AnalyticsDemographicsGraph
            metrics={this.props?.storyDemographicMetrics || []}
            metricsV2={this.props.storyMetricsV2!}
            isLoading={isLoading}
            isAnalyticsV2={this.props.useAnalyticsV2}
            isOnPromotionTab={isOnPromotionTab}
          />
        </Col>
        <Col xs={3}>
          <AnalyticsGenderGraph
            metrics={this.props?.storyDemographicMetrics || []}
            metricsV2={this.props.storyMetricsV2!}
            isAnalyticsV2={this.props.useAnalyticsV2}
            isLoading={isLoading}
            isOnPromotionTab={isOnPromotionTab}
          />
        </Col>
      </Row>
    );
  };

  renderQuizGraphs = () => {
    const quizzes = this.props.storySnapMetrics?.filter((snap: StorySnapMetrics) => snap.poll && snap.poll.outcomes);
    const isLoading = this.props.useAnalyticsV2 ? this.props.isGraphDataLoading : this.isLoadingStoryMetrics();
    return _.map(quizzes, (snap: StorySnapMetrics) => {
      return (
        <Row key={`quizquestion-${snap.name}`} data-test="quizQuestionGraph">
          <Col xs={12}>
            <AnalyticsMultiQuestionQuizGraph isLoading={isLoading} snap={snap} />
          </Col>
        </Row>
      );
    });
  };

  renderEditionTimeBasedGraphs(chart: ChartConfig) {
    const isLoading = this.props.isShowingSingleDayStats
      ? this.isLoading24HourMetrics()
      : this.isLoadingStoryDailyMetrics();
    const isEditionLoading = this.props.useAnalyticsV2 ? this.props.isGraphDataLoading : isLoading;
    return (
      <Row key={`edition-time-row-${chart.props.graphId}`}>
        <Col xs={12}>
          <Graph
            data={chart.dataFunc(this.props.analytics, { isSubscribable: this.props.isSubscribable })}
            snapPreviewFrames={this.props.snapPreviewFrames}
            isLoading={isEditionLoading}
            tooltipComponent={BasicTooltip}
            {...chart.props}
          />
        </Col>
      </Row>
    );
  }

  renderAllTimeGraphs() {
    return (
      <div>
        {this.renderBasicPerSnapGraphsBeforeDemographics()}
        {this.renderDemoGraphs()}
        {this.renderBasicPerSnapGraphsAfterDemographics()}
        {this.props.analyticsStatsType === AnalyticsStatsType.ALL && this.renderPollTimelineGraphs()}
        {this.renderQuizGraphs()}
      </div>
    );
  }

  render() {
    if (this.props.isShowingAllTimeStats) {
      return this.renderAllTimeGraphs();
    }
    if (this.props.isShowingSingleDayStats) {
      const singleDayChart = chartConfigs.getEditionTimeBasedGraphs(
        this.graphConfigIntlFunc,
        this.props.analyticsStatsType
      )[0];
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ dataFunc: (data: any, external... Remove this comment to see the full error message
      return this.renderEditionTimeBasedGraphs(singleDayChart);
    }
    const multiDayChart = chartConfigs.getEditionTimeBasedGraphs(
      this.graphConfigIntlFunc,
      this.props.analyticsStatsType
    )[1];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ dataFunc: (data: any, external... Remove this comment to see the full error message
    return this.renderEditionTimeBasedGraphs(multiDayChart);
  }
}

export default intlConnect(mapStateToProps, {})(AnalyticsStoryGraphs);
