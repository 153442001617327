import invariant from 'invariant';
import { noop } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isGeneratingStorySubtitles, isGeneratingSubtitles } from 'state/buildStatus/schema/buildStatusHelpers';
import { editionScheduleStatus, getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import {
  generateSubsForSnapAndReload,
  generateSubtitlesForEdition,
} from 'state/contentRecognition/actions/contentRecognitionActions';
import { getActiveUploadCountsForComponentIdByPurpose } from 'state/media/selectors/mediaSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { getActiveEditionId } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActivePublisherDefaultSubtitlesLanguage } from 'state/publishers/selectors/publishersSelectors';
import { getSubtitlesActiveLanguageCode } from 'state/subtitles/selectors/subtitlesSelectors';

import type { DropzoneTypeEnum, UploadPurpose } from 'config/constants';
import { magicWand } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { buildComponentIdForSnapId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { ModalType } from 'utils/modalConfig';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import { ExtractDispatchProps } from 'types/redux';

type ExternalProps = {
  hasSubtitles: boolean;
  purpose: UploadPurpose;
  dropzoneType: DropzoneTypeEnum;
  isReadOnly: boolean;
  snapId?: SnapId;
  'data-test'?: string;
  onGenerationFinished?: () => void;
};

type StateProps = {
  storyId: EditionID;
  activeSubtitlesLanguage: string;
  defaultSubtitlesLanguage: string;
  isWaitingForSubtitles: boolean;
  uploadingSubtitles: boolean;
};

type OwnState = {
  generatingSubtitles: boolean;
};

function mapStateToProps(state: State, props: ExternalProps): StateProps {
  const snapId = props.snapId;
  const componentId = snapId ? buildComponentIdForSnapId(snapId) : '';
  const uploadingSubtitles = Boolean(getActiveUploadCountsForComponentIdByPurpose(state)(componentId, props.purpose));
  const storyId = getActiveEditionId(state);
  invariant(storyId, 'storyId is not set');
  const isWaitingForStorySubtitles = isGeneratingStorySubtitles(editionScheduleStatus(state)(storyId));
  const isWaitingForSnapSubtitles = snapId ? isGeneratingSubtitles(getDiscoverSnapBuildStatus(state)(snapId)) : false;

  return {
    storyId,
    uploadingSubtitles,
    activeSubtitlesLanguage: getSubtitlesActiveLanguageCode(state),
    defaultSubtitlesLanguage: getActivePublisherDefaultSubtitlesLanguage(state),
    isWaitingForSubtitles: isWaitingForStorySubtitles || isWaitingForSnapSubtitles,
  };
}

const mapDispatchToProps = {
  generateSubtitlesForStory: generateSubtitlesForEdition,
  generateSubtitlesForSnap: generateSubsForSnapAndReload,
  showModal,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type OwnProps = ExternalProps & StateProps & DispatchProps;

type Props = OwnProps & typeof GenerateSubtitlesButton.defaultProps;

export class GenerateSubtitlesButton extends React.PureComponent<Props, OwnState> {
  mounted: boolean = false;

  static defaultProps = {
    onGenerationFinished: noop,
  };

  state = {
    generatingSubtitles: false,
  };

  componentDidMount(): void {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onDismissGenerateSubtitles = () => {};

  handleGenerateSubtitlesClick = () => {
    const { hasSubtitles, snapId } = this.props;
    if (hasSubtitles && !snapId) {
      const modalConfig: DialogModalOptions = {
        visible: true,
        isBodyCentered: true,
        onConfirm: this.doGenerateSubtitles,
        onCancel: this.onDismissGenerateSubtitles,
        body: (
          <FormattedMessage
            id="subtitles-confirm-replace-message"
            description="Message shown in alert modal asking if the user wants to replace existing subtitles"
            defaultMessage="Generating new subtitles will replace existing subtitles across your story."
          />
        ),
      };

      this.props.showModal(ModalType.DIALOG, 'SubtitlesGeneration', modalConfig);
    } else {
      this.doGenerateSubtitles();
    }
  };

  doGenerateSubtitles = () => {
    const {
      snapId,
      storyId,
      activeSubtitlesLanguage,

      generateSubtitlesForSnap,

      generateSubtitlesForStory,
      dropzoneType,
      purpose,
      defaultSubtitlesLanguage,
      onGenerationFinished,
    } = this.props;
    this.setState({ generatingSubtitles: true });

    const generatingSubtitlesPromise = snapId
      ? generateSubtitlesForSnap(storyId, activeSubtitlesLanguage, snapId)
      : generateSubtitlesForStory(storyId, activeSubtitlesLanguage);

    generatingSubtitlesPromise
      .then(() => {
        incrementCounter('subtitles.generate', {
          click: `${purpose}-${dropzoneType}`,
          language: defaultSubtitlesLanguage,
        });
      })
      .finally(() => {
        if (this.mounted) {
          this.setState({ generatingSubtitles: false });
        }
        (onGenerationFinished || noop)();
      });
  };

  render() {
    const isActiveLanguageDefault = this.props.activeSubtitlesLanguage === this.props.defaultSubtitlesLanguage;
    const isDisabled =
      this.props.isReadOnly ||
      this.props.uploadingSubtitles ||
      !isActiveLanguageDefault ||
      this.props.isWaitingForSubtitles ||
      this.state.generatingSubtitles;

    return (
      <SDSButton
        disabled={isDisabled}
        type={ButtonType.WHITE}
        loading={this.state.generatingSubtitles || this.props.isWaitingForSubtitles}
        inlineIcon={magicWand}
        onClick={this.handleGenerateSubtitlesClick}
        data-test={this.props['data-test']}
        block
      >
        <FormattedMessage
          id="subtitles-auto-sub-button-text"
          description="Text for auto subtitle generation button"
          defaultMessage="Auto-sub"
        />
      </SDSButton>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(GenerateSubtitlesButton);
