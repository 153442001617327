// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Row, Col, Breadcrumb } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import is from 'is_js';
import React from 'react';

import { push } from 'state/router/actions/routerActions';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { constructURL } from 'utils/linkUtils';
import { isRouteActive } from 'utils/router/routerUtils';
import { withRouter } from 'utils/routerUtils';

import style from './PageHeader.scss';

const mapStateToProps = (state: State) => ({
  entireState: state,
});
const mapDispatchToProps = {
  push,
};

type OwnPageHeaderProps = {
  match: any;
  location: any;
  history: any;
  breadcrumbs?: any[];
  push: (...args: any[]) => any;
  entireState?: any;
};

type PageHeaderProps = OwnPageHeaderProps & typeof PageHeader.defaultProps;

export class PageHeader extends React.Component<PageHeaderProps> {
  static defaultProps = {
    breadcrumbs: [],
  };

  getTitleFromRoute(crumb: any) {
    if (is.function(crumb.title)) {
      return crumb.title(this.props.match.params, this.props.entireState);
    }

    return crumb.title;
  }

  noopClick = (event: any) => {
    event.preventDefault();
  };

  clickCrumb = (event: any) => {
    event.preventDefault();
    const { currentTarget } = event;
    const path = currentTarget.getAttribute('data-path');
    this.props.push(path);
  };

  renderCrumb(crumb: any, index: any) {
    if (is.not.existy(crumb.path)) {
      return null;
    }

    const path = constructURL(crumb.path, { params: this.props.match.params });
    const href = this.props.history.createHref({ pathname: path });
    const active = isRouteActive(path, this.props.location.pathname);

    const onClick = active ? this.noopClick : this.clickCrumb;

    return (
      <Breadcrumb.Item
        href={href}
        data-path={path}
        key={index}
        active={active}
        onClick={onClick}
        data-test="PageHeader.breadcrumb.item"
      >
        {this.getTitleFromRoute(crumb)}
      </Breadcrumb.Item>
    );
  }

  render() {
    const { breadcrumbs } = this.props;
    return (
      <Row className={style.root}>
        <Col xs={12}>
          <h4>{this.props.children}</h4>
          {breadcrumbs && (
            <Breadcrumb className="pull-right">
              {breadcrumbs.map((crumb, index) => this.renderCrumb(crumb, index))}
            </Breadcrumb>
          )}
        </Col>
      </Row>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(PageHeader));
