import moment from 'moment-timezone';
import * as React from 'react';
import type { ReactNode } from 'react';

import { calendar, cross, chevronDown } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import DateRangePickerModal, {
  CalendarShortcut,
} from 'views/common/containers/DateRangePickerModal/DateRangePickerModal';

import style from './CalendarButton.scss';

type Props = {
  className?: string;
  shouldShowDropDownButton?: boolean;
  onUpdate: (b?: moment.Moment | null, a?: moment.Moment | null) => void;
  defaultText: string | ReactNode;
  from?: moment.Moment | null;
  to?: moment.Moment | null;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  shortcuts?: Set<CalendarShortcut>;
  maxDateRangeDuration?: number;
};

type State = {
  shouldShowDatePickerModal: boolean;
  from: moment.Moment | undefined | null;
  to: moment.Moment | undefined | null;
};

export default class CalendarButton extends React.Component<Props, State> {
  state: State = {
    shouldShowDatePickerModal: false,
    from: null,
    to: null,
  };

  UNSAFE_componentWillMount() {
    this.setUpdatedFromToState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setUpdatedFromToState(nextProps);
  }

  onClearClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.onDateRangeUpdated(null, null);
  };

  onDateRangeUpdated = (from?: moment.Moment | null, to?: moment.Moment | null) => {
    this.setState({ from, to });
    this.props.onUpdate(from, to);
  };

  setUpdatedFromToState = (props: Props) => {
    let { from, to } = props;
    if (from !== undefined && to !== undefined) {
      from = from && from.isValid() ? from : null;
      to = to && to.isValid() ? to : null;
      this.setState({ from, to });
    }
  };

  showDatePickerModal = () => {
    this.setState({ shouldShowDatePickerModal: true });
  };

  hideDatePickerModal = () => {
    this.setState({ shouldShowDatePickerModal: false });
  };

  renderDatePickerModal = () => {
    return (
      <DateRangePickerModal
        onHide={this.hideDatePickerModal}
        onUpdate={this.onDateRangeUpdated}
        from={this.state.from}
        to={this.state.to}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        shouldShowClearButton
        shortcuts={this.props.shortcuts}
        maxDateRangeDuration={this.props.maxDateRangeDuration}
      />
    );
  };

  render() {
    const { from, to } = this.state;
    const { shouldShowDropDownButton, className, defaultText } = this.props;
    const hasDate = from && to;
    const fromStringWithYear = from ? from.format('MM/DD/YYYY') : '';
    const toStringWithYear = to ? to.format('MM/DD/YYYY') : '';
    const fromStringWithoutYear = from ? from.format('MM/DD') : '';
    const toStringWithoutYear = to ? to.format('MM/DD') : '';
    let buttonText;
    // note that the calendar pickers with a dropdown display a shorter date, without the year
    if (hasDate && !shouldShowDropDownButton) {
      buttonText =
        fromStringWithYear === toStringWithYear ? fromStringWithYear : `${fromStringWithYear} - ${toStringWithYear}`;
    } else if (hasDate && shouldShowDropDownButton) {
      buttonText =
        fromStringWithoutYear === toStringWithoutYear
          ? fromStringWithoutYear
          : `${fromStringWithoutYear} - ${toStringWithoutYear}`;
    } else {
      buttonText = defaultText;
    }

    return (
      <div className={className} data-test="calendarButton">
        <SDSButton
          type={ButtonType.SECONDARY}
          inlineIcon={calendar}
          onClick={this.showDatePickerModal}
          data-test="calendarButton.button"
        >
          {buttonText}
          {hasDate && !shouldShowDropDownButton && (
            <Icon inlineIcon={cross} className={style.closeIcon} onClick={this.onClearClick} />
          )}
          {shouldShowDropDownButton && <Icon inlineIcon={chevronDown} className={style.closeIcon} />}
        </SDSButton>
        {this.state.shouldShowDatePickerModal ? this.renderDatePickerModal() : null}
      </div>
    );
  }
}
