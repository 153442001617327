import React from 'react';
import { FormattedMessage } from 'react-intl';

import SDSTabs from 'views/common/components/SDSTabs/SDSTabs';

import style from './HomepageTabs.scss';

export enum HomepageTabsEnum {
  STORIES = 0,
  SPOTLIGHTS = 1,
}

export const TabsConfig = {
  [HomepageTabsEnum.STORIES]: {
    text: (
      <FormattedMessage
        defaultMessage="Stories"
        id="homepage-tab-stories"
        description="Homepage tab item for stories"
      />
    ),
    tabKey: 'stories',
  },
  [HomepageTabsEnum.SPOTLIGHTS]: {
    text: (
      <FormattedMessage
        defaultMessage="Spotlight"
        id="homepage-tab-spotlights"
        description="Homepage tab item for spotlights"
      />
    ),
    tabKey: 'spotlights',
  },
};

type Props = {
  onTabChange: (selectedTabIndex: number) => void;
  selectedTabIndex: number;
};

export default function HomepageTabs({ onTabChange, selectedTabIndex }: Props) {
  return (
    <div className={style.tabsContainer}>
      <SDSTabs selectedTab={selectedTabIndex} handleTabSelected={onTabChange} tabs={Object.values(TabsConfig)} />
    </div>
  );
}
