import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import reactCSS from 'reactcss';
import InlineSVG from 'svg-inline-react';

import * as userSelectors from 'state/user/selectors/userSelectors';

import { CrossOrigin, DropzoneType, ImageFileExtension, UploadFormat, UploadPurpose } from 'config/constants';
import { trash } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as colorUtils from 'utils/media/colorUtils';
import { MediaOutputDimensions, MediaOutputFormat } from 'utils/media/mediaConfig';
import { getValidationOptions, hasValidationOptions } from 'utils/media/mediaValidationConfig';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { Spinner, SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/squarePreviewOverlay.svg... Remove this comment to see the full error message
import squarePreviewOverlay from 'icons/squarePreviewOverlay.svg.inline';

import style from './PublisherDetailImageRow.scss';

import type { MediaValidation } from 'types/media';
import type { PublisherID } from 'types/publishers';
import type { State as RootState } from 'types/rootState';

type StateProps = {
  publisherId: PublisherID;
};
type OwnProps = {
  title: string | ReactNode;
  hideMediaUploader?: boolean;
  uploadType: UploadPurpose;
  onChange: (a: string) => void;
  onDelete: () => void;
  uploadValidation?: MediaValidation;
  className?: string;
  defaultValue?: string;
  isHorizontalLayout?: boolean;
  children?: ReactNode;
  color?: string;
  disabled?: boolean;
  imageClassName?: string;
  tooltipMessage?: string | ReactNode;
  dropzoneType?: string;
  isLoading?: boolean;
};
type State = {
  blob: string | undefined | null;
};
const mapStateToProps = (state: RootState): StateProps => {
  return {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    publisherId: userSelectors.getActivePublisherId(state),
  };
};
type Props = (OwnProps & typeof PublisherDetailImageRow.defaultProps) & StateProps;
export class PublisherDetailImageRow extends React.PureComponent<Props, State> {
  static defaultProps = {
    dropzoneType: DropzoneType.REPLACE_TILE_LOGO,
  };

  state = {
    blob: null,
  };

  UNSAFE_componentWillMount() {
    if (this.props.defaultValue) {
      this.setState({ blob: this.props.defaultValue });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({ blob: nextProps.defaultValue });
    }
  }

  getValidation(): MediaValidation {
    if (this.props.uploadValidation) {
      return this.props.uploadValidation;
    }
    if (hasValidationOptions(this.props.uploadType)) {
      return getValidationOptions(this.props.uploadType);
    }
    return {};
  }

  uploadBlob = (blob: string) => {
    this.setState({ blob });
    this.props.onChange(blob);
  };

  isTileLogoWithGradient = () => {
    return this.props.uploadType === UploadPurpose.TILE_LOGO && this.props.color;
  };

  renderLoadingSpinner = () => {
    return <Spinner loading message={SpinnerLabels.UPLOADING} size={SpinnerSizes.MEDIUM} />;
  };

  renderOverlayImage() {
    if (this.props.uploadType === UploadPurpose.SQUARE_ICON) {
      return <InlineSVG className={style.foregroundImage} src={squarePreviewOverlay} />;
    }
    return null;
  }

  renderBackgroundImage() {
    const classes = classNames(
      {
        [style.backgroundImage]: true,
        [style.iconBackground]: this.props.uploadType === UploadPurpose.SQUARE_ICON,
        [style.tileBackground]: this.props.uploadType === UploadPurpose.TILE_IMAGE,
        [style.roundedBorder]: !!this.props.color,
      },
      this.props.imageClassName
    );
    return <div className={classes} />;
  }

  renderGradientBackground() {
    if (!this.props.color) {
      return null;
    }
    const colorRGBA = colorUtils.hexToRgbaStyle(this.props.color || '', 0.5);
    const colorClear = colorUtils.hexToRgbaStyle(this.props.color || '', 0.1);
    const gradientStyle = reactCSS({
      default: {
        overlay: {
          backgroundImage: `linear-gradient(to bottom, ${colorRGBA} 0%, ${colorClear} 100%)`,
        },
      },
    });
    return <div className={style.gradientOverlay} style={gradientStyle.overlay} />;
  }

  renderImageDetails() {
    const imageDimensions = MediaOutputDimensions[this.props.uploadType]
      ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        `${MediaOutputDimensions[this.props.uploadType].width} x ${
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          MediaOutputDimensions[this.props.uploadType].height
        }px `
      : '';
    const imageType = MediaOutputFormat[this.props.uploadType] ? MediaOutputFormat[this.props.uploadType] : 'PNG';
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const fileExtension = ImageFileExtension[imageType];
    return (
      <div className={style.imageDetails}>
        <span>{this.props.title}</span>
        <span>{`${imageDimensions}${fileExtension}`}</span>
      </div>
    );
  }

  renderLogoPreview() {
    if (this.state.blob) {
      const classes = classNames(style.icon, { [style.smallIcon]: this.isTileLogoWithGradient() });
      return (
        <img
          className={classes}
          src={this.state.blob!}
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          alt="Uploaded logo"
          data-test="publisherDetailImagerow.squarelogoImage"
        />
      );
    }
    return null;
  }

  renderImagePreview = () => {
    return (
      <div
        className={classNames(style.image, this.props.imageClassName, {
          [style.tileImage]: this.props.uploadType === UploadPurpose.TILE_IMAGE,
        })}
        data-test="publisherDetailImagerow.imagePreview"
      >
        {this.props.isLoading ? (
          this.renderLoadingSpinner()
        ) : (
          <>
            {this.renderOverlayImage()}
            {this.renderLogoPreview()}
            {this.renderGradientBackground()}
            {this.renderBackgroundImage()}
          </>
        )}
      </div>
    );
  };

  renderImagePreviewWithTooltip = () => {
    if (!this.props.tooltipMessage) {
      return this.renderImagePreview();
    }
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={this.props.tooltipMessage}
        id="publisher-detail-image-preview-text"
      >
        {this.renderImagePreview()}
      </SDSTooltip>
    );
  };

  render() {
    const classes = classNames(style.parent, this.props.className, {
      [style.horizontal]: this.props.isHorizontalLayout,
    });
    return (
      <div className={classes}>
        {this.renderImagePreviewWithTooltip()}
        {this.props.children ? <div className={style.additionalStyling}>{this.props.children}</div> : null}
        <div className={style.imageRow}>
          <div data-test="onboarding.publisherDetailImageRow.imageDetails" className={(style as any).imageRowDetails}>
            {this.renderImageDetails()}
          </div>
          <div className={style.buttonsContainer}>
            {!this.props.hideMediaUploader && (
              <MediaUploader
                publisherId={this.props.publisherId}
                uploadFormat={UploadFormat.ONE_FILE}
                dropzoneType={this.props.dropzoneType}
                purpose={this.props.uploadType}
                handleBlobOnly={this.uploadBlob}
                customValidationOptions={this.getValidation()}
                enabled={!this.props.disabled}
                data-test="publisherDetailImagerow.uploadImage"
              >
                <SDSButton
                  type={ButtonType.WHITE}
                  disabled={this.props.disabled}
                  data-test="onboarding.publisherDetail.imageRow.replace.button"
                >
                  {this.state.blob ? getMessageFromId('replace-button-label') : getMessageFromId('upload-button-label')}
                </SDSButton>
              </MediaUploader>
            )}
            {this.props.onDelete && (
              <div className={style.deleteButtonContainer}>
                <SDSButton
                  type={ButtonType.WHITE}
                  shape={ButtonShape.CIRCLE}
                  inlineIcon={trash}
                  onClick={this.props.onDelete}
                  disabled={this.props.disabled}
                  data-test="onboarding.publisherDetail.imageRow.delete.button"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, null)(PublisherDetailImageRow);
