import _ from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';
import { intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsAreaChart from 'views/analytics/components/AnalyticsAreaChart/AnalyticsAreaChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE } from 'views/analytics/utils/chartConfigs';

const PRIMARY_METRIC_COLOR = GRAPH_SHADES_OF_BLUE.NORMAL_BLUE;

type Props = {
  isLoading: boolean;
  graphTooltip?: ReactNode;
  data: any;
  property: string;
  descriptionMessageId: string;
  valueFormatter: (num: number) => string;
};

export class AnalyticsSingleAreaChart extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getConfig = () => {
    return this.props.data.map((entry: any) => {
      const uv = entry[this.props.property];

      return {
        name: entry.date,
        uv,
      };
    });
  };

  getArea = () => {
    return [
      {
        name: getLocalisedMessageFromId(this.context, this.props.descriptionMessageId),
        dataKey: 'uv',
        key: this.props.property,
        fill: PRIMARY_METRIC_COLOR,
      },
    ];
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, uv } = payload;
    const metricName = getLocalisedMessageFromId(this.context, this.props.descriptionMessageId);
    const metricRows = [{ metricName, metricValue: this.props.valueFormatter(uv) }];

    return <AnalyticsTooltip title={numberFormatter.showAsDateAndTime(name)} metricRows={metricRows} />;
  };

  render() {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <AnalyticsAreaChart
        title={getMessageFromId(this.props.descriptionMessageId)}
        data={this.getConfig()}
        tooltip={this.props.graphTooltip}
        areas={this.getArea()}
        isLoading={this.props.isLoading}
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={this.props.valueFormatter}
        xAxisTickFormatter={numberFormatter.showAsDateAndTime}
      />
    );
  }
}

export default AnalyticsSingleAreaChart;
