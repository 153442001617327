import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

import { FeaturesMap, UIFeature } from '../featuresAdminState';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'config/constants';
import { State } from 'src/types/rootState';

function getFeatures(state: State) {
  return state.features || EMPTY_OBJECT;
}

export const getFeaturesList = createKeySelector<Array<UIFeature>>(getFeatures, 'list', EMPTY_ARRAY);

export const getAllFeaturesMap = createKeySelector<FeaturesMap>(getFeatures, 'byId', EMPTY_OBJECT);

export const getAllFeaturesList = s(getAllFeaturesMap, featuresMap => Object.values(featuresMap));

export const getFeaturesMap = s(getFeaturesList, featuresList => {
  return featuresList.reduce((acc: any, feature: any) => {
    return { ...acc, [feature.id]: feature };
  }, {});
});

export const isRequestInProgress = s(
  createKeySelector(getFeatures, 'requestsCount', 0),
  requestsCount => requestsCount > 0
);
