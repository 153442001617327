import { gql } from '@apollo/client';

export const ARCHIVE_PUBLISHER = gql`
  mutation archivePublisher($hostUserId: String!) {
    archivePublisher(hostUserId: $hostUserId) {
      __typename
      ... on ArchivePublisherSuccess {
        archivedHostUserId
      }
      ... on ArchivePublisherError {
        message
      }
    }
  }
`;

type ArchivePublisherSuccess = {
  archivedHostUserId: string;
};

type ArchivePublisherError = {
  message: string;
};

export type ArchivePublisherResponse = ArchivePublisherSuccess | ArchivePublisherError;
