import moment from 'moment/moment';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { getActiveCreatorHostUserId } from 'state/user/selectors/userSelectors';

import { useGetSavedStoriesAnalytics } from 'gql/hooks/useGetSavedStoriesAnalytics';

import { EditionCountry, getEnumKey } from 'views/analytics/components/AnalyticsEditionHeader/EditionCountry';
import { AnalyticsHeader } from 'views/analytics/components/AnalyticsHeader/AnalyticsHeader';
import { AnalyticMetric } from 'views/analytics/components/ProfileKPI/ProfileKPI';
import { StoriesKPIs } from 'views/analytics/components/StoriesKPIs/StoriesKPIs';
import CheetahAnalyticsStoriesView from 'views/analytics/containers/CheetahAnalyticsStoriesView/CheetahAnalyticsStoriesView';
import { AnalyticsReportRange } from 'views/analytics/utils/kpiConfigs';
import { getDatesFromSearchParams, updateDatesInSearchParams } from 'views/analytics/utils/searchParamsUtil';

import style from './AnalyticsOverviewV2.scss';

import { SavedStoryAnalyticsResult } from 'types/analytics';

export default function AnalyticsOverviewV2() {
  const history = useHistory();
  const location = useLocation();
  const [activeMetric, setActiveMetric] = useState<AnalyticMetric>('uniqueViewers');
  const [reportRange] = useState<AnalyticsReportRange>(AnalyticsReportRange.WEEK);
  const hostUserId = useSelector(getActiveCreatorHostUserId) || '';
  const initialFrom = getDatesFromSearchParams(location).from || moment().subtract(7, 'days');
  const initialTo = getDatesFromSearchParams(location).to || moment();
  const [startDate, setStartDate] = useState<moment.Moment>(initialFrom);
  const [endDate, setEndDate] = useState<moment.Moment>(initialTo);
  const [country, setCountry] = useState(EditionCountry.ALL);

  function getCountryKey(currentCountry: EditionCountry) {
    return currentCountry === EditionCountry.ALL ? getEnumKey(currentCountry) : currentCountry;
  }

  const { data, loading } = useGetSavedStoriesAnalytics(
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD'),
    hostUserId,
    getCountryKey(country)
  );

  const renderProfileKPIsSection = () => {
    return (
      <StoriesKPIs
        activeMetric={activeMetric}
        reportRange={reportRange}
        setActiveMetric={setActiveMetric}
        isLoading={loading}
        storiesAnalyticsSummary={data?.savedStoryAnalytics?.savedStoryAnalytics?.savedStoryKPIs}
      />
    );
  };

  const onReportRangeChange = useCallback(
    async (start?: moment.Moment | null, end?: moment.Moment | null) => {
      if (start && end) {
        setStartDate(start);
        setEndDate(end);
        updateDatesInSearchParams(history, start, end);
      }
    },
    [history]
  );

  function toCheetahAnalyticsStories(storyAnalytics: SavedStoryAnalyticsResult) {
    const savedStoryAnalytics = storyAnalytics?.savedStoryItemAnalytics;
    const savedStoryMetadata = storyAnalytics?.savedStoryMetadata;
    if (savedStoryMetadata?.snapCount === 0) {
      return null;
    }
    return {
      metadata: {
        editionId: storyAnalytics.storyId,
        headline: storyAnalytics?.savedStoryMetadata?.storyName,
        storySnapsCount: storyAnalytics?.savedStoryMetadata?.snapCount,
        tileImageURL: storyAnalytics?.savedStoryMetadata?.thumbnailUrl,
        postTime: moment(+storyAnalytics?.savedStoryMetadata?.firstLiveDate),
      },
      stats: {
        uniqueDau: savedStoryAnalytics?.uniqueViewers,
        avgTimeViewed: savedStoryAnalytics?.timeSpentMs,
      },
    };
  }

  const renderStoriesSection = () => {
    const storiesAnalytics = data?.savedStoryAnalytics?.savedStoryAnalytics.savedStoryInsights
      ?.map(storyAnalytics => toCheetahAnalyticsStories(storyAnalytics!))
      .filter(analytics => analytics !== null)
      .sort((a, b) => b!.metadata.postTime.diff(a!.metadata.postTime));

    return (
      <CheetahAnalyticsStoriesView
        storiesV2={storiesAnalytics || []}
        isStoriesV2Loading={loading}
        isLoadingStories={false}
        isAnalyticsV2Enabled
      />
    );
  };

  return (
    <div className={style.root}>
      <AnalyticsHeader
        from={startDate}
        onDateRangeUpdated={onReportRangeChange}
        to={endDate}
        allowCountryFilter
        country={country}
        setCountry={setCountry}
      />
      {renderProfileKPIsSection()}
      <div className={style.storiesTable}>{renderStoriesSection()}</div>
    </div>
  );
}
