import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getActiveCreator, getUserInfo } from 'state/user/selectors/userSelectors';
import { User } from 'state/user/userState';

import {
  PostingTarget,
  PostToStorySnapResult,
  POST_STORY_SNAP,
  Media,
  StoryType,
  MediaType,
  PostStorySnapMethodParams,
  SnapData,
} from '../queries/spotlight/postStorySnap';

import { DEFAULT_LOCALE } from 'constants/locale';
import { VideoInfo } from 'utils/media/mediaValidation';

import { Creator } from 'types/creator';
import { BoltContentReference } from 'types/media';

const makePostingTarget = (creator: Creator, loggedInUser: User, storyType: StoryType): PostingTarget => {
  // If the logged-in user is the same as the creator host user
  //   - do not set the businessProfileId as they are posting as themselves
  //   - set the host user id instead
  //   - note that we don't need to set the host user id if posting via member roles
  return {
    storyType,
    businessProfileId: creator.businessProfileId,
  };
};

const makeTopics = (description?: string): string[] => {
  return description
    ? description
        .split(' ') // split the string into an array of words
        .filter(word => word.startsWith('#')) // filter out any word that doesn't start with #
        .map(topic => topic.substring(1)) // remove the # character from each remaining word
    : [];
};

const makeMedia = (
  videoMetadata: VideoInfo,
  boltContentReference: BoltContentReference,
  description?: string
): Media => {
  return {
    height: videoMetadata.height,
    width: videoMetadata.width,
    durationMs: Math.ceil(videoMetadata.duration),
    hasSound: Boolean(videoMetadata.hasAudio),

    ivBase64: boltContentReference.ivBase64 || '',
    keyBase64: boltContentReference.keyBase64 || '',
    contentObjectBase64: boltContentReference.contentObjectBase64,

    topics: makeTopics(description),
    description: description || '',

    mediaCaptureTimestampMs: 0, // TODO(mmeroi): Without web assembly we don't have this field availible - we could consider replacing with 'now'
    mediaType: MediaType.VIDEO,
  };
};
export function usePostStorySnap(storyType: StoryType): PostToStorySnapResult {
  const activeCreator = useSelector(getActiveCreator);
  const loggedInUser = useSelector(getUserInfo);
  const [postStorySnap] = useMutation<SnapData>(POST_STORY_SNAP);

  const post = useCallback(
    async ({
      saveToProfile,
      videoMetadata,
      boltContentReference,
      description,
      goLiveTsMs,
      disableRemix,
    }: PostStorySnapMethodParams) => {
      if (!activeCreator || !videoMetadata || !boltContentReference || !loggedInUser) {
        throw new Error('No publisher is selected');
      }
      const postingTarget = makePostingTarget(activeCreator, loggedInUser, storyType);
      const media = makeMedia(videoMetadata, boltContentReference, description);
      await postStorySnap({
        variables: {
          input: {
            media: [media],
            saveToProfile,
            locale: DEFAULT_LOCALE,
            postingTargets: [postingTarget],
            goLiveTsMs,
            disableRemix,
          },
        },
      });
    },
    [activeCreator, loggedInUser, postStorySnap, storyType]
  );

  return { post };
}
