import invariant from 'invariant';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isGeneratingStorySubtitles, isGeneratingSubtitles } from 'state/buildStatus/schema/buildStatusHelpers';
import { editionScheduleStatus, getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { setEditionPropertiesAndSave } from 'state/editions/actions/editionsActions';
import * as mediaActions from 'state/media/actions/mediaActions';
import { getActiveUploadCountsForComponentIdByPurpose } from 'state/media/selectors/mediaSelectors';
import { getActiveEditionId } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getSubtitlesActiveLanguageCode } from 'state/subtitles/selectors/subtitlesSelectors';

import { UploadPurpose } from 'config/constants';
import { plus } from 'icons/SDS/allIcons';
import { buildComponentIdForSnapId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import { DisplayRange } from 'views/common/components/SubtitlesPreview/components/SubtitlesBody/SubtitlesBody';
import {
  createSubtitleFile,
  EMPTY_SUBTITLE_FRAGMENT,
  SubtitleFragment,
} from 'views/common/components/SubtitlesPreview/subtitleParser';
import { updateSubtitlesState } from 'views/subtitles/state/actions/subtitlesEditorActions';

import { AssetID, createMediaID } from 'types/assets';
import { SnapId } from 'types/common';
import { EditionID } from 'types/editionID';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';

type ExternalProps = {
  snapId?: SnapId;
  videoAssetId: AssetID;
  isReadOnly: boolean;
  purpose: UploadPurpose;
  displayRange?: DisplayRange;
};

type StateProps = {
  storyId: EditionID;
  activeSubtitlesLanguage: string;
  isWaitingForSubtitles: boolean;
  uploadingSubtitles: boolean;
};

type OwnState = {
  creatingNewSubtitles: boolean;
};

export function getEmptySubtitleFragment(displayRange?: DisplayRange | null): SubtitleFragment {
  if (!displayRange) {
    return EMPTY_SUBTITLE_FRAGMENT;
  }

  return {
    ...EMPTY_SUBTITLE_FRAGMENT,
    timestamp: {
      startTimeMs: displayRange.startTimeMs,
      endTimeMs: displayRange.startTimeMs,
    },
  };
}

function mapStateToProps(state: State, props: ExternalProps): StateProps {
  const storyId = getActiveEditionId(state);
  invariant(storyId, 'storyId is not set');
  const snapId = props.snapId;
  const componentId = snapId ? buildComponentIdForSnapId(snapId) : '';
  const uploadingSubtitles = Boolean(getActiveUploadCountsForComponentIdByPurpose(state)(componentId, props.purpose));
  const isWaitingForStorySubtitles = isGeneratingStorySubtitles(editionScheduleStatus(state)(storyId));
  const isWaitingForSnapSubtitles = snapId ? isGeneratingSubtitles(getDiscoverSnapBuildStatus(state)(snapId)) : false;
  return {
    storyId,
    activeSubtitlesLanguage: getSubtitlesActiveLanguageCode(state),
    isWaitingForSubtitles: isWaitingForStorySubtitles || isWaitingForSnapSubtitles,
    uploadingSubtitles,
  };
}

const mapDispatchToProps = {
  saveSubtitles: mediaActions.uploadMediaAndFinalize,
  updateSubtitlesState,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;

export class NewSubtitlesButton extends React.PureComponent<Props, OwnState> {
  mounted: boolean = false;

  state = {
    creatingNewSubtitles: false,
  };

  componentDidMount(): void {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubtitlesSave = () => {
    const subtitlesFile = createSubtitleFile([]);
    const newSubtitleFile = new Blob([subtitlesFile], { type: 'text/vtt' });

    Promise.resolve(this.setState({ creatingNewSubtitles: true }))
      .then(() => {
        return this.props
          .saveSubtitles({
            media: newSubtitleFile,
            fileType: 'text',

            params: {
              assetId: this.props.videoAssetId,
              editionId: this.props.storyId,
              snapId: this.props.snapId,
              subtitlesLanguage: this.props.activeSubtitlesLanguage,
              purpose: this.props.purpose,
              componentId: '',
            },
            setEditionPropertiesAndSave,
          })
          .then(response => {
            const newSubtitlesEditorState = {
              isEditing: true,
              subtitleFragments: [getEmptySubtitleFragment(this.props.displayRange)],
            };
            grapheneUtils.incrementCounter('subtitles', { click: 'add_fragment' });
            return this.props.updateSubtitlesState(createMediaID(response.assetId), newSubtitlesEditorState);
          });
      })
      .finally(() => {
        if (this.mounted) {
          this.setState({ creatingNewSubtitles: false });
        }
      });
  };

  render() {
    const isDisabled =
      this.props.isReadOnly ||
      this.state.creatingNewSubtitles ||
      this.props.uploadingSubtitles ||
      this.props.isWaitingForSubtitles;

    return (
      <SDSButton
        disabled={isDisabled}
        type={ButtonType.WHITE}
        loading={this.state.creatingNewSubtitles}
        inlineIcon={plus}
        onClick={this.handleSubtitlesSave}
        metricName="subtitles"
        metricDimensions={{ click: 'new', language: this.props.activeSubtitlesLanguage }}
        block
      >
        <FormattedMessage
          id="subtitles-new-sub-button-text"
          description="Text for new subtitle generation button"
          defaultMessage="New"
        />
      </SDSButton>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(NewSubtitlesButton);
