import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import * as routerActions from 'state/router/actions/routerActions';
import { getActivePublisherId, getActiveCreator } from 'state/user/selectors/userSelectors';

import { gear } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/download.svg.inline' or ... Remove this comment to see the full error message
import downloadIcon from 'icons/download.svg.inline';

import style from './AnalyticsInsightsSettingsButton.scss';

import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';

type OwnProps = {
  className?: string;
};

type StateProps = {
  publisherId: number | null | undefined;
};

type Props = OwnProps & DispatchProps & StateProps;

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-redirect-to-report"
      description="Text on button redirecting to the insights page report"
      defaultMessage="View Insights Report"
    />
  ),
  params: [],
});

export const mapStateToProps = (state: RootState): StateProps => {
  const publisherId = getActivePublisherId(state) || getActiveCreator(state)?.publisherId;

  return {
    publisherId,
  };
};

const mapDispatchToProps = {
  goToInsightsAnalyticsReport: routerActions.goToInsightsAnalyticsReport,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

export class AnalyticsInsightsSettingsButton extends React.Component<Props> {
  redirectToPublisherInsightsReport = () => {
    // TODO(csteininger|PUB-14343): Clean up publisher router actions after project roll-out
    return this.props.goToInsightsAnalyticsReport({ publisherId: this.props.publisherId });
  };

  renderTooltipTitle = () => {
    return (
      <div
        data-test="analytics.analyticsInsightsSettings.button.tooltip.title"
        onClick={this.redirectToPublisherInsightsReport}
        className={style.menuItem}
      >
        <InlineSVG src={downloadIcon} className={style.downloadIcon} />{' '}
        {getMessageFromId('analytics-insights-redirect-to-report')}
      </div>
    );
  };

  render() {
    return (
      <div className={style.settingsButtonContainer}>
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.BOTTOM_RIGHT}
          title={this.renderTooltipTitle()}
          trigger="click"
          id="analytics-tooltip"
        >
          <SDSButton
            type={ButtonType.SECONDARY}
            shape={ButtonShape.CIRCLE}
            inlineIcon={gear}
            data-test="analyticsInsights.settings.button"
          />
        </SDSTooltip>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsInsightsSettingsButton);
