import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import { getActiveCreatorHostUserId, getActivePublisherId } from 'state/user/selectors/userSelectors';

import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounter } from 'utils/grapheneUtils';

import { EditionCountry, getExportCountry } from 'views/analytics/components/AnalyticsEditionHeader/EditionCountry';
import { exportEditionAnalyticsCSV } from 'views/analytics/utils/exportCSV';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

type Props = {
  from: moment.Moment | null;
  to: moment.Moment | null;
  country: EditionCountry;
  editionId?: number;
  setIsExporting: (isExporting: boolean) => void;
};
export function AnalyticsEditionCSVButton({ from, to, country, setIsExporting, editionId }: Props) {
  const publisherId = useSelector(getActivePublisherId);
  const hostUserId = useSelector(getActiveCreatorHostUserId);
  const authType = useSelector(getAuthType);
  const token = useSelector(getToken);

  const displayEditionCSVExportModal = useCallback(async () => {
    if (publisherId && hostUserId && authType && token) {
      incrementCounter(GrafanaMetrics.STORY_ANALYTICS, {
        type: 'downloadCSV',
      });
      await exportEditionAnalyticsCSV({
        bundleId: editionId!.toString(),
        publisherId,
        hostUserId,
        country: getExportCountry(country),
        token,
        authType,
      }).finally(() => {
        setIsExporting(false);
      });
    }
  }, [authType, country, editionId, hostUserId, publisherId, setIsExporting, token]);

  return (
    <SDSButton type={ButtonType.PRIMARY} onClick={displayEditionCSVExportModal}>
      {
        <FormattedMessage
          id="edition-csv-export-button"
          description="Label for edition csv export button"
          defaultMessage="Export"
        />
      }
    </SDSButton>
  );
}
