// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Row, Col } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import _ from 'lodash';
import React from 'react';

import type { AnalyticsState } from 'state/analytics/analyticsState';

import { StoryGraph } from 'config/constants';
import type { StoryGraphEnum } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';

import AnalyticsAttachmentConversionGraph from 'views/analytics/components/AnalyticsAttachmentConversionGraph/AnalyticsAttachmentConversionGraph';
import AnalyticsAttachmentTimeSpentGraph from 'views/analytics/components/AnalyticsAttachmentTimeSpentGraph/AnalyticsAttachmentTimeSpentGraph';
import AnalyticsScreenshotsSharesGraph from 'views/analytics/components/AnalyticsScreenshotsSharesGraph/AnalyticsScreenshotsSharesGraph';
import AnalyticsSnapDropOffGraph from 'views/analytics/components/AnalyticsSnapDropOffGraph/AnalyticsSnapDropOffGraph';
import AnalyticsTopsnapTimeSpentGraph from 'views/analytics/components/AnalyticsTopsnapTimeSpentGraph/AnalyticsTopsnapTimeSpentGraph';
import AnalyticsUniqueViewersGraph from 'views/analytics/components/AnalyticsUniqueViewersGraph/AnalyticsUniqueViewersGraph';

import type { StorySnapMetrics } from 'types/analytics';

const STORY_GRAPHS = {
  [StoryGraph.ATTACHMENT_CONVERSION]: {
    component: AnalyticsAttachmentConversionGraph,
    graphId: 'attachment-conversion',
  },
  [StoryGraph.ATTACHMENT_TIME_SPENT]: {
    component: AnalyticsAttachmentTimeSpentGraph,
    graphId: 'attachment-time-spent',
  },
  [StoryGraph.SCREENSHOTS_SHARES]: {
    component: AnalyticsScreenshotsSharesGraph,
    graphId: 'screenshots-shares',
  },
  [StoryGraph.SNAP_DROP_OFF]: {
    component: AnalyticsSnapDropOffGraph,
    graphId: 'chart-exit-rate',
  },
  [StoryGraph.TOPSNAP_TIME_SPENT]: {
    component: AnalyticsTopsnapTimeSpentGraph,
    graphId: 'topsnap-time-spent',
  },
  [StoryGraph.UNIQUE_VIEWERS]: {
    component: AnalyticsUniqueViewersGraph,
    graphId: 'unique-viewers',
  },
};

type Props = {
  analytics: AnalyticsState;
  isSubscribable: boolean;
  snapPreviewFrames: any;
  storyGraphSnapFilter?: (a: StorySnapMetrics) => boolean;
  storyGraphList: StoryGraphEnum[];
  storySnapMetrics: StorySnapMetrics[];
  formattedSnapMetrics: StorySnapMetrics[];
  isOnPromotionTab: boolean;
  isGraphDataLoading: boolean;
  useAnalyticsV2: boolean;
};

export class AnalyticsStoryBasicPerSnapGraphs extends React.PureComponent<Props> {
  isLoadingSnaps = () => {
    return !_.get(this.props, 'analytics.snaps', false);
  };

  render() {
    let metrics = this.props.useAnalyticsV2 ? this.props.formattedSnapMetrics : this.props.storySnapMetrics;
    if (this.props.storyGraphSnapFilter) {
      metrics = metrics?.filter(this.props.storyGraphSnapFilter);
    }

    return _.map(this.props.storyGraphList, (graphType: StoryGraphEnum) => {
      const graphConfig = STORY_GRAPHS[graphType];
      const GraphComponent = graphConfig?.component;

      return (
        <Row key={`edition-snap-row-${graphConfig?.graphId}`}>
          <Col xs={12}>
            {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'GraphComponent' does not have an... Remove this comment to see the full error message */}
            <GraphComponent
              metrics={metrics}
              isLoading={this.props.useAnalyticsV2 ? this.props.isGraphDataLoading : this.isLoadingSnaps()}
              isSubscribable={this.props.isSubscribable}
              isOnPromotionTab={this.props.isOnPromotionTab}
              useAnalyticsV2={this.props.useAnalyticsV2}
            />
          </Col>
        </Row>
      );
    });
  }
}

export default intlConnect(null, {})(AnalyticsStoryBasicPerSnapGraphs);
