import { gql } from '@apollo/client';

export const GET_UPLOAD_URL = gql`
  query GetUploadUrl($input: UploadUrlRequest!) {
    uploadUrl(input: $input) {
      uploadUrl
      contentObjectBase64
      contentUrl
      urlHeaders {
        key
        value
      }
    }
  }
`;

export interface UrlHeader {
  key: string;
  value: string;
}

export interface UploadUrlData {
  uploadUrl: {
    uploadUrl: string;
    contentObjectBase64: string;
    contentUrl: string;
    urlHeaders: UrlHeader[];
  };
}
