import React from 'react';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './LoadMoreItemsButton.scss';

type Props = {
  'data-test': string;
  onClickHandler: () => void;
};

function LoadMoreItemsButton(props: Props) {
  return (
    <div className={style.loadMoreButtonContainer}>
      <SDSButton type={ButtonType.WHITE} onClick={props.onClickHandler} data-test={`${props['data-test']}.button`}>
        {getMessageFromId('load-more-button')}
      </SDSButton>
    </div>
  );
}

export default LoadMoreItemsButton;
