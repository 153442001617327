import React from 'react';
import type { ReactNode } from 'react';

type TabProps = {
  /**
   * Tab content to be rendered
   */
  children?: ReactNode;
  /**
   * The title of the tab
   */
  title: ReactNode;
  /**
   * Class name for tab content
   */
  className?: string;
  /**
   * Class name for tab
   */
  tabClassName?: string;
};

function OriginalSDSIndividualTab({ className, children }: TabProps) {
  return <div className={className}>{children}</div>;
}

export default OriginalSDSIndividualTab;
