import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'react-virtualized';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"react-virtualized"' has no exported membe... Remove this comment to see the full error message
import type { RowRendererParams } from 'react-virtualized';

import { bulkUpdate } from 'state/features/admin/actions/featuresActions';
import { getAllFeaturesList, getAllFeaturesMap } from 'state/features/admin/selectors/featuresAdminSelectors';
import { getPublishers } from 'state/user/selectors/userSelectors';

import { FeaturesMap } from 'src/state/features/admin/featuresAdminState';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';

import styles from './ApplyFeatureFlagModal.scss';

import { ServerFeature, ServerPublisher } from 'types/features';
import { Publisher } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';

const mapStateToProps = (state: State) => {
  return {
    featuresMap: getAllFeaturesMap(state),
    publishers: getPublishers(state),
    features: getAllFeaturesList(state),
  };
};

const mapDispatchToProps = {
  bulkUpdate,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type StateProps = {
  featuresMap: FeaturesMap;
  publishers: Publisher[];
  features: Array<ServerFeature>;
};

type OwnProps = {
  modalId: string;
  hideModal: (modalId: string) => void;
  viewPublishers: Array<ServerPublisher>;
  options: {
    onBulkUpdateComplete: () => void;
  };
};

type Props = DispatchProps & StateProps & OwnProps;

type OwnState = {
  viewPublishers: ServerPublisher[];
  selectedAction: BulkAction;
  selectedFeature: ServerFeature | null;
};

enum BulkAction {
  View = 'View',
  Enable = 'Enable',
  Disable = 'Disable',
}

export class FeatureBulkUpdateModal extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      viewPublishers: [],
      selectedAction: BulkAction.View,
      selectedFeature: null,
    };
  }

  closeModal = () => {
    this.props.hideModal(this.props.modalId);
  };

  filterFeatureName = (
    inputValue: string,
    dropdownOption: {
      props: {
        children: React.ReactNode;
        value: string;
      };
    }
  ): boolean => {
    const featureName = dropdownOption.props.children;
    if (typeof featureName === 'string') {
      return this.matchString(featureName, inputValue);
    }
    return false;
  };

  filterPublisherOptions = (
    inputValue: string,
    dropdownOption: {
      props: {
        children: React.ReactNode;
        value: string;
      };
    }
  ): boolean => {
    const publisherName = dropdownOption.props.children;
    const publisherBusinessId = dropdownOption.props.value;
    const inputValueArray = inputValue.split(' ');
    if (typeof publisherName === 'string') {
      // We match both publisher name and business profile id.
      return (
        inputValueArray.some(value => this.matchString(publisherName, value)) ||
        inputValueArray.some(value => this.matchString(publisherBusinessId, value))
      );
    }
    return false;
  };

  matchString = (matchValue: string, inputValue: string) => {
    return matchValue.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  };

  getPublisherList = () => {
    const action = this.state.selectedAction;
    const selectedFeature = this.state.selectedFeature;
    let publishers;
    if (action === BulkAction.Disable && selectedFeature != null) {
      publishers = selectedFeature.publishers.map(publisher => ({
        label: publisher.displayName,
        value: publisher.businessProfileId,
      }));
    } else {
      publishers = this.props.publishers.map(publisher => ({
        label: publisher.mutablePublisherName,
        value: publisher.businessProfileId,
      }));
    }

    return publishers;
  };

  getFeatureList = () => {
    return this.props.features
      .filter(feature => feature.claims.length === 0) // Do not show feature that have claims
      .map(feature => ({
        label: feature.id,
        value: feature.id,
      }));
  };

  selectedPublishersRenderer = ({ index, style }: RowRendererParams) => {
    const viewPublishers = this.state.viewPublishers;
    const publisher = viewPublishers[index];
    if (publisher === undefined) {
      return null;
    }
    return (
      <div key={publisher.businessProfileId} style={style}>
        <FormattedMessage
          id="buck-update-publisher-name"
          description="Publisher name and business profile ids"
          defaultMessage={`${publisher.businessProfileId}-${publisher.displayName}`}
        />
      </div>
    );
  };

  onSelectedFeatureChange = (selectedFeatureID: string | null) => {
    if (selectedFeatureID == null) {
      this.setState({ viewPublishers: [] });
      return;
    }

    const feature = this.props.featuresMap[selectedFeatureID];
    if (feature === undefined) {
      this.setState({ viewPublishers: [] });
      return;
    }

    const action = this.state.selectedAction;
    switch (action) {
      case BulkAction.View:
      case BulkAction.Enable:
        this.setState({
          viewPublishers: feature.publishers,
          selectedFeature: feature,
        });
        break;
      case BulkAction.Disable:
        this.setState({
          selectedFeature: feature,
          viewPublishers: [],
        });
        break;
      default:
        break;
    }
  };

  onActionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const action: BulkAction = BulkAction[e.target.value as keyof typeof BulkAction];
    this.setState({
      selectedAction: action,
      viewPublishers: [],
      selectedFeature: null,
    });
  };

  onSelectedPublishersChange = (selectedPublisherBusinessIds: string[]) => {
    const publishers = this.props.publishers
      .filter(publisher => selectedPublisherBusinessIds.includes(publisher.businessProfileId))
      .map(publisher => ({
        businessProfileId: publisher.businessProfileId,
        displayName: publisher.mutablePublisherName,
        id: publisher.id,
      }));
    this.setState({
      viewPublishers: publishers,
    });
  };

  onApply = async () => {
    const viewPublishersIds = this.state.viewPublishers.map(publisher => publisher.businessProfileId);
    const selectedFeature = this.state.selectedFeature;
    if (selectedFeature === null || this.state.selectedAction === BulkAction.View) {
      return;
    }
    const enable = this.state.selectedAction === BulkAction.Enable;
    await this.props.bulkUpdate(selectedFeature.id, { publisherBusinessProfileIds: viewPublishersIds }, enable);
    this.props.options.onBulkUpdateComplete();
    this.closeModal();
  };

  onCancel = () => {
    this.closeModal();
  };

  renderActionRadioButton = (bulkAction: BulkAction) => {
    // @yyan TODO We should use SDSRadioGroup here. https://ant.design/components/radio/
    return (
      <div className={styles.radioButtonRow}>
        <input
          className={styles.radioButton}
          type="radio"
          id={bulkAction}
          name="bulkAction"
          value={bulkAction}
          onChange={this.onActionChange}
          checked={bulkAction === this.state.selectedAction}
        />{' '}
        {bulkAction}
      </div>
    );
  };

  render() {
    const publisherOptions = this.getPublisherList();
    const featureOptions = this.getFeatureList();
    const viewPublishers = this.state.viewPublishers;
    const selectedFeature = this.state.selectedFeature;
    const viewPublishersIds = this.state.viewPublishers.map(publisher => publisher.businessProfileId);

    const disablePublisherDropDown = this.state.selectedAction === BulkAction.View || selectedFeature === null;

    return (
      <SDSDialog
        visible
        title={
          <FormattedMessage
            id="apply-feature"
            defaultMessage="Apply Feature"
            description="Title for a dialog for applying features."
          />
        }
        onOk={this.onApply}
        onCancel={this.closeModal}
        okText={
          <FormattedMessage
            id="bulk-update-apply-button"
            description="Message on the apply button"
            defaultMessage="Apply"
          />
        }
        okButtonProps={{
          disabled: this.state.selectedAction === BulkAction.View || viewPublishersIds.length === 0,
        }}
        data-test="bulk-update-feature"
      >
        <div className={styles.container}>
          <div className={styles.publisherListContainer}>
            <FormattedMessage
              id="bulk-update-publisher-ids"
              description="Label for list publisher id / names"
              defaultMessage="Publishers IDs/Names"
            />
            <List
              className={styles.publisherList}
              width={500}
              height={300}
              rowCount={viewPublishers.length}
              rowHeight={30}
              rowRenderer={this.selectedPublishersRenderer}
            />
          </div>
          <div className={styles.radioButtonGroup}>
            {this.renderActionRadioButton(BulkAction.View)}
            {this.renderActionRadioButton(BulkAction.Enable)}
            {this.renderActionRadioButton(BulkAction.Disable)}
          </div>
        </div>
        <div className={styles.featureSelectorContainer}>
          <div className={styles.dropDownTitle}>
            <FormattedMessage
              id="bulk-update-feature-name"
              description="Label for selected feature"
              defaultMessage="Selected feature:"
            />
          </div>
          <SDSDropdown
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
            value={selectedFeature === null ? undefined : selectedFeature.id}
            data-test="bulk-update-feature-dropdown"
            showSearch
            onChange={this.onSelectedFeatureChange}
            filterOption={this.filterFeatureName}
          >
            {createSDSDropdownOptions(featureOptions)}
          </SDSDropdown>
        </div>
        <div className={styles.publisherSelectorContainer}>
          <div className={styles.dropDownTitle}>
            <FormattedMessage
              id="bulk-update-publishers-list"
              description="Label for selected publishers list"
              defaultMessage="Selected Publishers:"
            />
          </div>
          <SDSDropdown
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
            value={this.state.selectedAction === BulkAction.View ? [] : viewPublishersIds}
            data-test="bulk-update-publisher-dropdown"
            showSearch
            mode="tags"
            filterOption={this.filterPublisherOptions}
            disabled={disablePublisherDropDown}
            onChange={this.onSelectedPublishersChange}
            disableClear
            autoClearSearchValue={false}
          >
            {createSDSDropdownOptions(publisherOptions)}
          </SDSDropdown>
        </div>
      </SDSDialog>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(FeatureBulkUpdateModal);
