// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Spinner } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import type { ReactElement } from 'react';
import InlineSVG from 'svg-inline-react';

import * as analyticsActions from 'state/analytics/actions/analyticsActions';
import * as analyticsSelectors from 'state/analytics/selectors/analyticsSelectors';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import { getStoryModerationMessage } from 'state/moderation/selectors/moderationSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';
import { getActivePublisherId, getActiveCreator, hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { withKey } from 'utils/reactUtils';
import { withRouter } from 'utils/routerUtils';

import { getHiddenMetricsSet } from 'views/analytics/utils/analyticsUtil';
import { EDITION_KPI_CONFIG } from 'views/analytics/utils/kpiConfigs';
import InfoBox from 'views/common/components/InfoBox/InfoBox';
import KPIWidget from 'views/common/components/KPIWidget/KPIWidget';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/Warning.svg.inline' or i... Remove this comment to see the full error message
import warningIcon from 'icons/Warning.svg.inline';

import style from './AnalyticsCarousel.scss';

import { AnalyticsStatsType } from 'types/analytics';
import type { StoryMetricResults } from 'types/analytics';
import type { Edition } from 'types/editions';
import { Claim } from 'types/permissions';
import type { Publisher } from 'types/publishers';
import type { State as RootState } from 'types/rootState';
import type { ShowID } from 'types/shows';

type OwnProps = {
  activeEdition: Edition;
  className?: string;
  boxClassName?: string;
  hideKPIs?: boolean;
};

type StateProps = {
  publisherId: number | null | undefined;
  editionMetrics: StoryMetricResults;
  isAnalyticsViewer: boolean;
  activePublisher: Publisher | undefined | null;
  showId: ShowID | undefined | null;
  isShow: boolean;
  getStoryModerationMessage: ReactElement<any> | undefined | null;
};

type DispatchProps = {
  fetchEditionStats: typeof analyticsActions.fetchEditionStats;
  clearEditionStats: typeof analyticsActions.clearEditionStats;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  isLoadingMetrics: boolean;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const activeEditionId = ownProps.activeEdition.id;
  const activePublisher = publishersSelectors.getActivePublisherDetails(state);
  const publisherId = getActivePublisherId(state) || getActiveCreator(state)?.publisherId;
  const isShow = publishersSelectors.activePublisherIsShow(state);
  const show = publisherId ? showsSelectors.getShows(state)(publisherId)[0] : null;
  const showId = show ? show.showId : null;
  return {
    publisherId,
    getStoryModerationMessage: getStoryModerationMessage(state)(activeEditionId),
    editionMetrics: analyticsSelectors.getStoryEditionMetricsById(state)(activeEditionId),
    isAnalyticsViewer: hasClaimForActivePublisher(state, Claim.ANALYTICS_VIEWER),
    showId,
    activePublisher,
    isShow,
  };
};

const mapDispatchToProps = {
  fetchEditionStats: analyticsActions.fetchEditionStats,
  clearEditionStats: analyticsActions.clearEditionStats,
};

const mapPropsToKey = (props: Props) => props.publisherId;

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withKey(mapPropsToKey)
// @ts-expect-error ts-migrate(1219) FIXME: Experimental support for decorators is a feature t... Remove this comment to see the full error message
class AnalyticsCarousel extends React.Component<Props, State> {
  state = { isLoadingMetrics: false };

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'mounted' has no initializer and is not d... Remove this comment to see the full error message
  mounted: boolean; // eslint-disable-line react/sort-comp

  componentDidMount() {
    this.mounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  shouldHideKPIs = () => {
    // Do not show KPIs when user is not an analyst
    if (this.props.hideKPIs || !this.props.isAnalyticsViewer) {
      return true;
    }

    return (
      editionEntityHelpers.isDraft(this.props.activeEdition) || editionEntityHelpers.isHidden(this.props.activeEdition)
    );
  };

  loadData = async () => {
    if (this.shouldHideKPIs()) {
      return;
    }

    this.setState({ isLoadingMetrics: true });

    await this.props.fetchEditionStats(this.props.activeEdition.id, AnalyticsStatsType.ALL);
    if (this.mounted) {
      this.setState({ isLoadingMetrics: false });
    }
  };

  renderKPIs = () => {
    if (this.shouldHideKPIs()) {
      return null;
    }

    if (this.state.isLoadingMetrics || isEmpty(this.props.editionMetrics)) {
      return null;
    }
    const hiddenMetricsSet = getHiddenMetricsSet();

    return (
      EDITION_KPI_CONFIG.filter(kpi => !hiddenMetricsSet.has(kpi.metricId))
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        .filter(kpi => kpi.findValue(this.props.editionMetrics))
        .map(kpi => {
          return (
            <KPIWidget
              className={classNames(style.cardBox, this.props.boxClassName)}
              key={kpi.metricId}
              metric={kpi}
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              value={kpi.findValue(this.props.editionMetrics)}
              isLoading={this.state.isLoadingMetrics}
            />
          );
        })
    );
  };

  renderInfoBoxAnalytics = () => {
    if (this.state.isLoadingMetrics) {
      return (
        <div className={style.spinner}>
          <Spinner />
        </div>
      );
    }

    const { activeEdition } = this.props;
    let publishedMessage;

    if (editionEntityHelpers.isDraft(activeEdition)) {
      publishedMessage = getMessageFromId('analytics-draft-story-title');
    } else if (editionEntityHelpers.isAvailable(activeEdition)) {
      publishedMessage = getMessageFromId('analytics-published-story-title', {
        timePublished: moment(activeEdition.firstLiveDate).fromNow(),
      });
    } else if (editionEntityHelpers.isScheduled(activeEdition)) {
      publishedMessage = getMessageFromId('scheduled-status');
    }

    const lastUpdated = moment(activeEdition.lastUpdatedAt);
    const moderationMessage = this.props.getStoryModerationMessage;

    return (
      <div className={style.infoContent}>
        <span data-test="AnalyticsCarousel.publishedMessage">{publishedMessage}</span>
        <span data-test="AnalyticsCarousel.updatedMessage">
          {lastUpdated && getMessageFromId('analytics-updated-story-title', { timeUpdated: lastUpdated.fromNow() })}
        </span>
        {moderationMessage && (
          <div className={style.moderation}>
            <InlineSVG src={warningIcon} />
            <div>{moderationMessage}</div>
          </div>
        )}
      </div>
    );
  };

  renderInfoBox = () => {
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <InfoBox
        className={classNames(style.infoBox, this.props.boxClassName)}
        title={getMessageFromId('analytics-publishing-info')}
        data-test="AnalyticsCarousel.publishingInfo"
      >
        {this.renderInfoBoxAnalytics()}
      </InfoBox>
    );
  };

  render() {
    return (
      <div className={classNames(style.carouselContainer, this.props.className)}>
        {this.renderInfoBox()}
        {this.renderKPIs()}
      </div>
    );
  }
}

export { AnalyticsCarousel };
export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsCarousel));
