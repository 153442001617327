import { get } from 'lodash';

import { loadAssetInfoUntilBuildFinished } from 'state/asset/actions/assetActions';
import { clearAssociatedUserToken } from 'state/auth/actions/associatedUserAuthActions';
import { getAssociatedUser } from 'state/auth/selectors/authSelectors';
import * as featuresActions from 'state/features/admin/actions/featuresActions';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import { updateHostAccount } from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapAdminActions from 'state/snapAdmin/actions/snapAdminActions';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';
import { loadPublisherSnapcodesUntilUpdated } from 'state/snapcodes/actions/snapcodesActions';
import { getSnapcodeListPerPublisherId } from 'state/snapcodes/selectors/snapcodesSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import { StageType, UploadPurpose } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { mediaErrorHandler } from 'utils/errors/media/mediaErrorUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { isBlobUrl } from 'utils/media/blobUtils';
import {
  formatPublisherProperties,
  isInfiniteAdsEnabled,
  isSubtitleBurnEnabled,
} from 'utils/publisherSettings/publisherSettingsUtils';

import { AssetBuildStatus } from 'types/assets';
import { FeatureFlag } from 'types/featureFlags';
import { PropertyKeys, GeneratedPropertyKeys } from 'types/publisherSettings';
import type { EditableTileLogo, EditablePublisherProperties } from 'types/publisherSettings';
import type { Publisher, PublisherID } from 'types/publishers';
import type { GetState, Dispatch } from 'types/redux';
import type { ShowID } from 'types/shows';

const updateTileLogos = (publisherId: PublisherID, tileLogos?: EditableTileLogo[] | null) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (!tileLogos) {
    return;
  }
  const publisher = publishersSelectors.getPublisherDetailsDataById(getState())(publisherId) as Publisher;
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisher).is.object();
  const tileLogoComponents = snapAdminSelectors.getTileLogoComponents(getState());
  const originalProperties = formatPublisherProperties({ publisher, tileLogoComponents });
  const oldTileLogos = originalProperties.tileLogoComponents || [];
  const promises = tileLogos.map(async (tileLogo, i) => {
    const tileLogoDiff = {};
    const tileLogoKeys: any = [];
    if (tileLogo.isDeleted) {
      await dispatch(snapAdminActions.deleteTileLogoInfo({ assetId: tileLogo.id }));
    } else {
      if (tileLogo.blob) {
        (tileLogoDiff as any).image = tileLogo.blob;
        tileLogoKeys.push('image');
      }
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (tileLogo.color && (!oldTileLogos || !oldTileLogos[i] || tileLogo.color !== oldTileLogos[i].color)) {
        (tileLogoDiff as any).metadata = { color: tileLogo.color };
        tileLogoKeys.push('metadata');
      }
      if (tileLogoKeys.length > 0) {
        dispatch(snapAdminActions.setTempTileLogoProperties(tileLogo.componentId, tileLogoDiff));
        await dispatch(snapAdminActions.saveTileLogoInfo(tileLogo.componentId, publisherId)).then(() =>
          snapAdminActions.removeTempTileLogoProperties(tileLogo.componentId, tileLogoKeys)
        );
      }
    }
  });
  await Promise.all(promises);
};
const uploadTileLogoToBranding = (publisherId: PublisherID) => async (dispatch: Dispatch, getState: GetState) => {
  const settingsData: EditablePublisherProperties | undefined | null = stagesSelectors.getData(getState())(publisherId);
  if (!settingsData) {
    return;
  }
  const tileLogos = settingsData.tileLogoComponents || [];
  if (!tileLogos) {
    return;
  }
  let tileLogoId: any;
  const logoSettings = {
    image: get(settingsData, PropertyKeys.HORIZONTAL_ICON_BLOB),
    metadata: {
      color: get(settingsData, PropertyKeys.PRIMARY_COLOR),
    },
  };
  if (tileLogos.length > 0) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    tileLogoId = tileLogos[0].componentId;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    logoSettings.metadata.color = tileLogos[0].color;
  } else {
    tileLogoId = snapAdminSelectors.TILE_LOGO_PLACEHOLDER_COMPONENT_ID;
  }
  const horizontalLogoMediaId = settingsData?.[PropertyKeys.HORIZONTAL_ICON_ID];
  await dispatch(snapAdminActions.setTempTileLogoProperties(tileLogoId, logoSettings));
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
  await dispatch(snapAdminActions.saveTileLogoInfo(tileLogoId, publisherId, horizontalLogoMediaId)).then(() =>
    snapAdminActions.removeTempTileLogoProperties(tileLogoId, ['image', 'metadata'])
  );
};
const getStageBlobProperty = (
  stageId: string | number,
  propertyName: string,
  isEntityAttribute: boolean,
  createThunk: (a: string) => (b: Dispatch, a: GetState) => any
) => async (dispatch: Dispatch, getState: GetState) => {
  const stageData = stagesSelectors.getData(getState())(stageId);
  const propertyValue = get(stageData, propertyName);
  if (propertyValue && isBlobUrl(propertyValue)) {
    await dispatch(createThunk(propertyValue));
    if (!isEntityAttribute) {
      // this isn't a real attribute that we need to update on the entity
      dispatch(stagesActions.removeProperties(stageId, [propertyName]));
    }
  }
};
const uploadIcon = (
  url: string,
  mlsIconType: UploadPurpose,
  idStagePropertiesToUpdate: {
    [stageId in string | number]: string[];
  }
) => async (dispatch: Dispatch) => {
  // @ts-ignore
  const { transcodedMediaId } = await dispatch(publishersActions.uploadAndGetPath(url, mlsIconType));
  Object.keys(idStagePropertiesToUpdate).forEach(stageId => {
    const idOutputPropertyNames = idStagePropertiesToUpdate[stageId] || [];
    const idOutputProperties = transcodedMediaId
      ? idOutputPropertyNames.reduce((obj, item) => {
          return { ...obj, [item]: transcodedMediaId };
        }, {})
      : {};
    dispatch(stagesActions.updateProperties(stageId, idOutputProperties));
  });
};

const uploadSquareIcon = (publisherId: PublisherID) =>
  getStageBlobProperty(
    publisherId,
    PropertyKeys.SQUARE_ICON_BLOB,
    false,
    (url: string) => async (dispatch: Dispatch) => {
      const idsPropertiesToUpdate = {
        [publisherId]: [GeneratedPropertyKeys.FILLED_ICON_ID],
      };

      await dispatch(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
        uploadIcon(url, UploadPurpose.SQUARE_ICON, idsPropertiesToUpdate)
      );
    }
  );
const uploadHorizontalIcon = (publisherId: PublisherID, useHorizontalLogoForTileLogo: boolean) =>
  getStageBlobProperty(
    publisherId,
    PropertyKeys.HORIZONTAL_ICON_BLOB,
    false,
    (url: string) => async (dispatch: Dispatch) => {
      await dispatch(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
        uploadIcon(url, UploadPurpose.WIDE_ICON, {
          [publisherId]: [GeneratedPropertyKeys.HORIZONTAL_ICON_ID],
        })
      );
      if (useHorizontalLogoForTileLogo) {
        await dispatch(uploadTileLogoToBranding(publisherId));
      }
    }
  );
export const uploadSquareHeroImage = (publisherId: PublisherID) =>
  getStageBlobProperty(
    publisherId,
    PropertyKeys.SQUARE_HERO_IMAGE_BLOB,
    false,
    (url: string) => async (dispatch: Dispatch) => {
      await dispatch(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
        uploadIcon(url, UploadPurpose.SQUARE_HERO_IMAGE, {
          [publisherId]: [GeneratedPropertyKeys.SQUARE_HERO_IMAGE_ID],
        })
      );
    }
  );
export const pollForSnapcodeUpdate = (publisherId: PublisherID, callback: () => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const snapcodes: number[] = getSnapcodeListPerPublisherId(getState())(publisherId);
  const latestSnapcode = snapcodes[snapcodes.length - 1] || null;
  await dispatch(loadPublisherSnapcodesUntilUpdated(publisherId, latestSnapcode, callback, 60));
};

export const updateHostAccountAndPublisherSnapcode = (publisherId: PublisherID, showId: ShowID) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const publisher = publishersSelectors.getPublisherDetailsDataById(getState())(publisherId);
  const hostData = getAssociatedUser(getState());

  if (hostData?.businessProfileId && hostData.businessProfileId === publisher?.businessProfileId) {
    try {
      await dispatch(updateHostAccount(publisher.id, publisher.businessProfileId, hostData.token));
      await dispatch(clearAssociatedUserToken());
    } catch (error: any) {
      apiErrorHandler(dispatch, ErrorContexts.ASSOCIATE_HOST_USER)(error);
    }
  }
  // Return updated staged data so that we can send the correct data to graphQL
  return stagesSelectors.getData(getState())(publisherId);
};

export const uploadAndClaimMedia = (
  publisherId: PublisherID,
  useHorizontalLogoForTileLogo: boolean,
  property: PropertyKeys
) => async (dispatch: Dispatch, getState: GetState) => {
  const settingsData = stagesSelectors.getData(getState())(publisherId);

  let uploadIconAction;
  let iconIdKey = GeneratedPropertyKeys.SQUARE_HERO_IMAGE_ID;

  try {
    switch (property) {
      case PropertyKeys.SQUARE_HERO_IMAGE_BLOB:
        uploadIconAction = dispatch(uploadSquareHeroImage(publisherId));
        break;
      case PropertyKeys.HORIZONTAL_ICON_BLOB:
        uploadIconAction = dispatch(uploadHorizontalIcon(publisherId, useHorizontalLogoForTileLogo));
        iconIdKey = GeneratedPropertyKeys.HORIZONTAL_ICON_ID;
        break;
      case PropertyKeys.SQUARE_ICON_BLOB:
        uploadIconAction = dispatch(uploadSquareIcon(publisherId));
        iconIdKey = GeneratedPropertyKeys.FILLED_ICON_ID;
        break;
      case PropertyKeys.TILE_LOGOS:
        uploadIconAction = dispatch(updateTileLogos(publisherId, get(settingsData, PropertyKeys.TILE_LOGOS)));
        break;
      default:
        break;
    }
    if (uploadIconAction) {
      await uploadIconAction;
    }
  } catch (error: any) {
    apiErrorHandler(dispatch, ErrorContexts.UPLOAD_MEDIA)(error);
  }

  let assetId = get(stagesSelectors.getData(getState())(publisherId), iconIdKey as string, '');
  if (property === PropertyKeys.TILE_LOGOS) {
    const tileLogos = get(stagesSelectors.getData(getState())(publisherId), 'tileLogos');
    assetId = tileLogos[tileLogos.length - 1].mediaId;
  }

  return { assetId, publisherData: stagesSelectors.getData(getState())(publisherId) };
};

// Here we poll for the build status of an asset given it's id.
// After polling for the status, we generate new publisher details and update staging, before triggering a callback.
export const pollForAssetBuildStatusBeforeUpdating = (
  assetId: string,
  publisherId: PublisherID,
  publisherData: EditablePublisherProperties,
  finalCallbackFn: () => void
) => async (dispatch: Dispatch, getState: GetState) => {
  const pollLengthInSeconds: number = 180;
  const callback = (callbackId: string, status: AssetBuildStatus) => {
    const tileLogoComponents = snapAdminSelectors.getTileLogoComponents(getState());
    if (status !== AssetBuildStatus.SUCCESS) {
      grapheneUtils.incrementCounter('assetUpload.polling.byStatus', { status });
      const error = new Error(`Media failed to build for: ${callbackId}. Final build status: ${status}.`);
      const publisher = publishersSelectors.getPublisherDetailsDataById(getState())(publisherId);
      mediaErrorHandler(dispatch, ErrorContexts.UPLOAD_MEDIA)(error);

      // Reset staging back to original state.
      dispatch(stagesActions.stageData(publisherId, StageType.PUBLISHER));
      if (publisher) {
        const newProperties = formatPublisherProperties({ publisher, tileLogoComponents });
        dispatch(stagesActions.updateProperties(publisherId, newProperties));
      }
      finalCallbackFn();
      return;
    }
    const pubDataWithGeneratedProperties = formatPublisherProperties({
      publisher: publisherData as Publisher,
      tileLogoComponents: publisherData.tileLogoComponents as EditableTileLogo[],
    });
    dispatch(stagesActions.updateProperties(publisherId, pubDataWithGeneratedProperties));
    finalCallbackFn();
  };
  return dispatch(loadAssetInfoUntilBuildFinished(assetId, pollLengthInSeconds, callback));
};

export const saveSuperAdminMediaSettings = (publisherId: PublisherID) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const publisher = publishersSelectors.getPublisherDetailsDataById(getState())(publisherId) as Publisher;
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisher).is.object();
  const publisherStaged = stagesSelectors.getData(getState())(publisherId);
  const publisherIcons = {};
  if (publisherStaged.horizontalIconBlob) {
    // @ts-ignore
    const { transcodedMediaId } = await dispatch(
      publishersActions.uploadAndGetPath(publisherStaged.horizontalIconBlob, UploadPurpose.WIDE_ICON)
    );
    if (transcodedMediaId) {
      (publisherIcons as any).horizontalIconId = transcodedMediaId;
    }
    (publisherIcons as any).horizontalIconBlob = undefined;
  }
  if (publisherStaged.defaultFilledIconBlob) {
    // @ts-ignore
    const { transcodedMediaId } = await dispatch(
      publishersActions.uploadAndGetPath(publisherStaged.defaultFilledIconBlob, UploadPurpose.SQUARE_ICON)
    );
    if (transcodedMediaId) {
      (publisherIcons as any).defaultFilledIconId = transcodedMediaId;
    }
    (publisherIcons as any).defaultFilledIconBlob = undefined;
  }
  if (isInfiniteAdsEnabled(publisherStaged)) {
    // Enabling infinite ads
    await dispatch(
      featuresActions.saveFeatureForPublisher(publisher.businessProfileId, FeatureFlag.INFINITE_ADS_PLACER, true)
    );
  } else if (featuresSelectors.isInfiniteAdsPlacerEnabled(getState())) {
    // If it's not an infinite ads and the feature flag is enabled, disable it
    await dispatch(
      featuresActions.saveFeatureForPublisher(publisher.businessProfileId, FeatureFlag.INFINITE_ADS_PLACER, false)
    );
  }

  const isStagedSubtitleBurn = isSubtitleBurnEnabled(publisherStaged.audioClassification);
  const isSubtitleBurn = isSubtitleBurnEnabled(publisher.audioClassification);
  if (isStagedSubtitleBurn !== isSubtitleBurn) {
    await dispatch(
      featuresActions.saveFeatureForPublisher(
        publisher.businessProfileId,
        FeatureFlag.GENERATE_SUBTITLES_ON_UPLOAD,
        !isStagedSubtitleBurn
      )
    );
  }

  // Return updated staged data so that we can send the correct data to graphQL
  return stagesSelectors.getData(getState())(publisherId);
};
