import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import type { ReactNode, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  enableFeatureForAll,
  disableFeatureForAll,
  loadAllFeatures,
} from 'state/features/admin/actions/featuresActions';
import { getAllFeaturesList } from 'state/features/admin/selectors/featuresAdminSelectors';
import { showModal } from 'state/modals/actions/modalsActions';

import { FeatureWhitelistMode } from 'config/constants';
import type { FeatureWhitelistModeEnum } from 'config/constants';
import { gear, plus, search } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { ModalType } from 'utils/modalConfig';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import Header from 'views/common/components/Header/Header';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSTable from 'views/common/components/SDSTable/SDSTable';
import SDSTooltip from 'views/common/components/SDSTooltip/SDSTooltip';

import AddFeatureToTierButton from './AddFeatureToTierButton';
import style from './FeaturesManagement.scss';
import RemovableTierItemWithButton from './RemovableTierItemWithButton';

import type { ServerFeature } from 'types/features';
import { ExtractDispatchProps } from 'types/redux';

type OwnProps = {
  features: Array<ServerFeature>;
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = OwnProps & DispatchProps;

type OwnState = {
  query: string;
  showMoreClaimsIndexes: Set<number>;
  isSaving: boolean;
};
type FeatureRecord = {
  key: string;
} & ServerFeature;
const mapStateToProps = (state: State) => {
  return {
    features: getAllFeaturesList(state),
  };
};
const mapDispatchToProps = {
  showModal,
  enableFeatureForAll,
  disableFeatureForAll,
  loadAllFeatures,
};
export class FeaturesManagement extends React.Component<Props, OwnState> {
  static path = '/snap_admin/features';

  static title = 'Feature Flags';

  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
      showMoreClaimsIndexes: new Set(),
      isSaving: false,
    };
  }

  getDataSource = (): Array<FeatureRecord> => {
    const dataSource: Array<FeatureRecord> = this.props.features?.map((feature, index) => {
      return { ...feature, key: `${feature.id}_${index}` };
    });
    const query = this.state.query.toLowerCase();
    return dataSource.filter(feature => feature.id.toLowerCase().includes(query));
  };

  getFeatureColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'id',
        key: 'name',
        render: this.renderName,
      },
      {
        title: 'Claims',
        dataIndex: 'claims',
        key: 'claim',
        render: this.renderClaims,
      },
      {
        title: 'Tiers',
        dataIndex: 'tierLevels',
        key: 'tiers',
        render: this.renderTiers,
      },
      {
        title: 'Whitelist Mode',
        dataIndex: 'whitelistMode',
        key: 'whitelistMode',
        render: this.renderWhitelistMode,
      },
      {
        title: 'Publishers',
        dataIndex: 'publishers',
        render: this.renderPublishers,
        key: 'publishers',
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: this.renderCreatedAt,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'actions,',
        render: this.renderActions,
      },
    ];
  };

  renderName = (featureId: string, record: FeatureRecord) => {
    return (
      <div className={style.nameCell}>
        <div className={style.featureName}>{featureId}</div>
        <div>{record.description}</div>
      </div>
    );
  };

  onClaimsMore = (index: number, show: boolean) => () => {
    const showMoreClaimsIndexes = this.state.showMoreClaimsIndexes;
    if (show) {
      showMoreClaimsIndexes.add(index);
    } else if (showMoreClaimsIndexes.has(index)) {
      showMoreClaimsIndexes.delete(index);
    }
    this.setState({
      showMoreClaimsIndexes,
    });
  };

  renderClaimsList = (claimIds: Array<string>): ReactNode =>
    claimIds?.map(claimId => <div key={claimId}>{claimId}</div>);

  renderClaims = (claimIds: Array<string>, record: FeatureRecord, index: number) => {
    const isShowingAllClaims = this.state.showMoreClaimsIndexes.has(index);
    if (claimIds.length < 5 || isShowingAllClaims) {
      return (
        <div>
          {this.renderClaimsList(claimIds)}
          {isShowingAllClaims && (
            <SDSButton type={ButtonType.SECONDARY} onClick={this.onClaimsMore(index, false)}>
              Hide
            </SDSButton>
          )}
        </div>
      );
    }
    const moreString = `+ ${claimIds.length - 3} more`;
    return (
      <div>
        {this.renderClaimsList(claimIds.slice(0, 3))}
        <SDSButton type={ButtonType.SECONDARY} onClick={this.onClaimsMore(index, true)}>
          {moreString}
        </SDSButton>
      </div>
    );
  };

  renderTiers = (tierIds: Array<string>, record: FeatureRecord, index: number) => {
    const tierList = tierIds?.map(tierId => (
      <div key={`${tierId}_${index}`} className={(style as any).tierElement}>
        <RemovableTierItemWithButton featureId={record.id} tierId={tierId} />
      </div>
    ));
    return (
      <div className={style.tiersContainer}>
        <div className={style.tierList}>{tierList}</div>
        <AddFeatureToTierButton tierIds={tierIds} featureId={record.id} />
      </div>
    );
  };

  renderWhitelistMode = (whitelistMode: FeatureWhitelistModeEnum) => {
    return whitelistMode === FeatureWhitelistMode.INCLUSIVE ? 'Opt in' : 'Opt out';
  };

  renderPublishers = (publishers: Array<any>, record: FeatureRecord, index: number) => {
    const displayText =
      record.whitelistMode === FeatureWhitelistMode.INCLUSIVE
        ? `Enabled for ${publishers.length} publishers`
        : `Disabled for ${publishers.length} publishers`;
    if (publishers.length === 0) {
      return displayText;
    }
    const title = publishers?.map(publisher => <div key={`${publisher.id}_${index}`}>{publisher.displayName}</div>);
    return <SDSTooltip title={title}>{displayText}</SDSTooltip>;
  };

  renderCreatedAt = (createdAt: string) => {
    return moment(createdAt).format('DD MMM YYYY');
  };

  onFeatureAllowlistModeChanged = (featureId: string, enableForAll: boolean) => () => {
    const modalConfig: DialogModalOptions = {
      visible: true,
      onConfirm: () => {
        this.setState({ isSaving: true });
        const promise: Promise<any> = enableForAll
          ? this.props.enableFeatureForAll(featureId)
          : this.props.disableFeatureForAll(featureId);
        promise.finally(() => this.setState({ isSaving: false }));
      },
      body: (
        <>
          <FormattedMessage
            id="feature-update-confirmation"
            defaultMessage="This change will update the feature flag for ALL publishers, are you sure you want to go ahead?"
            description="Confirm with the user thay are happy to update the feature for all publishers."
          />
        </>
      ),
    };

    this.props.showModal(ModalType.DIALOG, 'FeatureAllowlistModeChangeConfirmationModal', modalConfig);
  };

  renderActions = (featureId: string, record: FeatureRecord) => {
    return (
      <div className={style.actions}>
        <SDSButton
          onClick={this.onFeatureAllowlistModeChanged(featureId, true)}
          disabled={this.state.isSaving}
          type={ButtonType.SECONDARY}
          data-test="FeaturesManagement.renderActions.enable"
        >
          Enable for everyone
        </SDSButton>
        <SDSButton
          onClick={this.onFeatureAllowlistModeChanged(featureId, false)}
          type={ButtonType.SECONDARY}
          disabled={this.state.isSaving}
          data-test="FeaturesManagement.renderActions.disable"
        >
          Disable for everyone
        </SDSButton>
      </div>
    );
  };

  onSearchChange = (event: ChangeEvent<EventTarget>) => {
    const query = get(event, 'target.value', '');
    this.setState({ query });
  };

  renderRightElements = () => {
    return (
      <div className={style.rightElements}>
        <div className={style.searchInputContainer}>
          <SDSInput
            value={this.state.query}
            onChange={this.onSearchChange}
            inlineIcon={search}
            autoFocus
            data-test="common.userManagement.searchBox.input"
          />
        </div>
        <SDSButton inlineIcon={plus} type={ButtonType.PRIMARY} onClick={this.onNewFeatureHandler}>
          New Feature
        </SDSButton>
        &nbsp;
        <SDSButton inlineIcon={plus} type={ButtonType.PRIMARY} onClick={this.onBulkUpdateHandler}>
          Bulk Update
        </SDSButton>
      </div>
    );
  };

  onNewFeatureHandler = () => this.props.showModal(ModalType.CREATE_FEATURE_FLAG, 'CreateFeatureFlag');

  onBulkUpdateHandler = () =>
    this.props.showModal(ModalType.FEATURE_BULK_UPDATE, 'FeatureBulkUpdate', {
      onBulkUpdateComplete: () => {
        this.props.loadAllFeatures();
      },
    });

  tableRowClassName = () => (style as any).row;

  render() {
    const dataSource = this.getDataSource();

    const title = `Features Management - ${this.props.features.length} features`;
    return (
      <div className={style.root}>
        <Header titleIcon={gear} title={title} rightElements={this.renderRightElements()} />
        <SDSTable columns={this.getFeatureColumns()} dataSource={dataSource} pagination={false} />
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(FeaturesManagement);
