import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import { isUpdatedProfilePreviewEnabled } from 'state/features/selectors/featuresSelectors';

import type { LogoDisplayEnum } from 'config/constants';
import { CrossOrigin } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import type { Enum } from 'utils/enum';
import { enumObject } from 'utils/enum';
import * as tileUtils from 'utils/tileUtils';

import { DotsProgress } from 'views/common/components/DotsProgress/DotsProgress';
import Spinner, { SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';

// @ts-ignore
import arrowUp from 'icons/arrow_up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/profileFurnitureLegacy.... Remove this comment to see the full error message
import legacyFurniture from 'images/profileFurnitureLegacy.svg.inline';
// @ts-ignore
import verified from 'images/verified.svg.inline';

import style from './PublisherProfilePreview.scss';

import { MediaLoading } from 'types/publisherSettings';
import type { State as RootState } from 'types/rootState';

const CurrentPreview = enumObject({
  PROFILE_PREVIEW: 'PROFILE_PREVIEW',
  LEGACY_PREVIEW: 'LEGACY_PREVIEW',
});

type CurrentPreviewType = Enum<typeof CurrentPreview>;

type StateProps = {
  isUpdatedProfilePreviewEnabled: boolean;
};

type Props = {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  imageClassName?: string;
  formalName: string | undefined | null;
  description: string | undefined | null;
  websiteUrl?: string;
  images: string[];
  profileLogoDisplay: LogoDisplayEnum;
  horizontalLogo: string | undefined | null;
  squareLogo: string | undefined | null;
  username: string | undefined | null;
  heroImagebitmojiTemplateId: string | undefined | null;
  'data-test': string | undefined | null;
  isLoading: MediaLoading;
} & StateProps;

type State = {
  currentPreview: CurrentPreviewType;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    isUpdatedProfilePreviewEnabled: isUpdatedProfilePreviewEnabled(state),
  };
};

export class PublisherProfilePreview extends React.PureComponent<Props, State> {
  state = {
    currentPreview: this.props.isUpdatedProfilePreviewEnabled
      ? CurrentPreview.PROFILE_PREVIEW
      : CurrentPreview.LEGACY_PREVIEW,
  };

  previewOrder = [CurrentPreview.LEGACY_PREVIEW, CurrentPreview.PROFILE_PREVIEW];

  getCurrentIndex = () => {
    return this.previewOrder.findIndex(preview => preview === this.state.currentPreview);
  };

  getImage = () => {
    const currentIndex = this.getCurrentIndex();
    const blob = this.props.images[currentIndex];

    if (!blob) {
      return null;
    }

    return this.props.heroImagebitmojiTemplateId && this.props.heroImagebitmojiTemplateId.length > 0
      ? tileUtils.buildBitmojiImageUrl(this.props.heroImagebitmojiTemplateId)
      : blob;
  };

  nextPreview = () => {
    const { length } = this.previewOrder;
    const nextIndex = (this.getCurrentIndex() + length + 1) % length;
    const currentPreview = this.previewOrder[nextIndex];
    if (currentPreview) {
      this.setState({ currentPreview });
    }
  };

  lastPreview = () => {
    const { length } = this.previewOrder;
    const prevIndex = (this.getCurrentIndex() + length - 1) % length;
    const currentPreview = this.previewOrder[prevIndex];
    if (currentPreview) {
      this.setState({ currentPreview });
    }
  };

  gotoPreview = (currentPreview: CurrentPreviewType) => {
    this.setState({ currentPreview });
  };

  renderPlaceholderBackgroundImage() {
    const currentIndex = this.getCurrentIndex();
    const blob = this.props.images[currentIndex];

    const classes = classNames({ [style.placeholderImage]: !blob });
    return <div className={classes} />;
  }

  renderGradient = () => {
    return <div className={style.gradient} data-test="publisherProfilePreview.gradient" />;
  };

  renderSquareLogo = () => {
    const blob = this.props.squareLogo;
    if (!blob) {
      return (
        <div
          className={classNames(style.logoPlaceholder, style.logo)}
          data-test="publisherProfilePreview.logoPlaceholder"
        />
      );
    }
    return (
      <img
        className={style.logo}
        src={blob}
        crossOrigin={CrossOrigin.USE_CREDENTIALS}
        alt="Logo"
        data-test="publisherProfilePreview.logo"
      />
    );
  };

  renderTitle = () => {
    return (
      <div className={style.title} data-test="publisherProfilePreview.title">
        <span data-test="publisherProfilePreview.formalName">{this.props.formalName}</span>
        <InlineSVG className={style.verified} src={verified} data-test="publisherProfilePreview.verified" />
      </div>
    );
  };

  renderSubTitle = () => {
    return (
      <div className={style.subTitle} data-test="publisherProfilePreview.title">
        <span data-test="publisherProfilePreview.formalName">{this.props.username}</span>
      </div>
    );
  };

  renderSubscribers = () => {
    const subscribers = '1.2M';
    return (
      <div className={style.subscribers} data-test="publisherProfilePreview.subscribers">
        <FormattedMessage
          id="publisher-profile-preview-subscribers"
          description="Subscribers label in publisher profile preview"
          defaultMessage="{subscribers} Subscribers"
          values={{ subscribers }}
        />
      </div>
    );
  };

  renderLoadingSpinner = () => {
    return <Spinner loading message={SpinnerLabels.UPLOADING} size={SpinnerSizes.MEDIUM} className={style.spinner} />;
  };

  renderProfilePreview() {
    const blob = this.getImage();
    const isHeroImageLoading = this.props.isLoading.squareHeroImage;
    const isSquareIconLoading = this.props.isLoading.squareIcon;
    const crossOrigin = this.props.heroImagebitmojiTemplateId ? CrossOrigin.NONE : CrossOrigin.USE_CREDENTIALS;
    if (isHeroImageLoading || isSquareIconLoading) {
      return <span data-test="publisherProfilePreview">{this.renderLoadingSpinner()}</span>;
    }

    if (!blob) {
      return null;
    }

    return (
      <span data-test="publisherProfilePreview">
        <div className={style.preview} data-test="publisherProfilePreview.preview">
          <img src={blob} crossOrigin={crossOrigin} alt="Preview" data-test="publisherProfilePreview.img" />
        </div>
        {this.renderGradient()}
        <div className={style.previewBox}>
          <div className={style.previewBar}>
            <InlineSVG
              className={style.furniture}
              src={legacyFurniture}
              data-test="publisherProfilePreview.furniture"
            />
          </div>
          <div className={style.header}>
            <div className={style.appBar}>
              <div className={style.horizontalBar} />
            </div>
            <div className={style.profileInfo}>
              {this.renderSquareLogo()}
              <div className={style.titles}>
                {this.renderTitle()}
                {this.renderSubscribers()}
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }

  renderImagePreview = () => {
    return (
      <div className={style.imageContainer}>
        <div className={classNames(style.image, this.props.imageClassName)}>
          {this.renderProfilePreview()}
          {this.renderPlaceholderBackgroundImage()}
        </div>
      </div>
    );
  };

  render() {
    const classes = classNames(style.parent, this.props.className);
    const shouldRenderArrows = this.props.images.length > 1;
    return (
      <div className={classes}>
        <div className={style.previewContainer} data-test="publisherProfilePreview.imageContainer">
          {shouldRenderArrows && (
            <InlineSVG onClick={this.lastPreview} className={classNames(style.arrowButton, style.back)} src={arrowUp} />
          )}
          {this.renderImagePreview()}
          {shouldRenderArrows && <InlineSVG onClick={this.nextPreview} className={style.arrowButton} src={arrowUp} />}
        </div>
        {shouldRenderArrows && (
          <DotsProgress
            className={style.dotsProgress}
            value={this.state.currentPreview}
            options={this.previewOrder}
            onChange={this.gotoPreview}
          />
        )}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(PublisherProfilePreview);
