import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import style from './DotsProgress.scss';

type Props = {
  className?: string;
  value: string;
  options: string[];
  onChange: (a: string) => void;
};

export class DotsProgress extends React.PureComponent<Props> {
  onChange = (value: string) => () => {
    this.props.onChange(value);
  };

  generateProgress = () => {
    const options = _.map(this.props.options, (value: string) => {
      const classes = classNames({
        [style.selectPreview]: true,
        [style.selected]: this.props.value === value,
      });
      return (
        <div className={classes} onClick={this.onChange(value)} key={value} data-test="dotProgress.bar.selected" />
      );
    });
    return options;
  };

  render() {
    return (
      <div className={classNames(style.progress, this.props.className)} data-test="dotProgress.bar">
        {this.generateProgress()}
      </div>
    );
  }
}

export default DotsProgress;
