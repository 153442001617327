import { gql } from '@apollo/client';

export const GET_INDIVIDUAL_SPOTLIGHT_POST_FIELDS = gql`
  fragment IndividualSpotlightAnalyticsFields on SpotlightAnalyticsResult {
    spotlightAnalyticsForPage(cursor: $cursor) {
      edges {
        node {
          id
          videoUrl
          thumbnailUrl
          key
          iv
          description
          moderationStatus
          moderationRejectionReasons
          creationTimestampMs
          analytics {
            totalShares
            totalFavourite
            subscribes
            totalViews
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_SPOTLIGHT_POSTS = gql`
  ${GET_INDIVIDUAL_SPOTLIGHT_POST_FIELDS}
  query GetSpotlightPosts($businessProfileId: UUID!, $cursor: String!) {
    spotlightAnalytics(businessProfileId: $businessProfileId) {
      ... on SpotlightAnalyticsResult {
        ...IndividualSpotlightAnalyticsFields
      }
    }
  }
`;
export const GET_SPOTLIGHT_ANALYTICS = gql`
  ${GET_INDIVIDUAL_SPOTLIGHT_POST_FIELDS}
  query GetSpotlightAnalytics($businessProfileId: UUID!, $cursor: String!) {
    spotlightAnalytics(businessProfileId: $businessProfileId) {
      ... on SpotlightAnalyticsResult {
        profileSpotlightAnalytics {
          aggregate {
            subscribesSinceLastMonth
            subscribesSinceLastWeek
            subscribesSincePreviousMonth
            subscribesSincePreviousWeek
            totalFavouritesSinceLastMonth
            totalFavouritesSinceLastWeek
            totalFavouritesSincePreviousMonth
            totalFavouritesSincePreviousWeek
            totalSharesSinceLastMonth
            totalSharesSinceLastWeek
            totalSharesSincePreviousMonth
            totalSharesSincePreviousWeek
            totalViewTimeSinceLastMonth
            totalViewTimeSinceLastWeek
            totalViewTimeSincePreviousMonth
            totalViewTimeSincePreviousWeek
            totalViewersSinceLastMonth
            totalViewersSinceLastWeek
            totalViewersSincePreviousMonth
            totalViewersSincePreviousWeek
            totalViewsSinceLastMonth
            totalViewsSinceLastWeek
            totalViewsSincePreviousMonth
            totalViewsSincePreviousWeek
          }
          timeSeries {
            timestamp
            summary {
              subscribes
              totalFavourite
              totalShares
              totalViewers
              totalViews
              totalViewTime
            }
          }
        }
        ...IndividualSpotlightAnalyticsFields
      }
    }
  }
`;
