import { isAvailable, isScheduled, isPublishing, isUnpublishing } from 'state/editions/schema/editionEntityHelpers';

import { isInTheFuture } from 'utils/dateUtils';

import type { Edition, EditionID } from 'types/editions';

export const TICK = 5000;
export default class StoryStateUpdater {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'story' has no initializer and is not def... Remove this comment to see the full error message
  story: Edition;

  getStory: (a: { editionId: EditionID }) => Promise<unknown>;

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'TimeoutID'.
  timerId: TimeoutID | undefined | null;

  hasCleared: boolean;

  constructor(story: Edition, getStory: (a: { editionId: EditionID }) => Promise<unknown>) {
    this.getStory = getStory;
    this.hasCleared = false;
    this.updateStory(story);
  }

  updateStory(story: Edition) {
    this.story = story;

    // Remove older timers
    this._resetTimer();

    // If edition scheduled, start checking for updates when we reach startDate
    if (isScheduled(this.story) && this.story.startDate && isInTheFuture(Date.parse(this.story.startDate))) {
      this.timerId = setTimeout(
        this._handleEditionTransitioningState,
        Date.parse(this.story.startDate || '') - Date.now() + 1000
      );
    }

    // If edition available, start checking for updates when we reach endDate
    if (isAvailable(this.story) && this.story.endDate && isInTheFuture(Date.parse(this.story.endDate))) {
      this.timerId = setTimeout(
        this._handleEditionTransitioningState,
        Date.parse(this.story.endDate || '') - Date.now() + 1000
      );
    }

    this._handleEditionTransitioningState();
  }

  clear() {
    this._resetTimer();
    this.hasCleared = true;
  }

  _handleEditionTransitioningState = () => {
    if (this._shouldFetchStoryState()) {
      this._fetchStoryState();
    }
  };

  _resetTimer = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = null;
  };

  _restartFetchCycle = () => {
    if (!this.hasCleared) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = this._shouldFetchStoryState() ? setTimeout(this._handleEditionTransitioningState, TICK) : null;
    }
  };

  // Story does not go instantly live. It takes some time for the publish state to change from SCHEDULED to LIVE
  // To show up to date UI, we check every TICK seconds if state has changed
  _fetchStoryState = () => {
    this.getStory({ editionId: this.story.id }).then(this._restartFetchCycle, this._restartFetchCycle);
  };

  _shouldFetchStoryState = () => {
    const publishing = this.story && isPublishing(this.story);

    const unpublishing = this.story && isUnpublishing(this.story);

    return publishing || unpublishing;
  };
}
