import { gql } from '@apollo/client';

import { StorySnapType } from '__ssp-generated__/globalTypes';

export const GET_STORY_DRAFTING_SNAPS = gql`
  query GetStoryDraftingSnaps($input: StoryDraftingSnapsRequest!) {
    storyDraftingSnaps(input: $input) {
      snaps {
        id
        mediaUrl
        mediaIv
        mediaKey
        mimeType
        posterMimeType
        storySnapType
        isContributor
        description
        goLiveTsMs
      }
      cursor
    }
  }
`;

export enum ScheduledStoryStatus {
  SCHEDULED,
  ERROR,
}

export type StoryDraftingSnap = {
  id: string;
  mediaUrl: string;
  mediaIv: string;
  mediaKey: string;
  mimeType: string;
  posterMimeType: string;
  storySnapType: StorySnapType;
  isContributor: boolean;
  description: string;
  goLiveTsMs: number;
};

export interface StoryDraftingSnaps {
  snaps: StoryDraftingSnap[];
  cursor: string;
}
