import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { send } from 'icons/SDS/allIcons';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SubmitBar.scss';

type Props = {
  disabled: boolean;
  isLoading?: boolean;
  isScheduling?: boolean;
  hasUserSignedSpotlightTerms: boolean;
  handleSubmit: () => void;
};

export default function SubmitBar(props: Props) {
  const submitMessage = props.isScheduling ? (
    <FormattedMessage defaultMessage="Schedule Snap" description="Schedule snap label" id="schedule-story-snap-label" />
  ) : (
    <FormattedMessage
      defaultMessage="Post to Snapchat"
      description="Post to snapchat label"
      id="post-story-snap-label"
    />
  );
  const buttonMessage = props.hasUserSignedSpotlightTerms ? (
    submitMessage
  ) : (
    <FormattedMessage
      defaultMessage="Agree to Spotlight Terms"
      description="Agree to snapchat terms label"
      id="accept-spotlight-terms-label"
    />
  );
  return (
    <div
      className={classNames(style.barContainer, {
        [style.disabledBar]: props.disabled,
      })}
    >
      <div className={style.buttonWrapper}>
        <SDSButton
          type={props.disabled ? ButtonType.WHITE_ON_GREY : ButtonType.ACTIVE_ON_WHITE}
          disabled={props.disabled}
          inlineIcon={send}
          loading={props.isLoading}
          onClick={props.handleSubmit}
        >
          {buttonMessage}
        </SDSButton>
      </div>
    </div>
  );
}
