import log from 'loglevel';
import React from 'react';

import * as routerActions from 'state/router/actions/routerActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { Claim } from 'src/types/permissions';
import { ExtractDispatchProps } from 'src/types/redux';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { functionRef } from 'utils/functionUtils';

export const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  hasClaim: userSelectors.hasClaimForActivePublisher(state, ownProps.requiredClaim),
  publisherId: userSelectors.getActivePublisherId(state),
});
const mapDispatchToProps = {
  goToNoPermission: functionRef(routerActions, 'goToNoPermission'),
};

type OwnProps = {
  requiredClaim: Claim;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;
export class ClaimGatedView extends React.PureComponent<Props> {
  UNSAFE_componentWillMount() {
    if (!this.props.hasClaim) {
      this.goToNoPermission();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (!nextProps.hasClaim) {
      this.goToNoPermission();
    }
  }

  goToNoPermission = () => {
    log.error('User has no claim on publisher, gating');
    this.props.goToNoPermission({ overwriteHistory: true, publisherId: this.props.publisherId });
  };

  render() {
    if (!this.props.hasClaim) {
      return null;
    }
    return this.props.children;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(ClaimGatedView);
