import React from 'react';
import { useSelector } from 'react-redux';

import { getUserBitmojiAvatarId } from 'state/user/selectors/userSelectors';

import { BITMOJI_TEMPLATES, DEFAULT_BITMOJI_AVATAR, getBitmojiUrl } from 'config/bitmojiConfig';
import { CrossOrigin } from 'config/constants';

import Icon from 'views/common/components/Icon/Icon';

import style from './BitmojiAvatar.scss';

type Props = {
  bitmojiTemplateId?: BITMOJI_TEMPLATES;
};

export default function BitmojiAvatar({ bitmojiTemplateId }: Props) {
  const bitmojiAvatarId = useSelector(getUserBitmojiAvatarId) || DEFAULT_BITMOJI_AVATAR;
  const templateId = bitmojiTemplateId || BITMOJI_TEMPLATES.USER_LAPTOP;
  return (
    <div className={style.bitmojiWrapper} data-test={`SpotlightUploaderView-${bitmojiTemplateId}`}>
      <Icon img={getBitmojiUrl(templateId!, bitmojiAvatarId)} crossOrigin={CrossOrigin.ANONYMOUS} />
    </div>
  );
}
