import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { circleFilled, warningCircle } from 'icons/SDS/allIcons';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './AnalyticsStatusIcon.scss';

import type { ServiceStatus } from 'types/analytics';

type Props = {
  serviceStatus: ServiceStatus;
};

export const AnalyticsServiceStatusEnum = {
  OK: 'OK',
  MINOR_DELAY: 'MINOR_DELAY',
  MAJOR_DELAY: 'MAJOR_DELAY',
  UNKNOWN: 'UNKNOWN',
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-service-status-minor-delays"
      description="Info message to let the user know analytics service has minor delays"
      defaultMessage="Minor delays"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-service-status-major-delays"
      description="Info message to let the user know analytics service has severe delays"
      defaultMessage="Severe delays"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-service-status-no-delays-tooltip"
      description="Tooltip message to let the user know analytics service has no delays"
      defaultMessage="Analytics are up to date"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-service-status-minor-delays-tooltip"
      description="Tooltip message to let the user know analytics service has minor delays"
      defaultMessage="There is a slight delay to analytics. Analytics will be available ASAP."
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-service-status-major-delays-tooltip"
      description="Tooltip message to let the user know analytics service has severe delays"
      defaultMessage="Analytics is delayed. We are working to resolve the issue ASAP. Apologies for the inconvenience."
    />
  ),
  params: [],
});

function AnalyticsStatusIcon(props: Props) {
  const {
    serviceStatus: { status },
  } = props;

  const renderTooltipText = (): ReactNode => {
    switch (status) {
      case AnalyticsServiceStatusEnum.OK:
      default:
        return getMessageFromId('analytics-service-status-no-delays-tooltip');
      case AnalyticsServiceStatusEnum.MINOR_DELAY:
      case AnalyticsServiceStatusEnum.UNKNOWN:
        return getMessageFromId('analytics-service-status-minor-delays-tooltip');
      case AnalyticsServiceStatusEnum.MAJOR_DELAY:
        return getMessageFromId('analytics-service-status-major-delays-tooltip');
    }
  };

  const renderServiceStatusLabel = (): ReactNode => {
    switch (status) {
      case AnalyticsServiceStatusEnum.MINOR_DELAY:
      case AnalyticsServiceStatusEnum.UNKNOWN:
      default:
        return (
          <div
            data-test="analytics.serviceStatus.label.minorDelay.container"
            className={classNames(style.labelContainer, style.partlyUnhealthyLabelContainer)}
          >
            {getMessageFromId('analytics-service-status-minor-delays')}
          </div>
        );
      case AnalyticsServiceStatusEnum.MAJOR_DELAY:
        return (
          <div
            data-test="analytics.serviceStatus.label.majorDelay.container"
            className={classNames(style.labelContainer, style.unhealthyLabelContainer)}
          >
            {getMessageFromId('analytics-service-status-major-delays')}
          </div>
        );
    }
  };

  const renderHealthIcon = () => {
    const isStatusHealthy = status === AnalyticsServiceStatusEnum.OK;
    const iconContainerClassNames = classNames({
      [style.healthyIconContainer]: isStatusHealthy,
      [style.unhealthyIconContainer]: !isStatusHealthy,
    });

    const iconClassNames = classNames({
      [style.healthyIcon]: isStatusHealthy,
      [style.partlyUnhealthyIcon]:
        status === AnalyticsServiceStatusEnum.MINOR_DELAY || status === AnalyticsServiceStatusEnum.UNKNOWN,
      [style.unhealthyIcon]: status === AnalyticsServiceStatusEnum.MAJOR_DELAY,
    });

    return (
      <div className={iconContainerClassNames}>
        <Icon inlineIcon={isStatusHealthy ? circleFilled : warningCircle} className={iconClassNames} />
      </div>
    );
  };

  return (
    <SDSTooltip
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      placement={TooltipPosition.BOTTOM}
      title={renderTooltipText()}
      id="tooltip"
    >
      <div data-test="analytics.serviceStatus.info.container" className={style.analyticsStatusContainer}>
        {renderHealthIcon()}
        {status === AnalyticsServiceStatusEnum.OK ? null : renderServiceStatusLabel()}
      </div>
    </SDSTooltip>
  );
}

export default AnalyticsStatusIcon;
