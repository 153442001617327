import React from 'react';
import type { ReactNode } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import AnalyticsGraphContainer from 'views/analytics/components/AnalyticsGraphContainer/AnalyticsGraphContainer';
import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';

type GraphBar = {
  fill: string;
  key: string;
  name: string | undefined | null;
  stackId?: string;
};

type Props = {
  barChartData: any;
  barChartTitle: ReactNode | undefined | null;
  bars: GraphBar[];
  graphTooltip?: ReactNode;
  graphHelpCenterLink?: HelpCenterDestination;
  isLoading: boolean;
  shouldShowToggle?: boolean;
  tooltipRenderer: () => ReactNode;
  yAxisTickFormatter: (num: number) => string;
  isStackedInitiallySelected?: boolean;
  barColorFunc?: (b: any, a: string) => string;
};

type OwnState = {
  selectedBar: string | undefined | null;
  isStackedSelected: boolean;
  graphSize:
    | {
        width: number;
        height: number;
      }
    | undefined
    | null;
};

type LegendOnClickEvent = {
  dataKey: string | undefined | null;
};

const barRadius = [5, 5, 0, 0];
const idealBarFillWidthRatio = 0.7;

const COMPACT_ITEM_COUNT_THRESHOLD = 50;
const NORMAL_BAR_LAYOUT_PARAMS = {
  barGap: 4,
  barCategoryGap: 4,
  maxBarSize: 60,
};

const COMPACT_BAR_LAYOUT_PARAMS = {
  barGap: 0,
  barCategoryGap: 0,
  maxBarSize: 20,
};

export class AnalyticsBarChart extends React.PureComponent<Props, OwnState> {
  state = {
    selectedBar: null,
    isStackedSelected: this.props.isStackedInitiallySelected || false,
    graphSize: null,
  };

  getBarChartDataWithPadding = () => {
    // We would like the both graph grid and axes to fill the available width, but not necessarily the bars themselves
    // (otherwise you either get crazy big bars or crazy big gaps between bars when the bar count is low).
    // We can achieve this by inserting some dummy padding entries in the dataset. They won't get rendered but will
    // cause the visible ones to be shifted to the left as required :)
    const { graphSize } = this.state;
    if (!graphSize || !this.props.barChartData.length) {
      return this.props.barChartData;
    }

    const layout = this.getBarLayoutParams();

    const barMaxWidth = layout.maxBarSize + layout.barGap * 2;
    const availableNumberOfBars = this.props.barChartData.length;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const idealNumberOfBars = Math.floor((graphSize.width * idealBarFillWidthRatio) / barMaxWidth);
    const numberOfPaddingBars = idealNumberOfBars - availableNumberOfBars;
    const padding = [];
    for (let i = 0; i < numberOfPaddingBars; ++i) {
      padding.push({});
    }

    return this.props.barChartData.concat(padding);
  };

  getBarLayoutParams = () => {
    const data = this.props.barChartData;
    return !data || data.length < COMPACT_ITEM_COUNT_THRESHOLD ? NORMAL_BAR_LAYOUT_PARAMS : COMPACT_BAR_LAYOUT_PARAMS;
  };

  getRadius = (index: number, numberOfStacks: number) => {
    // if we're stacking the chart we only want to apply a radius
    // to the topmost bar, else always apply radius
    const isTopmostStack = index === numberOfStacks - 1;
    if (this.state.isStackedSelected && !isTopmostStack) {
      return null;
    }
    return barRadius;
  };

  getStackId = (bar: GraphBar) => {
    return this.state.isStackedSelected ? bar.stackId : null;
  };

  handleGraphResize = (size: { width: number; height: number }) => {
    this.setState({
      graphSize: size,
    });
  };

  handleLegendOnClick = (event: LegendOnClickEvent) => {
    const selectedBar = this.state.selectedBar === event.dataKey ? null : event.dataKey && event.dataKey.trim();
    this.setState({ selectedBar });
  };

  handleToggleUpdated = (isStackedSelected: boolean) => {
    this.setState({ isStackedSelected });
  };

  renderCells = (defaultFill: string, bar: GraphBar) => {
    // sometimes we want to apply a custom color to an individual cell -
    // check if we have a color property on the raw data, else use the fill for
    // the current bar
    const barColorFunc = this.props.barColorFunc || ((barCharData: any, key: string) => barCharData?.color);

    return this.props.barChartData.map((entry: any) => {
      const fill = barColorFunc(entry, bar.key) || defaultFill;
      return <Cell key={entry.name} fill={fill} />;
    });
  };

  renderBarChart = () => {
    const barChartData = this.getBarChartDataWithPadding();
    return (
      <BarChart data={barChartData} {...this.getBarLayoutParams()}>
        <XAxis dataKey="name" />
        <YAxis tickFormatter={this.props.yAxisTickFormatter} />
        <Legend verticalAlign="top" align="right" iconType="circle" height={30} onClick={this.handleLegendOnClick} />
        <CartesianGrid />
        {this.props.bars.map((bar: GraphBar, index: number) => {
          const defaultFill = bar.fill; // used for the legend as well
          return (
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            <Bar
              name={bar.name}
              key={bar.key}
              stackId={this.getStackId(bar)}
              dataKey={this.state.selectedBar === null || this.state.selectedBar === bar.key ? bar.key : `${bar.key} `}
              fill={defaultFill}
              radius={this.getRadius(index, this.props.bars.length)}
            >
              {this.renderCells(defaultFill, bar)}
            </Bar>
          );
        })}
        <Tooltip
          content={this.props.tooltipRenderer}
          cursor={false}
          wrapperStyle={{
            background: 'white',
            border: '1px',
            borderColor: 'gray',
            borderRadius: '5px',
            padding: '10px',
            borderStyle: 'solid',
            zIndex: 5,
            minWidth: '180px',
          }}
        />
      </BarChart>
    );
  };

  render() {
    return (
      <AnalyticsGraphContainer
        chartTitle={this.props.barChartTitle}
        defaultToggleMessage={getMessageFromId('analytics-toggle-group')}
        alternativeToggleMessage={getMessageFromId('analytics-toggle-stack')}
        isLoading={this.props.isLoading}
        tooltip={this.props.graphTooltip}
        helpCenterLink={this.props.graphHelpCenterLink}
        shouldShowToggle={this.props.shouldShowToggle}
        isToggled={this.state.isStackedSelected}
        onToggleUpdated={this.handleToggleUpdated}
        onGraphResize={this.handleGraphResize}
      >
        {this.renderBarChart()}
      </AnalyticsGraphContainer>
    );
  }
}

export default AnalyticsBarChart;
