import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getLocalisedMessageFromId, getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE, GRAPH_SHADES_OF_GREEN } from 'views/analytics/utils/chartConfigs';
import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';

import style from './AnalyticsSnapDropOffGraph.scss';

import type { StorySnapMetrics } from 'types/analytics';

type Props = {
  isLoading: boolean;
  isSubscribable: boolean;
  isOnPromotionTab: boolean;
  metrics: StorySnapMetrics[];
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="top-snap-drop-off-rate-paid"
      defaultMessage="Top Snap Drop Off Rate Paid"
      description="Paid impressions top snap drop off rate"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="top-snap-drop-off-rate-organic"
      defaultMessage="Top Snap Drop Off Rate Organic"
      description="Organic impressions top snap drop off rate"
    />
  ),
  params: [],
});

export class AnalyticsSnapDropOffGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getDropOffConfig = () => {
    return this.props.isOnPromotionTab
      ? [
          {
            key: 'paidDropOffRates',
            name: getLocalisedMessageFromId(this.context, 'top-snap-drop-off-rate-paid'),
            fill: GRAPH_SHADES_OF_GREEN.MED_GREEN,
          },
          {
            key: 'organicDropOffRates',
            name: getLocalisedMessageFromId(this.context, 'top-snap-drop-off-rate-organic'),
            fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
          },
        ]
      : [
          {
            key: 'dropOffRates',
            name: getLocalisedMessageFromId(this.context, 'publisher-story-chart-exit-rate'),
            fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
          },
        ];
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, dropOffRates, snapId, mediaId, mediaType, paidDropOffRates, organicDropOffRates, mediaUrl } = payload;
    const dropOffConfig = this.getDropOffConfig();
    return (
      <AnalyticsTooltip
        title={name}
        snapId={snapId}
        mediaId={mediaId}
        mediaType={mediaType}
        generatedPreviewUrl={mediaUrl}
        metricRows={
          this.props.isOnPromotionTab
            ? [
                {
                  metricName: getLocalisedMessageFromId(this.context, 'top-snap-drop-off-rate-paid'),
                  metricValue: numberFormatter.printPercentage(paidDropOffRates),
                },
                {
                  metricName: getLocalisedMessageFromId(this.context, 'top-snap-drop-off-rate-organic'),
                  metricValue: numberFormatter.printPercentage(organicDropOffRates),
                },
              ]
            : [
                {
                  metricName: dropOffConfig?.[0]?.name,
                  metricValue: numberFormatter.printPercentage(dropOffRates),
                },
              ]
        }
      />
    );
  };

  renderGraphTooltip = () => {
    return <div className={style.withMargins}>{getMessageFromId('publisher-story-chart-exit-rate-explanation')}</div>;
  };

  getMetrics = () => {
    const { metrics, isSubscribable } = this.props;

    let barChartData = metrics;

    if (this.props.isOnPromotionTab) {
      barChartData = metrics.map(metric => {
        const paidDropOffCount =
          (metric.paidUniques && metric.paidDropOffRates && metric.paidUniques * metric.paidDropOffRates) || 0;
        const paidDropOffCountAsPercOfTotal = paidDropOffCount / metric.uniques;
        const organicDropOffRates = metric.dropOffRates - paidDropOffCountAsPercOfTotal;

        return {
          paidDropOffRates: metric.paidDropOffRates,
          organicDropOffRates,
          ...metric,
        };
      });
    }

    if (isSubscribable) {
      barChartData = barChartData?.slice(0, barChartData.length - 1);
    }

    return barChartData;
  };

  render() {
    return (
      <AnalyticsBarChart
        graphTooltip={this.renderGraphTooltip}
        graphHelpCenterLink={HelpCenterDestination.ANALYTICS_REDUCING_DROP_OFF}
        barChartData={this.getMetrics()}
        barChartTitle={getMessageFromId('publisher-story-chart-exit-rate')}
        bars={this.getDropOffConfig()}
        isLoading={this.props.isLoading}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={numberFormatter.printPercentage}
      />
    );
  }
}

export default AnalyticsSnapDropOffGraph;
