// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'd3'.... Remove this comment to see the full error message
import d3 from 'd3';
import is from 'is_js';
import log from 'loglevel';
import moment from 'moment';
import numeral from 'numeral';

export function showAsDate(d: any) {
  const date = new Date(d);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return d3.time.format('%b %d, %Y')(date);
}

export function showAsTime(d: any) {
  const date = new Date(d);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return d3.time.format('%-I:%M%p PDT')(date);
}

export function showAsDateAndTime(d: any) {
  const date = new Date(d);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return d3.time.format('%b %d, %Y %-I:%M%p PDT')(date);
}

export function prettyPrintNumber(num: any) {
  if (num === null || num === undefined) {
    log.error('Called prettyPrintNumber with non-number', num);
    return 0;
  }

  if (num < 1000) {
    return num;
  }

  // try to get 3 significant digits
  const len = num.toString().length % 3;

  if (len === 0) {
    return numeral(num).format('0a');
  }
  if (len === 1) {
    return numeral(num).format('0.00a');
  }

  return numeral(num).format('0.0a');
}

export function printDuration(durationMs: number) {
  const duration = Math.floor(durationMs / 1000);
  const sec = String(duration % 60).padStart(2, '0');
  const min = String(duration > 60 ? Math.floor(duration / 60) : 0).padStart(2, '0');
  return `${min}:${sec}`;
}

export function printSeconds(num: any) {
  if (num === 0 || is.not.number(num)) {
    return '0 secs';
  }
  if (num < 10) {
    return `${numeral(num).format('0.0')} secs`;
  }
  if (num < 60) {
    return `${numeral(num).format('00')} secs`;
  }

  const time = moment('2000-01-01 00:00:00').add(moment.duration(num, 'seconds')).format('m:ss');

  return `${time} min`;
}

/* converts millisecond input - decimals are rounded
  < 1min will display as zz secs
  > 1 min and < 1 hr will display yy:zz mins (where zz is number of seconds, so convert 3.5 min to 3:30 min)
  > 1hr < 1000hr will be displayed as xx:yy hours (where yy is number of minutes, so convert 65 min to 1:05 hrs)
  > 1000hr should just display as 1k hrs ignore min and sec, similarly for 1m hrs etc.
*/
export function formatTimeViewed(timeInMs: number = 0) {
  const timeInSecs = timeInMs / 1000;

  const seconds = Math.round(timeInSecs % 60);
  const minutes = Math.trunc(timeInSecs / 60) % 60;
  const hours = Math.trunc(timeInSecs / 3600);

  if (hours >= 1000) {
    return `${prettyPrintNumber(hours)} hrs`;
  }
  if (hours) {
    return minutes ? `${hours}:${minutes >= 10 ? '' : '0'}${minutes} hrs` : `${hours} hrs`;
  }
  if (minutes) {
    return seconds ? `${minutes}:${seconds >= 10 ? '' : '0'}${seconds} mins` : `${minutes} mins`;
  }
  return `${seconds} secs`;
}

export function printPercentage(num: number | string | null | undefined) {
  return numeral(num || 0).format('0.0%');
}

export function printPercentageGraph(num: number | string | null | undefined) {
  const input = +(num || 0);
  return `${Math.ceil(input)}%`;
}

export function printSignedPercentageRounded(num: any) {
  const sign = num >= 0 ? '+' : '-';
  return sign + numeral(Math.abs(num)).format('0%');
}

export function printRawPercentage(num: any) {
  return `${Math.abs(num).toFixed(2)}%`;
}

export function privacyNumberFormat(num = 0) {
  return is.not.number(num) || num < 30 ? '< 30' : prettyPrintNumber(num);
}

export function printMicrocentsAsDollars(num: number) {
  return printDollars(num / 1000000);
}
export function printDollars(num: number) {
  return `$${printNumberWithCommas(num.toFixed(2))}`;
}

export function convertMsToSingleTimeUnit(ms: number): string {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // We do not return intl string here because it not supported by the graph tooltip
  const remainingDays = days % 365;
  if (remainingDays > 0) {
    return `${remainingDays}d`;
  }

  const remainingHours = hours % 24;

  if (remainingHours > 0) {
    return `${remainingHours}h`;
  }

  const remainingMinutes = minutes % 60;
  return `${remainingMinutes}m`;
}

// TODO refactor print percentage remove this method
export function printAbsPercentageDecimal(num: any) {
  return numeral(Math.abs(num)).format('0.0%');
}

export function printMultiplier(num: any) {
  return `${numeral(num).format('0.0')}x`;
}

// Taken from https://stackoverflow.com/a/2901298
export function printNumberWithCommas(numberString: string | number) {
  return numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function printSecondsToTimeFormat(milliseconds: number): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}
