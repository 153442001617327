import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from '../storySnapMenuCardStyle.scss';

import { camera } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';

type Props = {
  disableRemix: boolean;
  toggleDisableRemix: () => void;
};

export default function AllowRemixCard({ disableRemix, toggleDisableRemix }: Props) {
  return (
    <div className={style.cardContainer}>
      <div className={style.cardInfo}>
        <Icon inlineIcon={camera} className={style.icon} />
        <div className={style.cardTitle}>
          <div className={style.heading}>
            <FormattedMessage
              defaultMessage="Allow Remixing"
              description="Allow other Snapchatters to remix Snap"
              id="allow-remix"
            />
          </div>

          <FormattedMessage
            defaultMessage="Allow other Snapchatters to remix this Spotlight video"
            description="Explanation of what allow remixing means to user"
            id="allow-remix-description"
          />
        </div>
      </div>
      <div className={style.toggleWrapper}>
        <SDSSwitch data-test="AllowRemixCard.toggle" onChange={toggleDisableRemix} value={!disableRemix} />
      </div>
    </div>
  );
}
