import React from 'react';

import * as pubUserManagementActions from 'state/publisherUserManagement/actions/publisherUserManagementActions';
import * as pubUserManagementSelectors from 'state/publisherUserManagement/selectors/publisherUserManagementSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { User, UserId } from 'state/user/userState';

import { intlConnect } from 'utils/connectUtils';
import { filterEmailDomain, getFilteredRolesTextForPublisherList } from 'utils/userManagementUtils';

import { Spinner, SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import UserManagement from 'views/common/components/UserManagement/UserManagement';
import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';

import style from './PublisherUserManagement.scss';

import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

export const mapStateToProps = (state: State) => {
  const isLoading = pubUserManagementSelectors.getPublisherUsersLoadingCount(state) > 0;
  const publisher = getActivePublisherDetails(state);
  return {
    publisher,
    rolesTextList: getFilteredRolesTextForPublisherList([]),
    users: pubUserManagementSelectors.getPublisherUsers(state),
    userList: pubUserManagementSelectors.getPublisherUserIdsAlphabeticalByName(state),
    isLoading,
  };
};
const mapDispatchToProps = {
  getUsers: pubUserManagementActions.getPublisherUsers,
  clearUsers: pubUserManagementActions.clearPublisherUsers,
  createUser: pubUserManagementActions.createUserAndAddPublisherRoles,
  editUser: pubUserManagementActions.editUser,
  deleteUser: pubUserManagementActions.deletePublisherUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps;
export class PublisherUserManagement extends React.Component<Props> {
  static title = 'User Management';

  static path = '/publisher/:publisherId/admin';

  UNSAFE_componentWillMount() {
    if (this.props.publisher?.id) {
      this.props.getUsers(this.props.publisher.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.publisher?.id !== nextProps.publisher?.id && nextProps.publisher?.id) {
      nextProps.clearUsers();
      nextProps.getUsers(nextProps.publisher.id);
    }
  }

  componentWillUnmount() {
    this.props.clearUsers();
  }

  createUser = (user: any) => {
    return this.props.createUser({
      businessProfileId: this.props.publisher?.businessProfileId,
      publisherId: this.props.publisher?.id,
      user,
    });
  };

  editUser = (user: User) => {
    if (this.props.publisher?.businessProfileId) {
      return this.props.editUser({ businessProfileId: this.props.publisher?.businessProfileId, user });
    }
    return null;
  };

  deleteUser = (userId: UserId) => {
    if (this.props.publisher?.id) {
      return this.props.deleteUser(this.props.publisher.id, userId);
    }
    return null;
  };

  loadingSpinner = () => {
    return (
      <div className={style.noRows}>
        <Spinner loading size={SpinnerSizes.LARGE} message={SpinnerLabels.LOADING} />
      </div>
    );
  };

  render() {
    if (this.props.isLoading || !this.props.publisher) {
      return this.loadingSpinner();
    }
    const emailDomain = filterEmailDomain(this.props.users);
    return (
      <ClaimGatedView requiredClaim={Claim.PUBLISHER_USER_MANAGER}>
        <UserManagement
          userList={this.props.userList}
          users={this.props.users}
          emailDomain={emailDomain}
          rolesTextList={(this.props as any).rolesTextList}
          editUser={this.editUser}
          createUser={this.createUser}
          deleteUser={this.deleteUser}
          publisher={this.props.publisher}
        />
      </ClaimGatedView>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(PublisherUserManagement);
