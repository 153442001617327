import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EMPTY_ARRAY } from 'config/constants';
import { help } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './AnalyticsInsightsLifestyleCategoryCarrousel.scss';

import type { LifestyleCategory } from 'types/analytics';

type Props = {
  slcs: LifestyleCategory[];
};

export class AnalyticsInsightsLifestyleCategoryCarrousel extends React.PureComponent<Props> {
  renderSLCCard(slc: LifestyleCategory) {
    return (
      <Col key={slc.value}>
        <div className={style.cardBox}>
          <div className={style.cardStyle}>
            <p>
              <span className={style.headerText}> {slc.value} </span>
            </p>
            <p>
              <span className={style.multiplierValue}>
                {getMessageFromId('analytics-insights-slc-multiplier-text', {
                  score: slc.score,
                })}
              </span>
            </p>
            <span className={style.previousDescription}>vs. snapchat average</span>
          </div>
        </div>
      </Col>
    );
  }

  renderContent() {
    // default state shows spinner
    if (this.props.slcs === EMPTY_ARRAY) {
      return (
        <span className={style.spinnerPosition}>
          <SpinnerIcon className={style.loadingIcon} />
        </span>
      );
    }

    return (
      <>
        {this.props.slcs.length ? (
          <Row type="flex" gutter={2} className={style.cardContainer}>
            {this.props.slcs.map(entry => this.renderSLCCard(entry))}
          </Row>
        ) : (
          <FormattedMessage
            id="insufficient-data-for-slc"
            description="Message to inform there is not enough data to provide SLC insights"
            defaultMessage="The audience size does not produce sufficient data for us to provide SLC insight"
          />
        )}
      </>
    );
  }

  render() {
    return (
      <div className={style.lifestyleCategoriesContainer}>
        <div className={style.cardBox}>
          <div className={style.slcCarrouselTitleContainer}>
            <span className={style.slcCarrouselTitle}>{getMessageFromId('analytics-insights-slc-title')}</span>
            <SDSTooltip
              id="tooltip-info"
              title={getMessageFromId('analytics-insights-slc-tooltip-info')}
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              placement={TooltipPosition.RIGHT}
            >
              <Icon className={style.infoIcon} inlineIcon={help} />
            </SDSTooltip>
          </div>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default AnalyticsInsightsLifestyleCategoryCarrousel;
