import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsPieChart from 'views/analytics/components/AnalyticsPieChart/AnalyticsPieChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE } from 'views/analytics/utils/chartConfigs';

import type { StoryDemographMetrics, StoryMetricResults } from 'types/analytics';

type Props = {
  isLoading: boolean;
  metrics: StoryDemographMetrics[];
  metricsV2: StoryMetricResults;
  isAnalyticsV2: boolean;
  isOnPromotionTab: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="story-gender-paid"
      defaultMessage="Story Gender (Paid)"
      description="Title for paid version of demographic pie chart"
    />
  ),
  params: [],
});

export class AnalyticsGenderGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getGenderConfigs = () => {
    const maleMessage = getLocalisedMessageFromId(this.context, 'demographics-male');
    const femaleMessage = getLocalisedMessageFromId(this.context, 'demographics-female');
    const unknownMessage = getLocalisedMessageFromId(this.context, 'demographics-unknown');

    let maleCount = 0;
    let femaleCount = 0;
    let unknownCount = 0;
    this.props.metrics?.forEach(ageGroup => {
      if (this.props.isOnPromotionTab) {
        maleCount += ageGroup.paidMale;
        femaleCount += ageGroup.paidFemale;
        unknownCount += ageGroup.paidUnknown;
      } else {
        maleCount += ageGroup.male;
        femaleCount += ageGroup.female;
        unknownCount += ageGroup.unknown;
      }
    });

    const maleFill = GRAPH_SHADES_OF_BLUE.NORMAL_BLUE;
    const femaleFill = GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE;
    const unknownFill = GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE;

    const maleConfig = { name: maleMessage, value: maleCount, fill: maleFill };
    const femaleConfig = { name: femaleMessage, value: femaleCount, fill: femaleFill };
    const unknownConfig = { name: unknownMessage, value: unknownCount, fill: unknownFill };
    return [maleConfig, femaleConfig, unknownConfig];
  };

  getGenderConfigsV2 = () => {
    const maleMessage = getLocalisedMessageFromId(this.context, 'demographics-male');
    const femaleMessage = getLocalisedMessageFromId(this.context, 'demographics-female');
    const unknownMessage = getLocalisedMessageFromId(this.context, 'demographics-unknown');

    let maleCount = 0;
    let femaleCount = 0;

    if (!this.props.isOnPromotionTab) {
      maleCount = +this.props.metricsV2?.uniqueViewersMale!;
      femaleCount = +this.props.metricsV2?.uniqueViewersFemale!;
    }

    const maleFill = GRAPH_SHADES_OF_BLUE.NORMAL_BLUE;
    const femaleFill = GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE;
    const unknownFill = GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE;

    const maleConfig = { name: maleMessage, value: maleCount, fill: maleFill };
    const femaleConfig = { name: femaleMessage, value: femaleCount, fill: femaleFill };
    const unknownConfig = { name: unknownMessage, value: 0, fill: unknownFill };

    return [maleConfig, femaleConfig, unknownConfig];
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, value } = payload;
    const metricRows = [{ metricName: name, metricValue: numberFormatter.privacyNumberFormat(value) }];
    return <AnalyticsTooltip title={name} metricRows={metricRows} />;
  };

  render() {
    const graphData = this.props.isAnalyticsV2 ? this.getGenderConfigsV2() : this.getGenderConfigs();
    return (
      <AnalyticsPieChart
        pieChartTitle={
          this.props.isOnPromotionTab ? getMessageFromId('story-gender-paid') : getMessageFromId('story-story-gender')
        }
        pies={graphData}
        isLoading={this.props.isLoading}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.renderChartTooltip}
      />
    );
  }
}

export default AnalyticsGenderGraph;
