import React from 'react';
import type { ReactNode } from 'react';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import HomepageView from 'views/homepage/containers/HomepageView/HomepageView';

type Props = {
  children?: ReactNode;
};

export const HomepageSwitch = (props: Props) => {
  const { children, ...restProps } = props;

  return <HomepageView {...restProps}>{children}</HomepageView>;
};

export default intlConnect((state: State) => ({}), null)(HomepageSwitch);
