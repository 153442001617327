import React from 'react';

import { getMetricsAndKPIs } from 'views/analytics/utils/analyticsUtil';
import KPIWidget from 'views/common/components/KPIWidget/KPIWidget';
import type { KPIMetricInput } from 'views/common/components/KPIWidget/KPIWidget';

import style from './AnalyticsDailyKPIs.scss';

import type { Kpis } from 'types/analytics';

type Props = {
  isLoading: boolean;
  startDate: string | undefined | null;
  endDate: string | undefined | null;
  minDate: boolean;
  previousRange: Kpis;
  totalDays: number;
  currentRange: Kpis;
};

export class AnalyticsDailyKPIs extends React.PureComponent<Props> {
  renderKPIWidget = (metric: KPIMetricInput, value?: number | null, previousValue?: number | null) => {
    const containerStyle = style.kpiWidgetContainer;
    return (
      <div className={containerStyle} key={`${metric.metricId}Column`}>
        <KPIWidget
          metric={metric}
          previousValue={previousValue}
          value={value}
          isLoading={this.props.isLoading && value === null}
        />
      </div>
    );
  };

  render() {
    const metricsAndKPIs = getMetricsAndKPIs(this.props);
    return metricsAndKPIs.map(({ metric, kpiCurrentValue, kpiPreviousValue }) =>
      this.renderKPIWidget(metric, kpiCurrentValue, kpiPreviousValue)
    );
  }
}

export default AnalyticsDailyKPIs;
