// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Col } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import { camelCase } from 'lodash';
import React, { useCallback, useEffect } from 'react';

import {
  getEditionDateRange,
  getEditionUniqueDau,
  getEditionSubscriberCount,
} from 'state/analytics/selectors/analyticsSelectors';
import {
  getActivePublisherBusinessProfileId,
  getActivePublisherDetails,
  getActivePublisherOrgId,
} from 'state/publishers/selectors/publishersSelectors';
import { getSelectedAdAccountId } from 'state/user/selectors/userSelectors';

import { intlConnect } from 'utils/connectUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';

import {
  PAY_TO_PROMOTE_KPI_CONFIG_ADS_MANAGER,
  PAY_TO_PROMOTE_KPI_CONFIG_STORY_STUDIO,
  PAY_TO_PROMOTE_MAX_VALUE_CONFIG,
} from 'views/analytics/utils/kpiConfigs';
import KPIWidget from 'views/common/components/KPIWidget/KPIWidget';
import { useProfileStoryAdsStatsQuery } from 'views/payToPromote/hooks/useProfileStoryAdsStatsQuery/useProfileStoryAdsStatsQuery';

import { StoryMetricKPIPaid } from 'types/analytics';
import { EditionID } from 'types/editionID';
import { State as RootState } from 'types/rootState';

export const mapStateToProps = (state: RootState) => {
  const activePublisher = getActivePublisherDetails(state);
  return {
    bizProfileId: getActivePublisherBusinessProfileId(state),
    orgId: getActivePublisherOrgId(state),
    activePublisher,
    editionDateRange: getEditionDateRange(state),
    editionDau: getEditionUniqueDau(state),
    editionSubscriberCount: getEditionSubscriberCount(state),
    selectedAdAccountId: getSelectedAdAccountId(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  storyId: EditionID;
  isStoryPromoted: boolean;
  isAnalyticsV2: boolean;
  storyAnalytics: StoryMetricKPIPaid;
};

type Props = StateProps & OwnProps;

export function AnalyticsPayToPromoteKPIs({
  storyId,
  orgId,
  bizProfileId,
  activePublisher,
  editionDateRange,
  editionDau,
  editionSubscriberCount,
  isStoryPromoted,
  selectedAdAccountId,
  storyAnalytics,
  isAnalyticsV2,
}: Props) {
  const uniqueDau = 'uniqueDau';
  const publisherSubscribeCount = 'publisherSubscribeCount';

  const { adsStats, profileStoryAdsStatsError, loading } = useProfileStoryAdsStatsQuery(
    storyId,
    orgId,
    bizProfileId,
    selectedAdAccountId,
    editionDateRange,
    isStoryPromoted
  );

  const getKPIConfig = useCallback(() => {
    if (profileStoryAdsStatsError || !adsStats?.length) {
      return PAY_TO_PROMOTE_KPI_CONFIG_STORY_STUDIO;
    }

    return [...PAY_TO_PROMOTE_KPI_CONFIG_STORY_STUDIO, ...PAY_TO_PROMOTE_KPI_CONFIG_ADS_MANAGER];
  }, [adsStats?.length, profileStoryAdsStatsError]);

  const getAdsStatsValue = useCallback(
    (metricId: string) => {
      if (adsStats) {
        return parseFloat(adsStats?.find(adStat => camelCase(adStat?.fieldName) === metricId)?.value) || 0;
      }
      return 0;
    },
    [adsStats]
  );

  const getTotalUniqueDAUValue = useCallback(() => {
    let editionUniqueDau = '0';
    if (isAnalyticsV2 && storyAnalytics) {
      editionUniqueDau = storyAnalytics?.uniqueDau || '0';
    }

    if (!isAnalyticsV2 && editionDau) {
      editionUniqueDau = editionDau?.uniqueDau || '0';
    }
    return parseInt(editionUniqueDau, 10);
  }, [editionDau, isAnalyticsV2, storyAnalytics]);

  const getTotalSubscriberCountValue = useCallback(() => {
    let subcriberCount = '0';
    if (isAnalyticsV2 && storyAnalytics) {
      subcriberCount = storyAnalytics?.publisherSubscribeCount || '0';
    }

    if (!isAnalyticsV2 && editionSubscriberCount) {
      subcriberCount = editionSubscriberCount?.subscriberCount || '0';
    }
    return parseInt(subcriberCount, 10);
  }, [editionSubscriberCount, isAnalyticsV2, storyAnalytics]);

  const getPaidUniqueDAUValue = () => {
    if (isAnalyticsV2 && storyAnalytics) {
      return storyAnalytics?.uniqueDauPaid || '0';
    }

    return editionDau?.paidUniqueDau || '0';
  };

  const getSubscriberCountValue = () => {
    if (isAnalyticsV2 && storyAnalytics) {
      return storyAnalytics?.publisherSubscribeCountPaid || '0';
    }

    return editionSubscriberCount?.paidSubscriberCount || '0';
  };

  const getMaxValueConfig = useCallback(
    (metricId: string) => {
      if (metricId === uniqueDau) {
        return {
          ...PAY_TO_PROMOTE_MAX_VALUE_CONFIG.paidUniqueDau,
          value: parseInt(getPaidUniqueDAUValue(), 10),
        };
      }

      if (metricId === publisherSubscribeCount) {
        return {
          ...PAY_TO_PROMOTE_MAX_VALUE_CONFIG.paidSubscriberCount,
          value: parseInt(getSubscriberCountValue(), 10),
        };
      }

      return null;
    },
    // To prevent rendering we need to exclude functions from deps check
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editionDau.paidUniqueDau, editionSubscriberCount.paidSubscriberCount]
  );

  const kpiWidgetValue = useCallback(
    (metricId: string) => {
      if (metricId === uniqueDau) {
        return getTotalUniqueDAUValue();
      }

      if (metricId === publisherSubscribeCount) {
        return getTotalSubscriberCountValue();
      }

      return getAdsStatsValue(metricId);
    },
    [getTotalUniqueDAUValue, getTotalSubscriberCountValue, getAdsStatsValue]
  );

  // metrics
  useEffect(() => {
    if (profileStoryAdsStatsError) {
      incrementCounterByPublisher(activePublisher, 'p2p.error.profileStoryAdsStatsQueryError', undefined);
    } else if (!adsStats?.length && !loading) {
      incrementCounterByPublisher(activePublisher, 'p2p.error.profileStoryAdsStatsQueryEmptyStatsList', undefined);
    }

    // Excluding the `activePublisher` from deps check to not cause refetch if object changes, bizProfileId is enough
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, bizProfileId, storyId, profileStoryAdsStatsError, adsStats]);

  return (
    <>
      {getKPIConfig().map(kpi => {
        return (
          <Col xs={12} md={6} lg={3} key={`${kpi.metricId}Column`} data-test={`${kpi.metricId}Column`}>
            <KPIWidget
              data-test={`${kpi.metricId}.widget`}
              metric={kpi}
              value={kpiWidgetValue(kpi.metricId)}
              isLoading={false}
              maxValue={getMaxValueConfig(kpi.metricId)}
            />
          </Col>
        );
      })}
    </>
  );
}
export default intlConnect(mapStateToProps, null)(AnalyticsPayToPromoteKPIs);
