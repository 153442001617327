import type { ColProps } from 'antd/lib/col';
import Col from 'antd/lib/col';
import classNames from 'classnames';
import React, { memo } from 'react';

import style from './SDSColumn.scss';

type Props = ColProps & { isClickable?: boolean };

function SDSColumn(props: Props) {
  return <Col className={classNames({ [style.clickableCol]: props.isClickable })} {...props} />;
}

export default memo(SDSColumn);
