import React from 'react';

import { getSnapPreviewById } from 'state/previews/selectors/previewsSelectors';

import { intlConnect } from 'utils/connectUtils';

import { Spinner, SpinnerSizes } from 'views/common/components/Spinner/Spinner';

import style from './AnalyticsChapterPreview.scss';

import { EditionID } from 'types/editionID';
import { State } from 'types/rootState';

type OwnProps = {
  storyId: EditionID;
  chapterId: number | string;
};
const mapStateToProps = (state: State, props: OwnProps) => {
  const snapId = `${props.storyId}-${props.chapterId}`;
  return {
    previewUrl: getSnapPreviewById(state)(snapId),
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

export function AnalyticsChapterPreview({ previewUrl }: Props) {
  if (!previewUrl) {
    return (
      <div className={style.previewContainer}>
        <Spinner loading size={SpinnerSizes.LARGE} />
      </div>
    );
  }
  return (
    <div className={style.previewContainer}>
      <img src={previewUrl} alt="Chapter Preview" data-test="AnalyticsChapterPreview.Image" />
    </div>
  );
}

export default intlConnect(mapStateToProps, {})(AnalyticsChapterPreview);
