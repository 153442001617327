import _ from 'lodash';
import React from 'react';

import { Manager, MANAGERS_BY_EMAIL, MANAGERS_LIST } from 'constants/managers';

import { Props as DropdownProps, SDSDropdown } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';

type OwnProps = {
  managerEmail: string;
  onManagerChange: (value: Manager) => void;
};

type Props = OwnProps & Omit<DropdownProps, 'value' | 'onChange' | 'children'>;

export default class ManagerDropdown extends React.PureComponent<Props> {
  handleOnChange = (email: string) => {
    const manager = MANAGERS_BY_EMAIL.get(email);
    if (manager) {
      this.props.onManagerChange(manager);
    }
  };

  render() {
    return (
      <SDSDropdown
        value={this.props.managerEmail}
        onChange={this.handleOnChange}
        {..._.omit(this.props, ['manager', 'onManagerChange', 'value', 'onChange'])}
      >
        {createSDSDropdownOptions(
          MANAGERS_LIST.map(manager => ({
            label: manager.name,
            value: manager.email,
          }))
        )}
      </SDSDropdown>
    );
  }
}
