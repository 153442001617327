// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
// discover-cms/no-snapnet
import { Grid } from '@snapchat/snapnet';
import classNames from 'classnames';
import { filter, find, flatten, get, some, uniq, without } from 'lodash';
import moment from 'moment-timezone';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Column, InfiniteLoader } from 'react-virtualized';

import { getAuthType } from 'state/auth/selectors/authSelectors';
import * as editionsActions from 'state/editions/actions/editionsActions';
import { getSimplifiedCheetahEditionState, isAvailable } from 'state/editions/schema/editionEntityHelpers';
import { getStoriesForPublisherId } from 'state/editions/selectors/editionsSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import {
  isSeasonsEpisodesDisabled,
  isSingleAssetStoryEditorEnabled,
  isSpotlightManagementEnabled,
  isSpotlightSchedulingEnabled,
} from 'state/features/selectors/featuresSelectors';
import * as homepageActions from 'state/homepage/actions/homepageActions';
import type { HomepageSortByEnum } from 'state/homepage/selectors/homepageSelectors';
import * as homepageSelectors from 'state/homepage/selectors/homepageSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import * as publisherToolsActions from 'state/publisherTools/actions/publisherToolsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { GoToPublisherEditorLink } from 'state/router/links/routerLinks';
import * as showsActions from 'state/shows/actions/showsActions';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import HomepageTabs, { HomepageTabsEnum } from '../../components/HomepageTabs/HomepageTabs';
import SpotlightHomepage from '../SpotlightHomepage/SpotlightHomepage';

import type { SortDirectionEnum, StoryStatusEnum } from 'config/constants';
import { AuthType, SimplifiedCheetahEditionState, SortDirection, StoryStatus } from 'config/constants';
import { availableStory, deactivate, plus, link } from 'icons/SDS/allIcons';
import tooltipStyle from 'styles/tooltip.scss';
import * as creativeSuiteAPI from 'utils/apis/creativeSuiteAPI';
import { intlConnect } from 'utils/connectUtils';
import * as dateUtils from 'utils/dateUtils';
import { functionRef } from 'utils/functionUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as locationUtils from 'utils/locationUtils';
import * as assetUtils from 'utils/media/assetUtils';
import { ModalType } from 'utils/modalConfig';
import { isStoryPromoted } from 'utils/payToPromoteUtils';
import { isWasmEnabled } from 'utils/platform/platformUtils';
import { withRouter } from 'utils/routerUtils';
import { buildBitmojiImageUrl } from 'utils/tileUtils';
import { guessTimezone } from 'utils/timezoneUtils';

import BadgesContainer, { BadgesAlignmentType } from 'views/badges/containers/BadgesContainer/BadgesContainer';
import CameoTilePreview from 'views/common/components/CameoTilePreview/CameoTilePreview';
import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import ImageWithPlaceholder from 'views/common/components/ImageWithPlaceholder/ImageWithPlaceholder';
import MultiSelectPopover from 'views/common/components/MultiSelectPopover/MultiSelectPopover';
import RelativeTime from 'views/common/components/RelativeTime/RelativeTime';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import type { CheckboxEvent } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { Spinner, SpinnerLabels } from 'views/common/components/Spinner/Spinner';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import TableWithCustomFilter from 'views/common/components/TableWithCustomFilter/TableWithCustomFilter';
import PageHeaderWithLeftMiddleRightElements from 'views/common/containers/PageHeaderWithLeftMiddleRightElements/PageHeaderWithLeftMiddleRightElements';
import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';
import AnalyticsButton from 'views/homepage/components/AnalyticsButton/AnalyticsButton';
import HomepageCellText from 'views/homepage/components/HomepageCellText/HomepageCellText';
import HomepageSearchBox from 'views/homepage/components/HomepageSearchBox/HomepageSearchBox';
import StoryPageCheckbox from 'views/homepage/components/StoryPageCheckbox/StoryPageCheckbox';
import { StoryStatusLabel } from 'views/homepage/components/StoryStatusLabel/StoryStatusLabel';
import UnavailableButton from 'views/homepage/components/UnavailableButton/UnavailableButton';
import AvailabilityTime from 'views/homepage/containers/AvailabilityTime/AvailabilityTime';
import CalendarButton from 'views/homepage/containers/CalendarButton/CalendarButton';
import { STORY_STATUS_DROPDOWN_OPTION } from 'views/homepage/containers/SpotlightHomepage/StoryStatus';
import { AlertOptions } from 'views/modals/containers/AlertModal/AlertModal';
import PayToPromoteButton from 'views/payToPromote/components/PayToPromoteButton/PayToPromoteButton';
import withProfileAdAccountsQuery from 'views/payToPromote/hocs/withProfileAdAccountsQuery/withProfileAdAccountsQuery';
import { ProfileAdAccountsQueryResult } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';
import PublishStoryButton from 'views/publisherStoryEditor/components/PublishStoryButton/PublishStoryButton';

import style from './HomepageView.scss';

import type { EditionID } from 'types/editionID';
import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';
import { Claim, SecureAction } from 'types/permissions';
import type { Publisher, PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';
import { PostingStoryType } from 'types/storySnapEditor';
import type { Tile } from 'types/tiles';

// Very big number - If the data goes pass that number, infinite scroll will stop polling
const STORY_COUNT = 10000000;
const MAX_SELECTED_STORY_COUNT = 10;

const mapStateToProps = (state: State) => {
  const publisher = publishersSelectors.getActivePublisherDetails(state);
  const isShow = publishersSelectors.activePublisherIsShow(state);
  const shows = publisher && showsSelectors.getShows(state)(publisher.id);
  const hasActiveShow = shows && shows.length > 0;
  const authType = getAuthType(state);
  const isSnapSSO = authType === AuthType.SNAPCHAT;
  return {
    businessProfileId: publisher && publisher.businessProfileId,
    endDate: homepageSelectors.getEndDate(state),
    hasActiveShow,
    hasLocallyStoredStories: publisher ? getStoriesForPublisherId(state)(publisher.id).length > 0 : false,
    isActiveCreatorPublisher: userSelectors.isActiveCreatorPublisher(state),
    isLoadingMorePages: homepageSelectors.getLoadingMorePages(state),
    isLoadingNewSearchCriteria: homepageSelectors.getLoadingNewSearchCriteria(state),
    isPublishingEnabled: publisher && publisher.publishingEnabled,
    isShow,
    isSeasonsEpisodesDisabled: isSeasonsEpisodesDisabled(state),
    isSingleAssetEnabled: isSingleAssetStoryEditorEnabled(state),
    isSpotlightManagementEnabled: isSpotlightManagementEnabled(state),
    isSpotlightSchedulingEnabled: isSpotlightSchedulingEnabled(state),
    lastBatchSize: homepageSelectors.getLastBatchSize(state),
    publisherId: publisher && publisher.id,
    titleSearch: homepageSelectors.getTitleSearch(state),
    selectedStates: homepageSelectors.getSelectedStates(state),
    sortBy: homepageSelectors.getSortBy(state),
    sortDirection: homepageSelectors.getSortDirection(state),
    startDate: homepageSelectors.getStartDate(state),
    stories: homepageSelectors.getOrderedStories(state),
    isStoryCreator: userSelectors.hasClaimForActivePublisher(state, Claim.STORY_CREATOR),
    isSnapSSO,
    isNewContentDisabled:
      !isWasmEnabled() ||
      (!userSelectors.hasClaimForActivePublisher(state, Claim.STORY_CREATOR) &&
        !userSelectors.hasSecureActionForActiveCreator(state, SecureAction.POST_TO_SPOTLIGHT)),
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
    activePublisherId: publishersSelectors.getActivePublisherId(state),
    activeCreatorHostUsername: userSelectors.getActiveCreatorHostUsername(state),
    isSuperAdmin: userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    isMakeAllStoriesUnavailableEnabled: featuresSelectors.isMakeAllStoriesUnavailableEnabled(state),
    isNewsFeedViewer:
      userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER) &&
      userSelectors.hasClaimForActivePublisher(state, Claim.HAPPENING_NOW_FEEDS_VIEWER),
    isNewsFeedsEnabled: featuresSelectors.isHappeningNowFeedsEnabled(state),
  };
};
const mapDispatchToProps = {
  getDisplayStories: homepageActions.getDisplayStories,
  goToStoryAnalytics: routerActions.goToStoryAnalytics,
  goToPublisherStoryEditor: routerActions.goToPublisherStoryEditor,
  goToShows: routerActions.goToShows,
  goToSettings: routerActions.goToSettings,
  goToSpotlightUploader: routerActions.goToSpotlightUploader,
  makeStoriesUnavailable: editionsActions.makeStoriesUnavailable,
  makeStoriesAvailable: editionsActions.makeStoriesAvailable,
  createEpisodePrompt: functionRef(publisherToolsActions, 'createEpisodePrompt'),
  createStoryPrompt: functionRef(publisherToolsActions, 'createStoryPrompt'),
  makeUnselectedStoriesUnavailable: editionsActions.makeUnselectedStoriesUnavailable,
  showModal,
  loadShows: showsActions.loadShows,
};

enum MessageId {
  DRAFT = 'draft-status',
  PUBLIC_ARCHIVE = 'story-state-public-archive',
  PRIVATE_ARCHIVE = 'story-state-private-archive',
  SCHEDULED = 'scheduled-status',
  LIVE = 'live-status',
  ENDED = 'ended',
}

const statusIdToMessageId: { [key in StoryState]: MessageId | null } = {
  [StoryState.NEW]: MessageId.DRAFT,
  [StoryState.IN_PROGRESS]: MessageId.DRAFT,
  [StoryState.READY]: MessageId.DRAFT,
  [StoryState.ARCHIVED]: MessageId.PUBLIC_ARCHIVE,
  [StoryState.HIDDEN]: MessageId.PRIVATE_ARCHIVE,
  [StoryState.SCHEDULED]: MessageId.SCHEDULED,
  [StoryState.SCHEDULED_PENDING_APPROVAL]: MessageId.SCHEDULED,
  [StoryState.PENDING_APPROVAL]: MessageId.SCHEDULED,
  [StoryState.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL]: MessageId.SCHEDULED,
  [StoryState.LIVE_EDIT_PENDING_APPROVAL]: MessageId.SCHEDULED,
  [StoryState.LIVE]: MessageId.LIVE,
  [StoryState.PUBLISHING]: MessageId.SCHEDULED,
  [StoryState.UNPUBLISHING]: MessageId.PUBLIC_ARCHIVE,
  [StoryState.DELETED]: null,
  [StoryState.LIVE]: MessageId.PUBLIC_ARCHIVE,
  [StoryState.BIB_REJECTED]: null,
  [StoryState.BIB_REVISION_NEEDED]: null,
};
const messageIdsToServerEditionState = {
  [MessageId.DRAFT]: [StoryState.NEW],
  [MessageId.PUBLIC_ARCHIVE]: [StoryState.ARCHIVED],
  [MessageId.PRIVATE_ARCHIVE]: [StoryState.HIDDEN],
  [MessageId.SCHEDULED]: [StoryState.SCHEDULED],
  [MessageId.LIVE]: [StoryState.LIVE],
  [MessageId.ENDED]: [StoryState.ARCHIVED],
  [MessageId.PUBLIC_ARCHIVE]: [StoryState.ARCHIVED, StoryState.LIVE],
};
const checkboxDisableReason = {
  NONE: 'NONE',
  STORY_IS_INCOMPLETE: 'SNAP_IS_INCOMPLETE',
  STORY_SELECT_LIMIT: 'STORY_SELECT_LIMIT',
  NOT_SAME_STATUS: 'NOT_SAME_STATUS',
};
const checkboxDisableReasonMessage = {
  [checkboxDisableReason.NONE]: null,
  [checkboxDisableReason.STORY_IS_INCOMPLETE]: getMessageFromId('incomplete-story-disable-message'),
  [checkboxDisableReason.STORY_SELECT_LIMIT]: getMessageFromId('selection-limit-disable-message', {
    limit: MAX_SELECTED_STORY_COUNT,
  }),
  [checkboxDisableReason.NOT_SAME_STATUS]: getMessageFromId('incorrect-status-disable-message'),
};
type RowData = {
  index: number;
  tile?: Tile;
  story: Edition;
  storyBuildStatus: StoryStatusEnum;
};
type DispatchProps = {
  getDisplayStories: typeof homepageActions.getDisplayStories;
  goToStoryAnalytics: typeof routerActions.goToStoryAnalytics;
  goToPublisherStoryEditor: typeof routerActions.goToPublisherStoryEditor;
  goToShows: typeof routerActions.goToShows;
  goToSettings: typeof routerActions.goToSettings;
  goToSpotlightUploader: typeof routerActions.goToSpotlightUploader;
  makeStoriesUnavailable: (storyIds: EditionID[]) => Promise<unknown>;
  makeStoriesAvailable: (storyIds: EditionID[], isFromHomepage: boolean) => Promise<unknown>;
  makeUnselectedStoriesUnavailable: typeof editionsActions.makeUnselectedStoriesUnavailable;
  loadShows: typeof showsActions.loadShows;
  showModal: typeof showModal;
};
type StateProps = {
  businessProfileId: string;
  publisherId: PublisherID | undefined | null;
  isActiveCreatorPublisher: boolean;
  isPublishingEnabled: boolean;
  stories: {
    story: Edition;
  }[];
  isLoadingNewSearchCriteria: boolean;
  isLoadingMorePages: boolean;
  sortBy: HomepageSortByEnum;
  sortDirection: SortDirectionEnum;
  titleSearch: string;
  startDate: number;
  endDate: number;
  lastBatchSize: number;
  selectedStates: StoryState[];
  hasActiveShow: boolean;
  hasLocallyStoredStories: boolean;
  isNewsFeedViewer: boolean;
  isShow: boolean;
  isSeasonsEpisodesDisabled: boolean;
  isSingleAssetEnabled: boolean;
  isSpotlightManagementEnabled: boolean;
  isSpotlightSchedulingEnabled: boolean;
  isStoryCreator: boolean;
  isSnapSSO: boolean;
  isNewContentDisabled: boolean;
  activePublisher: Publisher | null;
  createEpisodePrompt: (a: { publisherId: number; businessProfileId: string }) => void;
  activePublisherId: PublisherID | undefined | null;
  activeCreatorHostUsername: string | null;
  createStoryPrompt: (params: {
    publisherId: PublisherID;
    postingStoryType?: PostingStoryType;
    numberOfSnaps?: number;
  }) => void;
  isSuperAdmin: boolean;
  isMakeAllStoriesUnavailableEnabled: boolean;
  isNewsFeedsEnabled: boolean;
};

type OwnProps = ProfileAdAccountsQueryResult;
type Props = StateProps & DispatchProps & OwnProps;
type DisplayedStoriesParams = {
  selectedStates?: StoryState[];
  sortBy?: HomepageSortByEnum;
  sortDirection?: SortDirectionEnum;
  batchSize?: number;
  titleSearch?: string;
  keepExistingStories?: boolean;
};
type StateProp = {
  selectedStoryIds: EditionID[];
  hoveredRow: number | undefined | null;
  selectedTabIndex: number;
  spotlightStoryStatus: string | undefined | null;
};
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="homepage-no-stories"
      description="Shown when there are no stories to be shown"
      defaultMessage="You have no stories, click 'New' to start"
    />
  ),
  params: [],
});

export class HomepageView extends React.Component<Props, StateProp> {
  static toggleIcons = {
    true: <div className={`icon icon-filter ${style.filterSelected}`} />,
    false: <div className="icon icon-filter" />,
  };

  state = {
    selectedStoryIds: [],
    hoveredRow: null,
    selectedTabIndex: 0, // Default to the first tab
    spotlightStoryStatus: '',
  };

  componentDidMount() {
    if (this.props.publisherId && this.props.hasActiveShow) {
      this.props.loadShows(this.props.publisherId, this.props.businessProfileId);
    }
  }

  isSpotlightEnabledAndSelected = () => {
    // UGC creators only have access to spotlight. Default spotlight tab when a creator is not a publisher
    return (
      !this.props.isActiveCreatorPublisher ||
      (this.props.isSpotlightManagementEnabled && this.state.selectedTabIndex === HomepageTabsEnum.SPOTLIGHTS)
    );
  };

  getDisplayStories = (
    {
      selectedStates = this.props.selectedStates,
      sortBy = this.props.sortBy,
      sortDirection = this.props.sortDirection,
      batchSize = this.props.lastBatchSize,
      titleSearch = this.props.titleSearch,
      keepExistingStories = false,
    }: DisplayedStoriesParams,
    startDate: number | undefined | null = this.props.startDate,
    endDate: number | undefined | null = this.props.endDate
  ) => {
    const fetchOptions = {
      sortBy,
      sortDirection,
      state: selectedStates,
      titleSearch,
      startDate,
      endDate,
    };
    // We can't filter by date while ordering by created at, so set the dates to be null
    if (sortBy === homepageSelectors.HomepageSortBy.CREATED_AT && (startDate !== null || endDate !== null)) {
      if (this.props.startDate !== startDate || this.props.endDate !== endDate) {
        // The user just updated the dates, so change the sort by option
        fetchOptions.sortBy = homepageSelectors.HomepageSortBy.START_DATE;
      } else {
        // The user just updated the sort by method, so remove date filtering
        fetchOptions.startDate = null;
        fetchOptions.endDate = null;
      }
    }
    return this.props.getDisplayStories({
      publisherId: this.props.publisherId,
      fetchOptions,
      batchSize,
      keepExistingStories,
    });
  };

  getRowStyle = ({ index }: RowData) => {
    const rowStyle = this.isLoadingRow(index) || index < 0 ? style.headerRow : style.tableRow;
    if (get(this.props.stories[index], 'story.state') === StoryState.LIVE) {
      return classNames(rowStyle, style.tableRowEmphasis);
    }
    return rowStyle;
  };

  getCheckboxDisableReason = (rowData: RowData) => {
    if (!isAvailable(rowData.story) && this.buildStatusIsIncomplete(rowData.storyBuildStatus)) {
      return checkboxDisableReason.STORY_IS_INCOMPLETE;
    }
    const { selectedStoryIds } = this.state;
    if (selectedStoryIds.length >= MAX_SELECTED_STORY_COUNT) {
      return checkboxDisableReason.STORY_SELECT_LIMIT;
    }
    if (selectedStoryIds.length > 0) {
      if (getSimplifiedCheetahEditionState(rowData.story) !== this.currentSelectedEditionState()) {
        return checkboxDisableReason.NOT_SAME_STATUS;
      }
    }
    return checkboxDisableReason.NONE;
  };

  getStatusIdToMessageId = () => {
    return statusIdToMessageId;
  };

  currentSelectedEditionState = () => {
    const { selectedStoryIds } = this.state;
    if (selectedStoryIds.length === 0) {
      return null;
    }
    const firstSelectedStoryData = find(this.props.stories, storyData => storyData.story.id === selectedStoryIds[0]);
    const state = getSimplifiedCheetahEditionState(get(firstSelectedStoryData, 'story'));
    return state;
  };

  buildStatusIsIncomplete = (buildStatus: StoryStatusEnum) => {
    return buildStatus && buildStatus !== StoryStatus.PUBLISHABLE;
  };

  isRowLoaded = ({ index }: RowData) => {
    return index < this.props.stories.length;
  };

  loadMoreRows = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
    // Virtualized may suggest loading more rows if the user scrolls faster
    // However, we don't want to make requests for just 1 or 2 items either
    const suggestedBatchSize = stopIndex - startIndex;
    const batchSize =
      suggestedBatchSize > homepageActions.DEFAULT_BATCH_SIZE ? suggestedBatchSize : homepageActions.DEFAULT_BATCH_SIZE;
    return this.getDisplayStories({ batchSize, keepExistingStories: true });
  };

  editionStateToMessageId = (state: StoryState) => this.getStatusIdToMessageId()[state];

  editionStatesToMessageIds = (states: StoryState[]) => uniq(states.map(state => this.editionStateToMessageId(state)));

  messageIdToServerEditionStates = (messageId: MessageId) => {
    return messageIdsToServerEditionState[messageId] || [];
  };

  rowGetter = ({ index }: { index: number }) => {
    if (this.isLoadingRow(index)) {
      return { story: null };
    }
    return this.props.stories[index];
  };

  /*
      GO TO ACTIONS
    */
  goToPublisherStoryEditor = ({ index }: RowData) => {
    const data = this.rowGetter({ index });
    if (this.isLoadingRow(index) || !data) {
      return;
    }
    const { story } = data;
    const storyId = story?.id;
    const { publisherId } = this.props;
    this.props.goToPublisherStoryEditor({ publisherId, editionId: storyId, overwriteHistory: false });
  };

  goToAnalyticsPageForStory = ({ event, storyId }: { event: Event; storyId: EditionID }) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const { publisherId } = this.props;
    this.props.goToStoryAnalytics({ publisherId, editionId: storyId });
  };

  isLoadingRow = (index: number) => {
    return this.props.isLoadingMorePages && index >= this.props.stories.length;
  };

  /**
   * TABLE HEADERS LOGIC
   */
  storyNameHeaderRenderer = () => {
    const numSelectedStories = this.state.selectedStoryIds.length;
    if (numSelectedStories === 0) {
      return (
        <FormattedMessage
          id="home-page-story-name-column-header"
          description="Column Header with label Story on homepage table"
          defaultMessage="Story Name"
        />
      );
    }
    if (numSelectedStories === 1) {
      return (
        <FormattedMessage
          id="single-story-selected-header"
          description="Column header for when a single story has been selected"
          defaultMessage="1 story selected"
        />
      );
    }
    return (
      <FormattedMessage
        id="multiple-stories-selected-column-header"
        description="Column header for when more than one story has been selected"
        defaultMessage="{numSelectedStories} stories selected"
        values={{ numSelectedStories }}
      />
    );
  };

  arrowRenderer = (arrowField: any) => {
    const selectedStyling = arrowField === this.props.sortBy ? style.filterSelected : '';
    if (this.props.sortDirection === SortDirection.ASCENDING) {
      return <div className={`icon icon-arrow-up ${selectedStyling}`} data-test="homepageView.arrowUp" />;
    }
    return <div className={`icon icon-arrow-down ${selectedStyling}`} data-test="homepageView.arrowDown" />;
  };

  updatedSortingDirection = () => {
    return this.props.sortDirection === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
  };

  changeSelectedStates = (changedStatesMsgIds: [MessageId]) => {
    // convert back to EditionStates
    const selectedStates = flatten(
      changedStatesMsgIds.map(messageId => this.messageIdToServerEditionStates(messageId))
    );
    this.getDisplayStories({ selectedStates });
  };

  stateHeaderRenderer = () => {
    const multiSelectStyle = classNames(style.popoverHeader, style.multiSelectPopover);
    return (
      <div className={style.headerStyle} data-test="homepageView.status">
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <MultiSelectPopover
          selectedItems={this.editionStatesToMessageIds(this.props.selectedStates)}
          allValues={this.editionStatesToMessageIds(
            without(
              Object.values(StoryState),
              StoryState.DELETED,
              StoryState.BIB_REJECTED,
              StoryState.BIB_REVISION_NEEDED
            )
          )}
          style={multiSelectStyle}
          statusLabelId="status-column-header"
          hideSelectedItems
          id="status"
          onChange={this.changeSelectedStates}
          toggleIcons={HomepageView.toggleIcons}
          isUsingMessageIds
          controlled
        />
      </div>
    );
  };

  handleCreatedAtHeaderClick = () => {
    this.getDisplayStories({
      sortBy: homepageSelectors.HomepageSortBy.CREATED_AT,
      sortDirection: this.updatedSortingDirection(),
    });
  };

  createdAtHeaderRenderer = () => {
    const createdAtHeader = (
      <div className={style.liveHeader} onClick={this.handleCreatedAtHeaderClick} data-test="homepageView.created">
        <div className={style.liveMessage}>
          <FormattedMessage
            id="created-column-header"
            description="Column Header with label Created on homepage table"
            defaultMessage="Created"
          />
        </div>
        {this.arrowRenderer(homepageSelectors.HomepageSortBy.CREATED_AT)}
      </div>
    );
    if (!this.props.startDate) {
      return createdAtHeader;
    }
    return (
      <SDSTooltip
        title={
          <FormattedMessage
            id="created-column-tooltip"
            description="Tooltip on Created header explaing that date filter will be cleared"
            defaultMessage="Sorting by created will clear date filter"
          />
        }
        overlayClassName={classNames(tooltipStyle.tooltip, style.createdTooltip)}
      >
        {createdAtHeader}
      </SDSTooltip>
    );
  };

  handleLiveAtHeaderClick = () => {
    this.getDisplayStories({
      sortBy: homepageSelectors.HomepageSortBy.START_DATE,
      sortDirection: this.updatedSortingDirection(),
    });
  };

  liveHeaderRenderer = () => {
    return (
      <div className={style.liveHeader} onClick={this.handleLiveAtHeaderClick} data-test="homepageView.Availability">
        <div className={style.liveMessage}>
          <FormattedMessage
            id="availability-column-header"
            description="Column Header with label Availability on homepage table"
            defaultMessage="Availability"
          />
        </div>
        {this.arrowRenderer(homepageSelectors.HomepageSortBy.START_DATE)}
      </div>
    );
  };

  handleCalendarDateSelection = (from?: moment.Moment | null, to?: moment.Moment | null) => {
    this.getDisplayStories({}, from ? from.valueOf() : null, to ? to.valueOf() : null);
  };

  handleSearch = (titleSearch: string) => {
    this.getDisplayStories(
      {
        selectedStates: [],
        sortBy: homepageSelectors.HomepageSortBy.CREATED_AT,
        sortDirection: SortDirection.DESCENDING,
        titleSearch,
      },
      null,
      null
    );
  };

  /**
   * TABLE ROW CELL LOGIC
   */
  handleSelectedStory = (storyId: EditionID) => {
    const newSelectedStoryIds = [...this.state.selectedStoryIds, storyId];
    this.setState({
      selectedStoryIds: newSelectedStoryIds,
    });
  };

  handleUnselectedStory = (storyId: EditionID) => {
    const newSelectedStoryIds = filter(this.state.selectedStoryIds, id => id !== storyId);
    this.setState({
      selectedStoryIds: newSelectedStoryIds,
    });
  };

  handleCheckboxChange = (event: CheckboxEvent<void>, storyId: EditionID) => {
    if (event.target.checked) {
      this.handleSelectedStory(storyId);
    } else {
      this.handleUnselectedStory(storyId);
    }
  };

  handleGoToSeasonsClick = () => {
    this.props.goToShows({
      overwriteHistory: false,
      publisherId: this.props.publisherId,
    });
  };

  handleEpisodeCreationClick = () => {
    incrementCounterByPublisher(this.props.activePublisher, 'HomepageView.createEpisode.openCreativeSuite');

    locationUtils.openInNewWindow(
      creativeSuiteAPI.creative.createEpisode(this.props.isSnapSSO)({
        publisherId: this.props.publisherId,
      })
    );
  };

  showMakeAvailaleButton = () => {
    const currentState = this.currentSelectedEditionState();
    return (
      // We allocate episode numbers upon scheduling. When scheduling multiple stories this is non deterministic. Disable bulk make available for shows
      (currentState === SimplifiedCheetahEditionState.UNAVAILABLE ||
        currentState === SimplifiedCheetahEditionState.DRAFT) &&
      !this.props.isShow
    );
  };

  showMakeUnavailableButton = () => {
    const currentState = this.currentSelectedEditionState();
    return (
      currentState === SimplifiedCheetahEditionState.AVAILABLE ||
      currentState === SimplifiedCheetahEditionState.SCHEDULED
    );
  };

  makeSelectedStoriesAvailable = () => {
    if (this.state.selectedStoryIds.length === 0) {
      return;
    }
    this.props.makeStoriesAvailable(this.state.selectedStoryIds, true).then(() => {
      this.setState({ selectedStoryIds: [] });
    });
  };

  makeSelectedStoriesUnavailable = () => {
    if (this.state.selectedStoryIds.length === 0) {
      return;
    }
    this.props.makeStoriesUnavailable(this.state.selectedStoryIds).then(() => {
      this.setState({ selectedStoryIds: [] });
    });
  };

  renderCheckbox = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    let checkboxChecked = some(this.state.selectedStoryIds, id => id === rowData.story.id);
    const disableReason = this.getCheckboxDisableReason(rowData);
    // One of the reasons a checkbox might be disabled is if the build status is incomplete
    // The build status is populated asynchronously, there is a short period of time where a checkbox is
    // wrongly enabled, the user checks it, it gets disabled and the user is no longer able to uncheck
    // If we detect that this has happened, then uncheck the checkbox automatically
    const isIncomplete = disableReason === checkboxDisableReason.STORY_IS_INCOMPLETE;
    if (checkboxChecked && isIncomplete) {
      this.handleUnselectedStory(rowData.story.id);
      checkboxChecked = false;
    }
    // Don't disable checkboxes that are already checked
    const isDisabled = disableReason !== checkboxDisableReason.NONE && !checkboxChecked;
    const disableMessage = checkboxDisableReasonMessage[disableReason];
    return (
      <StoryPageCheckbox
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        className={(style as any).checkbox}
        onChange={this.handleCheckboxChange}
        editionId={rowData.story.id}
        disabled={isDisabled}
        disableMessage={disableMessage}
        checked={checkboxChecked}
      />
    );
  };

  renderTile = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex)) {
      return <SpinnerIcon className={style.spinnerIcon} />;
    }
    let tileRender;
    const { tile } = rowData;
    if (tile) {
      if (tile.cameoTile) {
        tileRender = <CameoTilePreview src={tile.cameoTile.previewUrl} />;
      } else {
        const bitmojiTemplateId = get(tile, 'bitmojiTileTemplateId') || null;
        const croppedImage = bitmojiTemplateId
          ? buildBitmojiImageUrl(bitmojiTemplateId)
          : assetUtils.getImagePreviewUrl(tile.croppedImageAssetId);
        tileRender = (
          <ImageWithPlaceholder
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            src={croppedImage}
            className={style.tile}
            data-test="homepageView.tile"
            showPlaceholderWhileLoading
            fadein
          >
            <div className={style.tilePlaceholder} data-test="homepageView.tileplaceholder" />
          </ImageWithPlaceholder>
        );
      }
    } else {
      tileRender = <div className={style.tilePlaceholder} />;
    }
    return this.wrapWithStoryLink(rowData)(tileRender);
  };

  renderStoryName = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex)) {
      return (
        <div className={style.loadingMessage}>
          <FormattedMessage
            id="loading-stories"
            description="Message indicating that more stories are loading"
            defaultMessage="Loading Stories..."
          />
        </div>
      );
    }
    return this.wrapWithStoryLink(rowData)(
      <HomepageCellText
        story={rowData.story}
        publisherId={this.props.publisherId}
        businessProfileId={this.props.businessProfileId}
        isShow={this.props.isShow}
        storyBuildStatus={rowData.storyBuildStatus}
        isStoryPromoted={isStoryPromoted(rowData.story.id, this.props.promotedStoriesIds)}
      />
    );
  };

  wrapWithStoryLink = (rowData: RowData) => (children: ReactNode) => {
    const { activeCreatorHostUsername, publisherId } = this.props;
    if (!publisherId) {
      return children;
    }

    return (
      <GoToPublisherEditorLink
        creatorHostUsername={activeCreatorHostUsername}
        publisherId={publisherId}
        editionId={rowData.story.id}
        overwriteHistory={false}
      >
        {children}
      </GoToPublisherEditorLink>
    );
  };

  renderBadges = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }

    return this.wrapWithStoryLink(rowData)(
      <BadgesContainer
        styleOptions={{ direction: BadgesAlignmentType.COLUMN }}
        featuresOptions={{
          isStoryPromoted: isStoryPromoted(rowData.story.id, this.props.promotedStoriesIds),
          isStorySponsored: rowData.story.isSponsored,
        }}
      />
    );
  };

  renderCreatedAtText = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    const createdAt = rowData.story.createdAt;
    return this.wrapWithStoryLink(rowData)(
      <SDSTooltip
        title={moment.tz(createdAt, guessTimezone().name).format('YYYY-MM-DD HH:mm:ss z')}
        overlayClassName={tooltipStyle.tooltip}
      >
        <RelativeTime
          baseTime={Date.parse(createdAt)}
          className={style.relativeTime}
          refreshIntervalMs={1000}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          formatTimeFunc={dateUtils.formatRelativeTime}
        />
      </SDSTooltip>
    );
  };

  renderLiveText = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    const { story } = rowData;
    return <AvailabilityTime story={story} />;
  };

  renderState = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex)) {
      return null;
    }
    return this.wrapWithStoryLink(rowData)(
      <StoryStatusLabel
        status={rowData.story.state}
        statusIdToMessageId={this.getStatusIdToMessageId()}
        isPublishingDisabled={!this.props.isPublishingEnabled}
      />
    );
  };

  renderActionButtons = ({ rowData, rowIndex }: { rowData: RowData; rowIndex: number }) => {
    if (this.isLoadingRow(rowIndex) || rowIndex !== this.state.hoveredRow) {
      // don't show the action buttons unless row is hovered
      return null;
    }
    const { story } = rowData;
    const { state } = story;
    const storyId = story.id;
    let actionButtonsToDisplay = null;
    switch (state) {
      case StoryState.NEW:
      case StoryState.IN_PROGRESS:
      case StoryState.READY:
        actionButtonsToDisplay = (
          <>
            <PublishStoryButton storyId={storyId} isHomepageButton />
          </>
        );
        break;
      case StoryState.SCHEDULED:
        actionButtonsToDisplay = (
          <>
            <UnavailableButton storyId={storyId} isHomepageButton />
          </>
        );
        break;
      case StoryState.LIVE:
      case StoryState.ARCHIVED:
        actionButtonsToDisplay = (
          <>
            <AnalyticsButton storyId={storyId} publisherId={this.props.publisherId} />
            <UnavailableButton storyId={storyId} isHomepageButton />
          </>
        );
        break;
      case StoryState.HIDDEN:
        return (
          <span className={style.actionButtons}>
            <AnalyticsButton storyId={storyId} publisherId={this.props.publisherId} />
            <PublishStoryButton storyId={storyId} isHomepageButton />
          </span>
        );
      default:
        break;
    }
    return (
      <span className={style.actionButtons}>
        <PayToPromoteButton
          buttonType={ButtonType.WHITE}
          storyId={rowData.story.id}
          error={this.props.profileAdAccountsError}
          adAccountId={this.props.adAccountId}
        />
        {actionButtonsToDisplay}
      </span>
    );
  };

  handleHoveredRow = ({ index }: RowData) => {
    this.setState({ hoveredRow: index });
  };

  hasPublisherNoStories = () => {
    const { stories } = this.props;
    return stories.length === 0 && !this.props.hasLocallyStoredStories && !this.props.isLoadingMorePages;
  };

  getHelpCenterDestination = () => {
    if (this.props.isSingleAssetEnabled) {
      return HelpCenterDestination.SINGLE_ASSET;
    }
    if (this.props.isShow) {
      return HelpCenterDestination.SHOWS;
    }
    return null;
  };

  renderStories() {
    const { stories } = this.props;
    const loadingRow = this.props.isLoadingMorePages ? 1 : 0;
    const rowCount = (stories?.length || 0) + loadingRow;
    const scrollToIndex = this.props.isLoadingNewSearchCriteria ? 0 : -1;

    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <InfiniteLoader isRowLoaded={this.isRowLoaded} loadMoreRows={this.loadMoreRows} rowCount={STORY_COUNT}>
        {({ onRowsRendered }) => (
          <TableWithCustomFilter
            ref="Table"
            rowGetter={this.rowGetter}
            rowCount={rowCount}
            rowHeight={100}
            onRowClick={this.goToPublisherStoryEditor}
            onRowMouseOver={this.handleHoveredRow}
            rowClassName={this.getRowStyle}
            onRowsRendered={onRowsRendered}
            scrollToIndex={scrollToIndex}
          >
            <Column
              style={{ marginRight: '0px' }}
              disableSort
              dataKey="select"
              width={56}
              cellRenderer={this.renderCheckbox}
            />
            <Column disableSort dataKey="tiles" width={56} cellRenderer={this.renderTile} />
            <Column
              headerRenderer={this.storyNameHeaderRenderer}
              disableSort
              dataKey="title"
              flexGrow={1}
              minWidth={280}
              width={410}
              cellRenderer={this.renderStoryName}
            />
            <Column
              className={style.badgesColumn}
              disableSort
              dataKey="badges"
              flexGrow={1}
              width={200}
              minWidth={200}
              maxWidth={250}
              cellRenderer={this.renderBadges}
            />
            <Column
              headerRenderer={this.stateHeaderRenderer}
              disableSort
              dataKey="status"
              flexGrow={1}
              width={100}
              minWidth={100}
              maxWidth={220}
              cellRenderer={this.renderState}
            />
            <Column
              headerRenderer={this.createdAtHeaderRenderer}
              dataKey="created"
              flexGrow={1}
              width={80}
              minWidth={80}
              maxWidth={160}
              cellRenderer={this.renderCreatedAtText}
            />
            <Column
              headerRenderer={this.liveHeaderRenderer}
              dataKey="live"
              flexGrow={1}
              width={80}
              minWidth={80}
              maxWidth={160}
              cellRenderer={this.renderLiveText}
            />
            <Column
              dataKey="render"
              style={{ textAlign: 'right' }}
              disableSort
              flexGrow={1}
              width={110}
              minWidth={110}
              maxWidth={160}
              cellRenderer={this.renderActionButtons}
            />
          </TableWithCustomFilter>
        )}
      </InfiniteLoader>
    );
  }

  renderBody() {
    if (this.isSpotlightEnabledAndSelected()) {
      return <SpotlightHomepage spotlightStoryStatus={this.state.spotlightStoryStatus} />;
    }

    if (this.hasPublisherNoStories()) {
      return (
        <BlankPageWithText
          happyState
          message={getMessageFromId('homepage-no-stories')}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          helpCenterDestination={this.getHelpCenterDestination()}
        />
      );
    }

    return this.renderStories();
  }

  renderPageTitle = () => (
    <div className={style.tableHeading} data-test="homepageView.search">
      <HomepageSearchBox onChange={this.handleSearch} />
    </div>
  );

  onTabChange = (selectedTabIndex: number) => {
    this.setState({ selectedTabIndex });
  };

  renderPageTabs = () => (
    <HomepageTabs
      onTabChange={this.onTabChange}
      selectedTabIndex={this.state.selectedTabIndex}
      data-test="HomepageView.tabs"
    />
  );

  renderLoadingSpinner = () => (
    <Spinner loading={this.props.isLoadingNewSearchCriteria} message={SpinnerLabels.LOADING} />
  );

  renderHeaderCenter = () => {
    return <div>{this.renderLoadingSpinner()}</div>;
  };

  renderMakeAvailableButton = () => {
    return (
      <SDSButton
        type={ButtonType.PRIMARY}
        inlineIcon={availableStory}
        onClick={this.makeSelectedStoriesAvailable}
        data-test="homepageActions.HomepageView.makeAvailable.button"
      >
        <FormattedMessage
          defaultMessage="Make available"
          id="homepage-make-available"
          description="Button for making stories available"
        />
      </SDSButton>
    );
  };

  renderMakeUnavailableButton = () => {
    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        inlineIcon={deactivate}
        onClick={this.makeSelectedStoriesUnavailable}
        data-test="homepageActions.HomepageView.makeUnavailable.button"
      >
        <FormattedMessage
          defaultMessage="Make unavailable"
          id="homepage-make-unavailable"
          description="Button for making stories unavailable"
        />
      </SDSButton>
    );
  };

  renderDateFilterButton = () => {
    const noDateText = (
      <FormattedMessage
        defaultMessage="Filter by availability date"
        id="homepage-calendar-no-date"
        description="Default text for the calendar button when no date has been selected"
      />
    );
    return (
      <CalendarButton
        onUpdate={this.handleCalendarDateSelection}
        defaultText={noDateText}
        from={moment(this.props.startDate)}
        to={moment(this.props.endDate)}
      />
    );
  };

  handleClickNewEpisode = () => {
    if (this.props.activePublisher) {
      const params = {
        publisherId: this.props.activePublisher.id,
        businessProfileId: this.props.activePublisher.businessProfileId,
      };
      this.props.createEpisodePrompt(params);
    }
  };

  handleClickNewStory = (postingStoryType?: PostingStoryType, numberOfSnaps?: number) => {
    if (this.props.activePublisherId) {
      this.props.createStoryPrompt({
        publisherId: this.props.activePublisherId,
        postingStoryType,
        numberOfSnaps,
      });
    }
  };

  handleNewStoryFromNewContentModal(postingStoryType: PostingStoryType) {
    switch (postingStoryType) {
      case PostingStoryType.PUBLIC_STORY:
        return () => this.handleClickNewStory(postingStoryType, 0);
      //
      default:
        return this.props.isShow ? this.handleClickNewEpisode : this.handleClickNewStory;
    }
  }

  handleNewSpotlightFromContentModal = () => {
    return this.props.goToSpotlightUploader();
  };

  handleSpotlightStoryStatusChange = (updatedStoryStatus: string) => {
    this.setState({
      spotlightStoryStatus: updatedStoryStatus,
    });
  };

  handleOpenNewContentPrompt = () => {
    // TODO(mmeroi) - remove when public story launches for all creators
    if (!this.props.isActiveCreatorPublisher) {
      return this.props.goToSpotlightUploader();
    }

    return this.props.showModal(ModalType.CREATE_CONTENT, 'createContentPrompt', {
      modalType: ModalType.CREATE_CONTENT,
      onNewStory: this.handleNewStoryFromNewContentModal(PostingStoryType.SAVED_STORY),
      onNewPublicStory: this.handleNewStoryFromNewContentModal(PostingStoryType.PUBLIC_STORY),
      onNewSpotlight: this.handleNewSpotlightFromContentModal,
    });
  };

  // The button opens a modal which allows users to pick whether they want to post to stories or spotlight
  renderNewContentButton() {
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.BOTTOM}
        title={isWasmEnabled() ? '' : getMessageFromId('unsupported-browser')}
        id="unsupported-browser-tooltip"
      >
        <SDSButton
          type={ButtonType.PRIMARY}
          inlineIcon={plus}
          onClick={this.handleOpenNewContentPrompt}
          disabled={this.props.isNewContentDisabled}
          data-test="homepage.newContentButton"
        >
          {getMessageFromId('post-to-snapchat')}
        </SDSButton>
      </SDSTooltip>
    );
  }

  renderGoToShowsButton() {
    if (this.props.isSeasonsEpisodesDisabled || !this.props.isShow || !this.props.hasActiveShow) {
      return null;
    }
    return (
      <SDSButton type={ButtonType.SECONDARY} onClick={this.handleGoToSeasonsClick} data-test="manage-seasons-button">
        <FormattedMessage
          defaultMessage="Manage seasons"
          id="homepage-manage-seasons"
          description="Button for managing seasons"
        />
      </SDSButton>
    );
  }

  makeUnselectedStoriesUnavailable = () => {
    (this.props.makeUnselectedStoriesUnavailable(this.state.selectedStoryIds) as any).then((result: any) => {
      this.setState({ selectedStoryIds: [] });
      const modalConfig: AlertOptions = {
        onConfirm: () => {},
        body: (
          <FormattedMessage
            id="made-unselected-stories-unavailable-message"
            description="Message shown in alert modal telling the user that x number of stories has been made unavailable"
            defaultMessage="{numberOfStories} stories have been made unavailable. It may take a few minutes to update."
            values={{
              numberOfStories: result.payload.count,
            }}
          />
        ),
      };
      this.props.showModal(ModalType.ALERT, 'HomepageView.madeUnselectedUnavailable', modalConfig);
    });
  };

  onMakeUnselectedStoriesUnavailableClick = () => {
    const modalConfig: DialogModalOptions = {
      visible: true,
      isBodyCentered: true,
      onConfirm: this.makeUnselectedStoriesUnavailable,
      body: (
        <FormattedMessage
          id="make-unselected-stories-unavailable-message"
          description="Message shown in alert modal asking if the user wants to make unselected stories unavailable"
          defaultMessage="Are you sure you want to make all unselected stories unavailable?"
        />
      ),
    };
    this.props.showModal(ModalType.DIALOG, 'HomepageView.makeUnselectedUnavailable', modalConfig);
  };

  showMakeUnselectedUnavailableButton = () => this.props.isMakeAllStoriesUnavailableEnabled && this.props.isSuperAdmin;

  renderMakeUnselectedUnavailableButton = () => {
    return (
      <SDSButton
        inlineIcon={deactivate}
        type={ButtonType.SECONDARY}
        onClick={this.onMakeUnselectedStoriesUnavailableClick}
        data-test="HomepageView.makeUnselectedUnavailableButton"
      >
        <FormattedMessage
          defaultMessage="Make unselected unavailable"
          id="homepage-make-unselected-unavailable"
          description="Text for button that makes all unselected stories unavailable"
        />
      </SDSButton>
    );
  };

  openRSSFeedModal = () => {
    this.props.showModal(ModalType.RSS_FEED_MODAL, 'HappeningNowHeaderButtons');
  };

  openRSSFeedAlertModal = () => {
    const options: DialogModalOptions = {
      visible: true,
      isBodyCentered: true,
      title: (
        <FormattedMessage
          id="missing-logo-title"
          description="Modal title when publisher tries to open rss feed, but doesn't have a horizontal logo"
          defaultMessage="Missing Wide Logo"
        />
      ),
      body: (
        <FormattedMessage
          id="missing-logo-message"
          description="Message displayed when publisher tries to open RSS feed, but doesn't have a horizontal logo"
          defaultMessage="You need to provide a Wide Format Logo in Publisher Settings to be able to configure your RSS feed"
        />
      ),
      okText: (
        <FormattedMessage
          id="go-to-settings"
          description="Redirects the user to the Publisher Settings page"
          defaultMessage="Go to Settings"
        />
      ),
      onConfirm: () => {
        this.props.goToSettings(this.props.activePublisherId);
      },
    };
    this.props.showModal(ModalType.DIALOG, 'RSSFeedAlertModal', options);
  };

  renderNewsFeedButton = () => {
    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        inlineIcon={link}
        onClick={this.props.activePublisher?.horizontalIconId ? this.openRSSFeedModal : this.openRSSFeedAlertModal}
        disabled={!this.props.activePublisher || this.hasPublisherNoStories()}
        data-test="HomePage.News.RSSFeedModal"
      >
        <FormattedMessage
          defaultMessage="RSS Feeds"
          id="homepage-rss-feed"
          description="Text for button that updates the RSS feeds"
        />
      </SDSButton>
    );
  };

  renderHeaderButtons = () => {
    return (
      <div className={style.headerButtonContainer} data-test="homepageView.headerContainer">
        {/* One check is needed for normal users, the other for super admins */}
        {this.props.isSpotlightManagementEnabled ? this.renderPageTitle() : null}
        {this.props.isNewsFeedViewer && this.props.isNewsFeedsEnabled && this.renderNewsFeedButton()}
        {this.showMakeAvailaleButton() && this.renderMakeAvailableButton()}
        {this.showMakeUnavailableButton() && this.renderMakeUnavailableButton()}
        {this.showMakeUnselectedUnavailableButton() && this.renderMakeUnselectedUnavailableButton()}
        {this.renderDateFilterButton()}
        {this.renderGoToShowsButton()}

        {this.renderNewContentButton()}
      </div>
    );
  };

  renderSpotlightStoryStatusDropdown = () => {
    return (
      <div className={style.headerDropdown} data-test="HomepageView.spotlight-story-status-dropdown">
        <SDSDropdown
          type={DropdownType.GREY}
          size={DropdownSize.SMALL}
          value={this.state.spotlightStoryStatus}
          onChange={this.handleSpotlightStoryStatusChange}
        >
          {createSDSDropdownOptions(STORY_STATUS_DROPDOWN_OPTION)}
        </SDSDropdown>
      </div>
    );
  };

  renderSpotlightHeaderButtons = () => {
    return (
      <div className={style.headerButtonContainer} data-test="homepageView.spotlightHeaderContainer">
        {this.props.isSpotlightSchedulingEnabled && this.renderSpotlightStoryStatusDropdown()}
        {this.renderNewContentButton()}
      </div>
    );
  };

  render() {
    return (
      <div className={style.root}>
        <Grid className={(style as any).container} data-test="homePageView.container">
          <div className={style.header}>
            <PageHeaderWithLeftMiddleRightElements
              leftElements={this.props.isSpotlightManagementEnabled ? this.renderPageTabs() : this.renderPageTitle()}
              middleElements={this.renderHeaderCenter()}
              rightElements={
                this.isSpotlightEnabledAndSelected() ? this.renderSpotlightHeaderButtons() : this.renderHeaderButtons()
              }
            />
          </div>
          <div className={style.table} data-test="homePageView.table">
            {this.renderBody()}
          </div>
        </Grid>
      </div>
    );
  }
}
export default withProfileAdAccountsQuery(withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(HomepageView)));
