import classNames from 'classnames';
import invariant from 'invariant';
import React from 'react';
import type { ReactNode } from 'react';

import { DropzoneType, ImageFileExtension, UploadFormat } from 'config/constants';
import type { UploadPurpose } from 'config/constants';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { MediaOutputDimensions, MediaOutputFormat } from 'utils/media/mediaConfig';
import { getValidationOptions, hasValidationOptions } from 'utils/media/mediaValidationConfig';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

import style from './PublisherDetailMediaButtonRow.scss';

import type { MediaValidation } from 'types/media';
import type { PublisherID } from 'types/publishers';

type OwnProps = {
  title: string | ReactNode;
  onChange: (a?: string | number | null) => void;
  className?: string;
  defaultValue?: string;
  maxSize: number;
  minSize: number;
  disabled?: boolean;
  uploadType: UploadPurpose;
  dropzoneType?: string;
  publisherId: PublisherID;
  uploadValidation?: MediaValidation;
};

type Props = OwnProps & typeof PublisherDetailMediaButtonRow.defaultProps;

export class PublisherDetailMediaButtonRow extends React.PureComponent<Props> {
  static defaultProps = {
    maxSize: Number.MAX_VALUE,
    minSize: Number.MIN_VALUE,
    disabled: false,
    dropzoneType: DropzoneType.REPLACE_TILE_LOGO,
  };

  getValidation(): MediaValidation {
    if (this.props.uploadValidation) {
      return this.props.uploadValidation;
    }
    if (hasValidationOptions(this.props.uploadType)) {
      return getValidationOptions(this.props.uploadType);
    }

    return {};
  }

  uploadBlob = (blob: string) => {
    this.props.onChange(blob);
  };

  handleChange = (event: Event) => {
    const element = event.target;
    invariant(element instanceof HTMLInputElement, 'element is not an HTMLElement');
    const { value } = element;

    const valid = this.isValid(value);
    if (!valid) {
      this.props.onChange(null);
      return;
    }

    this.props.onChange(value);
  };

  isValid = (value: string) => {
    const { maxSize, minSize } = this.props;
    const valueNum = value.length;
    return minSize <= valueNum && valueNum <= maxSize;
  };

  // FIXME: PublisherDetailImageRow has similar logic, probably worth factoring this out into a separate component
  renderImageDetails() {
    const imageDimensions = MediaOutputDimensions[this.props.uploadType]
      ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        `${MediaOutputDimensions[this.props.uploadType].width} x ${
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          MediaOutputDimensions[this.props.uploadType].height
        }px `
      : '';

    const imageType = MediaOutputFormat[this.props.uploadType] ? MediaOutputFormat[this.props.uploadType] : 'PNG';
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const fileExtension = ImageFileExtension[imageType];

    return `${imageDimensions}${fileExtension}`;
  }

  render() {
    const classes = classNames(style.parent, this.props.className);
    return (
      <div className={classes} data-test="onboarding.publisherDetail.mediaButtonRow.replace.container">
        <div className={style.leftPanel}>
          <SDSLabel data-test="onboarding.publisherDetail.mediaButtonRow.label" labelTitle={this.props.title}>
            <MediaUploader
              publisherId={this.props.publisherId}
              uploadFormat={UploadFormat.ONE_FILE}
              dropzoneType={this.props.dropzoneType}
              purpose={this.props.uploadType}
              handleBlobOnly={this.uploadBlob}
              customValidationOptions={this.getValidation()}
              enabled={!this.props.disabled}
            >
              <SDSButton
                type={ButtonType.WHITE}
                disabled={this.props.disabled}
                data-test="onboarding.publisherDetail.mediaButtonRow.replace.button"
              >
                {this.props.defaultValue
                  ? getMessageFromId('replace-button-label')
                  : getMessageFromId('upload-button-label')}
              </SDSButton>
            </MediaUploader>
          </SDSLabel>
        </div>

        <div className={style.rightPanel}>
          <div className={style.content} data-test="onboarding.publisherDetail.mediaButtonRow.imageDetails">
            {this.renderImageDetails()}
          </div>
          <div className={style.content} data-test="onboarding.publisherDetail.mediaButtonRow.cropMessage">
            {intlMessages.getMessageFromId('publisher-profile-preview-message-crop')}
          </div>
        </div>
      </div>
    );
  }
}

export default PublisherDetailMediaButtonRow;
