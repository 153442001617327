import Modal from 'antd/lib/modal';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import SDSButton, { ButtonSize, ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SDSAlert.scss';

// Modals from snapnet have z-index 1050 and from antd 1000,
// so in order to avoid occluding of SDSModal, the higher value is used.
const Z_INDEX = 1050;

type Props = {
  visible: boolean;
  onConfirm: () => void;
  children: ReactNode;
  width?: number;
  'data-test'?: string;
};

export function SDSAlert(props: Props) {
  const footer = (
    <SDSButton
      type={ButtonType.PRIMARY}
      size={ButtonSize.LARGE}
      onClick={props.onConfirm}
      data-test={`${props['data-test']}.button`}
    >
      <FormattedMessage
        id="confirm-button-modal"
        description="Text indicating to user to confirm a prompt"
        defaultMessage="Confirm"
      />
    </SDSButton>
  );

  return (
    <Modal
      className={style.modal}
      title={false}
      visible={props.visible}
      footer={footer}
      closable={false}
      centered
      zIndex={Z_INDEX}
      width={props.width}
    >
      {props.children}
    </Modal>
  );
}

export default SDSAlert;
