import invariant from 'invariant';
import _ from 'lodash';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import move from 'lodash-move';
import React from 'react';
import type { ChangeEvent } from 'react';
import InlineSVG from 'svg-inline-react';

import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editorActions from 'state/editor/actions/editorActions';
import { getShowEditorState } from 'state/editor/selectors/editorSelectors';
import { isSeasonsEpisodesDisabled } from 'state/features/selectors/featuresSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import {
  getActivePublisherBusinessProfileId,
  getActivePublisherPrimaryLanguage,
} from 'state/publishers/selectors/publishersSelectors';
import { goToHomepage } from 'state/router/actions/routerActions';
import * as seasonsActions from 'state/seasons/actions/seasonsActions';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import { cross } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { withRouter } from 'utils/routerUtils';

import makeNestableExpandableList from 'views/common/components/NestableExpandableList/NestableExpandableList';
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput, { InputType } from 'views/common/components/SDSInput/SDSInput';
import { validateMinMaxValue } from 'views/common/components/SDSInput/inputValidationUtils';
import SaveSettingsButton from 'views/onboarding/containers/SettingsView/save/SaveSettingsButton';
import EditorTopBar from 'views/page/components/EditorTopBar/EditorTopBar';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/edit.svg.inline' or its... Remove this comment to see the full error message
import edit from 'images/edit.svg.inline';

import EpisodePreview from './EpisodePreview/EpisodePreview';
import style from './ShowsView.scss';

import type { PublisherID } from 'types/publishers';
import type { State as ReduxState } from 'types/rootState';
import type { Show, ShowsEditorState, ShowsEditorStateUpdeep, IndexedEpisode, ShowID } from 'types/shows';

type StateProps = {
  publisherId: PublisherID;
  businessProfileId: string;
  isSeasonsEpisodesDisabled: boolean;
  primaryLanguage: string;
  getPrimaryLanguageMessage: ReturnType<typeof publishersSelectors.getPrimaryLanguageMessage>;
  show: Show;
  showEditorState: ShowsEditorState;
  showId: ShowID;
};

type DispatchProps = {
  updateShowEditorState: typeof editorActions.updateShowEditorState;
  updateSeasonsWithSaving: typeof seasonsActions.updateSeasonsWithSaving;
  goToHomepage: typeof goToHomepage;
  getEditionsWithFirstSnaps: typeof editionsActions.getEditionsWithFirstSnaps;
};

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: ReduxState): StateProps => {
  const publisherId = getActivePublisherId(state);
  const businessProfileId = getActivePublisherBusinessProfileId(state);
  // TODO: rewrite component to handle missing publisher.
  invariant(typeof publisherId === 'number', 'Active publisher must exist');
  invariant(typeof businessProfileId === 'string', 'Active publisher must exist');

  const show = showsSelectors.getShows(state)(publisherId)[0];
  const { showId } = show;

  return {
    publisherId,
    businessProfileId,
    isSeasonsEpisodesDisabled: isSeasonsEpisodesDisabled(state),
    primaryLanguage: getActivePublisherPrimaryLanguage(state),
    getPrimaryLanguageMessage: publishersSelectors.getPrimaryLanguageMessage(state),
    showId,
    show,
    showEditorState: getShowEditorState(state)(showId),
  };
};

const mapDispatchToProps = {
  goToHomepage,
  updateShowEditorState: editorActions.updateShowEditorState,
  updateSeasonsWithSaving: seasonsActions.updateSeasonsWithSaving,
  getEditionsWithFirstSnaps: editionsActions.getEditionsWithFirstSnaps,
};

export class ShowsView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.showsListDraggable = makeNestableExpandableList('Shows');
  }

  componentDidMount() {
    this.updateEditorState(this.createEditorSettings(this.props.show, 0));
    this.fetchSeasonEpisodes(0);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!_.isEqual(nextProps.show, this.props.show)) {
      this.updateEditorState(this.createEditorSettings(nextProps.show, this.getSelectedSeasonIndex()));
    }
  }

  getSelectedSeasonDisplayName() {
    const { showEditorState } = this.props;
    return showEditorState.displayNames[showEditorState.selectedSeasonIndex];
  }

  getSelectedSeasonSeasonNumber() {
    const { showEditorState } = this.props;
    return showEditorState.seasonNumbers[showEditorState.selectedSeasonIndex];
  }

  getSelectedSeasonIndex() {
    const { showEditorState } = this.props;
    return showEditorState.selectedSeasonIndex;
  }

  // Non typed list
  showsListDraggable: any = null;

  createEditorSettings(show: Show, selectedSeasonIndex: number): ShowsEditorState {
    const { seasons } = show;
    const season = seasons[selectedSeasonIndex];
    return {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Season | undefined' is not assignable to typ... Remove this comment to see the full error message
      season,
      selectedSeasonIndex,
      displayNames: seasons.map(s => s.displayName),
      seasonNumbers: seasons.map(s => s.seasonNumber),
      indexedEpisodes: seasons[selectedSeasonIndex] // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        ? seasons[selectedSeasonIndex].episodes.map((episode, index) => ({
            episode,
            index,
          }))
        : [],
    };
  }

  handleSeasonIndexChanged = (selectedSeasonIndexString: string) => {
    const selectedSeasonIndex = parseInt(selectedSeasonIndexString, 10);
    this.updateEditorState(this.createEditorSettings(this.props.show, selectedSeasonIndex));
    this.fetchSeasonEpisodes(selectedSeasonIndex);
  };

  handleSeasonNumberChanged = (e: ChangeEvent<EventTarget>) => {
    const element = e.target;
    invariant(element instanceof HTMLInputElement, 'element is not an HTMLElement');

    const seasonNumber = /\d+/.test(element.value) ? parseInt(element.value, 10) : '';

    this.updateEditorState({
      seasonNumbers: {
        [this.getSelectedSeasonIndex()]: seasonNumber,
      },
    });
  };

  handleDisplayNameChanged = (e: ChangeEvent<EventTarget>) => {
    const element = e.target;
    invariant(element instanceof HTMLInputElement, 'element is not an HTMLElement');

    this.updateEditorState({
      displayNames: {
        [this.getSelectedSeasonIndex()]: element.value,
      },
    });
  };

  handleEpisodeMove = (component: any, hoverIndex: number) => {
    const { indexedEpisodes } = this.props.showEditorState;
    const episode = indexedEpisodes[hoverIndex];
    if (!episode) {
      return;
    }

    const { episodeId } = component.props;
    const currentIndex = this.findEpisodeIndexById(indexedEpisodes, episodeId);
    if (currentIndex !== hoverIndex) {
      this.updateEditorState({
        indexedEpisodes: this.swapEpisodes(episodeId, hoverIndex),
      });
    }
  };

  handleOnSave = () => {
    const { showEditorState } = this.props;
    const currentSeasonNumber = showEditorState.seasonNumbers[showEditorState.selectedSeasonIndex];
    const seasonNumber = currentSeasonNumber !== '' ? currentSeasonNumber : 0;

    this.props.updateSeasonsWithSaving(this.props.publisherId, this.props.businessProfileId, this.props.showId, [
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        seasonNumber,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        displayName: showEditorState.displayNames[showEditorState.selectedSeasonIndex],
        episodeIds: showEditorState.indexedEpisodes.map(ep => ep.episode.id),
        id: showEditorState.season.id,
      },
    ]);
  };

  handleOnClose = () => {
    this.props.goToHomepage({
      overwriteHistory: true,
      publisherId: this.props.publisherId,
    });
  };

  fetchSeasonEpisodes(seasonIndex: number) {
    const { show } = this.props;
    if (!show || !show.seasons || show.seasons.length === 0) {
      return;
    }
    const season = show.seasons[seasonIndex];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.getEditionsWithFirstSnaps(season.episodes.map(ep => parseInt(ep.id, 10)));
  }

  updateEditorState(showEditorState: Partial<ShowsEditorStateUpdeep>) {
    this.props.updateShowEditorState(this.props.showId, showEditorState);
  }

  findEpisodeIndexById = (indexedEpisodes: IndexedEpisode[], episodeId: string): number => {
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    return _.findIndex(indexedEpisodes, ep => ep.episode.id === episodeId);
  };

  swapEpisodes = (episodeId: string, newIdex: number) => (indexedEpisodes: IndexedEpisode[]) => {
    const currentIndex = this.findEpisodeIndexById(indexedEpisodes, episodeId);
    return currentIndex === newIdex ? indexedEpisodes : move(indexedEpisodes, currentIndex, newIdex);
  };

  hasUnsavedChanges() {
    const { showEditorState } = this.props;
    const selectedSeason = showEditorState.season;
    if (!selectedSeason) {
      return false;
    }

    const currentEpisodesOrder = showEditorState.indexedEpisodes.map(ep => ep.episode.id);
    const episodesOrder = selectedSeason.episodes.map(ep => ep.id);

    const displayNamesEqual = _.isEqual(selectedSeason.displayName, this.getSelectedSeasonDisplayName());
    const episodesOrderEqual = _.isEqual(currentEpisodesOrder, episodesOrder);
    const seasonNumberEqual = _.isEqual(selectedSeason.seasonNumber, this.getSelectedSeasonSeasonNumber());
    return !displayNamesEqual || !episodesOrderEqual || !seasonNumberEqual;
  }

  renderEpisode = (ep: IndexedEpisode) => {
    return (
      <EpisodePreview
        episode={ep.episode}
        key={ep.index}
        moveEpisode={this.handleEpisodeMove}
        header="foo"
        showId={this.props.showId}
      />
    );
  };

  renderEpisodes() {
    const { showEditorState } = this.props;

    return <div className={style.episodesContainer}>{showEditorState.indexedEpisodes.map(this.renderEpisode)}</div>;
  }

  render() {
    const { show, showEditorState } = this.props;
    if (!show || !showEditorState || !showEditorState.displayNames) {
      return null;
    }

    const { displayNames, seasonNumbers } = showEditorState;

    const seasonsDropdownOptions = _.zip(displayNames, seasonNumbers).map(([displayName, seasonNumber], index) => ({
      value: String(index),
      label: getMessageFromId('season-field-with-description', { description: `${seasonNumber} ${displayName || ''}` }),
    }));
    return (
      <div>
        {!isSeasonsEpisodesDisabled && (
          <EditorTopBar>
            <div className={style.bar}>
              <div className={style.left}>
                <InlineSVG src={edit} className={style.pencil} />
                <div className={style.label}>Editing season</div>
                <div className={style.seasonSelect}>
                  <SDSDropdown
                    disableClear
                    dropdownWidthMatchContent
                    value={String(this.getSelectedSeasonIndex())}
                    onChange={this.handleSeasonIndexChanged}
                    size={DropdownSize.MEDIUM}
                    type={DropdownType.GREY}
                  >
                    {createSDSDropdownOptions(seasonsDropdownOptions)}
                  </SDSDropdown>
                </div>
              </div>
              <div className={style.right}>
                <div className={style.seasonsInput}>
                  <SDSInput
                    labelTitle={getMessageFromId('season-field-number')}
                    data-test="ShowsView.season.seasonNumber"
                    value={this.getSelectedSeasonSeasonNumber()}
                    onChange={this.handleSeasonNumberChanged}
                    type={InputType.NUMBER}
                    validateInput={validateMinMaxValue(1, 999)}
                    disabled
                  />
                </div>
                <div className={style.seasonsInput}>
                  <SDSInput
                    labelTitle={getMessageFromId('season-field-display-name')}
                    data-test="ShowsView.season.displayName"
                    value={this.getSelectedSeasonDisplayName()}
                    onChange={this.handleDisplayNameChanged}
                    maxLength={20}
                  />
                </div>
                <div className={style.barButtonsContainers}>
                  <SaveSettingsButton
                    isActive={this.hasUnsavedChanges()}
                    isSaving={showEditorState.isSaving}
                    onClick={this.handleOnSave}
                  />
                  <SDSButton
                    type={ButtonType.SECONDARY}
                    shape={ButtonShape.CIRCLE}
                    inlineIcon={cross}
                    onClick={this.handleOnClose}
                  />
                </div>
              </div>
            </div>
          </EditorTopBar>
        )}
        {this.renderEpisodes()}
      </div>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(ShowsView));
