import { ApolloError } from '@apollo/client';
import axios from 'axios';
import Base64 from 'base64-js';
import { detect } from 'detect-browser';
import { Dispatch } from 'react';

import { ErrorContexts } from '../errors/errorConstants';
import { InfoContext, showInfoMessage } from '../errors/infoMessage/infoMessageUtils';
import { mediaErrorHandler } from '../errors/media/mediaErrorUtils';

import { UploadPurpose } from 'config/constants';
import { getUploadUrl } from 'gql/hooks/useUploadUrl';
import { UrlHeader } from 'gql/queries/spotlight/uploadUrlQuery';
import { incrementCounter, reportLevel } from 'utils/grapheneUtils';
import { measureDurationWrap } from 'utils/performance/performanceUtils';

import { makeMediaPreviewMetadata } from './fileUtils';
import { encryptAESCBC } from './mediaEncoding';
import { validateStorySnapVideo } from './mediaValidation';

import { StorySnapMediaWithPreview, UploadToBoltResult } from 'types/media';

type GenericHeader = { [key: string]: string };

const makeUrlHeaders = (urlHeaders: UrlHeader[]) => {
  const formattedHeaders: GenericHeader = {};
  urlHeaders.forEach(header => {
    formattedHeaders[header.key] = header.value;
  });
  return formattedHeaders;
};

// Note that Bolt doesn't return anything to indicate the success of upload
export const uploadMediaToBolt = async (
  uploadUrl: string,
  buffer: ArrayBufferView,
  urlHeaders: UrlHeader[]
): Promise<void> => {
  const headers = makeUrlHeaders(urlHeaders);
  return axios.put(uploadUrl, buffer, {
    headers,
  });
};

// uploadStorySnapMedia breakdown:
//    - Validate media in the client using the native video tag element;
//    - Encrypt the media to be sent to bolt and generates the key/iv for encryption;
//    - Get the upload URL (graphQL endpoint) from Bolt;
//    - Upload the encrypted media directly to Bolt using the upload URL;
//    - Built the StorySnapMedia for preview and for posting
//    - Note that images and HEVC files are currently not supported in this flow;
export const useUploadStorySnapMedia = measureDurationWrap(
  async (file: File, uploadPurpose: UploadPurpose, dispatch: Dispatch<any>): Promise<StorySnapMediaWithPreview> => {
    // validate the media
    let videoMetadata;
    await validateStorySnapVideo(file, uploadPurpose)
      .then(videoInfo => {
        incrementCounter(`${uploadPurpose}.upload.validation`, { action: 'succeed' });
        videoMetadata = videoInfo;
      })
      .catch(validationError => {
        incrementCounter(`${uploadPurpose}.upload.validation`, { action: 'failed', reason: validationError });
        mediaErrorHandler(dispatch, ErrorContexts.VALIDATE_MEDIA)(validationError);
        return Promise.reject(new Error('Media validation failed'));
      });
    const encryptedDataPromise = encryptAESCBC(file); // triggering encryption to parallelize with ops below

    const {
      data: { uploadUrl },
    } = await getUploadUrl(file.type).catch((apolloError: ApolloError) => {
      dispatch(showInfoMessage(InfoContext.UPLOAD_MEDIA));
      return Promise.reject(new Error(apolloError.message));
    });

    const encryptedData = await encryptedDataPromise;

    incrementCounter(`${uploadPurpose}.bolt.upload`, {
      action: 'start',
      browser: detect(navigator?.userAgent)?.name || 'unknown',
    });

    await uploadMediaToBolt(uploadUrl.uploadUrl, new Uint8Array(encryptedData.data), uploadUrl.urlHeaders).catch(
      err => {
        dispatch(showInfoMessage(InfoContext.UPLOAD_MEDIA));
        incrementCounter(`${uploadPurpose}.bolt.upload`, {
          action: 'failed',
          browser: detect(navigator?.userAgent)?.name || 'unknown',
        });
        return Promise.reject(new Error(err.message));
      }
    );

    incrementCounter(`${uploadPurpose}.bolt.upload`, {
      action: 'complete',
      browser: detect(navigator?.userAgent)?.name || 'unknown',
    });

    const keyBase64 = Base64.fromByteArray(encryptedData.key);
    const ivBase64 = Base64.fromByteArray(encryptedData.iv);

    const preview = makeMediaPreviewMetadata(file);

    return Promise.resolve({
      preview,
      boltContentReference: {
        contentObjectBase64: uploadUrl.contentObjectBase64,
        keyBase64,
        ivBase64,
      },
      videoMetadata,
    });
  },
  { metricsName: 'bolt.media.upload_time' },
  (durationMs: number, file: File, uploadPurpose: UploadPurpose) => {
    const durationSeconds = Math.max(1, Math.ceil(durationMs / 1000));
    reportLevel(`${uploadPurpose}.media.upload.bytes_per_second`, undefined, file.size / durationSeconds);
  }
);

// enrich useUploadStorySnapMedia with other methods for different story types in the future
export function useUploadMedia(): UploadToBoltResult {
  return {
    upload: useUploadStorySnapMedia,
  };
}
