import { useDispatch } from 'react-redux';
import type { Dispatch } from 'redux';

import { UploadPurpose } from 'config/constants';
import { usePostStorySnap } from 'gql/hooks/usePostStorySnap';
import { PostStorySnapMethodParams, StoryType } from 'gql/queries/spotlight/postStorySnap';
import { InfoContext, showInfoMessage } from 'utils/errors/infoMessage/infoMessageUtils';
import { incrementCounter, reportLevel } from 'utils/grapheneUtils';
import { useUploadMedia } from 'utils/media/mediaUpload';

import { StorySnapMediaWithPreview } from 'types/media';
import { UploaderState } from 'types/storySnapEditor';

export const handleStorySnapPost = (
  dispatch: Dispatch<any>,
  post: (params: PostStorySnapMethodParams) => Promise<void>
) => {
  const handleSpotlightCallback = async (
    saveToProfile: boolean,
    mediaWithPreview: StorySnapMediaWithPreview | undefined,
    description: string | undefined,
    goLiveTsMs: number | undefined,
    disableRemix: boolean | undefined,
    setUploaderState: any
  ) => {
    try {
      setUploaderState(UploaderState.SUBMITTING);
      await post({
        saveToProfile,
        videoMetadata: mediaWithPreview?.videoMetadata,
        boltContentReference: mediaWithPreview?.boltContentReference,
        description,
        goLiveTsMs,
        disableRemix,
      });
      return setUploaderState(UploaderState.SUBMITTED);
    } catch (err) {
      dispatch(showInfoMessage(InfoContext.POST_STORY_SNAP_ERROR));
      return setUploaderState(UploaderState.SUBMITTED_ERROR);
    }
  };
  return handleSpotlightCallback;
};

export const handleSnapUpload = (
  dispatch: Dispatch<any>,
  upload: (file: File, uploadPurpose: UploadPurpose, dispatch: Dispatch<any>) => Promise<StorySnapMediaWithPreview>,
  uploadPurpose: UploadPurpose
) => {
  const handleOnUpload = async (file: File, setUploaderState: any) => {
    reportLevel(`${uploadPurpose}.file_size_bytes`, {}, file.size || 0);

    setUploaderState(UploaderState.UPLOADING);
    try {
      const snap = await upload(file, uploadPurpose, dispatch);
      setUploaderState(UploaderState.DONE);
      incrementCounter(`${uploadPurpose}.upload`, { action: 'succeed' });
      return Promise.resolve(snap); // Return the snap uploaded.
    } catch (err) {
      incrementCounter(`${uploadPurpose}.upload`, { action: 'failed' });
      setUploaderState(UploaderState.ERROR);
      dispatch(showInfoMessage(InfoContext.UPLOAD_MEDIA));
      return Promise.reject(err);
    }
  };

  return handleOnUpload;
};

const uploadPurposeToStoryType = (uploadPurpose: UploadPurpose) => {
  switch (uploadPurpose) {
    case UploadPurpose.SPOTLIGHT:
      return StoryType.SPOTLIGHT_STORY;
    case UploadPurpose.PUBLIC_STORY:
      return StoryType.PUBLIC_STORY;
    default:
      return StoryType.SPOTLIGHT_STORY;
  }
};

export const useUploaderHandlers = (uploadPurpose: UploadPurpose) => {
  const dispatch = useDispatch();
  const { upload } = useUploadMedia();
  const { post } = usePostStorySnap(uploadPurposeToStoryType(uploadPurpose));

  const handleOnPost = handleStorySnapPost(dispatch, post);
  const handleOnUpload = handleSnapUpload(dispatch, upload, uploadPurpose);

  return { handleOnUpload, handleOnPost };
};
