import React from 'react';
import type { ReactNode } from 'react';

import { stopEventPropagation } from 'utils/browserUtils';

import { SDSCheckbox } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import type { CheckboxEvent } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './StoryPageCheckbox.scss';

import type { EditionID } from 'types/editionID';

type Props = {
  onChange: (b: CheckboxEvent<void>, a: EditionID) => void;
  editionId: EditionID;
  disabled: boolean;
  checked: boolean;
  disableMessage: ReactNode;
};

export default class StoryPageCheckbox extends React.PureComponent<Props> {
  handleCheckboxChange = (event: CheckboxEvent<void>) => {
    const { onChange, editionId } = this.props;
    onChange(event, editionId);
  };

  render() {
    const { disabled, checked, disableMessage } = this.props;

    const checkbox = (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(event?: Event | null | undefined) => void' ... Remove this comment to see the full error message
      <div className={style.container} onClick={stopEventPropagation} data-test="storyPageCheckbox">
        <span className={style.checkbox}>
          <SDSCheckbox onChange={this.handleCheckboxChange} disabled={disabled} checked={checked} />
        </span>
      </div>
    );

    const checkboxWithOverlay = (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP_LEFT}
        title={disableMessage}
        id="homepage-checkbox-disable"
      >
        {checkbox}
      </SDSTooltip>
    );

    return disabled ? checkboxWithOverlay : checkbox;
  }
}
