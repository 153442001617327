import React from 'react';
import { AutoSizer, Table } from 'react-virtualized';

import style from './TableWithCustomFilter.scss';
import 'react-virtualized/styles.css';

type OwnProps = {
  onHeaderClick?: (...args: any[]) => any;
  onRowClick?: (...args: any[]) => any;
  onRowMouseOver?: (...args: any[]) => any;
  className?: string;
  rowClassName?: (...args: any[]) => any;
  rowGetter: (...args: any[]) => any;
  rowCount: number;
  rowHeight?: number;
  onRowsRendered?: (...args: any[]) => any;
  scrollToIndex?: number;
};

type Props = OwnProps & typeof TableWithCustomFilter.defaultProps;

export default class TableWithCustomFilter extends React.Component<Props> {
  static defaultProps = {
    headerHeight: 30,
    overscanRowCount: 10,
    rowHeight: 40,
  };

  rowClassName = ({ index }: any) => {
    if (index < 0) {
      return style.headerRow;
    }
    return style.rowStyle;
  };

  render() {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={this.props.className}
            rowClassName={this.rowClassName}
            headerClassName={style.header}
            {...this.props}
            height={height}
            width={width}
            gridClassName={style.tableStyle}
            onRowsRendered={this.props.onRowsRendered}
            scrollToIndex={this.props.scrollToIndex}
          />
        )}
      </AutoSizer>
    );
  }
}
