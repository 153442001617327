// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hex-... Remove this comment to see the full error message
import hexRgb from 'hex-rgb';

export function hexToRgbaStyle(hex: string, alpha: number) {
  const hexArgs = [...hexRgb(hex), alpha].join(', ');
  return `rgba(${hexArgs})`;
}

export function isColorLight(hex: string) {
  const rgb = hexRgb(hex);
  return rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 149;
}
