import * as React from 'react';
import { useCallback } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { trash } from 'icons/SDS/allIcons';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';

const DELETE_ALERT_TITLE = (
  <FormattedMessage id="delete-alert-title" description="Delete alert title" defaultMessage="Delete Alert" />
);
const DELETE_CONFIRM_TEXT = (
  <FormattedMessage id="delete-confirm-text" description="Delete confirm text" defaultMessage="Delete" />
);

type AlertMessage = string | ReactNode;

type DeleteAlertProps = {
  alertMessage: AlertMessage;
  onDelete: () => void;
  onDismiss: () => void;
};

const DeleteAlert = ({ onDelete, alertMessage, onDismiss }: DeleteAlertProps) => {
  const onOk = useCallback(() => {
    onDelete();
    onDismiss();
  }, [onDelete, onDismiss]);
  return (
    <SDSDialog visible onCancel={onDismiss} okText={DELETE_CONFIRM_TEXT} onOk={onOk} title={DELETE_ALERT_TITLE}>
      {alertMessage}
    </SDSDialog>
  );
};

type Props = {
  alertMessage: AlertMessage;
  className?: string;
  disabled?: boolean;
  isDeleting?: boolean;
  onDelete(): void;
  purpose: string;
};

type State = {
  showDeleteAlert: boolean;
};

/* eslint-disable react/prop-types */
export default class DeleteButton extends React.PureComponent<Props, State> {
  state: State = { showDeleteAlert: false };

  onDismissAlert = () => {
    this.setState({ showDeleteAlert: false });
  };

  onClickAlert = () => {
    const { disabled, isDeleting } = this.props;

    if (disabled || isDeleting) {
      return;
    }

    this.setState({ showDeleteAlert: true });
  };

  render() {
    const { purpose, onDelete, disabled, alertMessage, className, isDeleting } = this.props;
    return (
      <>
        <SDSButton
          className={className}
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={trash}
          loading={isDeleting}
          disabled={disabled}
          onClick={this.onClickAlert}
          data-test={`delete.${purpose}`}
        />
        {this.state.showDeleteAlert ? (
          <DeleteAlert onDelete={onDelete} onDismiss={this.onDismissAlert} alertMessage={alertMessage} />
        ) : null}
      </>
    );
  }
}
