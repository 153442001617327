import classNames from 'classnames';
import React, { useCallback } from 'react';

import KPIWidget, { KPIMetricInput } from 'views/common/components/KPIWidget/KPIWidget';
import SDSColumn from 'views/common/components/SDSColumn/SDSColumn';

import style from './RevenueKPI.scss';

import { RevenueMetric } from 'types/analytics';

type Props = {
  isActive: boolean;
  metric: KPIMetricInput<RevenueMetric>;
  value: number;
  onMetricClicked: (metric: RevenueMetric) => void;
};

export function RevenueKPI({ isActive, metric, value, onMetricClicked }: Props) {
  const handleMetricClick = useCallback(() => {
    onMetricClicked(metric.metricId);
  }, [metric, onMetricClicked]);

  return (
    <SDSColumn
      onClick={handleMetricClick}
      isClickable
      xs={6}
      md={6}
      lg={6}
      data-test={`RevenueKPI.${metric.metricId}.Column`}
    >
      <KPIWidget
        metric={metric}
        value={value}
        isLoading={false}
        data-test={`RevenueKPI.${metric.metricId}.Widget`}
        className={classNames({ [style.activeKpi]: isActive })}
      />
    </SDSColumn>
  );
}
