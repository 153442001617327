import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { getToken } from 'state/auth/selectors/authSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';

import { ADS_MANAGER_BASE_URL } from 'config/constants';
import { rocket } from 'icons/SDS/allIcons';
import { stopEventPropagation } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { assignLocation } from 'utils/locationUtils';

import SDSButton, { ButtonShape, ButtonTypeEnum } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import { EditionID } from 'types/editionID';
import { State as RootState } from 'types/rootState';

type OwnProps = {
  buttonType: ButtonTypeEnum;
  storyId?: EditionID;
  error: string | undefined;
  adAccountId: string | undefined;
};

type StateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => {
  const activePublisher = getActivePublisherDetails(state);
  return {
    authToken: getToken(state),
    bizProfileId: activePublisher?.businessProfileId,
    activePublisher,
  };
};

type Props = OwnProps & StateProps;

export function PayToPromoteButton({ buttonType, bizProfileId, storyId, error, adAccountId, activePublisher }: Props) {
  const goToAdsManager = useCallback(
    (e: Event) => {
      stopEventPropagation(e);
      incrementCounterByPublisher(activePublisher, 'p2p.promoteStoryButton.click');

      assignLocation(
        `${ADS_MANAGER_BASE_URL}${adAccountId}/from-story-studio?storyId=${storyId}&profileId=${bizProfileId}`
      );
    },
    [activePublisher, adAccountId, bizProfileId, storyId]
  );

  const childElement = (
    <SDSButton
      onClick={goToAdsManager}
      inlineIcon={rocket}
      type={buttonType}
      shape={ButtonShape.CIRCLE}
      disabled={!!error || !adAccountId}
      data-test="payToPromoteButton.button"
    />
  );

  if (!adAccountId) {
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={
          <FormattedMessage
            id="pay-to-promote-button-tooltip-promotion-not-available"
            defaultMessage="Promotion not available"
            description="Description for tooltip shown over disabled pay to promote button"
          />
        }
        id="tooltip-pay-to-promote-button"
        data-test="payToPromoteButton.tooltip"
      >
        {childElement}
      </SDSTooltip>
    );
  }
  return childElement;
}
export default intlConnect(mapStateToProps, null)(PayToPromoteButton);
