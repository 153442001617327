import * as React from 'react';
import { Provider } from 'react-redux'; // eslint-disable-line no-restricted-imports

import { getExtraTooltipComponent } from 'views/analytics/utils/tooltip/extraComponents';
import ConnectedIntlProvider from 'views/common/components/Root/ConnectedIntlProvider';

type Props = {
  extraComponents?: any[];
  store?: any;
  extraComponentsProvider?: (...args: any[]) => any;
};

/**
 * This is the top-level component for all graph tooltips.
 * It provides two main things:
 *   - Wrap the tooltip tree in a ConnectedIntlProvider (the tooltip tree is independent from the rest of the UI
 *     so intl is not available otherwise)
 *   - Create the optional list of extra components that a specific graph config can request.
 *     eg see the use of 'QuestionResults' in chartConfigs.js
 */
export default class GraphTooltip extends React.Component<Props> {
  /* eslint-disable react/no-array-index-key */
  renderExtraComponents() {
    return (this.props.extraComponents || []).map((componentName, index) => {
      const componentProvider = this.props.extraComponentsProvider || getExtraTooltipComponent;
      const Component = componentProvider(componentName);
      return <Component key={`extra-${index}`} {...this.props} />;
    });
  }
  /* eslint-enable react/no-array-index-key */

  render() {
    return (
      <Provider store={this.props.store}>
        {/* @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
        <ConnectedIntlProvider>
          <div>
            {this.props.children}
            {this.renderExtraComponents()}
          </div>
        </ConnectedIntlProvider>
      </Provider>
    );
  }
}
