import _ from 'lodash';
import React from 'react';

import * as editionsActions from 'state/editions/actions/editionsActions';

import { EMPTY_ARRAY } from 'config/constants';
import { TileFormat, TileFormatConfig } from 'config/tileConfig';
import { intlConnect } from 'utils/connectUtils';

import CheetahAnalyticsTilePreview from 'views/analytics/containers/CheetahAnalyticsTilePreview/CheetahAnalyticsTilePreview';
import type { EditionPreviewConfig } from 'views/analytics/containers/CheetahAnalyticsTilePreview/CheetahAnalyticsTilePreview';

import style from './TopStories.scss';

import type { CheetahAnalyticsStories } from 'types/analytics';
import type { EditionID } from 'types/editions';

type OwnProps = {
  topPerformingStoriesStats: CheetahAnalyticsStories;
};

type DispatchProps = {
  getEditionsWithFirstSnaps: typeof editionsActions.getEditionsWithFirstSnaps;
};

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = {
  getEditionsWithFirstSnaps: editionsActions.getEditionsWithFirstSnaps,
};

const MAX_NUM_STORIES_TO_DISPLAY = 4;

export class TopStories extends React.Component<Props> {
  componentDidMount() {
    this.fetchEditionData();
  }

  getTiles() {
    if (this.props.topPerformingStoriesStats !== EMPTY_ARRAY) {
      const topPerformingStories = this.props.topPerformingStoriesStats.slice(0, MAX_NUM_STORIES_TO_DISPLAY);

      return _.map(topPerformingStories, topPerformingStoryStat => {
        const { editionId, tileId, headline } = topPerformingStoryStat.metadata;
        const editionPreviewConfig = {
          editionId: editionId.toString(),
          headline: headline || '',
          tileId: tileId.toString(),
        };

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ editionId: string; headline: s... Remove this comment to see the full error message
        return this.getTile(editionPreviewConfig, editionId);
      });
    }
    return '';
  }

  getTile = (editionPreviewConfig: EditionPreviewConfig, editionId: EditionID) => {
    return (
      <div className={style.topTileItem} key={`TopStories_top_story_tile_${editionId}`}>
        <div className={style.analyticsInsightsReportStoryTileTextStyleOverride}>
          <CheetahAnalyticsTilePreview
            edition={editionPreviewConfig}
            tileFormat={TileFormatConfig[TileFormat.REPORT_SIZE]}
          />
        </div>
      </div>
    );
  };

  fetchEditionData = () => {
    const editionIds = this.props.topPerformingStoriesStats
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      .map(story => parseInt(story.metadata.editionId, 10));
    this.props.getEditionsWithFirstSnaps(_.uniq(editionIds));
  };

  render() {
    return <div className={style.topTiles}>{this.getTiles()}</div>;
  }
}

export default intlConnect(null, mapDispatchToProps)(TopStories);
