import React from 'react';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SaveSettingsButton.scss';

type Props = {
  isSaving?: boolean;
  isActive: boolean;
  isDisabled?: boolean;
  onClick: () => any;
  className?: string;
};

export class SaveSettingsButton extends React.PureComponent<Props> {
  getSaveButtonText() {
    if (this.props.isSaving) {
      return getMessageFromId('saving');
    }
    if (this.props.isDisabled) {
      return getMessageFromId('save-changes');
    }

    return this.props.isActive ? getMessageFromId('save-changes') : getMessageFromId('changes-saved');
  }

  handleClick = () => {
    const { isSaving, isActive, isDisabled, onClick } = this.props;
    if (isActive && !isSaving && !isDisabled) {
      onClick();
    }
  };

  render() {
    const { isSaving, isActive, isDisabled } = this.props;

    return (
      <div className={style.buttonContainer}>
        <SDSButton
          type={ButtonType.PRIMARY}
          disabled={!isActive || isSaving || isDisabled}
          onClick={this.handleClick}
          loading={isSaving}
          data-test="onboarding.settingsView.saveSettings.button"
        >
          {this.getSaveButtonText()}
        </SDSButton>
      </div>
    );
  }
}

export default SaveSettingsButton;
