import { useState, useEffect } from 'react';

import { DEFAULT_ASPECT_RATIO, MAX_VIDEO_HEIGHT, MIN_VIDEO_HEIGHT, VIDEO_PADDING } from 'config/storySnapConstants';

type VideoProperties = {
  videoHeight: number;
  videoWidth: number;
  shouldRenderSmallVideo: boolean;
};

function getVideoProperties() {
  const { innerHeight: height } = window;
  const videoHeight = Math.min(height - VIDEO_PADDING, MAX_VIDEO_HEIGHT);
  const videoWidth = Math.floor(videoHeight / DEFAULT_ASPECT_RATIO.height) * DEFAULT_ASPECT_RATIO.width;
  const shouldRenderSmallVideo = videoHeight <= MIN_VIDEO_HEIGHT;
  return {
    videoHeight,
    videoWidth,
    shouldRenderSmallVideo,
  };
}

export default function useVideoProperties(): VideoProperties {
  const [videoProperties, setVideoProperties] = useState(getVideoProperties());

  useEffect(() => {
    function handleResize() {
      setVideoProperties(getVideoProperties());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return videoProperties;
}
