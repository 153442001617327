// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Row, Col } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './PageHeaderWithLeftMiddleRightElements.scss';

type Props = {
  leftElements?: React.ReactNode;
  middleElements?: React.ReactNode;
  rightElements?: React.ReactNode;
};

type State = any;

export default class PageHeaderWithLeftMiddleRightElements extends React.Component<Props, State> {
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  render() {
    const { leftElements, middleElements, rightElements } = this.props;
    return (
      <Row className={style.root}>
        <Col xs={12}>
          <div className={style.left}>{leftElements}</div>
          <div className={style.middle}>{middleElements}</div>
          <div className={style.right}>{rightElements}</div>
        </Col>
      </Row>
    );
  }
}
