import { GET_UPLOAD_URL, UploadUrlData } from '../queries/spotlight/uploadUrlQuery';

import apolloClient from 'utils/apis/apollo';

export const getUploadUrl = async (fileType: string) => {
  const { data } = await apolloClient.query<UploadUrlData>({
    query: GET_UPLOAD_URL,
    // We need to handle the exception as from apollo client 2
    // error policy 'none' throws an exception
    // https://github.com/apollographql/apollo-client/issues/5708
    errorPolicy: 'none',
    // We must bypass cache or apollo client will reuse previous responses
    // https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
    fetchPolicy: 'no-cache', // TODO(mmeroi): investigate further and possibly remove
    variables: {
      input: {
        mediaFormat: fileType,
        // TODO(gulsum): Pass the country code from locale
        country: '',
      },
    },
  });
  return { data };
};
