import { gql } from '@apollo/client';

import { useProxyMutation } from 'utils/apis/graphQLUtils';

export type UpdateUserPropertiesMutationResult = {
  updateUserPropertiesError?: any;
  updateUserPropertiesData?: any;
  updateUserProperties?: any;
};

export const UPDATE_USER_PROPERTIES = gql`
  mutation updateUserProperties($publisherId: String!, $value: Boolean!) {
    updateUserProperties(input: { publisherId: $publisherId, value: $value }) {
      __typename
      ... on UpdateUserPropertiesSuccess {
        value
      }
      ... on UpdateUserPropertiesError {
        message
      }
    }
  }
`;

export function useUpdateUserPropertiesMutation() {
  const [updateUserProperties, { data, error }] = useProxyMutation(UPDATE_USER_PROPERTIES);
  if (error) {
    return { error };
  }
  return { updateUserProperties, data };
}
