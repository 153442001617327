import json2csv from 'json2csv';
import { toArray, first, last, trim, split } from 'lodash';
import log from 'loglevel';
import moment from 'moment-timezone';

import {
  AuthType,
  DISCOVER_SERVICE_BASE_URL,
  DEFAULT_ANALYTICS_GEO,
  AnalyticsCountryType,
  AnalyticsCsvDetailLevel,
  BearerPrefix,
  AuthTypeEnum,
} from 'config/constants';
import * as discoverAPI from 'utils/apis/discoverAPI';
import { AUTH_HEADERS_BY_BASE_URL } from 'utils/authUtils';
import * as gaUtils from 'utils/gaUtils';
import { withNewTimezone } from 'utils/time/analyticsTimeUtils';

import { getHiddenMetricsSet } from 'views/analytics/utils/analyticsUtil';
import { Granularity, RevenueType } from 'views/modals/components/RevenueCSVModal/RevenueCSVModal';

import * as csvConfigs from './csvConfigs';
import { save } from './saveFile';

export const DEFAULT_CSV_FILENAME = 'analytics.csv';

function saveDailyCSV(data: any, fields: any, meta: any) {
  const CSVMetricsBody = json2csv({ data, fields });
  const CSVBody = `${meta.CSVHeader}${meta.title}\n${CSVMetricsBody}`;
  const fileName = `daily_${meta.title.toLowerCase()}_metrics_${meta.dateRange}.csv`;
  save(new Blob([CSVBody], { type: 'text/csv;charset=utf-16' }), fileName);
}

// countryCodes => array
export function exportDailyCSV(countryCodes: any, dailyMetrics: any, publisher: any, exportAudience: any) {
  const { name: publisherName } = publisher;
  const hiddenMetricsSet = getHiddenMetricsSet();
  if (dailyMetrics !== undefined) {
    gaUtils.logGAEvent(gaUtils.GAUserActions.ANALYTICS, 'export-daily-csv', { publisherName });
    const serializedCountryCodes =
      countryCodes === DEFAULT_ANALYTICS_GEO ? DEFAULT_ANALYTICS_GEO : countryCodes.join('_');
    const dailyMetricsArray = toArray(dailyMetrics); // ordered?

    const startDate = withNewTimezone(moment(first(dailyMetricsArray).eventTime).utcOffset(0)).format('MM/DD/YY');
    const endDate = withNewTimezone(moment(last(dailyMetricsArray).eventTime).utcOffset(0)).format('MM/DD/YY');
    const CSVHeader = `${csvConfigs.DAILY_CSV_HEADER_TEMPLATE}${startDate}-${endDate}\n# ${serializedCountryCodes}\n\n`;
    const dateRange = `${startDate.replace(/\//g, '_')}-${endDate.replace(/\//g, '_')}`;
    const meta = { dateRange, CSVHeader };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | ((row: any) => any)' is... Remove this comment to see the full error message
    const behaviorFields = csvConfigs.BEHAVIOR_FIELDS.filter(field => !hiddenMetricsSet.has(field.value));

    if (exportAudience) {
      saveDailyCSV(dailyMetricsArray, csvConfigs.AUDIENCE_FIELDS, { ...meta, title: 'Audience' });
    } else {
      saveDailyCSV(dailyMetricsArray, behaviorFields, { ...meta, title: 'Behavior' });
    }
  }
}

export function fetchCSV(url: any, token: any, authType: any) {
  const headerType = authType === AuthType.SNAPCHAT ? BearerPrefix.SNAP_BEARER : BearerPrefix.BEARER;
  return fetch(url, {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    headers: { [AUTH_HEADERS_BY_BASE_URL[DISCOVER_SERVICE_BASE_URL]]: `${headerType} ${token}` },
    credentials: 'include',
  })
    .then(response =>
      response.blob().then(blob => {
        const encodedFilename = trim(split(response.headers.get('Content-Disposition'), 'filename="')[1], '"');
        const filename = encodedFilename ? decodeURI(encodedFilename) : DEFAULT_CSV_FILENAME;
        save(blob, filename);
      })
    )
    .catch(err => {
      log.error('Error while downloading CSV', err);
    });
}

export function exportEditionListCSV({
  isGlobalSummary,
  publisherName,
  publisherId,
  startDate,
  endDate,
  token,
  countries,
  authType,
  csvDetailLevel,
  analyticsStatsType,
}: any) {
  gaUtils.logGAEvent(gaUtils.GAUserActions.ANALYTICS, 'export-edition-list-csv', { publisherName });

  const endpointFunc = discoverAPI.analyticsV4.editionListCSV;

  let geoType = null;
  if (isGlobalSummary) {
    geoType = AnalyticsCountryType.GLOBAL;
  } else if (countries === DEFAULT_ANALYTICS_GEO) {
    geoType = AnalyticsCountryType.GLOBAL_ALL;
  } else {
    geoType = AnalyticsCountryType.COUNTRY;
  }

  const params = {
    publisherId,
    startDate,
    endDate,
    countries: countries === DEFAULT_ANALYTICS_GEO ? '' : countries,
    geoType,
    csvDetailLevel: csvDetailLevel || AnalyticsCsvDetailLevel.STORIES_AND_SNAPS,
    analyticsStatsType,
  };
  const url = endpointFunc(params);
  return fetchCSV(url, token, authType);
}

// countries => serialized string US_CA_MX
export function exportEditionCSV({
  isGlobalSummary,
  publisherName,
  editionId,
  token,
  countries,
  authType,
  analyticsStatsType,
}: any) {
  gaUtils.logGAEvent(gaUtils.GAUserActions.ANALYTICS, 'export-edition-csv', { publisherName });

  const endpointFunc = discoverAPI.analyticsV4.editionCSV;

  let geoType = null;
  if (isGlobalSummary) {
    geoType = AnalyticsCountryType.GLOBAL;
  } else if (countries === DEFAULT_ANALYTICS_GEO) {
    geoType = AnalyticsCountryType.GLOBAL_ALL;
  } else {
    geoType = AnalyticsCountryType.COUNTRY;
  }
  const params = {
    editionId,
    geoType,
    countries: countries === DEFAULT_ANALYTICS_GEO ? '' : countries,
    analyticsStatsType,
  };
  const url = endpointFunc(params);

  return fetchCSV(url, token, authType);
}

export const exportRevenueCSV = ({
  hostUserId,
  publisherId,
  startDate,
  endDate,
  granularity,
  revenueType,
  country,
  token,
  authType,
}: {
  hostUserId: string;
  publisherId: number;
  startDate: string;
  endDate: string;
  granularity: Granularity;
  revenueType: RevenueType | null;
  country: string | null;
  token: Readonly<{}>;
  authType: AuthTypeEnum;
}) => {
  const endpointFunc = discoverAPI.analyticsV4.revenueCSV;

  const params = {
    hostUserId,
    publisherId,
    startDate,
    endDate,
    granularity,
    revenueType,
    country,
  };
  const url = endpointFunc(params);

  return fetchCSV(url, token, authType);
};

export const exportProfileAnalyticsCSV = ({
  publisherId,
  hostUserId,
  token,
  authType,
  startDate,
  endDate,
}: {
  publisherId: number;
  hostUserId: string;
  token: Readonly<{}>;
  authType: AuthTypeEnum;
  startDate: string;
  endDate: string;
}) => {
  const endpointFunc = discoverAPI.analyticsV4.profileAnalyticsCSV;

  const params = {
    hostUserId,
    publisherId,
    startDate,
    endDate,
  };
  const url = endpointFunc(params);

  return fetchCSV(url, token, authType);
};

export const exportEditionAnalyticsCSV = ({
  bundleId,
  publisherId,
  hostUserId,
  country,
  token,
  authType,
}: {
  bundleId: string;
  publisherId: number;
  hostUserId: string;
  country: string | null;
  token: Readonly<{}>;
  authType: AuthTypeEnum;
}) => {
  const endpointFunc = discoverAPI.analyticsV4.editionAnalyticsCSV;

  const params = {
    bundleId,
    hostUserId,
    publisherId,
    country,
  };
  const url = endpointFunc(params);

  return fetchCSV(url, token, authType);
};
