import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { ResponsiveContainer } from 'recharts';

import { help } from 'icons/SDS/allIcons';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import Icon from 'views/common/components/Icon/Icon';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './AnalyticsGraphContainer.scss';

type Props = {
  chartTitle: ReactNode | undefined | null;
  defaultToggleMessage?: ReactNode;
  alternativeToggleMessage?: ReactNode;
  children: ReactNode;
  isLoading: boolean;
  isToggled: boolean;
  tooltip?: ReactNode;
  helpCenterLink?: HelpCenterDestination;
  shouldShowToggle?: boolean;
  onToggleUpdated?: (event: boolean) => void;
  onGraphResize?: (a: { width: number; height: number }) => void;
};

type State = {
  containerHasSize: boolean;
};

export class AnalyticsGraphContainer extends React.PureComponent<Props, State> {
  state = {
    containerHasSize: false,
  };

  setContainerRef = (elem?: Element | null) => {
    this.container = elem;
  };

  container: Element | undefined | null;

  // The version of ReactResizeDetector used in snapnet does not detect resizes as a result of
  // making a tab pane visible and can fail to render graphs on non-default tab panes.
  // We can work around this by installing our own newer ReactResizeDetector here.
  handleResize = () => {
    const { container } = this;
    if (container && container.clientWidth > 0) {
      this.setState({
        containerHasSize: true,
      });

      if (this.props.onGraphResize) {
        this.props.onGraphResize({
          width: container.clientWidth,
          height: container.clientHeight,
        });
      }
    }
  };

  renderTooltip = () => {
    if (!this.props.tooltip) {
      return null;
    }

    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.RIGHT}
        title={this.props.tooltip}
        id="tooltip"
      >
        <div data-test="analyticsGraphContainer.toolTip">
          <Icon inlineIcon={help} className={style.infoIcon} />
        </div>
      </SDSTooltip>
    );
  };

  renderHelpCenterLink = () => {
    if (!this.props.helpCenterLink) {
      return null;
    }
    return (
      <HelpCenterLink data-test="AnalyticsGraphContainer.helpCenterLink" destination={this.props.helpCenterLink} />
    );
  };

  renderGraph = () => {
    return !this.state.containerHasSize ? null : (
      <ResponsiveContainer className={style.graphContainer}>{this.props.children}</ResponsiveContainer>
    );
  };

  renderLoadingSpinner = () => {
    return <SpinnerIcon className={style.loadingIcon} />;
  };

  renderToggle = () => {
    if (this.props.shouldShowToggle) {
      return (
        <div className={style.toggleContainer}>
          {this.props.defaultToggleMessage}
          <SDSSwitch
            value={this.props.isToggled}
            onChange={this.props.onToggleUpdated}
            data-test="analyticsGraphContainer.toggle"
            isSmallToggle
          />
          {this.props.alternativeToggleMessage}
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={classNames('card-box', style.root)} ref={this.setContainerRef}>
        <div className={style.header}>
          <h4 className={style.label} data-test="analyticsGraphContainer.header">
            {this.props.chartTitle}
            {this.renderTooltip()}
            {this.renderToggle()}
          </h4>
          {this.renderHelpCenterLink()}
        </div>
        {this.props.isLoading ? this.renderLoadingSpinner() : this.renderGraph()}
        <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
      </div>
    );
  }
}

export default AnalyticsGraphContainer;
