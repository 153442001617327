import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE, GRAPH_SHADES_OF_GREEN } from 'views/analytics/utils/chartConfigs';
import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';

import style from './AnalyticsTopsnapTimeSpentGraph.scss';

import type { StorySnapMetrics } from 'types/analytics';

type Props = {
  isLoading: boolean;
  metrics: StorySnapMetrics[];
  isOnPromotionTab: boolean;
  useAnalyticsV2?: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="time-spent-paid"
      defaultMessage="TopSnap Time Spent Paid"
      description="Time spent for paid impressions"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="completion-rate-paid"
      defaultMessage="TopSnap Completion Rate Paid"
      description="Completion rate for paid impressions"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="time-spent-organic"
      defaultMessage="TopSnap Time Spent Organic"
      description="Time spent for organic impressions"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="completion-rate-organic"
      defaultMessage="TopSnap Completion Rate Organic"
      description="Completion rate for organic impressions"
    />
  ),
  params: [],
});

export class AnalyticsTopsnapTimeSpentGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getRunTimeConfig = () => {
    return {
      key: 'runTime',
      name: getLocalisedMessageFromId(this.context, 'analytics-metric-topsnap-runtime'),
      fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
    };
  };

  getPaidConfigs = () => {
    return [
      {
        key: 'paidTimeSpent',
        name: getLocalisedMessageFromId(this.context, 'time-spent-paid'),
        fill: GRAPH_SHADES_OF_GREEN.MED_GREEN,
      },
      {
        key: 'organicTimeSpent',
        name: getLocalisedMessageFromId(this.context, 'time-spent-organic'),
        fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
      },
      this.getRunTimeConfig(),
    ];
  };

  getDefaultConfigs = () => {
    return [
      {
        key: 'timeSpent',
        name: getLocalisedMessageFromId(this.context, 'publisher-story-topsnap-time-spent'),
        fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
      },
      this.getRunTimeConfig(),
    ];
  };

  getConfigs = () => {
    return this.props.isOnPromotionTab ? this.getPaidConfigs() : this.getDefaultConfigs();
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const {
      name,
      timeSpent,
      runTime,
      snapId,
      mediaId,
      mediaType,
      completionRate,
      paidTimeSpent,
      paidCompletionRate,
      organicCompletionRate,
      mediaUrl,
    } = payload;
    const configs = this.getConfigs();
    let metricRows;
    if (this.props.isOnPromotionTab) {
      metricRows = [
        {
          metricName: getLocalisedMessageFromId(this.context, 'time-spent-paid'),
          metricValue: this.props.useAnalyticsV2
            ? numberFormatter.formatTimeViewed(timeSpent)
            : numberFormatter.printSeconds(paidTimeSpent),
        },
        {
          metricName: getLocalisedMessageFromId(this.context, 'time-spent-organic'),
          metricValue: this.props.useAnalyticsV2
            ? numberFormatter.formatTimeViewed(timeSpent)
            : numberFormatter.printSeconds(paidTimeSpent),
        },
        {
          metricName: getLocalisedMessageFromId(this.context, 'analytics-metric-topsnap-runtime'),
          metricValue: this.props.useAnalyticsV2
            ? numberFormatter.formatTimeViewed(runTime)
            : numberFormatter.printSeconds(runTime),
        },
        {
          metricName: getLocalisedMessageFromId(this.context, 'completion-rate-paid'),
          metricValue: this.props.useAnalyticsV2
            ? numberFormatter.printPercentage(paidCompletionRate)
            : numberFormatter.printPercentage(paidCompletionRate),
        },
        {
          metricName: getLocalisedMessageFromId(this.context, 'completion-rate-organic'),
          metricValue: this.props.useAnalyticsV2
            ? numberFormatter.printPercentage(paidCompletionRate)
            : numberFormatter.printPercentage(organicCompletionRate),
        },
      ];
    } else {
      metricRows = [
        {
          metricName: configs[0]?.name,
          metricValue: this.props.useAnalyticsV2
            ? numberFormatter.formatTimeViewed(timeSpent)
            : numberFormatter.printSeconds(timeSpent),
        },
        {
          metricName: configs[1]?.name,
          metricValue: this.props.useAnalyticsV2
            ? numberFormatter.formatTimeViewed(runTime)
            : numberFormatter.printSeconds(runTime),
        },
        {
          metricName: getLocalisedMessageFromId(this.context, 'analytics-metric-topsnap-completion-rate'),
          metricValue: numberFormatter.printPercentage(completionRate),
        },
      ];
    }

    return (
      <AnalyticsTooltip
        title={name}
        snapId={snapId}
        mediaId={mediaId}
        generatedPreviewUrl={mediaUrl}
        mediaType={mediaType}
        metricRows={metricRows}
      />
    );
  };

  renderGraphTooltip = () => {
    return (
      <div className={style.withMargins}>{getMessageFromId('publisher-story-topsnap-time-spent-explanation')}</div>
    );
  };

  getMetrics = () => {
    const { metrics, isOnPromotionTab } = this.props;

    if (isOnPromotionTab) {
      return metrics.map(metric => {
        if (this.props.useAnalyticsV2) {
          return {
            paidTimeSpent: metric.paidTimeSpent,
            organicTimeSpent: metric.organicTimeSpent,
            organicCompletionRate: metric.organicCompletionRate,
            ...metric,
          };
        }
        // time spent
        const totalTimeSpent = metric.uniques * metric.timeSpent;
        const paidTotalTimeSpent =
          metric.paidUniques && metric.paidTimeSpent && metric.paidUniques * metric.paidTimeSpent;
        const organicTotalTimeSpent = paidTotalTimeSpent && totalTimeSpent - paidTotalTimeSpent;
        const organicUniques = metric.paidUniques && metric.uniques - metric.paidUniques;
        const organicTimeSpent = organicTotalTimeSpent && organicUniques && organicTotalTimeSpent / organicUniques;

        // completion rate
        const paidCompletionCount =
          (metric.paidUniques && metric.paidCompletionRate && metric.paidUniques * metric.paidCompletionRate) || 0;
        const paidCompletionCountAsPercOfTotal = paidCompletionCount / metric.uniques;
        const organicCompletionRate = metric.completionRate - paidCompletionCountAsPercOfTotal;

        return {
          paidTimeSpent: metric.paidTimeSpent,
          organicTimeSpent,
          organicCompletionRate,
          ...metric,
        };
      });
    }

    return metrics;
  };

  render() {
    return (
      <AnalyticsBarChart
        barChartData={this.getMetrics()}
        barChartTitle={getMessageFromId('publisher-story-topsnap-time-spent')}
        bars={this.getConfigs()}
        graphTooltip={this.renderGraphTooltip}
        graphHelpCenterLink={HelpCenterDestination.ANALYTICS_OPTIMIZING_FOR_TIME_SPENT}
        isLoading={this.props.isLoading}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={this.props.useAnalyticsV2 ? numberFormatter.formatTimeViewed : numberFormatter.printSeconds}
      />
    );
  }
}

export default AnalyticsTopsnapTimeSpentGraph;
