import React from 'react';

import { AnalyticMetric, ProfileKPI } from 'views/analytics/components/ProfileKPI/ProfileKPI';
import { rangeOverAvg, PROFILE_ANALYTICS_KPI__CONFIG, profileMetricMap } from 'views/analytics/utils/kpiConfigs';
import { KPIMetricInput } from 'views/common/components/KPIWidget/KPIWidget';
import SDSRow from 'views/common/components/SDSRow/SDSRow';

import style from './ProfileKPIs.scss';

import { ProfileAnalytics, ProfileMetric } from 'types/analytics';

type Props = {
  activeMetric: AnalyticMetric | null;
  setActiveMetric: (metric: AnalyticMetric) => void;
  profileAnalyticsSummary?: ProfileAnalytics;
  reportRange: number;
  isLoading: boolean;
};

export function ProfileKPIs(props: Props) {
  const { activeMetric, setActiveMetric, reportRange, profileAnalyticsSummary, isLoading } = props;

  const getFormattedProfileKPI = (profileKPI: KPIMetricInput<ProfileMetric>) => {
    return {
      ...profileKPI,
      previousValueDescription: rangeOverAvg(reportRange),
    };
  };

  const renderProfileKPIs = () => {
    const profileKPIs = PROFILE_ANALYTICS_KPI__CONFIG.map(profileKPI => {
      const formattedProfileKPI = getFormattedProfileKPI(profileKPI);

      const metric = profileMetricMap[formattedProfileKPI.metricId!];
      // @ts-ignore
      const currentValue: number = profileAnalyticsSummary?.[metric.currentMetrics];

      // @ts-ignore
      const previousValue: number = profileAnalyticsSummary?.[metric.previousMetrics];
      return (
        <ProfileKPI
          key={formattedProfileKPI.metricId}
          metric={formattedProfileKPI}
          tabSize={profileKPI.tabSize}
          value={currentValue}
          previousValue={previousValue}
          isActive={activeMetric === formattedProfileKPI.metricId}
          onMetricClicked={setActiveMetric}
          isLoading={isLoading}
        />
      );
    });
    return <SDSRow>{profileKPIs}</SDSRow>;
  };

  return <div className={style.content}>{renderProfileKPIs()}</div>;
}
