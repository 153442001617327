import { Empty } from 'antd';
import Table from 'antd/lib/table';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as editionsActions from 'state/editions/actions/editionsActions';

import { ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT, AnalyticsInsightsTableType } from 'config/constants';
import { help } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import { printSeconds } from 'utils/numberFormatter';

import AnalyticsInsightsTilePreview from 'views/analytics/components/AnalyticsInsightsTilePreview/AnalyticsInsightsTilePreview';
import { AntdTableOverriden } from 'views/analytics/components/AntdTableOverriden/AntdTableOverriden';
import LoadMoreItemsButton from 'views/analytics/components/LoadMoreItemsButton/LoadMoreItemsButton';
import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './AnalyticsInsightsTopPerformingTilesView.scss';

import type { TileStat, TopPerformingTilesStats } from 'types/analytics';

const { Column } = Table;

type OwnProps = {
  topPerformingTiles: TopPerformingTilesStats;
};

type DispatchProps = {
  getEditionsWithFirstSnaps: typeof editionsActions.getEditionsWithFirstSnaps;
};

type Props = OwnProps & DispatchProps;

type State = {
  numberOfItemsToDisplay: number;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-top-performing-tiles-tooltip"
      description="Text that appears in the Tooltip explanation of the Top Performing Tiles table on the Analytics Insights page"
      defaultMessage="This table contains performance data from all Tiles (used in Tile Optimizer) in the last 30 days"
    />
  ),
  params: [],
});

const mapKeyToReferenceKey = {
  avgTotalTimeViewed: 'globalAvgTotalTimeViewed',
  uniqueTopsnapsPerUser: 'globalUniqueTopsnapsPerUser',
};

const mapKeyToValueFormatter = {
  avgTotalTimeViewed: printSeconds,
  indexedCtr: (value: any) => {
    return `${value.toFixed(2)}x`;
  },
  uniqueTopsnapsPerUser: (value: any) => {
    return value.toFixed(1);
  },
};

const TABLE_TYPE = AnalyticsInsightsTableType.TOP_PERFORMING_TILES;

const mapDispatchToProps = {
  getEditionsWithFirstSnaps: editionsActions.getEditionsWithFirstSnaps,
};

export class AnalyticsInsightsTopPerformingTilesView extends React.Component<Props, State> {
  state = {
    numberOfItemsToDisplay: 5,
  };

  componentDidMount() {
    this.fetchEditionData();
  }

  componentDidUpdate(prevProps: Props): void {
    if (!_.isEqual(this.props.topPerformingTiles, prevProps.topPerformingTiles)) {
      this.fetchEditionData();
    }
  }

  increaseNumberOfItemsToDisplay = () => {
    this.setState(prevState => ({ numberOfItemsToDisplay: prevState.numberOfItemsToDisplay + 5 }));
  };

  fetchEditionData = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
    const editionIds = this.props.topPerformingTiles.tileStats.map(tile => parseInt(tile.editionId, 10));
    this.props.getEditionsWithFirstSnaps(_.uniq(editionIds));
  };

  renderComparisonStats = (statValue: number, statName: string) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const referenceValue = mapKeyToReferenceKey[statName]
      ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        this.props.topPerformingTiles[mapKeyToReferenceKey[statName]]
      : 1.0;
    // calculate how much (in percentage) the statValue of this content is above/below the referenceValue (average of all content)
    const percentageDifference = 100.0 * (statValue / referenceValue) - 100.0;

    return (
      <span
        className={classNames(style.multiplierValue, {
          [style.negativeValue]: percentageDifference < 0,
          [style.positiveValue]: percentageDifference > 0,
          [style.neutralValue]: percentageDifference === 0,
        })}
      >
        {`${percentageDifference > 0 ? '+' : ''}${percentageDifference.toFixed(1)}%`}
      </span>
    );
  };

  renderStatsWithComparison = (stat: number, statName: string) => {
    return (
      <div>
        <p>
          <span className={style.statWithComparisonBlock}>
            <span className={style.statValue}>
              {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {`${mapKeyToValueFormatter[statName](stat)}  `}
            </span>
            {this.renderComparisonStats(stat, statName)}
          </span>
        </p>
        <span className={style.previousDescription}>
          {getMessageFromId('analytics-insights-top-performing-tiles-reference-text')}
        </span>
      </div>
    );
  };

  renderTile = (tileStat: TileStat) => {
    const { editionId, tileId, headline } = tileStat;
    const editionPreviewConfig = {
      editionId,
      headline,
      tileId,
    };
    return (
      <AnalyticsInsightsTilePreview
        editionPreviewConfig={editionPreviewConfig}
        analyticsInsightsTableType={TABLE_TYPE}
      />
    );
  };

  renderTableIndex = (index: number) => {
    return <span className={style.tableIndex}>{index + 1}</span>;
  };

  renderIndexedCtr = (text: any) => {
    return this.renderStatsWithComparison(parseFloat(text), 'indexedCtr');
  };

  renderAvgTotalTimeViewed = (text: any) => {
    return this.renderStatsWithComparison(parseFloat(text), 'avgTotalTimeViewed');
  };

  renderUniqueTopsnapsPerUser = (text: any) => {
    return this.renderStatsWithComparison(parseFloat(text), 'uniqueTopsnapsPerUser');
  };

  renderTileWrapper = (text: any, record: TileStat) => {
    return this.renderTile(record);
  };

  renderIndexWrapper = (text: any, record: TileStat, index: number) => {
    return this.renderTableIndex(index);
  };

  renderExpandTableButton = () => {
    if (this.state.numberOfItemsToDisplay >= this.props.topPerformingTiles.tileStats.length) {
      return null;
    }
    return (
      <LoadMoreItemsButton
        onClickHandler={this.increaseNumberOfItemsToDisplay}
        data-test="analyticsInsights.topPerforming.tiles.loadMore"
      />
    );
  };

  makeRowKey = (record: TileStat) => `${record.editionId}_${record.tileId}`;

  totalTimeViewedSorter = (a: TileStat, b: TileStat) =>
    parseFloat(a.avgTotalTimeViewed) - parseFloat(b.avgTotalTimeViewed);

  indexerCtrSorter = (a: TileStat, b: TileStat) => parseFloat(a.indexedCtr) - parseFloat(b.indexedCtr);

  uniqueTopsnapSorter = (a: TileStat, b: TileStat) =>
    parseFloat(a.uniqueTopsnapsPerUser) - parseFloat(b.uniqueTopsnapsPerUser);

  renderTable = () => {
    // shouldn't render content with default state, due to lack of data
    if (this.props.topPerformingTiles === ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT) {
      return (
        <span className={style.spinnerPosition}>
          <SpinnerIcon className={style.loadingIcon} />
        </span>
      );
    }
    return (
      <span>
        {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'AntdTableOverriden' does not hav... Remove this comment to see the full error message */}
        <AntdTableOverriden
          dataSource={this.props.topPerformingTiles.tileStats}
          pagination={{
            pageSize: this.state.numberOfItemsToDisplay,
            total: this.state.numberOfItemsToDisplay,
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: this.renderNoDataMessage(),
          }}
          className={style.table}
          rowKey={this.makeRowKey}
        >
          <Column
            title=""
            dataIndex="tileIndex"
            key="tileIndex"
            render={this.renderIndexWrapper}
            width={50}
            fixed="left"
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-tiles-table-tile-column-header-text')}
            dataIndex="tile"
            key="tile"
            render={this.renderTileWrapper}
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-tiles-table-time-viewed-column-header-text')}
            dataIndex="avgTotalTimeViewed"
            key="avgTotalTimeViewed"
            render={this.renderAvgTotalTimeViewed}
            defaultSortOrder="descend"
            sorter={this.totalTimeViewedSorter}
            sortDirections={['descend', 'ascend']}
            width={180}
            fixed="right"
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-tiles-table-indexed-ctr-column-header-text-new')}
            dataIndex="indexedCtr"
            key="indexedCtr"
            render={this.renderIndexedCtr}
            sorter={this.indexerCtrSorter}
            sortDirections={['descend', 'ascend']}
            width={180}
            fixed="right"
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-tiles-table-snaps-viewed-column-header-text')}
            dataIndex="uniqueTopsnapsPerUser"
            key="uniqueTopsnapsPerUser"
            render={this.renderUniqueTopsnapsPerUser}
            sorter={this.uniqueTopsnapSorter}
            sortDirections={['descend', 'ascend']}
            width={180}
            fixed="right"
          />
        </AntdTableOverriden>
        {this.renderExpandTableButton()}
      </span>
    );
  };

  renderNoDataMessage = () => {
    return (
      <Empty
        description={<span>{getMessageFromId('analytics-insights-top-performing-tiles-table-no-data-text')}</span>}
      />
    );
  };

  render() {
    return (
      <div className={style.tableContainer}>
        <div className={style.tableTitleContainer}>
          <span className={style.topTilesTitle}>
            {getMessageFromId('analytics-insights-top-performing-tiles-title')}
          </span>
          <SDSTooltip
            id="tooltip-info"
            title={getMessageFromId('analytics-insights-top-performing-tiles-tooltip')}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            placement={TooltipPosition.RIGHT}
          >
            <Icon className={style.infoIcon} inlineIcon={help} />
          </SDSTooltip>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}

export default intlConnect(null, mapDispatchToProps)(AnalyticsInsightsTopPerformingTilesView);
