import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import ColorPicker from 'views/common/components/ColorPicker/ColorPicker';

import style from './PublisherColorRow.scss';

type Props = {
  title: string | ReactNode;
  info?: string | ReactNode;
  onChange: (a: string) => void;
  className?: string;
  defaultValue?: string;
  titleWithDescription?: boolean;
  disabled?: boolean;
};
type State = {
  color: string;
};
export class PublisherColorRow extends React.PureComponent<Props, State> {
  state = {
    color: '',
  };

  UNSAFE_componentWillMount() {
    if (this.props.defaultValue) {
      this.setState({ color: this.props.defaultValue });
    }
  }

  handleChange = (color: string) => {
    this.setState({ color });
    this.props.onChange(color);
  };

  render() {
    const { titleWithDescription, title } = this.props;
    const classes = classNames(style.parent, this.props.className, {
      [style.titleWithDescription]: titleWithDescription,
    });
    return (
      <div className={classes}>
        <div className={style.leftPanel}>
          <div className={style.colorPicker}>
            <ColorPicker
              label={titleWithDescription ? null : title}
              onChange={this.handleChange}
              colorHex={this.state.color}
              /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
              swatchClassName={(style as any).swatchStyle}
              isReadOnly={this.props.disabled}
            />
          </div>
        </div>
        {this.props.info ? (
          <div className={style.rightPanel}>
            {titleWithDescription ? title : null}
            {this.props.info}
          </div>
        ) : null}
      </div>
    );
  }
}
export default PublisherColorRow;
