import { Promise } from 'core-js';
import { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getActiveCreator } from 'state/user/selectors/userSelectors';

import { ScheduledStoryStatus, StorySnapType } from '__ssp-generated__/globalTypes';
import {
  GetSpotlightAnalytics,
  GetSpotlightAnalyticsVariables,
} from 'gql/queries/analytics/__ssp-generated__/GetSpotlightAnalytics';
import {
  GetSpotlightPosts,
  GetSpotlightPostsVariables,
} from 'gql/queries/analytics/__ssp-generated__/GetSpotlightPosts';
import {
  GetStoryDraftingSnaps,
  GetStoryDraftingSnapsVariables,
} from 'gql/queries/spotlight/__ssp-generated__/GetStoryDraftingSnaps';
import { StoryDraftingSnap } from 'gql/queries/spotlight/storyDraftingSnaps';
import { StorySnap } from 'gql/queries/spotlight/storySnaps';
import { canLoadImage } from 'utils/media/ImageLoader';

import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import Spinner, { SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';
import StorySnapsInfiniteLoader from 'views/homepage/components/StorySnapsInfiniteLoader/StorySnapsInfiniteLoader';

import style from './TopSpotlightAnalytics.scss';

import { ScheduledSpotlight, SpotlightAnalytics } from 'types/analytics';
import { FetchMoreFunc } from 'types/apollo';

type Props = {
  fetchMore:
    | FetchMoreFunc<GetSpotlightAnalytics, GetSpotlightAnalyticsVariables>
    | FetchMoreFunc<GetSpotlightPosts, GetSpotlightPostsVariables>;
  scheduledFetchMore?: FetchMoreFunc<GetStoryDraftingSnaps, GetStoryDraftingSnapsVariables>;
  spotlightAnalytics?: SpotlightAnalytics | null;
  isLoading: boolean;
  scheduledSpotlights?: ScheduledSpotlight | null;
  showScheduled?: boolean;
};

export default function TopSpotlightAnalytics({
  fetchMore,
  scheduledFetchMore,
  isLoading,
  spotlightAnalytics,
  scheduledSpotlights,
  showScheduled,
}: Props) {
  const businessProfileId = useSelector(getActiveCreator)?.businessProfileId || '';
  const hostUserId = useSelector(getActiveCreator)?.hostUserId || '';
  const [cursor, setCursor] = useState<string>(
    showScheduled ? scheduledSpotlights?.cursor || '' : spotlightAnalytics?.pageInfo?.endCursor || ''
  );
  const [hasNextPage, setHasNextPage] = useState<boolean>(
    showScheduled ? true : spotlightAnalytics?.pageInfo?.hasNextPage || true
  );
  const [snaps, setSnaps] = useState<StorySnap[]>([]);

  useEffect(() => {
    if (showScheduled) {
      setCursor(scheduledSpotlights?.cursor || '');
      setHasNextPage(true);
    } else {
      const currentCursor = spotlightAnalytics?.pageInfo?.endCursor || '';
      const currentHasNextPage = spotlightAnalytics?.pageInfo?.hasNextPage;
      setCursor(currentCursor);
      setHasNextPage(currentHasNextPage == null ? true : currentHasNextPage);
    }
  }, [
    spotlightAnalytics?.pageInfo?.endCursor,
    spotlightAnalytics?.pageInfo?.hasNextPage,
    showScheduled,
    scheduledSpotlights?.cursor,
  ]);

  const mapScheduledSnapToStorySnap = (draftingSnaps: StoryDraftingSnap[]) => {
    return draftingSnaps.map(snap => {
      return {
        id: snap.id,
        goLiveTsMs: snap.goLiveTsMs,
        description: snap.description,
        video: {
          url: snap.mediaUrl,
          key: snap.mediaKey,
          iv: snap.mediaIv,
        },
      };
    });
  };

  const loadMoreRows = useCallback(() => {
    if (showScheduled) {
      return scheduledFetchMore!({
        variables: {
          input: {
            businessProfileId,
            hostUserId,
            cursor,
            limit: 10,
            scheduledStoryStatus: ScheduledStoryStatus.SCHEDULED,
            storySnapType: StorySnapType.OUR_STORY,
          },
        },
        // @ts-ignore
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const updatedSnaps = [
            ...(prev.storyDraftingSnaps?.snaps || []),
            ...fetchMoreResult?.storyDraftingSnaps?.snaps,
          ];
          setSnaps(uniqBy(mapScheduledSnapToStorySnap(updatedSnaps), (p: StorySnap) => p.id));
          setHasNextPage(fetchMoreResult.storyDraftingSnaps?.snaps?.length === 0);
          setCursor(fetchMoreResult.storyDraftingSnaps.cursor || '');
          return {};
        },
      });
    }

    return fetchMore({
      variables: {
        cursor,
      },
      // @ts-ignore
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        setHasNextPage(fetchMoreResult.spotlightAnalytics.spotlightAnalyticsForPage?.pageInfo?.hasNextPage ?? true);
        setCursor(fetchMoreResult.spotlightAnalytics.spotlightAnalyticsForPage?.pageInfo?.endCursor || '');
        return {
          spotlightAnalytics: {
            ...prev.spotlightAnalytics,
            spotlightAnalyticsForPage: {
              __typename: 'SpotlightAnalyticsConnection',
              edges: [
                ...(prev?.spotlightAnalytics.spotlightAnalyticsForPage?.edges || []),
                ...(fetchMoreResult?.spotlightAnalytics.spotlightAnalyticsForPage?.edges || []),
              ],
              pageInfo: fetchMoreResult.spotlightAnalytics.spotlightAnalyticsForPage?.pageInfo || null,
            },
          },
        };
      },
    });
  }, [showScheduled, fetchMore, cursor, scheduledFetchMore, businessProfileId, hostUserId]);

  useEffect(() => {
    if (showScheduled) {
      const scheduledSnaps = scheduledSpotlights?.snaps || [];
      // @ts-ignore
      const storySnaps: StorySnap[] = mapScheduledSnapToStorySnap(scheduledSnaps);
      setSnaps(storySnaps);
    } else {
      const edges = spotlightAnalytics?.edges || [];
      const imageLoadPromise = edges.map(edge => canLoadImage(edge?.node?.thumbnailUrl!, edge!.node!.id));

      Promise.all(imageLoadPromise).then(imageLoadResponses => {
        const filteredEdges = edges.filter(edge => {
          const imageLoadResponseForEdge = imageLoadResponses.find(
            imageLoadResponse => imageLoadResponse.identifier === edge!.node!.id
          );
          return imageLoadResponseForEdge && imageLoadResponseForEdge.canLoad;
        });

        const storySnaps: StorySnap[] = filteredEdges.map(edge => {
          return {
            id: edge!.node!.id,
            posterUrl: edge?.node?.thumbnailUrl,
            video: {
              url: edge?.node?.videoUrl,
              key: edge?.node?.key,
              iv: edge?.node?.iv,
            },
            creationTimestampMs: edge?.node?.creationTimestampMs,
            moderationStatus: edge?.node?.moderationStatus,
            moderationRejectionReasons: edge?.node?.moderationRejectionReasons?.map(reason => reason.toString()!) || [],
            description: edge?.node?.description,
            analytics: {
              subscribes: edge?.node?.analytics?.subscribes,
              totalViews: edge?.node?.analytics?.totalViews,
              totalShares: edge?.node?.analytics?.totalShares,
              totalFavourite: edge?.node?.analytics?.totalFavourite,
            },
          };
        });
        setSnaps(storySnaps);
      });
    }
  }, [scheduledSpotlights?.snaps, showScheduled, spotlightAnalytics?.edges]);

  const handleRemoveSnap = useCallback(
    (snap: StorySnap) => {
      const newSnaps = snaps?.filter(s => s.id !== snap.id) || [];
      setSnaps(newSnaps);
    },
    [snaps]
  );

  const handleUpdateSnapGoLiveTime = useCallback(
    (snapToUpdate: StorySnap, updatedGoLiveTsMs: number) => {
      const index = snaps?.findIndex(snap => snap.id === snapToUpdate.id);
      if (index) {
        // @ts-ignore
        snaps[index].goLiveTsMs = updatedGoLiveTsMs;
        setSnaps(snaps);
      }
    },
    [snaps]
  );

  const renderLoadMoreAction = () => {
    if (hasNextPage && snaps?.length) {
      return (
        <div className={style.loadMoreWrapper} onClick={loadMoreRows}>
          <FormattedMessage id="load-more" description="Load more snaps" defaultMessage="Load More" />
        </div>
      );
    }
    return null;
  };

  function renderEmptySpotlightContentSection() {
    return (
      <BlankPageWithText
        happyState
        message={
          <FormattedMessage
            id="analytics-no-spotlights"
            description="Shown when there are no spotlights to be shown"
            defaultMessage="Nothing to see here"
          />
        }
        helpCenterDestination={HelpCenterDestination.SPOTLIGHT}
      />
    );
  }

  function renderSpotlightLoadMoreSpinner() {
    return (
      <>
        {isLoading ? (
          <div className={style.spinnerWrapper}>
            <Spinner loading size={SpinnerSizes.LARGE} />
          </div>
        ) : null}
      </>
    );
  }

  function isPageLoading() {
    if (showScheduled) {
      return isLoading;
    }
    const spotlightAnalyticsEdgesHasContent = spotlightAnalytics?.edges?.length !== 0;
    const snapsHasContent = snaps?.length !== 0;
    const dataFilterCompleted = spotlightAnalyticsEdgesHasContent === snapsHasContent;
    return isLoading || !dataFilterCompleted;
  }

  function renderSnapInfiniteLoaderSection() {
    return (
      <>
        {snaps.length !== 0 ? (
          <StorySnapsInfiniteLoader
            posts={snaps}
            loadMoreRows={loadMoreRows}
            handleRemoveSnap={handleRemoveSnap}
            handleUpdateSnapGoLiveTime={handleUpdateSnapGoLiveTime}
          />
        ) : (
          renderEmptySpotlightContentSection()
        )}
      </>
    );
  }

  const renderSpotlightSnapsCard = () => {
    return (
      <div className={style.cardWrapper}>
        {renderSnapInfiniteLoaderSection()}
        {renderSpotlightLoadMoreSpinner()}
        {renderLoadMoreAction()}
      </div>
    );
  };

  return <>{isPageLoading() ? <Spinner loading size={SpinnerSizes.LARGE} /> : renderSpotlightSnapsCard()}</>;
}
