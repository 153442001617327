import hoistStatics from 'hoist-non-react-statics';
import { omit } from 'lodash';
import * as React from 'react';

import { assertArg } from './assertionUtils';

import type { GenericComponent } from 'types/components';
// Used for omitting property names which get cloned on to your class by a parent
export function omitWrap(ComponentClass: any, propsToOmit: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(ComponentClass).is.object.or.is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(propsToOmit).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  propsToOmit.forEach((name: any) => assertArg(name).is.string());
  return (props: any) => {
    const propsWithout = omit(props, propsToOmit);
    return <ComponentClass {...propsWithout} />;
  };
}
export function omitInline(renderedComponent: any, propsToOmit: any) {
  const SubComponent = (props: any) => {
    const nextProps = omit(props, propsToOmit);
    return React.cloneElement(renderedComponent, nextProps);
  };
  return <SubComponent />;
}
export function withKey<Props, State>(keyResetter: (props: Props) => unknown) {
  return function composedComponent(ComposedComponent: GenericComponent<Props>) {
    class KeyResetter extends React.PureComponent<Props, State> {
      render() {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Readonly<Props> & { key: unknown; children?:... Remove this comment to see the full error message
        return <ComposedComponent {...this.props} key={keyResetter(this.props)} />;
      }
    }
    (KeyResetter as any).displayName = `withKeyResetter(${ComposedComponent.displayName || ComposedComponent.name})`;
    // This HOC will be used with connect from react-redux which does not work with forwardRef API yet.
    // Thus if you need to forward a ref to the `ComposedComponent`, make sure that you use
    // the React forwardRef API after you wrap the component in the connect HOC.
    // See https://github.com/reduxjs/react-redux/issues/914 for reference
    return hoistStatics(KeyResetter, ComposedComponent);
  };
}
