import React from 'react';

import AlertBox, { AlertType } from 'views/common/components/AlertBox/AlertBox';
import InfoBox from 'views/common/components/InfoBox/InfoBox';

import style from './DebugSDSBoxes.scss';

function DebugSDSBoxes() {
  return (
    <div className={style.wrapper}>
      <div>
        <h3>InfoBox</h3>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'children' is missing in type '{ title: s... Remove this comment to see the full error message */}
        <InfoBox title="Title" />
      </div>

      <div>
        <h3>AlertBox: INFO</h3>
        <AlertBox type={AlertType.INFO}>
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
        </AlertBox>
      </div>

      <div>
        <h3>AlertBox: WARNING</h3>
        <AlertBox type={AlertType.WARNING}>
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
        </AlertBox>
      </div>

      <div>
        <h3>AlertBox: ERROR</h3>
        <AlertBox type={AlertType.ERROR}>
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
        </AlertBox>
      </div>
    </div>
  );
}

export default DebugSDSBoxes;
