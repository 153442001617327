import React from 'react';
import InlineSVG from 'svg-inline-react';

import { getCountryCodes } from 'state/analytics/selectors/analyticsSelectors';
import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';

import { DEFAULT_ANALYTICS_GEO } from 'config/constants';
import type { AuthTypeEnum } from 'config/constants';
import { gear } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import { exportEditionCSV } from 'views/analytics/utils/exportCSV';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/download.svg.inline' or ... Remove this comment to see the full error message
import downloadIcon from 'icons/download.svg.inline';

import style from './AnalyticsSingleEditionSettingsButton.scss';

import { AnalyticsStatsType } from 'types/analytics';
import type { Publisher } from 'types/publishers';
import type { State as RootState } from 'types/rootState';

const COUNTRY_CODE_SEPARATOR = '_';

type OwnProps = {
  editionId: number;
  className?: string;
};

type StateProps = {
  activePublisher: Publisher | null;
  authToken: string;
  authType: AuthTypeEnum;
  pickedCountryCodes: string | Array<string>;
};

type Props = OwnProps & StateProps;

type State = {
  isDownloadingExport: boolean;
  isDownloadingGlobalSummaryExport: boolean;
};

export const mapStateToProps = (state: RootState): StateProps => {
  return {
    activePublisher: getActivePublisherDetails(state),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Readonly<{}> | null' is not assignable to ty... Remove this comment to see the full error message
    authToken: getToken(state),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    authType: getAuthType(state),
    pickedCountryCodes: getCountryCodes(state),
  };
};

export class AnalyticsSingleEditionSettingsButton extends React.Component<Props, State> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'mounted' has no initializer and is not d... Remove this comment to see the full error message
  mounted: boolean; // eslint-disable-line react/sort-comp

  state = {
    isDownloadingExport: false,
    isDownloadingGlobalSummaryExport: false,
  };

  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'mounted'.
  mounted = false;

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleExportStoryCSV = () => {
    if (this.state.isDownloadingExport) {
      return;
    }

    this.setState({ isDownloadingExport: true });
    const { pickedCountryCodes } = this.props;
    const countries = Array.isArray(pickedCountryCodes)
      ? pickedCountryCodes.join(COUNTRY_CODE_SEPARATOR)
      : DEFAULT_ANALYTICS_GEO;

    incrementCounterByPublisher(this.props.activePublisher || null, 'analytics.csv.story');
    const args = {
      isGlobalSummary: false,
      publisherName: this.props.activePublisher?.mutablePublisherName,
      editionId: this.props.editionId,
      token: this.props.authToken,
      authType: this.props.authType,
      countries,
      analyticsStatsType: AnalyticsStatsType.ALL,
    };
    exportEditionCSV(args).then(() => {
      if (this.mounted) {
        this.setState({ isDownloadingExport: false });
      }
    });
  };

  handleExportStoryGlobalSummaryCSV = () => {
    if (this.state.isDownloadingGlobalSummaryExport) {
      return;
    }

    this.setState({ isDownloadingGlobalSummaryExport: true });
    const countries = DEFAULT_ANALYTICS_GEO;

    incrementCounterByPublisher(this.props.activePublisher || null, 'analytics.csv.story.global');
    const args = {
      isGlobalSummary: true,
      publisherName: this.props.activePublisher?.mutablePublisherName,
      editionId: this.props.editionId,
      token: this.props.authToken,
      authType: this.props.authType,
      countries,
      analyticsStatsType: AnalyticsStatsType.ALL,
    };
    exportEditionCSV(args).then(() => {
      if (this.mounted) {
        this.setState({ isDownloadingGlobalSummaryExport: false });
      }
    });
  };

  renderDownloadButtonIcon = (isDownloadingExportFlag: boolean) => {
    if (!isDownloadingExportFlag) {
      return <InlineSVG src={downloadIcon} className={style.downloadIcon} />;
    }
    return <SpinnerIcon className={style.loadingIcon} />;
  };

  renderDownloadStoryGlobalSummaryButton = () => {
    if (this.props.pickedCountryCodes !== DEFAULT_ANALYTICS_GEO) {
      return null;
    }
    return (
      <div onClick={this.handleExportStoryGlobalSummaryCSV} className={style.menuItem}>
        {this.renderDownloadButtonIcon(this.state.isDownloadingGlobalSummaryExport)}
        {getMessageFromId('analytics-single-story-data-global-only')}
      </div>
    );
  };

  renderDownloadStoryButton = () => {
    return (
      <div onClick={this.handleExportStoryCSV} className={style.menuItem}>
        {this.renderDownloadButtonIcon(this.state.isDownloadingExport)}
        {this.props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO
          ? getMessageFromId('analytics-single-story-data-all-countries')
          : getMessageFromId('analytics-single-story-data')}
      </div>
    );
  };

  renderTooltip = () => {
    return (
      <div data-test="analytics.analyticsSingleEditSettings.button.tooltip.title">
        {this.renderDownloadStoryButton()}
        {this.renderDownloadStoryGlobalSummaryButton()}
      </div>
    );
  };

  render() {
    return (
      <div className={style.settingsButtonContainer}>
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.BOTTOM_RIGHT}
          id="analytics-single-edition-tooltip"
          title={this.renderTooltip()}
          trigger="click"
        >
          <SDSButton
            type={ButtonType.SECONDARY}
            shape={ButtonShape.CIRCLE}
            inlineIcon={gear}
            data-test="analytics.singleEdition.settings.button"
          />
        </SDSTooltip>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, {})(AnalyticsSingleEditionSettingsButton);
