import log from 'loglevel';
import { useSelector } from 'react-redux';

import { getActiveCreatorHostUserId } from 'state/user/selectors/userSelectors';

import { ARCHIVE_PUBLISHER } from 'gql/mutations/publisher/archivePublisher';
import { useProxyMutation } from 'utils/apis/graphQLUtils';

export type ArchivePublisherMutationResult = {
  archivePublisherError?: string;
  archivePublisherData?: any;
  archivePublisher: () => Promise<void>;
};

// Hook returns the callback to be triggered on deletion
export function useArchivePublisher() {
  const hostUserId = useSelector(getActiveCreatorHostUserId);
  const [archivePublisher, { data, error }] = useProxyMutation(ARCHIVE_PUBLISHER, {
    variables: {
      hostUserId,
    },
  });

  if (error) {
    log.error('Failed to archive publisher', error);
    return {
      error,
    };
  }

  return { archivePublisher, data };
}
