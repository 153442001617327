import { Mention } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getActivePublisher } from 'state/user/selectors/userSelectors';

import sharedStyle from '../storySnapMenuCardStyle.scss';

import { MAX_DESCRIPTION_CHAR_LENGTH } from 'config/storySnapConstants';
import { getTopics } from 'gql/hooks/useTopics';
import { warningTriangle } from 'icons/SDS/allIcons';
import { incrementCounter } from 'utils/grapheneUtils';

import Icon from 'views/common/components/Icon/Icon';
import { checkExceededMaxLength } from 'views/common/components/SDSInput/inputValidationUtils';
import SDSMention from 'views/common/components/SDSMention/SDSMention';

import style from './DescriptionCard.scss';

const { toString } = Mention;

type Props = {
  setDescription: (description: string) => void;
  description: string | undefined;
  isValidationFailed: boolean;
  setValidationFailed: (isFailed: boolean) => void;
};

export default function DescriptionCard({
  setDescription,
  description,
  isValidationFailed,
  setValidationFailed,
}: Props) {
  const [suggestions, setSuggestions] = useState<string[]>();

  // editor state is enforced by antd
  function onChange(editorStateValue: any) {
    const newDescription = toString(editorStateValue);
    const hasExceededMaxLength = checkExceededMaxLength(newDescription, MAX_DESCRIPTION_CHAR_LENGTH);
    setValidationFailed(hasExceededMaxLength);
    setDescription(newDescription);
  }
  // If publisher cannot be found in the state, backed will default to US as global country
  const activePublisherBusinessProfileId = useSelector(getActivePublisher)?.businessProfileId || '';
  const onSearchChange = useCallback(
    async (value: string) => {
      let newSuggestions: string[] = [];
      const response = await getTopics({
        prefix: value,
        businessProfileId: activePublisherBusinessProfileId,
      }).catch(() => {
        incrementCounter('Spotlight', { action: 'topics.error' });
      });

      if (response) {
        const {
          data: { topics },
        } = response;
        newSuggestions = topics.map(topic => topic.topic);
      }

      // the user can also input any new value as a new topic (topic creation handled in the backend)
      if (value.length && !newSuggestions.includes(value)) {
        newSuggestions = [value, ...newSuggestions];
      }
      setSuggestions(newSuggestions);
    },
    [activePublisherBusinessProfileId]
  );

  return (
    <div className={style.cardContainer}>
      <div className={classNames(sharedStyle.cardTitle, style.descriptionHeading)}>
        <div className={sharedStyle.heading}>
          <FormattedMessage
            defaultMessage="Description"
            description="Description input title"
            id="spotlight-description-heading"
          />
        </div>
        <div
          className={classNames(sharedStyle.cardInfo, {
            [style.failedValidation]: isValidationFailed,
          })}
        >
          {isValidationFailed ? <Icon inlineIcon={warningTriangle} className={style.infoIcon} /> : null}
          {description?.length || 0}/{MAX_DESCRIPTION_CHAR_LENGTH}
        </div>
      </div>
      <div className={style.mentionWrapper}>
        <SDSMention
          // @ts-expect-error Expects string, but we want to be able to pass a translation
          placeholder={
            <FormattedMessage
              defaultMessage="Add a description and #topics"
              description="Description input placeholder"
              id="description-spotlight-placeholder"
            />
          }
          notFoundContent={
            <FormattedMessage
              defaultMessage="Add new tag"
              description="Description input no topics found"
              id="no-topics-found-placeholder"
            />
          }
          prefix={['#']}
          onSearchChange={onSearchChange}
          suggestions={suggestions}
          onChange={onChange}
          isError={isValidationFailed}
        />
      </div>
    </div>
  );
}
