import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { CLEAR_ASSOCIATED_USER, SET_ASSOCIATED_USER } from 'state/auth/actions/authActionTypes';

import { publishers } from 'utils/apis/discoverAPI';

export const setAssociatedUserToken = (businessProfileId: string, token: string) => (dispatch: any) =>
  dispatch(
    createCallAction(
      {
        type: SET_ASSOCIATED_USER,
        params: { businessProfileId, token },
      },
      {
        method: 'GET',
        endpoint: publishers.validateHostUserToken({ bpid: businessProfileId, token }),
      }
    )
  );

export const clearAssociatedUserToken = () => ({
  type: CLEAR_ASSOCIATED_USER,
});
