import moment from 'moment/moment';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import { getActiveCreatorHostUserId, getActivePublisherId } from 'state/user/selectors/userSelectors';

import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounter } from 'utils/grapheneUtils';

import { exportProfileAnalyticsCSV } from 'views/analytics/utils/exportCSV';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

type Props = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
};
export function ProfileAnalyticsCSVButton({ startDate, endDate }: Props) {
  const publisherId = useSelector(getActivePublisherId);
  const hostUserId = useSelector(getActiveCreatorHostUserId);
  const authType = useSelector(getAuthType);
  const token = useSelector(getToken);

  const displayEditionCSVExportModal = useCallback(async () => {
    if (publisherId && hostUserId && startDate && endDate && authType && token) {
      incrementCounter(GrafanaMetrics.STORY_ANALYTICS, {
        type: 'downloadProfileCSV',
      });
      await exportProfileAnalyticsCSV({
        publisherId,
        hostUserId,
        token,
        authType,
        startDate: startDate?.format('YYYY-MM-DD'),
        endDate: endDate?.format('YYYY-MM-DD'),
      });
    }
  }, [publisherId, hostUserId, startDate, endDate, authType, token]);

  return (
    <SDSButton type={ButtonType.PRIMARY} onClick={displayEditionCSVExportModal}>
      {
        <FormattedMessage
          id="edition-csv-export-button"
          description="Label for edition csv export button"
          defaultMessage="Export"
        />
      }
    </SDSButton>
  );
}
