import moment from 'moment-timezone';

import { HIDDEN_FIELD } from 'config/constants';
import * as numberFormatter from 'utils/numberFormatter';
import { withNewTimezone } from 'utils/time/analyticsTimeUtils';

export const DAILY_CSV_HEADER_TEMPLATE = '# ----------------------------------------\n# Daily Analytics ';

const DAU_FIELDS = ['dau_1_day', 'dau_2_days', 'dau_3_4_days', 'dau_5_6_7_days'];

const GENDER_FIELDS = ['unique_male_viewers', 'unique_female_viewers', 'unique_unknown_gender_viewers'];

const AGE_FIELDS = [
  'unique_13_17_viewers',
  'unique_18_24_viewers',
  'unique_25_34_viewers',
  'unique_35_plus_viewers',
  'unique_unknown_age_viewers',
];

const TOPSNAP_LONGFORM_VIEWS_FIELDS = ['longform_text_view_count', 'longform_video_view_count', 'top_snap_view_count'];

export function rowEventTime(row: any) {
  return (row.eventTime ? moment(row.eventTime).utcOffset(0) : withNewTimezone(moment(row.date))).format('MM/DD/YY');
}

export const AUDIENCE_FIELDS = [
  {
    label: 'Day Index',
    value: rowEventTime,
  },
  {
    label: 'Daily Unique',
    value: 'unique_dau',
  },
  {
    label: 'Monthly Uniques',
    value: 'unique_mau',
  },
  {
    label: 'Subscribers',
    value: 'subscriber_count',
  },
  {
    label: 'Loyal Users',
    value: (row: any) => percentage(row.dau_5_6_7_days, sumFields(row, DAU_FIELDS)),
  },
  {
    label: 'Loyal Users',
    value: 'dau_5_6_7_days',
  },
  {
    label: 'Frequent Users',
    value: (row: any) => percentage(row.dau_3_4_days, sumFields(row, DAU_FIELDS)),
  },
  {
    label: 'Frequent Users',
    value: 'dau_3_4_days',
  },
  {
    label: 'Returning Users',
    value: (row: any) => percentage(row.dau_2_days, sumFields(row, DAU_FIELDS)),
  },
  {
    label: 'Returning Users',
    value: 'dau_2_days',
  },
  {
    label: 'New Users',
    value: (row: any) => percentage(row.dau_1_day, sumFields(row, DAU_FIELDS)),
  },
  {
    label: 'New Users',
    value: 'dau_1_day',
  },
  {
    label: 'Male',
    value: (row: any) => percentage(row.unique_male_viewers, sumFields(row, GENDER_FIELDS)),
  },
  {
    label: 'Male',
    value: 'unique_male_viewers',
  },
  {
    label: 'Female',
    value: (row: any) => percentage(row.unique_female_viewers, sumFields(row, GENDER_FIELDS)),
  },
  {
    label: 'Female',
    value: 'unique_female_viewers',
  },
  {
    label: 'Unknown Gender',
    value: (row: any) => percentage(row.unique_unknown_gender_viewers, sumFields(row, GENDER_FIELDS)),
  },
  {
    label: 'Unknown Gender',
    value: 'unique_unknown_gender_viewers',
  },
  {
    label: '13-17',
    value: (row: any) => percentage(row.unique_13_17_viewers, sumFields(row, AGE_FIELDS)),
  },
  {
    label: '13-17',
    value: 'unique_13_17_viewers',
  },
  {
    label: '18-24',
    value: (row: any) => percentage(row.unique_18_24_viewers, sumFields(row, AGE_FIELDS)),
  },
  {
    label: '18-24',
    value: 'unique_18_24_viewers',
  },
  {
    label: '25-34',
    value: (row: any) => percentage(row.unique_25_34_viewers, sumFields(row, AGE_FIELDS)),
  },
  {
    label: '25-34',
    value: 'unique_25_34_viewers',
  },
  {
    label: '35+',
    value: (row: any) => percentage(row.unique_35_plus_viewers, sumFields(row, AGE_FIELDS)),
  },
  {
    label: '35+',
    value: 'unique_35_plus_viewers',
  },
  {
    label: 'Unknown Age',
    value: (row: any) => percentage(row.unique_unknown_age_viewers, sumFields(row, AGE_FIELDS)),
  },
  {
    label: 'Unknown Age',
    value: 'unique_unknown_age_viewers',
  },
];

export const BEHAVIOR_FIELDS = [
  {
    label: 'Day Index',
    value: rowEventTime,
  },
  {
    label: 'Completion Rate',
    value: 'completion_rate',
  },
  {
    label: 'Unique Topsnaps Per User',
    value: 'unique_top_snap_view_per_user',
  },
  {
    label: 'Total Time Viewed(sec)',
    value: 'total_time_viewed',
  },
  {
    label: 'Time Spent',
    value: (row: any) => divide(row.total_time_viewed, row.unique_dau),
  },
  {
    label: 'Attachment Conversion',
    value: 'longform_avg_conversion',
  },
  {
    label: 'Attachment Article Views',
    value: (row: any) => percentage(row.longform_text_view_count, sumFields(row, TOPSNAP_LONGFORM_VIEWS_FIELDS)),
  },
  {
    label: 'Attachment Article Views',
    value: 'longform_text_view_count',
  },
  {
    label: 'Attachment Video Views',
    value: (row: any) => percentage(row.longform_video_view_count, sumFields(row, TOPSNAP_LONGFORM_VIEWS_FIELDS)),
  },
  {
    label: 'Attachment Video Views',
    value: 'longform_video_view_count',
  },
  {
    label: 'Topsnap Views',
    value: (row: any) => percentage(row.top_snap_view_count, sumFields(row, TOPSNAP_LONGFORM_VIEWS_FIELDS)),
  },
  {
    label: 'Topsnap Views',
    value: 'top_snap_view_count',
  },
  {
    label: 'Unique Topsnap Views',
    value: 'unique_top_snap_view_count',
  },
  {
    label: 'Screenshots',
    value: 'total_screenshot_count',
  },
  {
    label: 'Shares',
    value: 'total_share_count',
  },
];

AUDIENCE_FIELDS.forEach(attachDefaultValue);
BEHAVIOR_FIELDS.forEach(attachDefaultValue);

function attachDefaultValue(field: any) {
  const refField = field;
  refField.default = '';
}

function divide(numeratorStr: any, denominatorStr: any) {
  const val = parseFloat(numeratorStr) / parseFloat(denominatorStr);
  return Number.isNaN(Number(val)) ? '' : val;
}

function sumFields(row: any, arr: any) {
  return arr.reduce((a: any, b: any) => a + (Number.isNaN(Number(parseInt(row[b], 10))) ? 0 : parseInt(row[b], 10)), 0);
}

function percentage(numeratorStr: any, denominatorNum: any) {
  const num = parseFloat(numeratorStr);
  let percent;
  if (Number.isNaN(Number(num)) || denominatorNum === 0) {
    // For privacy, hide numbers when numbers are low
    percent = HIDDEN_FIELD;
  } else {
    percent = numberFormatter.printPercentage(parseFloat(numeratorStr) / denominatorNum);
  }
  return percent;
}
