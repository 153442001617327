import _ from 'lodash';
import React from 'react';
import { InjectedIntlProps, injectIntl, IntlShape } from 'react-intl';

import { showAsDate, privacyNumberFormat, formatTimeViewed } from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { AnalyticsReportRange, getProfileAnalyticsGraphConfig } from 'views/analytics/utils/kpiConfigs';

import {
  ProfileAnalytics,
  ProfileGraphDataPoint,
  ProfileMetric,
  ProfileMetricEnum,
  ProfileSubMetricEnum,
  ProfileTimeSeriesResult,
} from 'types/analytics';

type Props = {
  activeMetric: ProfileMetric;
  profileAnalytics?: ProfileAnalytics | null;
  intl: IntlShape;
  isLoading: boolean;
  reportRange: AnalyticsReportRange;
};

type TooltipMetricRow = {
  viewers: number;
  name: string;
  views: number;
  favourites: number;
  newFollowers: number;
  shares: number;
  viewers13To17?: number;
  viewers18To20?: number;
  viewers21To24?: number;
  viewers25To34?: number;
  viewers35Plus?: number;
  male?: number;
  female?: number;
  unknown?: number;
  totalTimeViewed?: number;
  uniqueViewersSubscribers?: number;
  totalSubscribers?: number;
};

type ChartToolTip = {
  formatter?: (num: number) => string;
  name: string;
  payload: TooltipMetricRow;
  value: number;
};

type MergedAgeAnalyticsEntry = {
  name: string;
  viewers13To17?: number;
  viewers18To20?: number;
  viewers21To24?: number;
  viewers25To34?: number;
  viewers35Plus?: number;
};

type MergedGenderAnalyticsEntry = {
  name: string;
  male?: number;
  female?: number;
  unknown?: number;
};

type GraphConfig = {
  name: string;
  graphTooltip: string;
  fill: string;
  key: string;
  yAxisTickFormatter: (num: number) => string;
};

function ProfileAnalyticsGraph({ activeMetric, profileAnalytics, intl, isLoading }: Props & InjectedIntlProps) {
  function getMetricsData(currentGraphKey?: string) {
    const mergedAgeAnalytics: MergedAgeAnalyticsEntry[] = [];
    const mergedGenderAnalytics: MergedGenderAnalyticsEntry[] = [];

    if (!profileAnalytics) {
      return null;
    }

    const mergeViewershipAgeData = (data: any, ageGroup: keyof MergedAgeAnalyticsEntry) => {
      data?.forEach((item: ProfileGraphDataPoint) => {
        const entry = mergedAgeAnalytics.find(e => e.name === showAsDate(item.timestamp)) as
          | { [k: string]: number }
          | undefined;
        if (entry) {
          entry[ageGroup] = item.value;
        } else {
          const newEntry: MergedAgeAnalyticsEntry = {
            name: showAsDate(item.timestamp),
            [ageGroup]: item.value,
          };
          mergedAgeAnalytics.push(newEntry);
        }
      });
    };

    const mergeViewershipGenderData = (data: any, gender: keyof MergedGenderAnalyticsEntry) => {
      data?.forEach((item: ProfileGraphDataPoint) => {
        const entry = mergedGenderAnalytics.find(e => e.name === showAsDate(item.timestamp)) as
          | { [k: string]: number }
          | undefined;
        if (entry) {
          entry[gender] = item.value;
        } else {
          const newEntry: MergedAgeAnalyticsEntry = {
            name: showAsDate(item.timestamp),
            [gender]: item.value,
          };
          mergedGenderAnalytics.push(newEntry);
        }
      });
    };

    if (activeMetric === ProfileMetricEnum.UniqueViewers && currentGraphKey === ProfileSubMetricEnum.AgeUniqueViewers) {
      mergeViewershipAgeData(profileAnalytics?.uniqueViewers13To17ForTimestamp, 'viewers13To17');
      mergeViewershipAgeData(profileAnalytics?.uniqueViewers18To20ForTimestamp, 'viewers18To20');
      mergeViewershipAgeData(profileAnalytics?.uniqueViewers21To24ForTimestamp, 'viewers21To24');
      mergeViewershipAgeData(profileAnalytics?.uniqueViewers25To34ForTimestamp, 'viewers25To34');
      mergeViewershipAgeData(profileAnalytics?.uniqueViewers35PlusForTimestamp, 'viewers35Plus');
      return mergedAgeAnalytics;
    }

    if (
      activeMetric === ProfileMetricEnum.UniqueViewers &&
      currentGraphKey === ProfileSubMetricEnum.GenderUniqueViewers
    ) {
      mergeViewershipGenderData(profileAnalytics?.uniqueViewersMaleForTimestamp, 'male');
      mergeViewershipGenderData(profileAnalytics?.uniqueViewersFemaleForTimestamp, 'female');
      mergeViewershipGenderData(profileAnalytics?.uniqueViewersGenderUnknownForTimestamp, 'unknown');
      return mergedGenderAnalytics;
    }

    if (activeMetric === ProfileMetricEnum.UniqueViewersSubscribers) {
      return profileAnalytics?.uniqueViewersSubscribersForTimestamp?.map((item: ProfileTimeSeriesResult) => ({
        uniqueViewersSubscribers: item.value,
        name: showAsDate(item.timestamp),
      }));
    }

    if (activeMetric === ProfileMetricEnum.TotalTimeViewedMs) {
      return profileAnalytics?.totalTimeViewedMsForTimestamp?.map((item: ProfileTimeSeriesResult) => ({
        totalTimeViewed: item.value,
        name: showAsDate(item.timestamp),
      }));
    }

    return profileAnalytics?.totalSubscribersForTimestamp?.map((item: ProfileTimeSeriesResult) => ({
      totalSubscribers: item.value,
      name: showAsDate(item.timestamp),
    }));
  }

  const profileAnalyticsGraphConfig = getProfileAnalyticsGraphConfig(intl)[activeMetric]!;

  const profileGraphs = profileAnalyticsGraphConfig?.graphs;

  function renderChartTooltip(
    tooltipData: { payload: ChartToolTip[] },
    configs: Array<GraphConfig>,
    currentGraphKey: string
  ) {
    const payload = tooltipData?.payload && tooltipData?.payload[0]?.payload;
    if (!payload) {
      return null;
    }

    let metricRows: {} = [];
    const { name } = payload!;

    if (currentGraphKey === ProfileSubMetricEnum.AgeUniqueViewers) {
      const { viewers13To17, viewers18To20, viewers21To24, viewers25To34, viewers35Plus } = payload!;

      metricRows = [
        { metricName: configs[0]?.name, metricValue: privacyNumberFormat(viewers13To17) },
        { metricName: configs[1]?.name, metricValue: privacyNumberFormat(viewers18To20) },
        { metricName: configs[2]?.name, metricValue: privacyNumberFormat(viewers21To24) },
        { metricName: configs[3]?.name, metricValue: privacyNumberFormat(viewers25To34) },
        { metricName: configs[4]?.name, metricValue: privacyNumberFormat(viewers35Plus) },
      ];
    }

    if (currentGraphKey === ProfileSubMetricEnum.GenderUniqueViewers) {
      const { male, female, unknown } = payload!;

      metricRows = [
        { metricName: configs[0]?.name, metricValue: privacyNumberFormat(male) },
        { metricName: configs[1]?.name, metricValue: privacyNumberFormat(female) },
        { metricName: configs[2]?.name, metricValue: privacyNumberFormat(unknown) },
      ];
    }

    if (currentGraphKey === ProfileMetricEnum.UniqueViewersSubscribers) {
      const { uniqueViewersSubscribers } = payload!;

      metricRows = [{ metricName: configs[0]?.name, metricValue: privacyNumberFormat(uniqueViewersSubscribers) }];
    }

    if (currentGraphKey === ProfileMetricEnum.TotalSubscribers) {
      const { totalSubscribers } = payload!;

      metricRows = [{ metricName: configs[0]?.name, metricValue: privacyNumberFormat(totalSubscribers) }];
    }
    if (currentGraphKey === ProfileMetricEnum.TotalTimeViewedMs.toString()) {
      const { totalTimeViewed } = payload!;

      metricRows = [{ metricName: configs[0]?.name, metricValue: formatTimeViewed(totalTimeViewed || 0) }];
    }

    return <AnalyticsTooltip title={name} metricRows={metricRows} />;
  }

  return (
    <>
      {profileGraphs?.map((graph, index) => {
        const currentGraphKey = profileGraphs[index]?.key;
        const graphConfig = graph.config as GraphConfig[];
        return (
          <AnalyticsBarChart
            key={currentGraphKey}
            barChartData={getMetricsData(currentGraphKey) || []}
            barChartTitle={graph.name}
            graphTooltip={graph.graphTooltip}
            isLoading={isLoading}
            yAxisTickFormatter={graph.yAxisTickFormatter}
            bars={graph.config}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            // eslint-disable-next-line react/jsx-no-bind
            tooltipRenderer={data => renderChartTooltip(data, graphConfig, currentGraphKey!)}
          />
        );
      })}
    </>
  );
}

export default injectIntl(ProfileAnalyticsGraph);
