import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import { CrossOrigin } from 'config/constants';
import { camera, send, heartFilled, messageFilled, subscribe, contextMenu, subscribeFilled } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';

import Icon from 'views/common/components/Icon/Icon';

import style from './PublisherEditionMetadata.scss';

import { Publisher } from 'types/publishers';

const mapStateToProps = (state: State) => ({
  publisherDetails: publishersSelectors.getActivePublisherDetails(state),
});

type Props = {
  publisherDetails: Publisher;
  isSpotlightSnap?: boolean;
  snapDescription?: string;
  overrideCTA?: JSX.Element | string;
};

class PublisherEditionMetadata extends React.PureComponent<Props> {
  getCTAMessage(): JSX.Element | string {
    const { overrideCTA, isSpotlightSnap } = this.props;
    if (overrideCTA) {
      return <div data-test="PublisherEditionMetadata.CTA.Override">{overrideCTA}</div>;
    }
    if (isSpotlightSnap) {
      return (
        <FormattedMessage id="reply-preview" description="reply control preview" defaultMessage="Add a reply..." />
      );
    }
    return (
      <FormattedMessage
        id="view-profile-preview"
        description="preview for view profile control"
        defaultMessage="View Profile"
      />
    );
  }

  renderSnapDescription() {
    const { snapDescription, isSpotlightSnap } = this.props;
    return snapDescription && isSpotlightSnap ? <div className={style.description}>{snapDescription}</div> : null;
  }

  render() {
    const { publisherDetails, isSpotlightSnap } = this.props;
    if (!publisherDetails) {
      return null;
    }
    return (
      <div
        className={classNames(style.wrapper, {
          [style.flexEnd]: isSpotlightSnap,
        })}
      >
        <div className={style.header}>
          <div
            className={classNames(style.profileContainer, {
              [style.spotlightProfileContainer]: isSpotlightSnap,
            })}
          >
            <>
              {publisherDetails.defaultFilledIconId ? (
                <Icon
                  className={style.profileIcon}
                  img={createAssetUrl(publisherDetails.defaultFilledIconId)}
                  crossOrigin={CrossOrigin.USE_CREDENTIALS}
                  data-test="PublisherEditionMetadata.publisherLogo.icon"
                />
              ) : null}
            </>
            <div className={style.detailsContainer}>
              <div className={style.profileName}>{publisherDetails.formalName}</div>
              {isSpotlightSnap ? null : <div className={style.publishTime}>{getMessageFromId('today')}</div>}
              {this.renderSnapDescription()}
            </div>
          </div>
          <div
            className={classNames(style.subscribeContainer, {
              [style.flexColumn]: isSpotlightSnap,
            })}
          >
            <Icon inlineIcon={isSpotlightSnap ? subscribeFilled : subscribe} className={style.iconStyle} />
            {isSpotlightSnap ? (
              <div className={style.spotlightIconWrapper}>
                <Icon inlineIcon={heartFilled} className={style.iconStyle} />
                <Icon inlineIcon={messageFilled} className={style.iconStyle} />
                <Icon inlineIcon={send} className={style.iconStyle} />
              </div>
            ) : null}
            <Icon inlineIcon={contextMenu} className={style.iconStyle} />
          </div>
        </div>

        <div className={style.footer}>
          <div className={style.scrollUpBar} />
          <div className={style.footerControlsContainer}>
            <div className={style.iconControlWrapper}>
              <Icon inlineIcon={camera} className={style.iconControl} />
            </div>

            <div className={style.viewProfileBar}>{this.getCTAMessage()}</div>
            <div className={style.iconControlWrapper}>
              <Icon inlineIcon={send} className={style.iconControl} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(PublisherEditionMetadata);
