import * as React from 'react';

import HeaderText from './HeaderText';

type Props = {
  d?: any;
  keyFormatter?: (...args: any[]) => any;
  valueFormatter?: (...args: any[]) => any;
  barColor?: (...args: any[]) => any;
  extraHeaderRows?: any[];
};

/**
 * This is a React-y version of the default tooltip that the NVD3 library produces.
 * Shows a configurable title, the key/value data under the cursor and a color legend.
 */
export default class DefaultHeader extends React.Component<Props> {
  getSeriesRows() {
    return this.props.d.series.map((s: any) => {
      const barColor = this.props.barColor ? this.props.barColor(s.data, this.props.d.index) : s.color;
      return (
        <tr key={`header-series-${s.data.series}`} data-test="header-series-row">
          <td className="legend-color-guide">
            <div style={{ backgroundColor: barColor }} />
          </td>
          {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
          <td className="key">{this.props.keyFormatter(s.key)}</td>
          {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
          <td className="value">{this.props.valueFormatter(s.value)}</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div>
        <table>
          <thead>
            <HeaderText {...this.props} />
          </thead>
          <tbody>
            {this.getSeriesRows()}
            {this.props.extraHeaderRows}
          </tbody>
        </table>
      </div>
    );
  }
}
