import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';

const guidelinesLink = 'https://support.snapchat.com/article/spotlight-guidelines';
const musicGuideLink = 'https://www.snap.com/en-US/music-guidelines';
const spotlightTermsLink = 'https://www.snap.com/terms/spotlight-terms';
const privacyPolicyLink = 'https://www.snap.com/privacy/privacy-policy';

export const makeSpotlightTermsOptions = (onConfirm: () => void): DialogModalOptions => {
  return {
    visible: true,
    onConfirm,
    okText: (
      <FormattedMessage
        id="spotlight-terms-prompt-confirm"
        defaultMessage="Accept and Post to Snapchat"
        description="Button caption to accept spotlight terms"
      />
    ),
    cancelText: (
      <FormattedMessage
        id="spotlight-terms-prompt-cancel"
        defaultMessage="Cancel"
        description="Button caption to dismiss the spotlight terms"
      />
    ),
    title: (
      <FormattedMessage
        id="spotlight-terms-prompt-title"
        defaultMessage="Spotlight Terms"
        description="Spotlight Terms Modal title"
      />
    ),
    body: (
      <>
        <FormattedMessage
          id="spotlight-terms-prompt-body"
          defaultMessage="Discover and share on Spotlight! Snaps on Spotlight are public, viewable on and off Snapchat, and can be remixed. This means others can use your Spotlight Snaps (including audio and video) to create new Snaps or other public content."
          description="text for the guidelines in the spotlight posting terms modal"
        />
        <br />
        <br />
        <FormattedMessage
          id="spotlight-terms-marketing"
          defaultMessage="You can always delete your Snaps in Settings but keep in mind remixes of them can’t be deleted. Make sure Spotlight Snaps follow our {guidelines} and {music}, and be sure to check out the {spotlightTerms} for important information, including on payment eligibility."
          description="text for spotlight content reuse policy section of the spotlight terms modal"
          values={{
            guidelines: (
              <a href={guidelinesLink} target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="spotlight-terms-prompt-guidelines-link"
                  defaultMessage="Guidelines for Spotlight"
                  description="text for the link to the spotlight posting guidelines - https://support.snapchat.com/article/spotlight-guidelines"
                />
              </a>
            ),
            music: (
              <a href={musicGuideLink} target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="spotlight-terms-prompt-music-link"
                  defaultMessage="Music"
                  description="text for the link to the music guidelines - https://www.snap.com/en-US/music-guidelines"
                />
              </a>
            ),
            spotlightTerms: (
              <a href={spotlightTermsLink} target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="spotlight-terms-prompt-posting-terms-link"
                  defaultMessage="Spotlight Terms"
                  description="text for the link to the spotlight posting guidelines - https://www.snap.com/terms/spotlight-terms"
                />
              </a>
            ),
          }}
        />
        <br />
        <br />
        <FormattedMessage
          id="spotlight-terms-body-terms"
          defaultMessage="By clicking ‘Accept’ you accept the {spotlightTerms} and {privacyPolicy}. If you’re submitting Snaps on behalf of someone else, then by clicking 'Accept', you accept the Terms on their behalf and represent that you’re authorized to do so."
          description="Title for the spotlight terms and conditions modal"
          values={{
            spotlightTerms: (
              <a href={spotlightTermsLink} target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="spotlight-terms-body-terms-link"
                  defaultMessage="Spotlight Terms"
                  description="text for the link to the spotlight terms - https://www.snap.com/terms/spotlight-terms"
                />
              </a>
            ),
            privacyPolicy: (
              <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="spotlight-terms-body-terms-privacy-policy"
                  defaultMessage="Privacy Policy"
                  description="text for the link to the Privacy Policy - https://www.snap.com/privacy/privacy-policy"
                />
              </a>
            ),
          }}
        />
      </>
    ),
  };
};
