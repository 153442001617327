import { createSelector as s } from 'reselect';

import { createKeySelector, createDynamicKeySelector, createListOfIdsByProperty } from 'state/common/selectorFactories';

import { EMPTY_OBJECT } from 'config/constants';

const getBase = (state = {}) => {
  return (state as any).publisherUserManagement || {};
};
export const getPublisherUsersLoadingCount = createKeySelector(getBase, 'publisherUsersLoading', 0);
export const getIsSavingUser = s(getBase, base => {
  return base.savingUser > 0;
});
export const getUserWhoNeedsMoreInfoToBeCreated = createKeySelector(getBase, 'userWhoNeedsMoreInfoToBeCreated', null);
export const getPublisherUsers = createKeySelector(getBase, 'publisherUsersByUserId', EMPTY_OBJECT);
export const getPublisherUserById = createDynamicKeySelector(getPublisherUsers, null);
export const getPublisherUserIdsAlphabeticalByName = createListOfIdsByProperty(getPublisherUsers, 'username');
