import React from 'react';
import { FormattedMessage } from 'react-intl';

import { showModal } from 'state/modals/actions/modalsActions';
import { goToHomepage } from 'state/router/actions/routerActions';
import { archiveOnePublisher } from 'state/snapAdmin/actions/snapAdminActions';
import { getPublishers } from 'state/user/selectors/userSelectors';

import withArchivePublisherMutation from 'gql/hocs/withArchivePublisherMutation';
import { ArchivePublisherMutationResult } from 'gql/hooks/useArchivePublisher';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { ModalType } from 'utils/modalConfig';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import type { Publisher, PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as BaseState } from 'types/rootState';

type StateProps = {
  publishers: Publisher[];
};

type ExternalProps = {
  className?: string;
  publisherId: PublisherID;
};

export const mapStateToProps = (state: BaseState): StateProps => {
  return {
    publishers: getPublishers(state),
  };
};

const mapDispatchToProps = {
  archiveOnePublisher,
  showModal,
  goToHomepage,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps & ArchivePublisherMutationResult;

export class ArchiveButton extends React.PureComponent<Props> {
  generateNewActivePublisher = () => {
    if (!this.props.publishers) {
      return null;
    }

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (this.props.publishers[0].id !== this.props.publisherId) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return this.props.publishers[0].id;
    }

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.publishers[1].id;
  };

  rerouteToHomepage = () => {
    this.props.goToHomepage({ overwriteHistory: true, publisherId: this.generateNewActivePublisher() });
  };

  archivePublisher = async () => {
    await this.props.archivePublisher();
    this.rerouteToHomepage();
  };

  handleClick = () => {
    const options: DialogModalOptions = {
      title: (
        <FormattedMessage
          id="archive-publisher"
          defaultMessage="Archive publisher"
          description="Button text to archive a publisher"
        />
      ),
      body: (
        <FormattedMessage
          id="archive-publisher-confirmation"
          defaultMessage="Are you sure you want to archive this account?"
          description="Text confirming that the superadmin wants to archive a publisher"
        />
      ),
      onConfirm: this.archivePublisher,
      visible: true,
    };
    this.props.showModal(ModalType.DIALOG, 'ArchivePublisherConfirmation', options);
  };

  render() {
    return (
      <SDSButton
        type={ButtonType.RED_ON_WHITE}
        onClick={this.handleClick}
        data-test="onboarding.settingsView.superAdmin.archive.button"
      >
        {getMessageFromId('archive-publisher')}
      </SDSButton>
    );
  }
}

export default withArchivePublisherMutation(intlConnect(mapStateToProps, mapDispatchToProps)(ArchiveButton));
