import { gql } from '@apollo/client';

export const GET_TOPICS = gql`
  query GetTopics($input: TopicRequest!) {
    topics(input: $input) {
      topic
    }
  }
`;

type Topic = {
  topic: string;
};

export interface TopicRequest {
  prefix: string;
  businessProfileId: string;
}

export interface TopicsData {
  topics: Topic[];
}
