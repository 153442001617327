import React from 'react';
import InlineSVG from 'svg-inline-react';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/arrow_down.svg.inline' o... Remove this comment to see the full error message
import arrowDown from 'icons/arrow_down.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/arrow_up.svg.inline' or ... Remove this comment to see the full error message
import arrowUp from 'icons/arrow_up.svg.inline';

type Props = {
  isUp: boolean;
  className?: string;
};

const ArrowUpDown = ({ isUp, className }: Props) => {
  const upView = <InlineSVG src={arrowUp} className={className} data-test="arrow-up" />;
  const downView = <InlineSVG src={arrowDown} className={className} data-test="arrow-down" />;

  return isUp ? upView : downView;
};

export default ArrowUpDown;
