import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import * as analyticsSelectors from 'state/analytics/selectors/analyticsSelectors';
import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import { getActivePublisherDetails, isPublishingEnabled } from 'state/publishers/selectors/publishersSelectors';
import { userId } from 'state/user/selectors/userSelectors';

import type { AuthTypeEnum } from 'config/constants';
import { calendar } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { withRouter } from 'utils/routerUtils';

import Icon from 'views/common/components/Icon/Icon';
import { Spinner, SpinnerLabels } from 'views/common/components/Spinner/Spinner';
import EditionTilePreview from 'views/common/containers/EditionTilePreview/EditionTilePreview';

import style from './AnalyticsCalendarView.scss';

import type { AnalyticsStoryMetadata } from 'types/analytics';
import { StoryState } from 'types/editions';
import type { ActivePublisher } from 'types/publishers';
import type { State } from 'types/rootState';

type Props = {
  match: any; // TODO flow: this is coming from react-router-dom, but can't seem to import it?,
  activePublisher: ActivePublisher;
  authToken: string;
  authType: AuthTypeEnum;
  editionWithTileList: AnalyticsStoryMetadata[];
  editionListLoading: number;
  userId: string;
  isPublishingEnabled: boolean;
};

const mapStateToProps = (state: State) => {
  const activePublisher = getActivePublisherDetails(state);

  return {
    authToken: getToken(state),
    authType: getAuthType(state),
    editionWithTileList: analyticsSelectors.getEditionList(state),
    isPublishingEnabled: isPublishingEnabled(state),
    editionListLoading: state.analytics.editionListLoading,
    activePublisher,
    userId: userId(state),
  };
};
const mapDispatchToProps = {};

export class AnalyticsCalendarView extends React.Component<Props> {
  static title = (
    <FormattedMessage
      id="title-publisher-story-analytics-2"
      description="Title Publisher Story Analytics"
      defaultMessage="Story"
    />
  );

  static path = '/publisher/:publisherId/analytics/stories';

  getEditionDate = (edition: AnalyticsStoryMetadata): string => {
    if (!edition.postTime) {
      return '';
    }

    return edition.postTime.utcOffset(-8 * 60).format('M/D');
  };

  getEditionTime = (edition: AnalyticsStoryMetadata): string => {
    if (!edition.postTime) {
      return '';
    }

    return edition.postTime.utcOffset(-8 * 60).format('h:mma');
  };

  renderBottomComponent = (edition: AnalyticsStoryMetadata): ReactNode => {
    return (
      <div className={style.dateRow} data-test="AnalyticsCalendarView.bottomComponent">
        <Icon inlineIcon={calendar} className={style.analyticsIcon} />
        <div className={style.date}>{this.getEditionDate(edition)}</div>
      </div>
    );
  };

  renderEdition = (edition: AnalyticsStoryMetadata) => {
    const { state } = edition;
    const editionStyle = {};
    const classNamesForEditionTileLink = [style.editionCalendarTileLinkSurround];

    if (edition.isEmpty) {
      classNamesForEditionTileLink.push(style.emptyEditionCalendarTileLinkSurround);
    }

    if (state === StoryState.LIVE) {
      classNamesForEditionTileLink.push(style.liveEditionCalendarTileLinkSurround);
    }

    if (!edition.headline) {
      // eslint-disable-next-line no-param-reassign
      edition.headline = getMessageFromId('analytics-calendar-view-headline', {
        date: this.getEditionDate(edition),
        time: this.getEditionTime(edition),
      });
    }

    const previewableEdition = {
      ...edition,
      id: edition.editionId,
      title: edition.headline,
    };

    const analyticsUrl = edition.analyticsURL;
    return (
      <div
        className={classNames(classNamesForEditionTileLink)}
        key={`edition-${edition.editionId}`}
        style={editionStyle}
      >
        {analyticsUrl && (
          <Link to={analyticsUrl} className={style.editionCalendarTileLink}>
            <div className={style.editionCalendarTile}>
              <EditionTilePreview
                edition={previewableEdition}
                tile={edition.tileCover}
                scaleToHeight={280}
                bottomComponent={this.renderBottomComponent(edition)}
              />
            </div>
          </Link>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className={style.grid}>{this.props.editionWithTileList.map(this.renderEdition)}</div>
        <Spinner loading={this.props.editionListLoading} message={SpinnerLabels.LOADING} />
      </div>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsCalendarView));
