import { useQuery } from '@apollo/client';

import { GetSpotlightPosts } from 'gql/queries/analytics/__ssp-generated__/GetSpotlightPosts';
import { GET_SPOTLIGHT_POSTS } from 'gql/queries/analytics/spotlightAnalyticsQuery';

export const useGetSpotlightPosts = (businessProfileId: string, cursor: string) => {
  const { data, loading, fetchMore } = useQuery<GetSpotlightPosts>(GET_SPOTLIGHT_POSTS, {
    variables: {
      businessProfileId,
      cursor,
    },
    // We want fetchMore to set `loading` to true when invoked with new parameters.
    notifyOnNetworkStatusChange: true,
  });
  return { data, loading, fetchMore };
};
