// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'deep... Remove this comment to see the full error message
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import log from 'loglevel';
import moment from 'moment-timezone';

import { magic } from 'utils/apis/magicAPI';
import { getFormattedFullTimestampString } from 'utils/dateUtils';

import { StoryState, LiveEditStatus } from 'types/editions';

export function formatDate(at: any) {
  function twoDigitPad(n: any) {
    return n < 10 ? `0${n}` : n.toString();
  }
  if (!at) {
    return 'null';
  }
  if (typeof at === 'string') {
    return at;
  }
  return [
    `${at.year}-${twoDigitPad(at.monthOfYear)}-${twoDigitPad(at.dayOfMonth)}`,
    `${twoDigitPad(at.hourOfDay)}:${twoDigitPad(at.minuteOfHour)}:${twoDigitPad(at.secondOfMinute)}`,
    `${at.chronology.zone.id}`,
  ].join(' ');
}
// Turns a UTC seconds timestamp into a date object that can be supplied to formatDate().
export function formatUTCSecondsTimestamp(timestamp: any) {
  if (!timestamp) {
    return 'null';
  }
  const date = moment.tz(timestamp * 1000, 'UTC');
  return formatDate({
    year: date.year(),
    monthOfYear: date.month() + 1,
    dayOfMonth: date.date(),
    hourOfDay: date.hour(),
    minuteOfHour: date.minute(),
    secondOfMinute: date.second(),
    chronology: {
      zone: {
        fixed: true,
        id: 'UTC',
      },
    },
  });
}
export function transformToDiffedAuditRecords(auditRecords: any) {
  // Initialize to an empty audit record - this means that the first diff will contain
  // the complete initial state of the entity.
  let previousRecord = { entity: {} };
  // Note the copy (via slice) and reverse in order to apply the diffing from the oldest
  // record through to the newest.
  return auditRecords
    .filter((record: any) => !!record)
    .slice()
    .reverse()
    .map((record: any) => {
      const changes = diffAuditRecords(previousRecord, record);
      previousRecord = record;
      return {
        auditee: record.auditeeName,
        action: record.action,
        timestamp: record.timestamp,
        diff: changes,
      };
    })
    .reverse();
}
function diffAuditRecords(recordA: any, recordB: any) {
  const entityA = _.get(recordA, ['entity']);
  const entityB = _.get(recordB, ['entity']);
  if (!entityA || !entityB) {
    log.warn('Missing entity when trying to calculate audit record diff!', entityA, entityB);
  }
  const diffResult = diff(stripLastUpdatedAt(entityA), stripLastUpdatedAt(entityB));
  return simplifyDiff(diffResult, entityA, entityB);
}
// Returns a formatted datetime string rather than dumping the entire DateTime object
export function dateTimeSimplifier(diffResult: any, key: any, entityA: any, entityB: any) {
  const dateTime = entityB[key];
  return dateTime ? formatDate(dateTime) : undefined;
}
// Returns the from/to indexes for a snap ID array diff where the contents are the same
// but one snap has been moved
function findMove(prevSnapIds: any, currentSnapIds: any) {
  function isMoveFromTo(fromSnapIds: any, toSnapIds: any, first: any, last: any) {
    return (
      _.isEqual(toSnapIds.slice(first, last), fromSnapIds.slice(first + 1, last + 1)) &&
      fromSnapIds[first] === toSnapIds[last]
    );
  }
  const diffIndexes = prevSnapIds
    .map((snapId: any, index: any) => index)
    .filter((index: any) => prevSnapIds[index] !== currentSnapIds[index]);
  if (diffIndexes.length > 0) {
    const first = diffIndexes[0];
    const last = diffIndexes[diffIndexes.length - 1];
    // forward move
    if (isMoveFromTo(prevSnapIds, currentSnapIds, first, last)) {
      return { from: first, to: last };
    }
    // backward move
    if (isMoveFromTo(currentSnapIds, prevSnapIds, first, last)) {
      return { from: last, to: first };
    }
  }
  return null;
}
export function transformDiffedAuditRecordsForDebug(auditRecords: any) {
  return auditRecords.map((record: any) => {
    return [
      ['Auditee', record.auditee],
      ['Action', record.action],
      ['Timestamp', formatUTCSecondsTimestamp(record.timestamp / 1000)],
      ['Diff', { type: 'json', object: record.diff, isExpanded: (keypath: any) => true }],
    ];
  });
}
export function transformDiffedAuditRecordsForLegible(auditRecords: any) {
  return auditRecords
    .map((record: any) => {
      try {
        const legibleDiffAction = transformAuditDiffToLegibleAction(record.diff);
        if (legibleDiffAction === undefined) {
          return undefined;
        }
        const legibleAuditee = transformAuditeeToLegible(record.auditee);
        return [
          [
            'Action',
            // @ts-expect-error ts-migrate(2569) FIXME: Type 'string | (string | { href: string; label: an... Remove this comment to see the full error message
            [[`${legibleAuditee} `, ...legibleDiffAction, ` at ${getFormattedFullTimestampString(record.timestamp)}`]],
          ],
        ];
      } catch (e) {
        log.error(e);
        return undefined;
      }
    })
    .filter((record: any) => !!record);
}
function transformAuditeeToLegible(auditee: any) {
  if (auditee.startsWith('anonymous')) {
    return 'System';
  }
  const auditeeName = auditee.split(' - ')[0];
  return auditeeName;
}
function transformAuditDiffToLegibleAction(auditDiff: any) {
  if (auditDiff.state) {
    if (auditDiff.state === StoryState.SCHEDULED) {
      return `scheduled story to go live at ${auditDiff.startDate}`;
    }
    if (auditDiff.state === StoryState.LIVE) {
      return 'published story';
    }
    if (auditDiff.state === StoryState.NEW) {
      return auditDiff.createdAt ? 'created story' : 'unscheduled story';
    }
    return `changed story state to ${auditDiff.state}`;
  }
  if (auditDiff.liveEditStatus) {
    if (auditDiff.liveEditStatus === LiveEditStatus.NONE_OR_PUBLISHED) {
      return 'published live edit';
    }
    if (auditDiff.liveEditStatus === LiveEditStatus.IN_PROGRESS) {
      return 'started live edit';
    }
    return 'finished live edit';
  }
  const tiles = auditDiff.tiles;
  if (tiles !== undefined) {
    const tileKeys = Object.keys(tiles);
    if (tileKeys.length !== 0) {
      // We assume we only get a single key for each tile operation
      const tileId = tileKeys[0];
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const tile = tiles[tileId];
      if (tile !== undefined) {
        if (tile.baseImageAssetId !== undefined) {
          return [
            'changed tile media to ',
            {
              href: magic.getSearch({ searchItem: tile.baseImageAssetId }),
              label: tile.baseImageAssetId,
              type: 'link',
            },
          ];
        }
        if (tile.headline !== undefined) {
          return [`changed tile ${tileId} header to ${tile.headline}`];
        }
      }
    }
  }
  if (auditDiff.snapIds) {
    if (auditDiff.snapIds.Move) {
      return `moved snap ${auditDiff.snapIds.Move}`;
    }
    if (auditDiff.snapIds.Additions) {
      return `added snap ${auditDiff.snapIds.Additions}`;
    }
    if (auditDiff.snapIds.Deletions) {
      return `removed snap ${auditDiff.snapIds.Deletions}`;
    }
  }
  return undefined;
}
// Simplifies snap ID diffs to detect and report snap additions, deletions and movements
export function snapIdSimplifier(diffResult: any, key: any, entityA: any, entityB: any) {
  const { snapIds } = diffResult;
  if (snapIds && entityA.snapIds && entityB.snapIds) {
    const sortedSnapIdsPrev = entityA.snapIds.slice().sort();
    const sortedSnapIdsCurrent = entityB.snapIds.slice().sort();
    if (_.isEqual(sortedSnapIdsPrev, sortedSnapIdsCurrent)) {
      // No additions/deletions, just moving snaps around to change the order
      const movement = findMove(entityA.snapIds, entityB.snapIds);
      if (movement) {
        return {
          Move: `${entityA.snapIds[movement.from]} position ${movement.from} -> ${movement.to}`,
        };
      }
      // Can't figure it out (maybe multiple movements but that shouldn't happen in one update).
      // Just return everything
      return snapIds;
    }
    // must be some additions and/or deletions
    const additions = entityB.snapIds.filter((snapId: any) => snapId && !entityA.snapIds.includes(snapId));
    const deletions = entityA.snapIds.filter((snapId: any) => snapId && !entityB.snapIds.includes(snapId));
    const result = {};
    if (additions.length > 0) {
      (result as any).Additions = additions;
    }
    if (deletions.length > 0) {
      (result as any).Deletions = deletions;
    }
    return result;
  }
  return snapIds;
}
const diffSimplifiers = {
  createdAt: dateTimeSimplifier,
  startDate: dateTimeSimplifier,
  endDate: dateTimeSimplifier,
  firstLiveDate: dateTimeSimplifier,
  lastLiveDate: dateTimeSimplifier,
  lastPublishDate: dateTimeSimplifier,
  snapIds: snapIdSimplifier,
};
function simplifyDiff(diffResult: any, entityA: any, entityB: any) {
  const simplifiedDiff = {};
  Object.keys(diffResult).forEach(key => {
    const original = diffResult[key];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const simplifier = diffSimplifiers[key];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    simplifiedDiff[key] = simplifier ? simplifier(diffResult, key, entityA, entityB) : original;
  });
  return simplifiedDiff;
}
// The lastUpdatedAt timestamp adds noise to the audit history diff because it
// changes on every update - stripping it makes the history easier to read.
function stripLastUpdatedAt(obj = {}) {
  const copy = { ...obj };
  delete (copy as any).lastUpdatedAt;
  return copy;
}
