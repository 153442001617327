import { gql } from '@apollo/client';
import log from 'loglevel';

import { useProxyMutation } from 'utils/apis/graphQLUtils';

export type UpdatePublisherMutationResult = {
  updatePublisherError?: string;
  updatePublisherData?: any;
  updatePublisher?: any;
};

export const EXTERNAL_USER_UPDATE_PUBLISHER_DETAILS = gql`
  mutation externalUpdatePublisherDetails($updatedFieldsInput: ExternalUpdatePublisherInput) {
    externalUserUpdatePublisher(input: $updatedFieldsInput) {
      __typename
      ... on Publisher {
        id
        businessProfileId

        description
        defaultFilledIconId
        horizontalIconId
        primaryColor
        secondaryColor
        squareHeroImageBitmojiTemplateId
        squareHeroImageId
        tags {
          SCC
          WIKI
          MANUAL
        }
        tileLogos {
          mediaId
          color
        }
        websiteUrl

        defaultNumSnaps
        dynamicEditionAutoCreateStoryTimeUTC {
          hour
          minute
        }
        targetSnapLengthMs
      }
      ... on ErrorInvalidArgument {
        message
      }
    }
  }
`;

export const UPDATE_PUBLISHER_DETAILS = gql`
  mutation updatePublisherDetails($updatedFieldsInput: UpdatePublisherInput, $isSuperAdmin: Boolean!) {
    updatePublisher(input: $updatedFieldsInput) {
      publishers {
        id
        businessProfileId
        hostUsername
        defaultFilledIconId
        defaultNumSnaps
        description
        formalName @include(if: $isSuperAdmin)
        horizontalIconId
        primaryColor
        profileLogoDisplay @include(if: $isSuperAdmin)
        secondaryColor
        squareHeroImageBitmojiTemplateId
        squareHeroImageId
        tags {
          SCC
          WIKI
          MANUAL
        }
        targetSnapLengthMs
        tileLogos {
          mediaId
          color
        }
        websiteUrl
        mutablePublisherName
        dynamicEditionAutoCreateStoryTimeUTC {
          hour
          minute
        }
        advertisingEnabled @include(if: $isSuperAdmin)
        advancedAdsEnabled @include(if: $isSuperAdmin)
        advancedAdsLatestFirstSlot @include(if: $isSuperAdmin)
        advancedAdsMaxSlots @include(if: $isSuperAdmin)
        advancedAdsMinSlots @include(if: $isSuperAdmin)
        advancedAdsMinSpacing @include(if: $isSuperAdmin)
        defaultAdSlots @include(if: $isSuperAdmin)
        defaultAdSlotsMillis @include(if: $isSuperAdmin)
        isComScoreIntegrationEnabled @include(if: $isSuperAdmin)
        subscribable @include(if: $isSuperAdmin)
        topsnapLimit @include(if: $isSuperAdmin)
        rollingNewsEnabled @include(if: $isSuperAdmin)
        videoModeEnabled @include(if: $isSuperAdmin)
        adSetting @include(if: $isSuperAdmin)
        moderationLevel @include(if: $isSuperAdmin)
        audioClassification @include(if: $isSuperAdmin)
        adsPreRollEnabled @include(if: $isSuperAdmin)
        dynamicAdsEnabled @include(if: $isSuperAdmin)
        webviewLimit @include(if: $isSuperAdmin)
        tier @include(if: $isSuperAdmin)
        type @include(if: $isSuperAdmin)
        isOurStories @include(if: $isSuperAdmin)
        publisherFeatures @include(if: $isSuperAdmin)
        articleCSS @include(if: $isSuperAdmin)
        nicknames @include(if: $isSuperAdmin)
        websiteUrl @include(if: $isSuperAdmin)
        primaryLanguage @include(if: $isSuperAdmin)
        homeCountry @include(if: $isSuperAdmin)
        defaultShareOption @include(if: $isSuperAdmin)
        geofenceIds @include(if: $isSuperAdmin)
        ageGate @include(if: $isSuperAdmin)
        allowlistCountry @include(if: $isSuperAdmin)
        blocklistCountry @include(if: $isSuperAdmin)
        allowlistDistributionCountry @include(if: $isSuperAdmin)
        blocklistDistributionCountry @include(if: $isSuperAdmin)
        allowlistLanguage @include(if: $isSuperAdmin)
        blocklistLanguage @include(if: $isSuperAdmin)
        isEmployeeOnly @include(if: $isSuperAdmin)
        searchable @include(if: $isSuperAdmin)
        localContent @include(if: $isSuperAdmin)
        publishingApprovals @include(if: $isSuperAdmin)
        contentAccessLists @include(if: $isSuperAdmin) {
          DISCOVER_FEED {
            isAllowlist
            list
          }
          SEARCH {
            isAllowlist
            list
          }
          SNAPCODE {
            isAllowlist
            list
          }
          SHARE {
            isAllowlist
            list
          }
        }
        publishingEnabled @include(if: $isSuperAdmin)
        regions @include(if: $isSuperAdmin)
        defaultSubtitlesLanguage @include(if: $isSuperAdmin)
        managerEmail @include(if: $isSuperAdmin)
        managerName @include(if: $isSuperAdmin)
        editorialRationale @include(if: $isSuperAdmin)
        editorialApproval @include(if: $isSuperAdmin)
        urlSafeFormalName @include(if: $isSuperAdmin)
      }
    }
  }
`;

export function useExternalUpdatePublisherMutation() {
  const [updatePublisher, { data, error }] = useProxyMutation(EXTERNAL_USER_UPDATE_PUBLISHER_DETAILS);

  if (error) {
    log.error('Failed to update publisher', error);
    return { error };
  }
  return { updatePublisher, data };
}

export function useUpdatePublisherMutation() {
  const [updatePublisher, { data, error }] = useProxyMutation(UPDATE_PUBLISHER_DETAILS);

  if (error) {
    log.error('Failed to update publisher', error);
    return {
      error,
    };
  }

  return { updatePublisher, data };
}
