import is from 'is_js';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DailyGraph, PT_TIME_ZONE } from 'config/constants';
import { enumObject } from 'utils/enum';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import { AnalyticsStatsType } from 'types/analytics';

const PRIVACY_NUM = 0;
const MS_PER_HOUR = 60 * 60 * 1000;
const DEFAULT_INTL_FUNC = (id: any) => id;
export const GRAPH_SHADES_OF_BLUE = enumObject({
  EXTRA_LIGHT_BLUE: '#9ADCFF',
  LIGHT_BLUE: '#61C9FF',
  NORMAL_BLUE: '#0EADFF',
  MED_BLUE: '#0096E5',
  STRONG_BLUE: '#0076B5',
  EXTRA_STRONG_BLUE: '#034C73',
});
export const GRAPH_SHADES_OF_GREEN = enumObject({
  EXTRA_LIGHT_GREEN: '#AEFBE3',
  LIGHT_GREEN: '#86FDCB',
  NORMAL_GREEN: '#24F4B2',
  MED_GREEN: '#00E59C',
  STRONG_GREEN: '#00B57C',
  EXTRA_STRONG_GREEN: '#029969',
});

const SERIES_CHART_COLOURS = enumObject({
  BLUE: '#0055FF',
  RED: '#F94768',
});

export const MULTICHART_CONFIG = {
  [DailyGraph.UNIQUE_DAU]: [
    {
      name: 'uniqueSubscriberViewers',
      descriptionId: 'daily-audience-daily-follower-uniques',
    },
    {
      name: 'uniqueNonSubscriberViewers',
      descriptionId: 'daily-audience-daily-non-followers-uniques',
    },
  ],
  [DailyGraph.USER_LOYALTY]: [
    {
      name: 'userLoyaltyDau1Day',
      descriptionId: 'user-loyalty-new-users-1-day-per-week',
    },
    {
      name: 'userLoyaltyDau2Day',
      descriptionId: 'user-loyalty-returning-users-2-days-per-week',
    },
    {
      name: 'userLoyaltyDau34Day',
      descriptionId: 'user-loyalty-frequent-users-3-4-days-per-week',
    },
    {
      name: 'userLoyaltyDau567Day',
      descriptionId: 'user-loyalty-loyal-users-5-7-days-per-week',
    },
  ],
  [DailyGraph.GENDER_DEMOGRAPHICS]: [
    {
      name: 'uniqueMaleViewers',
      descriptionId: 'demographics-male',
    },
    {
      name: 'uniqueFemaleViewers',
      descriptionId: 'demographics-female',
    },
    {
      name: 'uniqueUnknownViewers',
      descriptionId: 'demographics-unknown',
    },
  ],
  [DailyGraph.AGE_DEMOGRAPHICS]: [
    {
      name: 'age13to17Viewers',
      descriptionId: 'demographics-age-13-to-17',
    },
    {
      name: 'age18to24Viewers',
      descriptionId: 'demographics-age-18-to-24',
    },
    {
      name: 'age25to34Viewers',
      descriptionId: 'demographics-age-25-to-34',
    },
    {
      name: 'age35PlusViewers',
      descriptionId: 'demographics-age-35-plus',
    },
    {
      name: 'ageUnknownViewers',
      descriptionId: 'demographics-unknown',
    },
  ],
  [DailyGraph.VIEW_COUNTS]: [
    {
      name: 'topsnapViewCount',
      descriptionId: 'daily-behavior-topsnap-views',
    },
    {
      name: 'longformVideoViewCount',
      descriptionId: 'daily-behavior-video-attachment-views',
    },
    {
      name: 'longformTextViewCount',
      descriptionId: 'daily-behavior-article-attachment-views',
    },
  ],
  [DailyGraph.SCREENSHOT_SHARES]: [
    {
      name: 'screenshotCount',
      descriptionId: 'daily-behavior-screenshots',
    },
    {
      name: 'shareCount',
      descriptionId: 'daily-behavior-shares',
    },
  ],
};
export const getEditionTimeBasedGraphs = (intlFunc = DEFAULT_INTL_FUNC, analyticsStatsType: any) => [
  {
    dataFunc: edition24HoursDataFunc(
      analyticsStatsType === AnalyticsStatsType.ALL
        ? [
            {
              name: intlFunc('analytics-unique-viewers'),
              value: 'editionHau',
              extras: { color: SERIES_CHART_COLOURS.BLUE },
            },
            {
              name: intlFunc('analytics-average'),
              value: 'averageHau',
              extras: { color: SERIES_CHART_COLOURS.RED },
            },
          ]
        : [
            {
              name: intlFunc('analytics-unique-viewers-organic'),
              value: 'organicEditionHau',
              extras: { color: SERIES_CHART_COLOURS.BLUE },
            },
            {
              name: intlFunc('analytics-unique-viewers-paid'),
              value: 'paidEditionHau',
              extras: { color: SERIES_CHART_COLOURS.RED },
            },
          ],
      analyticsStatsType
    ),
    props: {
      graphId: 'daily-hourly-single-day',
      graphName: getMessageFromId('publisher-story-chart-daily-hourly'),
      chartType: 'lineChart',
      tooltip: getMessageFromId('publisher-story-chart-unique-viewers-explanation'),
      showPoints: true,
      additionalProps: {
        interactiveLayer: {
          tooltip: {
            headerFormatter: (x: any) =>
              `${numberFormatter.showAsTime(x)} - ${numberFormatter.showAsTime(x + MS_PER_HOUR)}`,
          },
        },
        xAxis: { tickFormat: numberFormatter.showAsTime },
        noData: 'No Data',
      },
    },
  },
  {
    dataFunc: editionDailyDataFunc(
      analyticsStatsType === AnalyticsStatsType.ALL
        ? [
            {
              name: intlFunc('analytics-unique-viewers'),
              value: 'editionDau',
              extras: { color: SERIES_CHART_COLOURS.BLUE },
            },
          ]
        : [
            {
              name: intlFunc('analytics-unique-viewers-organic'),
              value: 'organicEditionDau',
              extras: { color: SERIES_CHART_COLOURS.BLUE },
            },
            {
              name: intlFunc('analytics-unique-viewers-paid'),
              value: 'paidEditionDau',
              extras: { color: SERIES_CHART_COLOURS.RED },
            },
          ],
      analyticsStatsType
    ),
    props: {
      graphId: 'daily-hourly-multi-day',
      graphName: getMessageFromId('publisher-story-chart-daily-hourly'),
      chartType: 'lineChart',
      tooltip: getMessageFromId('publisher-story-chart-unique-viewers-explanation'),
      showPoints: true,
      additionalProps: {
        interactiveLayer: {
          tooltip: {
            headerFormatter: numberFormatter.showAsDate,
          },
        },
        xAxis: { tickFormat: numberFormatter.showAsDate },
        noData: 'No Data',
      },
    },
  },
];
export const EDITION_POLL_TIMELINE_GRAPHS = {
  getPollTimelineGraphs,
};
// Number of graphs is dynamic - depends on number of polls in the Edition
function getPollTimelineGraphs(data: any) {
  if (!data || !data.pollTimeline || !data.pollTimeline.pollTimelines) {
    return [];
  }
  const { pollTimelines } = data.pollTimeline;
  return Object.keys(pollTimelines)
    .filter(pollId => {
      const { snap } = pollTimelines[pollId];
      return snap && snap.questions && snap.questions.length === 1;
    })
    .map(pollId => {
      const pollTimeline = pollTimelines[pollId];
      const pollStart = numberFormatter.showAsDateAndTime(pollTimeline.startTimestamp);
      const pollEnd = numberFormatter.showAsDateAndTime(pollTimeline.endTimestamp);
      const pollTitle = _.get(pollTimeline.snap, ['relatedSnaps', 'TOP', 'name'], '');
      const graph = {
        pollTimeline,
        props: {
          graphId: 'poll-timeline',
          graphName: (
            <FormattedMessage
              id="publisher-story-poll-timeline-graph"
              description="Title of the poll, retrieved directly from snap"
              defaultMessage="Poll: {pollTitle}"
              values={{
                pollTitle,
                pollId,
              }}
            />
          ),
          chartType: 'lineChart',
          tooltip: <span />,
          showPoints: false,
          additionalProps: {
            xAxis: {
              tickFormat: numberFormatter.showAsTime,
              axisLabel: `${pollStart} - ${pollEnd}`,
            },
            interpolate: 'linear',
            interactiveLayer: {
              tooltip: {
                pollTimeline,
                headerFormatter: numberFormatter.showAsDateAndTime,
              },
            },
          },
        },
      };
      const { timelinesByChoice } = pollTimelines[pollId];
      const questions = pollTimelines[pollId].snap.questions || [];
      const pollChoices = (questions[0] && questions[0].options) || [];
      const seriesList = pollChoices
        .map((choice: any, index: any) => {
          return {
            pollId,
            choiceId: choice.id,
            name: `Choice ${index + 1}`,
            value: 'runningTotal',
          };
        })
        .filter((choice: any) => {
          // Only show choices for which we have some timeline data
          return timelinesByChoice[choice.choiceId] !== undefined;
        });
      (graph as any).dataFunc = pollTimelineDataFunc(seriesList);
      return graph;
    });
}
export function fillMissingDates(dates: any) {
  if (dates.length <= 1) {
    return dates;
  }
  let startDate = moment.tz(dates[0], 'YYYY-MM-DD', PT_TIME_ZONE);
  let endDate = moment.tz(dates[0], 'YYYY-MM-DD', PT_TIME_ZONE);
  dates.forEach((dateStr: any) => {
    const date = moment.tz(dateStr, 'YYYY-MM-DD', PT_TIME_ZONE);
    if (date.isBefore(startDate)) {
      startDate = date;
    }
    if (date.isAfter(endDate)) {
      endDate = date;
    }
  });
  const currDate = startDate;
  const returnDates = [currDate.format('YYYY-MM-DD')];
  do {
    currDate.add(1, 'days');
    returnDates.push(currDate.format('YYYY-MM-DD'));
  } while (!currDate.isSame(endDate));
  return returnDates;
}
const deserializeDate = _.memoize(str => moment.tz(str, 'YYYY-MM-DD', PT_TIME_ZONE).valueOf());
const deserializeTime = _.memoize(str => moment.tz(str, 'h:m', 'UTC').valueOf());
function edition24HoursDataFunc(seriesConfig: any, analyticsStatsType: any) {
  return dataFuncProvider(seriesConfig, {
    iterator(data: any) {
      if (!data?.edition24Hours?.[AnalyticsStatsType.ALL]?.hourlyMetrics) {
        return [];
      }
      return Object.keys(data?.edition24Hours?.[AnalyticsStatsType.ALL]?.hourlyMetrics);
    },
    datasetFunc(data: any, hour: any) {
      if (analyticsStatsType === AnalyticsStatsType.ALL) {
        return data?.edition24Hours?.[AnalyticsStatsType.ALL]?.hourlyMetrics?.[hour];
      }
      const paidEditionHau = data?.edition24Hours?.[AnalyticsStatsType.PAID]?.hourlyMetrics?.[hour]?.editionHau || 0;

      return {
        organicEditionHau:
          data?.edition24Hours?.[AnalyticsStatsType.ALL]?.hourlyMetrics?.[hour]?.editionHau - paidEditionHau,
        paidEditionHau,
      };
    },
    keyFunc(data: any, timeOfDay: any) {
      return deserializeTime(timeOfDay);
    },
  });
}
function editionDailyDataFunc(seriesConfig: any, analyticsStatsType: any) {
  return dataFuncProvider(seriesConfig, {
    iterator(data: any) {
      if (!data?.editionDaily?.[AnalyticsStatsType.ALL]?.dailyMetrics) {
        return [];
      }

      const keys = Object.keys(data?.editionDaily?.[AnalyticsStatsType.ALL]?.dailyMetrics);

      keys.sort();

      return keys;
    },
    datasetFunc(data: any, date: any) {
      if (analyticsStatsType === AnalyticsStatsType.ALL) {
        return data?.editionDaily?.[AnalyticsStatsType.ALL]?.dailyMetrics?.[date];
      }
      const paidEditionDau = data?.editionDaily?.[AnalyticsStatsType.PAID]?.dailyMetrics[date]?.editionDau || 0;

      return {
        organicEditionDau:
          data?.editionDaily?.[AnalyticsStatsType.ALL]?.dailyMetrics[date]?.editionDau - paidEditionDau,
        paidEditionDau,
      };
    },
    keyFunc(data: any, date: any) {
      return deserializeDate(date);
    },
  });
}
function pollTimelineDataFunc(seriesConfig: any) {
  return dataFuncProvider(seriesConfig, {
    iterator(data: any, config: any, series: any) {
      if (!data || !data.pollTimeline || !data.pollTimeline.pollTimelines) {
        return [];
      }
      const { timelinesByChoice } = data.pollTimeline.pollTimelines[series.pollId];
      if (!timelinesByChoice || !timelinesByChoice[series.choiceId]) {
        return [];
      }
      return Object.keys(timelinesByChoice[series.choiceId]);
    },
    datasetFunc(data: any, timelineEntryIndex: any, series: any) {
      const timeline = data.pollTimeline.pollTimelines[series.pollId].timelinesByChoice[series.choiceId];
      return timeline[timelineEntryIndex];
    },
    keyFunc(data: any, timelineEntryIndex: any, dataset: any, series: any) {
      return dataset.timestamp;
    },
  });
}
export function dataFuncProvider(series: any, config: any) {
  return (data: any, externalConfig = {}) =>
    series.map((ser: any) => seriesFunc(data, config, ser, externalConfig)).filter(Boolean);
}
// externalConfig { isGlobal, isSingleCountry, isSubscribable }
function seriesFunc(data: any, config: any, series: any, externalConfig: any) {
  const { iterator } = config;
  const { datasetFunc } = config;
  const keyFunc = config.keyFunc || defaultKeyFunc;
  const valueFunc = config.valueFunc || defaultValueFunc;
  const colorFunc = config.colorFunc || defaultColorFunc;
  const { isGlobal } = externalConfig;
  if (isGlobal && series.hideForGlobal) {
    return false;
  }
  const values = iterator(data, config, series, externalConfig).map((el: any, index: any) => {
    const dataset = datasetFunc(data, el, series);
    const key = keyFunc(data, el, dataset, series, index);
    const value = valueFunc(data, el, dataset, series, index);
    const color = colorFunc(data, el, dataset, series, index);
    const ret = {};
    (ret as any).x = key;
    (ret as any).y = value;
    if (color) {
      (ret as any).color = color;
    }
    return ret;
  });
  let result = { key: series.name, values, ...series.extras };
  if (config.postProcessFunc) {
    result = config.postProcessFunc(result);
  }
  return result;
}
function defaultKeyFunc(data: any, el: any, dataset: any, series: any, index: any) {
  return index;
}
function defaultValueFunc(data: any, el: any, dataset: any, series: any) {
  const valueKey = series.value;
  let val;
  if (is.string(valueKey)) {
    val = parseFloat(dataset[valueKey]);
  } else if (is.array(valueKey)) {
    val = valueKey.reduce((prevValue: any, currKey: any) => prevValue + parseFloat(dataset[currKey]), 0);
  } else if (is.function(valueKey)) {
    val = valueKey(dataset);
  } else {
    console.error('Unknown valueKey', valueKey); // eslint-disable-line no-console
  }
  return is.not.number(val) ? PRIVACY_NUM : val;
}
function defaultColorFunc(data: any, el: any, dataset: any, series: any, index: any) {
  // No specific bar color set, will revert to overall graph color
  return undefined;
}
