import { useQuery } from '@apollo/client';

import { GetProfileAnalytics } from 'gql/queries/analytics/__ssp-generated__/GetProfileAnalytics';
import { GET_PROFILE_ANALYTICS } from 'gql/queries/analytics/profileAnalyticsQuery';

export const useGetProfileAnalytics = (hostUserId: string, start: string, end: string) => {
  const { data, loading } = useQuery<GetProfileAnalytics>(GET_PROFILE_ANALYTICS, {
    variables: {
      input: {
        hostUserId,
        start,
        end,
      },
    },
  });
  return { data, loading };
};
