import type { RowProps } from 'antd/lib/row';
import Row from 'antd/lib/row';
import React, { memo } from 'react';

type Props = RowProps;

function SDSRow(props: Props) {
  return <Row {...props} />;
}

export default memo(SDSRow);
