import React from 'react';

import * as publisherToolsSelectors from 'state/publisherTools/selectors/publisherToolsSelectors';

import { TileFormatConfig, TileFormat } from 'config/tileConfig';
import type { TileConfig } from 'config/tileConfig';
import { intlConnect } from 'utils/connectUtils';

import TilePreview from 'views/editor/containers/TilePreview/TilePreview';

import type { EditionID } from 'types/editions';
import type { State } from 'types/rootState';
import type { Tile } from 'types/tiles';

export type EditionPreviewConfig = {
  editionId: EditionID;
  headline: string;
  tileId: string;
};

type Props = {
  edition: EditionPreviewConfig;
  className: string;
  tile: Tile;
  tileFormat: TileConfig;
  omitHeadline?: boolean;
  tileImageUrl?: string;
  tileLogoUrl?: string;
  tileHeadline?: string;
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { edition } = ownProps;
  let tile = publisherToolsSelectors.getTileForEditionByScsId(state)(edition.editionId, edition.tileId);
  if (!tile) {
    // If we can't find the tile it's probably a legacy Discover tile identified by the cropped image asset ID
    tile = publisherToolsSelectors.getTileForEditionByCroppedImageAssetId(state)(edition.editionId, edition.tileId);
  }

  return {
    tileFormat: ownProps.tileFormat ? ownProps.tileFormat : TileFormatConfig[TileFormat.CHEETAH_DEFAULT],
    tile,
  };
};
const mapDispatchToProps = {};

export class CheetahAnalyticsTilePreview extends React.PureComponent<Props> {
  render() {
    return (
      <div>
        <TilePreview
          playOnHover
          roundCorners
          tile={this.props.tile}
          format={this.props.tileFormat}
          omitHeadline={this.props.omitHeadline}
          useSmallHeadline
          dummyLogo={this.props.tileLogoUrl}
          dummyHeadline={this.props.tileHeadline}
          tileImageUrl={this.props.tileImageUrl}
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(CheetahAnalyticsTilePreview);
