import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { AnalyticsReportRange } from 'views/analytics/utils/kpiConfigs';
import SDSDropdown from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';

import style from './SpotlightAnalyticsHeader.scss';

type Props = {
  currentRange: AnalyticsReportRange;
  setReportRange: (reportRange: AnalyticsReportRange) => void;
};

const DATE_RANGE_OPTIONS = [
  {
    label: (
      <FormattedMessage
        id="spotlightanalytics-header-weekly"
        description="Label for spotlight analytics header weekly"
        defaultMessage="Past 7 Days"
      />
    ),
    value: AnalyticsReportRange.WEEK.toString(),
  },
  {
    label: (
      <FormattedMessage
        id="spotlightanalytics-header-monthly"
        description="Label for spotlight analytics header monthly"
        defaultMessage="Past 28 Days"
      />
    ),
    value: AnalyticsReportRange.MONTH.toString(),
  },
];

export function SpotlightAnalyticsHeader({ currentRange, setReportRange }: Props) {
  const onReportRangeUpdated = useCallback(
    (reportRange: AnalyticsReportRange) => {
      setReportRange(reportRange);
    },
    [setReportRange]
  );
  const renderDateFilterButton = () => {
    return (
      <SDSDropdown
        onChange={onReportRangeUpdated}
        value={currentRange?.toString() || AnalyticsReportRange.WEEK.toString()}
        disableClear
        data-test="spotlightAnalyticsRange.dropdown"
      >
        {createSDSDropdownOptions(DATE_RANGE_OPTIONS)}
      </SDSDropdown>
    );
  };

  return <div className={style.headerButtonContainer}>{renderDateFilterButton()}</div>;
}
