import { isArrayBuffer } from 'lodash';

import { EncryptResult } from 'types/media';

export function readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = event => {
      const data = event.target?.result;
      if (isArrayBuffer(data)) {
        resolve(data);
      } else {
        reject("Didn't read file as array buffer");
      }
    };

    reader.onerror = event => {
      const error = event?.target?.error || undefined;
      reject(`Error reading file ${error}`);
    };

    reader.readAsArrayBuffer(file);
  });
}

export async function encryptAESCBC(file: File): Promise<EncryptResult> {
  const iv = crypto.getRandomValues(new Uint8Array(16));
  const key = await crypto.subtle.generateKey({ name: 'AES-CBC', length: 256 }, true, ['encrypt', 'decrypt']);

  const data = await readAsArrayBuffer(file);

  const encryptedData = await crypto.subtle.encrypt({ name: 'AES-CBC', iv }, key, data);
  const exportedKey = await crypto.subtle.exportKey('raw', key);

  return {
    data: encryptedData,
    iv,
    key: new Uint8Array(exportedKey),
  };
}
