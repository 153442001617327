import { BroadcastChannel } from 'broadcast-channel';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { setAssociatedUserToken } from 'state/auth/actions/associatedUserAuthActions';
import {
  getAssociatedPublisher,
  getAssociatedUsername,
  getAuthType,
  getIsAssociating,
} from 'state/auth/selectors/authSelectors';
import {
  getActivePublisherBusinessProfileId,
  getPublisherHostUsername,
} from 'state/publishers/selectors/publishersSelectors';

import { AuthType, ZENDESK_BASE_URL } from 'config/constants';
import { externalLink } from 'icons/SDS/allIcons';
import { login as loginRoutes } from 'utils/apis/localRoutes';
import { snapSSO } from 'utils/apis/snapSSOAPI';
import { intlConnect } from 'utils/connectUtils';
import { openInNewWindow } from 'utils/locationUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import Spinner from 'views/common/components/Spinner/Spinner';
import style from 'views/onboarding/components/UserAssociationView/UserAssociationView.scss';

import { USERNAME_AUTH_BROADCAST_CHANNEL } from './UserAssociationConstants';

import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';

const mapStateToProps = (state: State) => {
  return {
    businessProfileId: getActivePublisherBusinessProfileId(state) || '',
    hostUsername: getPublisherHostUsername(state) || undefined,
    isAssociating: getIsAssociating(state),
    associatedPublisher: getAssociatedPublisher(state),
    associatedUsername: getAssociatedUsername(state) || undefined,
    isGoogleAuth: getAuthType(state) === AuthType.GOOGLE,
  };
};

const mapDispatchToProps = {
  setAssociatedUserToken,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

const selectAccountText = (
  <FormattedMessage
    id="user-association-select-account"
    description="Select account button text"
    defaultMessage="Select account"
  />
);

const changeUsernameSupportLinkUrl = 'https://support.snapchat.com/en-US/a/change-username';
const openChangeUsernameSupportLink = () => openInNewWindow(changeUsernameSupportLinkUrl);

const changeUsernameSupportLink = (
  <SDSButton
    type={ButtonType.INLINE_LINK}
    onClick={openChangeUsernameSupportLink}
    data-test="onboarding.settingsView.userAssociation.changeUsername"
  >
    <FormattedMessage
      id="user-association-change-username-support"
      description="Change username support link text"
      defaultMessage="here"
    />
  </SDSButton>
);

const publicProfilesLinkUrl =
  'https://businesshelp.snapchat.com/s/topic/0TO0y000000YklvGAC/public-profiles-for-businesses?language=en_US';
const openPublicProfilesLink = () => openInNewWindow(publicProfilesLinkUrl);

const publicProfilesLink = (
  <SDSButton
    type={ButtonType.INLINE_LINK}
    onClick={openPublicProfilesLink}
    data-test="onboarding.settingsView.userAssociation.publicProfilesLink"
  >
    <FormattedMessage
      id="user-association-public-profiles-link"
      description="Public profiles help link text"
      defaultMessage="Public Profiles"
    />
  </SDSButton>
);

export class UserAssociationView extends React.Component<Props> {
  bc?: BroadcastChannel;

  componentDidMount() {
    // Broadcast channel for receiving username auth ticket from popup window
    // Docs: https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
    this.bc = new BroadcastChannel(USERNAME_AUTH_BROADCAST_CHANNEL);
    this.bc.onmessage = message => this.props.setAssociatedUserToken(this.props.businessProfileId, message);
  }

  componentWillUnmount() {
    if (this.bc) {
      this.bc.close();
    }
  }

  handleLoginButtonClick = () => {
    const redirectUrl = this.props.isGoogleAuth ? snapSSO.loginWithCookies : snapSSO.signupWithoutCookies;
    openInNewWindow(redirectUrl({ referrer: window.location.origin + loginRoutes.ticket({}) }));
  };

  helpCentreLinkUrl = `${ZENDESK_BASE_URL}/hc/en-us/articles/4406860527379`;

  openHelpCentreLink = () => openInNewWindow(this.helpCentreLinkUrl);

  helpCentreLink = (
    <SDSButton
      type={ButtonType.INLINE_LINK}
      onClick={this.openHelpCentreLink}
      data-test="onboarding.settingsView.userAssociation.helpCentre"
    >
      <FormattedMessage
        id="user-association-help-centre"
        description="Help centre link text"
        defaultMessage="Help Centre"
      />
    </SDSButton>
  );

  renderNotAssociatedHelpMessage() {
    return (
      <div className={style.helpMessage} data-test="onboarding.settingsView.userAssociation.notAssociatedHelp">
        <p>
          <FormattedMessage
            id="user-association-message-about"
            description="Message about account username association profile."
            defaultMessage="Choose a {channelSpecific} Snapchat account to be associated with this Profile."
            values={{
              channelSpecific: (
                <strong>
                  <FormattedMessage
                    id="user-association-message-about-channel-specific"
                    description="Emphasised word Channel-specific in the mssage about account username association profile."
                    defaultMessage="Channel-specific"
                  />
                </strong>
              ),
            }}
          />
          &nbsp;
          <strong>
            <FormattedMessage
              id="user-association-message-about-aditionally"
              defaultMessage="These accounts must not be existing {publicProfiles}."
              values={{ publicProfiles: publicProfilesLink }}
            />
          </strong>
        </p>

        <p>
          <FormattedMessage
            id="user-association-message-howto"
            description="Message about account username association process."
            defaultMessage="Click the ‘{selectAccount}’ button below and sign in to the desired account to connect it with this Profile. You may also create a new Snapchat Account to associate to the channel by clicking the button below. Please note that after a username is associated with a channel, the only way to change it is through changing the username itself in-app. For more information on how to do so, head {changeUsernameSupportLink}."
            values={{ selectAccount: selectAccountText, changeUsernameSupportLink }}
          />
        </p>

        <p>
          <FormattedMessage
            id="user-association-message-learn-more"
            description="Message about account username association info."
            defaultMessage="To learn more about username association please visit the {helpCentreLink}. If have any other issues, please contact your Partner Manager"
            values={{ helpCentreLink: this.helpCentreLink }}
          />
        </p>

        {this.isConnected() && (
          <p className={style.reminder}>
            <FormattedMessage
              id="user-association-message-reminder"
              description="Reminder to save the settings to finish association"
              defaultMessage="If the username is correct click Save Changes to confirm user association."
            />
          </p>
        )}
      </div>
    );
  }

  isConnected() {
    return this.props.associatedPublisher === this.props.businessProfileId && this.props.associatedUsername;
  }

  renderLoginButton() {
    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        inlineIcon={externalLink}
        onClick={this.handleLoginButtonClick}
        data-test="onboarding.settingsView.userAssociation.loginButton"
      >
        {selectAccountText}&nbsp;(accounts.snapchat.com)
      </SDSButton>
    );
  }

  renderNotAssociatedContent() {
    return (
      <div className={style.buttons}>
        {this.isConnected() && (
          <SDSInput
            data-test="onboarding.settingsView.userAssociation.associatedHostUsername"
            disabled
            value={`@${this.props.associatedUsername}`}
          />
        )}
        {this.renderLoginButton()}
        <Spinner loading={this.props.isAssociating} data-test="onboarding.settingsView.userAssociation.spinner" />
      </div>
    );
  }

  renderNotAssociated() {
    return (
      <div>
        {this.renderNotAssociatedHelpMessage()}
        {this.renderNotAssociatedContent()}
      </div>
    );
  }

  renderAssociatedHelpMessage() {
    return (
      <div className={style.helpMessage} data-test="onboarding.settingsView.userAssociation.associatedHelp">
        <p>
          <FormattedMessage
            id="user-association-message-learn-more-success"
            description="Message about account username association"
            defaultMessage="You have successfully connected a Snapchat account to your profile. If you would like to associate a different username with the channel, you must change the previously associated username in the Snapchat app. For more information on how to do so, head {changeUsernameSupportLink}."
            values={{ changeUsernameSupportLink }}
          />
        </p>
      </div>
    );
  }

  renderAssociated() {
    return (
      <div>
        {this.renderAssociatedHelpMessage()}
        <SDSInput
          data-test="onboarding.settingsView.userAssociation.hostUsername"
          disabled
          value={`@${this.props.hostUsername}`}
        />
      </div>
    );
  }

  render() {
    return this.props.hostUsername ? this.renderAssociated() : this.renderNotAssociated();
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(UserAssociationView);
