import _ from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';
import { intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsAreaChart from 'views/analytics/components/AnalyticsAreaChart/AnalyticsAreaChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE } from 'views/analytics/utils/chartConfigs';

const COLORS = [
  GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE,
  GRAPH_SHADES_OF_BLUE.MED_BLUE,
  GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
  GRAPH_SHADES_OF_BLUE.STRONG_BLUE,
  GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
];

type MetricProperty = {
  name: string;
  descriptionId: string;
};

type MetricProperties = MetricProperty[];

type Props = {
  descriptionMessageId: string;
  isLoading: boolean;
  graphTooltip?: ReactNode;
  data: any;
  metricProperties: MetricProperties;
  valueFormatter: (num: number) => string;
};

export class AnalyticsMultiAreaChart extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getConfig = () => {
    return this.props.data.map((entry: any) => {
      const metrics = this.props.metricProperties.reduce((acc, property) => {
        return { ...acc, [property.name]: entry[property.name] };
      }, {});

      return {
        name: entry.date,
        ...metrics,
      };
    });
  };

  getAreas = () => {
    return _.map(this.props.metricProperties, (property, index) => {
      return {
        name: getLocalisedMessageFromId(this.context, property.descriptionId),
        dataKey: property.name,
        key: property.name,
        fill: COLORS[index % COLORS.length],
      };
    });
  };

  getPercent = (value: number, total: number) => {
    const ratio = total > 0 ? value / total : 0;

    return this.toPercent(ratio, 2);
  };

  toPercent = (decimal: number, fixed: number = 0) => {
    return `${(decimal * 100).toFixed(fixed)}%`;
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const total = this.props.metricProperties.reduce((acc, property) => {
      return acc + payload[property.name];
    }, 0);

    const isExpandedSelected = _.get(tooltipData, 'isExpandedSelected', false);
    const baseMetricRows = this.props.metricProperties.map(property => {
      const metricValue = payload[property.name];
      const formattedValue = isExpandedSelected
        ? this.getPercent(metricValue, total)
        : numberFormatter.prettyPrintNumber(metricValue);

      return {
        metricName: getLocalisedMessageFromId(this.context, property.descriptionId),
        metricValue: formattedValue,
      };
    });

    if (!isExpandedSelected) {
      baseMetricRows.push({
        metricName: getLocalisedMessageFromId(this.context, 'daily-audience-total-metric'),
        metricValue: numberFormatter.prettyPrintNumber(total),
      });
    }

    return <AnalyticsTooltip title={numberFormatter.showAsDateAndTime(payload.name)} metricRows={baseMetricRows} />;
  };

  render() {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <AnalyticsAreaChart
        title={getMessageFromId(this.props.descriptionMessageId)}
        tooltip={this.props.graphTooltip}
        data={this.getConfig()}
        areas={this.getAreas()}
        shouldShowToggle
        isLoading={this.props.isLoading}
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={numberFormatter.prettyPrintNumber}
        xAxisTickFormatter={numberFormatter.showAsDateAndTime}
      />
    );
  }
}

export default AnalyticsMultiAreaChart;
