import { TagInput } from '@blueprintjs/labs';
import React from 'react';

import style from './MultiTagInput.scss';

type Props = {
  tags: string[];
  onChange: (a: string[]) => void;
  validationType?: string;
};

type State = {
  tags: string[];
};

class MultiTagInput extends React.Component<Props, State> {
  state: State = {
    tags: [],
  };

  UNSAFE_componentWillMount() {
    const tags = this.props.tags || [];
    this.setState({ tags });
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (props.tags !== this.state.tags) {
      this.setState({ tags: props.tags });
    }
  }

  isValid = (tag: string) => {
    if (!this.props.validationType) {
      return true;
    }

    if (this.props.validationType === 'number') {
      // Check if the tag is a number
      return /^\d+$/.test(tag);
    }

    return true;
  };

  handleChange = (newTags: string[]) => {
    const tags = newTags.filter((elem, index, self) => {
      return index === self.indexOf(elem) && this.isValid(elem);
    });
    this.setState({ tags });
    this.props.onChange(tags);
  };

  render() {
    return (
      <TagInput
        className={style.tagInput}
        values={this.state.tags}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(newTags: string[]) => void' is not assignab... Remove this comment to see the full error message
        onChange={this.handleChange}
      />
    );
  }
}

export default MultiTagInput;
