import React, { ReactNode } from 'react';

import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';

import { useUpdatePublisherMutation } from '../hooks/useUpdatePublisherMutation';

import { intlConnect } from 'utils/connectUtils';

import { State as RootState } from 'types/rootState';

type OwnProps = {
  children: ReactNode;
};

type StateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => {
  const activePublisher = getActivePublisherDetails(state);
  const bizProfileId = activePublisher?.businessProfileId;
  const publisherId = activePublisher?.id;
  return {
    activePublisher,
    bizProfileId,
    publisherId,
  };
};

type Props = OwnProps & StateProps;

// Add logging with graphene -> see withProfileAdAccountsQuery.tsx for ex.

export default function withUpdatePublisherMutation(Component: typeof React.Component) {
  function WrappedComponent(props: Props) {
    const { updatePublisher, data, error } = useUpdatePublisherMutation();

    return (
      <Component
        {...props}
        updatePublisher={updatePublisher}
        updatePublisherData={data}
        updatePublisherError={error}
        data-test={'withUpdatePublisherMutation.component'}
      >
        {props.children}
      </Component>
    );
  }

  return intlConnect(mapStateToProps, null)(WrappedComponent);
}
