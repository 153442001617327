import React from 'react';
import { FormattedMessage } from 'react-intl';

import { addFeatureToTier } from 'state/features/admin/actions/featuresActions';

import { plus } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import AlertBox, { AlertType } from 'views/common/components/AlertBox/AlertBox';
import SDSButton, { ButtonType, ButtonSize } from 'views/common/components/SDSButton/SDSButton';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';

import style from './AddFeatureToTierButton.scss';

import type { DropdownOption } from 'types/SDSDropdown';
import { TierLevel } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';

type ExternalProps = {
  tierIds: string[];
  featureId: string;
};

type State = {
  isModalVisible: boolean;
  selectedTierId: string | undefined | null;
};

const mapDispatchToProps = {
  addFeatureToTier,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps;

export class AddFeatureToTierButton extends React.PureComponent<Props, State> {
  state = {
    isModalVisible: false,
    selectedTierId: '',
  };

  handleButtonClick = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleOk = () => {
    if (this.state.selectedTierId) {
      this.props.addFeatureToTier(this.props.featureId, this.state.selectedTierId);
    }
    this.setState({ isModalVisible: false });
  };

  featureIsInAllTiers = () => {
    return Object.keys(TierLevel).every(tier => this.props.tierIds?.indexOf(tier) >= 0);
  };

  getTierDropdownOptions = () => {
    return Object.keys(TierLevel).map<DropdownOption>(tier => {
      const disabled = this.props.tierIds?.indexOf(tier) >= 0;
      return {
        value: tier,
        label: <div>{tier}</div>,
        disabled,
      };
    });
  };

  handleDropdownSelection = (tier: TierLevel) => {
    this.setState({ selectedTierId: tier });
  };

  renderModalDropdown = () => {
    return (
      <SDSDropdown
        disableClear
        dropdownWidthMatchContent
        type={DropdownType.GREY}
        size={DropdownSize.MEDIUM}
        onChange={this.handleDropdownSelection}
      >
        {createSDSDropdownOptions(this.getTierDropdownOptions())}
      </SDSDropdown>
    );
  };

  renderModalContent = () => {
    return (
      <div>
        <div>Please select the tier you would like to add this feature to</div>
        {this.renderModalDropdown()}
        <AlertBox className={style.alertBox} type={AlertType.WARNING}>
          <div className={style.alertContent}>
            {<div className={style.textWarning}>This might have serious impact on many publishers</div>}
            {<div>Do NOT add this if you are not absolutely sure</div>}
          </div>
        </AlertBox>
      </div>
    );
  };

  renderModal = () => {
    return (
      <SDSDialog
        title={
          <FormattedMessage
            id="add-feature-to-tier"
            defaultMessage="Add Feature to Tier"
            description="Title for a dialog for adding features to tier."
          />
        }
        visible={this.state.isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okButtonProps={{ disabled: !this.state.selectedTierId }}
      >
        {this.renderModalContent()}
      </SDSDialog>
    );
  };

  render() {
    if (this.featureIsInAllTiers()) {
      return null;
    }
    return (
      <div>
        <SDSButton
          inlineIcon={plus}
          type={ButtonType.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={this.handleButtonClick}
        >
          Add To Tier
        </SDSButton>
        {this.renderModal()}
      </div>
    );
  }
}

export default intlConnect(null, mapDispatchToProps)(AddFeatureToTierButton);
