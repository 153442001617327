import { ScheduledStoryStatus } from 'gql/queries/spotlight/storyDraftingSnaps';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const STORY_STATUS_DROPDOWN_OPTION = [
  {
    label: (
      <FormattedMessage
        id="spotlight-status-live"
        description="Button to toggle to live spotlight snaps view"
        defaultMessage="Live Snaps"
      />
    ),
    value: '',
  },
  {
    label: (
      <FormattedMessage
        id="spotlight-status-scheduled"
        description="Button to toggle to scheduled spotlight snaps view"
        defaultMessage="Scheduled Snaps"
      />
    ),
    value: ScheduledStoryStatus.SCHEDULED.toString(),
  },
];
