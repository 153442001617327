import { gql } from '@apollo/client';

export const GET_PROFILE_ANALYTICS = gql`
  query GetProfileAnalytics($input: ProfileAnalyticsInput!) {
    profileAnalytics(input: $input) {
      totalSubscribers
      totalSubscribersForTimestamp {
        value
        timestamp
      }
      totalSubscribersPreviousPeriod
      totalTimeViewedMs
      totalTimeViewedMsForTimestamp {
        value
        timestamp
      }
      totalTimeViewedPreviousPeriodMs
      uniqueViewers
      uniqueViewers13To17ForTimestamp {
        value
        timestamp
      }
      uniqueViewers18To20ForTimestamp {
        value
        timestamp
      }
      uniqueViewers21To24ForTimestamp {
        value
        timestamp
      }
      uniqueViewers25To34ForTimestamp {
        value
        timestamp
      }
      uniqueViewers35PlusForTimestamp {
        value
        timestamp
      }
      uniqueViewersAgeUnknownForTimestamp {
        value
        timestamp
      }
      uniqueViewersFemaleForTimestamp {
        value
        timestamp
      }
      uniqueViewersForTimestamp {
        value
        timestamp
      }
      uniqueViewersGenderUnknownForTimestamp {
        value
        timestamp
      }
      uniqueViewersMaleForTimestamp {
        value
        timestamp
      }
      uniqueViewersNonSubscribersForTimestamp {
        value
        timestamp
      }
      uniqueViewersPreviousPeriod
      uniqueViewersSubscribers
      uniqueViewersSubscribersForTimestamp {
        value
        timestamp
      }
      uniqueViewersSubscribersPreviousPeriod
    }
  }
`;
