import { ApolloQueryResult } from '@apollo/client';
import _, { chunk } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, IndexRange, InfiniteLoader } from 'react-virtualized';

import { isSidebarVisible } from 'state/navLayout/selectors/navLayoutSelectors';

import { GetSpotlightAnalytics } from 'gql/queries/analytics/__ssp-generated__/GetSpotlightAnalytics';
import { GetSpotlightPosts } from 'gql/queries/analytics/__ssp-generated__/GetSpotlightPosts';
import { GetStoryDraftingSnaps } from 'gql/queries/spotlight/__ssp-generated__/GetStoryDraftingSnaps';
import { StorySnap } from 'gql/queries/spotlight/storySnaps';
import { useWindowSize } from 'utils/window/windowSize';

import StorySnapCell from 'views/common/components/StorySnapCell/StorySnapCell';

import style from './StorySnapsInfiniteLoader.scss';

interface Props {
  posts: StorySnap[];
  loadMoreRows: (
    params: IndexRange
  ) => Promise<ApolloQueryResult<GetSpotlightPosts | GetSpotlightAnalytics | GetStoryDraftingSnaps>>;
  handleRemoveSnap: (snap: StorySnap) => void;
  handleUpdateSnapGoLiveTime: (snap: StorySnap, updatedGoLiveTime: number) => void;
}

// dimensions of video in grid cell, these sizes are fixed
export const CELL_VIDEO_HEIGHT = 230;
export const CELL_VIDEO_WIDTH = 137;

// considerate cell margins
const CELL_MARGIN = 16;
const ROW_HEIGHT = CELL_VIDEO_HEIGHT + CELL_MARGIN * 2; // this is slightly more than the actual row height in the grid to compensate for padding
const COLUMN_WIDTH = CELL_VIDEO_WIDTH + CELL_MARGIN;

// grid info
const DEFAULT_COLUMN_COUNT = 12;

// Very big number - If the data goes pass that number, infinite scroll will stop polling
const ROW_COUNT = 100000;

type CellProps = {
  snap: StorySnap;
  handleRemoveSnap: (snap: StorySnap) => void;
  handleUpdateSnapGoLiveTime: (snap: StorySnap, updatedGoLiveTime: number) => void;
};
const Cell = ({ snap, handleRemoveSnap, handleUpdateSnapGoLiveTime }: CellProps) => {
  return (
    <>
      {snap && (
        <div className={style.cellWrapper} style={{ height: CELL_VIDEO_HEIGHT, width: CELL_VIDEO_WIDTH }}>
          <StorySnapCell
            snap={snap}
            handleRemoveSnap={handleRemoveSnap}
            handleUpdateSnapGoLiveTime={handleUpdateSnapGoLiveTime}
          />
        </div>
      )}
    </>
  );
};

export default function StorySnapsInfiniteLoader({
  posts,
  loadMoreRows,
  handleRemoveSnap,
  handleUpdateSnapGoLiveTime,
}: Props): JSX.Element {
  const size = useWindowSize();
  const isNavOpen = useSelector(isSidebarVisible); // if the nav is open the grid needs more space (not automatically done as we rely on the window size above to resize)
  const [snapsGrid, setSnapsGrid] = useState(chunk(posts, DEFAULT_COLUMN_COUNT));
  const [responsiveColumnCount, setResponsiveColumnCount] = useState<number>(DEFAULT_COLUMN_COUNT);

  useEffect(() => {
    // calculate how many snaps can fit in one row based on the window width
    let columnCount = size?.width ? Math.floor(size.width / COLUMN_WIDTH) : DEFAULT_COLUMN_COUNT;
    if (isNavOpen) {
      columnCount -= 2; // Make the grid smaller when the navbar is open
    }

    const gridSnapsList = chunk(posts, columnCount);
    setSnapsGrid(gridSnapsList);
    setResponsiveColumnCount(columnCount);
  }, [isNavOpen, posts, size.width]);

  const isRowLoaded = useCallback(
    ({ index }: { index: number }) => {
      return !!posts[index];
    },
    [posts]
  );
  const loadMoreRowsCallback = useCallback(
    (params: IndexRange): Promise<unknown> => {
      return loadMoreRows(params);
    },
    [loadMoreRows]
  );

  function cellRenderer({ columnIndex, key, rowIndex }: { columnIndex: number; key: string; rowIndex: number }) {
    const snap = snapsGrid[rowIndex]?.[columnIndex];
    return (
      snap && (
        <Cell
          key={key}
          snap={snap}
          handleRemoveSnap={handleRemoveSnap}
          handleUpdateSnapGoLiveTime={handleUpdateSnapGoLiveTime}
        />
      )
    );
  }

  const getRowCount = () => {
    return snapsGrid.length;
  };

  return (
    <>
      <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRowsCallback} rowCount={ROW_COUNT}>
        {({ onRowsRendered, registerChild }) => (
          <Grid
            autoHeight
            ref={registerChild}
            cellRenderer={cellRenderer}
            onRowsRendered={onRowsRendered}
            columnCount={responsiveColumnCount}
            columnWidth={COLUMN_WIDTH}
            height={ROW_COUNT * ROW_HEIGHT}
            rowCount={getRowCount()}
            rowHeight={ROW_HEIGHT}
            width={COLUMN_WIDTH * responsiveColumnCount}
            estimatedRowSize={ROW_HEIGHT}
          />
        )}
      </InfiniteLoader>
    </>
  );
}
