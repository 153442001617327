import { omit } from 'lodash';
import React from 'react';
import Dropzone, { FileWithPreview } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSAlert from 'views/common/components/SDSAlert/SDSAlert';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

type Props = {
  onFile: (file: FileWithPreview) => any;
  'data-test'?: string;
};
type State = any;
export class RestrictedDropzone extends React.Component<Props, State> {
  static alertType = {
    LENGTH: 'one-file',
    TYPE: 'file-type',
    NONE: 'none',
  };

  state = {
    showFileAlert: RestrictedDropzone.alertType.NONE,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  onDropCheckLen = (files: FileWithPreview[]) => {
    if (!this.tooManyFiles(files) && files[0]) {
      this.props.onFile(files[0]);
    }
  };

  onDropRejected = (files: FileWithPreview[]) => {
    if (!this.tooManyFiles(files)) {
      this.setState({
        showFileAlert: RestrictedDropzone.alertType.TYPE,
        alertData: files[0]?.type,
      });
    }
  };

  getAlertData = () => {
    if (this.state.showFileAlert === RestrictedDropzone.alertType.TYPE) {
      return {
        type: (this.state as any).alertData,
      };
    }
    return null;
  };

  hideFileAlert = () => {
    this.setState({ showFileAlert: RestrictedDropzone.alertType.NONE });
  };

  tooManyFiles = (files: FileWithPreview[]) => {
    if (files.length !== 1) {
      this.setState({ showFileAlert: RestrictedDropzone.alertType.LENGTH });
      return true;
    }
    return false;
  };

  renderFileAlert() {
    if (this.state.showFileAlert === RestrictedDropzone.alertType.NONE) {
      return null;
    }
    return (
      <SDSAlert onConfirm={this.hideFileAlert} visible>
        <FormattedMessage
          id="upload-alert-one-file-required"
          defaultMessage="One file required"
          description="Message asking user to upload exactly one file"
        />
        {getMessageFromId(`upload-alert-${this.state.showFileAlert}`, this.getAlertData())}
      </SDSAlert>
    );
  }

  render() {
    return (
      <Dropzone
        {...omit(this.props, ['onFile', 'children', 'data-test'])}
        onDrop={this.onDropCheckLen}
        data-test={this.props['data-test']}
        onDropRejected={this.onDropRejected}
      >
        {this.props.children}
        {this.renderFileAlert()}
      </Dropzone>
    );
  }
}
export default RestrictedDropzone;
