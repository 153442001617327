import moment from 'moment-timezone';
import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { isShareable } from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import { isSeasonsEpisodesDisabled } from 'state/features/selectors/featuresSelectors';
import type { ScheduleEpisodeParams } from 'state/publisherTools/actions/publisherToolsActions';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getSeasonById } from 'state/seasons/selectors/seasonsSelectors';
import * as showsActions from 'state/shows/actions/showsActions';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';

import { EMPTY_ARRAY } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import {
  getDisabledEndTimeHours,
  getDisabledEndTimeMinutes,
  getDisabledStartTimeHours,
  getDisabledStartTimeMinutes,
} from 'utils/dateUtils';
import { enumObject } from 'utils/enum';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import {
  generateNewEpisodeNumber,
  getEditionsScheduledAfterStartDate,
  getNextSeasonNumber,
} from 'utils/shows/episodesAllocationUtils';
import { generateEndDateTimezones, generateStartDateTimezones, guessTimezone } from 'utils/timezoneUtils';

import AlertBox, { AlertType } from 'views/common/components/AlertBox/AlertBox';
import ArrowUpDown from 'views/common/components/ArrowUpDown/ArrowUpDown';
import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import InfoBox from 'views/common/components/InfoBox/InfoBox';
import SDSDatePicker from 'views/common/components/SDSDatePicker/SDSDatePicker';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptionGroups } from 'views/common/components/SDSDropdownOptionGroups/SDSDropdownOptionGroups';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';
import SharingModal from 'views/publisherStoryEditor/containers/ScheduleStoryModal/SharingModal';

import style from './EpisodeScheduleModal.scss';

import type {
  Edition,
  EditionID,
  LiveEdition,
  LiveEditionWithEpisodeNumber,
  ScheduledEdition,
  ScheduledEditionWithEpisodeNumber,
} from 'types/editions';
import { StoryState } from 'types/editions';
import type { BusinessProfileID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { Season, ShowID } from 'types/shows';

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="episode-schedule-modal-no-start-no-end-info-message"
      description="Info message if there is no set start or end date"
      defaultMessage="Your story will be published immediately to the Discover feed and remain live until you unpublish it"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="episode-schedule-modal-with-start-no-end-info-message"
      description="Info message if there is a set start but no end date"
      defaultMessage="Your story will be published on {startDate} to the Discover feed and remain live until you unpublish it"
    />
  ),
  params: ['startDate'],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="episode-schedule-modal-no-start-with-end-info-message"
      description="Info message if there is no set start but there is an end date"
      defaultMessage="Your story will be published immediately to the Discover feed and will be taken down on {endDate}"
    />
  ),
  params: ['endDate'],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="episode-schedule-modal-with-start-with-end-info-message"
      description="Info message if there is a set start and an end date"
      defaultMessage="Your story will be published on {startDate} to the Discover feed and and will be taken down on {endDate}"
    />
  ),
  params: ['startDate', 'endDate'],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="new-season-label"
      defaultMessage="New Season"
      description="Label for new season value in seasons dropdown"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="existing-season-label"
      defaultMessage="Existing Seasons"
      description="Label for existing seasons group in seasons dropdown"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="scheduling-episodes-warning"
      defaultMessage="This scheduling will change {episodesLength} episode's ordering:"
      description="Warning for the user that their current selection will reallocate future episodes"
    />
  ),
  params: ['episodesLength'],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="episode-schedule-modal-episode-info-message-2"
      description="Info message to give more information about the episode and season number when you schedule a story to be published."
      defaultMessage="Season and Episode numbers are allocated at time of scheduling. Your story will appear in your profile as {seasonName}, Episode {episodeNumber}"
    />
  ),
  params: ['seasonName', 'episodeNumber'],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="scheduling-episodes-warning-from-new-episode-number"
      description="Info message to give more information about the changes of the future episode"
      defaultMessage="From {breakLine}
    Season {seasonName}, Episode {episodeNumber} {breakLine}"
    />
  ),
  params: ['seasonName', 'episodeNumber', 'breakLine'],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="scheduling-episodes-warning-to-new-episode-number"
      description="Info message to give more information about the changes of the future episode"
      defaultMessage="To {breakLine}
    Season {seasonName}, Episode {newEpisodeNumber}"
    />
  ),
  params: ['seasonName', 'newEpisodeNumber', 'breakLine'],
});
const NEW_SEASON_MESSAGE = 'New Season';
// FORMATS
const DATE_FORMAT = 'MMMM Do';
const SEASON_FULL_CAPACITY = 75;
const Step = enumObject({
  SCHEDULE: 'SCHEDULE',
  SHARING: 'SHARING',
});
type ExternalProps = {
  modalId: string;
  hideModal: (modalId: string, results?: {}) => void;
  options: {
    storyIds: EditionID[];
    preScheduleEpisode: (
      params: ScheduleEpisodeParams,
      publisherId: number,
      businessProfileId: BusinessProfileID,
      storyId: number | undefined,
      seasonNumber: number,
      seasonDisplayName: string | undefined | null,
      liveAndScheduledFutureStories: (LiveEditionWithEpisodeNumber | ScheduledEditionWithEpisodeNumber)[]
    ) => void;
    onScheduleEpisode: (
      startDate: string | null | undefined,
      endDate: string | null | undefined,
      params: ScheduleEpisodeParams,
      publisherId: number,
      businessProfileId: BusinessProfileID,
      storyId: number,
      liveAndScheduledFutureStories: (LiveEditionWithEpisodeNumber | ScheduledEditionWithEpisodeNumber)[],
      isEpisodeLocked: boolean
    ) => void;
  };
};
type StateProps = {
  seasons: Season[];
  liveAndScheduledStories: (LiveEdition | ScheduledEdition)[];
  getPrimaryLanguageMessage: ReturnType<typeof publishersSelectors.getPrimaryLanguageMessage>;
  activePublisherId: number | null;
  currentStory: Edition | undefined | null;
  activePublisherBusinessProfileId: string | null;
  showId: ShowID;
  isEpisodeLocked: boolean;
  isSeasonEpisodeDisabled: boolean;
  lockedEpisodeSeason: Season | undefined | null;
  lockedEpisodeNumber: number | undefined | null;
};
const mapStateToProps = (state: State, ownProps: ExternalProps): StateProps => {
  const activePublisherId = publishersSelectors.getActivePublisherId(state);
  const activePublisherBusinessProfileId = publishersSelectors.getActivePublisherBusinessProfileId(state);
  const shows = showsSelectors.getShows(state)(activePublisherId);
  const currentShow = shows[0] || null;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
  const currentStory = editionsSelectors.getEditionById(state)(ownProps.options.storyIds[0]);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
  const episode = episodesSelectors.getEpisodeById(state)(ownProps.options.storyIds[0]);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const lockedEpisodeSeason = getSeasonById(state)(episode?.seasonId);
  const lockedEpisodeNumber = episode?.episodeNumber;
  // if the story doesn't have any metadata we should treat it as if it was unlocked so that we can add metadata to it
  const isEpisodeLocked = !!episode && currentStory?.state === StoryState.HIDDEN;

  const liveAndScheduledStories = activePublisherId
    ? editionsSelectors.getLiveAndScheduledStories(state)(activePublisherId)
    : [];

  return {
    showId: currentShow?.id,
    seasons: currentShow ? currentShow.seasons : EMPTY_ARRAY,
    getPrimaryLanguageMessage: publishersSelectors.getPrimaryLanguageMessage(state),
    liveAndScheduledStories,
    activePublisherId,
    currentStory,
    activePublisherBusinessProfileId,
    isEpisodeLocked,
    isSeasonEpisodeDisabled: isSeasonsEpisodesDisabled(state),
    lockedEpisodeSeason,
    lockedEpisodeNumber,
  };
};
const mapDispatchToProps = {
  getScheduledAndLiveStoriesForPublisher: publishersActions.getScheduledAndLiveStoriesForPublisher,
  loadShows: showsActions.loadShows,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;
function EpisodeScheduleModal(props: Props) {
  const {
    seasons,
    hideModal,
    modalId,
    liveAndScheduledStories,
    getScheduledAndLiveStoriesForPublisher,
    activePublisherId,
    activePublisherBusinessProfileId,
    showId,
    currentStory,
    isEpisodeLocked,
    isSeasonEpisodeDisabled,
    lockedEpisodeSeason,
    lockedEpisodeNumber,
    loadShows,
  } = props;
  // MODALS
  const [currentStep, setCurrentStep] = useState(Step.SCHEDULE);
  // TOGGLES
  const [isSchedulingEpisodeStartDate, setStartDateToggle] = useState(false);
  const [isSchedulingEpisodeEndDate, setEndDateToggle] = useState(false);
  // START AND END DATES
  const initialTimezone = guessTimezone().name;
  const [selectedDateTimezone, setDateTimezone] = useState(initialTimezone);
  const firstPossibleStartDay = moment.tz(selectedDateTimezone).startOf('day');
  const lastPossibleEndDate = moment.tz(selectedDateTimezone).add(2, 'years');
  const suggestedStartDate = moment.tz(selectedDateTimezone).add(1, 'week');
  const [startDate, setStartDate] = useState();
  const defaultStartDate = startDate || moment.tz(selectedDateTimezone); // when the publish later toggle is off start date is null
  const suggestedEndDate = startDate
    ? moment(startDate).tz(selectedDateTimezone).add(1, 'month')
    : moment.tz(selectedDateTimezone).add(1, 'month');
  const [endDate, setEndDate] = useState();
  const initialStartDateTimezoneOptions = generateStartDateTimezones(
    defaultStartDate,
    endDate,
    selectedDateTimezone,
    isSchedulingEpisodeEndDate
  );
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  const initialEndDateTimezoneOptions = generateEndDateTimezones(defaultStartDate, endDate, selectedDateTimezone);
  const [endDateTimezoneOptions, setEndDateTimezoneOptions] = useState(initialEndDateTimezoneOptions);
  const [startDateTimezoneOptions, setStartDateTimezoneOptions] = useState(initialStartDateTimezoneOptions);
  // NEW SEASON
  const [isNewSeasonEnabled, toggleEnableNewSeasonInput] = useState(false);
  const [seasonDisplayName, updateSeasonDisplayName] = useState();
  // get season with highest season number
  const highestSeason = seasons?.length ? seasons.reduce((a, b) => (a.seasonNumber > b.seasonNumber ? a : b)) : null;
  const latestSeason = seasons?.length ? seasons[seasons.length - 1] : null;
  const getDefaultSeason = () => {
    // if the show has no seasons only show 'New Season option'
    if (!seasons?.length) {
      return null;
    }
    // if the episode is locked show the season it currently belongs to
    if (isEpisodeLocked) {
      return lockedEpisodeSeason;
    }
    const isLatestSeasonFull = latestSeason?.episodes?.length
      ? latestSeason?.episodes?.length >= SEASON_FULL_CAPACITY
      : false;
    // if the latest season is full by default choose new season
    return isLatestSeasonFull ? null : latestSeason;
  };
  const [currentSeason, setSeason] = useState(getDefaultSeason());
  const defaultEpisodeNumber =
    isEpisodeLocked && lockedEpisodeNumber
      ? lockedEpisodeNumber
      : generateNewEpisodeNumber(currentSeason, liveAndScheduledStories, defaultStartDate, selectedDateTimezone);
  const [episodeNumber, setEpisodeNumber] = useState(defaultEpisodeNumber);
  const [frozenLiveAndScheduledEditions, setLiveAndScheduledEditions] = useState(liveAndScheduledStories);
  const defaultLiveAndScheduledFutureEditions = getEditionsScheduledAfterStartDate(
    currentSeason,
    liveAndScheduledStories,
    defaultStartDate,
    selectedDateTimezone
  );
  const [liveAndScheduledFutureEditions, setFutureEditions] = useState(defaultLiveAndScheduledFutureEditions);
  const updateLiveAndScheduledEditionsIfNeeded = useCallback(() => {
    // only update the episode when the live and scheduled edition change
    // otherwise it re-renders infinitely
    if (frozenLiveAndScheduledEditions.length !== liveAndScheduledStories.length && !isEpisodeLocked) {
      // ensure that the season in state has the most up to date season data
      const upToDateSeason = seasons.find(season => season.id === currentSeason?.id);
      setSeason(upToDateSeason);
      const newEpisodeNumber = generateNewEpisodeNumber(
        upToDateSeason,
        liveAndScheduledStories,
        defaultStartDate,
        selectedDateTimezone
      );
      setEpisodeNumber(newEpisodeNumber);
      setLiveAndScheduledEditions(liveAndScheduledStories);
      const futureEditions = getEditionsScheduledAfterStartDate(
        upToDateSeason,
        liveAndScheduledStories,
        defaultStartDate,
        selectedDateTimezone
      );
      setFutureEditions(futureEditions);
    }
  }, [
    currentSeason,
    defaultStartDate,
    frozenLiveAndScheduledEditions.length,
    isEpisodeLocked,
    liveAndScheduledStories,
    seasons,
    selectedDateTimezone,
  ]);
  // PUBLISHER STORIES
  const fetchScheduledAndLiveStoriesForPublisher = useCallback(async () => {
    if (!activePublisherId || !activePublisherBusinessProfileId) {
      return;
    }
    await Promise.all([
      loadShows(activePublisherId, activePublisherBusinessProfileId),
      getScheduledAndLiveStoriesForPublisher({ publisherId: activePublisherId, startDate: undefined }),
    ]);
  }, [activePublisherBusinessProfileId, activePublisherId, getScheduledAndLiveStoriesForPublisher, loadShows]);
  // if we don't do this, there is only the active edition in state
  useEffect(() => {
    fetchScheduledAndLiveStoriesForPublisher();
  }, [fetchScheduledAndLiveStoriesForPublisher, startDate]);
  // START AND END DATES HANDLERS
  const setNewStartDate = useCallback(
    (newStartDate, newSuggestedEndDate) => {
      const endDateForTimezones = newSuggestedEndDate || endDate;
      const newStartDateTimezoneOptions = generateStartDateTimezones(
        newStartDate,
        endDateForTimezones,
        selectedDateTimezone,
        isSchedulingEpisodeEndDate
      );
      setStartDateTimezoneOptions(newStartDateTimezoneOptions);
      setStartDate(newStartDate);
      const futureEditions = getEditionsScheduledAfterStartDate(
        currentSeason,
        frozenLiveAndScheduledEditions,
        newStartDate,
        selectedDateTimezone
      );
      if (!isEpisodeLocked) {
        const newEpisodeNumber = generateNewEpisodeNumber(
          currentSeason,
          frozenLiveAndScheduledEditions,
          newStartDate,
          selectedDateTimezone
        );
        setEpisodeNumber(newEpisodeNumber);
        setFutureEditions(futureEditions);
      }
    },
    [
      endDate,
      selectedDateTimezone,
      isSchedulingEpisodeEndDate,
      currentSeason,
      frozenLiveAndScheduledEditions,
      isEpisodeLocked,
    ]
  );
  const setNewEndDate = useCallback(
    newEndDate => {
      const newEndDateTimezoneOptions = generateEndDateTimezones(defaultStartDate, newEndDate, selectedDateTimezone);
      setEndDateTimezoneOptions(newEndDateTimezoneOptions);
      setEndDate(newEndDate);
    },
    [defaultStartDate, selectedDateTimezone]
  );
  const setNewDateTimezone = useCallback(setDateTimezone, [setDateTimezone]);
  const toggleStartDate = useCallback(() => {
    // when the toggle is not enabled, clear the startDate so it doesn't show in the info box
    Promise.all([
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      setNewStartDate(startDate ? null : suggestedStartDate),
      setStartDateToggle(!isSchedulingEpisodeStartDate),
    ]).then(() => {
      if (endDate) {
        const newEndDateTimezoneOptions = generateEndDateTimezones(defaultStartDate, endDate, selectedDateTimezone);
        setEndDateTimezoneOptions(newEndDateTimezoneOptions);
      }
    });
  }, [
    setNewStartDate,
    startDate,
    suggestedStartDate,
    isSchedulingEpisodeStartDate,
    endDate,
    defaultStartDate,
    selectedDateTimezone,
  ]);
  const toggleTakeDown = useCallback(() => {
    // when the toggle is not enabled, clear the endDate so it doesn't show in the info box
    Promise.all([setNewEndDate(endDate ? null : suggestedEndDate), setEndDateToggle(!isSchedulingEpisodeEndDate)]).then(
      () => {
        if (startDate) {
          const newStartDateTimezoneOptions = generateStartDateTimezones(
            startDate,
            endDate,
            selectedDateTimezone,
            !isSchedulingEpisodeEndDate
          );
          setStartDateTimezoneOptions(newStartDateTimezoneOptions);
        }
      }
    );
  }, [
    endDate,
    isSchedulingEpisodeEndDate,
    selectedDateTimezone,
    setNewEndDate,
    startDate,
    suggestedEndDate,
    setStartDateTimezoneOptions,
  ]);
  // info message at the top is updated with the start and end date of the episode
  const getInfoMessage = () => {
    // format example: November 9th
    const formattedStartDate = startDate ? `${moment(startDate).format(DATE_FORMAT)}` : '';
    const formattedEndDate = endDate ? `${moment(endDate).format(DATE_FORMAT)}` : '';
    if (!isSchedulingEpisodeStartDate && !isSchedulingEpisodeEndDate) {
      return getMessageFromId('episode-schedule-modal-no-start-no-end-info-message');
    }
    if (isSchedulingEpisodeStartDate && !isSchedulingEpisodeEndDate) {
      return getMessageFromId('episode-schedule-modal-with-start-no-end-info-message', {
        startDate: formattedStartDate,
      });
    }
    if (!isSchedulingEpisodeStartDate && isSchedulingEpisodeEndDate) {
      return getMessageFromId('episode-schedule-modal-no-start-with-end-info-message', { endDate: formattedEndDate });
    }
    return getMessageFromId('episode-schedule-modal-with-start-with-end-info-message', {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };
  // DATE INPUTS
  const getDisabledTimeAndFormat = (isTogglingStartDate?: boolean | null) => {
    const disabledHours = isTogglingStartDate
      ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        getDisabledStartTimeHours(startDate, endDate, selectedDateTimezone, isSchedulingEpisodeEndDate)
      : // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        getDisabledEndTimeHours(startDate, endDate, selectedDateTimezone);
    const disabledMinutes = isTogglingStartDate
      ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        getDisabledStartTimeMinutes(startDate, endDate, selectedDateTimezone, isSchedulingEpisodeEndDate)
      : // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        getDisabledEndTimeMinutes(startDate, endDate, selectedDateTimezone);
    return {
      format: 'HH:mm',
      disabledHours,
      disabledMinutes,
      minuteStep: 15,
    };
  };
  const generateDisabledTimezones = (isTogglingStartDate?: boolean | null) => {
    if (isSchedulingEpisodeStartDate && !isSchedulingEpisodeEndDate) {
      return startDateTimezoneOptions;
    }
    if (!isSchedulingEpisodeStartDate && isSchedulingEpisodeEndDate) {
      return endDateTimezoneOptions;
    }
    return startDateTimezoneOptions.map((timezone, i) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const newValue = timezone.disabled ? timezone.disabled : endDateTimezoneOptions[i].disabled;
      return { ...timezone, disabled: newValue };
    });
  };
  const handleStartDateChange = (value: any) => {
    const newStartDate = moment(value).tz(selectedDateTimezone, true);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (isSchedulingEpisodeEndDate && endDate.isBefore(value)) {
      const newSuggestedEndDate = moment(value).tz(selectedDateTimezone).add(1, 'month');
      setNewEndDate(newSuggestedEndDate);
      setNewStartDate(newStartDate, newSuggestedEndDate);
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      setNewStartDate(newStartDate);
    }
  };
  const handleEndDateChange = (value: any) => {
    if (isSchedulingEpisodeStartDate) {
      const newStartDateTimezoneOptions = generateStartDateTimezones(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        startDate,
        value,
        selectedDateTimezone,
        isSchedulingEpisodeEndDate
      );
      setStartDateTimezoneOptions(newStartDateTimezoneOptions);
    }
    const newEndDate = moment(value).tz(selectedDateTimezone, true);
    setNewEndDate(newEndDate);
  };
  function handleDateTimezoneChange(value: any) {
    if (isSchedulingEpisodeStartDate) {
      const newStartDateTimezoneOptions = generateStartDateTimezones(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        startDate,
        endDate,
        value,
        isSchedulingEpisodeEndDate
      );
      setStartDateTimezoneOptions(newStartDateTimezoneOptions);
    } else {
      const newEndDate = moment(endDate).tz(value, true);
      setNewEndDate(newEndDate);
    }
    setNewDateTimezone(value);
  }
  // SEASONS DROPDOWN
  const getNewSeasonGroup = () => {
    return [{ value: NEW_SEASON_MESSAGE, label: getMessageFromId('new-season-label') }];
  };
  // if the show doesn't have any seasons then only show the new season group
  const generateNewSeasonGroupOnly = () => {
    return [
      {
        label: getMessageFromId('new-season-label'),
        key: NEW_SEASON_MESSAGE,
        children: getNewSeasonGroup(),
      },
    ];
  };
  const generateSeasonDropdownGroups = (seasonsArray: Season[]) => {
    const existingSeasonsGroup = seasonsArray.map(season => {
      const { displayName, seasonNumber, id, episodes } = season;
      const isSeasonFull = episodes?.length >= SEASON_FULL_CAPACITY;
      return { value: id, label: displayName || `Season ${seasonNumber}`, disabled: isSeasonFull };
    });
    return [
      {
        label: getMessageFromId('new-season-label'),
        key: NEW_SEASON_MESSAGE,
        children: getNewSeasonGroup(),
      },
      {
        label: getMessageFromId('existing-season-label'),
        key: 'Existing Seasons',
        children: existingSeasonsGroup,
      },
    ];
  };
  const getPlaceHolderMessage = (seasonNumber?: number | null) => {
    if (!seasonNumber) {
      return getMessageFromId('new-season-label');
    }
    return getMessageFromId('season-placeholder-with-season-number', { number: seasonNumber });
  };

  const getDefaultValue = () => {
    if (isEpisodeLocked && lockedEpisodeSeason) {
      return `${lockedEpisodeSeason.displayName || getPlaceHolderMessage(lockedEpisodeSeason.seasonNumber)}`;
    }
    if (!latestSeason || latestSeason.episodes?.length >= SEASON_FULL_CAPACITY) {
      return NEW_SEASON_MESSAGE;
    }
    return latestSeason.displayName
      ? `${latestSeason.displayName} (latest)`
      : getMessageFromId('season-placeholder-with-season-number-latest', { number: lockedEpisodeSeason?.seasonNumber });
  };
  function handleSeasonDropdownChange(value: any) {
    const chosenSeason = seasons.find(season => season.id === value);
    setSeason(chosenSeason);
    const futureEditions = getEditionsScheduledAfterStartDate(
      chosenSeason,
      frozenLiveAndScheduledEditions,
      defaultStartDate,
      selectedDateTimezone
    );
    setFutureEditions(futureEditions);
    const newEpisodeNumber = generateNewEpisodeNumber(
      chosenSeason,
      frozenLiveAndScheduledEditions,
      defaultStartDate,
      selectedDateTimezone
    );
    setEpisodeNumber(newEpisodeNumber);
    if (value === NEW_SEASON_MESSAGE) {
      toggleEnableNewSeasonInput(true);
      return;
    }
    toggleEnableNewSeasonInput(false);
  }
  // NEW SEASON input
  function onSeasonDisplayNameChange(event: ChangeEvent<EventTarget>) {
    updateSeasonDisplayName((event.target as any).value);
  }
  const getSelectedPlaceholderDisplayName = () => {
    const seasonNumber = getNextSeasonNumber(highestSeason);
    return getPlaceHolderMessage(seasonNumber);
  };
  const getSeasonName = () => {
    if (isEpisodeLocked && lockedEpisodeSeason) {
      return lockedEpisodeSeason.displayName || getPlaceHolderMessage(lockedEpisodeSeason.seasonNumber);
    }
    if (!currentSeason) {
      return seasonDisplayName || getSelectedPlaceholderDisplayName();
    }
    return currentSeason.displayName || getPlaceHolderMessage(currentSeason.seasonNumber);
  };
  function repopulateEmptyInput() {
    if (seasonDisplayName) {
      return;
    } // don't replace if the user entered something in the input
    const seasonDisplayNamePlaceholder = getSelectedPlaceholderDisplayName();
    updateSeasonDisplayName(seasonDisplayNamePlaceholder); // this ensures the season is not created without a name
  }
  function onHide() {
    hideModal(modalId);
  }
  const getSeasonNumber = () => {
    if (currentSeason) {
      return currentSeason.seasonNumber;
    } // user selected season
    if (highestSeason) {
      return highestSeason.seasonNumber + 1;
    } // user selected new season, the show has at least one season already
    return 1; // this is the first season of the show
  };
  const completePreScheduleActions = (seasonNumber: number, newSeasonDisplayName: string | undefined | null) => {
    if (!activePublisherId || !activePublisherBusinessProfileId) {
      return Promise.resolve();
    }
    return fetchScheduledAndLiveStoriesForPublisher()
      .then(() => updateLiveAndScheduledEditionsIfNeeded())
      .then(() => {
        if (!currentStory) {
          onHide();
          return Promise.resolve();
        }
        const params = {
          id: currentStory.id,
          title: currentStory.title,
          showId,
          seasonId: currentSeason?.id || null,
          episodeNumber,
          seasonNumber,
        };
        return props.options.preScheduleEpisode(
          params,
          activePublisherId,
          activePublisherBusinessProfileId,
          currentStory?.id,
          seasonNumber,
          newSeasonDisplayName,
          liveAndScheduledFutureEditions
        );
      });
  };
  const onScheduleComplete = async () => {
    if (!currentStory) {
      onHide();
      return;
    }
    // if republishing an episode that has already been live (hence it must be locked), don't show the sharing modal
    if (!isShareable(currentStory) || isEpisodeLocked) {
      onHide();
    } else {
      setCurrentStep(Step.SHARING);
    }
    const seasonNumber = getSeasonNumber();
    const newSeasonDisplayName = seasonDisplayName || null;
    if (!isEpisodeLocked) {
      try {
        await completePreScheduleActions(seasonNumber, newSeasonDisplayName);
      } catch {
        return;
      }
    }
    const params = {
      id: currentStory.id,
      title: currentStory.title,
      showId,
      seasonId: currentSeason?.id || null,
      episodeNumber,
      seasonNumber,
    };
    const startDateWithTimezone = moment(defaultStartDate).tz(selectedDateTimezone, true);
    const formattedStartDate = startDateWithTimezone.utc().format();
    let formattedEndDate = null;
    if (endDate) {
      const endDateWithTimezone = moment(endDate).tz(selectedDateTimezone, true);
      formattedEndDate = endDateWithTimezone.utc().format();
    }
    if (!activePublisherId || !activePublisherBusinessProfileId) {
      return;
    }
    // set the edition state
    props.options.onScheduleEpisode(
      formattedStartDate,
      formattedEndDate,
      params,
      activePublisherId,
      activePublisherBusinessProfileId,
      currentStory.id,
      liveAndScheduledFutureEditions,
      isEpisodeLocked
    );
  };
  function handleScheduleEpisode() {
    if (!currentStory) {
      onHide();
      return;
    }
    onScheduleComplete();
  }
  // info box at the top of the modal to summarise the start and end date states of the episode
  const renderInfoBox = () => {
    updateLiveAndScheduledEditionsIfNeeded();
    return (
      <AlertBox type={AlertType.INFO}>
        <div>{getInfoMessage()}</div>
      </AlertBox>
    );
  };
  // the toggle is reused for the start and the end date boxes
  const renderToggle = (isTogglingStartDate?: boolean | null) => {
    return (
      <div
        className={style.toggleAndTitleContainer}
        onClick={isTogglingStartDate ? toggleStartDate : toggleTakeDown}
        data-test={`modals.episodeScheduleModal.scheduling${
          isTogglingStartDate ? 'Start' : 'End'
        }DateToggleAndTitleContainer`}
      >
        {}
        <SDSSwitch
          /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; value: boolean; }' is not as... Remove this comment to see the full error message */
          name="EpisodeModal.isPartOfShowToggle"
          value={isTogglingStartDate ? isSchedulingEpisodeStartDate : isSchedulingEpisodeEndDate}
        />
        <div className={style.titleContainer}>
          {isTogglingStartDate ? (
            <FormattedMessage
              id="episode-schedule-modal-schedule-title"
              description="Schedule episode toggle title"
              defaultMessage="Schedule publishing"
            />
          ) : (
            <FormattedMessage
              id="episode-schedule-modal-take-down-title"
              description="Take down episode toggle title"
              defaultMessage="Automatically take down story at a later date"
            />
          )}
        </div>
      </div>
    );
  };
  const renderDateInput = (isTogglingStartDate?: boolean | null) => {
    const firstPossibleEndDay = startDate
      ? moment(startDate).tz(selectedDateTimezone).startOf('day')
      : firstPossibleStartDay;
    const lowerBound = isTogglingStartDate ? firstPossibleStartDay : firstPossibleEndDay;
    const timezones = generateDisabledTimezones(isTogglingStartDate);
    return (
      <div className={style.inputContainer}>
        <SDSLabel
          labelTitle={
            isTogglingStartDate ? (
              <FormattedMessage
                id="episode-schedule-modal-publish-date-and-time-input-label"
                description="Label for publish date and time input"
                defaultMessage="Publish date and time"
              />
            ) : (
              <FormattedMessage
                id="episode-schedule-modal-take-down-date-input-label"
                description="Label for take down date input"
                defaultMessage="Take down date"
              />
            )
          }
          withoutDefaultMargin
        >
          <SDSDatePicker
            allowClear={false}
            data-test={`modals.episodeScheduleModal.scheduling${isTogglingStartDate ? 'Start' : 'End'}DatePicker`}
            onChange={isTogglingStartDate ? handleStartDateChange : handleEndDateChange}
            value={isTogglingStartDate ? startDate : endDate}
            showTime={getDisabledTimeAndFormat(isTogglingStartDate)}
            upperBoundDate={lastPossibleEndDate}
            lowerBoundDate={lowerBound}
          />
        </SDSLabel>
        <SDSLabel labelTitle="Timezone" withoutDefaultMargin>
          <SDSDropdown
            data-test={`modals.episodeScheduleModal.scheduling${
              isTogglingStartDate ? 'Start' : 'End'
            }DateTimezoneDropdown`}
            defaultValue={initialTimezone}
            value={isTogglingStartDate ? selectedDateTimezone : selectedDateTimezone}
            onChange={handleDateTimezoneChange}
            disableClear
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
          >
            {createSDSDropdownOptions(timezones)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  };
  const renderStartDateBox = () => {
    return (
      <div className={style.infoBoxContainer}>
        <InfoBox>
          {renderToggle(true)}
          {isSchedulingEpisodeStartDate && renderDateInput(true)}
        </InfoBox>
      </div>
    );
  };
  const renderEndDateBox = () => {
    return (
      <div className={style.infoBoxContainer}>
        <InfoBox>
          {renderToggle()}
          {isSchedulingEpisodeEndDate && renderDateInput()}
        </InfoBox>
      </div>
    );
  };
  const shouldRenderSeasonDisplayNameInput = () => {
    return isNewSeasonEnabled || !latestSeason;
  };
  const renderSeasonDropdown = () => {
    return (
      <>
        <SDSLabel
          labelTitle={
            <div className={style.modalTitleWrapper}>
              <FormattedMessage
                id="episode-schedule-modal-choose-or-create-season-dropdown-label"
                description="Label for season dropdown"
                defaultMessage="Choose or create season"
              />
              <HelpCenterLink
                destination={HelpCenterDestination.SEASONS_AND_EPISODES}
                data-test="EpisodeScheduleModal.schedule"
              />
            </div>
          }
          withoutDefaultMargin
        >
          <SDSDropdown
            disableClear
            onChange={handleSeasonDropdownChange}
            defaultValue={getDefaultValue()}
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
            data-test="modals.episodeScheduleModal.seasonDropdown"
            disabled={isEpisodeLocked}
            suffixIcon={isEpisodeLocked ? null : <ArrowUpDown isUp={false} className={style.downArrow} />}
          >
            {latestSeason
              ? createSDSDropdownOptionGroups(generateSeasonDropdownGroups(seasons))
              : createSDSDropdownOptionGroups(generateNewSeasonGroupOnly())}
          </SDSDropdown>
        </SDSLabel>
        {shouldRenderSeasonDisplayNameInput() && (
          <div className={style.inputContainer}>
            <SDSInput
              labelTitle={getMessageFromId('season-field-display-name')}
              value={seasonDisplayName || getSelectedPlaceholderDisplayName()}
              suffix={
                !seasonDisplayName ? (
                  <FormattedMessage
                    id="suffix-default"
                    description="Suffix shown when the user hasn't typed to explain the input value is default"
                    defaultMessage="default"
                  />
                ) : null
              }
              maxLength={20}
              onChange={onSeasonDisplayNameChange}
              onBlur={repopulateEmptyInput}
              data-test="modals.episodeScheduleModal.seasonName.input"
              withoutDefaultMargin
            />
          </div>
        )}
      </>
    );
  };
  // info box at the bottom of the modal to summarise the season and episode number
  const renderEpisodeInfoBox = () => {
    return (
      <div className={style.episodeInfoBoxContainer}>
        <AlertBox data-test="modals.EpisodeScheduleModal.episode.infoBox" type={AlertType.INFO}>
          <div>
            {getMessageFromId('episode-schedule-modal-episode-info-message-2', {
              seasonName: getSeasonName(),
              episodeNumber,
            })}
          </div>
        </AlertBox>
      </div>
    );
  };
  // info box at the bottom of the modal to summarise the season and episode number
  const renderFutureEpisodesWarningBox = () => {
    return (
      <div className={style.episodeInfoBoxContainer}>
        <AlertBox data-test="modals.EpisodeScheduleModal.futureEpisodes.warningBox" type={AlertType.INFO}>
          <div>
            {getMessageFromId('scheduling-episodes-warning', { episodesLength: liveAndScheduledFutureEditions.length })}
            {liveAndScheduledFutureEditions.map(edition => {
              const newEpisodeNumber = edition.episodeNumber + 1;
              const breakLine = <br />;
              return (
                <div
                  data-test="modals.EpisodeScheduleModal.futureEpisodes.warningBox.episodeInfo"
                  className={style.futureEpisodesContainer}
                  key={edition.id}
                >
                  <span>{edition.title}</span>
                  <div>
                    {getMessageFromId('scheduling-episodes-warning-from-new-episode-number', {
                      seasonName: currentSeason?.seasonNumber || seasonDisplayName,
                      episodeNumber: edition.episodeNumber,
                      breakLine,
                    })}
                    {getMessageFromId('scheduling-episodes-warning-to-new-episode-number', {
                      seasonName: currentSeason?.seasonNumber || seasonDisplayName,
                      newEpisodeNumber,
                      breakLine,
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </AlertBox>
      </div>
    );
  };
  const renderScheduleModal = () => {
    updateLiveAndScheduledEditionsIfNeeded();
    return (
      <SDSDialog
        visible
        title={
          <div className={style.modalTitleWrapper}>
            <FormattedMessage
              id="publish-story-label-schedule-modal"
              defaultMessage="Publish Story"
              description="Publish story label"
            />
            <HelpCenterLink
              destination={
                isSeasonEpisodeDisabled ? HelpCenterDestination.SHOWS : HelpCenterDestination.SEASONS_AND_EPISODES
              }
              data-test="EpisodeScheduleModal.schedule"
            />
          </div>
        }
        onCancel={onHide}
        onOk={handleScheduleEpisode}
        data-test="modals.episodeScheduleModal"
        okText={
          <FormattedMessage
            id="schedule-button-label"
            defaultMessage="Schedule"
            description="Button label to open schedule modal"
          />
        }
      >
        <div>
          {renderInfoBox()}
          {renderStartDateBox()}
          {renderEndDateBox()}
          {!isSeasonEpisodeDisabled && renderSeasonDropdown()}
          {frozenLiveAndScheduledEditions.length ? !isSeasonEpisodeDisabled && renderEpisodeInfoBox() : null}
          {!isEpisodeLocked && liveAndScheduledFutureEditions.length
            ? !isSeasonEpisodeDisabled && renderFutureEpisodesWarningBox()
            : null}
        </div>
      </SDSDialog>
    );
  };
  const renderSharingModal = () => {
    if (!currentStory) {
      onHide();
      return null;
    }
    return <SharingModal edition={currentStory} onDismiss={onHide} />;
  };
  switch (currentStep) {
    case Step.SCHEDULE:
      return renderScheduleModal();
    case Step.SHARING:
      return renderSharingModal();
    default:
      return null;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(EpisodeScheduleModal);
