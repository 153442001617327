import { gql } from '@apollo/client';

export const GET_SAVED_STORIES_ANALYTICS = gql`
  query GetSavedStoryAnalytics($input: SavedStoryInput!) {
    savedStoryAnalytics(input: $input) {
      savedStoryAnalytics {
        savedStoryKPIs {
          uniqueViewers
          uniqueViewersPreviousPeriod
          timeSpentMs
          timeSpentMsPreviousPeriod
          timeViewedMs
          timeViewedMsPreviousPeriod
          screenshots
          screenshotsPreviousPeriod
          totalStoryViews
          totalStoryViewsPreviousPeriod
          attachmentConversion
          attachmentConversionPreviousPeriod
          shares
        }
        savedStoryInsights {
          storyId
          savedStoryMetadata {
            storyName
            snapCount
            thumbnailUrl
            firstLiveDate
          }
          savedStoryItemAnalytics {
            uniqueViewers
            timeSpentMs
          }
        }
      }
    }
  }
`;
