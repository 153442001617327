import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { isPreviewMuted } from 'state/previews/selectors/previewsSelectors';

import useVideoProperties from 'utils/media/videoProperties';

import PlayButton from 'views/common/components/PlayButton/PlayButton';
import PublisherEditionMetadata from 'views/common/components/TopsnapPreview/PublisherEditionMetadata';

import style from './StorySnapUploadPreviewCard.scss';

type Props = {
  src?: string;
  snapDescription?: string;
  posterUrl?: string;
};
export default function StorySnapUploadPreviewCard({ src, snapDescription, posterUrl }: Props) {
  const isMuted = useSelector(isPreviewMuted);
  const videoPlayer = useRef<HTMLVideoElement>(null);
  const [isOnPlay, setIsOnPlay] = useState(false);
  const { videoHeight, videoWidth } = useVideoProperties();

  const togglePlay = useCallback(() => {
    setIsOnPlay(!isOnPlay);
  }, [isOnPlay]);

  const playVideo = useCallback(() => {
    const playPromise = videoPlayer?.current?.play();

    if (playPromise) {
      playPromise.catch(error => {
        throw error;
      });
    }
  }, []);

  const pauseVideo = useCallback(() => {
    videoPlayer?.current?.pause();
  }, []);

  useEffect(() => {
    if (isOnPlay) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [isOnPlay, playVideo, pauseVideo]);

  return (
    <div
      className={style.overlayWrapper}
      data-test="StorySnapUploadPreviewCard.wrapper"
      // @ts-expect-error style not part of the intrinsic properties
      style={{ '--video-width': `${videoWidth}px`, '--video-height': `${videoHeight}px` }}
    >
      <div className={style.publisherMetadataWrapper} data-test="StorySnapUploadPreviewCard.publisherMetadata">
        <PublisherEditionMetadata isSpotlightSnap snapDescription={snapDescription} />
      </div>
      <video
        ref={videoPlayer}
        crossOrigin="anonymous"
        src={src}
        preload="metadata" // without this property poster does not load on Safari https://stackoverflow.com/questions/41255841/how-to-get-html5-video-thumbnail-without-using-poster-on-safari-or-ios
        playsInline
        loop
        className={style.video}
        poster={posterUrl || ''}
        muted={isMuted}
      />
      <PlayButton
        isOnPlay={isOnPlay}
        handleClick={togglePlay}
        isLoading={!src?.length}
        data-test="StorySnapUploadPreviewCard.playButton"
      />
    </div>
  );
}
