import _ from 'lodash';
import React from 'react';

import style from './AudienceBreakdownGraph.scss';

type AudienceEntry = {
  group: string;
  percent: number;
  col: string;
};

type Props = {
  sortByName: boolean;
  sort: boolean;
  data: AudienceEntry[];
};

export class AudienceBreakdownGraph extends React.PureComponent<Props> {
  getCallouts = () => {
    let callouts = '';
    if (this.props.data) {
      const sortedBarData = this.getSortedBarData();

      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element[]' is not assignable to type 'string... Remove this comment to see the full error message
      callouts = _.map(sortedBarData, (barDataEntry, index) => {
        const offsetPercent = _.sumBy(sortedBarData.slice(0, index), barData => barData.percent * 100);
        const finalPercentage = offsetPercent + barDataEntry.percent * 50;
        const styleType = { left: `${finalPercentage}%` };
        return (
          <div style={styleType}>
            <div className={style.audienceBreakdownCallout} style={styleType} key={`group_${barDataEntry.group}`}>
              <p className={style.audienceBreakdownCalloutLabel}>{barDataEntry.group}</p>
              <p className={style.audienceBreakdownCalloutValue}>{this.getPercentValue(barDataEntry.percent)}</p>
            </div>
          </div>
        );
      });
    }
    return callouts;
  };

  getPercentValue = (percent: number) => {
    return `${Math.round(percent * 100)}%`;
  };

  getBars = (barData: AudienceEntry[]) => {
    if (!barData) {
      return null;
    }

    return _.map(barData, barDataEntry => {
      const barStyle = {
        width: `${Math.round(barDataEntry.percent * 100)}%`,
        background: barDataEntry.col,
      };
      return <div style={barStyle} />;
    });
  };

  getFullBar = () => {
    return <div className={style.audienceBreakdownBar}>{this.getBars(this.getSortedBarData())}</div>;
  };

  getSortedBarData = (): AudienceEntry[] => {
    let sortedBarsData = this.props.data;
    if (this.props.sortByName) {
      sortedBarsData = _.sortBy(this.props.data, ['group']);
    } else if (this.props.sort) {
      sortedBarsData = _.sortBy(this.props.data, ['percent']);
    }
    return sortedBarsData;
  };

  render() {
    return (
      <div className={style.audienceBreakdownGraph}>
        {this.getFullBar()}
        {this.getCallouts()}
      </div>
    );
  }
}

export default AudienceBreakdownGraph;
