import Modal from 'antd/lib/modal';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from './SDSModal.scss';

type Props = {
  className?: string;
  onCancel?: () => void;
  cancelText?: JSX.Element;
  visible: boolean;
  centerTitle?: boolean;
  title?: JSX.Element;
  subtitle?: JSX.Element;
  footer?: JSX.Element | boolean;
  width?: number;
  children: ReactNode;
  closable?: boolean;
  okButtonProps?: {
    disabled: boolean;
  };
  cancelButtonProps?: {
    disabled: boolean;
  };
  onOk?: () => void;
  okText?: JSX.Element;
  'data-test'?: string;
};

function SDSModal(props: Props) {
  const renderTitles = () => {
    const { title, subtitle, centerTitle } = props;

    if (!title) {
      return null;
    }
    return (
      <>
        <div className={classNames(style.mainTitle, { [style.centerTitle]: centerTitle })}>{title}</div>
        {subtitle && <div className={style.subtitle}>{subtitle}</div>}
      </>
    );
  };

  return (
    <Modal
      className={classNames(style.modal, props.className)}
      visible={props.visible}
      onCancel={props.onCancel}
      cancelText={props.cancelText}
      cancelButtonProps={props.cancelButtonProps}
      title={renderTitles()}
      footer={props.footer}
      width={props.width}
      closable={props.closable}
      okButtonProps={props.okButtonProps}
      onOk={props.onOk}
      okText={props.okText}
    >
      {props.children}
    </Modal>
  );
}

export default SDSModal;
