import React from 'react';
import type { ReactNode } from 'react';
import { Area, ComposedChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import AnalyticsGraphContainer from 'views/analytics/components/AnalyticsGraphContainer/AnalyticsGraphContainer';

type AreaEntry = {
  name: string;
  dataKey: string;
  key: string;
  fill: string;
};

type Props = {
  areas: Area[];
  data: any;
  title: string;
  tooltip?: ReactNode;
  isLoading: boolean;
  shouldShowToggle?: boolean;
  tooltipRenderer: () => ReactNode;
  yAxisTickFormatter: (num: number) => string;
  xAxisTickFormatter: (num: number) => string;
};

type OwnState = {
  isExpandedSelected: boolean;
};

export class AnalyticsAreaChart extends React.PureComponent<Props, OwnState> {
  state = {
    isExpandedSelected: false,
  };

  handleToggleUpdated = (isExpandedSelected: boolean) => {
    this.setState({ isExpandedSelected });
  };

  toPercent = (decimal: number, fixed: number = 0) => {
    return `${(decimal * 100).toFixed(fixed)}%`;
  };

  renderAreaChart = () => {
    const stackOffset = this.state.isExpandedSelected ? 'expand' : 'none';
    const yTickFormatter = this.state.isExpandedSelected ? this.toPercent : this.props.yAxisTickFormatter;

    return (
      <ComposedChart data={this.props.data} stackOffset={stackOffset}>
        <XAxis dataKey="name" scale="point" tickFormatter={this.props.xAxisTickFormatter} />
        <YAxis tickFormatter={yTickFormatter} />
        <Legend verticalAlign="top" align="right" iconType="circle" height={30} />
        <CartesianGrid />
        {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(area: AreaEntry, index: number)... Remove this comment to see the full error message
          this.props.areas.map((area: AreaEntry, index: number) => {
            const defaultFill = area.fill; // used for the legend as well
            return (
              <Area
                type="monotone"
                stackId="1"
                name={area.name}
                key={area.key}
                dataKey={area.dataKey}
                fill={defaultFill}
                stroke={defaultFill}
              />
            );
          })
        }
        <Tooltip
          content={this.props.tooltipRenderer}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          isExpandedSelected={this.state.isExpandedSelected}
          cursor={false}
          wrapperStyle={{
            background: 'white',
            border: '1px',
            borderColor: 'gray',
            borderRadius: '5px',
            padding: '10px',
            borderStyle: 'solid',
            zIndex: 5,
            minWidth: '180px',
          }}
        />
      </ComposedChart>
    );
  };

  render() {
    return (
      <AnalyticsGraphContainer
        tooltip={this.props.tooltip}
        defaultToggleMessage={getMessageFromId('analytics-toggle-area-stacked')}
        alternativeToggleMessage={getMessageFromId('analytics-toggle-area-expanded')}
        onToggleUpdated={this.handleToggleUpdated}
        chartTitle={this.props.title}
        shouldShowToggle={this.props.shouldShowToggle}
        isToggled={this.state.isExpandedSelected}
        isLoading={this.props.isLoading}
      >
        {this.renderAreaChart()}
      </AnalyticsGraphContainer>
    );
  }
}

export default AnalyticsAreaChart;
