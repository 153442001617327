import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { RevenueKPI } from 'views/analytics/components/RevenueKPI/RevenueKPI';
import { REVENUE_KPI_CONFIG } from 'views/analytics/utils/kpiConfigs';
import SDSColumn from 'views/common/components/SDSColumn/SDSColumn';
import SDSRow from 'views/common/components/SDSRow/SDSRow';

import style from './RevenueKPIs.scss';

import { RevenueMetric, RevenueSummary } from 'types/analytics';

type Props = {
  activeMetric: RevenueMetric | null;
  revenueSummary: RevenueSummary;
  setActiveMetric: (metric: RevenueMetric) => void;
};

export function RevenueKPIs({ activeMetric, revenueSummary, setActiveMetric }: Props) {
  function renderAnalyticsDelaySection() {
    return (
      <SDSColumn xs={6} md={6} lg={6} data-test="RevenueKPI.revenue.analyticsDelay">
        <div className={classNames(style.infoBox, 'card-box')}>
          <label className={style.infoBoxTitlePadding}>
            <div className={style.infoBoxTitleContainer}>
              <span className={style.infoBoxTitle}>
                <FormattedMessage
                  id="revenue-kpis-analytics-delay-overview"
                  description="Title of the analytics delay card"
                  defaultMessage="Overview"
                />
              </span>
            </div>
          </label>
          <div className={style.infoBoxContent}>
            <FormattedMessage
              id="revenue-kpis-analytics-delay"
              description="Content of the analytics delay card"
              defaultMessage="Please note that revenue data takes up to 7 days to populate from story posting date"
            />
          </div>
        </div>
      </SDSColumn>
    );
  }

  const renderKPIs = () => {
    const kpis = REVENUE_KPI_CONFIG.map(kpi => (
      <RevenueKPI
        key={kpi.metricId}
        metric={kpi}
        value={revenueSummary[kpi.metricId]}
        isActive={activeMetric === kpi.metricId}
        onMetricClicked={setActiveMetric}
      />
    ));
    return (
      <SDSRow>
        {kpis}
        {renderAnalyticsDelaySection()}
      </SDSRow>
    );
  };

  return <div className={style.content}>{renderKPIs()}</div>;
}
