import { useQuery } from '@apollo/client';

import { GetSpotlightAnalytics } from 'gql/queries/analytics/__ssp-generated__/GetSpotlightAnalytics';
import { GET_SPOTLIGHT_ANALYTICS } from 'gql/queries/analytics/spotlightAnalyticsQuery';

export const useGetSpotlightAnalytics = (businessProfileId: string, cursor: string) => {
  const { data, loading, fetchMore } = useQuery<GetSpotlightAnalytics>(GET_SPOTLIGHT_ANALYTICS, {
    variables: {
      businessProfileId,
      cursor,
    },
    // We want fetchMore to set `loading` to true when invoked with new parameters.
    notifyOnNetworkStatusChange: true,
  });
  return { data, loading, fetchMore };
};
