import { Empty } from 'antd';
import Table from 'antd/lib/table';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as storiesAnalyticsSelectors from 'state/analytics/selectors/storiesAnalyticsSelectors';
import * as editionsActions from 'state/editions/actions/editionsActions';

import type { CheetahStoryStateEnum } from 'config/constants';
import {
  ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT,
  AnalyticsInsightsTableType,
  CheetahStoryState,
} from 'config/constants';
import { help } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import { printSeconds, privacyNumberFormat } from 'utils/numberFormatter';

import AnalyticsInsightsTilePreview from 'views/analytics/components/AnalyticsInsightsTilePreview/AnalyticsInsightsTilePreview';
import { AntdTableOverriden } from 'views/analytics/components/AntdTableOverriden/AntdTableOverriden';
import LoadMoreItemsButton from 'views/analytics/components/LoadMoreItemsButton/LoadMoreItemsButton';
import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import { StoryStatusLabel } from 'views/homepage/components/StoryStatusLabel/StoryStatusLabel';

import style from './AnalyticsInsightsTopPerformingStoriesView.scss';

import type { AnalyticsStoryMetadata, CheetahAnalyticsStory, TopPerformingStoriesStats } from 'types/analytics';

const { Column } = Table;

type OwnProps = {
  topPerformingStories: TopPerformingStoriesStats;
};

type DispatchProps = {
  getEditionsWithFirstSnaps: typeof editionsActions.getEditionsWithFirstSnaps;
};

type Props = OwnProps & DispatchProps;

type State = {
  numberOfItemsToDisplay: number;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-top-performing-stories-tooltip"
      description="Text that appears in the Tooltip explanation of the Top Performing Stories table on the Analytics Insights page"
      defaultMessage="This table contains performance data from all Stories published in the last 30 days"
    />
  ),
  params: [],
});

const mapKeyToReferenceKey = {
  uniqueDau: 'globalAvgUniqueDau',
  avgTimeViewed: 'globalAvgTimeViewed',
  editionSubscribeCount: 'globalAvgEditionSubscribeCount',
};

const mapKeyToValueFormatter = {
  uniqueDau: privacyNumberFormat,
  avgTimeViewed: printSeconds,
  editionSubscribeCount: privacyNumberFormat,
};

const TABLE_TYPE = AnalyticsInsightsTableType.TOP_PERFORMING_STORIES;

const cheetahStatusIdToMessage: {
  [key in CheetahStoryStateEnum]: string;
} = {
  [CheetahStoryState.AVAILABLE]: 'available-status',
  [CheetahStoryState.UNAVAILABLE]: 'unavailable-status',
};

const mapDispatchToProps = {
  getEditionsWithFirstSnaps: editionsActions.getEditionsWithFirstSnaps,
};

export class AnalyticsInsightsTopPerformingStoriesView extends React.Component<Props, State> {
  state = {
    numberOfItemsToDisplay: 5,
  };

  componentDidMount() {
    this.fetchEditionData();
  }

  componentDidUpdate(prevProps: Props): void {
    if (!_.isEqual(this.props.topPerformingStories, prevProps.topPerformingStories)) {
      this.fetchEditionData();
    }
  }

  increaseNumberOfItemsToDisplay = () => {
    this.setState(prevState => ({ numberOfItemsToDisplay: prevState.numberOfItemsToDisplay + 5 }));
  };

  fetchEditionData = () => {
    const editionIds = this.props.topPerformingStories.storyStats
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      .map(story => parseInt(story.metadata.editionId, 10));
    this.props.getEditionsWithFirstSnaps(_.uniq(editionIds));
  };

  renderComparisonStats = (statValue: number, statName: string) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const referenceValue = mapKeyToReferenceKey[statName]
      ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        this.props.topPerformingStories[mapKeyToReferenceKey[statName]]
      : 1.0;
    // calculate how much (in percentage) the statValue of this content is above/below the referenceValue (average of all content)
    const percentageDifference = 100.0 * (statValue / referenceValue) - 100.0;

    return (
      <span
        className={classNames(style.multiplierValue, {
          [style.negativeValue]: percentageDifference < 0,
          [style.positiveValue]: percentageDifference > 0,
          [style.neutralValue]: percentageDifference === 0,
        })}
      >
        {`${percentageDifference > 0 ? '+' : ''}${percentageDifference.toFixed(1)}%`}
      </span>
    );
  };

  renderStatsWithComparison = (stat: number, statName: string) => {
    return (
      <div>
        <p>
          <span className={style.statWithComparisonBlock}>
            <span className={style.statValue}>
              {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {`${mapKeyToValueFormatter[statName](stat)}  `}
            </span>
            {this.renderComparisonStats(stat, statName)}
          </span>
        </p>
        <span className={style.previousDescription}>
          {getMessageFromId('analytics-insights-top-performing-tiles-reference-text')}
        </span>
      </div>
    );
  };

  renderTile = (storyMetadata: AnalyticsStoryMetadata) => {
    const { editionId, tileId, headline } = storyMetadata;
    const editionPreviewConfig = {
      editionId,
      headline,
      tileId,
    };
    return (
      <AnalyticsInsightsTilePreview
        editionPreviewConfig={editionPreviewConfig}
        analyticsInsightsTableType={TABLE_TYPE}
      />
    );
  };

  renderTableIndex = (index: number) => {
    return <span className={style.tableIndex}>{index + 1}</span>;
  };

  renderUniqueDau = (text: any) => {
    return this.renderStatsWithComparison(parseFloat(text.uniqueDau), 'uniqueDau');
  };

  renderAvgTotalTimeViewed = (text: any) => {
    return this.renderStatsWithComparison(parseFloat(text.avgTimeViewed), 'avgTimeViewed');
  };

  renderSubscribeCount = (text: any) => {
    return this.renderStatsWithComparison(parseFloat(text.editionSubscribeCount), 'editionSubscribeCount');
  };

  renderTileWrapper = (text: any, record: CheetahAnalyticsStory) => {
    return this.renderTile(text);
  };

  renderIndexWrapper = (text: any, record: CheetahAnalyticsStory, index: number) => {
    return this.renderTableIndex(index);
  };

  renderStatus = (text: any, record: CheetahAnalyticsStory) => {
    const status = storiesAnalyticsSelectors.getAvailability(record);
    return <StoryStatusLabel status={status} statusIdToMessageId={cheetahStatusIdToMessage} isAnalytics />;
  };

  renderExpandTableButton = () => {
    if (this.state.numberOfItemsToDisplay >= this.props.topPerformingStories.storyStats.length) {
      return null;
    }
    return (
      <LoadMoreItemsButton
        onClickHandler={this.increaseNumberOfItemsToDisplay}
        data-test="analyticsInsights.topPerforming.stories.loadMore"
      />
    );
  };

  makeRowKey = (record: CheetahAnalyticsStory) => `${record.metadata.editionId}_${record.metadata.tileId}`;

  timeViewedSorter = (a: CheetahAnalyticsStory, b: CheetahAnalyticsStory) =>
    a.stats.avgTimeViewed - b.stats.avgTimeViewed;

  uniqueDAUSorter = (a: CheetahAnalyticsStory, b: CheetahAnalyticsStory) => a.stats.uniqueDau - b.stats.uniqueDau;

  storySubscriberSorter = (a: CheetahAnalyticsStory, b: CheetahAnalyticsStory) =>
    a.stats.editionSubscribeCount - b.stats.editionSubscribeCount;

  renderTable = () => {
    // shouldn't render content with default state, due to lack of data
    if (this.props.topPerformingStories === ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT) {
      return (
        <span className={style.spinnerPosition}>
          <SpinnerIcon className={style.loadingIcon} />
        </span>
      );
    }
    return (
      <span>
        {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'AntdTableOverriden' does not hav... Remove this comment to see the full error message */}
        <AntdTableOverriden
          dataSource={this.props.topPerformingStories.storyStats}
          pagination={{
            pageSize: this.state.numberOfItemsToDisplay,
            total: this.state.numberOfItemsToDisplay,
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: this.renderNoDataMessage(),
          }}
          className={style.table}
          rowKey={this.makeRowKey}
        >
          <Column
            title=""
            dataIndex="storyIndex"
            key="storyIndex"
            render={this.renderIndexWrapper}
            width={50}
            fixed="left"
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-stories-table-tile-column-header-text')}
            dataIndex="metadata"
            key="story"
            render={this.renderTileWrapper}
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-stories-table-status-column-header-text')}
            dataIndex="metadata"
            key="status"
            render={this.renderStatus}
            width={150}
            fixed="right"
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-stories-table-time-viewed-column-header-text')}
            dataIndex="stats"
            key="avgTotalTimeViewed"
            render={this.renderAvgTotalTimeViewed}
            defaultSortOrder="descend"
            sorter={this.timeViewedSorter}
            sortDirections={['descend', 'ascend']}
            width={180}
            fixed="right"
          />
          <Column
            title={getMessageFromId('analytics-insights-top-performing-stories-table-unique-dau-column-header-text')}
            dataIndex="stats"
            key="uniqueDau"
            render={this.renderUniqueDau}
            sorter={this.uniqueDAUSorter}
            sortDirections={['descend', 'ascend']}
            width={180}
            fixed="right"
          />
          <Column
            title={getMessageFromId(
              'analytics-insights-top-performing-stories-table-subscriber-count-column-header-text'
            )}
            dataIndex="stats"
            key="subscribeCount"
            render={this.renderSubscribeCount}
            sorter={this.storySubscriberSorter}
            sortDirections={['descend', 'ascend']}
            width={180}
            fixed="right"
          />
        </AntdTableOverriden>
        {this.renderExpandTableButton()}
      </span>
    );
  };

  renderNoDataMessage = () => {
    return (
      <Empty
        description={<span>{getMessageFromId('analytics-insights-top-performing-stories-table-no-data-text')}</span>}
      />
    );
  };

  render() {
    return (
      <div className={style.tableContainer}>
        <div className={style.tableTitleContainer}>
          <span className={style.topStoriesTitle}>
            {getMessageFromId('analytics-insights-top-performing-stories-title')}
          </span>
          <SDSTooltip
            id="tooltip-info"
            title={getMessageFromId('analytics-insights-top-performing-stories-tooltip')}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            placement={TooltipPosition.RIGHT}
          >
            <Icon className={style.infoIcon} inlineIcon={help} />
          </SDSTooltip>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}

export default intlConnect(null, mapDispatchToProps)(AnalyticsInsightsTopPerformingStoriesView);
