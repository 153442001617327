import { last, memoize, get } from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapcodesActions from 'state/snapcodes/actions/snapcodesActions';
import * as snapcodesSelectors from 'state/snapcodes/selectors/snapcodesSelectors';

import { intlConnect } from 'utils/connectUtils';
import { functionRef } from 'utils/functionUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { openInNewWindow } from 'utils/locationUtils';
import { getSnapcodeImageUrl } from 'utils/snapcodeUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/BlankSnapcode.svg.inlin... Remove this comment to see the full error message
import blankSnapcode from 'images/BlankSnapcode.svg.inline';

import style from './SharingList.scss';

import type { Edition } from 'types/editions';
import type { Publisher } from 'types/publishers';
import type { State } from 'types/rootState';
import type { Snapcode } from 'types/snapcodes';

const snapcodeIntlMessage = <FormattedMessage id="snapcode" defaultMessage="Snapcode" description="snapcode" />;
const linkToStoryInSnapchatIntlMessage = (
  <FormattedMessage
    id="link-to-story-in-snapchat"
    defaultMessage="Link to story in Snapchat"
    description="message describing to the user that some text will link a user into the snapchat app"
  />
);
const copiedIntlMessage = (
  <FormattedMessage
    id="copied"
    defaultMessage="Copied"
    description="button text describing that some text was copied to their clipboard"
  />
);
const downloadIntlMessage = (
  <FormattedMessage
    id="sharing-list-download"
    defaultMessage="Download"
    description="button text describing that clicking the button will download something"
  />
);
const expandedSnapcodeCopy = (
  <FormattedMessage
    id="expanded-snapcode-copy"
    defaultMessage="Once available, users can scan this Snapcode to see this story."
    description={
      'text describing to the user that if they use the snapchat app to scan a snapcode (qr like code), ' +
      'then it will allow them to view story in the app'
    }
  />
);
type ProvidedProps = {
  edition: Edition;
  expandedSnapcodeItem?: boolean;
};
type StateProps = {
  snapcode?: Snapcode;
  activePublisher: Publisher;
};
type DispatchProps = {
  loadStorySnapcodes: typeof snapcodesActions.loadStorySnapcodes;
};
type Props = ProvidedProps & StateProps & DispatchProps;
type InternalState = {
  copiedDataTest: string | undefined | null;
};
function mapStateToProps(state: State, ownProps: Props) {
  const entityId = ownProps.edition?.id;
  const snapcodes = snapcodesSelectors.getDecoratedSnapcodesPerStoryId(state)(entityId);
  return {
    snapcode: last(snapcodes),
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
  };
}
const mapDispatchToProps = {
  loadStorySnapcodes: functionRef(snapcodesActions, 'loadStorySnapcodes'),
};
export class SharingList extends React.Component<Props, InternalState> {
  state = {
    copiedDataTest: null,
  };

  /* eslint-disable react/sort-comp */
  hiddenInput = null;

  /* eslint-enable react/sort-comp */
  componentDidMount() {
    // In concorde we create a snapcode for the story when it is scheduled, that means this sharing modal should
    // already have a snapcode to work with when it's loaded
    this.props.loadStorySnapcodes(this.props.edition.id);
  }

  onDownload = () => {
    const url = get(this.props, 'snapcode.downloadLinkUrl', null);
    if (url) {
      openInNewWindow(url);
    }
  };

  onCopy = (copyText: string) => () => {
    // @ts-expect-error ts-migrate(2358) FIXME: The left-hand side of an 'instanceof' expression m... Remove this comment to see the full error message
    if (this.hiddenInput instanceof HTMLInputElement) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.hiddenInput.value = copyText;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.hiddenInput.select();
      document.execCommand('copy');
    }
  };

  linkToStoryCopy() {
    const { snapcode } = this.props;
    return snapcode ? snapcode.fullDeepLinkUrl : '';
  }

  onCopyClicked(dataTest: string, callback: () => void) {
    return () => {
      this.setState({ copiedDataTest: dataTest });
      callback();
    };
  }

  renderMinimizedSnapcodeDownload() {
    const snapcodeUrl = this.props.snapcode ? this.props.snapcode.downloadLinkUrl : null;
    return (
      <div className={style.sharingItem} data-test="SharingList.snapcode">
        <span className={style.sharingDescription}>{snapcodeIntlMessage}</span>
        <SDSButton type={ButtonType.PRIMARY} disabled={!snapcodeUrl} onClick={this.onDownload}>
          {downloadIntlMessage}
        </SDSButton>
      </div>
    );
  }

  renderSnapcodeImage() {
    const { snapcode } = this.props;
    const imageUrl = snapcode ? getSnapcodeImageUrl(snapcode) : null;
    if (imageUrl) {
      return (
        <div className={style.snapcodeImage}>
          <img src={imageUrl} alt="" />
        </div>
      );
    }
    return (
      <div className={style.snapcodeImage}>
        <InlineSVG className={(style as any).placeholderSnapcode} src={blankSnapcode} />
      </div>
    );
  }

  renderExpandedSnapcodeDownload() {
    const snapcodeUrl = this.props.snapcode ? this.props.snapcode.downloadLinkUrl : null;
    return (
      <div className={style.expandedSnapcodeSharing} data-test="SharingList.snapcode">
        <div className={style.copyContainer}>
          <span className={style.sharingDescription}>{snapcodeIntlMessage}</span>
          <div className={style.expandedSnapcodeCopy}>{expandedSnapcodeCopy}</div>
          <SDSButton type={ButtonType.PRIMARY} disabled={!snapcodeUrl} onClick={this.onDownload}>
            {downloadIntlMessage}
          </SDSButton>
        </div>
        <div>{this.renderSnapcodeImage()}</div>
      </div>
    );
  }

  renderSharingItem(text: ReactNode, copyCallback: () => void, dataTest: string) {
    return (
      <div className={style.sharingItem} data-test={dataTest}>
        <span className={style.sharingDescription}>{text}</span>
        <SDSButton type={ButtonType.SECONDARY} onClick={memoize(this.onCopyClicked(dataTest, copyCallback))}>
          {this.state.copiedDataTest === dataTest ? copiedIntlMessage : getMessageFromId('copy')}
        </SDSButton>
      </div>
    );
  }

  renderSpacer = () => <div className={style.spacer} />;

  refHiddenInput = (element?: HTMLElement | null) => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element | Text | null' is not assignable to ... Remove this comment to see the full error message
    this.hiddenInput = ReactDOM.findDOMNode(element);
  };

  render() {
    return (
      <div className={style.container}>
        <input type="text" ref={this.refHiddenInput} className={style.hidden} />
        {this.props.expandedSnapcodeItem
          ? this.renderExpandedSnapcodeDownload()
          : this.renderMinimizedSnapcodeDownload()}
        {this.renderSpacer()}
        {this.renderSharingItem(
          linkToStoryInSnapchatIntlMessage,
          this.onCopy(this.linkToStoryCopy()),
          'SharingList.story-link'
        )}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SharingList);
