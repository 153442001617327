import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';

import * as autocompleteActions from 'state/autocomplete/actions/autocompleteActions';
import * as autocompleteSelectors from 'state/autocomplete/selectors/autocompleteSelectors';

import { SnapTag } from 'config/constants';
import type { SnapTagEnum } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';

import MultiSelectInput from 'views/common/components/MultiInput/MultiSelectInput';
import type { TagOptions } from 'views/common/components/MultiInput/MultiSelectInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

import style from './PublisherDetailTagRow.scss';

import type { TagMap } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';

type StateProps = {
  sccTagsMap: {
    [x: string]: string;
  };
};
type State = {
  autocompleteSuggestions: string[];
  latestAutocompleteString: string;
};
export const mapStateToProps = (state: RootState, props: Props): StateProps => {
  return {
    sccTagsMap: autocompleteSelectors.getSccCodeToTextMapping(state),
  };
};
const mapDispatchToProps = {
  autocompleteSCCTagSearch: autocompleteActions.autocompleteSCCTagSearch,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = {
  title: string | ReactNode;
  onChange: (a: TagMap) => void;
  className?: string;
  defaultTags: TagMap;
  disabled?: boolean;
  'data-test'?: string;
} & StateProps &
  DispatchProps;
export class PublisherDetailTagRow extends React.PureComponent<Props, State> {
  state = {
    autocompleteSuggestions: [],
    latestAutocompleteString: '',
  };

  onTagsChanged = (tags: TagMap) => {
    this.props.onChange(tags);
  };

  onTagsSearch = (search: string) => {
    const MIN_NUM_CHARS_TO_SEARCH = 3;
    if (search !== '' && search.length >= MIN_NUM_CHARS_TO_SEARCH) {
      this.setState({ latestAutocompleteString: search });
      this.searchTags(search);
    } else {
      this.setState({ autocompleteSuggestions: [], latestAutocompleteString: search });
    }
  };

  searchTags = _.debounce(
    search =>
      this.props.autocompleteSCCTagSearch(search).then(resultTags => {
        if (this.state.latestAutocompleteString === search) {
          this.setState({ autocompleteSuggestions: resultTags });
        }
      }),
    150
  );

  render() {
    const classes = classNames(style.parent, this.props.className);
    const tagOptions: {
      [k in SnapTagEnum]: TagOptions;
    } = {
      [SnapTag.SCC]: {
        defaultTags: this.props.defaultTags ? this.props.defaultTags[SnapTag.SCC] || [] : [],
        suggestions: this.state.autocompleteSuggestions,
        tagMapping: this.props.sccTagsMap,
        className: (style as any).scc,
        maxSelected: 3,
      },
    };
    return (
      <div className={classes} data-test={this.props['data-test']}>
        <SDSLabel data-test="onboarding.publisherDetail.tagRow.label" labelTitle={this.props.title}>
          <MultiSelectInput
            tagOptions={tagOptions}
            tagTypeOrder={[SnapTag.SCC]}
            onChange={this.onTagsChanged}
            searchItems={this.onTagsSearch}
            addSearchTermOnEnter
            disabled={this.props.disabled}
            waitForAllSuggestionsLoaded
          />
        </SDSLabel>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(PublisherDetailTagRow);
