import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE, GRAPH_SHADES_OF_GREEN } from 'views/analytics/utils/chartConfigs';

import type { StorySnapMetrics } from 'types/analytics';

type Props = {
  isLoading: boolean;
  metrics: StorySnapMetrics[];
  isOnPromotionTab: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="screenshots-organic"
      defaultMessage="Screenshots Organic"
      description="Screenshots for organic impressions"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="screenshots-paid"
      defaultMessage="Screenshots Paid"
      description="Screenshots for paid impressions"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="shares-organic"
      defaultMessage="Shares Organic"
      description="Shares for organic impressions"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage id="shares-paid" defaultMessage="Shares Paid" description="Shares for paid impressions" />
  ),
  params: [],
});

export class AnalyticsScreenshotsSharesGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getBarChartData = () => {
    return this.props.isOnPromotionTab
      ? this.props.metrics.map(metric => ({
          organicShares: metric.shares - (metric.paidShares || 0),
          organicScreenshots: metric.screenshots - (metric.paidScreenshots || 0),
          paidShares: metric.paidShares || 0,
          paidScreenshots: metric.paidScreenshots || 0,
          ...metric,
        }))
      : this.props.metrics;
  };

  getScreenshotSharesConfigs = () => {
    const defaultConfig = [
      {
        key: 'screenshots',
        name: getLocalisedMessageFromId(this.context, 'analytics-metric-screenshots'),
        fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
        stackId: 'x',
      },
      {
        key: 'shares',
        name: getLocalisedMessageFromId(this.context, 'analytics-metric-shares'),
        fill: GRAPH_SHADES_OF_BLUE.STRONG_BLUE,
        stackId: 'x',
      },
    ];

    const promotionConfig = [
      {
        key: 'organicScreenshots',
        name: getLocalisedMessageFromId(this.context, 'screenshots-organic'),
        fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
        stackId: 'screenshots',
      },
      {
        key: 'paidScreenshots',
        name: getLocalisedMessageFromId(this.context, 'screenshots-paid'),
        fill: GRAPH_SHADES_OF_GREEN.MED_GREEN,
        stackId: 'screenshots',
      },
      {
        key: 'organicShares',
        name: getLocalisedMessageFromId(this.context, 'shares-organic'),
        fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
        stackId: 'shares',
      },
      {
        key: 'paidShares',
        name: getLocalisedMessageFromId(this.context, 'shares-paid'),
        fill: GRAPH_SHADES_OF_GREEN.NORMAL_GREEN,
        stackId: 'shares',
      },
    ];

    return this.props.isOnPromotionTab ? promotionConfig : defaultConfig;
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const {
      name,
      snapId,
      mediaId,
      mediaType,
      organicScreenshots,
      paidScreenshots,
      organicShares,
      paidShares,
      shares,
      screenshots,
      mediaUrl,
    } = payload;
    const getScreenshotSharesConfigs = this.getScreenshotSharesConfigs();
    let metricRows;
    if (this.props.isOnPromotionTab) {
      metricRows = [
        {
          metricName: getScreenshotSharesConfigs[0]?.name,
          metricValue: numberFormatter.privacyNumberFormat(organicScreenshots),
        },
        {
          metricName: getScreenshotSharesConfigs[1]?.name,
          metricValue: numberFormatter.privacyNumberFormat(paidScreenshots),
        },
        {
          metricName: getScreenshotSharesConfigs[2]?.name,
          metricValue: numberFormatter.privacyNumberFormat(organicShares),
        },
        {
          metricName: getScreenshotSharesConfigs[3]?.name,
          metricValue: numberFormatter.privacyNumberFormat(paidShares),
        },
      ];
    } else {
      metricRows = [
        {
          metricName: getScreenshotSharesConfigs[0]?.name,
          metricValue: numberFormatter.privacyNumberFormat(screenshots),
        },
        {
          metricName: getScreenshotSharesConfigs[1]?.name,
          metricValue: numberFormatter.privacyNumberFormat(shares),
        },
      ];
    }

    return (
      <AnalyticsTooltip
        title={name}
        snapId={snapId}
        generatedPreviewUrl={mediaUrl}
        mediaId={mediaId}
        mediaType={mediaType}
        metricRows={metricRows}
      />
    );
  };

  render() {
    return (
      <AnalyticsBarChart
        graphTooltip={getMessageFromId('publisher-story-screenshots-shares-explanation')}
        barChartData={this.getBarChartData()}
        barChartTitle={getMessageFromId('publisher-story-screenshots-shares')}
        bars={this.getScreenshotSharesConfigs()}
        shouldShowToggle
        isLoading={this.props.isLoading}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={numberFormatter.prettyPrintNumber}
      />
    );
  }
}

export default AnalyticsScreenshotsSharesGraph;
