// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Spinner } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';
import InlineSVG from 'svg-inline-react';

import * as intlMessages from 'utils/intlMessages/intlMessages';
import { openInNewWindow } from 'utils/locationUtils';
import { getSnapcodeImageUrl } from 'utils/snapcodeUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/ghost_missing.svg.inlin... Remove this comment to see the full error message
import ghostMissingSVG from 'images/ghost_missing.svg.inline';

import style from './PublisherSnapcodePreview.scss';

import type { Snapcode } from 'types/snapcodes';

type Props = {
  snapcodes: Snapcode[] | null;
};

export class PublisherSnapcodePreview extends React.Component<Props> {
  snapcodeUrlRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.snapcodeUrlRef = React.createRef();
  }

  copyUrl = () => {
    if (this.snapcodeUrlRef.current) {
      this.snapcodeUrlRef.current.select();
      document.execCommand('copy');
    }
  };

  handleDownload = (snapcode: Snapcode) => () => openInNewWindow(snapcode.downloadLinkUrl);

  renderSnapcode = (snapcode: Snapcode) => {
    const snapcodeUrl = getSnapcodeImageUrl(snapcode);
    return <img src={snapcodeUrl} alt="Publisher Snapcode" />;
  };

  renderSnapcodePlaceholder = () => {
    return (
      <div className={style.parent}>
        <div className={style.info}>
          <p className={style.title}>{intlMessages.getMessageFromId('publisher-snapcode-title')}</p>
          <p className={style.description}>{intlMessages.getMessageFromId('publisher-no-snapcode-part-1')}</p>
          <p className={style.description}>{intlMessages.getMessageFromId('publisher-no-snapcode-part-2')}</p>
        </div>
        <div className={style.preview}>
          <InlineSVG src={ghostMissingSVG} />
        </div>
      </div>
    );
  };

  render() {
    const { snapcodes } = this.props;

    // no snapcodes has loaded yet
    if (!snapcodes) {
      return (
        <div className={style.spinnerParent}>
          <Spinner />
        </div>
      );
    }

    const snapcode = snapcodes[snapcodes.length - 1];
    if (!snapcode) {
      return this.renderSnapcodePlaceholder();
    }

    return (
      <div className={style.parent}>
        <div className={style.info}>
          <p className={style.title}>{intlMessages.getMessageFromId('publisher-snapcode-title')}</p>
          <p className={style.description}>{intlMessages.getMessageFromId('publisher-snapcode-part-1')}</p>
          <p className={style.description}>{intlMessages.getMessageFromId('publisher-snapcode-part-2')}</p>
          <div className={style.buttons} data-test="publisherSnapCodePreview.snapCodeButton">
            <SDSButton
              type={ButtonType.PRIMARY}
              onClick={this.handleDownload(snapcode)}
              data-test="onboarding.publisherSnapcodePreview.download.button"
            >
              {intlMessages.getMessageFromId('story-snapcode-sharing-download')}
            </SDSButton>
            <SDSButton
              type={ButtonType.SECONDARY}
              onClick={this.copyUrl}
              data-test="onboarding.publisherSnapcodePreview.copy.button"
            >
              {intlMessages.getMessageFromId('story-snapcode-sharing-copy-to-clipboard')}
            </SDSButton>
          </div>
        </div>
        <div className={style.preview} data-test="publisherSnapCodePreview.snapCodePreview">
          {this.renderSnapcode(snapcode)}
        </div>
        <input
          type="text"
          ref={this.snapcodeUrlRef}
          defaultValue={snapcode?.fullDeepLinkUrl}
          className={style.snapcodeUrl}
        />
      </div>
    );
  }
}
export default PublisherSnapcodePreview;
