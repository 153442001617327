import { gql } from '@apollo/client';

export const GET_STORY_PAGE_ANALYTICS = gql`
  query GetStoryPageAnalytics(
    $input: StoryAnalyticsInput!
    $hostUserId: UUID!
    $storyId: ID!
    $storyIdString: String!
    $country: EditionCountry!
  ) {
    storyAnalytics(input: $input) {
      ... on StoryAnalyticsResult {
        profileStoryAnalytics {
          storyState
          firstLiveDate
          ageGate
          isSponsored
          uniqueViewers
          uniqueViewersPaid
          uniqueViewersSubscriber
          uniqueViewersMale
          uniqueViewersFemale
          uniqueViewers13to17
          uniqueViewers18to24
          uniqueViewers25to34
          uniqueViewers35Plus
          timeSpentMs
          attachmentConversion
          screenshots
          shares
          snapsPerUser
          subscribersAdded
          subscribersAddedPaid
          totalStoryViews
          storyRunTime
          reachAffectedByModeration
          tileCount
          snapCount
          uniqueViewersForTimestampEntries {
            timeStamp
            value
          }
          uniqueViewersPaidForTimestampEntries {
            timeStamp
            value
          }
          uniqueViewersOrganicForTimestampEntries {
            timeStamp
            value
          }
        }
      }
      ... on InvalidArgumentError {
        field
        message
      }
    }
    tileAnalytics(input: { hostUserId: $hostUserId, storyId: $storyIdString }) {
      ... on TileAnalyticsResult {
        tileAnalytics {
          id
          headline
          indexedCtr
          uniqueTopsnapsPerUser
          impressionPercentage
          tileAverageViewTime
          tileLogoUrl
          tileImageUrl
        }
      }
    }
    snapAnalytics(hostUserId: $hostUserId, storyId: $storyId, country: $country) {
      snapAnalytics {
        analyticsEntries {
          snapId
          duration
          seekPoint
          mediaUrl
          hasChapterId
          analytics {
            chapterId
            uniqueViewers
            uniqueViewersSubscriber
            uniqueViewersNonSubscriber
            snapDropOffRate
            snapDropOffRatePaid
            snapDropOffRateOrganic
            uniqueViewersPaid
            timeSpentMs
            timeSpentMsPaid
            timeSpentMsOrganic
            snapCompletionRate
            snapCompletionRatePaid
            snapCompletionRateOrganic
            attachmentConversion
            attachmentConversionPaid
            attachmentConversionOrganic
            screenshots
            screenshotsPaid
            screenshotsOrganic
            shares
            sharesPaid
            sharesOrganic
            attachmentTimeSpentMsOrganic
            attachmentTimeSpentMsPaid
            attachmentTimeSpentMsOrganic
          }
        }
      }
    }
  }
`;
