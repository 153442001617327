import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

import { BASE_PATH, EMPTY_ARRAY, EMPTY_OBJECT } from 'config/constants';
import { getHostname } from 'utils/locationUtils';

// These selectors are related to the router but the 'router' substate is owned by another module.
// So we have 'extraRouter' selectors and reducers to avoid polluting state we don't own.

const getExtraRouter = (state: any) => {
  return state.extraRouter || EMPTY_OBJECT;
};

export const getLocationHistory = createKeySelector(getExtraRouter, 'locationHistory', EMPTY_OBJECT);
export const getLocationHistoryPaths = createKeySelector(getLocationHistory, 'paths', EMPTY_ARRAY);
export const getPreviousLocationPath = s(getLocationHistoryPaths, paths => {
  return paths.length >= 2 ? paths[1] : null; // paths[0] is the current location
});
export const getPreviousLocationPathFull = s(getPreviousLocationPath, path => {
  return path ? `${getHostname()}${BASE_PATH}${path}` : null;
});
