import moment from 'moment-timezone';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'quer... Remove this comment to see the full error message
import queryString from 'query-string';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"react-router"' has no exported member 'Lo... Remove this comment to see the full error message
import type { Location, RouterHistory } from 'react-router';

export const getDatesFromSearchParams = (location: Location) => {
  const dateStrings = queryString.parse(location.search);
  const dateMoments = {
    from: moment(dateStrings.from, 'YYYY-MM-DD'),
    to: moment(dateStrings.to, 'YYYY-MM-DD'),
  };
  if (dateMoments.from.isValid() && dateMoments.to.isValid() && dateMoments.from <= dateMoments.to) {
    return dateMoments;
  }
  return {
    to: null,
    from: null,
  };
};

export const updateDatesInSearchParams = (
  history: RouterHistory,
  from: moment.Moment,
  to: moment.Moment,
  replace: boolean = false
) => {
  const newQueryValues = queryString.stringify({
    from: from.format('YYYY-MM-DD').toString(),
    to: to.format('YYYY-MM-DD').toString(),
  });
  if (replace) {
    history.replace(`?${newQueryValues}`);
  } else {
    history.push(`?${newQueryValues}`);
  }
};
