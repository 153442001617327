import { GET_TOPICS, TopicRequest, TopicsData } from 'gql/queries/spotlight/topics';
import apolloClient from 'utils/apis/apollo';

export const getTopics = async (request: TopicRequest) => {
  const { data } = await apolloClient.query<TopicsData>({
    query: GET_TOPICS,
    // We need to handle the exception as from apollo client 2
    // error policy 'none' throws an exception
    // https://github.com/apollographql/apollo-client/issues/5708
    errorPolicy: 'none',
    variables: {
      input: request,
    },
  });

  return { data };
};
