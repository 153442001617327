import classNames from 'classnames';
import is from 'is_js';
import _ from 'lodash';
import moment from 'moment-timezone';
import type { ChangeEvent, ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  isRemoteWebLimitEnabled,
  isSingleAssetStoryEditorEnabled,
  isOptionalAdsOverrideEnabled,
} from 'state/features/selectors/featuresSelectors';
import * as inventoryActions from 'state/inventory/actions/inventoryActions';
import * as inventorySelectors from 'state/inventory/selectors/inventorySelectors';
import * as modalActions from 'state/modals/actions/modalsActions';
import { loadOrg } from 'state/organisations/actions/organisationsActions';
import * as publisherSettingsActions from 'state/publisherSettings/actions/publisherSettingsActions';
import {
  getStoriesForPublisherInAvailableState,
  setPublisherDetailsWithGraphQLResponse,
} from 'state/publishers/actions/publishersActions';
import { isShowPublisher } from 'state/publishers/schema/publisherEntityHelpers';
import { getActivePublisherDetails, isPublishingEnabled } from 'state/publishers/selectors/publishersSelectors';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import {
  DEFAULT_ADS_LATEST_FIRST_SLOT,
  DEFAULT_ADS_MAX_SLOTS,
  DEFAULT_ADS_MIN_SLOTS,
  DEFAULT_ADS_MIN_SPACING,
} from 'config/adsConfig';
import type { ContentLifeSpanEnum } from 'config/constants';
import {
  ADMIN_SETTINGS_KEYS,
  AdSetting,
  AgeGate,
  ContentLifeSpan,
  EditorialStatus,
  getContentLifeSpanChoices,
  ModerationLevel,
  ShareOption,
  StageType,
} from 'config/constants';
import withUpdatePublisherMutation from 'gql/hocs/withUpdatePublisherMutation';
import { UpdatePublisherMutationResult } from 'gql/hooks/useUpdatePublisherMutation';
import { UpdatePublisherResponse, UpdatePublisherResponseDetails } from 'gql/types/updatePublisherTypes';
import { formatStagedSuperAdminSettingsData } from 'gql/utils/graphQLSettingsUtils';
import { Manager } from 'src/constants/managers';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getNonSensitiveCountryCodes, getSensitiveCountryNames, isSensitiveCountry } from 'utils/countryUtils';
import { Enum } from 'utils/enum';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import {
  allCountries,
  formatContentAccessLists,
  formatPublisherForEditing,
  GeneratedPublisherType,
  SHOW_PUBLISHER_DEFAULTS,
  togglePublisherFeatures,
} from 'utils/managePublisherUtils';
import { createAssetUrl } from 'utils/media/assetUtils';
import { ModalType } from 'utils/modalConfig';
import { withKey } from 'utils/reactUtils';

import CountryPicker from 'views/common/components/CountryPicker/CountryPicker';
import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import MultiTagInput from 'views/common/components/MultiInput/MultiTagInput';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput, { InputType } from 'views/common/components/SDSInput/SDSInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSPanel from 'views/common/components/SDSPanel/SDSPanel';
import SDSTextArea from 'views/common/components/SDSTextArea/SDSTextArea';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { AlertOptions } from 'views/modals/containers/AlertModal/AlertModal';
import PublisherDetailImageRow from 'views/onboarding/components/PublisherDetailImageRow/PublisherDetailImageRow';
import PublisherDetailOption from 'views/onboarding/components/PublisherDetailOptionRow/PublisherDetailOptionRow';
import OrganisationInput from 'views/onboarding/containers/SettingsView/components/OrganisationInput/OrganisationInput';
import SaveSettingsButton from 'views/onboarding/containers/SettingsView/save/SaveSettingsButton';
import {
  REQUIRED_PROPERTIES_TRANSLATIONS,
  TRANSLATIONS,
} from 'views/onboarding/containers/SettingsView/tabs/SuperAdminTab/superAdminTranslations';
import ManagerDropdown from 'views/snapAdmin/components/ManagerDropdown/ManagerDropdown';

import ArchiveButton from './ArchiveButton/ArchiveButton';
import InventoryDropdown from './InventoryDropdown/InventoryDropdown';
import type { TagOptions } from './MultiSelectTagFilter/MultiSelectTagFilter';
import MultiSelectTagFilter from './MultiSelectTagFilter/MultiSelectTagFilter';
import style from './SuperAdminTab.scss';
import type { PublisherKey, TemporaryPublisher } from './superAdminSettingsConfig';
import {
  BUTTONS_DEFINITION,
  CARD_HEADERS,
  COUNTRY_INPUT_DEFINITION,
  DROPDOWNS_DEFINITION,
  EDITORIAL_RATIONALE_DEFINITION,
  FEATURES_BUTTONS_DEFINITION,
  IMAGE_INPUT_DEFINITION,
  INLINE_NUMBER_INPUT_DEFINITION,
  MULTI_SELECT_INPUT_DEFINITION,
  TEXT_INPUT_DEFINITION,
} from './superAdminSettingsConfig';

import type { PickedCountryCodes } from 'types/countries';
import { ContentAccessLists, CountryCodeCategory } from 'types/countries';
import {
  DefaultPublisherBundles,
  DefaultShowsBundles,
  INVENTORY_BUNDLE_TYPE_EXISTING,
  INVENTORY_BUNDLE_TYPE_NEW,
} from 'types/inventory';
import { Claim } from 'types/permissions';
import type { EditablePublisherProperties } from 'types/publisherSettings';
import { PublisherID, TierLevel, PublisherType, PublisherFeature } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { Show, ShowID } from 'types/shows';
import { NEW_SHOW_TEMP_ID } from 'types/shows';

type TooltipWrapParams = {
  overlayDataTest: string;
  tooltipId: string;
  tooltipText: string | JSX.Element;
  tooltipPosition: Enum<typeof TooltipPosition>;
  childElement: ReactNode;
  contentClassName?: string;
};
type SettingChangeHandler = (a: PublisherKey) => (b: any) => void;
type ExternalProps = {
  publisherId: PublisherID;
  setTabHasActiveChanges: (a: boolean) => void;
};
type StateProps = {
  isShowPublisher: boolean;
  isSuperAdmin: boolean;
  publisher: TemporaryPublisher;
  stagedPublisher: TemporaryPublisher | undefined | null;
  showId: ShowID;
  inventoryItem: any;
  activeInventoryBundlesByIdMap: any;
  inventoryBundleItemMappingsByBundleIdMap: any;
  isInventoryDataLoading: boolean;
  isRemoteWebLimitEnabled: boolean;
  isSingleAssetStoryEditorEnabled: boolean;
  isOptionalAdsOverrideEnabled: boolean;
  isPublishingEnabled: boolean;
};
type OwnProps = UpdatePublisherMutationResult;
type OwnState = {
  isSaving: boolean;
  hasMadeChanges: boolean;
  hasInventoryChanges: boolean;
  bundles: {
    [tag: string]: string[];
  };
};

type RequiredPropertyKey = keyof typeof REQUIRED_PROPERTIES_TRANSLATIONS;

const MANDATORY_PUBLISHER_PROPERTIES: Array<RequiredPropertyKey> = [
  'horizontalIconId',
  'description',
  'primaryColor',
  'squareHeroImageId',
  'hostUsername',
];

const MANDATORY_MANAGER_PROPERTIES: Array<RequiredPropertyKey> = [
  'homeCountry',
  'managerEmail',
  'managerName',
  'editorialRationale',
];

function validateUrlText(urlText: string) {
  return urlText.length === 0 || is.url(urlText);
}

function validateWebviewLimit(webviewLimit: number) {
  return webviewLimit >= 0 && webviewLimit <= 30;
}

function mapStateToProps(state: State, props: ExternalProps): StateProps {
  const publisher = getActivePublisherDetails(state);
  const shows: Show[] | undefined | null = publisher ? showsSelectors.getShows(state)((publisher as any).id) : [];
  const show = _.head(shows);
  const showId = _.get(show, 'id', NEW_SHOW_TEMP_ID);
  return {
    isShowPublisher: isShowPublisher(publisher),
    isSuperAdmin: userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'TemporaryPubl... Remove this comment to see the full error message
    publisher: getActivePublisherDetails(state),
    stagedPublisher: stagesSelectors.getData(state)(publisher?.id),
    showId,
    inventoryItem: inventorySelectors.getPublisherInventoryItemByBpid(state)((publisher as any).businessProfileId),
    activeInventoryBundlesByIdMap: inventorySelectors.getInventoryBundlesByIdMap(state),
    inventoryBundleItemMappingsByBundleIdMap: inventorySelectors.getInventoryBundleItemMappingsByBundleIdMap(state),
    isInventoryDataLoading: inventorySelectors.isInventoryDataLoading(state),
    isRemoteWebLimitEnabled: isRemoteWebLimitEnabled(state),
    isSingleAssetStoryEditorEnabled: isSingleAssetStoryEditorEnabled(state),
    isOptionalAdsOverrideEnabled: isOptionalAdsOverrideEnabled(state),
    isPublishingEnabled: isPublishingEnabled(state),
  };
}

const mapDispatchToProps = {
  saveAndUploadMediaSettings: publisherSettingsActions.saveSuperAdminMediaSettings,
  getOrg: loadOrg,
  stageData: stagesActions.stageData,
  updateProperties: stagesActions.updateProperties,
  getPublisherInventoryItem: inventoryActions.loadInventoryItem,
  getInventoryBundles: inventoryActions.loadInventoryBundles,
  getInventoryBundleItemMappings: inventoryActions.loadInventoryBundleItemMappings,
  getStoriesForPublisherInAvailableState,
  saveInventoryData: inventoryActions.saveInventoryData,
  showModal: modalActions.showModal,
  setModalConfigProperties: modalActions.setModalConfigProperties,
  updatePublisherProperties: setPublisherDetailsWithGraphQLResponse,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps & OwnProps;
const MULTI_SELECT_DEFAULT_KEY = 'MULTI_SELECT_DEFAULT_KEY';
const mapPropsToKey = (props: Props) => props.publisherId;

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withKey(mapPropsToKey)
// @ts-expect-error ts-migrate(1219) FIXME: Experimental support for decorators is a feature t... Remove this comment to see the full error message
class SuperAdminTab extends React.PureComponent<Props, OwnState> {
  state: OwnState = {
    isSaving: false,
    hasMadeChanges: false,
    hasInventoryChanges: false,
    bundles: {},
  };

  handlePublisherFeatureOnChange = _.memoize((keys: PublisherFeature[]) => (values: any) => {
    const valueSlice = typeof values !== 'undefined' && values instanceof Array ? values : [values];
    if (this.props.stagedPublisher) {
      const publisherFeatures = togglePublisherFeatures(
        this.props.stagedPublisher.publisherFeatures.slice(),
        keys,
        valueSlice
      );
      this.setState({ hasMadeChanges: true });
      this.props.updateProperties(this.props.publisherId, { publisherFeatures });
    }
  });

  handleOnChange: SettingChangeHandler = _.memoize((key: PublisherKey) => (value: any) => {
    if (key === 'defaultAdSlots' || key === 'defaultAdSlotsMillis') {
      // eslint-disable-next-line no-param-reassign
      value = value ? value.split(',').map((x: string) => parseInt(x, 10)) : [];
    }
    if (key === 'timeUntilAutoPublishFeedStory') {
      // The field is in seconds
      value = 60 * value; // eslint-disable-line
    }
    if (key === 'test') {
      const typeValue = value === true ? PublisherType.TEST : PublisherType.NORMAL;
      this.props.updateProperties(this.props.publisherId, {
        type: typeValue,
      });
    }
    this.props.updateProperties(this.props.publisherId, {
      [key]: value,
    });
    this.setState({ hasMadeChanges: true });

    if (key === ADMIN_SETTINGS_KEYS.TIER) {
      if (value === TierLevel.SHOW) {
        this.props.updateProperties(this.props.publisherId, {
          ...SHOW_PUBLISHER_DEFAULTS,
        });
      }

      if (this.props.stagedPublisher?.tier === TierLevel.HAPPENING_NOW) {
        this.props.updateProperties(this.props.publisherId, {
          subscribable: false,
        });
      }

      if (value === TierLevel.DYNAMIC_EDITIONS) {
        this.props.updateProperties(this.props.publisherId, {
          dynamicEditionAutoCreateStoryTimeUTC: {
            hour: 22,
            minute: 0,
          },
        });
      }
    }

    if (key === ADMIN_SETTINGS_KEYS.ADVERTISING_ENABLED) {
      if (!this.props.stagedPublisher?.advertisingEnabled) {
        this.props.updateProperties(this.props.publisherId, {
          dynamicAdsEnabled: false,
        });
      }
    }

    if (key === ADMIN_SETTINGS_KEYS.ADVANCED_ADS_ENABLED) {
      if (this.props.stagedPublisher?.advancedAdsEnabled) {
        this.props.updateProperties(this.props.publisherId, {
          dynamicAdsEnabled: false,
        });
      }
    }

    if (key === ADMIN_SETTINGS_KEYS.PUBLISHER_TYPE) {
      const publisherFeaturesToToggle = [PublisherFeature.TEAM_SNAPCHAT, PublisherFeature.FRIENDS_CAROUSEL];
      // Check if any of the publisher features is assigned as value of PublisherType
      const toggleValues: boolean[] = publisherFeaturesToToggle.map(publisherFeature => publisherFeature === value);
      this.handlePublisherFeatureOnChange(publisherFeaturesToToggle)(toggleValues);

      if (publisherFeaturesToToggle.includes(value)) {
        this.props.updateProperties(this.props.publisherId, {
          publisherType: GeneratedPublisherType.PUBLISHER,
          isOurStories: false,
        });
      } else if (value === GeneratedPublisherType.OUR_STORIES) {
        this.props.updateProperties(this.props.publisherId, {
          isOurStories: true,
        });
      }
      // GeneratedPublisherType === Publisher
      else {
        this.props.updateProperties(this.props.publisherId, {
          isOurStories: false,
        });
      }
    }
  });

  handleNumberInputOnChange: SettingChangeHandler = _.memoize(
    (key: PublisherKey) => (event: ChangeEvent<EventTarget>) => {
      return this.handleOnChange(key)(parseInt((event.target as any).value, 10));
    }
  );

  handleInputOnChange: SettingChangeHandler = _.memoize((key: PublisherKey) => (event: ChangeEvent<EventTarget>) => {
    return this.handleOnChange(key)((event.target as any).value);
  });

  handleDropdownOnChange: SettingChangeHandler = _.memoize((key: PublisherKey) => (value: string) => {
    return this.handleOnChange(key)(value);
  });

  handleMultiSelectOnChange: SettingChangeHandler = _.memoize(
    (key: PublisherKey) => (event: { [x: string]: string[] }) => {
      return this.handleOnChange(key)(event[MULTI_SELECT_DEFAULT_KEY]);
    }
  );

  componentDidMount() {
    const { publisher, publisherId, stageData, updateProperties } = this.props;
    Promise.all([stageData(publisherId, StageType.PUBLISHER)])
      .then(() => {
        if (publisher) {
          const newProperties = formatPublisherForEditing(publisher);
          updateProperties(this.props.publisherId, newProperties);
        }
      })
      .then(() => this.props.getOrg({ bpid: this.props.publisher.businessProfileId }))
      .then((res: any) =>
        this.props.getPublisherInventoryItem({
          organizationId: res.payload.orgId,
          businessProfileId: this.props.publisher.businessProfileId,
        })
      )
      .then((res: any) => this.props.getInventoryBundleItemMappings({ publisherId: res.payload.inventoryItems[0].id }));
    this.props.getInventoryBundles();
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState) {
    if (prevProps.publisher.id !== this.props.publisher.id) {
      this.props.updateProperties(this.props.publisher.id, formatPublisherForEditing(this.props.publisher));
    }
    if (prevState.hasMadeChanges !== this.state.hasMadeChanges) {
      this.props.setTabHasActiveChanges(this.state.hasMadeChanges);
    }
  }

  onLaunchDateChanged = (launchDate?: moment.Moment | null) => {
    const launchTimestamp = launchDate ? launchDate.valueOf() : null;
    this.props.updateProperties(this.props.publisherId, { launchDate: launchTimestamp });
  };

  getPublisherValue(key: PublisherKey, defaultValue?: any): any {
    const propertyValue = _.get(this.props.stagedPublisher, key, defaultValue);
    if (key === 'timeUntilAutoPublishFeedStory') {
      return propertyValue / 60;
    }
    if (key === 'defaultAdSlots' || key === 'defaultAdSlotsMillis') {
      return propertyValue.join(', ');
    }
    if (key === 'publisherType') {
      if (this.getPublisherFeatureValue(PublisherFeature.TEAM_SNAPCHAT) === true) {
        return PublisherFeature.TEAM_SNAPCHAT;
      }
      if (this.getPublisherFeatureValue(PublisherFeature.FRIENDS_CAROUSEL) === true) {
        return PublisherFeature.FRIENDS_CAROUSEL;
      }
    }
    return propertyValue;
  }

  getPublisherFeatureValue(key: PublisherFeature) {
    if (this.props.stagedPublisher) {
      const { publisherFeatures } = this.props.stagedPublisher;
      return publisherFeatures && publisherFeatures.includes(key);
    }
    return false;
  }

  isLoadingCurrentPublisher = () => {
    return Object.keys(this.props.publisher).length === 0 || this.props.stagedPublisher?.id !== this.props.publisherId;
  };

  adsEnabled = () => this.props.stagedPublisher?.advertisingEnabled;

  simpleAdsEnabled = () => this.adsEnabled() && !this.props.stagedPublisher?.advancedAdsEnabled;

  advancedAdsEnabled = () => this.adsEnabled() && this.props.stagedPublisher?.advancedAdsEnabled;

  stagedIsShow = () => this.props.stagedPublisher?.tier === TierLevel.SHOW;

  isPublisherTierInvalid = async () => {
    if (this.props.isShowPublisher !== this.stagedIsShow()) {
      const getStoriesForPublisherInAvailableStateResponse = await this.props.getStoriesForPublisherInAvailableState(
        this.props.publisherId
      );
      return getStoriesForPublisherInAvailableStateResponse?.payload?.result?.length > 0;
    }
    return false;
  };

  canAddDefaultBundles = () => {
    return (
      this.stagedIsShow() ||
      (this.props.stagedPublisher?.publisherType === GeneratedPublisherType.PUBLISHER &&
        this.props.stagedPublisher?.tier === TierLevel.PREMIUM)
    );
  };

  setAddDefaultInventoryBundles = () => {
    const defaultBundles = this.stagedIsShow() ? DefaultShowsBundles : DefaultPublisherBundles;
    this.handleInventoryBundleChange(defaultBundles);
  };

  handleOnSave = async () => {
    this.setState({ isSaving: true });
    const hasBundlesInState =
      Object.keys(this.state.bundles)
        .map(key => (this.state.bundles[key] || []).length)
        .filter(len => len > 0).length > 0;
    const hasBundlesInProps = Object.keys(this.props.inventoryBundleItemMappingsByBundleIdMap).length > 0;
    const hasBundles = hasBundlesInState || hasBundlesInProps;

    // The below checks that the publisher is currently disabled, but becoming enabled on settings save.
    const isBeingEnabled = !this.props.isPublishingEnabled && this.props.stagedPublisher?.publishingEnabled;

    if (isBeingEnabled && !this.props.stagedPublisher?.advertisingEnabled) {
      const optionsAccountAdsDisabled: DialogModalOptions = {
        visible: true,
        isBodyCentered: true,
        title: (
          <FormattedMessage id="ads-are-disabled" defaultMessage="Ads are disabled" description="Title for an alert" />
        ),
        body: (
          <FormattedMessage
            id="account-ads-disabled"
            description="Message displayed when publisher has ads disabled"
            defaultMessage="This account has ads disabled. Continue to save?"
          />
        ),
        okText: getMessageFromId('confirm-button-modal'),
        onConfirm: async () => {
          await this.savePublisherSettings();
        },
        cancelText: getMessageFromId('cancel-button-modal'),
        onCancel: () => {
          this.setState({ isSaving: false });
        },
      };

      this.props.showModal(ModalType.DIALOG, 'SuperAdminTabSave', optionsAccountAdsDisabled);
      return;
    }

    if (isBeingEnabled && this.props.stagedPublisher?.advertisingEnabled && !hasBundles) {
      const optionsMissingInventoryBundles: DialogModalOptions = {
        visible: true,
        isBodyCentered: true,
        title: (
          <FormattedMessage
            id="missing-inventory-bundles"
            defaultMessage="Missing inventory bundles"
            description="Title for an alert."
          />
        ),
        body: (
          <>
            <FormattedMessage
              id="account-no-bundles"
              description="Message displayed when publisher has ads, but no inventory bundles"
              defaultMessage="This account has ads enabled and no inventory bundles added."
            />
            {this.canAddDefaultBundles() ? (
              <FormattedMessage
                id="add-default-bundles"
                description="Message for adding default inventory bundles on save"
                defaultMessage="Continue saving and add default bundles?"
              />
            ) : (
              <FormattedMessage
                id="add-bundles-before-continuing"
                description="Message for manually adding bundles on save"
                defaultMessage="Please add bundles before proceeding."
              />
            )}
          </>
        ),
        okText: getMessageFromId('confirm-button-modal'),
        okButtonProps: { disabled: !this.canAddDefaultBundles() },
        onConfirm: async () => {
          this.setAddDefaultInventoryBundles();
          await this.savePublisherSettings();
        },
        cancelText: getMessageFromId('cancel-button-modal'),
        onCancel: () => {
          this.setState({ isSaving: false });
        },
      };

      this.props.showModal(ModalType.DIALOG, 'SuperAdminTabSave', optionsMissingInventoryBundles);
      return;
    }

    // The below checks that the tier is switched between any tier and 'Show', while there are available stories
    const isPublisherTierInvalid = await this.isPublisherTierInvalid();
    if (isPublisherTierInvalid) {
      this.renderPublisherTierChangeForbiddenModal();
      return;
    }

    await this.savePublisherSettings();
  };

  private async savePublisherSettings() {
    try {
      await this.saveAndUploadMediaSettings();
      await this.savePublisherInventory();
      this.updateSubmitButton();
    } catch (err) {
      this.resetSubmitButton();
      // Throw this error so it is capture by the toast error HOC
      throw err;
    }
  }

  private async saveAndUploadMediaSettings() {
    const businessProfileId = this.props.publisher.businessProfileId;
    await this.props
      .saveAndUploadMediaSettings(this.props.publisherId)
      .then((publisherData: EditablePublisherProperties) => {
        // Formats the staged data into the structure graphql server expects for this mutation.
        const updatePublisherInputFields = formatStagedSuperAdminSettingsData(
          publisherData,
          this.props.publisherId,
          businessProfileId
        );

        return this.props.updatePublisher({
          variables: { updatedFieldsInput: updatePublisherInputFields, isSuperAdmin: this.props.isSuperAdmin },
        });
      })
      // Pass updated fields as input to mutation to handle updating publisher details
      .then((res: UpdatePublisherResponse) => {
        const data: UpdatePublisherResponseDetails = res.data.updatePublisher.publishers;
        // Update publisher redux store with new properties
        this.props.updatePublisherProperties(data);
      });
  }

  private async savePublisherInventory() {
    if (this.state.hasInventoryChanges) {
      await this.props.saveInventoryData(this.props.inventoryItem.id, this.state.bundles).then(() => {
        this.props
          .getPublisherInventoryItem({
            organizationId: this.props.publisher.orgId,
            businessProfileId: this.props.publisher.businessProfileId,
          })
          .then((res: any) =>
            this.props.getInventoryBundleItemMappings({ publisherId: res.payload.inventoryItems[0].id })
          );
      });
    }
  }

  private updateSubmitButton() {
    this.setState({
      hasMadeChanges: false,
      hasInventoryChanges: false,
      isSaving: false,
    });
  }

  private resetSubmitButton() {
    this.setState({
      hasMadeChanges: true,
      isSaving: false,
    });
  }

  wrapWithTooltip = ({
    overlayDataTest,
    tooltipId,
    tooltipText,
    tooltipPosition,
    childElement,
    contentClassName,
  }: TooltipWrapParams) => {
    return (
      <div className={style.tooltipContainer}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <SDSTooltip placement={tooltipPosition} id={tooltipId} title={tooltipText} data-test={overlayDataTest}>
          <span className={classNames(style.tooltipContent, contentClassName)}>{childElement}</span>
        </SDSTooltip>
      </div>
    );
  };

  renderUnableToPublishMessage() {
    const missingPublisherProperties = this.getMissingMandatoryPublisherProperties();
    const missingManagerProperties = this.getMissingMandatoryManagerProperties();

    return (
      <FormattedMessage
        id="superadmin.missingProperties.message"
        description="Message displayed when some of the required properties are missing"
        defaultMessage="Missing some of the mandatory properties.{publisherProperties}{managerProperties}"
        values={{
          publisherProperties:
            missingPublisherProperties.length === 0 ? null : (
              <>
                <br />
                <b>
                  <FormattedMessage
                    id="superadmin.missingProperties.publisher"
                    description="Missing publisher properties description"
                    defaultMessage="Publisher Settings: "
                  />
                </b>
                {missingPublisherProperties.map((property, index) => (
                  <span key={property} data-test={`superadmin.missingProperties.publisher.${property}`}>
                    {index > 0 ? ', ' : ''}
                    {REQUIRED_PROPERTIES_TRANSLATIONS[property]}
                  </span>
                ))}
              </>
            ),
          managerProperties:
            missingManagerProperties.length === 0 ? null : (
              <>
                <br />
                <b>
                  <FormattedMessage
                    id="superadmin.missingProperties.manager"
                    description="Missing manager properties description"
                    defaultMessage="Manager Settings: "
                  />
                </b>
                {missingManagerProperties.map((property, index) => (
                  <span key={property} data-test={`superadmin.missingProperties.manager.${property}`}>
                    {index > 0 ? ', ' : ''}
                    {REQUIRED_PROPERTIES_TRANSLATIONS[property]}
                  </span>
                ))}
              </>
            ),
        }}
      />
    );
  }

  wrapWithUnableToPublishTooltip = (childElement: ReactNode) => {
    return this.wrapWithTooltip({
      overlayDataTest: 'superadmin.missingProperties.tooltip',
      tooltipId: 'settings-publisher-publishingEnabled',
      tooltipText: this.renderUnableToPublishMessage(),
      tooltipPosition: TooltipPosition.TOP,
      childElement,
    });
  };

  allowlistToAccessListObjects = (pickedCountryCodes: PickedCountryCodes): ContentAccessLists => {
    return Object.keys(pickedCountryCodes).reduce((acc, accKey) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[accKey] = { isAllowlist: true, list: pickedCountryCodes[accKey] };
      return acc;
    }, {} as ContentAccessLists);
  };

  ifGlobalChangeToAllCountryList = (countryLists: ContentAccessLists) => {
    const altCountryList = countryLists;
    Object.keys(countryLists).forEach(key => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (countryLists[key].list === 'Global') {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        altCountryList[key].isAllowlist = true;
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        altCountryList[key].list = allCountries;
      }
    });
    return altCountryList;
  };

  handleCountryOnChange: SettingChangeHandler = _.memoize(
    (key: PublisherKey) => (event: { pickedCountryCodes: any }) => {
      const countryLists = this.allowlistToAccessListObjects(event.pickedCountryCodes);
      // If every country is selected the country picker will set the list to 'Global' which breaks the
      // UI. Instead we want to replace Global with the list of all countries on change.
      const countryListsToBlocklist = this.ifGlobalChangeToAllCountryList(countryLists);
      return this.handleOnChange(key)(countryListsToBlocklist);
    }
  );

  hasChanges() {
    return this.state.hasMadeChanges;
  }

  formatCountryCodes = (contentAccessLists: ContentAccessLists): { [key in CountryCodeCategory]: string[] } => {
    const formattedContentAccessLists = formatContentAccessLists(contentAccessLists);
    return (Object.keys(formattedContentAccessLists) as CountryCodeCategory[]).reduce(
      (acc: { [key in CountryCodeCategory]: string[] }, accKey: CountryCodeCategory) => {
        const formatted = formattedContentAccessLists[accKey];
        if (formatted) {
          acc[accKey] = formatted.list;
        }
        return acc;
      },
      {} as { [key in CountryCodeCategory]: string[] }
    );
  };

  deselectSensitiveCountries = () => {
    const oldCountryCodesByCategory = this.formatCountryCodes(this.getPublisherValue('contentAccessLists', []));
    const pickedCountryCodes = (Object.keys(oldCountryCodesByCategory) as CountryCodeCategory[]).reduce(
      (acc, category: CountryCodeCategory) => {
        const categoryCountryCodes = oldCountryCodesByCategory[category];
        const nonSensitiveCountries = Array.isArray(categoryCountryCodes)
          ? categoryCountryCodes.filter(countryCode => !isSensitiveCountry(countryCode))
          : getNonSensitiveCountryCodes();
        return {
          ...acc,
          [category]: nonSensitiveCountries,
        };
      },
      {}
    );
    this.handleCountryOnChange('contentAccessLists')({ pickedCountryCodes });
  };

  isEditorialApprovalDisabled(): boolean {
    const { stagedPublisher: data } = this.props;
    return data?.editorialApproval === EditorialStatus.APPROVED && data.publishingEnabled;
  }

  isPublishingSwitchDisabled(): boolean {
    const { stagedPublisher: data } = this.props;
    // The switch should be enabled if the publishing is already enabled.
    return (
      !data?.publishingEnabled &&
      (this.getMissingMandatoryPublisherProperties().length > 0 ||
        this.getMissingMandatoryManagerProperties().length > 0)
    );
  }

  getMissingMandatoryPublisherProperties(): Array<RequiredPropertyKey> {
    const { stagedPublisher: data } = this.props;
    const missingProperties = MANDATORY_PUBLISHER_PROPERTIES.filter(
      property => !is.existy(data?.[property]) || is.empty(data?.[property])
    );
    if (!data?.tags || Object.keys(data.tags).length === 0) {
      missingProperties.push('tags');
    }
    return missingProperties;
  }

  getMissingMandatoryManagerProperties(): Array<RequiredPropertyKey> {
    const { stagedPublisher: data } = this.props;
    const missingProperties = MANDATORY_MANAGER_PROPERTIES.filter(
      property => !is.existy(data?.[property]) || is.empty(data?.[property])
    );
    if (data?.editorialApproval !== EditorialStatus.APPROVED) {
      missingProperties.push('editorialApproval');
    }
    if (
      !this.getPublisherFeatureValue(PublisherFeature.EVERGREEN) &&
      !this.getPublisherFeatureValue(PublisherFeature.TIMELY)
    ) {
      missingProperties.push('contentLifespan');
    }
    return missingProperties;
  }

  renderPublisherTierChangeForbiddenModal() {
    const options: AlertOptions = {
      body: (
        <>
          <FormattedMessage
            id="available-stories-title"
            defaultMessage="There are available Stories"
            description="Title for a available stories alert."
          />
          <br />
          <br />
          <FormattedMessage
            id="available-stories-body"
            defaultMessage="Updating the Publisher Tier to {tier} while there are available stories may cause errors. Please, make all stories unavailable, before trying to update this field."
            description="Body for a available stories alert."
            values={{ tier: this.props.stagedPublisher?.tier }}
          />
        </>
      ),
      onConfirm: () => {
        this.setState({ isSaving: false });
      },
    };

    this.props.showModal(ModalType.ALERT, 'SuperAdminTabChangeForbiddenModal', options);
  }

  renderOptions(propertyName: PublisherKey, defaultValue: any, disabled: boolean = false) {
    const { title, buttons } = BUTTONS_DEFINITION[propertyName];
    return (
      <PublisherDetailOption
        title={title}
        selectedKey={this.getPublisherValue(propertyName, defaultValue)}
        buttons={buttons}
        onKeySelected={this.handleOnChange(propertyName)}
        data-test={`superAdmin.settings.detail-option.${propertyName}`}
        disabled={disabled}
      />
    );
  }

  renderPublisherFeatureOptions(propertyName: PublisherFeature) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ title: ... Remove this comment to see the full error message
    const { title, buttons, toolTipText } = FEATURES_BUTTONS_DEFINITION[propertyName];
    return (
      <PublisherDetailOption
        title={title}
        toolTipText={toolTipText}
        selectedKey={this.getPublisherFeatureValue(propertyName)}
        buttons={buttons}
        onKeySelected={this.handlePublisherFeatureOnChange([propertyName])}
        data-test={`superAdmin.settings.detail-option.${propertyName}`}
      />
    );
  }

  handleContentLifeSpanChange = (key: ContentLifeSpanEnum) => {
    switch (key) {
      case ContentLifeSpan.EVERGREEN: {
        this.handlePublisherFeatureOnChange([PublisherFeature.EVERGREEN, PublisherFeature.TIMELY])([true, false]);
        break;
      }
      case ContentLifeSpan.TIMELY: {
        this.handlePublisherFeatureOnChange([PublisherFeature.EVERGREEN, PublisherFeature.TIMELY])([false, true]);
        break;
      }
      default:
        break;
    }
  };

  getContentLifeSpanValue = () => {
    if (this.getPublisherFeatureValue(PublisherFeature.TIMELY) === true) {
      return ContentLifeSpan.TIMELY;
    }

    if (this.getPublisherFeatureValue(PublisherFeature.EVERGREEN) === true) {
      return ContentLifeSpan.EVERGREEN;
    }

    // if a value is not set, we present nothing in the dropdown
    return undefined;
  };

  renderContentLifeSpan() {
    const currentContentLifeSpanValue = this.getContentLifeSpanValue();
    const { stagedPublisher: data } = this.props;
    const isEditorialStatusApproved = data?.editorialApproval === EditorialStatus.APPROVED;
    return (
      <div className={classNames(style.inputContainer)}>
        <SDSLabel
          labelTitle={
            <FormattedMessage
              id="superadmin.content-lifespan-settings-mandatory"
              defaultMessage="Content Lifespan{mandatoryIndicator}"
              description="Content Lifespan setting (mandatory)"
              values={{ mandatoryIndicator: isEditorialStatusApproved ? '*' : '' }} // field is only mandatory if editorial status has been approved
            />
          }
        >
          <SDSDropdown
            disableClear
            dropdownWidthMatchContent
            onChange={this.handleContentLifeSpanChange}
            value={currentContentLifeSpanValue}
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
          >
            {createSDSDropdownOptions(getContentLifeSpanChoices())}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  }

  renderDropdown(propertyName: PublisherKey, defaultValue: any, disabled: boolean = false) {
    const { title, buttons } = DROPDOWNS_DEFINITION[propertyName];
    if (buttons === null || buttons === undefined) {
      return null;
    }
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel labelTitle={title} data-test={`superAdmin.settings.detail-option.${propertyName}`}>
          <SDSDropdown
            disableClear
            dropdownWidthMatchContent
            onChange={this.handleDropdownOnChange(propertyName)}
            value={this.getPublisherValue(propertyName, defaultValue)}
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
            disabled={disabled}
          >
            {createSDSDropdownOptions(buttons)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  }

  renderTextInput(propertyName: PublisherKey, defaultValue: any, textValidationFn?: (a: string) => boolean) {
    const { title } = TEXT_INPUT_DEFINITION[propertyName];
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSInput
          data-test={`superAdmin.settings.detail-option.${propertyName}`}
          labelTitle={title}
          value={this.getPublisherValue(propertyName, defaultValue)}
          onChange={this.handleInputOnChange(propertyName)}
          maxLength={80}
          validateInput={textValidationFn}
        />
      </div>
    );
  }

  renderTextBox(propertyName: PublisherKey, defaultValue: any) {
    const { title } = TEXT_INPUT_DEFINITION[propertyName];
    return (
      <div className={classNames(style.inputContainer, style.textAreaInputContainer)}>
        <SDSTextArea
          labelTitle={title}
          data-test={`superAdmin.settings.detail-option.${propertyName}`}
          placeholder=""
          onChange={this.handleInputOnChange(propertyName)}
          value={this.getPublisherValue(propertyName, defaultValue)}
        />
      </div>
    );
  }

  renderTagInput(propertyName: PublisherKey, defaultValue: any) {
    const { title, validationType } = TEXT_INPUT_DEFINITION[propertyName];
    return (
      <div className={classNames(style.inputContainer, style.tagInputContainer, style.formInputContainer)}>
        <SDSLabel data-test={`superAdmin.settings.detail-option.${propertyName}`} labelTitle={title}>
          <MultiTagInput
            tags={this.getPublisherValue(propertyName, defaultValue)}
            onChange={this.handleOnChange(propertyName)}
            validationType={validationType}
          />
        </SDSLabel>
      </div>
    );
  }

  renderEditorialReviewDropdown(propertyName: PublisherKey, defaultValue: any) {
    const { stagedPublisher: data } = this.props;
    const editorialStatus = data?.editorialApproval;
    const isEnabled = editorialStatus === EditorialStatus.APPROVED || editorialStatus === EditorialStatus.REJECTED;
    const buttons = editorialStatus ? EDITORIAL_RATIONALE_DEFINITION[editorialStatus] || [] : [];

    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel
          labelTitle="Editorial Review Rationale"
          data-test={`superAdmin.settings.detail-option.${propertyName}`}
        >
          <SDSDropdown
            disableClear
            dropdownWidthMatchContent
            onChange={this.handleDropdownOnChange(propertyName)}
            value={this.getPublisherValue(propertyName, defaultValue)}
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
            disabled={!isEnabled}
          >
            {createSDSDropdownOptions(buttons)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  }

  renderInlineNumberInput(
    propertyName: PublisherKey,
    defaultValue: any,
    inputClassName: string | undefined | null = null,
    numValidationFn?: (a: number) => boolean,
    min?: number
  ) {
    const { title, content } = INLINE_NUMBER_INPUT_DEFINITION[propertyName];
    const field = (
      <div className={style.inlineNumberInput}>
        <SDSInput
          value={this.getPublisherValue(propertyName, defaultValue) || ''}
          type={InputType.NUMBER}
          onChange={this.handleNumberInputOnChange(propertyName)}
          data-test="onboarding.settingsView.inlineNumber.input"
          validateInput={numValidationFn}
          min={min}
        />
      </div>
    );
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer, inputClassName)}>
        <SDSLabel labelTitle={title} data-test={`superAdmin.settings.detail-option.${propertyName}`}>
          {React.cloneElement(content, { values: { field } })}
        </SDSLabel>
      </div>
    );
  }

  renderImageInput(propertyName: PublisherKey, fallbackPropertyName: PublisherKey, defaultValue: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'uploadType' does not exist on type '{ ti... Remove this comment to see the full error message
    const { title, uploadType } = IMAGE_INPUT_DEFINITION[propertyName];
    const iconId = this.props.stagedPublisher ? this.props.stagedPublisher[fallbackPropertyName] : '';
    const defaultIdValue = iconId && typeof iconId === 'string' ? createAssetUrl(iconId) : defaultValue;

    return (
      <PublisherDetailImageRow
        title={title}
        onChange={this.handleOnChange(propertyName)}
        key={propertyName}
        uploadType={uploadType}
        defaultValue={this.getPublisherValue(propertyName, defaultIdValue)}
        data-test={`superAdmin.settings.detail-option.${propertyName}`}
      />
    );
  }

  renderCountrySelection(propertyName: PublisherKey, defaultValue: any) {
    const propertyValue = this.props.stagedPublisher?.[propertyName];
    if (!propertyValue || typeof propertyValue !== 'object' || Object.keys(propertyValue).length === 0) {
      return null;
    }
    const { title } = COUNTRY_INPUT_DEFINITION[propertyName];
    return (
      <div className={classNames(style.inputContainer, style.countryPickerWrapper)}>
        <SDSLabel data-test={`superAdmin.settings.detail-option.${propertyName}`} labelTitle={title}>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'disabled' is missing in type '{ classNam... Remove this comment to see the full error message */}
          <CountryPicker
            dropdownClassName={style.countryPickerDropdown}
            pickedCountryCodes={this.formatCountryCodes(this.getPublisherValue(propertyName, defaultValue))}
            width={350}
            numCountries={3}
            onChange={this.handleCountryOnChange(propertyName)}
            onToggle={this.handleCountryOnChange(propertyName)}
            countryCodeCategories={[
              CountryCodeCategory.DEFAULT,
              CountryCodeCategory.DISCOVER_FEED,
              CountryCodeCategory.SNAPCODE,
              CountryCodeCategory.SEARCH,
              CountryCodeCategory.SHARE,
            ]}
          />
          {this.renderDeslectSensitiveCountriesButton()}
        </SDSLabel>
      </div>
    );
  }

  renderDeslectSensitiveCountriesButton() {
    return this.wrapWithTooltip({
      tooltipId: 'deselect-sensitive-countries',
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(acc: string, name: string) => E... Remove this comment to see the full error message
      tooltipText: getSensitiveCountryNames().reduce((acc, name) => {
        return (
          <>
            {acc}
            <br />
            {name}
          </>
        );
      }),
      tooltipPosition: TooltipPosition.RIGHT, // Place on the right as the tooltip is long and may cover the button.
      overlayDataTest: 'data-test-deslect-sensitive-countries',
      contentClassName: style.centerDeselectButton,
      childElement: (
        <div className={style.deleteButtonContainer}>
          <SDSButton type={ButtonType.WHITE} onClick={this.deselectSensitiveCountries}>
            <FormattedMessage
              id="super-admin-deslect-sensitive-countries"
              description="This message is displayed on super admin settings page"
              defaultMessage="Deselect Sensitive Countries"
            />
          </SDSButton>
        </div>
      ),
    });
  }

  renderMultiSelectInput(propertyName: PublisherKey, defaultValue: any) {
    const { title, buttons } = MULTI_SELECT_INPUT_DEFINITION[propertyName];
    const tagOptions: TagOptions = {
      [MULTI_SELECT_DEFAULT_KEY]: {
        defaultTags: this.getPublisherValue(propertyName, defaultValue),
        tagMapping: buttons,
      },
    };
    return (
      <div className={classNames(style.inputContainer, style.tagInputContainer)}>
        <SDSLabel data-test={`superAdmin.settings.detail-option.${propertyName}`} labelTitle={title}>
          <MultiSelectTagFilter
            tagOptions={tagOptions}
            tagTypeOrder={[MULTI_SELECT_DEFAULT_KEY]}
            onChange={this.handleMultiSelectOnChange(propertyName)}
          />
        </SDSLabel>
      </div>
    );
  }

  renderDisabledTextInput(propertyName: PublisherKey, defaultValue: any) {
    const { title } = TEXT_INPUT_DEFINITION[propertyName];
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSInput
          data-test={`superAdmin.settings.detail-option.${propertyName}`}
          labelTitle={title}
          disabled
          value={this.getPublisherValue(propertyName, defaultValue)}
        />
      </div>
    );
  }

  renderPublishingEnabledOption() {
    const disabled = this.isPublishingSwitchDisabled();
    const publishingEnabledOption = this.renderOptions('publishingEnabled', true, disabled);
    if (!disabled) {
      return publishingEnabledOption;
    }
    return this.wrapWithUnableToPublishTooltip(publishingEnabledOption);
  }

  handleInventoryBundleChange = (bundles: { [tag: string]: string[] } | {}) => {
    return this.setState({ hasMadeChanges: true, hasInventoryChanges: true, bundles });
  };

  renderInventoryBundleList = () => {
    return (
      <InventoryDropdown
        activeInventoryBundlesByIdMap={this.props.activeInventoryBundlesByIdMap}
        inventoryBundleItemMappingsByBundleIdMap={this.props.inventoryBundleItemMappingsByBundleIdMap}
        isLoading={this.props.isInventoryDataLoading}
        onChange={this.handleInventoryBundleChange}
        tagTypeOrder={[INVENTORY_BUNDLE_TYPE_NEW, INVENTORY_BUNDLE_TYPE_EXISTING]}
        dataTest={'superAdmin.settings.detail-option.inventory.v2.input'}
      />
    );
  };

  handleOnManagerChange = (manager: Manager) => {
    this.props.updateProperties(this.props.publisherId, {
      managerEmail: manager.email,
      managerName: manager.name,
    });
    this.setState({ hasMadeChanges: true });
  };

  renderManagerInput = () => {
    const title = TRANSLATIONS.managerEmailMandatory;
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel labelTitle={title} data-test="superAdmin.settings.detail-option.managerEmail.label">
          <ManagerDropdown
            disableClear
            dropdownWidthMatchContent
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
            managerEmail={this.getPublisherValue('managerEmail', '')}
            onManagerChange={this.handleOnManagerChange}
            data-test="superAdmin.settings.detail-option.managerEmail.input"
          />
        </SDSLabel>
      </div>
    );
  };

  render() {
    const isLoading = this.isLoadingCurrentPublisher();

    return (
      <div>
        <div className={style.settingsButton}>
          <SaveSettingsButton isSaving={this.state.isSaving} isActive={this.hasChanges()} onClick={this.handleOnSave} />
        </div>

        <div className={style.mandatoryExplanationMsg}>
          <FormattedMessage
            id="super-admin-mandatory-fields-explanation"
            description="Explains that fields marked with '*' are mandatory to enable publishing"
            defaultMessage="Fields marked with '*' are mandatory to enable publishing"
          />
        </div>

        <SDSPanel header={CARD_HEADERS.publisher} isLoading={isLoading}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              {this.renderPublishingEnabledOption()}
              {this.renderDropdown(
                'editorialApproval',
                EditorialStatus.TO_BE_REVIEWED,
                this.isEditorialApprovalDisabled()
              )}
              {this.renderDropdown('publisherType', GeneratedPublisherType.PUBLISHER)}
              {this.renderDropdown('tier', GeneratedPublisherType.PUBLISHER)}
              {this.stagedIsShow() ? this.renderPublisherFeatureOptions(PublisherFeature.EXCLUSIVE_SHOW) : null}
              {this.stagedIsShow() && this.getPublisherFeatureValue(PublisherFeature.EXCLUSIVE_SHOW)
                ? this.renderPublisherFeatureOptions(PublisherFeature.ORIGINAL_SHOW)
                : null}
              {this.renderTextInput('websiteUrl', '', validateUrlText)}
              {this.renderDropdown('audioClassification', this.props.stagedPublisher?.audioClassification)}
              {this.renderOptions('isComScoreIntegrationEnabled', false)}
              {this.renderOptions('searchable', false)}
              {this.renderOptions('rollingNewsEnabled', false)}
            </SDSPanel.Column>
            <SDSPanel.Column>
              {this.renderOptions('isEmployeeOnly', false)}
              {this.renderEditorialReviewDropdown('editorialRationale', '')}
              {this.renderOptions('test', PublisherType.NORMAL)}
              {this.renderOptions('moderationLevel', ModerationLevel.STANDARD)}
              {this.renderDropdown('primaryLanguage', 'en')}
              {this.renderContentLifeSpan()}
              {this.renderPublisherFeatureOptions(PublisherFeature.SUBSCRIBER_ONLY)}
              {this.renderPublisherFeatureOptions(PublisherFeature.MADE_FOR_US)}
              {this.renderManagerInput()}
            </SDSPanel.Column>
          </SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={CARD_HEADERS.countries} isLoading={isLoading}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              {this.renderTagInput('geofenceIds', [])}
              {this.renderMultiSelectInput('allowlistLanguage', [])}
              {this.renderDropdown('homeCountry', '')}
            </SDSPanel.Column>
            <SDSPanel.Column>
              {this.renderCountrySelection('contentAccessLists', [])}
              {this.renderMultiSelectInput('localContent', [])}
            </SDSPanel.Column>
          </SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={CARD_HEADERS.publisherNames} isLoading={isLoading}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              {this.renderTextInput('formalName', '')}
              {this.renderTagInput('nicknames', [])}
              <div className={classNames(style.inputContainer, style.formInputContainer)}>
                <OrganisationInput publisherId={this.props.publisherId} />
              </div>
              {this.renderDisabledTextInput('id', '')}
            </SDSPanel.Column>
            <SDSPanel.Column>
              {this.renderTextInput('mutablePublisherName', '')}
              {this.renderTextInput('urlSafeFormalName', '')}
              {this.renderDisabledTextInput('orgId', '')}
              {this.renderDisabledTextInput('businessProfileId', '')}
            </SDSPanel.Column>
          </SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={CARD_HEADERS.storyDefaults} isLoading={isLoading}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              {this.renderOptions('subscribable', false)}
              {this.renderOptions('defaultShareOption', ShareOption.NO_SHARE)}
              {this.renderOptions('videoModeEnabled', false)}
              {this.props.isRemoteWebLimitEnabled
                ? this.renderInlineNumberInput('webviewLimit', 0, null, validateWebviewLimit, 0)
                : null}
            </SDSPanel.Column>
            <SDSPanel.Column>
              {this.renderOptions('ageGate', AgeGate.UNRESTRICTED)}
              {this.renderOptions('topsnapLimit', 10)}
            </SDSPanel.Column>
          </SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={CARD_HEADERS.adSettings} isLoading={isLoading}>
          <SDSPanel.Row>
            <SDSPanel.Column>
              {this.renderOptions('advertisingEnabled', false)}
              {this.stagedIsShow() && this.adsEnabled() ? this.renderOptions('adSetting', AdSetting.SKIPPABLE) : null}
              {this.adsEnabled() ? this.renderPublisherFeatureOptions(PublisherFeature.FIXED_PAYMENTS) : null}
              {this.simpleAdsEnabled() && !this.props.isSingleAssetStoryEditorEnabled
                ? this.renderDropdown('defaultAdSlots', [])
                : null}
              {this.advancedAdsEnabled()
                ? this.renderInlineNumberInput('advancedAdsMinSlots', DEFAULT_ADS_MIN_SLOTS)
                : null}
              {this.advancedAdsEnabled()
                ? this.renderInlineNumberInput('advancedAdsMaxSlots', DEFAULT_ADS_MAX_SLOTS)
                : null}
            </SDSPanel.Column>
            <SDSPanel.Column>
              {this.adsEnabled() ? this.renderInventoryBundleList() : null}
              {this.adsEnabled() && (!this.props.isSingleAssetStoryEditorEnabled || this.advancedAdsEnabled())
                ? this.renderOptions('advancedAdsEnabled', false)
                : null}
              {this.advancedAdsEnabled()
                ? this.renderInlineNumberInput('advancedAdsMinSpacing', DEFAULT_ADS_MIN_SPACING)
                : null}
              {this.advancedAdsEnabled()
                ? this.renderInlineNumberInput(
                    'advancedAdsLatestFirstSlot',
                    DEFAULT_ADS_LATEST_FIRST_SLOT,
                    style.formInputWithExtraMargins
                  )
                : null}
              {this.adsEnabled() ? this.renderOptions('adsPreRollEnabled', false) : null}
              {this.simpleAdsEnabled() ? this.renderOptions('dynamicAdsEnabled', false) : null}
              {this.simpleAdsEnabled() &&
              this.props.isSingleAssetStoryEditorEnabled &&
              this.props.isOptionalAdsOverrideEnabled
                ? this.renderDropdown('defaultAdSlotsMillis', [])
                : null}
            </SDSPanel.Column>
          </SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={CARD_HEADERS.icons} isLoading={isLoading}>
          <SDSPanel.Row>
            <SDSPanel.Column>{this.renderImageInput('horizontalIconBlob', 'horizontalIconId', '')}</SDSPanel.Column>
          </SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={CARD_HEADERS.css} isLoading={isLoading}>
          <SDSPanel.Row>{this.renderTextBox('articleCSS', '')}</SDSPanel.Row>
        </SDSPanel>

        <SDSPanel header={CARD_HEADERS.archive} isLoading={isLoading}>
          <SDSPanel.Row>{getMessageFromId('archive-publisher-description')}</SDSPanel.Row>
          <SDSPanel.Row>
            <div className={style.archiveButtonContainer}>
              <ArchiveButton />
            </div>
          </SDSPanel.Row>
        </SDSPanel>
      </div>
    );
  }
}

export { SuperAdminTab };
export default withUpdatePublisherMutation(intlConnect(mapStateToProps, mapDispatchToProps)(SuperAdminTab));
