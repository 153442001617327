import * as React from 'react';

import * as previewsSelectors from 'state/previews/selectors/previewsSelectors';

import { CrossOrigin } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import style from './GraphTooltip.scss';

/**
 * This adds the standard preview of a topsnap to edition-based graphs (ie graphs where the x-axis implies
 * the snap number in an edition)
 */

const mapStateToProps = (state: State, ownProps: any) => {
  const snapPreviewFrame = ownProps.d && ownProps.snapPreviewFrames && ownProps.snapPreviewFrames[ownProps.d.index];
  return {
    snapPreview: snapPreviewFrame && previewsSelectors.getSnapPreviewById(state)(snapPreviewFrame.snapId),
  };
};

type SnapPreviewProps = {
  d?: any;
  snapPreviewFrames?: any[];
  snapPreview?: string;
};

// eslint-disable-next-line react/prefer-stateless-function
export class SnapPreview extends React.Component<SnapPreviewProps> {
  render() {
    if (!this.props.snapPreview) {
      return null;
    }
    return (
      <div className={style.imageTooltipPreviewRow}>
        <img src={this.props.snapPreview} crossOrigin={CrossOrigin.USE_CREDENTIALS} alt="snapPreview" />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(SnapPreview);
