import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveCreator } from 'state/user/selectors/userSelectors';

import { useGetScheduledSpotlightPosts } from 'gql/hooks/useGetScheduledSpotlightPosts';
import { useGetSpotlightPosts } from 'gql/hooks/useGetSpotlightPosts';
import { ScheduledStoryStatus } from 'gql/queries/spotlight/storyDraftingSnaps';

import TopSpotlightAnalytics from 'views/analytics/components/TopSpotlightAnalytics/TopSpotlightAnalytics';
import SDSCard from 'views/common/components/SDSCard/SDSCard';

import style from './SpotlightHomepage.scss';

type Props = {
  spotlightStoryStatus: string;
};

export default function SpotlightHomepage({ spotlightStoryStatus }: Props) {
  const activePublisherBusinessProfileId = useSelector(getActiveCreator)?.businessProfileId || '';
  const activePublisherHostUserId = useSelector(getActiveCreator)?.hostUserId || '';

  const { data, loading, fetchMore } = useGetSpotlightPosts(activePublisherBusinessProfileId, '');
  const scheduledPostsQuery = useGetScheduledSpotlightPosts(
    activePublisherBusinessProfileId,
    activePublisherHostUserId,
    ''
  );
  const showScheduled = spotlightStoryStatus === ScheduledStoryStatus.SCHEDULED.toString();
  const scheduledSnaps = scheduledPostsQuery.data?.storyDraftingSnaps;
  const scheduledIsLoading = scheduledPostsQuery.loading;
  const scheduledFetchMore = scheduledPostsQuery.fetchMore;

  return (
    <div className={style.cardWrapper}>
      <SDSCard>
        <TopSpotlightAnalytics
          fetchMore={fetchMore}
          scheduledFetchMore={scheduledFetchMore}
          isLoading={showScheduled ? scheduledIsLoading : loading}
          spotlightAnalytics={data?.spotlightAnalytics.spotlightAnalyticsForPage}
          scheduledSpotlights={scheduledSnaps}
          showScheduled={spotlightStoryStatus === ScheduledStoryStatus.SCHEDULED.toString()}
        />
      </SDSCard>
    </div>
  );
}
