import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SpotlightRequirements } from 'gql/types/spotlightRequirementsEnum';

import style from './SpotlightRequirements.scss';

const SPOTLIGHT_BEST_PRACTICES_LINK = 'https://help.snapchat.com/hc/en-gb/articles/7012287256596-Spotlight-Guidelines';
const WATCH_SPOTLIGHT_LINK = 'https://story.snapchat.com/spotlight';

export const SPOTLIGHT_REQUIREMENTS = {
  [SpotlightRequirements.LENGTH]: {
    id: 'spotlight-requirement-length',
    defaultMessage: 'Video must be between {boldText} long',
    description: 'Explains the spotlight requirement for the length of the video uploaded',
    boldText: '5 seconds and 5 minutes',
  },
  [SpotlightRequirements.SIZE]: {
    id: 'spotlight-requirement-size',
    defaultMessage: 'Video must be at least {boldText} and respect 9:16 aspect ratio',
    description: 'Explains the spotlight requirement for the dimentions of the video uploaded',
    boldText: '540x960px',
  },
  [SpotlightRequirements.SOUND]: {
    id: 'spotlight-requirement-sound',
    defaultMessage: 'Must be full frame, vertical video {boldText}',
    description: 'Explains the spotlight requirement for the sound of the video uploaded',
    boldText: 'with sound',
  },
  [SpotlightRequirements.ATTATCHMENTS]: {
    id: 'spotlight-requirement-attatchments',
    defaultMessage: 'Spotlight Snaps {boldText} or URL link outs',
    description: 'Explains the spotlight requirement for the attatchment of the video uploaded',
    boldText: 'do not support attatchments',
  },
  [SpotlightRequirements.RIGHTS]: {
    id: 'spotlight-requirement-rights',
    defaultMessage: 'You must have {boldText} posted on Spotlight',
    description: 'Explains the spotlight requirement for the copyright of the video uploaded',
    boldText: 'rights to all content',
  },
  [SpotlightRequirements.WATERMARK]: {
    id: 'spotlight-requirement-watermark',
    defaultMessage: '{boldText} are not eligible',
    description: 'Explains the spotlight requirement for watermark of the video uploaded',
    boldText: 'Watermarked videos',
  },
};

export default function SpotlightUploadRequirements() {
  const renderListItem = (value: SpotlightRequirements) => {
    const { id, defaultMessage, description, boldText } = SPOTLIGHT_REQUIREMENTS[value];
    return (
      <li data-test={`SpotlightUploadRequirement.listItem.${value}`} key={value}>
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          description={description}
          values={{
            boldText: <strong>{boldText}</strong>,
          }}
        />
      </li>
    );
  };
  return (
    <div className={style.wrapper}>
      <div className={style.title}>
        <FormattedMessage
          id="spotlight-requirements-title"
          defaultMessage="Spotlight Requirements"
          description="Title for the section that explains the spotlight requirements"
        />
      </div>
      <ul className={style.listItems}>
        {(Object.keys(SPOTLIGHT_REQUIREMENTS) as Array<SpotlightRequirements>).map(value => renderListItem(value))}
      </ul>
      <div className={style.subheading}>
        <FormattedMessage
          id="spotlight-requirements-subheading"
          defaultMessage="Need some inspiration?"
          description="Title for the section that gives inspiration to create spotlight"
        />
      </div>
      <div className={style.promptsWrapper}>
        <FormattedMessage
          id="spotlight-requirements-watch-prompt"
          defaultMessage="Watch some popular Spotlights {link}"
          description="Prompt to watch spotlight"
          values={{
            link: (
              <a href={WATCH_SPOTLIGHT_LINK} target="_blank">
                here
              </a>
            ),
          }}
        />
        <FormattedMessage
          id="spotlight-requirements-best-practices"
          defaultMessage="View Spotlight Best Practices {link}"
          description="Prompt to read the spotlight best practices"
          values={{
            link: (
              <a href={SPOTLIGHT_BEST_PRACTICES_LINK} target="_blank">
                here
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
}
