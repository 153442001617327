import DefaultHeader from 'views/common/components/Graph/DefaultHeader';
import QuestionResults from 'views/common/components/Graph/QuestionResults';
import SnapPreview from 'views/common/components/Graph/SnapPreview';

const TOOLTIP_EXTRA_COMPONENTS = {
  SnapPreview,
  QuestionResults,
  DefaultHeader,
};

// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
export const getExtraTooltipComponent = (componentName: any) => TOOLTIP_EXTRA_COMPONENTS[componentName];
