// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Grid } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import moment from 'moment-timezone';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"react-router"' has no exported member 'Co... Remove this comment to see the full error message
import type { ContextRouter } from 'react-router';

import * as analyticsActions from 'state/analytics/actions/analyticsActions';
import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';
import { userId } from 'state/user/selectors/userSelectors';

import type { AuthTypeEnum } from 'config/constants';
import { list, gridContent } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { GAUserActions, logGAEvent } from 'utils/gaUtils';
import { getRangeMessage } from 'utils/intlMessages/intlMessages';
import { withRouter } from 'utils/routerUtils';
import { getSpecifiedTime } from 'utils/time/analyticsTimeUtils';

import AnalyticsCalendarView from 'views/analytics/containers/AnalyticsCalendarView/AnalyticsCalendarView';
import AnalyticsSettingsButton from 'views/analytics/containers/AnalyticsSettingsButton/AnalyticsSettingsButton';
import CheetahAnalyticsStoriesView from 'views/analytics/containers/CheetahAnalyticsStoriesView/CheetahAnalyticsStoriesView';
import { getDatesFromSearchParams, updateDatesInSearchParams } from 'views/analytics/utils/searchParamsUtil';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';
import CalendarButton from 'views/homepage/containers/CalendarButton/CalendarButton';

import style from './AnalyticsOverviewView.scss';

import { AnalyticsStatsType } from 'types/analytics';
import type { CheetahAnalyticsStories } from 'types/analytics';
import { Claim } from 'types/permissions';
import type { ActivePublisher } from 'types/publishers';
import type { State } from 'types/rootState';
import type { Subscription } from 'types/subscriptions';

type StateProps = {
  publisherId: number;
  activePublisher: ActivePublisher;
  authToken: string;
  authType: AuthTypeEnum;
  userId: string;
  subscription: Subscription;
  isShowingNotificationMessage: boolean;
};

type DispatchProps = {
  fetchStoriesList: typeof analyticsActions.fetchStorySummaryListAndFirstSnapPreview;
  clearStoryList: typeof analyticsActions.clearStoryList;
  fetchEditionList: typeof analyticsActions.fetchEditionList;
  clearEditionList: typeof analyticsActions.clearEditionList;
};

type OwnState = {
  stories: CheetahAnalyticsStories;
  from: moment.Moment;
  to: moment.Moment;
  shouldShowListView: boolean;
};

type Props = StateProps & DispatchProps & ContextRouter;

const mapStateToProps = (state: State, ownProps: Props) => {
  return {
    authToken: getToken(state),
    authType: getAuthType(state),
    userId: userId(state),
    isShowingNotificationMessage: snapAdminSelectors.isShowingNotificationMessage(state),
  };
};

const mapDispatchToProps = {
  fetchStoriesList: analyticsActions.fetchStorySummaryListAndFirstSnapPreview,
  clearStoryList: analyticsActions.clearStoryList,
  fetchEditionList: analyticsActions.fetchEditionList,
  clearEditionList: analyticsActions.clearEditionList,
};

export class AnalyticsOverviewView extends React.PureComponent<Props, OwnState> {
  static title = (
    <FormattedMessage
      id="title-publisher-story-analytics-overview"
      description="Title for Publisher Story Analytics overview"
      defaultMessage="Story analytics"
    />
  );

  constructor(props: Props) {
    super(props);
    this.state = {
      stories: [],
      shouldShowListView: false,
      from: getDatesFromSearchParams(this.props.location).from || getSpecifiedTime(31, 'days'),
      to: getDatesFromSearchParams(this.props.location).to || getSpecifiedTime(0, 'days'),
    };
  }

  componentDidMount() {
    this.fetchStories(this.props, this.state.from, this.state.to);
    const searchParamFrom = getDatesFromSearchParams(this.props.location).from;
    const searchParamTo = getDatesFromSearchParams(this.props.location).to;
    if (!searchParamFrom || !searchParamTo) {
      updateDatesInSearchParams(this.props.history, this.state.from, this.state.to, true);
    }
  }

  onDateRangeUpdated = (fromMoment?: moment.Moment | null, toMoment?: moment.Moment | null) => {
    const from = fromMoment || moment();
    const to = toMoment || moment();

    this.fetchStories(this.props, from, to);

    this.setState({ from, to });

    updateDatesInSearchParams(this.props.history, from, to);
  };

  onViewToggleUpdated = () => {
    this.setState({ shouldShowListView: !this.state.shouldShowListView });
  };

  fetchStories(props: Props, from: moment.Moment, to: moment.Moment) {
    const publisherId = parseInt(props.publisherId, 10);
    const startDate = from.format('YYYY-MM-DD');
    const endDate = to.format('YYYY-MM-DD');

    // TODO: unify these endpoints?
    // fetch the new story summary analytics for the list view
    this.props.clearStoryList();

    this.props.fetchStoriesList({ endDate, publisherId, startDate, analyticsStatsType: AnalyticsStatsType.ALL });

    // fetch the old story analytics for the calendar view
    this.props.clearEditionList();

    this.props.fetchEditionList({ endDate, publisherId, startDate, analyticsStatsType: AnalyticsStatsType.ALL });
  }

  renderDateFilterButton = () => {
    const from = this.state.from ? this.state.from : moment();
    const to = this.state.to ? this.state.to : moment();

    const range = getRangeMessage(from, to, 'MM/DD');

    logGAEvent(GAUserActions.ANALYTICS, 'analytics-overview-date-picker', {
      publisherName: this.props.activePublisher?.name,
      noOfDays: from.diff(to, 'days'),
    });

    return (
      <CalendarButton
        onUpdate={this.onDateRangeUpdated}
        defaultText={range}
        from={from}
        to={to}
        shouldShowDropDownButton
      />
    );
  };

  renderToggle = () => {
    const isListSelected = this.state.shouldShowListView;
    return (
      <div className={style.listButtonContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={isListSelected ? gridContent : list}
          onClick={this.onViewToggleUpdated}
          data-test="analytics.overview.listToggle.button"
        />
      </div>
    );
  };

  renderHeaderButtons = () => {
    const from = this.state.from ? this.state.from : moment();
    const to = this.state.to ? this.state.to : moment();
    const classes = classNames(style.controlGroup, { [style.withNavBar]: this.props.isShowingNotificationMessage });

    return (
      <div className={classes}>
        {this.renderDateFilterButton()}
        {this.renderToggle()}
        <AnalyticsSettingsButton from={from} to={to} />
      </div>
    );
  };

  renderBody = () => {
    return this.state.shouldShowListView ? (
      <CheetahAnalyticsStoriesView stories={this.state.stories} {...this.props} />
    ) : (
      <AnalyticsCalendarView {...this.props} />
    );
  };

  render() {
    return (
      <ClaimGatedView requiredClaim={Claim.ANALYTICS_VIEWER}>
        <div className={style.container}>
          <Grid fluid className={style.root}>
            <div className={style.table}>
              {this.renderHeaderButtons()}
              <div className={style.bodyWrapper}>{this.renderBody()}</div>
            </div>
          </Grid>
        </div>
      </ClaimGatedView>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsOverviewView));
