import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getAuthType } from 'state/auth/selectors/authSelectors';
import { isSpotlightSchedulingEnabled } from 'state/features/selectors/featuresSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { getActiveCreatorBusinessProfileId, getUsername } from 'state/user/selectors/userSelectors';

import { makeSpotlightTermsOptions } from '../SpotlightTermsModal/SpotlightTermsModalOptions';
import SpotlightUploadRequirements from '../SpotlightUploadRequirements/SpotlightUploadRequirements';

import { AuthType, UploadPurpose } from 'config/constants';
import { ModalType } from 'utils/modalConfig';
import * as termUtils from 'utils/spotlightTerms/termUtils';

import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';
import AllowRemixCard from 'views/storySnapUploader/components/SnapUploaderMenuCards/AllowRemixCard/AllowRemixCard';
import DescriptionCard from 'views/storySnapUploader/components/SnapUploaderMenuCards/DescriptionCard/DescriptionCard';
import MediaCard from 'views/storySnapUploader/components/SnapUploaderMenuCards/MediaCard/MediaCard';
import SaveToProfileCard from 'views/storySnapUploader/components/SnapUploaderMenuCards/SaveToProfileCard/SaveToProfileCard';
import ScheduleCard from 'views/storySnapUploader/components/SnapUploaderMenuCards/ScheduleCard/ScheduleCard';
import SubmitBar from 'views/storySnapUploader/components/SnapUploaderMenuCards/SubmitBar/SubmitBar';
import StorySnapCardRenderer from 'views/storySnapUploader/components/StorySnapCards/StorySnapCardRenderer/StorySnapCardRenderer';
import SubmittedPostSection from 'views/storySnapUploader/components/SubmittedPostSection/SubmittedPostSection';
import { useUploaderHandlers } from 'views/storySnapUploader/hooks/useUploaderHandlers';
import { useUploaderState } from 'views/storySnapUploader/hooks/useUploaderState';

import style from './SpotlightUploaderView.scss';

import { StorySnapMediaWithPreview } from 'types/media';
import { UploaderState } from 'types/storySnapEditor';

export default function SpotlightUploaderView() {
  // enterStorySnapEditorHook requires loading the publisher, which will always get the business profile id
  const activePublisherBusinessProfileId = useSelector(getActiveCreatorBusinessProfileId) || '';
  const isSchedulingEnabled = useSelector(isSpotlightSchedulingEnabled);
  const loggedInUserName = useSelector(getUsername);
  const authType = useSelector(getAuthType);

  const dispatch = useDispatch();

  const {
    state: { setUploaderState, uploadState },
    utils: { isUploadNotReady, isSubmittedState },
  } = useUploaderState();
  const { handleOnPost, handleOnUpload } = useUploaderHandlers(UploadPurpose.SPOTLIGHT);
  const [mediaWithPreview, setMediaWithPreview] = useState<StorySnapMediaWithPreview | undefined>();
  const [saveToProfile, setSaveToProfile] = useState<boolean>(true);
  const [description, setDescription] = useState<string | undefined>();
  const [goLiveTsMs, setGoLiveTsMs] = useState<number | undefined>();
  const [isValidationFailed, setValidationFailed] = useState(false);
  const [disableRemix, setDisableRemix] = useState(false);

  const onPost = useCallback(() => {
    handleOnPost(saveToProfile, mediaWithPreview, description, goLiveTsMs, disableRemix, setUploaderState);
  }, [disableRemix, description, goLiveTsMs, handleOnPost, mediaWithPreview, saveToProfile, setUploaderState]);

  const onUpload = useCallback(
    (file: File) => {
      handleOnUpload(file, setUploaderState).then(snap => setMediaWithPreview(snap));
    },
    [handleOnUpload, setUploaderState]
  );
  const toggleSaveToProfile = useCallback(() => {
    setSaveToProfile(!saveToProfile);
  }, [saveToProfile]);

  const isReadOnly = () => {
    return isUploadNotReady() || isValidationFailed;
  };

  const handleClearMedia = useCallback(() => {
    setUploaderState(UploaderState.NOT_STARTED);
    setMediaWithPreview(undefined);
  }, [setUploaderState]);

  const handleClearEditor = useCallback(() => {
    // If posting failed then don't clear the media and description to let the user try again
    if (uploadState === UploaderState.SUBMITTED_ERROR) {
      setUploaderState(UploaderState.DONE);
      return;
    }
    handleClearMedia();
    setSaveToProfile(true);
    setDescription(undefined);
    setGoLiveTsMs(undefined);
    setDisableRemix(false);
  }, [handleClearMedia, setUploaderState, uploadState]);

  const acceptTermsAndPost = useCallback(() => {
    termUtils.acceptSpotlightTerms(loggedInUserName, activePublisherBusinessProfileId);
    onPost();
  }, [activePublisherBusinessProfileId, loggedInUserName, onPost]);

  const displaySpotlightTermsModal = useCallback(() => {
    const options = makeSpotlightTermsOptions(acceptTermsAndPost);
    return dispatch(showModal(ModalType.DIALOG, 'SpotlightTerms', options));
  }, [dispatch, acceptTermsAndPost]);

  const hasUserSignedSpotlightTerms = useCallback(
    () => termUtils.hasUserSignedSpotlightTerms(loggedInUserName, activePublisherBusinessProfileId),
    [loggedInUserName, activePublisherBusinessProfileId]
  );

  const toggleDisableRemix = useCallback(() => {
    setDisableRemix(!disableRemix);
  }, [disableRemix]);

  const handleSubmit = useCallback(async () => {
    if (hasUserSignedSpotlightTerms()) {
      onPost();
      return;
    }
    displaySpotlightTermsModal();
  }, [hasUserSignedSpotlightTerms, displaySpotlightTermsModal, onPost]);

  const renderControls = () => {
    switch (uploadState) {
      case UploaderState.NOT_STARTED:
      case UploaderState.UPLOADING:
        return <SpotlightUploadRequirements />;
      case UploaderState.DONE:
      case UploaderState.SUBMITTING:
        return (
          <>
            {mediaWithPreview?.preview ? (
              <MediaCard name={mediaWithPreview.preview.name} clearMedia={handleClearMedia} />
            ) : (
              <></>
            )}
            <DescriptionCard
              setDescription={setDescription}
              description={description}
              isValidationFailed={isValidationFailed}
              setValidationFailed={setValidationFailed}
            />
            <SaveToProfileCard toggleSaveToProfile={toggleSaveToProfile} saveToProfile={saveToProfile} />
            {isSchedulingEnabled ? <ScheduleCard setGoLiveTsMs={setGoLiveTsMs} /> : null}
            {isSchedulingEnabled ? (
              <AllowRemixCard disableRemix={disableRemix} toggleDisableRemix={toggleDisableRemix} />
            ) : null}
          </>
        );
      case UploaderState.SUBMITTED:
        return <SubmittedPostSection handleClearEditor={handleClearEditor} isScheduling={goLiveTsMs != null} />;
      case UploaderState.SUBMITTED_ERROR:
        return <SubmittedPostSection handleClearEditor={handleClearEditor} isError />;
      default:
        return <SpotlightUploadRequirements />;
    }
  };

  const renderBlankPageWithText = () => {
    return (
      <BlankPageWithText
        happyState
        message={
          <FormattedMessage
            id="uploader-no-spotlights-google-auth"
            description="Access denied to the spotlight uploader because user is logged in with GA"
            defaultMessage="Please login with Snap Auth to upload to Spotlight"
          />
        }
        helpCenterDestination={HelpCenterDestination.SPOTLIGHT}
      />
    );
  };

  const renderUploaderView = () => {
    return (
      <>
        <div data-test="SpotlightUploaderView.cardWrapper" className={style.cardWrapper}>
          <StorySnapCardRenderer
            uploadState={uploadState}
            onUpload={onUpload}
            mediaWithPreview={mediaWithPreview}
            description={description}
          />
        </div>
        <div className={style.menuWrapper}>
          <div className={style.controlsWrapper}>{renderControls()}</div>
          {isSubmittedState() ? null : (
            <SubmitBar
              disabled={isReadOnly()}
              isLoading={uploadState === UploaderState.SUBMITTING}
              handleSubmit={handleSubmit}
              hasUserSignedSpotlightTerms={hasUserSignedSpotlightTerms()}
              isScheduling={goLiveTsMs != null}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div className={style.viewWrapper}>
      {authType === AuthType.GOOGLE ? renderBlankPageWithText() : renderUploaderView()}
    </div>
  );
}
