import PropTypes from 'prop-types';
import React from 'react';

import * as routerActions from 'state/router/actions/routerActions';

import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import HomepageButton, { HomepageButtonTypes } from 'views/homepage/components/HomepageButton/HomepageButton';

import { ExtractDispatchProps } from 'types/redux';

const mapDispatchToProps = {
  goToStoryAnalytics: routerActions.goToStoryAnalytics,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = {
  publisherId: number;
  storyId: number;
} & DispatchProps;

export class AnalyticsButton extends React.PureComponent<Props> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  onClick = (e: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { publisherId } = this.props;
    this.props.goToStoryAnalytics({ publisherId, editionId: this.props.storyId });
  };

  render() {
    return (
      <SDSTooltip
        id="analytics-story-status"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={getMessageFromId('story-status-analytics')}
      >
        <span data-test="analytics">
          <HomepageButton onClick={this.onClick} type={HomepageButtonTypes.ANALYTICS} />
        </span>
      </SDSTooltip>
    );
  }
}

export default intlConnect(() => {
  return {};
}, mapDispatchToProps)(AnalyticsButton);
