import Table from 'antd/lib/table';
import type { TableProps } from 'antd/lib/table';
import classNames from 'classnames';
import React, { memo, useMemo } from 'react';

import style from './SDSTable.scss';

export type DataKey = string;
export type DataValue = any;

export type Data = {
  key: string;
} & {
  [k in DataKey]: DataValue;
};

// @ts-expect-error ts-migrate(2314) FIXME: Generic type 'TableProps<T>' requires 1 type argum... Remove this comment to see the full error message
type Props = TableProps;

function SDSTable(props: Props) {
  const columnsWithClassName = useMemo(() => {
    return props.columns.map((column: any) => ({
      ...column,
      className: classNames(column.className, style.columnClassName),
    }));
  }, [props.columns]);

  return <Table {...props} columns={columnsWithClassName} />;
}

export default memo(SDSTable);
