import { memoize } from 'lodash';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { EMPTY_ARRAY } from 'config/constants';
import type { Enum } from 'utils/enum';
import { enumObject } from 'utils/enum';

import type { AssetID } from 'types/assets';

export type SubtitleTrack = {
  id: AssetID;
  assetId: AssetID; // Deprecated in favour of id,
  language: string;
  src: string;
  source: string; // Deprecated in favour of src,
  rawContent: string | undefined | null;
};

export const SubtitleLanguage = enumObject({
  ENGLISH: 'ENGLISH',
  SPANISH: 'SPANISH',
  FRENCH: 'FRENCH',
  GERMAN: 'GERMAN',
  ARABIC: 'ARABIC',
  HINDI: 'HINDI',
  ITALIAN: 'ITALIAN',
  KOREAN: 'KOREAN',
  JAPANESE: 'JAPANESE',
  PORTUGUESE: 'PORTUGUESE',
  PUNJABI: 'PUNJABI',
  RUSSIAN: 'RUSSIAN',
  NORWEGIAN: 'NORWEGIAN',
});

export type SubtitleLanguageEnum = Enum<typeof SubtitleLanguage>;

export const SUBTITLES_LANGUAGE_TO_INTL_NAME = defineMessages({
  [SubtitleLanguage.ENGLISH]: {
    id: 'subtitle-language-english',
    description: 'Display name for English language in subtitles',
    defaultMessage: 'English',
  },
  [SubtitleLanguage.SPANISH]: {
    id: 'subtitle-language-spanish',
    description: 'Display name for Spanish language in subtitles',
    defaultMessage: 'Spanish',
  },
  [SubtitleLanguage.FRENCH]: {
    id: 'subtitle-language-french',
    description: 'Display name for French language in subtitles',
    defaultMessage: 'French',
  },
  [SubtitleLanguage.GERMAN]: {
    id: 'subtitle-language-german',
    description: 'Display name for German language in subtitles',
    defaultMessage: 'German',
  },
  [SubtitleLanguage.ARABIC]: {
    id: 'subtitle-language-arabic',
    description: 'Display name for Arabic language in subtitles',
    defaultMessage: 'Arabic',
  },
  [SubtitleLanguage.HINDI]: {
    id: 'subtitle-language-hindi',
    description: 'Display name for Hindi language in subtitles',
    defaultMessage: 'Hindi',
  },
  [SubtitleLanguage.ITALIAN]: {
    id: 'subtitle-language-italian',
    description: 'Display name for Italian language in subtitles',
    defaultMessage: 'Italian',
  },
  [SubtitleLanguage.KOREAN]: {
    id: 'subtitle-language-korean',
    description: 'Display name for Korean language in subtitles',
    defaultMessage: 'Korean',
  },
  [SubtitleLanguage.JAPANESE]: {
    id: 'subtitle-language-japanese',
    description: 'Display name for Japanese language in subtitles',
    defaultMessage: 'Japanese',
  },
  [SubtitleLanguage.NORWEGIAN]: {
    id: 'subtitle-language-norwegian',
    description: 'Display name for Norwegian language in subtitles',
    defaultMessage: 'Norwegian',
  },
  [SubtitleLanguage.PORTUGUESE]: {
    id: 'subtitle-language-portugueses',
    description: 'Display name for Portuguese language in subtitles',
    defaultMessage: 'Portuguese',
  },
  [SubtitleLanguage.PUNJABI]: {
    id: 'subtitle-language-punjabi',
    description: 'Display name for Punjabi language in subtitles',
    defaultMessage: 'Punjabi',
  },
  [SubtitleLanguage.RUSSIAN]: {
    id: 'subtitle-language-russian',
    description: 'Display name for Russian language in subtitles',
    defaultMessage: 'Russian',
  },
});

export type SubtitlesLanguageDefinition = {
  code: string;
  description: string;
  subtitlesLanguage?: SubtitleLanguageEnum;
};

export const SUBTITLES_LANGUAGES = [
  { code: 'af-ZA', description: 'Afrikaans (South Africa)' },
  { code: 'am-ET', description: 'Amharic (Ethiopia)' },
  { code: 'hy-AM', description: 'Armenian (Armenia)' },
  { code: 'az-AZ', description: 'Azerbaijani (Azerbaijan)' },
  { code: 'id-ID', description: 'Indonesian (Indonesia)' },
  { code: 'ms-MY', description: 'Malay (Malaysia)' },
  { code: 'bn-BD', description: 'Bengali (Bangladesh)' },
  { code: 'bn-IN', description: 'Bengali (India)' },
  { code: 'ca-ES', description: 'Catalan (Spain)' },
  { code: 'cs-CZ', description: 'Czech (Czech Republic)' },
  { code: 'da-DK', description: 'Danish (Denmark)' },
  { code: 'de-DE', description: 'German (Germany)', subtitlesLanguage: SubtitleLanguage.GERMAN },
  { code: 'en-AU', description: 'English (Australia)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-CA', description: 'English (Canada)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-GH', description: 'English (Ghana)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-GB', description: 'English (United Kingdom)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-IN', description: 'English (India)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-IE', description: 'English (Ireland)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-KE', description: 'English (Kenya)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-NZ', description: 'English (New Zealand)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-NG', description: 'English (Nigeria)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-PH', description: 'English (Philippines)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-ZA', description: 'English (South Africa)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-TZ', description: 'English (Tanzania)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'en-US', description: 'English (United States)', subtitlesLanguage: SubtitleLanguage.ENGLISH },
  { code: 'es-AR', description: 'Spanish (Argentina)', subtitlesLanguage: SubtitleLanguage.SPANISH },
  { code: 'es-ES', description: 'Spanish (Spain)', subtitlesLanguage: SubtitleLanguage.SPANISH },
  { code: 'es-US', description: 'Spanish (United States)', subtitlesLanguage: SubtitleLanguage.SPANISH },
  { code: 'es-MX', description: 'Spanish (Mexico)', subtitlesLanguage: SubtitleLanguage.SPANISH },
  { code: 'fil-PH', description: 'Filipino (Philippines)' },
  { code: 'fr-CA', description: 'French (Canada)', subtitlesLanguage: SubtitleLanguage.FRENCH },
  { code: 'fr-FR', description: 'French (France)', subtitlesLanguage: SubtitleLanguage.FRENCH },
  { code: 'hr-HR', description: 'Croatian (Croatia)' },
  { code: 'is-IS', description: 'Icelandic (Iceland)' },
  { code: 'it-IT', description: 'Italian (Italy)', subtitlesLanguage: SubtitleLanguage.ITALIAN },
  { code: 'jv-ID', description: 'Javanese (Indonesia)' },
  { code: 'lv-LV', description: 'Latvian (Latvia)' },
  { code: 'lt-LT', description: 'Lithuanian (Lithuania)' },
  { code: 'hu-HU', description: 'Hungarian (Hungary)' },
  { code: 'nl-NL', description: 'Dutch (Netherlands)' },
  { code: 'nb-NO', description: 'Norwegian Bokmål (Norway)', subtitlesLanguage: SubtitleLanguage.NORWEGIAN },
  { code: 'pl-PL', description: 'Polish (Poland)' },
  { code: 'pt-BR', description: 'Portuguese (Brazil)', subtitlesLanguage: SubtitleLanguage.PORTUGUESE },
  { code: 'pt-PT', description: 'Portuguese (Portugal)', subtitlesLanguage: SubtitleLanguage.PORTUGUESE },
  { code: 'pa', description: 'Punjabi', subtitlesLanguage: SubtitleLanguage.PUNJABI },
  { code: 'ro-RO', description: 'Romanian (Romania)' },
  { code: 'sk-SK', description: 'Slovak (Slovakia)' },
  { code: 'sl-SI', description: 'Slovenian (Slovenia)' },
  { code: 'fi-FI', description: 'Finnish (Finland)' },
  { code: 'sv-SE', description: 'Swedish (Sweden)' },
  { code: 'vi-VN', description: 'Vietnamese (Vietnam)' },
  { code: 'tr-TR', description: 'Turkish (Turkey)' },
  { code: 'el-GR', description: 'Greek (Greece)' },
  { code: 'bg-BG', description: 'Bulgarian (Bulgaria)' },
  { code: 'ru-RU', description: 'Russian (Russia)', subtitlesLanguage: SubtitleLanguage.RUSSIAN },
  { code: 'sr-RS', description: 'Serbian (Serbia)' },
  { code: 'uk-UA', description: 'Ukrainian (Ukraine)' },
  { code: 'he-IL', description: 'Hebrew (Israel)' },
  { code: 'ar-AE', description: 'Arabic (United Arab Emirates)', subtitlesLanguage: SubtitleLanguage.ARABIC },
  { code: 'ar-SA', description: 'Arabic (Saudi Arabia)', subtitlesLanguage: SubtitleLanguage.ARABIC },
  { code: 'ar-IQ', description: 'Arabic (Iraq)', subtitlesLanguage: SubtitleLanguage.ARABIC },
  { code: 'ar-KW', description: 'Arabic (Kuwait)', subtitlesLanguage: SubtitleLanguage.ARABIC },
  { code: 'ar-MA', description: 'Arabic (Morocco)', subtitlesLanguage: SubtitleLanguage.ARABIC },
  { code: 'fa-IR', description: 'Persian (Iran)' },
  { code: 'hi-IN', description: 'Hindi (India)', subtitlesLanguage: SubtitleLanguage.HINDI },
  { code: 'th-TH', description: 'Thai (Thailand)' },
  { code: 'ko-KR', description: 'Korean (South Korea)', subtitlesLanguage: SubtitleLanguage.KOREAN },
  { code: 'cmn-Hant-TW', description: 'Mandarin (Traditional, Taiwan)' },
  { code: 'yue-Hant-HK', description: 'Cantonese (Traditional, Hong Kong)' },
  { code: 'ja-JP', description: 'Japanese (Japan)', subtitlesLanguage: SubtitleLanguage.JAPANESE },
  { code: 'cmn-Hans-HK', description: 'Mandarin (Simplified, Hong Kong)' },
  { code: 'cmn-Hans-CN', description: 'Mandarin (Simplified, China)' },
];

// export for tests only
export const ALLOWED_TRANSLATIONS = {
  [SubtitleLanguage.ENGLISH]: ['ar-SA', 'fr-FR', 'de-DE', 'hi-IN', 'it-IT', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.ARABIC]: ['en-US', 'fr-FR', 'de-DE', 'hi-IN', 'it-IT', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.GERMAN]: ['en-US', 'ar-SA', 'fr-FR', 'hi-IN', 'it-IT', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.FRENCH]: ['en-US', 'ar-SA', 'de-DE', 'hi-IN', 'it-IT', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.HINDI]: ['en-US', 'ar-SA', 'fr-FR', 'de-DE', 'it-IT', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.PUNJABI]: ['en-US', 'ar-SA', 'fr-FR', 'de-DE', 'hi-IN', 'it-IT', 'pt-PT', 'es-MX'],
  [SubtitleLanguage.ITALIAN]: ['en-US', 'ar-SA', 'fr-FR', 'de-DE', 'hi-IN', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.PORTUGUESE]: ['en-US', 'ar-SA', 'fr-FR', 'de-DE', 'hi-IN', 'it-IT', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.NORWEGIAN]: ['en-US', 'ar-SA', 'fr-FR', 'de-DE', 'hi-IN', 'it-IT', 'pt-PT', 'pa', 'es-MX'],
  [SubtitleLanguage.SPANISH]: ['en-US', 'ar-SA', 'fr-FR', 'de-DE', 'hi-IN', 'it-IT', 'pt-PT', 'pa'],
};

// Lazy initialization of the Map
const getSubtitlesLanguageDefinitionByCodeMap = memoize(() => {
  return SUBTITLES_LANGUAGES.reduce((map, languageDefinition) => {
    return map.set(languageDefinition.code, languageDefinition);
  }, new Map());
});

// Lazy initialization of the Map
const getSubtitlesDisplayLanguageByCodeMap = memoize(() => {
  return SUBTITLES_LANGUAGES.reduce((map, languageDefinition) => {
    return map.set(languageDefinition.code, getDisplayMessage(languageDefinition));
  }, new Map());
});

export function getDisplayMessageForLanguageCode(languageCode: string) {
  return getSubtitlesDisplayLanguageByCodeMap().get(languageCode) || '';
}

export function getDisplayMessage({ description, subtitlesLanguage }: SubtitlesLanguageDefinition) {
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    (subtitlesLanguage && <FormattedMessage {...SUBTITLES_LANGUAGE_TO_INTL_NAME[subtitlesLanguage]} />) || description
  );
}

export const getAllowedLanguageCodesByLanguageCode = memoize((languageCode: string) => {
  const languageDefinitionByLanguageCodeMap = getSubtitlesLanguageDefinitionByCodeMap();
  const languageDefinition = languageDefinitionByLanguageCodeMap.get(languageCode);
  if (!languageDefinition || !languageDefinition.subtitlesLanguage) {
    return EMPTY_ARRAY;
  }

  return ALLOWED_TRANSLATIONS[languageDefinition.subtitlesLanguage] || EMPTY_ARRAY;
});
