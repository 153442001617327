import moment from 'moment/moment';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounter } from 'utils/grapheneUtils';

import {
  EDITION_COUNTRY_OPTIONS,
  EditionCountry,
} from 'views/analytics/components/AnalyticsEditionHeader/EditionCountry';
import { ProfileAnalyticsCSVButton } from 'views/analytics/components/ProfileAnalyticsCSVButton/ProfileAnalyticsCSVButton';
import { AnalyticsReportRange } from 'views/analytics/utils/kpiConfigs';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import { CalendarShortcut } from 'views/common/containers/DateRangePickerModal/DateRangePickerModal';
import CalendarButton from 'views/homepage/containers/CalendarButton/CalendarButton';

import style from './AnalyticsHeader.scss';

type Props = {
  onDateRangeUpdated?: (fromMoment?: moment.Moment | null, toMoment?: moment.Moment | null) => void;
  from: moment.Moment | null;
  to: moment.Moment | null;
  currentRange?: AnalyticsReportRange;
  setReportRange?: (reportRange: AnalyticsReportRange) => void;
  allowDataExport?: boolean;
  country?: EditionCountry;
  setCountry?: (country: EditionCountry) => void;
  allowCountryFilter?: boolean;
};

export function AnalyticsHeader({
  onDateRangeUpdated,
  from,
  to,
  allowDataExport,
  country,
  setCountry,
  allowCountryFilter,
}: Props) {
  const renderDownloadCSVButton = () => {
    if (!allowDataExport) {
      return null;
    }
    return <ProfileAnalyticsCSVButton startDate={from} endDate={to} />;
  };

  const onCountryChange = useCallback(
    (updatedCountry: EditionCountry) => {
      incrementCounter(GrafanaMetrics.STORY_ANALYTICS, {
        type: 'countryFilter',
        action: updatedCountry?.toLowerCase(),
      });
      if (setCountry) {
        setCountry(updatedCountry);
      }
    },
    [setCountry]
  );

  const renderCountryFilterButton = () => {
    if (allowCountryFilter) {
      return (
        <div className={style.countryDropdown}>
          <SDSDropdown
            type={DropdownType.GREY}
            size={DropdownSize.SMALL}
            value={country}
            data-test="AnalyticsEditionCSVModal.breakdownDropdown"
            onChange={onCountryChange}
          >
            {createSDSDropdownOptions(EDITION_COUNTRY_OPTIONS)}
          </SDSDropdown>
        </div>
      );
    }
    return null;
  };

  const renderDateFilterButton = () => {
    const minDate = moment().subtract(90, 'days');
    const noDateText = (
      <FormattedMessage
        defaultMessage="Last 3 Months"
        id="analytics-edition-90-days-range"
        description="Default text for the analytics date range picker button"
      />
    );
    return (
      <CalendarButton
        className={style.datePickerButton}
        onUpdate={onDateRangeUpdated!}
        defaultText={noDateText}
        from={from}
        to={to}
        minDate={minDate}
        shortcuts={
          new Set<CalendarShortcut>([
            CalendarShortcut.LAST_SEVEN_DAYS,
            CalendarShortcut.LAST_THIRTY_DAYS,
            CalendarShortcut.LAST_THREE_MONTHS,
          ])
        }
      />
    );
  };

  return (
    <div className={style.controlGroup} data-test="editionAnalyticsControlGroup">
      <div className={style.rangeSelectorContainer}>{renderDateFilterButton()}</div>
      <div className={style.exportCsvContainer}>{renderDownloadCSVButton()}</div>
      <div className={style.exportCsvContainer}>{renderCountryFilterButton()}</div>
    </div>
  );
}
