import AntdDatePicker from 'antd/lib/date-picker';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"antd/lib/date-picker"' has no exported me... Remove this comment to see the full error message
import type { DatePickerProps } from 'antd/lib/date-picker';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';

import style from './SDSDatepicker.scss';

type OwnProps = {
  lowerBoundDate: moment.Moment | undefined | null;
  upperBoundDate: moment.Moment | undefined | null;
};

type Props = DatePickerProps & OwnProps;

export class SDSDatePicker extends PureComponent<Props> {
  handleOnChange = (date?: moment.Moment | null) => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    if (!date) {
      onChange(undefined);
    } else {
      onChange(date);
    }
  };

  isDateDisabled = (current?: moment.Moment | null): boolean | undefined | null => {
    if (!current) {
      return false;
    }

    if (this.props.lowerBoundDate && current.isBefore(this.props.lowerBoundDate)) {
      return true;
    }

    return !!(this.props.upperBoundDate && current.isAfter(this.props.upperBoundDate));
  };

  render() {
    return (
      <div>
        <AntdDatePicker
          allowClear={this.props.allowClear}
          className={style.datePicker}
          disabled={this.props.disabled}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          disabledDate={this.isDateDisabled}
          dropdownClassName={style.datePickerDropdown}
          format="lll"
          onChange={this.handleOnChange}
          placeholder={this.props.placeholder}
          showTime={this.props.showTime}
          value={this.props.value}
        />
      </div>
    );
  }
}

export default SDSDatePicker;
