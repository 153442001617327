import { useStore } from 'react-redux';

import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { Claim } from 'types/permissions';

export const usePermissionCheck = (claim: Claim) => {
  const store = useStore();
  return hasClaimForActivePublisher(store.getState(), claim);
};
