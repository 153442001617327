import React from 'react';
import { InjectedIntlProps, injectIntl, IntlShape } from 'react-intl';

import { showAsDate } from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { getSpotlightAnalyticsGraphConfig, AnalyticsReportRange } from 'views/analytics/utils/kpiConfigs';

import { ProfileSpotlightAnalytics, SpotlightMetric } from 'types/analytics';

type Props = {
  activeMetric: SpotlightMetric;
  profileSpotlightAnalytics?: ProfileSpotlightAnalytics | null;
  intl: IntlShape;
  isLoading: boolean;
  reportRange: AnalyticsReportRange;
};

type ChartToolTip = {
  formatter?: (num: number) => string;
  name: string;
  payload: {
    viewers: number;
    views: number;
    favourites: number;
    newFollowers: number;
    shares: number;
  };
  value: number;
};

function SpotlightAnalyticsGraph({
  activeMetric,
  profileSpotlightAnalytics,
  intl,
  isLoading,
  reportRange,
}: Props & InjectedIntlProps) {
  let metricsData = profileSpotlightAnalytics?.timeSeries.map(item => ({
    ...item.summary,
    name: showAsDate(item.timestamp),
  }));

  if (reportRange === AnalyticsReportRange.WEEK && metricsData) {
    const metricsDataFromLastWeek = metricsData.length - 7;
    metricsData = metricsData?.slice(metricsDataFromLastWeek, metricsData.length - 1);
  }

  const spotlightAnalyticsGraphConfig = getSpotlightAnalyticsGraphConfig(intl)[activeMetric]!;

  function renderChartTooltip(tooltipData: { payload: ChartToolTip[] }) {
    const chartPayload = tooltipData?.payload && tooltipData.payload[0];
    const currentMetricTitle = spotlightAnalyticsGraphConfig.name;
    const formatter = spotlightAnalyticsGraphConfig.yAxisTickFormatter;
    const metricRows = [
      {
        metricValue: formatter(chartPayload?.value || 0),
        metricName: currentMetricTitle,
      },
    ];
    return <AnalyticsTooltip title={currentMetricTitle} metricRows={metricRows} />;
  }
  return (
    <AnalyticsBarChart
      barChartData={metricsData || []}
      barChartTitle={spotlightAnalyticsGraphConfig.name}
      graphTooltip={spotlightAnalyticsGraphConfig.graphTooltip}
      isLoading={isLoading}
      yAxisTickFormatter={spotlightAnalyticsGraphConfig.yAxisTickFormatter}
      bars={[spotlightAnalyticsGraphConfig]}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      tooltipRenderer={renderChartTooltip}
    />
  );
}

export default injectIntl(SpotlightAnalyticsGraph);
