import React from 'react';
import type { ReactNode } from 'react';

import style from './EditorTopBar.scss';

type Props = {
  children: ReactNode;
};

export default function EditorTopBar(props: Props) {
  return (
    <div className={style.root}>
      {props.children}
      <div className={style.bottomGradient} />
    </div>
  );
}
