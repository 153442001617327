import React from 'react';

import StorySnapUploadCard from 'views/storySnapUploader/components/StorySnapCards/StorySnapUploadCard/StorySnapUploadCard';
import StorySnapUploadPreviewCard from 'views/storySnapUploader/components/StorySnapCards/StorySnapUploadPreviewCard/StorySnapUploadPreviewCard';
import StorySnapUploadingCard from 'views/storySnapUploader/components/StorySnapCards/StorySnapUploadingCard/StorySnapUploadingCard';

import { StorySnapMediaWithPreview } from 'types/media';
import { UploaderState } from 'types/storySnapEditor';

type Props = {
  uploadState: UploaderState;
  onUpload: (file: File) => void;
  mediaWithPreview?: StorySnapMediaWithPreview;
  description?: string;
};

export default function StorySnapCardWrapper({ uploadState, onUpload, mediaWithPreview, description }: Props) {
  const snapCardRenderer = () => {
    switch (uploadState) {
      case UploaderState.NOT_STARTED:
        return <StorySnapUploadCard onUpload={onUpload} />;
      case UploaderState.UPLOADING:
        return <StorySnapUploadingCard />;
      case UploaderState.ERROR:
        return <StorySnapUploadCard onUpload={onUpload} isError />;
      case UploaderState.DONE:
      case UploaderState.SUBMITTING:
      case UploaderState.SUBMITTED:
      case UploaderState.SUBMITTED_ERROR:
        return mediaWithPreview?.preview ? (
          <StorySnapUploadPreviewCard src={mediaWithPreview.preview.src} snapDescription={description} />
        ) : (
          <></>
        );
      default:
        return <StorySnapUploadingCard />;
    }
  };
  return <>{snapCardRenderer()}</>;
}
