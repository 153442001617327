import md5 from 'md5';

import { LocalStorage } from 'config/constants';

import { termsVersion } from './terms.json';

interface SpotlightTermsAcceptance {
  termsVersion: string;
  posixDateMs: number;
  hashedUsername: string;
  hashedBusinessProfileIds: string[];
}

const DEFAULT_TERMS_ACCEPTANCE: SpotlightTermsAcceptance = {
  termsVersion: '',
  posixDateMs: 0,
  hashedUsername: '',
  hashedBusinessProfileIds: [],
};

export const getSpotlightTermStatus = (): SpotlightTermsAcceptance[] => {
  const currentTermsJson = localStorage.getItem(LocalStorage.SPOTLIGHT_TERMS);

  let currentTerms: SpotlightTermsAcceptance[] = [];
  if (currentTermsJson) {
    currentTerms = JSON.parse(currentTermsJson);
  }

  return currentTerms;
};

export const getSpotlightTermStatusForUser = (hashedUsername: string): SpotlightTermsAcceptance => {
  const currentTerms = getSpotlightTermStatus();
  const maybeCurrentTermForUser: SpotlightTermsAcceptance[] = currentTerms.filter(
    terms => terms.hashedUsername === hashedUsername
  );

  // @ts-ignore maybeCurrentTermForUser is not undefined
  return maybeCurrentTermForUser && maybeCurrentTermForUser.length > 0
    ? maybeCurrentTermForUser[0]
    : DEFAULT_TERMS_ACCEPTANCE;
};
export const hasUserSignedSpotlightTerms = (username: string, businessProfileId: string) => {
  const hashedUsername = md5(username);
  const hashedBusinessProfileId = md5(businessProfileId);
  const currentTermStatusForUser = getSpotlightTermStatusForUser(hashedUsername);

  const sameHash = currentTermStatusForUser.termsVersion === termsVersion;
  const sameUser = currentTermStatusForUser.hashedUsername === hashedUsername;
  return (
    sameHash &&
    sameUser &&
    currentTermStatusForUser.hashedBusinessProfileIds.filter(
      acceptedHashedBusinessProfileId => acceptedHashedBusinessProfileId === hashedBusinessProfileId
    ).length > 0
  );
};

export const acceptSpotlightTerms = (username: string, businessProfileId: string) => {
  const hashedUsername = md5(username);
  const hashedBusinessProfileId = md5(businessProfileId);
  const currentTerms = getSpotlightTermStatus();
  const termsForHashedUser = getSpotlightTermStatusForUser(hashedUsername);

  const newTermStatusForUser = {
    termsVersion,
    posixDateMs: Date.now(),
    hashedUsername,
    hashedBusinessProfileIds: [...(termsForHashedUser.hashedBusinessProfileIds || []), hashedBusinessProfileId],
  };

  // Remove old terms for the user and update with new one
  const updatedTerms = [newTermStatusForUser, ...currentTerms.filter(terms => terms.hashedUsername !== hashedUsername)];
  localStorage.setItem(LocalStorage.SPOTLIGHT_TERMS, JSON.stringify(updatedTerms));
};
