import { useQuery } from '@apollo/client';

import { GetStoryPageAnalytics } from 'gql/queries/analytics/__ssp-generated__/GetStoryPageAnalytics';
import { GET_STORY_PAGE_ANALYTICS } from 'gql/queries/analytics/storyPageAnalyticsQuery';

export const useGetStoryPageAnalytics = (
  start: string | undefined,
  end: string | undefined,
  hostUserId: string,
  storyId: string,
  country: string
) => {
  const { data, loading } = useQuery<GetStoryPageAnalytics>(GET_STORY_PAGE_ANALYTICS, {
    variables: {
      input: {
        start,
        end,
        hostUserId,
        storyId,
        country,
      },
      hostUserId,
      storyId,
      country,
      storyIdString: storyId,
    },
  });
  return { data, loading };
};
