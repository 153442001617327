import { gql } from '@apollo/client';

import { useProxyMutation } from 'utils/apis/graphQLUtils';

import {
  useAppealTileModerations,
  useAppealTileModerationsVariables,
} from './__ssp-generated__/useAppealTileModerations';

export const APPEAL_TILE_MODERATIONS = gql`
  mutation useAppealTileModerations($input: AppealTileModerationsInput) {
    appealTileModerations(input: $input) {
      tiles {
        id
        error
      }
    }
  }
`;

export function useAppealTileModerationsMutation() {
  const [appealTileModerations, { data, error }] = useProxyMutation<
    useAppealTileModerations,
    useAppealTileModerationsVariables
  >(APPEAL_TILE_MODERATIONS);
  if (error) {
    return { appealTileModerations, error };
  }
  return { appealTileModerations, data };
}
