import React from 'react';

import { Spinner, SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';

import style from './StorySnapUploadingCard.scss';

export default function StorySnapUploadingCard() {
  return (
    <div data-test="StorySnapUploadingCard.spinner.wrapper" className={style.container}>
      <Spinner loading message={SpinnerLabels.UPLOADING} size={SpinnerSizes.LARGE} />
    </div>
  );
}
