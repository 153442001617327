import classNames from 'classnames';
import React from 'react';

import { BITMOJI_BASE, CrossOrigin } from 'config/constants';

import style from './ImageWithPlaceholder.scss';

type Props = {
  src?: string;
  className?: string;
  showPlaceholderWhileLoading?: boolean;
  fadein?: boolean;
};

type State = any;

export class ImageWithPlaceholder extends React.Component<Props, State> {
  state = {
    imageLoaded: false,
    error: false,
    image: null,
  };

  UNSAFE_componentWillMount() {
    this.receiveProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.src !== nextProps.src) {
      this.receiveProps(nextProps);
    }
  }

  componentWillUnmount() {
    this.destroyTheImage();
  }

  destroyTheImage(additionalProps = {}) {
    if (!this.state.image) {
      return;
    }

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    this.state.image.onload = null;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    this.state.image.onerror = null;

    this.setState({
      image: null,
      ...additionalProps,
    });
  }

  handleImageLoaded = () => {
    this.destroyTheImage({ imageLoaded: true, error: false });
  };

  handleImageError = () => {
    this.destroyTheImage({ error: true });
  };

  preloadImage(src: any) {
    const image = new Image();
    image.src = src;
    image.crossOrigin = CrossOrigin.NONE;

    if (!image.src.startsWith(BITMOJI_BASE)) {
      image.crossOrigin = CrossOrigin.USE_CREDENTIALS;
    }
    image.onload = this.handleImageLoaded;
    image.onerror = this.handleImageError;
    this.setState({ image });
  }

  receiveProps(props: any) {
    // If no src treat as error and show placeholder
    if (!props.src) {
      this.setState({ error: true });
      return;
    }

    this.preloadImage(props.src);
  }

  renderImage() {
    if (!this.state.imageLoaded) {
      return null;
    }

    const crossOrigin = this.props.src?.startsWith(BITMOJI_BASE) ? CrossOrigin.NONE : CrossOrigin.USE_CREDENTIALS;
    return (
      <img
        alt=""
        draggable="false"
        src={this.props.src}
        crossOrigin={crossOrigin}
        className={classNames(this.props.className, style.img, {
          [style.hidden]: !this.state.imageLoaded,
          [style.fadein]: this.props.fadein && this.state.imageLoaded,
        })}
      />
    );
  }

  render() {
    return (
      <div className={style.container} data-test="imageWithPlacehodler">
        {this.renderImage()}
        {(this.state.error || this.props.showPlaceholderWhileLoading) && this.props.children}
      </div>
    );
  }
}

export default ImageWithPlaceholder;
