import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import BitmojiAvatar from '../../BitmojiAvatar/BitmojiAvatar';

import { BITMOJI_TEMPLATES } from 'config/bitmojiConfig';
import { MIN_WIDTH, MIN_HEIGHT, MIN_DURATION_SECS, MAX_DURATION_MINS } from 'config/storySnapConstants';
import { upload } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import RestrictedDropzone from 'views/common/components/RestrictedDropzone/RestrictedDropzone';

import style from './StorySnapUploadCard.scss';

type Props = {
  onUpload: (file: File) => void;
  isError?: boolean;
};

// Update to allow images to be posted
const STORY_SNAP_VIDEO_FORMAT = 'video/mp4,video/x-m4v,video/*';

export default function StorySnapUploadCard({ onUpload, isError }: Props) {
  return (
    <RestrictedDropzone
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call className.
      className={classNames(style.container, {
        [style.errorContainer]: isError,
      })}
      data-test="SnapUploaderCard"
      onFile={onUpload}
      accept={STORY_SNAP_VIDEO_FORMAT}
    >
      {isError ? (
        <div data-test="SnapUploaderCard.bitmoji.fail" className={style.errorBitmoji}>
          <BitmojiAvatar bitmojiTemplateId={BITMOJI_TEMPLATES.USER_ERROR} />
        </div>
      ) : (
        <Icon inlineIcon={upload} data-test="SnapUploaderCard.icon" className={style.uploadIcon} />
      )}

      <div className={style.heading}>
        {isError ? (
          <FormattedMessage
            id="drag-and-drop-story-snap-error"
            defaultMessage="Failed to upload. {br} Drag and drop or select media to retry."
            description="Instructions for how to retry to upload a video."
            values={{ br: <br /> }}
          />
        ) : (
          <FormattedMessage
            id="drag-and-drop-story-snap"
            defaultMessage="Drag and drop or select a video to upload"
            description="Instructions for how to upload a video."
          />
        )}
      </div>
      <div className={style.subHeading}>
        <FormattedMessage
          id="media-restriction-story-snap-v2"
          defaultMessage=".mp4 video, {minDurationSecs} seconds - {maxDurationMins} minutes, {minWidth}x{minHeight} minimum resolution"
          description="media restrictions when uploading"
          values={{
            minWidth: MIN_WIDTH,
            minHeight: MIN_HEIGHT,
            minDurationSecs: MIN_DURATION_SECS,
            maxDurationMins: MAX_DURATION_MINS,
          }}
        />
      </div>
    </RestrictedDropzone>
  );
}
