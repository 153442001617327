import classnames from 'classnames';
import { get, reduce } from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { audienceToShortString, shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import {
  getStoryAudience,
  hasAnyTilesBeenFlaggedForModerationForStoryId,
} from 'state/buildStatus/selectors/buildStatusSelectors';
import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import * as extrasSelectors from 'state/extras/selectors/extrasSelectors';
import { isSeasonsEpisodesDisabled } from 'state/features/selectors/featuresSelectors';
import { getStoryIsReadOnlyOrSaving } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { renameStoryPrompt, updateEpisodePrompt } from 'state/publisherTools/actions/publisherToolsActions';
import * as seasonsSelectors from 'state/seasons/selectors/seasonsSelectors';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';

import { StoryStatus } from 'config/constants';
import type { StoryStatusEnum } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import pencilIcon from 'views/publisherStoryEditor/icons/drawIcon.svg';

import style from './HomepageCellText.scss';

import type { Edition } from 'types/editions';
import { LiveEditStatus } from 'types/editions';
import type { AudienceEnum } from 'types/moderation';
import type { BusinessProfileID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { Episode, Extra, Season, Show } from 'types/shows';

export const numSnapsMessage = (numSnaps: number): ReactNode => (
  <FormattedMessage
    id="num-snaps-on-story"
    description="Displays the number of snaps in a story"
    defaultMessage={'{numSnaps} snaps'}
    values={{ numSnaps }}
  />
);

export const singleSegment: ReactNode = (
  <FormattedMessage
    id="one-segment-on-story"
    description="Has a single segment in the message"
    defaultMessage="1 segment"
  />
);

export const multipleSegments = (numSegments: number): ReactNode => (
  <FormattedMessage
    id="num-segments-on-story"
    description="Displays the number of segments in a story"
    defaultMessage={'{numSegments} segments'}
    values={{ numSegments }}
  />
);

export const tilesFlaggedByModeration: ReactNode = (
  <FormattedMessage
    id="tiles-flagged-by-moderation"
    description="Some of the tiles of this story have been flagged by moderation"
    defaultMessage="Tiles flagged by moderation"
  />
);

type StateProps = {
  areTilesFlaggedForModeration: boolean;
  episode: Episode | undefined | null;
  extra: Extra | undefined | null;
  isSeasonEpisodesDisabled: boolean;
  liveEditStatus: string;
  season: Season | undefined | null;
  show: Show | undefined | null;
  storyAudienceList: Array<AudienceEnum>;
  storyId: number;
  storyTitle: string;
  titleIsEditable: boolean;
};

type OwnProps = {
  isShow: boolean;
  publisherId: number;
  businessProfileId: BusinessProfileID;
  story: Edition;
  storyBuildStatus: StoryStatusEnum;
  isStoryPromoted: boolean;
};

const mapStateToProps = (state: State, ownProps: any): StateProps => {
  const { story } = ownProps;
  const storyId: number = story.id;

  const episode = episodesSelectors.getEpisodeById(state)(storyId);
  const extra = extrasSelectors.getExtraById(state)(storyId);
  const seasonId = (episode && episode.seasonId) || (extra && extra.seasonId) || null;
  const season = seasonId ? seasonsSelectors.getSeasonById(state)(seasonId) : null;
  const show = showsSelectors.getShowById(state)(get(season, 'showId'));

  return {
    areTilesFlaggedForModeration: hasAnyTilesBeenFlaggedForModerationForStoryId(state)(storyId),
    episode,
    extra,
    isSeasonEpisodesDisabled: isSeasonsEpisodesDisabled(state),
    liveEditStatus: story.liveEditStatus,
    season,
    show,
    storyAudienceList: getStoryAudience(state)(storyId),
    storyId,
    storyTitle: story.title || '',
    titleIsEditable: !getStoryIsReadOnlyOrSaving(state),
  };
};

const mapDispatchToProps = {
  renameStoryPrompt,
  updateEpisodePrompt,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps & StateProps;

export class HomepageCellText extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps);
  }

  getTitleStyle = () => {
    if (this.props.titleIsEditable) {
      return style.editableStoryTitle;
    }
    return style.uneditableStoryTitle;
  };

  showRenamePopup = (storyId: number) => {
    const { publisherId, isShow, businessProfileId } = this.props;
    if (isShow) {
      this.props.updateEpisodePrompt({ storyId, publisherId, businessProfileId });
      return;
    }

    this.props.renameStoryPrompt({
      storyId,
    });
  };

  handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (this.props.titleIsEditable) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.showRenamePopup(this.props.storyId);
    }
  };

  handleSerializedShowClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!this.props.isShow) {
      return;
    }

    this.handleClick(e);
  };

  renderPencilIcon = () => {
    return <img src={pencilIcon} alt="icon" className={style.pencilIcon} />;
  };

  renderUnpublishedChanges = () => {
    if (this.props.liveEditStatus === LiveEditStatus.IN_PROGRESS) {
      const unpublishedChangesMessage = intlMessages.getMessageFromId('story-unpublished-changes');

      return <span data-test="homepageCellText.unpublishedChanges">| {unpublishedChangesMessage}</span>;
    }
    return null;
  };

  renderIncompleteStory = () => {
    if (this.props.storyBuildStatus === StoryStatus.PUBLISHABLE || !this.props.storyBuildStatus) {
      return null;
    }

    const incompleteSnapsMessage = intlMessages.getMessageFromId('story-incomplete-story');

    return <span data-test="homepageCellText.incompleteStory">| {incompleteSnapsMessage}</span>;
  };

  renderShowsMetadata = () => {
    const { isShow, episode, extra, season, show, isSeasonEpisodesDisabled } = this.props;
    if (isSeasonEpisodesDisabled || !isShow || (!episode && !extra) || !season || !show) {
      return null;
    }
    const params = { showName: show.name, seasonNumber: season.seasonNumber };
    if (episode) {
      return intlMessages.getMessageFromId('home-page-cell-text-episode-metadata', {
        ...params,
        episodeNumber: episode.episodeNumber,
      });
    }
    // for now, Extras are always trailers
    return intlMessages.getMessageFromId('home-page-cell-text-trailer', params);
  };

  renderSegmentMetadata = () => {
    const numSegments: number = this.props.story.segments.length;
    if (numSegments === 0) {
      return null;
    }

    const segmentMessage = numSegments === 1 ? singleSegment : multipleSegments(numSegments);

    return <>|{segmentMessage}</>;
  };

  renderStoryAudienceStatus = (): ReactNode | undefined | null => {
    if (shouldShowModeration(this.props.storyAudienceList)) {
      return reduce(
        this.props.storyAudienceList,
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        (acc, audienceItem) => {
          return (
            <>
              {acc} | {audienceToShortString(audienceItem)}
            </>
          );
        },
        ''
      );
    }

    if (this.props.areTilesFlaggedForModeration) {
      return <> |{tilesFlaggedByModeration}</>;
    }

    return null;
  };

  renderStorySubtitle() {
    let subtitleStyles = style.subtitle;
    let storyStateMessage: ReactNode | null = this.renderIncompleteStory() || this.renderUnpublishedChanges();

    // Prioritise incomplete and unpublished changes statuses first
    if (!storyStateMessage) {
      storyStateMessage = this.renderStoryAudienceStatus();

      // If a story is moderation restricted, display the subtitle in a warning colour
      if (storyStateMessage) {
        subtitleStyles = classnames(subtitleStyles, style.warning);
      }
    }

    const numSnaps = this.props.story.snapIds.length;
    return (
      <div className={subtitleStyles} data-test="homepageCelltext.subtitle">
        {numSnapsMessage(numSnaps)} {this.renderSegmentMetadata()} {this.renderShowsMetadata()} {storyStateMessage}
      </div>
    );
  }

  render() {
    const title = this.props.storyTitle;
    return (
      <div className={style.storyCell} onClick={this.handleSerializedShowClick}>
        <div className={this.getTitleStyle()} onClick={this.handleClick} data-test="homepageCelltext.storyTitle">
          <div className={style.titleContainer}>
            {title}
            {this.renderPencilIcon()}
          </div>
        </div>
        {this.renderStorySubtitle()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(HomepageCellText);
