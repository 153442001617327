import moment from 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import style from '../storySnapMenuCardStyle.scss';

import { calendar } from 'icons/SDS/allIcons';
import { getDisabledStartTimeHours, getDisabledStartTimeMinutes } from 'utils/dateUtils';
import { generateStartDateTimezones, guessTimezone } from 'utils/timezoneUtils';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDatePicker from 'views/common/components/SDSDatePicker/SDSDatePicker';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';

type Props = {
  setGoLiveTsMs: (goLiveDateTsMs: number | undefined) => void;
  initialGoLiveTsMs?: number;
  showUpdateScheduleMenu?: boolean;
  saveUpdatedSchedule?: () => void;
};
export default function ScheduleCard({
  setGoLiveTsMs,
  initialGoLiveTsMs,
  showUpdateScheduleMenu,
  saveUpdatedSchedule,
}: Props) {
  const [isScheduling, setIsScheduling] = useState(false);
  const initialTimezone = guessTimezone().name;
  const [selectedDateTimezone, setDateTimezone] = useState(initialTimezone);

  const [startDate, setStartDate] = useState<moment.Moment>(
    initialGoLiveTsMs ? moment.utc(initialGoLiveTsMs) : moment.utc().add(1, 'day')
  );

  // PostStorySnapV2 validates that the go live timestamp is no more than 30
  // days from now. In order to accomodate the time of day input we use 29 days
  // from now.
  const lastPossibleEndDate = moment().add(29, 'days');
  const firstPossibleStartDay = moment.tz(selectedDateTimezone).startOf('day');
  const suggestedStartDate = moment.tz(selectedDateTimezone).add(1, 'week');
  const defaultStartDate = startDate || suggestedStartDate; // when the publish later toggle is off start date is null

  const initialStartDateTimezoneOptions = generateStartDateTimezones(
    defaultStartDate,
    undefined,
    selectedDateTimezone,
    false
  );
  const [startDateTimezoneOptions, setStartDateTimezoneOptions] = useState(initialStartDateTimezoneOptions);

  const setNewDateTimezone = useCallback(setDateTimezone, [setDateTimezone]);

  const setNewTimestamp = useCallback(
    (date, tz) => {
      const tsMs = moment(date).tz(tz, true).valueOf();
      const options = generateStartDateTimezones(date, undefined, selectedDateTimezone, false);
      setStartDateTimezoneOptions(options);
      setGoLiveTsMs(Number(tsMs));
    },
    [selectedDateTimezone, setGoLiveTsMs]
  );

  const toggleIsScheduling = useCallback(() => {
    const isSchedulingTurnedOn = !isScheduling;
    setIsScheduling(isSchedulingTurnedOn);
    if (isSchedulingTurnedOn) {
      setGoLiveTsMs(Number(moment.utc(defaultStartDate)).valueOf());
    } else {
      setGoLiveTsMs(undefined);
    }
  }, [defaultStartDate, isScheduling, setGoLiveTsMs]);

  const handleScheduleUpdateSave = useCallback(() => {
    if (saveUpdatedSchedule) {
      saveUpdatedSchedule();
    }
  }, [saveUpdatedSchedule]);

  const handleDateTimezoneChange = useCallback(
    (value: string) => {
      const date = startDate || firstPossibleStartDay;
      const newStartDateTimezoneOptions = generateStartDateTimezones(date, undefined, value, false);
      setStartDateTimezoneOptions(newStartDateTimezoneOptions);
      setNewDateTimezone(value);
      setNewTimestamp(startDate, value);
    },
    [startDate, firstPossibleStartDay, setNewDateTimezone, setNewTimestamp]
  );

  // DATE INPUTS
  const getDisabledTimeAndFormat = () => {
    const date = startDate || firstPossibleStartDay;
    const disabledHours = getDisabledStartTimeHours(date, undefined, selectedDateTimezone, false);
    const disabledMinutes = getDisabledStartTimeMinutes(date, undefined, selectedDateTimezone, false);
    return {
      format: 'HH:mm',
      disabledHours,
      disabledMinutes,
      minuteStep: 1,
    };
  };

  const handleGoLiveDateChange = useCallback(
    date => {
      setStartDate(date);
      setNewTimestamp(date, selectedDateTimezone);
    },
    [selectedDateTimezone, setNewTimestamp]
  );

  const renderScheduleMenu = () => {
    return (
      <div className={style.collapsedCardSection}>
        <SDSLabel labelTitle="Date" withoutDefaultMargin>
          <SDSDatePicker
            allowClear={false}
            data-test="ScheduleCard.scheduling.DatePicker"
            onChange={handleGoLiveDateChange}
            value={startDate || suggestedStartDate}
            showTime={getDisabledTimeAndFormat()}
            upperBoundDate={lastPossibleEndDate}
            lowerBoundDate={firstPossibleStartDay}
          />
        </SDSLabel>
        <SDSLabel labelTitle="Timezone" withoutDefaultMargin>
          <SDSDropdown
            data-test="ScheduleCard.scheduling.DateTimezoneDropdown"
            defaultValue={initialTimezone}
            value={selectedDateTimezone}
            onChange={handleDateTimezoneChange}
            disableClear
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
          >
            {createSDSDropdownOptions(startDateTimezoneOptions)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  };

  const renderScheduleToggle = () => {
    return (
      <div className={style.toggleWrapper}>
        <SDSSwitch data-test="ScheduleCard.toggle" onChange={toggleIsScheduling} value={isScheduling} />
      </div>
    );
  };

  const renderScheduleSaveButton = () => {
    return showUpdateScheduleMenu ? (
      <div>
        <SDSButton data-test="ScheduleCard.SaveButton" type={ButtonType.SECONDARY} onClick={handleScheduleUpdateSave}>
          <FormattedMessage
            id="schedule-save-button"
            description="Button to save scheduled Spotlight's go live date"
            defaultMessage="Save"
          />
        </SDSButton>
      </div>
    ) : null;
  };

  return (
    <div className={showUpdateScheduleMenu ? style.updateViewCardContainer : style.collapsableCardContainer}>
      <div className={style.collapsableCardInfo}>
        <div className={style.cardInfo}>
          <Icon inlineIcon={calendar} className={style.icon} />
          <div className={style.cardTitle}>
            <div className={style.heading}>
              <FormattedMessage
                defaultMessage="Schedule"
                description="Schedule spotlight title"
                id="scheduling spotlight"
              />
            </div>

            <FormattedMessage
              defaultMessage="Schedule to submit to Spotlight at a later date"
              description="Schedule spotlight snap to go live at a later date description"
              id="schedule-spotlight-description"
            />
          </div>
        </div>
        {showUpdateScheduleMenu ? renderScheduleSaveButton() : renderScheduleToggle()}
      </div>

      {isScheduling || showUpdateScheduleMenu ? renderScheduleMenu() : null}
    </div>
  );
}
