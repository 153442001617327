import { get } from 'lodash';
import React from 'react';
import type { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import * as snapAdminActions from 'state/snapAdmin/actions/snapAdminActions';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';

import { check } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import { SDSCheckbox } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import PageHeader from 'views/common/containers/PageHeader/PageHeader';

import style from './NotificationBannerPage.scss';

import type { State } from 'types/rootState';

type StateProps = {
  cmsNotificationSetting: {
    notificationMessage: string;
    showNotificationMessage: string;
  };
};

type DispatchProps = {
  getNotificationSettingData: typeof snapAdminActions.getNotificationSettingData;
  setNotificationSettingData: typeof snapAdminActions.setNotificationSettingData;
  initNotificationUpdateLoop: typeof snapAdminActions.initNotificationUpdateLoop;
};

type OwnState = {
  notificationMessage: string;
  showNotificationMessage: boolean;
  hasChanges: boolean;
};

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: State) => {
  return {
    cmsNotificationSetting: snapAdminSelectors.getCmsNotificationSetting(state),
  };
};

const mapDispatchToProps = {
  getNotificationSettingData: snapAdminActions.getNotificationSettingData,
  setNotificationSettingData: snapAdminActions.setNotificationSettingData,
  initNotificationUpdateLoop: snapAdminActions.initNotificationUpdateLoop,
};

export class NotificationBannerPage extends React.Component<Props, OwnState> {
  static path = '/snap_admin/notification';

  static title = 'Notification message';

  static bannerInfoDocUrl =
    'https://docs.google.com/spreadsheets/d/1OAwpzM6Txo3tdHzswsWfERgRHAun-epT5ALlzZHtVHk/edit?usp=sharing';

  state = {
    notificationMessage: '',
    showNotificationMessage: false,
    hasChanges: false,
  };

  UNSAFE_componentWillMount() {
    this.props.getNotificationSettingData();
    this.props.initNotificationUpdateLoop();

    const notificationMessage = get(this.props.cmsNotificationSetting, 'notificationMessage', '');
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    const showNotificationMessage = this.props.cmsNotificationSetting === 'true';

    if (notificationMessage) {
      this.setState({ notificationMessage, showNotificationMessage });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.cmsNotificationSetting !== this.props.cmsNotificationSetting) {
      const notificationMessage = get(nextProps.cmsNotificationSetting, 'notificationMessage', '');
      const showNotificationMessage = nextProps.cmsNotificationSetting.showNotificationMessage === 'true';

      if (notificationMessage) {
        this.setState({ notificationMessage, showNotificationMessage });
      }
    }
  }

  handleNotificationMessageChanged = (event: ChangeEvent<EventTarget>) => {
    const { target } = event;

    if (target instanceof HTMLInputElement) {
      const notificationMessage = target.value;
      this.setState({ notificationMessage, hasChanges: true });
    }
  };

  handleOnSave = () => {
    this.props.setNotificationSettingData({
      notificationMessage: this.state.notificationMessage,
      showNotificationMessage: this.state.showNotificationMessage,
    });
    this.setState({ hasChanges: false });
  };

  handleEnableNotificationBanner = () => {
    this.setState({ showNotificationMessage: !this.state.showNotificationMessage, hasChanges: true });
  };

  renderBody() {
    return (
      <div>
        <SDSInput
          labelTitle={getMessageFromId('banner-message')}
          value={this.state.notificationMessage}
          onChange={this.handleNotificationMessageChanged}
          data-test="snapAdmin.notificationBannerPage.input"
        />
        <div className={style.buttonContainer}>
          <SDSButton
            type={ButtonType.PRIMARY}
            inlineIcon={check}
            disabled={!this.state.hasChanges}
            onClick={this.handleOnSave}
          >
            {getMessageFromId('save-changes')}
          </SDSButton>
          <span className={style.checkbox}>
            <SDSCheckbox
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              id="notification-enabled-checkbox"
              checked={this.state.showNotificationMessage}
              onChange={this.handleEnableNotificationBanner}
            />
          </span>
          <span className={style.checkboxText}>{getMessageFromId('enable-banner-message')}</span>
        </div>
        <div>
          <div>
            <span className={style.bannerDocUrlText}>
              <FormattedMessage
                id="banner-message-doc-prompt"
                description="Text Instructing the superadmin to update the google doc of banner messages"
                defaultMessage="If you are enabling/changing the banner message please update this doc:"
              />
            </span>
          </div>
          <div>
            <a href={NotificationBannerPage.bannerInfoDocUrl} target="_blank">
              <span className={style.bannerDocUrlText}>{NotificationBannerPage.bannerInfoDocUrl}</span>
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={style.container}>
        <div className={style.table}>
          <PageHeader>{NotificationBannerPage.title}</PageHeader>
          <div className={style.form}>{this.renderBody()}</div>
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(NotificationBannerPage);
