import React, { memo, MouseEvent, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';

import * as localRoutes from 'utils/apis/localRoutes';
import { constructURL } from 'utils/linkUtils';

import style from './routerLinks.scss';

import { EditionID } from 'types/editionID';
import { PublisherID } from 'types/publishers';

/**
 * In this file we store the links to pages.
 * This file is an equivalent of routerActions.
 * For goToPublisherStoryEditor -> we have GoToPublisherStoryEditorLink
 */

type CommonLinkProps = {
  overwriteHistory: boolean;
  children: ReactNode;
};

type RouterLinkProps = {
  url: string;
  external?: boolean;
} & CommonLinkProps;

const RouterLink = memo(({ url, overwriteHistory, children, external }: RouterLinkProps) => {
  const preventDefault = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  }, []);
  return (
    <Link
      target={external ? '_blank' : '_self'}
      to={url}
      replace={overwriteHistory}
      onClick={preventDefault}
      className={style.routerLink}
    >
      {children}
    </Link>
  );
});

type GoToPublisherEditorLinkProps = {
  publisherId: PublisherID;
  editionId: EditionID;
  creatorHostUsername: string | null;
} & CommonLinkProps;

export const GoToPublisherEditorLink = memo(
  ({ publisherId, editionId, overwriteHistory, creatorHostUsername, children }: GoToPublisherEditorLinkProps) => {
    const url = constructURL(localRoutes.creatorEditor.edition.path, {
      params: {
        editionId,
        hostUsername: creatorHostUsername,
      },
    });

    return (
      <RouterLink url={url} overwriteHistory={overwriteHistory}>
        {children}
      </RouterLink>
    );
  }
);
