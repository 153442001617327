import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { AgeGate, PT_TIME_ZONE } from 'config/constants';
import { printDuration } from 'utils/numberFormatter';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';

import style from './AnalyticsContentInfo.scss';

import type { StoryMetricResults } from 'types/analytics';
import { StoryAnalyticsResult } from 'types/analytics';
import type { Edition } from 'types/editions';

export type EditionData = {
  firstLiveDate: moment.Moment;
  ageGate: boolean | null;
};
type Props = {
  title: string | React.ReactElement | undefined | null;
  subtitle?: string | undefined | null;
  snapCount: number;
  // If provided it will display duration instead of snap count.
  videoDurationMs?: number;
  tileCount: number;
  edition?: EditionData | Edition | undefined | null;

  editionMetrics: StoryMetricResults | StoryAnalyticsResult | undefined | null;
  reachAffectedByModeration: boolean;
  isAnalyticsV2Enabled?: boolean;
};
export const storyReachAffectedByModeration = (
  <FormattedMessage
    id="visibility-story-affected"
    defaultMessage="The visibility was affected by policy violation"
    description="Appears when the story has been flagged by moderators at least once in its life cycle"
  />
);
export class AnalyticsContentInfo extends React.Component<Props> {
  getDateTimeValue(time: moment.Moment) {
    const timestamp = time.unix() * 1000;
    return {
      date: (
        <span className={(style as any).timestamp}>
          <FormattedDate value={timestamp} timeZone={PT_TIME_ZONE} />
        </span>
      ),
      time: (
        <span className={(style as any).timestamp}>
          <FormattedTime
            value={timestamp}
            hour="numeric"
            minute="numeric"
            timeZone={PT_TIME_ZONE}
            timeZoneName="short"
          />
        </span>
      ),
    };
  }

  renderMainSubtitle = () => {
    const mainSubtitleContainer = (
      <div className={style.storyMainSubtitle} data-test="AnalyticsContentInfo.MainSubtitle">
        {this.props.subtitle}
      </div>
    );
    return this.props.subtitle ? mainSubtitleContainer : null;
  };

  renderSubtitle() {
    const { videoDurationMs, snapCount, tileCount } = this.props;

    if (this.props.isAnalyticsV2Enabled) {
      return (
        <FormattedMessage
          id="analytics-edition-snaps"
          defaultMessage="{snapCount} Snaps"
          description="Show the user the number of snaps in an edition"
          values={{ snapCount }}
        />
      );
    }
    if (videoDurationMs === undefined) {
      return (
        <FormattedMessage
          id="analytics-edition-snaps-and-tiles"
          defaultMessage="{snapCount} Snaps, {tileCount, plural, one {1 Tile} other {# Tiles}}"
          description="Show the user the number of snaps and tiles in an edition"
          values={{ snapCount, tileCount }}
        />
      );
    }
    const duration = printDuration(videoDurationMs);
    return (
      <FormattedMessage
        id="analytics-edition-duration-and-tiles"
        defaultMessage="{duration} Duration, {tileCount, plural, one {1 Tile} other {# Tiles}}"
        description="Show the user video duration and number of tiles in an edition"
        values={{ duration, tileCount }}
      />
    );
  }

  render() {
    const { edition, editionMetrics } = this.props;
    if (!edition || !editionMetrics) {
      return null;
    }
    const publishedAt = moment(edition.firstLiveDate);
    const { ageGate } = edition;
    return (
      <div className={style.storyInfo}>
        <div className={style.storyTitle}>
          {this.props.title}
          <HelpCenterLink
            destination={HelpCenterDestination.ANALYTICS_GLOSSARY}
            data-test="AnalyticsContentInfo.helpCenterLink.glossary"
          />
        </div>
        {this.renderMainSubtitle()}
        <div className={style.storySubtitle}>{this.renderSubtitle()}</div>
        {publishedAt && (
          <div className={style.storySubtitle}>
            <FormattedMessage
              id="analytics-edition-published-time"
              defaultMessage="Published {date} @ {time} ({timeAgo})"
              description="Show the user when the edition was last published"
              values={{
                ...this.getDateTimeValue(publishedAt),
                timeAgo: publishedAt.fromNow(),
              }}
            />
          </div>
        )}

        {this.props.reachAffectedByModeration && (
          <div
            className={classNames(style.storySubtitle, style.warning)}
            data-test="AnalyticsContentInfo.reachAffectedByModeration"
          >
            {storyReachAffectedByModeration}
          </div>
        )}
        {ageGate === AgeGate.EXPLICIT && (
          <div className={style.storySubtitle}>
            <FormattedMessage
              id="analytics-edition-content-restricted"
              defaultMessage="This content is age gated (over 18 access only)"
              description="Show the user when the edition has explicit content"
            />
          </div>
        )}
      </div>
    );
  }
}
export default AnalyticsContentInfo;
