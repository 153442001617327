import { gql } from '@apollo/client';

import { BoltContentReference } from 'src/types/media';
import { VideoInfo } from 'src/utils/media/mediaValidation';

// https://livegrep.sc-corp.net/view/Snapchat/content-platform/story-studio-proxy/graphql-schema/src/main/resources/graphql/web-uploader.graphqls#L1
export const POST_STORY_SNAP = gql`
  mutation PostStorySnap($input: PostStorySnapInput) {
    postStorySnap(input: $input) {
      snapId
    }
  }
`;

export type Media = {
  height: number;
  width: number;
  durationMs: number;
  contentObjectBase64: string;
  ivBase64: string;
  keyBase64: string;
  hasSound: boolean;
  mediaCaptureTimestampMs: number;
  topics: string[];
  description: string;
  mediaType: MediaType;
};

export enum MediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export type PostingTarget = {
  storyType: StoryType;
  businessProfileId: string;
};

export enum StoryType {
  SPOTLIGHT_STORY = 'SPOTLIGHT_STORY',
  PUBLIC_STORY = 'PUBLIC_STORY',
}

export interface PostStorySnapInput {
  media: Media[];
  saveToProfile: boolean;
  locale: string;
  postingTargets: PostingTarget[];
  goLiveTsMs?: number;
  disableRemix?: boolean;
}

export interface SnapData {
  input: PostStorySnapInput;
}

export type PostStorySnapMethodParams = {
  saveToProfile?: boolean;
  videoMetadata?: VideoInfo;
  boltContentReference?: BoltContentReference;
  description?: string;
  topics?: string[];
  goLiveTsMs?: number;
  disableRemix?: boolean;
};
export interface PostToStorySnapResult {
  post: (params: PostStorySnapMethodParams) => Promise<void>;
}
