import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { intlConnect } from 'utils/connectUtils';

import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SharingList from 'views/common/components/SharingList/SharingList';

import type { Edition } from 'types/editions';
import type { State } from 'types/rootState';

type ProvidedProps = {
  hideModal: (a: string) => void;
  modalId: string;
};

type StateProps = {
  activeEdition: Edition;
};

type Props = StateProps & ProvidedProps;

function mapStateToProps(state: State, ownProps: Props) {
  return {
    activeEdition: publisherStoryEditorSelectors.getActiveEdition(state),
  };
}

export class StorySnapcodeSharingModal extends React.Component<Props> {
  onClose = () => {
    this.props.hideModal(this.props.modalId);
  };

  render() {
    return (
      <SDSCustomModal
        visible
        onClose={this.onClose}
        title={
          <FormattedMessage
            id="story-snapcode-sharing"
            defaultMessage="Sharing"
            description="The title of the Sharing Snapcode modal"
          />
        }
        footer={null}
        isBodyCentered
      >
        <SharingList edition={this.props.activeEdition} expandedSnapcodeItem />
      </SDSCustomModal>
    );
  }
}

export default intlConnect(mapStateToProps, null)(StorySnapcodeSharingModal);
