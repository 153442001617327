import React from 'react';

import { getSnapPreviewById } from 'state/previews/selectors/previewsSelectors';

import { CrossOrigin } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getImagePreviewUrl, getVideoAssetPosterUrl } from 'utils/media/assetUtils';

import AnalyticsPollToolTip from 'views/analytics/components/AnalyticsPollToolTip/AnalyticsPollToolTip';

import style from './AnalyticsTooltip.scss';

import type { Poll, PollQuestion } from 'types/analytics';
import { SnapId } from 'types/common';
import { State } from 'types/rootState';

type MetricRow = {
  metricName: string | undefined | null;
  metricValue: string | undefined | null;
};
type OwnProps = {
  title: string;
  snapId?: SnapId;
  mediaId?: string;
  mediaType?: 'video' | 'image';
  metricRows?: MetricRow[];
  pollMetadata?: Poll | PollQuestion;
  generatedPreviewUrl?: string;
};

const mapStateToProps = (state: State, props: OwnProps) => {
  return {
    previewUrl: props.snapId ? getSnapPreviewById(state)(props.snapId) : null,
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;
export class AnalyticsTooltip extends React.Component<Props> {
  renderSnapPreviewIfExists = () => {
    const { previewUrl, mediaId, mediaType } = this.props;
    let imageSource: string | null = null;
    // Use preview url if exists, otherwise try generating preview.
    if (previewUrl) {
      imageSource = previewUrl;
    } else if (mediaId && mediaType === 'image') {
      imageSource = getImagePreviewUrl(mediaId);
    } else if (mediaId && mediaType === 'video') {
      imageSource = getVideoAssetPosterUrl(mediaId);
    } else if (this.props.generatedPreviewUrl) {
      imageSource = this.props.generatedPreviewUrl;
    }

    if (!imageSource) {
      return null;
    }
    return (
      <div className={style.imageTooltipPreviewRow} data-test="AnalyticsTooltip.Preview">
        <img src={imageSource} crossOrigin={CrossOrigin.USE_CREDENTIALS} alt="snapPreview" />
      </div>
    );
  };

  renderPollMetadataIfExists = () => {
    if (this.props.pollMetadata) {
      return <AnalyticsPollToolTip pollMetadata={this.props.pollMetadata} />;
    }
    return null;
  };

  render() {
    return (
      <div className={style.tooltipContainer}>
        <h4>{this.props.title}</h4>
        {this.props.metricRows &&
          this.props.metricRows.map(metric => {
            const name = metric.metricName || '';
            const value = metric.metricValue || '';
            return (
              <div className={style.metricContainer} key={metric.metricName}>
                <span className={style.metricName}>{`${name}: `}</span>
                <span className={(style as any).metricValue}>{`${value}`}</span>
              </div>
            );
          })}
        {this.renderSnapPreviewIfExists()}
        {this.renderPollMetadataIfExists()}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, {})(AnalyticsTooltip);
