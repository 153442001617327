import { gql } from '@apollo/client';

import { StorySnapType } from 'gql/queries/spotlight/storySnaps';

export const DELETE_STORY_SNAPS = gql`
  mutation DeleteStorySnaps($input: DeleteStorySnapInput) {
    deleteStorySnap(input: $input) {
      __typename
      ... on DeleteStorySnapSuccess {
        deletedSnapIds
      }
      ... on DeleteStorySnapError {
        message
      }
    }
  }
`;

export type DeleteStorySnapInput = {
  hostUserId: string;
  type: StorySnapType;
  snapIds: string[]; // Batch delete is not supported yet, so will throw an error if this array has more than one snap ID.
};

type DeleteStorySnapSuccess = {
  deletedSnapIds: string[];
};

type DeleteStorySnapError = {
  message: string;
};

export type DeleteStorySnapResponse = DeleteStorySnapSuccess | DeleteStorySnapError;
