import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';

import { hamburger, plus, minus } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';

import style from './SortableExpandableListItem.scss';

const makeDragSource = DragSource;
const makeDropTarget = DropTarget;
// listItemSource and listItemTarget code repurposed from http://gaearon.github.io/react-dnd/examples-sortable-simple.html
export const listItemSource = {
  beginDrag(props: any) {
    return {
      item: props.item,
      id: props.index,
      index: props.index,
      listId: props.listId,
    };
  },
  endDrag(props: any, monitor: any) {
    if (props.saveState) {
      props.saveState();
    }
  },
};
export function makeListItemTarget() {
  return {
    hover(props: any, monitor: any, component: any) {
      const dragItem = monitor.getItem();
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Determine rectangle on screen
      const hoverBoundingRect = (findDOMNode(component) as any).getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.updateState(dragItem.item, hoverIndex, props.listId);
    },
    canDrop(props: any) {
      return true;
    },
    drop(target: any, monitor: any) {
      return target.id;
    },
  };
}
type Props = {
  listId?: string;
  updateState: (...args: any[]) => any;
  saveState?: (...args: any[]) => any;
  moveItem?: (...args: any[]) => any;
  item?: React.ReactNode;
  header: React.ReactElement;
  sortable?: boolean;
  expandable?: boolean;
  connectDragSource: (...args: any[]) => any;
  connectDropTarget: (...args: any[]) => any;
  connectDragPreview: (...args: any[]) => any;
  isDragging?: boolean;
  saveExpanded?: (...args: any[]) => any;
  expanded?: boolean;
  index?: number;
  headerClassName?: string;
};
type State = any;
export class SortableExpandableListItem extends React.Component<Props, State> {
  state = {
    expanded: this.props.expanded ? this.props.expanded : false,
  };

  toggleExpanded = () => {
    const expandStatus = !this.state.expanded;
    this.setState({ expanded: expandStatus });
    if (this.props.saveExpanded) {
      this.props.saveExpanded(this.props.index, expandStatus);
    }
  };

  renderExpandIcon() {
    if (!this.props.expandable || !this.props.item) {
      return null;
    }
    if (this.state.expanded) {
      return (
        <SDSButton
          type={ButtonType.WHITE}
          shape={ButtonShape.CIRCLE}
          inlineIcon={minus}
          onClick={this.toggleExpanded}
          data-test="common.sortableExpandableList.item.minus.button"
        />
      );
    }
    return (
      <SDSButton
        type={ButtonType.WHITE}
        shape={ButtonShape.CIRCLE}
        inlineIcon={plus}
        onClick={this.toggleExpanded}
        data-test="common.sortableExpandableList.item.plus.button"
      />
    );
  }

  renderExpandedContent() {
    if (!this.props.expandable || !this.props.item) {
      return null;
    }
    if (this.state.expanded) {
      return <div>{this.props.item}</div>;
    }
    return null;
  }

  renderSortableHamburger() {
    if (this.props.sortable) {
      return (
        <div data-test="common.sortableExpandableListItem.hamburger">
          <Icon inlineIcon={hamburger} className={style.bars} />
        </div>
      );
    }
    return null;
  }

  render() {
    const { connectDragSource, connectDropTarget, connectDragPreview } = this.props;
    const opacity = this.props.isDragging ? 0.4 : 1;
    const isPlaceholder = get(this.props, 'header.props.placeholderOnly', false);
    const rootClassNames = classNames(style.root, {
      [style.placeholderOnly]: isPlaceholder,
    });
    return connectDragPreview(
      connectDropTarget(
        <div className={rootClassNames} style={{ opacity }}>
          <div className={classNames(style.headerInfo, this.props.headerClassName)}>
            {connectDragSource(this.renderSortableHamburger())}
            <div className={style.headerContent}>
              <div className={(style as any).header}>{this.props.header}</div>
            </div>
            {this.renderExpandIcon()}
          </div>
          {this.renderExpandedContent()}
        </div>
      )
    );
  }
}
// The `type` argument provided to DropTarget() and DragSource() here is used to stop
// draggable items in separate lists from being dragged from one list to the other.
//
// It essentially 'scopes' the draggability to only work with other list items that
// share the same list type. It's also useful with nested lists, to prevent items in
// the child list from conflicting with those in the parent list whilst being dragged.
export default (type: any) => {
  const ExpandableListItemDropTarget = makeDropTarget(type, makeListItemTarget(), connect => ({
    connectDropTarget: connect.dropTarget(),
  }))(SortableExpandableListItem);
  return makeDragSource(type, listItemSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))(ExpandableListItemDropTarget);
};
