import React, { ReactNode } from 'react';
import type { ChangeEvent } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import * as extraRouterSelectors from 'state/router/selectors/extraRouterSelectors';
import * as supportTicketsActions from 'state/supportTickets/actions/supportTicketsActions';
import * as supportTicketsSelectors from 'state/supportTickets/selectors/supportTicketsSelectors';

import { trash, message } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSTextArea from 'views/common/components/SDSTextArea/SDSTextArea';
import SDSTooltip from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import PageHeader from 'views/common/containers/PageHeader/PageHeader';
import { getSupportCategoryOptions } from 'views/supportTickets/SupportTicketsFormOptions';
import type { PriorityEnum, CategoryEnum, SupportCategoryOption } from 'views/supportTickets/SupportTicketsFormOptions';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/conversation.svg.inline'... Remove this comment to see the full error message
import conversationIcon from 'icons/conversation.svg.inline';

import style from './SupportTicketForm.scss';

import type { Context } from 'types/common';
import type { Publisher } from 'types/publishers';
import type { State } from 'types/rootState';

const weGotYourBackTitle = (
  <FormattedMessage
    id="we-got-your-back"
    description="Title of a section allowing users to raise Support tickets"
    defaultMessage={"We've got your back!"}
  />
);

const supportTicketPublishingDisabledInfoMsg = (
  <FormattedMessage
    id="support-info-publishing-disabled-msg"
    description="Description of a section explaining to users how to raise Support tickets"
    defaultMessage={"Can't publish? It looks like your account is not enabled for publishing yet."} //eslint-disable-line
  />
);

const supportTicketInfoMsg1 = (
  <FormattedMessage
    id="support-info-msg-1"
    description="Description of a section explaining to users how to raise Support tickets"
    defaultMessage="We're here to help!"
  />
);

const supportTicketInfoMsg2 = (
  <FormattedMessage
    id="support-info-msg-2"
    description="Description of a section explaining to users how to raise Support tickets"
    defaultMessage="Got an idea for how to make our tools better?"
  />
);

const supportTicketInfoMsg3 = (
  <FormattedMessage
    id="support-info-msg-3"
    description="Description of a section explaining to users how to raise Support tickets"
    defaultMessage={"Got a question that you can't find in our help center?"}
  />
);

const supportTicketInfoMsg4 = (
  <FormattedMessage
    id="support-info-msg-4"
    description="Description of a section explaining to users how to raise Support tickets"
    defaultMessage={
      "We're ready to hear you about it and help you out. Please fill out the form below and we'll get back to you as soon as we can."
    } //eslint-disable-line
    values={{ email: <strong>publish-support@snapchat.com</strong> }}
  />
);

const supportTicketInfoMsg5 = (
  <FormattedMessage
    id="support-info-msg-5"
    description="Description of a section explaining to users how to raise Support tickets in preferred language"
    defaultMessage={
      'We offer support in English, French and Arabic. Please reach out to us in your preferred language.'
    }
  />
);

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="support-what-do-you-need-help"
      description="Placeholder for asking the user what kind of help is necessary"
      defaultMessage="What do you need help with?"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="how-urgent"
      description="Placeholder asking the user to choose how urgent is the problem they are having"
      defaultMessage="How urgent is it?"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="zendesk-dropdown-ads-monetization"
      description="Zendesk dropdown for ads & monetization option"
      defaultMessage="Ads & Monetization"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="zendesk-dropdown-feedback"
      description="Zendesk dropdown for feedback option"
      defaultMessage="Feedback"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="zendesk-dropdown-onboarding"
      description="Zendesk dropdown for on-boarding option"
      defaultMessage="Onboarding"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="zendesk-dropdown-story-creation"
      description="Zendesk dropdown for story-creation option"
      defaultMessage="Story Creation"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="zendesk-dropdown-profile-settings"
      description="Zendesk dropdown for profile settings option"
      defaultMessage="Profile Settings"
    />
  ),
  params: [],
});

const submittingTicket = (
  <FormattedMessage
    id="support-info-submitting"
    description="Button caption indicating the support ticket is being submitted"
    defaultMessage="Submitting ticket"
  />
);

const screenshotAttachment = (
  <FormattedMessage
    id="support-info-screenshot-attachment"
    description="Caption indicating the screenshot to attach to the support ticket"
    defaultMessage="Screenshot to attach"
  />
);

type StateProps = {
  category: CategoryEnum;
  description: string;
  isSendButtonEnabled: boolean;
  sendButtonTooltip: ReactNode;
  isSubmittingTicket: boolean;
  priority: PriorityEnum;
  subject: string;
  url: string;
  fallbackUrl: string;
  screenshot: {
    blobBase64: string;
  };
  publisher: Publisher;
};

type DispatchProps = {
  sendTicket: typeof supportTicketsActions.sendTicket;
  setCategory: typeof supportTicketsActions.setCategory;
  setDescription: typeof supportTicketsActions.setDescription;
  setPriority: typeof supportTicketsActions.setPriority;
  setSubject: typeof supportTicketsActions.setSubject;
  setURL: typeof supportTicketsActions.setURL;
  takeScreenshot: typeof supportTicketsActions.takeScreenshot;
  clearScreenshot: typeof supportTicketsActions.clearScreenshot;
};

type Props = StateProps & DispatchProps;

export const mapStateToProps = (state: State) => {
  const { isSendButtonEnabled, sendButtonTooltip } = supportTicketsSelectors.getIsSendButtonEnabledAndTooltip(state);

  return {
    description: supportTicketsSelectors.getDescription(state),
    isSendButtonEnabled,
    sendButtonTooltip,
    isSubmittingTicket: supportTicketsSelectors.isSubmittingTicket(state) > 0,
    category: supportTicketsSelectors.getCategory(state),
    subject: supportTicketsSelectors.getSubject(state),
    url: supportTicketsSelectors.getURL(state),
    screenshot: supportTicketsSelectors.getScreenshot(state),
    fallbackUrl: extraRouterSelectors.getPreviousLocationPathFull(state),
    publisher: getActivePublisherDetails(state),
  };
};

const mapDispatchToProps = {
  sendTicket: supportTicketsActions.sendTicket,
  setDescription: supportTicketsActions.setDescription,
  setCategory: supportTicketsActions.setCategory,
  setPriority: supportTicketsActions.setPriority,
  setSubject: supportTicketsActions.setSubject,
  setURL: supportTicketsActions.setURL,
  clearScreenshot: supportTicketsActions.clearScreenshot,
};

export class SupportTicketForm extends React.Component<Props> {
  static contextTypes: Context = {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'IntlShape' is not assignable to type 'IntlCo... Remove this comment to see the full error message
    intl: intlShape,
  };

  setFallbackUrlIfNecessary = () => {
    const url = this.props.url;
    if (!url || url.length === 0) {
      this.props.setURL(this.props.fallbackUrl);
    }
  };

  setPriority = (value: string) => this.props.setPriority(value);

  setCategory = (value: string) => this.props.setCategory(value);

  handleSetDescription = (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    this.props.setDescription(value);
  };

  handleSetUrl = (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    this.props.setURL(value);
  };

  handleSetSubject = (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    this.props.setSubject(value);
  };

  getCategoryTitle = (allCategories: SupportCategoryOption[]) => {
    const whatDoYouNeedHelpString: string = getLocalisedMessageFromId(this.context, 'support-what-do-you-need-help');
    if (!this.props.category) {
      return whatDoYouNeedHelpString;
    }

    const categoryOption: SupportCategoryOption | undefined = allCategories.find(
      category => category.value === this.props.category
    );
    if (categoryOption?.label === null) {
      return whatDoYouNeedHelpString;
    }
    return categoryOption ? categoryOption.label : whatDoYouNeedHelpString;
  };

  componentDidMount() {
    this.setFallbackUrlIfNecessary();
  }

  componentDidUpdate(prevProps: Props): void {
    this.setFallbackUrlIfNecessary();
  }

  removeScreenshot = () => {
    grapheneUtils.incrementCounter('SupportTicketForm', { removeScreenshot: 'true' });
    this.props.clearScreenshot();
  };

  renderScreenshot() {
    const screenshotBase64 = this.props.screenshot && this.props.screenshot.blobBase64;
    if (!screenshotBase64) {
      return null;
    }
    return (
      <div className={style.screenshot}>
        <div className={style.screenshotTitle}>
          {screenshotAttachment}
          <SDSButton
            type={ButtonType.SECONDARY}
            onClick={this.removeScreenshot}
            shape={ButtonShape.CIRCLE}
            inlineIcon={trash}
          />
        </div>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'screenshotImage' does not exist on type ... Remove this comment to see the full error message */}
        <img className={style.screenshotImage} src={screenshotBase64} alt="screenshot" />
      </div>
    );
  }

  render() {
    const allCategories = getSupportCategoryOptions(this.context);

    return (
      <div>
        <PageHeader>
          <Icon inlineIcon={message} className={style.pageHeaderIcon} />
          {getMessageFromId('contact-title')}
        </PageHeader>
        <div className={style.root}>
          <div className={style.centered}>
            <div className={style.title}>{weGotYourBackTitle}</div>
            <InlineSVG
              className={style.icon}
              data-test="authMigration.explanation.icon"
              src={conversationIcon}
              element="span"
            />
            <div className={style.content}>
              <div>{!this.props.publisher?.publishingEnabled && supportTicketPublishingDisabledInfoMsg}</div>
              <div>{supportTicketInfoMsg1}</div>
              <div>{supportTicketInfoMsg2}</div>
              <div>{supportTicketInfoMsg3}</div>
              <div>{supportTicketInfoMsg4}</div>
              <div>{supportTicketInfoMsg5}</div>
            </div>

            <div className={style.inputFields}>
              <SDSDropdown
                disableClear
                type={DropdownType.GREY}
                size={DropdownSize.MEDIUM}
                value={this.getCategoryTitle(allCategories)}
                onChange={this.setCategory}
                data-test="supportTicket.category.dropdown"
              >
                {createSDSDropdownOptions(allCategories)}
              </SDSDropdown>
              <SDSInput
                data-test="supportTicket.subject.textField"
                onChange={this.handleSetSubject}
                placeholder={getLocalisedMessageFromId(this.context, 'sum-up-request')}
                maxLength={100}
                value={this.props.subject}
              />
              <SDSTextArea
                data-test="supportTicket.description.textField"
                placeholder={getLocalisedMessageFromId(this.context, 'describe-a-problem')}
                onChange={this.handleSetDescription}
                value={this.props.description}
              />
              <SDSTextArea
                data-test="supportTicket.url.textField"
                placeholder={getLocalisedMessageFromId(this.context, 'send-relevant-url-mandatory')}
                onChange={this.handleSetUrl}
                value={this.props.url}
              />
            </div>
            <div>
              <SDSTooltip title={this.props.sendButtonTooltip}>
                <SDSButton
                  type={ButtonType.PRIMARY}
                  disabled={!this.props.isSendButtonEnabled || this.props.isSubmittingTicket}
                  onClick={this.props.sendTicket}
                >
                  <span className={style.buttonMessage}>
                    {this.props.isSubmittingTicket ? submittingTicket : getMessageFromId('send-button-modal')}
                    {this.props.isSubmittingTicket ? <SpinnerIcon className={style.spinner} /> : null}
                  </span>
                </SDSButton>
              </SDSTooltip>
            </div>
            {this.renderScreenshot()}
          </div>
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SupportTicketForm);
