import React from 'react';

import { SpotlightKPI } from 'views/analytics/components/SpotlightKPI/SpotlightKPI';
import {
  rangeOverAvg,
  SPOTLIGHT_ANALYTICS_KPI__CONFIG,
  AnalyticsReportRange,
  spotlightMetricMap,
} from 'views/analytics/utils/kpiConfigs';
import { KPIMetricInput } from 'views/common/components/KPIWidget/KPIWidget';
import SDSRow from 'views/common/components/SDSRow/SDSRow';

import style from './SpotlightKPIs.scss';

import { SpotlightMetric, SpotlightSummary } from 'types/analytics';

type Props = {
  activeMetric: SpotlightMetric | null;
  spotlightAnalyticsSummary?: SpotlightSummary;
  setActiveMetric: (metric: SpotlightMetric) => void;
  reportRange: AnalyticsReportRange;
  isLoading: boolean;
};

export function SpotlightKPIs(props: Props) {
  const { activeMetric, spotlightAnalyticsSummary, setActiveMetric, reportRange, isLoading } = props;

  const getFormattedSpotlightKPI = (spotlightKPI: KPIMetricInput<SpotlightMetric>) => {
    return {
      ...spotlightKPI,
      previousValueDescription: rangeOverAvg(reportRange),
    };
  };

  const renderSpotlightKPIs = () => {
    const spotlightKPIs = SPOTLIGHT_ANALYTICS_KPI__CONFIG.map(spotlightKPI => {
      const formattedSpotlightKPI = getFormattedSpotlightKPI(spotlightKPI);

      return (
        <SpotlightKPI
          key={formattedSpotlightKPI.metricId}
          metric={formattedSpotlightKPI}
          value={
            spotlightAnalyticsSummary?.[spotlightMetricMap[formattedSpotlightKPI.metricId].currentMetrics[reportRange]]
          }
          previousValue={
            spotlightAnalyticsSummary?.[spotlightMetricMap[formattedSpotlightKPI.metricId].previousMetrics[reportRange]]
          }
          isActive={activeMetric === formattedSpotlightKPI.metricId}
          onMetricClicked={setActiveMetric}
          isLoading={isLoading}
        />
      );
    });
    return <SDSRow>{spotlightKPIs}</SDSRow>;
  };

  return <div className={style.content}>{renderSpotlightKPIs()}</div>;
}
