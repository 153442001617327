/* eslint-disable camelcase */
import { assign, get, head, isEqual, omit } from 'lodash';
import moment from 'moment-timezone';
import type { ChangeEvent } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getAssociatedPublisher, getAssociatedUserToken } from 'state/auth/selectors/authSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as navLayoutSelectors from 'state/navLayout/selectors/navLayoutSelectors';
import * as publisherSettingsActions from 'state/publisherSettings/actions/publisherSettingsActions';
import { pollForSnapcodeUpdate } from 'state/publisherSettings/actions/publisherSettingsActions';
import { setPublisherDetailsWithGraphQLResponse } from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';
import { loadPublisherSnapcodes } from 'state/snapcodes/actions/snapcodesActions';
import { getDecoratedSnapcodesPerPublisherId } from 'state/snapcodes/selectors/snapcodesSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';
import { isHostUserEnabledForPublisher } from 'state/user/selectors/userSelectors';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';

import { DropzoneType, LogoDisplay, ORIGINAL_KEYS, StageType, UploadPurpose } from 'config/constants';
import withExternalUpdatePublisherMutation from 'gql/hocs/withExternalUserUpdatePublisherMutation';
import { UpdatePublisherMutationResult } from 'gql/hooks/useUpdatePublisherMutation';
import { UpdateUserPropertiesMutationResult } from 'gql/hooks/useUpdateUserPropertiesMutation';
import { UpdatePublisherResponseDetails } from 'gql/types/updatePublisherTypes';
import { formatStagedPublisherSettingsData } from 'gql/utils/graphQLSettingsUtils';
import { help } from 'icons/SDS/allIcons';
import { isDefaultNumSnapsAllowed } from 'utils/adSnapIndexUtils';
import { intlConnect } from 'utils/connectUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import { openInNewWindow } from 'utils/locationUtils';
import { formatPublisherProperties } from 'utils/publisherSettings/publisherSettingsUtils';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSInput, { InputType } from 'views/common/components/SDSInput/SDSInput';
import { validateMinMaxValue } from 'views/common/components/SDSInput/inputValidationUtils';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSPanel from 'views/common/components/SDSPanel/SDSPanel';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';
import SDSTimePicker from 'views/common/components/SDSTimePicker/SDSTimePicker';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import PublisherDetailMediaButtonRow from 'views/onboarding/components/PublisherDetailMediaButtonRow/PublisherDetailMediaButtonRow';
import PublisherDetailTagRow from 'views/onboarding/components/PublisherDetailTagRow/PublisherDetailTagRow';
import PublisherProfilePreview from 'views/onboarding/components/PublisherProfilePreview/PublisherProfilePreview';
import PublisherSnapcodePreview from 'views/onboarding/components/PublisherSnapcodePreview/PublisherSnapcodePreview';
import UserAssociationView from 'views/onboarding/components/UserAssociationView/UserAssociationView';
import OrganisationInput from 'views/onboarding/containers/SettingsView/components/OrganisationInput/OrganisationInput';
import SaveSettingsButton from 'views/onboarding/containers/SettingsView/save/SaveSettingsButton';
import AdvancedStylingEditor from 'views/onboarding/containers/SettingsView/tabs/PublisherSettingsTab/AdvancedStylingEditor/AdvancedStylingEditor';
import SimpleStylingEditor from 'views/onboarding/containers/SettingsView/tabs/PublisherSettingsTab/SimpleStylingEditor/SimpleStylingEditor';

import style from './PublisherSettingsTab.scss';

import { Claim } from 'types/permissions';
import type { MediaLoading, TileLogoComponent } from 'types/publisherSettings';
import { PropertyKeys, PublisherValidation } from 'types/publisherSettings';
import { Publisher, PublisherID, TagMap, TierLevel } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';
import type { Show } from 'types/shows';
import { NEW_SHOW_TEMP_ID } from 'types/shows';

type ExternalProps = {
  publisherId: PublisherID;
  setTabHasActiveChanges?: (a?: boolean | null) => void;
};

type OwnProps = UpdatePublisherMutationResult & UpdateUserPropertiesMutationResult;

type State = {
  isSaving: boolean;
  isSnapcodeUpdating: boolean;
  isSimpleStylingMode: boolean;
  publisher: Publisher | null;
  publisherValidation: PublisherValidation | null;
  tileLogoComponents: TileLogoComponent[] | null;
  isMediaLoading: MediaLoading;
};

export const TARGET_SNAP_LENGHT_ERROR = (
  <FormattedMessage
    id="target-snap-lengt-field-error"
    description="Label that shows the target snap length"
    defaultMessage="Target snap length can be between 4 and 16 seconds"
  />
);

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="publisher-target-snap-length"
      description="When we create snaps from shots, what would be the target snap length"
      defaultMessage="Target Snap Length"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="publisher-target-snap-length-description"
      description="Description for the target snap length field"
      defaultMessage="The target snap length can be between 4 and 16s"
    />
  ),
  params: [],
});

const DEFAULT_NUM_SNAPS = '8';
const DEFAULT_TARGET_SNAP_LENGHT_MS = 7 * 1000;

export const mapStateToProps = (state: RootState, props: ExternalProps) => {
  const { publisherId } = props;
  const shows: Show[] | undefined | null = showsSelectors.getShows(state)(publisherId);
  const show = head(shows);
  const showId = get(show, 'id', NEW_SHOW_TEMP_ID);
  const publisherData = publishersSelectors.getPublisherDetailsDataById(state)(publisherId);
  const isShow = publishersSelectors.activePublisherIsShow(state);
  const getPrimaryLanguageMessage = publishersSelectors.getPrimaryLanguageMessage(state);
  const showData = stagesSelectors.getData(state)(showId);

  const associatedToken = getAssociatedUserToken(state);
  const associatedPublisher = getAssociatedPublisher(state);
  const isAssociatedPublisherDirty =
    !!associatedToken && associatedPublisher === get(publisherData, 'businessProfileId', null);

  return {
    isUpdatedProfilePreviewEnabled: featuresSelectors.isUpdatedProfilePreviewEnabled(state),
    isHostUserEnabled: isHostUserEnabledForPublisher(state)(publisherId),
    isShow,
    isAdvancedStyler: userSelectors.hasClaimForActivePublisher(state, Claim.ADVANCED_STYLER),
    publisher: publishersSelectors.getPublisherDetailsDataById(state)(publisherId),
    isSidebarVisible: navLayoutSelectors.isSidebarVisible(state),
    isPublisherSettingsEditor: userSelectors.hasClaimForActivePublisher(state, Claim.PUBLISHER_SETTINGS_EDITOR),
    tileLogoComponents: snapAdminSelectors.getTileLogoComponents(state),
    data: stagesSelectors.getData(state)(publisherId),
    showData,
    isShowDirty: stagesSelectors.isDirty(state)(showId),
    showId,
    isBitmojiContentEnabled: featuresSelectors.isBitmojiContentEnabled(state),
    getPrimaryLanguageMessage,
    isAssociatedPublisherDirty,
    isAdvancedCurationEnabled: featuresSelectors.isAdvancedCurationEnabled(state),
    isPublisherCommentsSettingEnabled: featuresSelectors.isPublisherCommentsSettingEnabled(state),
    isSingleAssetStoryEditorEnabled: featuresSelectors.isSingleAssetStoryEditorEnabled(state),
    snapcodes: getDecoratedSnapcodesPerPublisherId(state)(publisherId),
    isCustomDEAutoCreateTimeEnabled: featuresSelectors.isCustomDynamicEditionAutoCreateTimeEnabled(state),
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  updateHostAccountAndPublisherSnapcode: publisherSettingsActions.updateHostAccountAndPublisherSnapcode,
  stageData: stagesActions.stageData,
  updateProperties: stagesActions.updateProperties,
  discardData: stagesActions.discardData,
  resolveVideoUntilComplete: videoLibraryActions.resolveVideoUntilComplete,
  updatePublisherProperties: setPublisherDetailsWithGraphQLResponse,
  uploadAndClaimMedia: publisherSettingsActions.uploadAndClaimMedia,
  pollMediaStatusAndUpdate: publisherSettingsActions.pollForAssetBuildStatusBeforeUpdating,
  loadSnapcodes: loadPublisherSnapcodes,
  pollForSnapcodeUpdate,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps & OwnProps;

export class PublisherSettingsTab extends React.Component<Props, State> {
  static path = '/publisher/:publisherId/settings';

  state = {
    isSaving: false,
    isSnapcodeUpdating: false,
    isSimpleStylingMode: true,
    publisher: null,
    publisherValidation: {},
    tileLogoComponents: null,
    isMediaLoading: { squareHeroImage: false, horizontalIcon: false, squareIcon: false, tileLogos: false },
  };

  componentDidMount() {
    const { publisher, publisherId, tileLogoComponents, stageData, updateProperties, loadSnapcodes } = this.props;
    loadSnapcodes(publisherId);

    Promise.all([stageData(publisherId, StageType.PUBLISHER)])
      .then(() => {
        if (publisher) {
          const newProperties = formatPublisherProperties({ publisher, tileLogoComponents });
          updateProperties(this.props.publisherId, newProperties);
        }
      })
      .then(() => {
        this.setState({
          publisher: publisher || null,
          tileLogoComponents: tileLogoComponents || null,
        });
      });
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { publisher, tileLogoComponents } = prevState;
    const { updateProperties, publisherId } = nextProps;

    if (nextProps.publisher) {
      if (!isEqual(publisher, nextProps.publisher) || !isEqual(tileLogoComponents, nextProps.tileLogoComponents)) {
        const updatedProperties = formatPublisherProperties({
          publisher: nextProps.publisher,
          tileLogoComponents: nextProps.tileLogoComponents,
        });
        updateProperties(publisherId, updatedProperties);
      }
    }

    const settingsChanged = PublisherSettingsTab.settingsHaveChanged(nextProps);
    if (nextProps.setTabHasActiveChanges) {
      nextProps.setTabHasActiveChanges(settingsChanged);
    }

    return {
      publisher: nextProps.publisher || null,
      tileLogoComponents: nextProps.tileLogoComponents || null,
    };
  }

  static settingsHaveChanged(props: Props) {
    const { isShowDirty, isAssociatedPublisherDirty, data, publisher, tileLogoComponents } = props;
    if (!publisher) {
      return false;
    }
    const originalProperties = formatPublisherProperties({ publisher, tileLogoComponents });

    const areChanges = Object.values(PropertyKeys).some(value => {
      const newData = get(data, value);
      const oldData = get(originalProperties, value);
      if (typeof newData === 'object') {
        return Boolean(!isEqual(omit(newData, ORIGINAL_KEYS), omit(oldData, ORIGINAL_KEYS)));
      }
      return Boolean(!isEqual(newData, oldData));
    });
    return areChanges || isShowDirty || isAssociatedPublisherDirty;
  }

  static isValid(publisherValidation: PublisherValidation) {
    return !Object.values(publisherValidation).some(value => !value);
  }

  componentWillUnmount() {
    this.props.discardData(this.props.publisherId);
  }

  getFormControlPlaceHolder(): string {
    return getMessageFromId('form-control-placeholder');
  }

  handleChange = (property: string) => (value: unknown) => {
    this.props.updateProperties(this.props.publisherId, { [property]: value });
  };

  handleMediaChange = (property: PropertyKeys) => async (value: string | number | null | undefined) => {
    const resetMediaLoading = { squareHeroImage: false, horizontalIcon: false, squareIcon: false, tileLogos: false };
    const resetState = () => {
      this.setState({ isSaving: false, isMediaLoading: resetMediaLoading });
    };

    if (!this.state.isSaving) {
      this.setState({ isSaving: true });

      switch (property) {
        case PropertyKeys.SQUARE_HERO_IMAGE_BLOB:
          this.setState({ isMediaLoading: { ...resetMediaLoading, squareHeroImage: true } });
          break;
        case PropertyKeys.HORIZONTAL_ICON_BLOB:
          this.setState({ isMediaLoading: { ...resetMediaLoading, horizontalIcon: true } });
          break;
        case PropertyKeys.SQUARE_ICON_BLOB:
          this.setState({ isMediaLoading: { ...resetMediaLoading, squareIcon: true } });
          break;
        case PropertyKeys.TILE_LOGOS:
          this.setState({ isMediaLoading: { ...resetMediaLoading, tileLogos: true } });
          break;
        default:
          this.setState({ isMediaLoading: resetMediaLoading });
      }
      this.props.updateProperties(this.props.publisherId, { [property]: value });
      await this.props
        .uploadAndClaimMedia(this.props.publisherId, this.state.isSimpleStylingMode, property)
        .then(({ assetId, publisherData }) => {
          this.props.pollMediaStatusAndUpdate(assetId, this.props.publisherId, publisherData, resetState);
        });
    }
  };

  handleInputChange = (property: string) => (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    this.props.updateProperties(this.props.publisherId, { [property]: value });
  };

  handleTargetSnapLengthChanged = (property: string) => (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    const targetSnapLengthMs = 1000 * parseInt(value, 10);
    this.props.updateProperties(this.props.publisherId, { [property]: targetSnapLengthMs });
  };

  handleAutoApproveCommentsChanged = (value: boolean) => {
    this.props.updateProperties(this.props.publisherId, {
      [PropertyKeys.AUTO_APPROVE_COMMENTS]: value,
    });
  };

  handleDynamicEditionAutoCreateTimeChanged = (value: moment.Moment | null) => {
    if (value) {
      const hour = value.hour();
      const minute = value.minute();

      this.props.updateProperties(this.props.publisherId, {
        [PropertyKeys.DYNAMIC_EDITION_AUTO_CREATE_STORY_TIME]: {
          hour,
          minute,
        },
      });
    }
  };

  handleValidation = (property: string) => (value: any) => {
    const publisherValidation = { ...this.state.publisherValidation, ...{ [property]: value } };
    this.setState({ publisherValidation });
  };

  handleTagsChange = (value: TagMap) => {
    const oldTags: TagMap = get(this.props.data, PropertyKeys.TAGS) || {};
    const newTags = assign({}, oldTags, value);
    this.handleChange(PropertyKeys.TAGS)(newTags);
  };

  handleHorizontalIconChange = (value: string) => {
    this.handleMediaChange(PropertyKeys.HORIZONTAL_ICON_BLOB)(value);
  };

  handleSquareIconChange = (value: string) => {
    this.handleMediaChange(PropertyKeys.SQUARE_ICON_BLOB)(value);
  };

  handleTileLogoChange = (value: string) => {
    this.handleMediaChange(PropertyKeys.TILE_LOGOS)(value);
  };

  shouldUpdateSnapcode = () => {
    const { publisher, data } = this.props;
    if (!publisher) {
      return false;
    }

    const primaryColourHasChanged = Boolean(publisher.primaryColor !== get(data, PropertyKeys.PRIMARY_COLOR));
    const squareIconHasChanged = Boolean(
      publisher.defaultFilledIconId !== get(data, PropertyKeys.SQUARE_FILLED_ICON_ID)
    );
    const hasChanges = primaryColourHasChanged || squareIconHasChanged;
    const hasPrimaryColour = primaryColourHasChanged || Boolean(publisher.primaryColor);
    const hasSquareIcon = squareIconHasChanged || Boolean(publisher.defaultFilledIconId);

    return hasChanges && hasPrimaryColour && hasSquareIcon;
  };

  handleClick = async () => {
    if (this.settingsHaveChanged() && !this.state.isSaving && this.props.publisher) {
      const updateSnapcode = this.shouldUpdateSnapcode();

      this.setState({ isSaving: true });

      const businessProfileId = this.props.publisher.businessProfileId;
      const publisherData = await this.props.updateHostAccountAndPublisherSnapcode(
        this.props.publisherId,
        this.props.showId
      );

      const updatePublisherInputFields = formatStagedPublisherSettingsData(
        publisherData,
        this.props.publisherId,
        businessProfileId
      );

      const externalUpdatePublisherResponse = await this.props.updatePublisher({
        variables: { updatedFieldsInput: updatePublisherInputFields },
      });

      await this.props
        .updateUserProperties({
          variables: { publisherId: this.props.publisherId.toString(), value: publisherData.autoApproveComments },
        })
        .then((res: any) => {
          const data: UpdatePublisherResponseDetails = externalUpdatePublisherResponse.data.externalUserUpdatePublisher;
          const userProperties = res.data?.updateUserProperties?.value;
          // Update publisher redux store with new properties
          this.props.updatePublisherProperties(
            data,
            userProperties && { autoApproveComments: res.data?.updateUserProperties?.value }
          );
          if (updateSnapcode) {
            this.setState({ isSnapcodeUpdating: true });
            this.props.pollForSnapcodeUpdate(this.props.publisherId, () =>
              this.setState({ isSnapcodeUpdating: false })
            );
          }
        })
        .finally(() => this.setState({ isSaving: false }));
    }
  };

  handleDownload = () => {
    const url = 'https://storage.googleapis.com/discover-cms/onboarding/CreatorPlatformTemplate.psd';
    openInNewWindow(url);
  };

  toggleAdvancedMode = () => {
    this.setState({ isSimpleStylingMode: !this.state.isSimpleStylingMode });
  };

  settingsHaveChanged = () => PublisherSettingsTab.settingsHaveChanged(this.props);

  isValid = () => PublisherSettingsTab.isValid(this.state.publisherValidation);

  renderStylingHeaderAdvancedButton = () => {
    const hasAddedHorizontalIcon = this.props.publisher && !!this.props.publisher.horizontalIconId;
    const hasAddedDefaultFilledIcon = this.props.publisher && !!this.props.publisher.defaultFilledIconId;
    if (!this.props.isAdvancedStyler || !hasAddedHorizontalIcon || !hasAddedDefaultFilledIcon) {
      return null;
    }

    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        onClick={this.toggleAdvancedMode}
        data-test="onboarding.publisherSettingsTab.styling.advancedModeToggle.button"
      >
        {this.state.isSimpleStylingMode
          ? getMessageFromId('publisher-details-styling-advanced')
          : getMessageFromId('publisher-details-styling-simple')}
      </SDSButton>
    );
  };

  renderBitmojiTemplateIdInput = (isDisabled: boolean) => {
    if (!this.props.isBitmojiContentEnabled) {
      return null;
    }

    return (
      <SDSInput
        labelTitle={getMessageFromId('publisher-details-profile-bitmoji-template-id')}
        data-test="PublisherSettingsTab.PublisherProfile.SquareHeroBitmojiTemplateId"
        onChange={this.handleInputChange(PropertyKeys.SQUARE_HERO_IMAGE_BITMOJI_TEMPLATE_ID)}
        key={PropertyKeys.SQUARE_HERO_IMAGE_BITMOJI_TEMPLATE_ID}
        value={get(this.props.data, PropertyKeys.SQUARE_HERO_IMAGE_BITMOJI_TEMPLATE_ID)}
        disabled={isDisabled}
      />
    );
  };

  renderStylingHeaderDownloadButton = () => {
    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        onClick={this.handleDownload}
        data-test="onboarding.publisherSettingsTab.styling.download.button"
      >
        {getMessageFromId('publisher-details-styling-download')}
      </SDSButton>
    );
  };

  renderStylingHeader() {
    return (
      <>
        <div className={style.header}>
          <div className={style.title}>
            <FormattedMessage
              id="publisher-details-styling-header"
              description="Header for the styling section of the page asking for more publisher details"
              defaultMessage="Styling"
            />
          </div>
          <div className={style.stylingOptions} data-test="publisherSettingsTab.header">
            {this.renderStylingHeaderDownloadButton()}
            {this.renderStylingHeaderAdvancedButton()}
          </div>
          <HelpCenterLink
            destination={HelpCenterDestination.BRANDING_BEST_PRACTICES}
            data-test="PublisherSettingsTab.helpCenterLink.styling"
          />
        </div>
      </>
    );
  }

  renderLabelText(titleId: string, tooltipTextId: string) {
    const title = getMessageFromId(titleId);
    const tooltipText = getMessageFromId(tooltipTextId);

    return this.renderLabelTextFromFormattedMessage(title, tooltipText);
  }

  renderLabelTextFromFormattedMessage(title: JSX.Element, tooltipText: JSX.Element) {
    return (
      <div className={style.labelContainer}>
        <span>{title}</span>
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.TOP}
          title={tooltipText}
          id="settings-tooltip"
        >
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    );
  }

  getProfileImages = () => {
    if (this.props.isUpdatedProfilePreviewEnabled) {
      const imagePath = PropertyKeys.SQUARE_HERO_IMAGE_BLOB;
      return [get(this.props.data, imagePath), get(this.props.data, imagePath)];
    }
    return [get(this.props.data, PropertyKeys.SQUARE_HERO_IMAGE_BLOB)];
  };

  renderPreviewVersionsMessage = () => {
    if (this.props.isUpdatedProfilePreviewEnabled) {
      return (
        <div className={style.comments}>
          <p className={style.description}>
            {intlMessages.getMessageFromId('publisher-profile-preview-message-version')}
          </p>
        </div>
      );
    }
    return null;
  };

  renderProfile = (isDisabled: boolean) => {
    return (
      <SDSPanel header={getMessageFromId('publisher-details-profile-header')}>
        <SDSPanel.Row>
          <SDSPanel.Column className={style.profileColumn}>
            <PublisherDetailMediaButtonRow
              data-test="PublisherSettingsTab.PublisherProfile.SquareHero"
              title={getMessageFromId('publisher-details-profile-image')}
              key={PropertyKeys.SQUARE_HERO_IMAGE_BLOB}
              onChange={this.handleMediaChange(PropertyKeys.SQUARE_HERO_IMAGE_BLOB)}
              defaultValue={get(this.props.data, PropertyKeys.SQUARE_HERO_IMAGE_BLOB)}
              uploadType={UploadPurpose.SQUARE_HERO_IMAGE}
              dropzoneType={DropzoneType.PROFILE_IMAGE}
              publisherId={this.props.publisherId}
              disabled={isDisabled}
            />
            {this.renderPreviewVersionsMessage()}
            {this.renderBitmojiTemplateIdInput(isDisabled)}
          </SDSPanel.Column>
          <SDSPanel.Column>
            <PublisherProfilePreview
              data-test="PublisherSettingsTab.PublisherProfile.Preview"
              key="PublisherSettingsTab.PublisherProfile.Preview"
              imageClassName={style.showsPreviewImage}
              images={this.getProfileImages()}
              profileLogoDisplay={get(this.props.data, 'profileLogoDisplay') || LogoDisplay.BOTTOM}
              squareLogo={get(this.props.data, PropertyKeys.SQUARE_ICON_BLOB)}
              horizontalLogo={get(this.props.data, PropertyKeys.HORIZONTAL_ICON_BLOB)}
              formalName={get(this.props.data, PropertyKeys.FORMAL_NAME)}
              description={get(this.props.data, PropertyKeys.DESCRIPTION) || ''}
              websiteUrl={get(this.props.data, 'websiteUrl')}
              username={get(this.props.data, 'hostUsername')}
              isLoading={this.state.isMediaLoading}
              heroImagebitmojiTemplateId={
                this.props.isBitmojiContentEnabled
                  ? get(this.props.data, PropertyKeys.SQUARE_HERO_IMAGE_BITMOJI_TEMPLATE_ID)
                  : null
              }
            />
          </SDSPanel.Column>
        </SDSPanel.Row>
      </SDSPanel>
    );
  };

  renderUserAssociationHeader() {
    return (
      <>
        <div className={style.header}>
          <div className={style.title}>
            <FormattedMessage
              id="publisher-details-channel-username-header"
              description="Header for the channel username section of the page asking for publisher details"
              defaultMessage="Channel Username"
            />
          </div>
          <HelpCenterLink
            destination={HelpCenterDestination.HOST_USER_ASSOCIATION}
            data-test="PublisherSettingsTab.helpCenterLink.storycreation"
          />
        </div>
      </>
    );
  }

  renderUserAssociationPanel() {
    if (!this.props.isHostUserEnabled) {
      return null;
    }
    return (
      <SDSPanel header={this.renderUserAssociationHeader()} data-test="publisherSettings.userAssociationPanel">
        <UserAssociationView data-test="publisherSettings.userAssociationView" />
      </SDSPanel>
    );
  }

  renderStoryCreationHeader() {
    return (
      <>
        <div className={style.header}>
          <div className={style.title}>
            <FormattedMessage
              id="publisher-details-story-creation-header"
              description="Header for the story creation section of the page asking for publisher details"
              defaultMessage="Story Creation"
            />
          </div>
          <HelpCenterLink
            destination={HelpCenterDestination.SINGLE_ASSET}
            data-test="PublisherSettingsTab.helpCenterLink.storycreation"
          />
        </div>
      </>
    );
  }

  renderStoryCreationPanel() {
    const canConfigureNumberOfEmptySnaps = this.props.publisher
      ? isDefaultNumSnapsAllowed(this.props.publisher, this.props.isAdvancedCurationEnabled) &&
        !this.props.isSingleAssetStoryEditorEnabled
      : false;

    const isDisabled = !this.props.isPublisherSettingsEditor;
    const numberOfSnaps = String(get(this.props.data, PropertyKeys.NUM_SNAPS) || DEFAULT_NUM_SNAPS);
    const targetSnapLength =
      get(this.props.data, PropertyKeys.TARGET_SNAP_LENGTH, DEFAULT_TARGET_SNAP_LENGHT_MS) / 1000;

    // Do not show the creation panel if there are no settings to edit.
    if (this.props.isSingleAssetStoryEditorEnabled && !this.shouldRenderAutoCreateTime()) {
      return null;
    }

    return (
      <SDSPanel header={this.renderStoryCreationHeader()} data-test="publisherSettingsTab.storyCreationPanel">
        {canConfigureNumberOfEmptySnaps && (
          <SDSInput
            labelTitle={this.renderLabelText('publisher-details-num-snaps', 'publisher-details-num-snaps-description')}
            onChange={this.handleInputChange(PropertyKeys.NUM_SNAPS)}
            key={PropertyKeys.NUM_SNAPS}
            type={InputType.NUMBER}
            validateInput={validateMinMaxValue(1, 50)}
            value={numberOfSnaps}
            disabled={isDisabled}
            data-test="publisherSettingsTab.detailNum"
          />
        )}
        {!this.props.isSingleAssetStoryEditorEnabled && (
          <SDSInput
            labelTitle={this.renderLabelText(
              'publisher-target-snap-length',
              'publisher-target-snap-length-description'
            )}
            data-test="publisherSettingsTab.targetSnapLength"
            onChange={this.handleTargetSnapLengthChanged(PropertyKeys.TARGET_SNAP_LENGTH)}
            onValidation={this.handleValidation(PropertyKeys.TARGET_SNAP_LENGTH)}
            key={PropertyKeys.TARGET_SNAP_LENGTH}
            type={InputType.NUMBER}
            value={targetSnapLength}
            disabled={isDisabled}
            errorMessage={validateMinMaxValue(4, 16)(`${targetSnapLength}`) ? null : TARGET_SNAP_LENGHT_ERROR}
          />
        )}
        {this.shouldRenderAutoCreateTime() && (
          <SDSLabel labelTitle={this.renderDynamicEditionAutoCreateTimeText()}>
            <SDSTimePicker
              allowClear
              use12Hours
              format={'h:mm a'}
              data-test={'publisherSettingsTab.dynamicEditionAutoCreateTime'}
              onChange={this.handleDynamicEditionAutoCreateTimeChanged}
              value={this.getDynamicEditionAutoCreateMoment()}
            />
          </SDSLabel>
        )}
      </SDSPanel>
    );
  }

  renderDynamicEditionAutoCreateTimeText() {
    const title = (
      <FormattedMessage
        id="publisher-dynamic-edition-auto-create-time"
        description="Name for the setting to control when daily dynamic edition stories are auto created"
        defaultMessage="Daily story auto create time (in UTC)"
      />
    );

    const tooltipText = (
      <FormattedMessage
        id="publisher-dynamic-edition-auto-create-time-description"
        description="Tooltip description for the setting to control when daily dynamic edition stories are auto created"
        defaultMessage="A new story will be automatically created every day for you. This setting controls when this
        daily story creation takes place. The time is in UTC."
      />
    );

    return this.renderLabelTextFromFormattedMessage(title, tooltipText);
  }

  shouldRenderAutoCreateTime() {
    return (
      this.props.isCustomDEAutoCreateTimeEnabled && this.props.data[PropertyKeys.TIER] === TierLevel.DYNAMIC_EDITIONS
    );
  }

  getDynamicEditionAutoCreateMoment() {
    const autoCreateTime = this.props.data[PropertyKeys.DYNAMIC_EDITION_AUTO_CREATE_STORY_TIME];

    // Default to 22:00 UTC. Before publishers were allowed to choose when their daily auto create took place, we
    // auto created new stories for all dynamic edition publishers at 22:00 UTC.
    const hour = autoCreateTime?.hour ?? 22;
    const minute = autoCreateTime?.minute ?? 0;

    return moment().set('hour', hour).set('minute', minute);
  }

  isPublisherBestPracticesIncomplete() {
    if (!this.props.publisher) {
      return null;
    }

    const { squareHeroImageId, primaryColorRgb, description } = this.props.publisher;
    return !squareHeroImageId || !primaryColorRgb || !description;
  }

  renderProfileHeader() {
    const title = (
      <FormattedMessage
        id="publisher-details-profile-display-header"
        description="Header for the profile section of the page asking for more publisher details"
        defaultMessage="Profile"
      />
    );

    if (this.isPublisherBestPracticesIncomplete()) {
      return (
        <>
          <div className={style.header}>
            <div className={style.title}>{title}</div>
            <HelpCenterLink
              destination={HelpCenterDestination.BRANDING_BEST_PRACTICES}
              data-test="PublisherSettingsTab.helpCenterLink.profile.header"
            />
          </div>
        </>
      );
    }

    return title;
  }

  render() {
    if (!this.props.data) {
      return null;
    }
    const isAnyMediaUploading = Object.values(this.state.isMediaLoading).includes(true);
    const isDisabled = !this.props.isPublisherSettingsEditor;

    return (
      <div className={style.parent}>
        <div className={style.settingsButton} data-test="publisherSettingsTab.saveButton">
          <SaveSettingsButton
            onClick={this.handleClick}
            isSaving={this.state.isSaving}
            isActive={this.settingsHaveChanged()}
            isDisabled={!this.isValid()}
          />
        </div>
        <SDSPanel header={this.renderProfileHeader()}>
          <SDSInput
            labelTitle={this.renderLabelText(
              'publisher-details-publication-name',
              'publisher-details-publishing-name-description'
            )}
            onChange={this.handleInputChange(PropertyKeys.FORMAL_NAME)}
            key={PropertyKeys.FORMAL_NAME}
            value={get(this.props.data, PropertyKeys.FORMAL_NAME) || ''}
            disabled
            data-test="publisherSettings.name"
          />
          <OrganisationInput publisherId={this.props.publisherId} />
          <SDSInput
            labelTitle={this.renderLabelText(
              'publisher-details-publishing-descr',
              'publisher-details-publishing-descr-description'
            )}
            onChange={this.handleInputChange(PropertyKeys.DESCRIPTION)}
            onValidation={this.handleValidation(PropertyKeys.DESCRIPTION)}
            data-test="publisherSettings.description"
            disabled={isDisabled}
            key={PropertyKeys.DESCRIPTION}
            placeholder={this.getFormControlPlaceHolder()}
            maxLength={200}
            value={get(this.props.data, PropertyKeys.DESCRIPTION) || ''}
          />
          <PublisherDetailTagRow
            title={this.renderLabelText(
              'publisher-details-publishing-tags',
              'publisher-details-publishing-tags-description'
            )}
            onChange={this.handleTagsChange}
            key={PropertyKeys.TAGS}
            defaultTags={get(this.props.data, PropertyKeys.TAGS)}
            disabled={isDisabled}
            data-test="publisherSettings.tags"
          />
          {this.props.isPublisherCommentsSettingEnabled && (
            <SDSLabel
              labelTitle={
                <FormattedMessage
                  id="auto-approve-comments-title"
                  description="Label for auto approve comments toggle"
                  defaultMessage="Comment Settings"
                />
              }
            >
              <FormattedMessage
                id="auto-approve-comments-subtitle"
                description="Label subtitle for auto approve comments toggle"
                defaultMessage="Snapchatters can comment on your Stories and Spotlight posts. You can choose which comments to make visible to Snapchatters by manually approving them in-app, or enabling Auto-Approve for comments. All comments are moderated by Snapchat."
              />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px' }}>
                <SDSSwitch
                  data-test="publisherSettingsTab.autoApproveComments.toggle"
                  onChange={this.handleAutoApproveCommentsChanged}
                  value={get(this.props.data, PropertyKeys.AUTO_APPROVE_COMMENTS) || false}
                />
                <div className={style.autoApproveCommentsLabel}>
                  <FormattedMessage
                    id="auto-approve-comments-toggle-label"
                    description="Label shown next to the Auto Approve Comments toggle"
                    defaultMessage="Auto Approve Comments"
                  />
                </div>
              </div>
            </SDSLabel>
          )}
        </SDSPanel>
        {this.renderUserAssociationPanel()}
        {this.renderProfile(isDisabled || isAnyMediaUploading)}
        <SDSPanel
          header={
            <>
              <div className={style.header}>
                <div className={style.title}>
                  <FormattedMessage
                    id="publisher-details-sharing-header"
                    description="Header for the sharing section of the page asking for more publisher details"
                    defaultMessage="Sharing"
                  />
                </div>
                <HelpCenterLink
                  destination={HelpCenterDestination.CONTENT_SHARING}
                  data-test="PublisherSettingsTab.helpCenterLink.sharing"
                />
              </div>
            </>
          }
        >
          <PublisherSnapcodePreview snapcodes={this.state.isSnapcodeUpdating ? null : this.props.snapcodes} />
        </SDSPanel>
        <SDSPanel header={this.renderStylingHeader()}>
          {this.state.isSimpleStylingMode ? (
            <SimpleStylingEditor
              publisherId={this.props.publisherId}
              disabled={isDisabled || isAnyMediaUploading}
              onChange={this.handleMediaChange}
              isLoading={this.state.isMediaLoading}
            />
          ) : (
            <AdvancedStylingEditor
              publisherId={this.props.publisherId}
              disabled={isDisabled || isAnyMediaUploading}
              handleTileLogoChange={this.handleTileLogoChange}
              handleHorizontalIconChange={this.handleHorizontalIconChange}
              handleSquareIconChange={this.handleSquareIconChange}
              isLoading={this.state.isMediaLoading}
            />
          )}
        </SDSPanel>
        {this.renderStoryCreationPanel()}
      </div>
    );
  }
}

export default withExternalUpdatePublisherMutation(
  intlConnect(mapStateToProps, mapDispatchToProps)(PublisherSettingsTab)
);
