import React from 'react';
import { FormattedMessage } from 'react-intl';

export const glossaryOfAnalytics = (
  <FormattedMessage
    id="analytics-edition-glossary-of-analytics"
    defaultMessage="Glossary of Analytics"
    description="List of definitions for each metric listed within the CSV reports that you can download in the analytics dashboard."
  />
);
