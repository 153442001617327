import classNames from 'classnames';
import React, { useCallback } from 'react';

import KPIWidget, { KPIMetricInput } from 'views/common/components/KPIWidget/KPIWidget';
import SDSColumn from 'views/common/components/SDSColumn/SDSColumn';

import style from './SpotlightKPI.scss';

import { SpotlightMetric } from 'types/analytics';

type Props = {
  isActive: boolean;
  metric: KPIMetricInput<SpotlightMetric>;
  value: number;
  previousValue: number;
  onMetricClicked: (metric: SpotlightMetric) => void;
  isLoading: boolean;
};
export function SpotlightKPI(props: Props) {
  const { isActive, metric, value, previousValue, onMetricClicked, isLoading } = props;
  const handleMetricClick = useCallback(() => {
    onMetricClicked(metric.metricId);
  }, [metric, onMetricClicked]);
  return (
    <SDSColumn onClick={handleMetricClick} xs={4} md={4} lg={4} data-test={`SpotlightKPI.${metric.metricId}.Column`}>
      <KPIWidget
        metric={metric}
        value={value}
        isLoading={isLoading}
        previousValue={previousValue}
        data-test={`SpotlightKPI.${metric.metricId}.Widget`}
        className={classNames({
          [style.activeKpi]: isActive,
          [style.kpiTab]: !isActive,
        })}
      />
    </SDSColumn>
  );
}
