import Table from 'antd/lib/table';

/*
This is overriding one unpleasant behavior of antd:
 clicking on the column sorter loops like the following:
  undefined(not selected) --> (iterates through sortDirection) --> undefined
 this override is taking the undefined out of the loop so that we have:
  undefined(not selected) --> (iterates through sortDirection) --> (iterates through sortDirection) i.e. it doesn't return to undefined
 The only changes were made in the 3 lines in this if statement:
  https://github.com/ant-design/ant-design/blob/47dee4c7d756a088ea18cab170add4e4d56dddc7/components/table/Table.tsx#L806
 */
// @ts-expect-error ts-migrate(2314) FIXME: Generic type 'Table<T>' requires 1 type argument(s... Remove this comment to see the full error message
export class AntdTableOverriden extends Table {
  toggleSortOrder(column: any) {
    if (!column.sorter) {
      return;
    }
    // @ts-expect-error
    const pagination = { ...this.state.pagination };

    // @ts-expect-error
    const sortDirections = column.sortDirections || this.props.sortDirections;
    // @ts-expect-error
    const { sortOrder, sortColumn } = this.state;
    // 只同时允许一列进行排序，否则会导致排序顺序的逻辑问题
    let newSortOrder;
    // 切换另一列时，丢弃 sortOrder 的状态
    if (super.isSameColumn(sortColumn, column) && sortOrder !== undefined) {
      // <-- this if was overriden
      // 按照sortDirections的内容依次切换排序状态
      const methodIndex = (sortDirections.indexOf(sortOrder) + 1) % sortDirections.length;
      newSortOrder = sortDirections[methodIndex];
    } else {
      newSortOrder = sortDirections[0];
    }

    // @ts-expect-error
    if (this.props.pagination) {
      // Reset current prop
      pagination.current = 1;
      pagination.onChange(pagination.current);
    }
    const newState = {
      pagination,
      sortOrder: newSortOrder,
      sortColumn: newSortOrder ? column : null,
    };

    // Controlled
    // @ts-expect-error
    if (this.getSortOrderColumns().length === 0) {
      // @ts-expect-error
      this.setState(newState);
    }

    // @ts-expect-error
    const { onChange } = this.props;
    if (onChange) {
      // eslint-disable-next-line prefer-spread
      onChange.apply(
        null,
        // @ts-expect-error
        this.prepareParamsArguments({
          // @ts-expect-error
          ...this.state,
          ...newState,
        })
      );
    }
  }
}
