import React, { ReactElement } from 'react';

import Icon from '../Icon/Icon';
import Spinner, { SpinnerSizes } from '../Spinner/Spinner';

import { playFilled } from 'icons/SDS/allIcons';

import style from './PlayButton.scss';

export interface Props {
  handleClick: () => void;
  isOnPlay: boolean;
  isLoading?: boolean;
}

export default function PlayButton({ isOnPlay, handleClick, isLoading }: Props): ReactElement {
  return (
    <div className={style.buttonWrapper} onClick={handleClick} data-testid="PlayButton.wrapper">
      {isLoading ? (
        <Spinner loading size={SpinnerSizes.LARGE} />
      ) : (
        !isOnPlay && <Icon data-testid="PlayButton.icon" inlineIcon={playFilled} className={style.icon} />
      )}
    </div>
  );
}
