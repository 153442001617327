import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';

import { StatItem } from './StatItem';
import style from './ToplineStats.scss';

import type { CheetahAnalyticsStories } from 'types/analytics';

type Props = {
  totalViewers: number;
  reportStartDate: moment.Moment;
  reportEndDate: moment.Moment;
  topPerformingStoriesStats: CheetahAnalyticsStories;
  isShow: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-top-performing-stories-kpi-header-number-of-stories-published-text-regular-publisher-case"
      description="Text indicating the number of stories published for the kpi part on the Analytics Insights Report (show publisher case)"
      defaultMessage="Editions Published"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-top-performing-stories-kpi-header-avg-viewers-per-story-text-regular-publisher-case"
      description="Text indicating the average number of viewers for the kpi part on the Analytics Insights Report (show publisher case)"
      defaultMessage="Viewers Per Edition"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-top-performing-stories-kpi-header-number-of-stories-published-text-show-publisher-case"
      description="Text indicating the number of stories published for the kpi part on the Analytics Insights Report (regular publisher case)"
      defaultMessage="Episodes Published"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-top-performing-stories-kpi-header-avg-viewers-per-story-text-show-publisher-case"
      description="Text indicating the average number of viewers for the kpi part on the Analytics Insights Report (regular publisher case)"
      defaultMessage="Viewers Per Episode"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-insights-report-top-performing-stories-kpi-header-total-viewers-text"
      description="Text indicating the unit (total viewers) for the kpi part on the Analytics Insights Report"
      defaultMessage="Total Viewers"
    />
  ),
  params: [],
});

export class ToplineStats extends React.Component<Props> {
  getViewersPerStory = () => {
    return _.meanBy(this.props.topPerformingStoriesStats, storyStat => storyStat.stats.uniqueDau);
  };

  getNumberOfStoriesPublished = () => {
    return this.props.topPerformingStoriesStats.length;
  };

  render() {
    const publisherType = this.props.isShow ? 'show' : 'regular';
    return (
      <div className={style.toplineStats}>
        <StatItem
          name={getMessageFromId(
            `analytics-insights-report-top-performing-stories-kpi-header-number-of-stories-published-text-${publisherType}-publisher-case`
          )}
          value={this.getNumberOfStoriesPublished()}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          roundValue
          roundType="rawNumber"
          context="Total"
          reportStartDate={this.props.reportStartDate}
          reportEndDate={this.props.reportEndDate}
        />
        <StatItem
          name={getMessageFromId(
            `analytics-insights-report-top-performing-stories-kpi-header-avg-viewers-per-story-text-${publisherType}-publisher-case`
          )}
          value={this.getViewersPerStory()}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          roundValue
          roundType="number"
          context="Average"
          reportStartDate={this.props.reportStartDate}
          reportEndDate={this.props.reportEndDate}
        />
        <StatItem
          name={getMessageFromId('analytics-insights-report-top-performing-stories-kpi-header-total-viewers-text')}
          value={this.props.totalViewers}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          roundValue
          roundType="number"
          context="Total"
          reportStartDate={this.props.reportStartDate}
          reportEndDate={this.props.reportEndDate}
        />
      </div>
    );
  }
}

export default ToplineStats;
