import React from 'react';
import { FormattedMessage } from 'react-intl';

import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';

export default function NotFoundView() {
  return (
    <BlankPageWithText
      message={<FormattedMessage id="not-found" defaultMessage="Page not found" description="Not Found" />}
    />
  );
}
