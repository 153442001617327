// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
// discover-cms/no-snapnet
import { Col, Grid, Row } from '@snapchat/snapnet';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as analyticsActions from 'state/analytics/actions/analyticsActions';
import * as analyticsSelectors from 'state/analytics/selectors/analyticsSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { countryPickerDropdown } from '../AnalyticsEditionView/AnalyticsEditionView.scss';

import { AnalyticsCountryType, DailyGraph, DailyMetricType, DEFAULT_ANALYTICS_GEO } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import type { Enum } from 'utils/enum';
import { enumObject } from 'utils/enum';
import { GAUserActions, logGAEvent } from 'utils/gaUtils';
import { getMessageFromId, getRangeMessage, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';
import { withRouter } from 'utils/routerUtils';
import { TIME_OPTIONS } from 'utils/time/analyticsTimeUtils';

import AnalyticsDailyKPIs from 'views/analytics/components/AnalyticsDailyKPIs/AnalyticsDailyKPIs';
import AnalyticsFooter from 'views/analytics/components/AnalyticsFooter/AnalyticsFooter';
import AnalyticsMultiAreaChart from 'views/analytics/components/AnalyticsMultiAreaChart/AnalyticsMultiAreaChart';
import AnalyticsSingleAreaChart from 'views/analytics/components/AnalyticsSingleAreaChart/AnalyticsSingleAreaChart';
import { versionCounter } from 'views/analytics/containers/AnalyticsDailyView/analyticsVersionCounter';
import AnalyticsSettingsButton from 'views/analytics/containers/AnalyticsSettingsButton/AnalyticsSettingsButton';
import { getFirstDate, getLastDate, getTotalDays } from 'views/analytics/utils/analyticsUtil';
import { MULTICHART_CONFIG } from 'views/analytics/utils/chartConfigs';
import { getDatesFromSearchParams, updateDatesInSearchParams } from 'views/analytics/utils/searchParamsUtil';
import CountryPicker, { areCountryCodesEqual } from 'views/common/components/CountryPicker/CountryPicker';
import SDSAlert from 'views/common/components/SDSAlert/SDSAlert';
import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';
import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';
import CalendarButton from 'views/homepage/containers/CalendarButton/CalendarButton';

import style from './AnalyticsDailyView.scss';

import type { DailyAnalytics, DailyMetrics } from 'types/analytics';
import { CountryCodeCategory } from 'types/countries';
import { Claim } from 'types/permissions';
import type { Publisher, PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';

const COUNTRY_CODE_SEPARATOR = '_';
export const ViewType = enumObject({
  AUDIENCE: 'audience',
  BEHAVIOR: 'behavior',
});
type ViewTypeEnum = Enum<typeof ViewType>;
type CountryPickerToggleResult = {
  isDropdownVisible: string;
  pickedCountryCodes: {
    [x: string]: string | string[];
  };
};
type QueryChangeResult = {
  from: moment.Moment;
  to: moment.Moment;
};
type OwnProps = {
  publisherId: PublisherID;
  breadcrumbs: any;
  history: any;
  location: any;
  match: any;
  viewType: ViewTypeEnum;
};
type StateProps = {
  activePublisher: Publisher | undefined | null;
  analytics: DailyAnalytics | undefined | null;
  pickedCountryCodes: string | Array<string>;
  dailyMetrics: DailyMetrics;
  isSuperAdmin: boolean;
};
type DispatchProps = {
  fetchDailyStats: typeof analyticsActions.fetchDailyStats;
  clearDailyStats: typeof analyticsActions.clearDailyStats;
  setCountryCodes: typeof analyticsActions.setCountryCodes;
  persistCountryCodes: typeof analyticsActions.persistCountryCodes;
  showCountryPickerAlertModal: typeof modalsActions.showModal;
};
type OwnState = {
  from: moment.Moment;
  to: moment.Moment;
  zeroCountriesAlertIsShowing: boolean;
  isLoadingMetrics: boolean; // will be true until all metrics have loaded,
  isMetricsSectionLoaded: {
    [metric: string]: boolean;
  }; // can be used to check if a specific metrics section has loaded
};
type Props = StateProps & DispatchProps & OwnProps;
const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const primaryLanguage = publishersSelectors.getActivePublisherPrimaryLanguage(state);
  const dailyMetrics = analyticsSelectors.getDailyMetrics(state)(primaryLanguage);
  // lock country codes to US only for Happening Now publishers (because we are only in US for now)
  const pickedCountryCodes = analyticsSelectors.getCountryCodes(state);
  return {
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
    analytics: analyticsSelectors.getDaily(state),
    pickedCountryCodes,
    dailyMetrics,
    isSuperAdmin: hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
  };
};
const mapDispatchToProps = {
  fetchDailyStats: analyticsActions.fetchDailyStats,
  clearDailyStats: analyticsActions.clearDailyStats,
  setCountryCodes: analyticsActions.setCountryCodes,
  persistCountryCodes: analyticsActions.persistCountryCodes,
  shouldShowCountryPickerAlertModal: modalsActions.showModal,
};
const initialTimeOption = TIME_OPTIONS[3];
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="analytics-no-data"
      description="Shown when there is no data collected yet"
      defaultMessage="We're still gathering data for today, please come back later"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="daily-behavior-topsnap-views-explanation"
      description="Topsnap Views Explanation in Daily Behavior Analytics"
      defaultMessage="Shows total number of topsnap views on a given day."
    />
  ),
  params: [],
});
export class AnalyticsDailyView extends React.Component<Props, OwnState> {
  static title = (
    <FormattedMessage
      id="title-daily-analytics-3"
      description="Title Daily Analytics"
      defaultMessage="Daily analytics"
    />
  );

  static path = '/publisher/:publisherId/analytics';

  state = {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    from: getDatesFromSearchParams(this.props.location).from || initialTimeOption.from(),
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    to: getDatesFromSearchParams(this.props.location).to || initialTimeOption.to(),
    zeroCountriesAlertIsShowing: false,
    isLoadingMetrics: true,
    isMetricsSectionLoaded: {},
  };

  initialEndDate = this.state.to; // eslint-disable-line react/sort-comp

  UNSAFE_componentWillMount() {
    this.getAnalyticsData(this.props, this.state.from, this.state.to);
    updateDatesInSearchParams(this.props.history, this.state.from, this.state.to, true);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.props.persistCountryCodes);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.props.persistCountryCodes);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.publisherId !== this.props.publisherId) {
      this.getAnalyticsData(nextProps, this.state.from, this.state.to);
    }
    if (!areCountryCodesEqual(this.props.pickedCountryCodes, nextProps.pickedCountryCodes)) {
      this.getAnalyticsData(nextProps, this.state.from, this.state.to);
    }
  }

  onCountryPickerToggled = ({ isDropdownVisible, pickedCountryCodes }: CountryPickerToggleResult) => {
    if (isDropdownVisible) {
      return;
    }
    const pickedCountryCodesList = pickedCountryCodes[CountryCodeCategory.DEFAULT];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | string[] | undefined' i... Remove this comment to see the full error message
    if (areCountryCodesEqual(this.props.pickedCountryCodes, pickedCountryCodesList)) {
      return;
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (pickedCountryCodesList !== DEFAULT_ANALYTICS_GEO && pickedCountryCodesList.length === 0) {
      this.alertZeroCountries();
      return;
    }
    this.props.setCountryCodes(pickedCountryCodesList);
  };

  alertZeroCountries = () => {
    this.setState({
      zeroCountriesAlertIsShowing: true,
    });
  };

  confirmAlertZeroCountries = () => {
    this.setState({
      zeroCountriesAlertIsShowing: false,
    });
  };

  getAnalyticsData = (props: Props, from: moment.Moment, to: moment.Moment) => {
    if (from.isSame(moment(), 'day')) {
      // Don't get data for today
      return;
    }
    const metrics = _.values(DailyMetricType);
    const isMetricsSectionLoaded: {
      [metric: string]: boolean;
    } = {};
    metrics.forEach(metric => {
      isMetricsSectionLoaded[metric as any] = false;
    });
    this.setState({ isLoadingMetrics: true, isMetricsSectionLoaded });
    props.clearDailyStats();
    const countries = Array.isArray(props.pickedCountryCodes)
      ? encodeURI(props.pickedCountryCodes.join(COUNTRY_CODE_SEPARATOR))
      : null;
    const commonArgs = {
      version: versionCounter.version,
      publisherId: props.publisherId,
      startDate: from.format('YYYY-MM-DD'),
      endDate: to.format('YYYY-MM-DD'),
      type:
        props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO ? AnalyticsCountryType.GLOBAL : AnalyticsCountryType.COUNTRY,
      countries,
    };
    Promise.all(
      metrics.map(metric => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ metric: string; version: numbe... Remove this comment to see the full error message
        return (props.fetchDailyStats({ ...commonArgs, metric }) as any).then(() => {
          this.setState(prevState => ({
            isMetricsSectionLoaded: { ...prevState.isMetricsSectionLoaded, [metric as any]: true },
          }));
        });
      })
    ).then(() => {
      this.setState({ isLoadingMetrics: false });
    });
  };

  handleQueryChange = ({ from, to }: QueryChangeResult) => {
    this.getAnalyticsData(this.props, from, to);
    this.setState({
      from,
      to,
    });
    updateDatesInSearchParams(this.props.history, from, to);
  };

  onDateRangeUpdated = (fromMoment?: moment.Moment | null, toMoment?: moment.Moment | null) => {
    const from = fromMoment || moment();
    const to = toMoment || moment();
    this.handleQueryChange({ from, to });
  };

  isGlobal = () => this.props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO;

  isSingleCountry = () => this.props.pickedCountryCodes.length === 1;

  isGlobalOrSingleCountry = () => this.isGlobal() || this.isSingleCountry();

  getDailyUniquesGraph = () => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const isLoading = !this.state.isMetricsSectionLoaded[DailyMetricType.NORMAL];
    return (
      <AnalyticsMultiAreaChart
        data={this.props.dailyMetrics}
        valueFormatter={numberFormatter.prettyPrintNumber}
        graphTooltip={
          <>
            {getMessageFromId('daily-audience-daily-uniques-explanation')}&nbsp;
            {getMessageFromId('subscription-analytics-excludes-spotlight-subscription-notice')}
          </>
        }
        isLoading={isLoading}
        descriptionMessageId="daily-audience-daily-uniques"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        metricProperties={MULTICHART_CONFIG[DailyGraph.UNIQUE_DAU]}
      />
    );
  };

  renderDailyAudienceGraphs = () => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const isLoadingNormal = !this.state.isMetricsSectionLoaded[DailyMetricType.NORMAL];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const isLoadingMonthly = !this.state.isMetricsSectionLoaded[DailyMetricType.RANGE_VIEWERS];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const isLoadingLoyalty = !this.state.isMetricsSectionLoaded[DailyMetricType.LOYALTY];
    return (
      <div data-test="analyticsDailyView.allAudienceGraphs">
        <Row key="area-chart-daily-uniques">
          <Col xs={12} data-test="analyticsDailyView.dailyUniquesGraph">
            {this.getDailyUniquesGraph()}
          </Col>
        </Row>
        <Row key="area-chart-monthly-uniques">
          <Col xs={12} data-test="analyticsDailyView.monthlyUniquesGraph">
            <AnalyticsSingleAreaChart
              data={this.props.dailyMetrics}
              valueFormatter={numberFormatter.prettyPrintNumber}
              graphTooltip={getMessageFromId('daily-audience-V3-monthly-uniques-explanation')}
              isLoading={isLoadingMonthly}
              property="uniqueMau"
              descriptionMessageId="daily-audience-V3-monthly-uniques"
            />
          </Col>
        </Row>
        <Row key="area-chart-subscribers">
          <Col xs={12} data-test="analyticsDailyView.subscribersGraph">
            <AnalyticsSingleAreaChart
              data={this.props.dailyMetrics}
              valueFormatter={numberFormatter.prettyPrintNumber}
              graphTooltip={
                <>
                  {getMessageFromId('daily-audience-followers-explanation')}&nbsp;
                  {getMessageFromId('subscription-analytics-excludes-spotlight-subscription-notice')}
                </>
              }
              isLoading={isLoadingNormal}
              property="subscribers"
              descriptionMessageId="daily-audience-followers"
            />
          </Col>
        </Row>
        <Row key="area-chart-user-loyalty">
          <Col xs={12} data-test="analyticsDailyView.userLoyaltyGraph">
            <AnalyticsMultiAreaChart
              data={this.props.dailyMetrics}
              graphTooltip={getMessageFromId('daily-audience-user-loyalty-explanation')}
              isLoading={isLoadingLoyalty}
              /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
              metricProperties={MULTICHART_CONFIG[DailyGraph.USER_LOYALTY]}
              descriptionMessageId="daily-audience-user-loyalty"
            />
          </Col>
        </Row>
        <Row key="area-chart-gender-demographics" data-test="analyticsDailyView.genderDemographicsGraph">
          <Col xs={12}>
            <AnalyticsMultiAreaChart
              data={this.props.dailyMetrics}
              isLoading={isLoadingNormal}
              /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
              metricProperties={MULTICHART_CONFIG[DailyGraph.GENDER_DEMOGRAPHICS]}
              descriptionMessageId="daily-audience-gender-demographics"
            />
          </Col>
        </Row>
        <Row key="area-chart-age-demographics">
          <Col xs={12} data-test="analyticsDailyView.ageDemographicsGraph">
            <AnalyticsMultiAreaChart
              data={this.props.dailyMetrics}
              isLoading={isLoadingNormal}
              /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
              metricProperties={MULTICHART_CONFIG[DailyGraph.AGE_DEMOGRAPHICS]}
              descriptionMessageId="daily-audience-age-demographics"
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderDailyBehaviourGraphs = () => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const isLoadingNormal = !this.state.isMetricsSectionLoaded[DailyMetricType.NORMAL];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const isLoadingAttachmentConversion = !this.state.isMetricsSectionLoaded[DailyMetricType.ATTACHMENT_CONVERSION];
    return (
      <div data-test="analyticsDailyView.allBehaviourGraphs">
        <Row key="area-chart-behaviour-unique-topsnaps">
          <Col xs={12} data-test="analyticsDailyView.uniqueTopSnap">
            <AnalyticsSingleAreaChart
              data={this.props.dailyMetrics}
              valueFormatter={numberFormatter.prettyPrintNumber}
              graphTooltip={getMessageFromId('daily-behavior-avg-unique-topsnap-view-explanation')}
              isLoading={isLoadingNormal}
              property="uniqueTopsnapViewsPerUser"
              descriptionMessageId="daily-behavior-avg-unique-topsnap-view"
            />
          </Col>
        </Row>
        <Row key="area-chart-behaviour-time-spent">
          <Col xs={12} data-test="analyticsDailyView.timeSpent">
            <AnalyticsSingleAreaChart
              data={this.props.dailyMetrics}
              valueFormatter={numberFormatter.printSeconds}
              graphTooltip={getMessageFromId('daily-behavior-time-spent-explanation')}
              isLoading={isLoadingNormal}
              property="timeSpent"
              descriptionMessageId="daily-behavior-time-spent"
            />
          </Col>
        </Row>
        <Row key="area-chart-behaviour-attachment-conversion">
          <Col xs={12} data-test="analyticsDailyView.attachmentConversion">
            <AnalyticsSingleAreaChart
              data={this.props.dailyMetrics}
              valueFormatter={numberFormatter.printPercentage}
              graphTooltip={getMessageFromId('daily-behavior-attachment-conversion-explanation')}
              isLoading={isLoadingAttachmentConversion}
              property="attachmentConversion"
              descriptionMessageId="daily-behavior-attachment-conversion"
            />
          </Col>
        </Row>
        <Row key="area-chart-behaviour-view-counts">
          <Col xs={12} data-test="analyticsDailyView.viewCount">
            <AnalyticsMultiAreaChart
              data={this.props.dailyMetrics}
              isLoading={isLoadingNormal}
              /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
              metricProperties={MULTICHART_CONFIG[DailyGraph.VIEW_COUNTS]}
              descriptionMessageId={'daily-behavior-topsnap-attachment-views'}
              graphTooltip={getMessageFromId('daily-behavior-topsnap-attachment-views-explanation')}
            />
          </Col>
        </Row>
        <Row key="area-chart-behaviour-screenshot-shares">
          <Col xs={12} data-test="analyticsDailyView.screenShotnShares">
            <AnalyticsMultiAreaChart
              data={this.props.dailyMetrics}
              isLoading={isLoadingNormal}
              /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
              metricProperties={MULTICHART_CONFIG[DailyGraph.SCREENSHOT_SHARES]}
              descriptionMessageId="daily-behavior-screenshots-shares"
              graphTooltip={getMessageFromId('daily-behavior-screenshots-shares-explanation')}
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderGraphs(tab: ViewTypeEnum) {
    return tab === 'audience' ? this.renderDailyAudienceGraphs() : this.renderDailyBehaviourGraphs();
  }

  renderAlertModal = () => {
    return (
      <SDSAlert visible onConfirm={this.confirmAlertZeroCountries} data-test="analyticsDailyView.sdsAlert">
        <FormattedMessage
          id="alert-zero-countries"
          description="Alert message for not selecting any country"
          defaultMessage="Please select at least one country."
        />
      </SDSAlert>
    );
  };

  renderCountryPicker = () => {
    return (
      <div className={classNames(style.countryPicker, style.headerButton)} data-test="analyticsDailyView.CountryPicker">
        <CountryPicker
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          pickedCountryCodes={this.props.pickedCountryCodes}
          width={160}
          numCountries={2}
          onChange={_.noop}
          dropdownClassName={countryPickerDropdown}
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          onToggle={this.onCountryPickerToggled}
        />
      </div>
    );
  };

  renderDateFilterButton = () => {
    const from = this.state.from ? this.state.from : moment();
    const to = this.state.to ? this.state.to : moment();
    const range = getRangeMessage(from, to, 'MM/DD');

    logGAEvent(GAUserActions.ANALYTICS, 'analytics-daily-view-date-picker', {
      publisherName: this.props.activePublisher?.name,
      noOfDays: from.diff(to, 'days'),
    });

    return (
      <CalendarButton
        className={style.headerButton}
        onUpdate={this.onDateRangeUpdated}
        defaultText={range}
        from={from}
        to={to}
        shouldShowDropDownButton
      />
    );
  };

  renderHeaderButtons = () => {
    const from = this.state.from ? this.state.from : moment();
    const to = this.state.to ? this.state.to : moment();
    return (
      <div className={style.cheetahControlGroup}>
        {this.renderDateFilterButton()}
        {this.renderCountryPicker()}
        <AnalyticsSettingsButton from={from} to={to} />
        {this.state.zeroCountriesAlertIsShowing && this.renderAlertModal()}
      </div>
    );
  };

  renderAnalyticsBody = () => {
    if (this.state.from.isSame(moment(), 'day')) {
      return <BlankPageWithText happyState message={getMessageFromId('analytics-no-data')} />;
    }
    const { analytics } = this.props;
    const dailyMetrics = _.get(analytics, 'dailyMetrics');
    const firstDate = getFirstDate(dailyMetrics);
    const lastDate = getLastDate(dailyMetrics);
    const totalDays = getTotalDays(dailyMetrics);
    const previousRange = _.get(analytics, ['kpiComparison', 'previousRange']);
    const currentRange = _.get(analytics, ['kpiComparison', 'currentRange']);
    return (
      <>
        <div className={style.analyticsKpiContainer}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <AnalyticsDailyKPIs
            currentRange={currentRange}
            endDate={lastDate}
            isLoading={this.state.isLoadingMetrics}
            minDate={this.initialEndDate === this.state.to}
            previousRange={previousRange}
            startDate={firstDate}
            totalDays={totalDays}
          />
        </div>
        {this.renderGraphs(this.props.viewType)}
        <AnalyticsFooter />
      </>
    );
  };

  render() {
    return (
      <ClaimGatedView requiredClaim={Claim.ANALYTICS_VIEWER}>
        <Grid fluid className={style.root}>
          {this.renderHeaderButtons()}
          {this.renderAnalyticsBody()}
        </Grid>
      </ClaimGatedView>
    );
  }
}
export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsDailyView));
