import React from 'react';
import { FormattedMessage } from 'react-intl';

import { loadOrg } from 'state/organisations/actions/organisationsActions';
import { getOrgByBusinessProfileId, isOrgLoading } from 'state/organisations/selectors/organisationsSelectors';
import { getPublisherDetailsDataById } from 'state/publishers/selectors/publishersSelectors';

import { duplicate } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import style from 'views/onboarding/containers/SettingsView/components/OrganisationInput/OrganisationInput.scss';

import type { Org } from 'types/organisations';
import type { Publisher, PublisherID } from 'types/publishers';
import type { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';

type ExternalProps = {
  publisherId: PublisherID;
};

type StateProps = {
  publisher: Publisher | undefined | null;
  org: Org | undefined | null;
  isOrgLoading: boolean;
};

export const mapStateToProps = (state: RootState, props: ExternalProps): StateProps => {
  const { publisherId } = props;
  const publisher = getPublisherDetailsDataById(state)(publisherId);

  return {
    publisher,
    isOrgLoading: isOrgLoading(state),
    org: publisher ? getOrgByBusinessProfileId(state)(publisher.businessProfileId) : null,
  };
};

const mapDispatchToProps = {
  getOrg: loadOrg,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = ExternalProps & DispatchProps & StateProps;

export class OrganisationInput extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.publisher) {
      return;
    }
    this.props.getOrg({ bpid: this.props.publisher.businessProfileId });
  }

  handleCopyOrgId = () => {
    if (this.props.org?.orgId) {
      navigator.clipboard.writeText(this.props.org?.orgId);
    }
  };

  render() {
    const labelTitle = (
      <FormattedMessage
        id="super-admin-organisation-input-label"
        description="Label for organisation name input"
        defaultMessage="Organisation Name"
      />
    );

    if (this.props.isOrgLoading) {
      return (
        <SDSLabel labelTitle={labelTitle}>
          <SpinnerIcon className={style.orgSpinner} data-test="onboarding.settingsView.organisationInput.spinner" />
        </SDSLabel>
      );
    }
    return (
      <div className={style.inputWrapper}>
        <SDSInput
          labelTitle={labelTitle}
          value={this.props.org?.orgName || 'n/a'}
          errorMessage={
            this.props.org ? null : (
              <FormattedMessage
                id="super-admin-no-organisation-error-message"
                description="Error message for organisation name input"
                defaultMessage="Publisher is not linked to an organisation"
              />
            )
          }
          disabled
          data-test="onboarding.settingsView.organisationInput.input"
        />
        <div className={style.tooltipWrapper}>
          <SDSTooltip
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            placement={TooltipPosition.TOP}
            title={
              <FormattedMessage
                id="copy-org-id"
                description="Copy organisation ID label"
                defaultMessage="Copy Org ID"
              />
            }
            id="tooltip"
          >
            <SDSButton
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              inlineIcon={duplicate}
              onClick={this.handleCopyOrgId}
              disabled={!this.props.org?.orgId} // If there's no org id disable the button since there would be nothing to copy
            />
          </SDSTooltip>
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(OrganisationInput);
