import React, { memo } from 'react';

import { download } from 'icons/SDS/allIcons';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';

type Props = {
  className?: string;
  disabled?: boolean;
  isDownloading?: boolean;
  onDownload(): void;
  purpose: string;
  metricButtonType: string;
  extraMetricDimensions?: object;
};

function DownloadButton({
  purpose,
  onDownload,
  disabled,
  className,
  isDownloading,
  metricButtonType,
  extraMetricDimensions = {},
}: Props) {
  return (
    <SDSButton
      className={className}
      type={ButtonType.SECONDARY}
      shape={ButtonShape.CIRCLE}
      inlineIcon={download}
      loading={isDownloading}
      disabled={disabled}
      onClick={onDownload}
      data-test={`download.${purpose}`}
      metricName="download"
      metricDimensions={{ click: metricButtonType, ...extraMetricDimensions }}
    />
  );
}

export default memo(DownloadButton);
