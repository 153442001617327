import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE } from 'views/analytics/utils/chartConfigs';

import type { StorySnapMetrics } from 'types/analytics';

type Props = {
  isLoading: boolean;
  metrics: StorySnapMetrics[];
  isOnPromotionTab: boolean;
  useAnalyticsV2?: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="attachment-conversion-organic"
      defaultMessage="Attachment Conversion Organic"
      description="Conversion rate on for organic impressions"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="attachment-conversion-paid"
      defaultMessage="Attachment Conversion Paid"
      description="Conversion rate on attachments for paid impressions"
    />
  ),
  params: [],
});

export class AnalyticsAttachmentConversionGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getConversionConfig = () => {
    return this.props.isOnPromotionTab
      ? [
          {
            key: 'paidConversion',
            name: getLocalisedMessageFromId(this.context, 'attachment-conversion-paid'),
            fill: GRAPH_SHADES_OF_BLUE.MED_BLUE,
          },
        ]
      : [
          {
            key: 'conversion',
            name: getLocalisedMessageFromId(this.context, 'publisher-story-attachment-conversion'),
            fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
          },
        ];
  };

  getConversionConfigV2 = () => {
    return this.props.isOnPromotionTab
      ? [
          {
            key: 'conversionPaid',
            name: getLocalisedMessageFromId(this.context, 'attachment-conversion-paid'),
            fill: GRAPH_SHADES_OF_BLUE.MED_BLUE,
          },
        ]
      : [
          {
            key: 'conversion',
            name: getLocalisedMessageFromId(this.context, 'publisher-story-attachment-conversion'),
            fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
          },
        ];
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, conversion, snapId, mediaId, mediaType, poll, paidConversion, mediaUrl } = payload;
    const conversionConfig = this.getConversionConfig();
    const metricRows = this.props.isOnPromotionTab
      ? [{ metricName: conversionConfig[0]?.name, metricValue: numberFormatter.printPercentage(paidConversion) }]
      : [
          {
            metricName: conversionConfig[0]?.name,
            metricValue: numberFormatter.printPercentage(conversion),
          },
        ];

    return (
      <AnalyticsTooltip
        title={name}
        snapId={snapId}
        mediaId={mediaId}
        mediaType={mediaType}
        metricRows={metricRows}
        pollMetadata={poll}
        generatedPreviewUrl={mediaUrl}
      />
    );
  };

  renderChartTooltipV2 = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, conversion, snapId, mediaId, mediaType, poll, paidConversion, mediaUrl } = payload;
    const conversionConfig = this.getConversionConfig();
    const metricRows = this.props.isOnPromotionTab
      ? [{ metricName: conversionConfig[0]?.name, metricValue: numberFormatter.printPercentage(paidConversion) }]
      : [
          {
            metricName: conversionConfig[0]?.name,
            metricValue: numberFormatter.printPercentage(conversion),
          },
        ];

    return (
      <AnalyticsTooltip
        title={name}
        snapId={snapId}
        mediaId={mediaId}
        mediaType={mediaType}
        metricRows={metricRows}
        pollMetadata={poll}
        generatedPreviewUrl={mediaUrl}
      />
    );
  };

  render() {
    return (
      <AnalyticsBarChart
        barChartData={this.props.metrics}
        barChartTitle={getMessageFromId('publisher-story-attachment-conversion')}
        bars={this.props.useAnalyticsV2 ? this.getConversionConfigV2() : this.getConversionConfig()}
        graphTooltip={getMessageFromId('publisher-story-attachment-conversion-explanation')}
        isLoading={this.props.isLoading}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.props.useAnalyticsV2 ? this.renderChartTooltipV2 : this.renderChartTooltip}
        yAxisTickFormatter={numberFormatter.printPercentage}
      />
    );
  }
}

export default AnalyticsAttachmentConversionGraph;
