import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

export const ANALYTICS_TABS = enumObject({
  OVERVIEW: 'OVERVIEW',
  TILES: 'TILES',
  TILES_NO_ANALYTICS: 'TILES_NO_ANALYTICS',
  SEGMENTS: 'SEGMENTS',
  PROMOTION: 'PROMOTION',
});

export type AnalyticsTabsEnum = Enum<typeof ANALYTICS_TABS>;
