import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { getActiveCreatorHostUserId } from 'state/user/selectors/userSelectors';

import { useGetProfileAnalytics } from 'gql/hooks/useGetProfileAnalytics';

import { AnalyticsHeader } from 'views/analytics/components/AnalyticsHeader/AnalyticsHeader';
import ProfileAnalyticsGraph from 'views/analytics/components/ProfileAnalyticsGraph/ProfileAnalyticsGraph';
import { AnalyticMetric } from 'views/analytics/components/ProfileKPI/ProfileKPI';
import { ProfileKPIs } from 'views/analytics/components/ProfileKPIs/ProfileKPIs';
import { getDatesFromSearchParams, updateDatesInSearchParams } from 'views/analytics/utils/searchParamsUtil';

import style from './ProfileAnalyticsView.scss';

import { ProfileMetric } from 'types/analytics';

export default function ProfileAnalyticsView() {
  const history = useHistory();
  const location = useLocation();
  const [activeMetric, setActiveMetric] = useState<ProfileMetric | null>('uniqueViewers');
  const initialFrom = getDatesFromSearchParams(location).from || moment().subtract(7, 'days');
  const initialTo = getDatesFromSearchParams(location).to || moment();
  const [startDate, setStartDate] = useState<moment.Moment>(initialFrom);
  const [endDate, setEndDate] = useState<moment.Moment>(initialTo);
  const activeCreatorHostUserId = useSelector(getActiveCreatorHostUserId) || '';
  const reportRange = endDate.diff(startDate, 'days');

  const { data, loading } = useGetProfileAnalytics(
    activeCreatorHostUserId,
    startDate.format('YYYY-MM-DD'),
    (startDate.isSame(endDate) ? endDate.add(1, 'day') : endDate).format('YYYY-MM-DD')
  );

  function updateMetricAction(metric: AnalyticMetric) {
    setActiveMetric(metric as ProfileMetric);
  }

  const renderProfileKPIsSection = () => {
    return (
      <ProfileKPIs
        activeMetric={activeMetric}
        reportRange={reportRange}
        profileAnalyticsSummary={data?.profileAnalytics}
        setActiveMetric={updateMetricAction}
        isLoading={loading}
      />
    );
  };

  const onReportRangeChange = useCallback(
    async (start?: moment.Moment | null, end?: moment.Moment | null) => {
      if (start && end) {
        let updatedEndDate = end;

        if (start.isSame(end, 'days')) {
          updatedEndDate = end.clone().add(1, 'days');
        }

        setStartDate(start);
        setEndDate(updatedEndDate);
        updateDatesInSearchParams(history, start, end);
      }
    },
    [history]
  );

  const renderProfileKPIGraphSection = () => {
    return (
      <ProfileAnalyticsGraph
        activeMetric={activeMetric!}
        reportRange={reportRange}
        profileAnalytics={data?.profileAnalytics}
        isLoading={loading}
      />
    );
  };

  return (
    <div className={style.containerFluid}>
      <AnalyticsHeader from={startDate} to={endDate} onDateRangeUpdated={onReportRangeChange} allowDataExport />
      {renderProfileKPIsSection()}
      {renderProfileKPIGraphSection()}
    </div>
  );
}
