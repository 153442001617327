import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getActiveCreatorHostUserId } from 'state/user/selectors/userSelectors';

import { getSnapIds } from 'gql/hooks/useDeleteStorySnap';
import { DELETE_STORY_DRAFTING_SNAPS } from 'gql/mutations/spotlight/deleteStoryDraftingSnaps';
import { StorySnap } from 'gql/queries/spotlight/storySnaps';
import { useProxyMutation } from 'utils/apis/graphQLUtils';

export function useDeleteStoryDraftingSnaps() {
  const [deleteStoryDraftingSnaps] = useProxyMutation(DELETE_STORY_DRAFTING_SNAPS);
  const hostUserId = useSelector(getActiveCreatorHostUserId);

  const deleteDraftingSnaps = useCallback(
    async (snaps: StorySnap[]) => {
      const snapIds = getSnapIds(snaps);
      return deleteStoryDraftingSnaps({
        variables: {
          input: {
            hostUserId,
            snapIds,
          },
        },
      });
    },
    [deleteStoryDraftingSnaps, hostUserId]
  );

  return { deleteDraftingSnaps };
}
