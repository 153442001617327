import moment from 'moment-timezone';

import type {
  LiveEdition,
  ScheduledEdition,
  LiveEditionWithEpisodeNumber,
  ScheduledEditionWithEpisodeNumber,
} from 'types/editions';
import type { Season } from 'types/shows';

export const getNextSeasonNumber = (season?: Season | null) => {
  const previousSeasonNumber = season?.seasonNumber || 0;
  return previousSeasonNumber + 1;
};

export const getEditionsInChosenSeason = (
  chosenSeason: Season | undefined | null,
  editions: (LiveEdition | ScheduledEdition)[]
) => {
  return editions.reduce((filteredEditions, edition) => {
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    const episodeInChosenSeason = chosenSeason?.episodes.find(episode => episode.id === edition.id.toString());
    if (episodeInChosenSeason) {
      // only keep the ones in the chosen season
      const editionWithEpisodeNumber = {
        ...edition,
        episodeNumber: episodeInChosenSeason.episodeNumber, // include their episode number
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ episodeNumber: number; publish... Remove this comment to see the full error message
      filteredEditions.push(editionWithEpisodeNumber);
    }
    return filteredEditions;
  }, []);
};

export const isChosenStartDateBeforeEpisodeStartDate = (
  startDate: moment.Moment | undefined | null,
  episodeStartDate: moment.Moment,
  selectedDateTimezone: string
) => {
  if (!episodeStartDate) {
    return false;
  } // the episode is probably still a draft so no need to check
  if (!startDate) {
    // when the user want to schedule immediately

    const now = moment().tz(selectedDateTimezone);
    return now.isBefore(episodeStartDate);
  }
  return startDate.isBefore(episodeStartDate);
};

export const getEditionsScheduledAfterStartDate = (
  chosenSeason: Season | undefined | null,
  liveAndScheduledEditions: (LiveEdition | ScheduledEdition)[],
  startDate: moment.Moment | undefined | null,
  selectedDateTimezone: string
): (LiveEditionWithEpisodeNumber | ScheduledEditionWithEpisodeNumber)[] => {
  const liveAndScheduledEditionsInChosenSeason: (
    | LiveEditionWithEpisodeNumber
    | ScheduledEditionWithEpisodeNumber
  )[] = getEditionsInChosenSeason(chosenSeason, liveAndScheduledEditions);

  return liveAndScheduledEditionsInChosenSeason.filter(edition => {
    const editionStartDate = moment(edition.startDate).tz(selectedDateTimezone);
    return isChosenStartDateBeforeEpisodeStartDate(startDate, editionStartDate, selectedDateTimezone);
  });
};

export const getFirstPossibleEpisodeNumber = (
  editions: (LiveEditionWithEpisodeNumber | ScheduledEditionWithEpisodeNumber)[]
): number => {
  return editions.reduce((lowestEpisodeNumber, edition) => {
    // we only need the lowest episode number, any editions after that are superflous
    if (edition.episodeNumber !== null && (lowestEpisodeNumber === 0 || lowestEpisodeNumber > edition.episodeNumber)) {
      // eslint-disable-next-line no-param-reassign
      lowestEpisodeNumber = edition.episodeNumber;
    }
    return lowestEpisodeNumber;
  }, 0);
};

const getLatestEpisodeNumber = (chosenSeason: Season): number => {
  // check if the chosen season has episodes
  const seasonHasEpisodes = chosenSeason.episodes.length > 0;

  // find the highest episode number
  const allEpisodeNumbers = chosenSeason.episodes.map(ep => ep.episodeNumber);
  const highestEpNumber = Math.max(...allEpisodeNumbers);
  const lastEpisodeNumber = highestEpNumber || 0;

  // in case there are gaps, always pick the highest number possible
  return seasonHasEpisodes ? lastEpisodeNumber + 1 : 1;
};

export const generateNewEpisodeNumber = (
  chosenSeason: Season | undefined | null,
  liveAndScheduledEditions: (LiveEdition | ScheduledEdition)[],
  startDate: moment.Moment | undefined | null,
  selectedDateTimezone: string
): number => {
  // if creating a new season, or the chosen season doesn't have any episodes the episode number should be 1 by default
  if (!chosenSeason || !chosenSeason?.episodes.length) {
    return 1;
  }

  const editionsScheduledAfterStartDateInChosenSeason = getEditionsScheduledAfterStartDate(
    chosenSeason,
    liveAndScheduledEditions,
    startDate,
    selectedDateTimezone
  );

  const firstPossibleEpisodeNumber = getFirstPossibleEpisodeNumber(editionsScheduledAfterStartDateInChosenSeason);
  const latestPossibleEpisodeNumber = getLatestEpisodeNumber(chosenSeason);
  // if there are episodes scheduled after the chosen first date then pick the first possible one
  // otherwise place the episode as the latest one
  return firstPossibleEpisodeNumber !== null
    ? firstPossibleEpisodeNumber || latestPossibleEpisodeNumber
    : latestPossibleEpisodeNumber;
};
