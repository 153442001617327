// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Col, Grid, Row } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import _, { noop } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import {
  clearEdition24HoursStats,
  clearEditionDailyStats,
  clearEditionStats,
  clearSnapStats,
  fetchEdition24HoursStats,
  fetchEditionDailyStats,
  fetchEditionStats,
  fetchEditionStatsByCountries,
  fetchPollTimeline,
  fetchServiceStatus,
  fetchSnapStats,
  loadCountryCodes,
  persistCountryCodes,
  setAnalyticsStatsType,
  setCountryCodes,
  setEditionDateRange,
} from 'state/analytics/actions/analyticsActions';
import {
  getAnalyticsStatsType,
  getServiceStatus,
  isShowingAllTimeStats,
  isShowingSingleDayStats,
  isStoryReachAffectedByModeration,
} from 'state/analytics/selectors/analyticsSelectors';
import { getAuthType, getToken } from 'state/auth/selectors/authSelectors';
import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as previewsActions from 'state/previews/actions/previewsActions';
import { loadChaptersPreview } from 'state/previews/actions/previewsActions';
import { getStoryChapters } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getPresentationalTileForEdition } from 'state/publisherTools/selectors/publisherToolsSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import { getChaptersSummary } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { AnalyticsCountryType, DEFAULT_ANALYTICS_GEO, DEFAULT_GEO } from 'config/constants';
import { pencil } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { functionRef } from 'utils/functionUtils';
import { GAUserActions, logGAEvent } from 'utils/gaUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isStoryPromoted } from 'utils/payToPromoteUtils';
import { withRouter } from 'utils/routerUtils';

import AnalyticsContentInfo from 'views/analytics/components/AnalyticsContentInfo/AnalyticsContentInfo';
import AnalyticsFooter from 'views/analytics/components/AnalyticsFooter/AnalyticsFooter';
import AnalyticsStatusIcon from 'views/analytics/components/AnalyticsStatusIcon/AnalyticsStatusIcon';
import AnalyticsStoryGraphs from 'views/analytics/components/AnalyticsStoryGraphs/AnalyticsStoryGraphs';
import AnalyticsStoryKPIs from 'views/analytics/components/AnalyticsStoryKPIs/AnalyticsStoryKPIs';
import AnalyticsTopsnapPreview from 'views/analytics/components/AnalyticsTopsnapPreview/AnalyticsTopsnapPreview';
import TileVariantTable from 'views/analytics/components/TileVariantTable/TileVariantTable';
import AnalyticsPayToPromoteKPIs from 'views/analytics/containers/AnalyticsPayToPromoteKPIs/AnalyticsPayToPromoteKPIs';
import AnalyticsSingleEditionSettingsButton from 'views/analytics/containers/AnalyticsSingleEditionSettingsButton/AnalyticsSingleEditionSettingsButton';
import { getDatesFromSearchParams, updateDatesInSearchParams } from 'views/analytics/utils/searchParamsUtil';
import BadgesContainer, { BadgesAlignmentType } from 'views/badges/containers/BadgesContainer/BadgesContainer';
import CountryPicker, { areCountryCodesEqual } from 'views/common/components/CountryPicker/CountryPicker';
import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import SDSAlert from 'views/common/components/SDSAlert/SDSAlert';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import type { TabHeader } from 'views/common/components/SDSTabs/SDSTabs';
import SDSTabs from 'views/common/components/SDSTabs/SDSTabs';
import StoryStateIcon from 'views/common/components/StoryStateIcon/StoryStateIcon';
import AdAccountPicker from 'views/common/containers/AdAccountPicker/AdAccountPicker';
import ClaimGatedView from 'views/common/containers/ClaimGatedView/ClaimGatedView';
import SnapPublisherPreCache from 'views/editor/components/SnapPublisher/SnapPublisherPreCache';
import CalendarButton from 'views/homepage/containers/CalendarButton/CalendarButton';
import PayToPromoteButton from 'views/payToPromote/components/PayToPromoteButton/PayToPromoteButton';
import withProfileAdAccountsQuery from 'views/payToPromote/hocs/withProfileAdAccountsQuery/withProfileAdAccountsQuery';
import { ProfileAdAccountsQueryResult } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';

import style from './AnalyticsEditionView.scss';
import { glossaryOfAnalytics } from './AnalyticsEditionViewIntlMessage';
import type { AnalyticsTabsEnum } from './tabs/analyticsTabsConfig';
import { ANALYTICS_TABS } from './tabs/analyticsTabsConfig';

import type { TileStats } from 'types/analytics';
import { AnalyticsStatsType } from 'types/analytics';
import { CountryCodeCategory } from 'types/countries';
import type { EditionID } from 'types/editions';
import { StoryState } from 'types/editions';
import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';

const COUNTRY_CODE_SEPARATOR = '_';
type CountryPickerToggleResult = {
  isDropdownVisible: string;
  pickedCountryCodes: {
    [x: string]: string | string[];
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  match: any;
  history: any;
  location: any;
} & ProfileAdAccountsQueryResult;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type OwnState = {
  snapPreviewFrames: any;
  analyticsTabName: AnalyticsTabsEnum;
  isZeroCountriesAlertModalVisible: boolean;
};
export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { editionId } = ownProps.match.params;
  const primaryLanguage = publishersSelectors.getActivePublisherPrimaryLanguage(state);
  const activePublisher = publishersSelectors.getActivePublisherDetails(state);
  const publisherId = userSelectors.getActivePublisherId(state) || userSelectors.getActiveCreator(state)?.publisherId;
  const isSubscribable = publishersSelectors.isActivePublisherSubscribable(state);
  const edition = editionsSelectors.getEditionById(state)(editionId);
  const editionTiles = editionsSelectors.getSnapAndSegmentTilesByEditionId(state)(editionId);
  return {
    primaryLanguage,
    editionAnalytics: state.analytics.edition,
    edition,
    editionTiles,
    processedSnaps: state.analytics.processedSnaps,
    analytics: state.analytics,
    user: state.user,
    authToken: getToken(state),
    authType: getAuthType(state),
    activePublisher,
    publisherId,
    firstTile: getPresentationalTileForEdition(state)(editionId),
    pickedCountryCodes: state.analytics.countryCodes,
    editionDateRange: state.analytics.editionDateRange,
    isSubscribable,
    snaps: snapsSelectors.getAllSnaps(state),
    reachAffectedByModeration: isStoryReachAffectedByModeration(state)(editionId),
    serviceStatus: getServiceStatus(state),
    analyticsStatsType: getAnalyticsStatsType(state),
    isShowingSingleDayStats: isShowingSingleDayStats(state),
    isShowingAllTimeStats: isShowingAllTimeStats(state),
    chapters: getChaptersSummary(getStoryChapters(state)(editionId)),
  };
};
const mapDispatchToProps = {
  clearEdition24HoursStats,
  clearEditionDailyStats,
  clearEditionStats,
  clearSnapStats,
  fetchEdition24HoursStats,
  fetchEditionDailyStats,
  fetchEditionStats,
  fetchEditionStatsByCountries,
  fetchPollTimeline,
  fetchSnapStats,
  getEdition: editionsActions.getEditionIfStale,
  getSnapIds: editionsActions.getSnapIds,
  loadCountryCodes,
  loadSnapPreviewIfMissing: previewsActions.loadSnapPreviewIfMissing,
  loadSnapsIfRequired: snapsActions.loadSnapsIfRequired,
  persistCountryCodes,
  setCountryCodes,
  setEditionDateRange,
  showModal: modalsActions.showModal,
  goToPublisherStoryEditor: functionRef(routerActions, 'goToPublisherStoryEditor'),
  fetchServiceStatus,
  setAnalyticsStatsType,
  loadChaptersPreview,
};
export const TabsConfig: Record<string, TabHeader> = {
  [ANALYTICS_TABS.OVERVIEW]: {
    text: (
      <FormattedMessage
        id="analytics-edition-overview-tab-header"
        description="Text for Overview tab header"
        defaultMessage="Overview"
      />
    ),
  },
  [ANALYTICS_TABS.TILES]: {
    text: (
      <FormattedMessage
        id="analytics-edition-tiles-tab-header"
        description="Text for Tiles tab header"
        defaultMessage="Tiles"
      />
    ),
  },
  [ANALYTICS_TABS.TILES_NO_ANALYTICS]: {
    dotStatus: <DotStatus status={DotStatusState.INCOMPLETE} />,
    toolTipMessage: (
      <FormattedMessage
        id={'analytics-edition-tiles-no-tile-analytics-tab-header'}
        description="Tooltip for tile analytics not being available"
        defaultMessage="Tile Analytics are available when more than 1 tile is added to Snap 1. {br} In order to see analytics for the tile optimizer, please ensure the geo filter is set to Global."
        values={{
          br: <br />,
        }}
      />
    ),
    text: (
      <FormattedMessage
        id="analytics-edition-tiles-tab-header"
        description="Text for Tiles tab header"
        defaultMessage="Tiles"
      />
    ),
  },
  [ANALYTICS_TABS.PROMOTION]: {
    text: (
      <FormattedMessage
        id="analytics-edition-promotion-tab-header"
        description="Text for Promotion tab header"
        defaultMessage="Promotion"
      />
    ),
  },
};
export class AnalyticsEditionView extends React.Component<Props, OwnState> {
  mounted: boolean = false; // eslint-disable-line react/sort-comp

  static contextTypes = {
    intl: intlShape,
  };

  static path = '/publisher/:publisherId/analytics/story/:editionId';

  state = {
    snapPreviewFrames: [],
    analyticsTabName: ANALYTICS_TABS.OVERVIEW,
    isZeroCountriesAlertModalVisible: false,
  };

  UNSAFE_componentWillMount() {
    const { props } = this;
    this.fetchData(props);
    const snapPreviewFrames = getIndexedProcessedSnaps(props.processedSnaps);
    this.setState({ snapPreviewFrames });
  }

  componentDidMount() {
    this.props.setEditionDateRange(getDatesFromSearchParams(this.props.location));
    this.props.fetchServiceStatus();
    window.addEventListener('beforeunload', this.props.persistCountryCodes);
    this.mounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.processedSnaps !== nextProps.processedSnaps) {
      const snapPreviewFrames = getIndexedProcessedSnaps(nextProps.processedSnaps);
      this.setState({ snapPreviewFrames });
    }
    if (
      !isMomentSame(this.props.editionDateRange.from, nextProps.editionDateRange.from) ||
      !isMomentSame(this.props.editionDateRange.to, nextProps.editionDateRange.to) ||
      !areCountryCodesEqual(this.props.pickedCountryCodes, nextProps.pickedCountryCodes) ||
      this.props.analyticsStatsType !== nextProps.analyticsStatsType
    ) {
      // If we're on the promotion tab and we change the countries or date range, we need to refetch both PAID and ALL data.
      // Todo @gmcquaid look at creating an endpoint that returns both PAID and ALL data in one request.
      if (
        this.props.analyticsStatsType === AnalyticsStatsType.PAID &&
        nextProps.analyticsStatsType === AnalyticsStatsType.PAID
      ) {
        this.fetchData({ ...nextProps, analyticsStatsType: AnalyticsStatsType.ALL });
      }
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.props.persistCountryCodes);
    this.props.clearEditionStats();
    this.props.clearSnapStats();
    this.props.clearEdition24HoursStats();
    this.props.clearEditionDailyStats();
    this.mounted = false;
  }

  getActiveTabs(): TabHeader[] {
    const activeTabs = [TabsConfig[ANALYTICS_TABS.OVERVIEW]];
    if (this.getOrderedTileStats().length > 1) {
      activeTabs.push(TabsConfig[ANALYTICS_TABS.TILES]);
    } else {
      activeTabs.push(TabsConfig[ANALYTICS_TABS.TILES_NO_ANALYTICS]);
    }

    // @ts-ignore
    return [...activeTabs, TabsConfig[ANALYTICS_TABS.PROMOTION]];
  }

  onTabSelected = (index: number): void => {
    const selectedTab = this.getActiveTabs()[index];
    const analyticsTabName = Object.keys(TabsConfig).filter(key => TabsConfig[key] === selectedTab)[0];
    logGAEvent(GAUserActions.ANALYTICS, `navigate-to-edition-${analyticsTabName?.toLowerCase()}-tab`, {
      publisherName: this.props.activePublisher?.name,
    });
    if (analyticsTabName === ANALYTICS_TABS.PROMOTION) {
      this.props.setAnalyticsStatsType(AnalyticsStatsType.PAID);
    } else if (this.state.analyticsTabName === ANALYTICS_TABS.PROMOTION) {
      this.props.setAnalyticsStatsType(AnalyticsStatsType.ALL);
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.setState({ analyticsTabName });
  };

  onCountryPickerToggled = ({ isDropdownVisible, pickedCountryCodes }: CountryPickerToggleResult) => {
    if (isDropdownVisible) {
      return;
    }
    const pickedCountryCodesList = pickedCountryCodes[CountryCodeCategory.DEFAULT];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | string[] | undefined' i... Remove this comment to see the full error message
    if (areCountryCodesEqual(this.props.pickedCountryCodes, pickedCountryCodesList)) {
      return;
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (pickedCountryCodesList !== DEFAULT_ANALYTICS_GEO && pickedCountryCodesList.length === 0) {
      this.showZeroCountriesAlertModal();
      return;
    }
    this.props.setCountryCodes(pickedCountryCodesList);
  };

  onDateRangeUpdated = (from?: moment.Moment | null, to?: moment.Moment | null) => {
    const newFrom = from && from.isValid() ? from : null;
    const newTo = to && to.isValid() ? to : newFrom;
    this.props.setEditionDateRange({
      from: newFrom,
      to: newTo,
    });
    if (!!newFrom && !!newTo) {
      logGAEvent(GAUserActions.ANALYTICS, 'analytics-edition-view-date-picker', {
        publisherName: this.props.activePublisher?.name,
        noOfDays: newFrom.diff(newTo, 'days'),
      });
      updateDatesInSearchParams(this.props.history, newFrom, newTo);
    }
  };

  getTitle() {
    const headline = _.get(this.props.firstTile, 'headline');
    if (headline) {
      return headline;
    }
    const { editionId } = this.props.match.params;
    return (
      <FormattedMessage
        id="analytics-edition-view-default-title"
        description="Title for page of analytics about editions"
        defaultMessage="Publisher Story #{editionId}"
        values={{ editionId }}
      />
    );
  }

  getOrderedTileStats = (): TileStats => {
    if (this.props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO) {
      return _.get(this.props, ['editionAnalytics', AnalyticsStatsType.ALL, 'tileStats'], []) || [];
    }
    return [];
  };

  async fetchData(props: Props) {
    const publisherId = props.publisherId;
    const editionId: EditionID = parseInt(props.match.params.editionId, 10);
    const geo = DEFAULT_GEO;
    const { editionDateRange } = props;
    const { from, to } = editionDateRange;
    const startDate = from ? from.format('YYYY-MM-DD') : null;
    const endDate = to ? to.format('YYYY-MM-DD') : null;
    const countries = Array.isArray(props.pickedCountryCodes)
      ? encodeURI(props.pickedCountryCodes.join(COUNTRY_CODE_SEPARATOR))
      : '';
    const analyticsStatsType = props.analyticsStatsType;
    const geoType =
      props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO ? AnalyticsCountryType.GLOBAL : AnalyticsCountryType.COUNTRY;
    if (startDate) {
      if (startDate === endDate) {
        props.fetchEdition24HoursStats({
          editionId,
          startDate,
          countries,
          geoType,
          analyticsStatsType,
        });
      }
      if (endDate) {
        // We need the daily stats for any date range (even the 24-hour view) in order to calculate KPIs
        props.fetchEditionDailyStats({
          editionId,
          startDate,
          endDate,
          countries,
          geoType,
          analyticsStatsType,
        });
      }
    } else {
      if (props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO) {
        props.fetchEditionStats(editionId, analyticsStatsType);
      } else {
        props.fetchEditionStatsByCountries(editionId, countries, analyticsStatsType);
      }
      const fetchParams = {
        editionId,
        publisherId,
        geoType,
        geo,
        userId: props.user.info.userId,
        countries,
        startDate,
        endDate,
        analyticsStatsType,
      };
      props.fetchSnapStats(fetchParams);
      props.fetchPollTimeline(fetchParams);
      await props.getEdition({ editionId });
      const data: {} = await props.getSnapIds({ editionId });
      const snapIds = _.get(data, ['payload']);
      await props.loadSnapsIfRequired({ snapIds });
      snapIds.forEach(props.loadSnapPreviewIfMissing);
      // No need to wait for the promise to resolve.
      props.loadChaptersPreview(editionId);
    }
  }

  closeShowZeroCountriesAlertModal = () => {
    this.setState({ isZeroCountriesAlertModalVisible: false });
  };

  renderZeroCountriesAlertModal = () => {
    return (
      <SDSAlert
        visible={this.state.isZeroCountriesAlertModalVisible}
        onConfirm={this.closeShowZeroCountriesAlertModal}
        data-test={'analyticsEditionView.sdsAlert'}
      >
        <FormattedMessage
          id="alert-zero-countries"
          description="Alert message for not selecting any country"
          defaultMessage="Please select at least one country."
        />
      </SDSAlert>
    );
  };

  showZeroCountriesAlertModal = () => {
    this.setState({ isZeroCountriesAlertModalVisible: true });
  };

  goToPublisherStoryEditor = () => {
    const publisherId = this.props.publisherId;
    const editionId = _.get(this.props, 'match.params.editionId', 0);
    this.props.goToPublisherStoryEditor({ publisherId, editionId, overwriteHistory: false });
  };

  renderGraphs() {
    return (
      <AnalyticsStoryGraphs
        analytics={this.props.analytics}
        chapters={this.props.chapters}
        snapPreviewFrames={this.state.snapPreviewFrames}
        isGlobalGeo={this.props.pickedCountryCodes === DEFAULT_ANALYTICS_GEO}
        isShowingAllTimeStats={this.props.isShowingAllTimeStats}
        isShowingSingleDayStats={this.props.isShowingSingleDayStats}
        isSubscribable={this.props.isSubscribable}
        analyticsStatsType={this.props.analyticsStatsType}
        primaryLanguage={this.props.primaryLanguage}
      />
    );
  }

  renderStoryInfo = () => {
    const { edition, editionAnalytics, chapters } = this.props;
    if (!edition) {
      return null;
    }
    const durationMs = chapters ? chapters.reduce((ms, chapter) => ms + chapter.durationMs, 0) : undefined;
    return (
      <AnalyticsContentInfo
        edition={edition}
        reachAffectedByModeration={this.props.reachAffectedByModeration}
        title={glossaryOfAnalytics}
        subtitle={getMessageFromId('analytics-edition-story-info')}
        snapCount={edition.snapIds.length}
        videoDurationMs={durationMs}
        tileCount={this.props.editionTiles.length}
        editionMetrics={_.get(editionAnalytics, [this.props.analyticsStatsType, 'editionMetrics', edition.id])}
      />
    );
  };

  renderSnapListContainer = () => {
    const storyId = Number(this.props.match.params.editionId);
    return (
      <div className={style.snapListContainer}>
        {this.renderStoryInfo()}
        <AnalyticsTopsnapPreview
          editionId={storyId}
          processedSnaps={this.props.processedSnaps}
          snaps={this.props.snaps}
          chapters={this.props.chapters}
          isAnalyticsV2Enabled={false}
        />
      </div>
    );
  };

  renderTopsnapPreview = () => {
    if (!this.props.isShowingAllTimeStats) {
      return null;
    }
    return (
      <Row>
        <Col xs={12} className={style.topsnapPreviewContainer}>
          <div className={style.cardBox}>{this.renderSnapListContainer()}</div>
        </Col>
      </Row>
    );
  };

  renderEditionKPIs = () => {
    const storyId = Number(this.props.match.params.editionId);
    const storyAnalytics = this.props.isShowingAllTimeStats
      ? _.get(this.props, ['editionAnalytics', this.props.analyticsStatsType, 'editionMetrics'], null)
      : _.get(this.props, ['analytics', 'editionDaily', this.props.analyticsStatsType, 'editionMetrics'], null);
    const currentStoryAnalytics = storyAnalytics && storyAnalytics[storyId] ? storyAnalytics[storyId] : {};
    return (
      <Row>
        <AnalyticsStoryKPIs
          activePublisher={this.props.activePublisher}
          storyAnalytics={currentStoryAnalytics}
          data-test="AnalyticsEditionView.analyticsStoryKPIs"
        />
      </Row>
    );
  };

  renderDefaultTab() {
    return (
      <div data-test="editionAnalyticsDefaultTab">
        {this.renderEditionKPIs()}
        {this.renderTopsnapPreview()}
        {this.renderGraphs()}
      </div>
    );
  }

  renderPromotionTab() {
    return (
      <div data-test="AnalyticsEditionView.promotionTab">
        <AnalyticsPayToPromoteKPIs
          data-test="AnalyticsEditionView.analyticsPayToPromoteKPIs"
          storyId={this.props.edition?.id}
          isStoryPromoted={isStoryPromoted(this.props.edition?.id, this.props.promotedStoriesIds)}
        />
        {this.renderTopsnapPreview()}
        {this.renderGraphs()}
      </div>
    );
  }

  renderCountryPicker() {
    return (
      <div className={style.countryPicker}>
        <CountryPicker
          width={150}
          /* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | string[]' is not assignable to type... Remove this comment to see the full error message */
          pickedCountryCodes={this.props.pickedCountryCodes}
          dropdownClassName={style.countryPickerDropdown}
          onChange={noop}
          /* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | string[]' is not assignable to type... Remove this comment to see the full error message */
          onToggle={this.onCountryPickerToggled}
        />
      </div>
    );
  }

  renderDatePicker() {
    const noDateText = (
      <FormattedMessage
        defaultMessage="All-Time"
        id="analytics-edition-no-date-range"
        description="Default text for the analytics edition date range button when no range has been selected"
      />
    );
    const dateRange = this.props.editionDateRange;
    return (
      <CalendarButton
        className={style.datePickerButton}
        onUpdate={this.onDateRangeUpdated}
        defaultText={noDateText}
        from={dateRange && dateRange.from}
        to={dateRange && dateRange.to}
      />
    );
  }

  renderControlGroup() {
    return (
      <div className={style.controlGroup} data-test="editionAnalyticsControlGroup">
        <div className={style.expand} />
        {this.props.edition?.state !== StoryState.HIDDEN && (
          <PayToPromoteButton
            buttonType={ButtonType.SECONDARY}
            storyId={this.props.edition?.id}
            adAccountId={this.props.adAccountId}
            error={this.props.profileAdAccountsError}
            data-test="AnalyticsEditionView.payToPromoteButton"
          />
        )}
        <div className={(style as any).viewStoryButtonContainer}>
          <SDSButton
            type={ButtonType.SECONDARY}
            inlineIcon={pencil}
            onClick={this.goToPublisherStoryEditor}
            data-test="analytics.edition.viewStory.button"
          >
            {getMessageFromId('view-story-button')}
          </SDSButton>
        </div>
        {this.renderDatePicker()}
        {this.renderCountryPicker()}
        {<AdAccountPicker data-test="AnalyticsEditionView.adAccountPicker" />}
        <AnalyticsSingleEditionSettingsButton editionId={this.props.match.params.editionId} />
      </div>
    );
  }

  renderContent = () => {
    switch (this.state.analyticsTabName) {
      case ANALYTICS_TABS.TILES:
      case ANALYTICS_TABS.TILES_NO_ANALYTICS:
        return (
          <TileVariantTable
            editionId={this.props.match.params.editionId}
            orderedTileStats={this.getOrderedTileStats()}
          />
        );
      case ANALYTICS_TABS.PROMOTION:
        return this.renderPromotionTab();
      default:
      case ANALYTICS_TABS.OVERVIEW:
        return this.renderDefaultTab();
    }
  };

  renderPage = () => {
    if (!this.props.edition) {
      return null;
    }

    const { edition, serviceStatus } = this.props;
    const activeTabs = this.getActiveTabs();
    return (
      <Grid fluid>
        <div data-test="analytics.analyticsEditionview.gridPage" className={style.header}>
          <div className={style.headerText}>
            <StoryStateIcon storyState={edition.state} />
            {this.getTitle()}
            {serviceStatus?.status ? <AnalyticsStatusIcon serviceStatus={serviceStatus} /> : null}
            <div className={style.badgeContainer}>
              <BadgesContainer
                styleOptions={{ direction: BadgesAlignmentType.ROW }}
                featuresOptions={{
                  isStoryPromoted: isStoryPromoted(this.props.edition?.id, this.props.promotedStoriesIds),
                  isStorySponsored: this.props.edition.isSponsored,
                }}
              />
            </div>
          </div>
          {activeTabs.length > 1 && (
            <div className={style.tabsContainer}>
              <SDSTabs defaultTab={0} handleTabSelected={this.onTabSelected} tabs={activeTabs} />
            </div>
          )}
          {this.renderControlGroup()}
        </div>
        <div className={style.content}>{this.renderContent()}</div>
        <AnalyticsFooter />
      </Grid>
    );
  };

  render() {
    return (
      <ClaimGatedView requiredClaim={Claim.ANALYTICS_VIEWER}>
        {this.renderPage()}
        {this.renderZeroCountriesAlertModal()}
        {this.props.chapters && <SnapPublisherPreCache handshake />}
      </ClaimGatedView>
    );
  }
}
function getIndexedProcessedSnaps(processedSnaps: any) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'snap' implicitly has an 'any' type.
  return (processedSnaps || []).map((snap, index) => ({
    ...snap,
    index,
  }));
}
function isMomentSame(a: any, b: any) {
  return a ? a.isSame(b) : !b;
}
export default withProfileAdAccountsQuery(
  withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsEditionView))
);
