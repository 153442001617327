import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE, GRAPH_SHADES_OF_GREEN } from 'views/analytics/utils/chartConfigs';

import type { StoryDemographMetrics, StoryMetricResults } from 'types/analytics';

type Props = {
  isLoading: boolean;
  metrics: StoryDemographMetrics[];
  metricsV2: StoryMetricResults;
  isOnPromotionTab: boolean;
  isAnalyticsV2?: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="demographics-male-organic"
      defaultMessage="Male Organic"
      description="Organic impressions male demographic"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="demographics-male-paid"
      defaultMessage="Male Paid"
      description="Paid impressions male demographic"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="demographics-female-organic"
      defaultMessage="Female Organic"
      description="Organic impressions female demographic"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="demographics-female-paid"
      defaultMessage="Female Paid"
      description="Paid impressions female demographic"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="demographics-unknown-organic"
      defaultMessage="Unknown Organic"
      description="Organic impressions unknown demographic"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="demographics-unknown-paid"
      defaultMessage="Unknown Paid"
      description="Paid impressions unknown demographic"
    />
  ),
  params: [],
});

export class AnalyticsDemographicsGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getBarChartData = () => {
    if (!this.props.isAnalyticsV2 && !this.props.isOnPromotionTab) {
      return this.props.metrics;
    }
    if (this.props.isAnalyticsV2 && !this.props.isOnPromotionTab) {
      return [
        {
          viewers13to17: this.props.metricsV2?.uniqueViewers13to17,
          viewers18to24: this.props.metricsV2?.uniqueViewers18to24,
          viewers25to34: this.props.metricsV2?.uniqueViewers25to34,
          viewers35Plus: this.props.metricsV2?.uniqueViewers35Plus,
        },
      ];
    }

    return this.props.metrics.map(metric => ({
      organicMale: metric.male - metric.paidMale,
      organicFemale: metric.female - metric.paidFemale,
      organicUnknown: metric.unknown - metric.paidUnknown,
      ...metric,
    }));
  };

  getDemographicsConfigsV2 = () => {
    if (this.props.isOnPromotionTab) {
      return [];
    }

    return [
      {
        key: 'viewers13to17',
        name: getLocalisedMessageFromId(this.context, 'demographics-age-13-to-17'),
        fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
        stackId: 'all',
      },
      {
        key: 'viewers18to24',
        name: getLocalisedMessageFromId(this.context, 'demographics-age-18-to-24'),
        fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
        stackId: 'all',
      },
      {
        key: 'viewers25to34',
        name: getLocalisedMessageFromId(this.context, 'demographics-age-25-to-34'),
        fill: GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE,
        stackId: 'all',
      },
      {
        key: 'viewers35Plus',
        name: getLocalisedMessageFromId(this.context, 'demographics-age-35-plus'),
        fill: GRAPH_SHADES_OF_BLUE.MED_BLUE,
        stackId: 'all',
      },
    ];
  };

  getDemographicsConfigs = () => {
    if (!this.props.isOnPromotionTab) {
      return [
        {
          key: 'male',
          name: getLocalisedMessageFromId(this.context, 'demographics-male'),
          fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
          stackId: 'all',
        },
        {
          key: 'female',
          name: getLocalisedMessageFromId(this.context, 'demographics-female'),
          fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
          stackId: 'all',
        },
        {
          key: 'unknown',
          name: getLocalisedMessageFromId(this.context, 'demographics-unknown'),
          fill: GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE,
          stackId: 'all',
        },
      ];
    }

    return [
      {
        key: 'organicMale',
        name: getLocalisedMessageFromId(this.context, 'demographics-male-organic'),
        fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
        stackId: 'male',
      },
      {
        key: 'paidMale',
        name: getLocalisedMessageFromId(this.context, 'demographics-male-paid'),
        fill: GRAPH_SHADES_OF_GREEN.NORMAL_GREEN,
        stackId: 'male',
      },
      {
        key: 'organicFemale',
        name: getLocalisedMessageFromId(this.context, 'demographics-female-organic'),
        fill: GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
        stackId: 'female',
      },
      {
        key: 'paidFemale',
        name: getLocalisedMessageFromId(this.context, 'demographics-female-paid'),
        fill: GRAPH_SHADES_OF_GREEN.STRONG_GREEN,
        stackId: 'female',
      },
      {
        key: 'organicUnknown',
        name: getLocalisedMessageFromId(this.context, 'demographics-unknown-organic'),
        fill: GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE,
        stackId: 'unknown',
      },
      {
        key: 'paidUnknown',
        name: getLocalisedMessageFromId(this.context, 'demographics-unknown-paid'),
        fill: GRAPH_SHADES_OF_GREEN.EXTRA_LIGHT_GREEN,
        stackId: 'unknown',
      },
    ];
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const {
      name,
      male,
      female,
      unknown,
      organicMale,
      paidMale,
      organicFemale,
      paidFemale,
      organicUnknown,
      paidUnknown,
    } = payload;
    const demographicsConfigs = this.getDemographicsConfigs();
    let metricRows;

    if (!this.props.isOnPromotionTab) {
      metricRows = [
        { metricName: demographicsConfigs[0]?.name, metricValue: numberFormatter.privacyNumberFormat(male) },
        { metricName: demographicsConfigs[1]?.name, metricValue: numberFormatter.privacyNumberFormat(female) },
        { metricName: demographicsConfigs[2]?.name, metricValue: numberFormatter.privacyNumberFormat(unknown) },
      ];
    } else {
      metricRows = [
        { metricName: demographicsConfigs[0]?.name, metricValue: numberFormatter.privacyNumberFormat(organicMale) },
        { metricName: demographicsConfigs[1]?.name, metricValue: numberFormatter.privacyNumberFormat(paidMale) },
        { metricName: demographicsConfigs[2]?.name, metricValue: numberFormatter.privacyNumberFormat(organicFemale) },
        { metricName: demographicsConfigs[3]?.name, metricValue: numberFormatter.privacyNumberFormat(paidFemale) },
        { metricName: demographicsConfigs[4]?.name, metricValue: numberFormatter.privacyNumberFormat(organicUnknown) },
        { metricName: demographicsConfigs[5]?.name, metricValue: numberFormatter.privacyNumberFormat(paidUnknown) },
      ];
    }

    return <AnalyticsTooltip title={name} metricRows={metricRows} />;
  };

  renderChartTooltipV2 = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, viewers13to17, viewers18to24, viewers25to34, viewers35Plus } = payload;
    const demographicsConfigs = this.getDemographicsConfigsV2();

    const metricRows = [
      { metricName: demographicsConfigs[0]?.name, metricValue: numberFormatter.privacyNumberFormat(viewers13to17) },
      { metricName: demographicsConfigs[1]?.name, metricValue: numberFormatter.privacyNumberFormat(viewers18to24) },
      { metricName: demographicsConfigs[2]?.name, metricValue: numberFormatter.privacyNumberFormat(viewers25to34) },
      { metricName: demographicsConfigs[3]?.name, metricValue: numberFormatter.privacyNumberFormat(viewers35Plus) },
    ];

    return <AnalyticsTooltip title={name} metricRows={metricRows} />;
  };

  render() {
    return (
      <AnalyticsBarChart
        barChartData={this.getBarChartData()}
        barChartTitle={getMessageFromId('story-story-demographics')}
        bars={this.props.isAnalyticsV2 ? this.getDemographicsConfigsV2() : this.getDemographicsConfigs()}
        isLoading={this.props.isLoading}
        shouldShowToggle
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.props.isAnalyticsV2 ? this.renderChartTooltipV2 : this.renderChartTooltip}
        yAxisTickFormatter={numberFormatter.privacyNumberFormat}
      />
    );
  }
}

export default AnalyticsDemographicsGraph;
