import React, { ReactNode, useEffect } from 'react';

import {
  getActivePublisherBusinessProfileId,
  getActivePublisherDetails,
  getActivePublisherOrgId,
} from 'state/publishers/selectors/publishersSelectors';
import { setSelectedAdAccountId as setSelectedAdAccountIdAction } from 'state/user/actions/userActions';
import { getSelectedAdAccountId } from 'state/user/selectors/userSelectors';

import { intlConnect } from 'utils/connectUtils';
import { incrementCounterByPublisher, reportLevelByPublisher } from 'utils/grapheneUtils';

import { useProfileAdAccountsQuery } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';

import { ExtractDispatchProps } from 'types/redux';
import { State as RootState } from 'types/rootState';

type OwnProps = {
  children: ReactNode;
};

type StateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => {
  return {
    activePublisher: getActivePublisherDetails(state),
    bizProfileId: getActivePublisherBusinessProfileId(state),
    orgId: getActivePublisherOrgId(state),
    selectedAdAccountId: getSelectedAdAccountId(state),
  };
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

const mapDispatchToProps = {
  setSelectedAdAccountId: setSelectedAdAccountIdAction,
};

type Props = OwnProps & StateProps & DispatchProps;

export default function withProfileAdAccountsQuery(Component: typeof React.Component) {
  function WrappedComponent(props: Props) {
    const { activePublisher, bizProfileId, orgId, selectedAdAccountId, setSelectedAdAccountId } = props;
    const { profileAdAccountsError, adAccountId, adAccounts, promotedStoriesIds } = useProfileAdAccountsQuery(
      orgId,
      bizProfileId
    );

    useEffect(() => {
      setSelectedAdAccountId(adAccountId ?? null);

      // Always select the main ad account ID when the Ad Account list changes (e.g. user switch to different profile)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adAccounts]);

    useEffect(() => {
      incrementCounterByPublisher(activePublisher, 'p2p.fetching', { query: 'ProfileAdAccountsQuery' });

      // Excluding the `activePublisher` from deps check to not cause refetch if object changes, bizProfileId is enough
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, bizProfileId]);

    const promotedStoryIdsSize = promotedStoriesIds?.size || 0;

    // metrics
    useEffect(() => {
      if (!profileAdAccountsError && promotedStoryIdsSize > 0) {
        reportLevelByPublisher(activePublisher, 'p2p.promotedStories', undefined, promotedStoryIdsSize);
      }

      if (profileAdAccountsError) {
        incrementCounterByPublisher(activePublisher, 'p2p.error.adAccountsQueryError', undefined, promotedStoryIdsSize);
      }
      // Excluding the `activePublisher` from deps check to not cause refetch if object changes, bizProfileId is enough
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, bizProfileId, profileAdAccountsError, promotedStoryIdsSize]);

    return (
      <Component
        {...props}
        profileAdAccountsError={profileAdAccountsError}
        adAccountId={selectedAdAccountId ?? adAccountId}
        promotedStoriesIds={promotedStoriesIds}
        data-test={'withProfileAdAccountsQuery.component'}
      >
        {props.children}
      </Component>
    );
  }

  return intlConnect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}
