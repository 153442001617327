import _ from 'lodash';
import React from 'react';

import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';

import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import EditionTilePreview from 'views/common/containers/EditionTilePreview/EditionTilePreview';

import style from './MobileTilesView.scss';

import type { SnapId } from 'types/common';
import type { State as RootState } from 'types/rootState';
import type { Tile } from 'types/tiles';

const SPACE_BETWEEN_TILES = 10;

type OwnProps = {
  snapId: SnapId;
  snapIndex: number;
};

type StateProps = {
  tiles: Tile[] | undefined | null;
};

type Props = OwnProps & StateProps;

type State = {
  tileWidth: number | undefined | null;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  return {
    tiles: snapsSelectors.getTilesByWholeSnapId(state)(ownProps.snapId),
  };
};

export class MobileTilesView extends React.Component<Props, State> {
  state = { tileWidth: null };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'React$ElementRef'.
  refCallback = (element?: React$ElementRef<'div'> | null) => {
    if (element) {
      const tilesContainerWidth = element.getBoundingClientRect();

      // The height of each tile will be scaled automatically to keep the same ratio that we
      // have in the app. For that reason we can't just use CSS, cause the height is dynamically
      // calculated based on the width.
      // To calculate the width of each tile, we first get the width of the whole container, remove
      // the space that we need between the tiles and divide by two, because we will have exactly
      // two tiles per row.
      const tileWidth = (tilesContainerWidth.width - SPACE_BETWEEN_TILES) / 2;
      this.setState({ tileWidth });
    }
  };

  renderTiles = () => {
    // If we don't have the tileWidth yet, then we can't render the tiles
    // as we don't know which size they are gonna have
    if (!this.state.tileWidth || !this.props.tiles) {
      return null;
    }

    return _.map(this.props.tiles, tile => (
      <EditionTilePreview className={style.tile} key={tile.id} tile={tile} scaleToWidth={this.state.tileWidth} />
    ));
  };

  render() {
    const { tiles, snapIndex } = this.props;
    if (!tiles || tiles.length === 0) {
      return null;
    }

    return (
      <div className={style.tilesView}>
        <div className={style.title}>{getMessageFromId('analytics-snap-tile', { index: snapIndex })}</div>
        <div className={style.tiles} ref={this.refCallback}>
          {this.renderTiles()}
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(MobileTilesView);
