import React from 'react';
import { FormattedMessage } from 'react-intl';

import { removeFeatureFromTier } from 'state/features/admin/actions/featuresActions';

import { crossCircle } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import AlertBox, { AlertType } from 'views/common/components/AlertBox/AlertBox';
import SDSButton, { ButtonSize, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSModal from 'views/common/components/SDSModal/SDSModal';

import style from './RemovableTierItemWithButton.scss';

import { ExtractDispatchProps } from 'types/redux';

type ExternalProps = {
  tierId: string;
  featureId: string;
};

type State = {
  isModalVisible: boolean;
};

const mapDispatchToProps = {
  removeFeatureFromTier,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps;

export class RemovableTierItemWithButton extends React.PureComponent<Props, State> {
  state = {
    isModalVisible: false,
  };

  handleButtonClick = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleOk = () => {
    this.props.removeFeatureFromTier(this.props.featureId, this.props.tierId);
    this.setState({ isModalVisible: false });
  };

  renderModalContent = () => {
    return (
      <div>
        {`You are about to remove the feature ${this.props.featureId} from the ${this.props.tierId} tier`}
        <AlertBox className={style.alertBox} type={AlertType.WARNING}>
          <div className={style.alertContent}>
            {<div className={style.textWarning}>This might have serious impact on many publishers</div>}
            {<div>Are you sure you wish to proceed ?</div>}
          </div>
        </AlertBox>
      </div>
    );
  };

  renderModal = () => {
    return (
      <SDSModal
        title={
          <FormattedMessage
            id="remove-feature-from-tier"
            defaultMessage="Remove Feature From Tier"
            description="Title for a dialog for removing features from tier."
          />
        }
        visible={this.state.isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        {this.renderModalContent()}
      </SDSModal>
    );
  };

  render() {
    return (
      <div>
        <div className={style.tierElement}>
          {this.props.tierId}
          <SDSButton
            inlineIcon={crossCircle}
            className={style.removeFromTierIcon}
            onClick={this.handleButtonClick}
            size={ButtonSize.SMALL}
            shape={ButtonShape.CIRCLE}
          />
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

export default intlConnect(null, mapDispatchToProps)(RemovableTierItemWithButton);
