import { useState } from 'react';

import { UploaderState } from 'types/storySnapEditor';

export const useUploaderState = () => {
  const [uploadState, setUploaderState] = useState<UploaderState>(UploaderState.NOT_STARTED);

  const isUploadNotReady = () => {
    return uploadState !== UploaderState.DONE;
  };

  const isSubmittedState = () => {
    return uploadState === UploaderState.SUBMITTED || uploadState === UploaderState.SUBMITTED_ERROR;
  };

  return {
    state: { uploadState, setUploaderState },
    utils: { isUploadNotReady, isSubmittedState },
  };
};
