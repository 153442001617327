import is from 'is_js';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import {
  AnalyticsV2Snap,
  SnapPreviewMetadata,
} from 'views/analytics/components/AnalyticsTopsnapPreview/AnalyticsTopsnapPreview';

import {
  PreviewFrame,
  ProcessedSnapMetricResultsV2,
  SnapAnalyticsEntry,
  SnapAnalyticsResult,
  SnapAnalyticsResultEntry,
  StoryAnalyticsStat,
  StoryMetricKPI,
  StoryMetricKPIPaid,
  TileAnalyticsMetric,
} from 'types/analytics';
import { SnapRelationship } from 'types/snaps';

function getValue(metric: any) {
  const parsedValue = parseFloat(metric);
  return is.not.number(parsedValue) ? 0 : parsedValue;
}

function formatSeekTime(milliseconds: number): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const getSnapPreview = (snapAnalytics: SnapAnalyticsResultEntry | null, index: number): SnapPreviewMetadata => {
  const snapTitle = getMessageFromId('snap-index-in-edition', { index: index + 1 });
  return {
    snapId: snapAnalytics?.snapId || '',
    mediaUrl: snapAnalytics?.mediaUrl || '',
    seekPoint: formatSeekTime(snapAnalytics?.seekPoint || 0),
    duration: snapAnalytics?.duration || 0,
    isContentDeleted: false,
    title: snapTitle,
  };
};

export const getProcessedSnap = (snapAnalytics: SnapAnalyticsResultEntry | null, index: number): AnalyticsV2Snap => {
  const snapTitle = getMessageFromId('snap-index-in-edition', { index: index + 1 });
  return {
    snapId: snapAnalytics?.snapId || '',
    mediaUrl: snapAnalytics?.mediaUrl || '',
    seekPoint: formatSeekTime(snapAnalytics?.seekPoint || 0),
    duration: snapAnalytics?.duration || 0,
    isContentDeleted: false,
    id: snapAnalytics?.snapId || '',
    mediaName: snapTitle,
    segmentId: '',
    runtime: snapAnalytics?.duration || 0,
  };
};

export const getPreviewSnapAnalytics = (
  snaps: SnapAnalyticsResult,
  previewFramesUrl: string[],
  firstTile: TileAnalyticsMetric
): PreviewFrame[] => {
  return snaps.map((snap, index) => {
    return {
      snapIndex: index,
      imgSrc: index === 0 ? firstTile?.tileImageUrl : previewFramesUrl[index],
      src: previewFramesUrl[index] || '',
      title: index === 0 ? firstTile?.headline : '',
      index,
      publisherLogoSrc: firstTile?.tileLogoUrl || '',
    } as PreviewFrame;
  });
};

export const getSnapDuration = (currentIndex: number, snapAnalytics: SnapAnalyticsResult) => {
  const currentSnap = snapAnalytics[currentIndex];
  if (currentSnap?.seekPoint !== null) {
    const lastSnapIndex = snapAnalytics.length - 1;
    if (currentIndex === lastSnapIndex) {
      return currentSnap?.duration - currentSnap?.seekPoint;
    }
    const nextSnap = snapAnalytics[currentIndex + 1];
    return nextSnap?.seekPoint - currentSnap?.seekPoint;
  }
  return currentSnap?.duration || 0;
};

export const formatSnapMetrics = (
  index: number,
  snapId: string | undefined,
  duration: number | null | undefined,
  mediaUrl: string | null | undefined,
  seekPoint: number | null | undefined,
  isPaidStats: boolean,
  snap: SnapAnalyticsEntry | null | undefined,
  snapAnalyticsResult: SnapAnalyticsResult | null | undefined
) => {
  if (!snap) {
    return {};
  }

  const name = getMessageFromId('snap-index-in-edition', { index: index + 1 });

  // metrics
  const uniques = getValue(snap?.uniqueViewers);
  const paidUniques = getValue(snap?.uniqueViewersPaid);
  const uniqueSubscriberViewers = getValue(snap?.uniqueViewersSubscriber);
  const uniqueNonSubscriberViewers = getValue(snap?.uniqueViewersNonSubscriber);
  const attachmentTimeSpent = Math.ceil(getValue(snap?.attachmentTimeSpentMsOrganic));
  const paidAttachmentTimeSpent = Math.ceil(getValue(snap?.attachmentTimeSpentMsPaid));
  const completionRate = getValue(snap?.snapCompletionRate);
  const completionRatePaid = getValue(snap?.snapCompletionRatePaid);
  const completionRateOrganic = getValue(snap?.snapCompletionRateOrganic);
  const conversion = getValue(snap?.attachmentConversion);
  const conversionPaid = getValue(snap?.attachmentConversionPaid);
  const conversionOrganic = getValue(snap?.attachmentConversionOrganic);
  const dropOffRates = getValue(snap?.snapDropOffRate);
  const paidDropOffRates = getValue(snap?.snapDropOffRatePaid);
  const organicDropOffRates = getValue(snap?.snapDropOffRateOrganic);
  const runTime = getSnapDuration(index, snapAnalyticsResult!);
  const screenshots = getValue(snap?.screenshots);
  const paidScreenshots = getValue(snap?.screenshotsPaid);
  const organicScreenshots = getValue(snap?.screenshotsOrganic);
  const shares = getValue(snap?.shares);
  const paidShares = getValue(snap?.sharesPaid);
  const organicShares = getValue(snap?.sharesOrganic);
  const timeSpent = getValue(snap?.timeSpentMs);
  const paidTimeSpent = getValue(snap.timeSpentMsPaid);
  const organicTimeSpent = getValue(snap.timeSpentMsOrganic);
  const formattedSeekPoint = formatSeekTime(seekPoint || 0);
  return {
    snapId,
    attachmentTimeSpent,
    paidAttachmentTimeSpent,
    completionRate,
    completionRatePaid,
    completionRateOrganic,
    conversion,
    conversionOrganic,
    conversionPaid,
    dropOffRates,
    organicDropOffRates,
    paidDropOffRates,
    name,
    runTime,
    screenshots,
    paidScreenshots,
    organicScreenshots,
    shares,
    paidShares,
    organicShares,
    timeSpent,
    paidTimeSpent,
    organicTimeSpent,
    uniques,
    paidUniques,
    uniqueSubscriberViewers,
    uniqueNonSubscriberViewers,
    mediaUrl,
    formattedSeekPoint,
  };
};

export const formatStoryMetricNonPaid = (storyStat: StoryAnalyticsStat): StoryMetricKPI => {
  return {
    avgTotalTimeViewed: storyStat?.timeSpentMs,
    longformConversionRate: storyStat?.attachmentConversion,
    longformViewCount: storyStat?.totalStoryViews,
    reachAffectedByModeration: false, // Not available
    totalScreenshotCount: storyStat?.screenshots,
    publisherSubscribeCount: storyStat?.subscribersAdded,
    totalShareCount: storyStat?.shares,
    uniqueDau: storyStat?.uniqueViewers,
    uniqueSubscriberViewers: storyStat?.uniqueViewersSubscriber,
    uniqueViewers: storyStat?.uniqueViewers,
    topSnapViewCount: storyStat?.totalStoryViews,
    uniqueTopSnapViewCount: storyStat?.totalStoryViews,
    uniqueTopSnapViewPerUser: storyStat?.snapsPerUser,
    avgViewedPercentage: 0, // Not available
    publisherSubscribeCountPaid: storyStat?.subscribersAddedPaid,
    uniqueViewers13to17: storyStat?.uniqueViewers13to17,
    uniqueViewers18to24: storyStat?.uniqueViewers18to24,
    uniqueViewers25to34: storyStat?.uniqueViewers25to34,
    uniqueViewers35Plus: storyStat?.uniqueViewers35Plus,
    uniqueViewersMale: storyStat?.uniqueViewersMale,
    uniqueViewersFemale: storyStat?.uniqueViewersFemale,
  };
};

export const formatStoryMetricPaid = (storyStat: StoryAnalyticsStat): StoryMetricKPIPaid => {
  return {
    uniqueDau: storyStat?.uniqueViewers,
    uniqueDauPaid: storyStat?.uniqueViewersPaid,
    publisherSubscribeCount: storyStat?.subscribersAdded,
    publisherSubscribeCountPaid: storyStat?.subscribersAddedPaid,
  };
};

export const EDITION_ANALYTICS_RESULT = {
  data: {
    editionData: {
      id: 760062101026816,
      state: 'LIVE',
      tiles: [
        {
          id: 'SNAP-760062101026816-760062101026817-63df554d-f7f5-4241-a1e4-1387d848c27e',
          headline: 'Test Snap 1',
        },
      ],
      snapIds: ['760062101026816-760062101026817', '760062101026816-760062101026816'],
      firstLiveDate: '2023-08-22T15:30:18Z',
      ageGate: 'UNRESTRICTED',
      isSponsored: false,
      tags: [
        {
          type: 'SCC',
          value: 'Fashion & Style',
          usage: 61,
        },
        {
          type: 'SCC',
          value: 'News & Current Events',
          usage: 61,
        },
        {
          type: 'SCC',
          value: 'Pop Culture & Celebrity News',
          usage: 61,
        },
        {
          type: 'WIKI',
          value: 'Showbiz',
          usage: 61,
        },
        {
          type: 'WIKI',
          value: 'Fashion',
          usage: 61,
        },
      ],
    },
    editionAnalytics: {
      editionStats: {
        // KPIs
        uniqueDau: '1000',
        publisherSubscribeCount: '5000',
        uniqueTopSnapViewPerUser: '10',
        avgTotalTimeViewed: '54.3790',
        longformConversionRate: '0.05',
        totalShareCount: '89',
        uniqueTopSnapViewCount: '1878485',
        uniqueSubscriberViewers: '85453',
        totalScreenshotCount: '500',
        uniqueViewers: '173234',
        topSnapViewCount: '1885',
        longformViewCount: '485',
        avgViewedPercentage: '10',
        reachAffectedByModeration: true,

        // Demographics
        uniqueMale1317Viewers: '230',
        uniqueMale1820Viewers: '230',
        uniqueMale2124Viewers: '230',
        uniqueMale2534Viewers: '230',
        uniqueMale35PlusViewers: '230',
        uniqueMaleUnknownViewers: '230',
        uniqueFemale1317Viewers: '230',
        uniqueFemale1820Viewers: '230',
        uniqueFemale2124Viewers: '230',
        uniqueFemale2534Viewers: '230',
        uniqueFemale35PlusViewers: '230',
        uniqueFemaleUnknownViewers: '230',
        uniqueUnknown1317Viewers: '230',
        uniqueUnknown1820Viewers: '230',
        uniqueUnknown2124Viewers: '545',
        uniqueUnknown2534Viewers: '5454',
        uniqueUnknown35PlusViewers: '1232',
        uniqueUnknownUnknownViewers: '900',
      },
      tileStats: [
        {
          editionId: '',
          tileId: '',
          headline: '',
          impressionPercentage: '1.0000',
          indexedCtr: '1.0000',
          avgTotalTimeViewed: '181.7352',
          uniqueTopsnapsPerUser: '16.8149',
        },
      ],
    },
    editionSnaps: <AnalyticsV2Snap[]>[
      <AnalyticsV2Snap>{
        // Rendered Graphs
        id: '760062101026816-760062101026816',
        remoteUrl: '',
        shareOption: 'SHARE_LIVE_ARCHIVED',
        createdAt: '2023-08-22T15:26:47Z',
        assetId: '',
        videoAssetId: 'ee8a3b98-59ea-4bd9-9d9a-f97d42c48849',
        sourceMediaId: 'MediaID-f5eb5d22-1275-4c87-9493-47593cd58d4f',
        overlayImageAssetId: '3b837add-df2d-419e-b8d7-43aa9e175bb4',
        creativeId: null,
        type: 'VIDEO',
        isContentDeleted: false,
        cameoSnapModel: null,
        relatedSnaps: {
          [SnapRelationship.TOP]: null,
          [SnapRelationship.BOTTOM]: null,
        },
        // Required for snap analytics preview
        longformMediaType: 'VIDEO',
        mediaName: 'Test Snap 1',
        snapIndex: 0,
        snapId: '760062101026816-760062101026816',
        runtime: 12.3,
      },
    ],
    editionSnapAnalytics: <ProcessedSnapMetricResultsV2[]>[],
  },
};
