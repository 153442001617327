import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createFeature } from 'state/features/admin/actions/featuresActions';
import { getAllFeaturesMap } from 'state/features/admin/selectors/featuresAdminSelectors';

import { FeaturesMap } from 'src/state/features/admin/featuresAdminState';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SDSInput from 'views/common/components/SDSInput/SDSInput';

const mapDispatchToProps = {
  createFeature,
};

const mapStateToProps = (state: State) => {
  return {
    featuresMap: getAllFeaturesMap(state),
  };
};

type Props = {
  modalId: string;
  hideModal: (modalId: string) => void;
  createFeature: ({ featureId, description }: { featureId: string; description: string }) => void;
  featuresMap: FeaturesMap;
};

type OwnState = {
  featureId: string;
  description: string;
};
export class CreateFeatureFlagModal extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      featureId: '',
      description: '',
    };
  }

  closeModal = () => {
    this.props.hideModal(this.props.modalId);
  };

  onIdHandler = (event: any) => {
    this.setState({ featureId: event.target.value });
  };

  onDescriptionHandler = (event: any) => {
    this.setState({ description: event.target.value });
  };

  onCreateHandler = () => {
    this.props.createFeature({ featureId: this.state.featureId, description: this.state.description });
    this.closeModal();
  };

  isExistingFeatureId = () => Boolean(this.props.featuresMap[this.state.featureId.toUpperCase()]);

  isSubmitDisabled = () => {
    return this.state.featureId.length === 0 || this.isExistingFeatureId() || this.state.description.length === 0;
  };

  render() {
    return (
      <SDSDialog
        visible
        title={
          <FormattedMessage
            id="create-new-feature"
            defaultMessage="Create New Feature"
            description="Title for a dialog for creating a new feature."
          />
        }
        onOk={this.onCreateHandler}
        onCancel={this.closeModal}
        okText={
          <FormattedMessage
            id="create-feature-flag-new-feature-submit-button"
            description="Text in create feature flag submit button"
            defaultMessage="Create Feature"
          />
        }
        okButtonProps={{ disabled: this.isSubmitDisabled() }}
      >
        <SDSInput
          labelTitle={
            <FormattedMessage
              id="create-feature-flag-new-feature-name"
              description="Label for input for new feature name"
              defaultMessage="Feature Name"
            />
          }
          onChange={this.onIdHandler}
          value={this.state.featureId}
          data-test="modals.createFeatureFlag.newFeatureName.input"
          errorMessage={this.isExistingFeatureId() ? 'FeatureId already exists' : null}
        />
        <SDSInput
          labelTitle={
            <FormattedMessage
              id="create-feature-flag-new-feature-description"
              description="Label for input for new feature description"
              defaultMessage="Feature Description"
            />
          }
          data-test="modals.createFeatureFlag.newFeatureDescription.input"
          onChange={this.onDescriptionHandler}
        />
      </SDSDialog>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(CreateFeatureFlagModal);
