import { get, assign } from 'lodash';
import React from 'react';

import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import { UploadPurpose, RichPublisherComponentType, MaxNumTileLogos, DEFAULT_LOGO_COLOR } from 'config/constants';
import { buildComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as assetUtils from 'utils/media/assetUtils';
import { isColorLight } from 'utils/media/colorUtils';

import PublisherColorRow from 'views/onboarding/components/PublisherColorRow/PublisherColorRow';
import PublisherDetailImageRow from 'views/onboarding/components/PublisherDetailImageRow/PublisherDetailImageRow';

import { PropertyKeys, MediaLoading } from 'types/publisherSettings';
import type { EditableTileLogo } from 'types/publisherSettings';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';

type ExternalProps = {
  publisherId: PublisherID;
  disabled?: boolean;
  isLoading: MediaLoading;
  handleTileLogoChange: (value: any) => void;
  handleHorizontalIconChange: (value: any) => void;
  handleSquareIconChange: (value: any) => void;
};
type StateProps = {
  data: {} | undefined | null;
};
export const mapStateToProps = (state: RootState, props: ExternalProps): StateProps => {
  return {
    data: stagesSelectors.getData(state)(props.publisherId),
  };
};
const mapDispatchToProps = {
  updateProperties: stagesActions.updateProperties,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;
export class AdvancedStylingEditor extends React.Component<Props> {
  handleChange = (property: string) => (value: unknown) => {
    this.props.updateProperties(this.props.publisherId, { [property]: value });
  };

  handlePrimaryColorChange = (value: string) => {
    this.handleChange(PropertyKeys.PRIMARY_COLOR)(value);
    // Compute how bright the color is. If it's quite light, use black as the secondary color. If it's darker, use white as
    // the secondary color. This makes text (which uses the secondary color) easier to read when on top of the primary color
    const secondaryColor = isColorLight(value) ? '#000000' : '#FFFFFF';
    this.handleChange(PropertyKeys.SECONDARY_COLOR)(secondaryColor);
  };

  handleTileLogoChange = (componentId: string, property: string) => (value: string | boolean) => {
    if (!get(this.props.data, PropertyKeys.TILE_LOGOS)) {
      return;
    }
    const oldTileLogos = get(this.props.data, PropertyKeys.TILE_LOGOS);
    const tileLogos = (oldTileLogos as any).map((tileLogo: EditableTileLogo) => {
      if (componentId === tileLogo.componentId) {
        return assign({}, tileLogo, { [property]: value });
      }
      return tileLogo;
    });
    const newNumOfTileLogos = this.numberOfTileLogos(tileLogos);
    if (
      this.isTileLogoPlaceholder(componentId) &&
      newNumOfTileLogos < MaxNumTileLogos &&
      !this.hasEmptyTileLogoPlaceholder(tileLogos)
    ) {
      tileLogos.push({
        componentId: buildComponentId(RichPublisherComponentType.TILE_LOGO_PLACEHOLDER, tileLogos.length),
        color: '',
      });
    }
    if (this.numberOfTileLogos(oldTileLogos) >= MaxNumTileLogos && newNumOfTileLogos < MaxNumTileLogos) {
      tileLogos.push({
        componentId: buildComponentId(RichPublisherComponentType.TILE_LOGO_PLACEHOLDER, tileLogos.length),
        color: '',
      });
    }
    this.props.handleTileLogoChange(tileLogos);
  };

  handleTileLogoDelete = (componentId: string) => () => {
    this.handleTileLogoChange(componentId, 'isDeleted')(true);
  };

  numberOfTileLogos = (tileLogos: EditableTileLogo[]) => {
    return tileLogos.reduce((total: number, tileLogo: EditableTileLogo) => {
      if (tileLogo.isDeleted || this.isEmptyTileLogoPlaceholder(tileLogo)) {
        return total;
      }
      return total + 1;
    }, 0);
  };

  hasEmptyTileLogoPlaceholder = (tileLogos: EditableTileLogo[]) => {
    return tileLogos.some((tileLogo: EditableTileLogo) => {
      return this.isEmptyTileLogoPlaceholder(tileLogo);
    });
  };

  isTileLogoPlaceholder = (componentId: string) => {
    return componentId.indexOf(RichPublisherComponentType.TILE_LOGO_PLACEHOLDER) !== -1;
  };

  isEmptyTileLogoPlaceholder = (tileLogo: EditableTileLogo) => {
    // A tile logo placeholder that hasn't had a logo added to it yet
    return this.isTileLogoPlaceholder(tileLogo.componentId) && !tileLogo.id && !tileLogo.blob;
  };

  render() {
    const tileLogos = get(this.props.data, PropertyKeys.TILE_LOGOS);
    const primaryColor = get(this.props.data, PropertyKeys.PRIMARY_COLOR);
    return (
      <>
        <PublisherColorRow
          title={getMessageFromId('publisher-details-publishing-color')}
          info={getMessageFromId('publisher-details-publishing-color-description')}
          onChange={this.handlePrimaryColorChange}
          key={PropertyKeys.PRIMARY_COLOR}
          defaultValue={get(this.props.data, PropertyKeys.PRIMARY_COLOR) || ''}
          titleWithDescription
          disabled={this.props.disabled}
        />
        <PublisherDetailImageRow
          title={getMessageFromId('publisher-details-square-icon')}
          onChange={this.props.handleSquareIconChange}
          key={PropertyKeys.SQUARE_ICON_BLOB}
          uploadType={UploadPurpose.SQUARE_ICON}
          defaultValue={get(this.props.data, PropertyKeys.SQUARE_ICON_BLOB) || ''}
          isHorizontalLayout
          disabled={this.props.disabled}
          isLoading={this.props.isLoading.squareIcon}
        />
        <PublisherDetailImageRow
          title={getMessageFromId('publisher-details-horizontal-icon')}
          onChange={this.props.handleHorizontalIconChange}
          key={PropertyKeys.HORIZONTAL_ICON_BLOB}
          uploadType={UploadPurpose.TILE_LOGO}
          defaultValue={get(this.props.data, PropertyKeys.HORIZONTAL_ICON_BLOB) || ''}
          isHorizontalLayout
          disabled={this.props.disabled}
          isLoading={this.props.isLoading.horizontalIcon}
        />
        {}
        {tileLogos
          ? (tileLogos as any).map((tileLogo: EditableTileLogo) => {
              if (tileLogo.isDeleted) {
                return null;
              }
              if (this.isEmptyTileLogoPlaceholder(tileLogo)) {
                return (
                  <PublisherDetailImageRow
                    title={getMessageFromId('publisher-details-optional-horizontal-icon')}
                    onChange={this.handleTileLogoChange(tileLogo.componentId, 'blob')}
                    key={`${PropertyKeys.TILE_LOGOS}logo${tileLogo.componentId}`}
                    uploadType={UploadPurpose.TILE_LOGO}
                    isHorizontalLayout
                    disabled={this.props.disabled}
                    isLoading={this.props.isLoading.tileLogos}
                  />
                );
              }
              const canDelete = this.numberOfTileLogos(tileLogos) > 1;
              return (
                <PublisherDetailImageRow
                  title={getMessageFromId('publisher-details-optional-horizontal-icon')}
                  onChange={this.handleTileLogoChange(tileLogo.componentId, 'blob')}
                  key={`${PropertyKeys.TILE_LOGOS}logo${tileLogo.componentId}`}
                  uploadType={UploadPurpose.TILE_LOGO}
                  defaultValue={tileLogo.blob || assetUtils.getImagePreviewUrl(tileLogo.id)}
                  isHorizontalLayout
                  onDelete={canDelete ? this.handleTileLogoDelete(tileLogo.componentId) : null}
                  color={tileLogo.color || primaryColor || DEFAULT_LOGO_COLOR}
                  disabled={this.props.disabled}
                  isLoading={this.props.isLoading.tileLogos}
                >
                  <PublisherColorRow
                    title={getMessageFromId('publisher-details-backing-color')}
                    onChange={this.handleTileLogoChange(tileLogo.componentId, 'color')}
                    key={`${PropertyKeys.TILE_LOGOS}color${tileLogo.componentId}`}
                    defaultValue={tileLogo.color || primaryColor || DEFAULT_LOGO_COLOR}
                    disabled={this.props.disabled}
                  />
                </PublisherDetailImageRow>
              );
            })
          : null}
      </>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(AdvancedStylingEditor);
