import { gql } from '@apollo/client';

export const UPDATE_STORY_DRAFTING_SNAPS = gql`
  mutation UpdateStoryDraftingSnaps($input: UpdateStoryDraftingSnapsInput) {
    updateStoryDraftingSnaps(input: $input) {
      __typename
      ... on UpdateStoryDraftingSnapsSuccess {
        updatedSnapId
      }
      ... on UpdateStoryDraftingSnapsError {
        message
      }
    }
  }
`;
