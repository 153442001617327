import React from 'react';

import style from './ReportSnapcode.scss';

type Props = {
  snapcodeURL: string | undefined | null;
};

export class ReportSnapcode extends React.PureComponent<Props> {
  render() {
    return (
      <div className={style.reportSnapcode}>
        <div className={style.snapcode}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message */}
          <img src={this.props.snapcodeURL} alt="Publisher Snapcode" />
        </div>
      </div>
    );
  }
}

export default ReportSnapcode;
