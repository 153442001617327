import { findIndex } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import * as analyticsActions from 'state/analytics/actions/analyticsActions';
import { getServiceStatus } from 'state/analytics/selectors/analyticsSelectors';
import {
  isStoriesAnalyticsV2Enabled,
  isStoryAnalyticsProfileV2Enabled,
} from 'state/features/selectors/featuresSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { chart } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { logGAEvent, GAUserActions } from 'utils/gaUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isRevenueDashboardEnabled } from 'utils/storytellerMonetizationUtils';

import AnalyticsStatusIcon from 'views/analytics/components/AnalyticsStatusIcon/AnalyticsStatusIcon';
import SDSTabs from 'views/common/components/SDSTabs/SDSTabs';
import type { TabHeader } from 'views/common/components/SDSTabs/SDSTabs';

import style from './AnalyticsView.scss';
import { ANALYTICS_TABS, TABS_CONFIG } from './analyticsTabsConfig';
import type { AnalyticsTabsEnum } from './analyticsTabsConfig';

import type { ServiceStatus } from 'types/analytics';
import { Claim } from 'types/permissions';
import type { PublisherID, Publisher } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';

type OwnProps = {
  tab: AnalyticsTabsEnum;
};

type StateProps = {
  isAnalyticsViewer: boolean;
  isDailyAnalyticsViewer: boolean;
  isInsightsAnalystViewer: boolean;
  isMonetizationAnalystViewer: boolean;
  isProfileAnalyticsEnabled: boolean;
  isStoriesAnalyticsV2Enabled: boolean;
  publisherId: PublisherID;
  activePublisher: Publisher | null;
  serviceStatus: ServiceStatus;
};

export const mapStateToProps = (state: RootState): StateProps => {
  const publisherId = userSelectors.getActivePublisherId(state) || userSelectors.getActiveCreator(state)?.publisherId;

  return {
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
    isAnalyticsViewer: userSelectors.hasClaimForActivePublisher(state, Claim.ANALYTICS_VIEWER),
    isDailyAnalyticsViewer: userSelectors.hasClaimForActivePublisher(state, Claim.ANALYTICS_DAILY_VIEWER),
    isInsightsAnalystViewer: userSelectors.hasClaimForActivePublisher(state, Claim.ANALYTICS_DAILY_VIEWER),
    isMonetizationAnalystViewer: userSelectors.hasClaimForActivePublisher(state, Claim.MONETIZATION_ANALYST_VIEWER),
    isProfileAnalyticsEnabled: isStoryAnalyticsProfileV2Enabled(state),
    isStoriesAnalyticsV2Enabled: isStoriesAnalyticsV2Enabled(state),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    publisherId,
    serviceStatus: getServiceStatus(state),
  };
};

const mapDispatchToProps = {
  goToAnalytics: routerActions.goToAnalytics,
  goToStoriesAnalytics: routerActions.goToStoriesAnalytics,
  goToStoriesAnalyticsV2: routerActions.goToStoriesAnalyticsV2,
  goToBehaviorAnalytics: routerActions.goToBehaviorAnalytics,
  goToSpotlightAnalytics: routerActions.goToSpotlightAnalytics,
  goToInsightsAnalytics: routerActions.goToInsightsAnalytics,
  goToRevenueAnalytics: routerActions.goToRevenueAnalytics,
  goToProfileAnalytics: routerActions.goToProfileAnalytics,
  fetchServiceStatus: analyticsActions.fetchServiceStatus,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps & StateProps;

const canViewDailyAnalytics = (props: Props) => {
  return props.isAnalyticsViewer && props.isDailyAnalyticsViewer;
};

const AnalyticsTabsConfig = {
  [ANALYTICS_TABS.PROFILE]: {
    text: getMessageFromId('analytics-profile'),
    tabKey: 'profile',
    isActive: (props: Props) => {
      return canViewDailyAnalytics(props) && props.isProfileAnalyticsEnabled;
    },
  },
  [ANALYTICS_TABS.AUDIENCE]: {
    text: getMessageFromId('analytics-audience'),
    tabKey: 'audience',
    isActive: (props: Props) => canViewDailyAnalytics(props) && !props.isProfileAnalyticsEnabled,
  },
  [ANALYTICS_TABS.BEHAVIOR]: {
    text: getMessageFromId('analytics-behavior'),
    tabKey: 'behaviour',
    isActive: (props: Props) => canViewDailyAnalytics(props) && !props.isProfileAnalyticsEnabled,
  },
  [ANALYTICS_TABS.INSIGHTS]: {
    text: getMessageFromId('analytics-insights'),
    tabKey: 'insights',
    isActive: (props: Props) => props.isAnalyticsViewer && props.isInsightsAnalystViewer,
  },
  [ANALYTICS_TABS.STORIES]: {
    text: getMessageFromId('analytics-stories'),
    tabKey: 'stories',
    isActive: (props: Props) => props.isAnalyticsViewer && !props.isStoriesAnalyticsV2Enabled,
  },
  [ANALYTICS_TABS.STORIES_V2]: {
    text: getMessageFromId('analytics-stories'),
    tabKey: 'stories',
    isActive: (props: Props) => props.isAnalyticsViewer && props.isStoriesAnalyticsV2Enabled,
  },
  [ANALYTICS_TABS.SPOTLIGHT]: {
    text: (
      <FormattedMessage
        id="analytics-spotlight"
        description="Button text to select Spotlight in Analytics."
        defaultMessage="Spotlight"
      />
    ),
    tabKey: 'spotlight',
    isActive: (props: Props) => props.isAnalyticsViewer,
  },
  [ANALYTICS_TABS.REVENUE]: {
    text: getMessageFromId('analytics-revenue'),
    tabKey: 'revenue',
    isActive: (props: Props) => {
      return isRevenueDashboardEnabled(props.isMonetizationAnalystViewer, props.activePublisher);
    },
  },
};

export class AnalyticsView extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchServiceStatus();
  }

  getActiveTabs(): TabHeader[] {
    // return an array of just the tabs that are active
    // @ts-expect-error ts-migrate(2322) FIXME: Type '({ text: any; tabKey: string; isActive: (pro... Remove this comment to see the full error message
    return (
      Object.keys(AnalyticsTabsConfig)
        .map(key => AnalyticsTabsConfig[key])
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        .filter(tab => tab.isActive && tab.isActive(this.props))
    );
  }

  onTabSelected = (index: number) => {
    const { publisherId, activePublisher } = this.props;

    const selectedTab = this.getActiveTabs()[index];

    logGAEvent(GAUserActions.ANALYTICS, `navigate-to-${selectedTab?.text}-tab`, {
      publisherName: activePublisher?.name,
    });

    switch (selectedTab) {
      case AnalyticsTabsConfig[ANALYTICS_TABS.AUDIENCE]:
      default:
        this.props.goToAnalytics({ publisherId });
        break;
      case AnalyticsTabsConfig[ANALYTICS_TABS.BEHAVIOR]:
        this.props.goToBehaviorAnalytics({ publisherId });
        break;
      case AnalyticsTabsConfig[ANALYTICS_TABS.INSIGHTS]:
        this.props.goToInsightsAnalytics({ publisherId });
        break;
      case AnalyticsTabsConfig[ANALYTICS_TABS.STORIES]:
        this.props.goToStoriesAnalytics({ publisherId });
        break;
      case AnalyticsTabsConfig[ANALYTICS_TABS.STORIES_V2]:
        this.props.goToStoriesAnalyticsV2({ publisherId });
        break;
      case AnalyticsTabsConfig[ANALYTICS_TABS.SPOTLIGHT]:
        this.props.goToSpotlightAnalytics({ publisherId });
        break;
      case AnalyticsTabsConfig[ANALYTICS_TABS.REVENUE]:
        this.props.goToRevenueAnalytics({ publisherId });
        break;
      case AnalyticsTabsConfig[ANALYTICS_TABS.PROFILE]:
        this.props.goToProfileAnalytics({ publisherId });
        break;
    }
  };

  renderTabs(currentTab: AnalyticsTabsEnum, activeTabs: TabHeader[]) {
    if (activeTabs.length <= 1) {
      return null;
    }
    const selectedTabKey = AnalyticsTabsConfig[currentTab]?.tabKey;
    const currentTabIndex = findIndex(activeTabs, tab => tab.tabKey === selectedTabKey);
    const selectedTabIndex = currentTabIndex !== -1 ? currentTabIndex : 0;

    return (
      <div className={style.centerContainer}>
        <div className={style.tabsContainer}>
          <SDSTabs selectedTab={selectedTabIndex} handleTabSelected={this.onTabSelected} tabs={activeTabs} />
        </div>
      </div>
    );
  }

  render() {
    let currentTab = this.props.tab;

    if (
      this.props.isProfileAnalyticsEnabled &&
      (currentTab === ANALYTICS_TABS.AUDIENCE || currentTab === ANALYTICS_TABS.BEHAVIOR)
    ) {
      incrementCounter(GrafanaMetrics.ANALYTIC_TABS, {
        type: 'tabChange',
        tabName: ANALYTICS_TABS.PROFILE,
      });
      currentTab = ANALYTICS_TABS.PROFILE;
    }

    const activeTabs = this.getActiveTabs();

    if (activeTabs.length === 0) {
      return null;
    }

    if (activeTabs.length === 1) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      currentTab = Object.keys(AnalyticsTabsConfig).filter(key => AnalyticsTabsConfig[key] === activeTabs[0])[0];
    }

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const TabContent = TABS_CONFIG[currentTab].content;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'props' does not exist on type '{ content... Remove this comment to see the full error message
    const { props } = TABS_CONFIG[currentTab];

    return (
      <div className={style.parent}>
        <div className={style.header} data-test="analyticsView.header">
          <div className={style.headerText}>
            <InlineSVG src={chart} className={style.analyticsIcon} alt="" data-test="analyticsView.icon" />
            {getMessageFromId('homepage-analytics-button')}
            {this.props.serviceStatus?.status ? <AnalyticsStatusIcon serviceStatus={this.props.serviceStatus} /> : null}
          </div>
          {this.renderTabs(currentTab, activeTabs)}
          <div className={style.optionsPlaceholder} />
        </div>
        <div className={style.content}>
          {this.props.publisherId && TabContent && <TabContent publisherId={this.props.publisherId} {...props} />}
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(AnalyticsView);
