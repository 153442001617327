import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE, GRAPH_SHADES_OF_GREEN } from 'views/analytics/utils/chartConfigs';

import type { StorySnapMetrics } from 'types/analytics';

type Props = {
  isLoading: boolean;
  metrics: StorySnapMetrics[];
  isOnPromotionTab: boolean;
  useAnalyticsV2?: boolean;
};

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="attachment-time-spent-paid"
      defaultMessage="Attachment Time Spent Paid"
      description="Time spent on attachments for paid impressions"
    />
  ),
  params: [],
});

export class AnalyticsAttachmentTimeSpentGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getTimeSpentConfig = () => {
    return this.props.isOnPromotionTab
      ? [
          {
            key: 'paidAttachmentTimeSpent',
            name: getLocalisedMessageFromId(this.context, 'attachment-time-spent-paid'),
            fill: GRAPH_SHADES_OF_GREEN.MED_GREEN,
          },
        ]
      : [
          {
            key: 'attachmentTimeSpent',
            name: getLocalisedMessageFromId(this.context, 'publisher-story-attachment-time-spent'),
            fill: GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
          },
        ];
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const { name, attachmentTimeSpent, snapId, mediaId, mediaType, paidAttachmentTimeSpent, mediaUrl } = payload;
    const timeSpentConfig = this.getTimeSpentConfig();

    const metricRows = this.props.isOnPromotionTab
      ? [
          {
            metricName: timeSpentConfig[0]?.name,
            metricValue: this.props.useAnalyticsV2
              ? numberFormatter.formatTimeViewed(paidAttachmentTimeSpent)
              : numberFormatter.printSeconds(paidAttachmentTimeSpent),
          },
        ]
      : [
          {
            metricName: timeSpentConfig[0]?.name,
            metricValue: this.props.useAnalyticsV2
              ? numberFormatter.formatTimeViewed(attachmentTimeSpent)
              : numberFormatter.printSeconds(attachmentTimeSpent),
          },
        ];

    return (
      <AnalyticsTooltip
        title={name}
        snapId={snapId}
        mediaId={mediaId}
        generatedPreviewUrl={mediaUrl}
        mediaType={mediaType}
        metricRows={metricRows}
      />
    );
  };

  render() {
    return (
      <AnalyticsBarChart
        barChartData={this.props.metrics}
        barChartTitle={getMessageFromId('publisher-story-attachment-time-spent')}
        bars={this.getTimeSpentConfig()}
        graphTooltip={getMessageFromId('publisher-story-attachment-time-spent-explanation')}
        isLoading={this.props.isLoading}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={this.props.useAnalyticsV2 ? numberFormatter.formatTimeViewed : numberFormatter.printSeconds}
      />
    );
  }
}

export default AnalyticsAttachmentTimeSpentGraph;
