import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum SpotlightModerationRejectionParam {
  InvasionOfPrivacy = 'content related to privacy invasion',
  HiveOtherBlood = 'disturbing or violent content',
  HiveVeryBloody = 'disturbing or violent content',
  HiveSelfHarm = 'disturbing or violent content',
  QualityDriving = 'driving while filming',
  HiveNudity = 'nudity',
  DangerousOrHarmfulActivities = 'risky behavior',
  HiveSexualActivity = 'sexualized content',
  HiveSexualIntent = 'sexualized content',
  HiveSmoking = 'smoking',
  HiveGunInHand = 'weapons',
  AbusiveLanguage = 'abusive language',
  Alcohol = 'alcohol',
  UnsafeURL = 'an unsafe URL',
  AnimalViolence = 'animal suffering',
  BodilyFluids = 'bodily fluids',
  Clickbait = 'clickbait',
  CommercialSalesAndSolicitations = 'commercial promotion',
  CommercialSpam = 'commercial promotion',
  PrivacyInvasion = 'content related to privacy invasion',
  Harassment = 'content relating to bullying',
  TerrorismAndViolentExtremismContent = 'content relating to or referencing extremism',
  Terrorist = 'content relating to or referencing terrorism',
  Drugs = 'controlled substances',
  DangerousDriving = 'dangerous driving',
  HateSpeech = 'disrespect against a group or identity',
  DangerousActivities = 'disturbing or violent content',
  HumanViolence = 'disturbing or violent content',
  SelfHarmSuicide = 'disturbing or violent content',
  ViolentOrDisturbingContent = 'disturbing or violent content',
  SelfHarmAndSuicide = 'disturbing or violent content',
  FraudAndSpam = 'fraudulent or inauthentic behavior',
  HumanOrAnimalViolence = 'human or animal harm',
  IllegalGoodsAndActivities = 'illegal or regulated activities',
  Marijuana = 'marijuana',
  HarmfulFalseInformation = 'misleading information',
  PoliticalContent = 'political content',
  Profanity = 'profanity',
  RegulatedGoodsAndActivities = 'regulated activities',
  BodyImagery = 'revealing body imagery',
  SexualCaption = 'sexual captions',
  RelationshipsIntimacy = 'sexualized content',
  SexActs = 'sexualized content',
  SexualContentReason = 'sexualized content',
  SexualSolicitation = 'sexualized content',
  SexualizedObjects = 'sexualized content',
  Threats = 'threats',
  Tobacco = 'tobacco',
  Weapons = 'weapons',
  Default = '',
}
export enum SpotlightModerationRejectionReason {
  NoRejectionReasonGiven = 'No Rejection Reason Given',
  InvasionOfPrivacy = 'INVASION_OF_PRIVACY',
  HiveOtherBlood = 'HIVE_OTHER_BLOOD',
  HiveVeryBloody = 'HIVE_VERY_BLOODY',
  HiveSelfHarm = 'HIVE_SELF_HARM',
  QualityDriving = 'QUALITY_DRIVING',
  HiveNudity = 'HIVE_NUDITY',
  DangerousOrHarmfulActivities = 'DANGEROUS_OR_HARMFUL_ACTIVITIES',
  HiveSexualActivity = 'HIVE_SEXUAL_ACTIVITY',
  HiveSexualIntent = 'HIVE_SEXUAL_INTENT',
  HiveSmoking = 'HIVE_SMOKING',
  HiveGunInHand = 'HIVE_GUN_IN_HAND',
  PublicProfileModerationRejected = 'PUBLIC_PROFILE_MODERATION_REJECTED',
  PublicProfileModerationRestrictedDistribution = 'PUBLIC_PROFILE_MODERATION_RESTRICTED_DISTRIBUTION',
  PublicProfileModerationRestrictedDistributionNonALD = 'PUBLIC_PROFILE_MODERATION_RESTRICTED_DISTRIBUTION_NON_ALD',
  SimilarContent = 'SIMILAR_CONTENT',
  CreativeToolsAndQuality = 'CREATIVE_TOOLS_AND_QUALITY',
  AbusiveMentionChecker = 'ABUSIVE_MENTION_CHECKER',
  BadWord = 'BAD_WORD',
  CreatorScoreChecker = 'CREATOR_SCORE_CHECKER',
  HiveGeneralNSFW = 'HIVE_GENERAL_NSFW',
  HiveNSFWChecker = 'HIVE_NSFW_CHECKER',
  MLReject = 'ML_REJECT',
  NSFW = 'NSFW',
  PIEChecks = 'PIE_CHECKS',
  QualityRiskAccount = 'QUALITY_RISK_ACCOUNT',
  RLAttachment = 'RL_ATTACHMENT',
  SnapDeletion = 'SNAP_DELETION',
  TextModeration = 'TEXT_MODERATION',
  TextModerationOCR = 'TEXT_MODERATION_OCR',
  Timesformer = 'TIMESFORMER',
  UserAgeGating = 'USER_AGE_GATING',
  UserReportAgeGating = 'USER_REPORT_AGE_GATING',
  LowContentQuality = 'LOW_CONTENT_QUALITY',
  QualityFPS = 'QUALITY_FPS',
  QualitySharpness = 'QUALITY_SHARPNESS',
  QualityColorCroppableRatio = 'QUALITY_COLOR_CROPPABLE_RATIO',
  QualityCroppableRatio = 'QUALITY_CROPPABLE_RATIO',
  URLAttachment = 'URL_ATTACHMENT',
  QualityAspectRatio = 'QUALITY_ASPECT_RATIO',
  CommercialPromotionAndSolicitation = 'COMMERCIAL_PROMOTION_AND_SOLICITATION',
  ContainLogo = 'CONTAIN_LOGO',
  ContainSnapchatLogo = 'CONTAIN_SNAPCHAT_LOGO',
  QualityHorizontal = 'QUALITY_HORIZONTAL',
  QualityMotion = 'QUALITY_MOTION',
  VideoLongLength = 'VIDEO_LONG_LENGTH',
  QualityLuminance = 'QUALITY_LUMINANCE',
  MultipleViolations = 'Multiple Violations',
  AbusiveLanguage = 'ABUSIVE_LANGUAGE',
  Alcohol = 'ALCOHOL',
  UnsafeURL = 'UNSAFE_URL',
  AnimalViolence = 'ANIMAL_VIOLENCE',
  BodilyFluids = 'BODILY_FLUIDS',
  Clickbait = 'CLICKBAIT',
  CommercialSalesAndSolicitations = 'COMMERCIAL_SALES_AND_SOLICITATIONS',
  CommercialSpam = 'COMMERCIAL_SPAM',
  PrivacyInvasion = 'PRIVACY_INVASION',
  Harassment = 'HARASSMENT',
  TerrorismAndViolentExtremismContent = 'TERRORISM_AND_VIOLENT_EXTREMISM_CONTENT',
  Terrorist = 'TERRORIST',
  Drugs = 'DRUGS',
  DangerousDriving = 'DANGEROUS_DRIVING',
  HateSpeech = 'HATE_SPEECH',
  DangerousActivities = 'DANGEROUS_ACTIVITIES',
  HumanViolence = 'HUMAN_VIOLENCE',
  SelfHarmSuicide = 'SELF_HARM_SUICIDE',
  ViolentOrDisturbingContent = 'VIOLENT_OR_DISTURBING_CONTENT',
  SelfHarmAndSuicide = 'SELF_HARM_AND_SUICIDE',
  FraudAndSpam = 'FRAUD_AND_SPAM',
  HumanOrAnimalViolence = 'HUMAN_OR_ANIMAL_VIOLENCE',
  IllegalGoodsAndActivities = 'ILLEGAL_GOODS_AND_ACTIVITIES',
  Marijuana = 'MARIJUANA',
  HarmfulFalseInformation = 'HARMFUL_FALSE_INFORMATION',
  PoliticalContent = 'POLITICAL_CONTENT',
  Profanity = 'PROFANITY',
  RegulatedGoodsAndActivities = 'REGULATED_GOODS_AND_ACTIVITIES',
  BodyImagery = 'BODY_IMAGERY',
  SexualCaption = 'SEXUAL_CAPTION',
  RelationshipsIntimacy = 'RELATIONSHIPS_INTIMACY',
  SexActs = 'SEX_ACTS',
  SexualContentReason = 'SEXUAL_CONTENT_REASON',
  SexualSolicitation = 'SEXUAL_SOLICITATION',
  SexualizedObjects = 'SEXUALIZED_OBJECTS',
  Threats = 'THREATS',
  Tobacco = 'TOBACCO',
  Weapons = 'WEAPONS',
  EditorialQuality = 'EDITORIAL_QUALITY',
  IntellectualProperty = 'INTELLECTUAL_PROPERTY',
  MinorsUnder13YearsOld = 'MINORS_UNDER_13_YEARS_OLD',
  AppVersionMissingMultiSnapMeta = 'APP_VERSION_MISSING_MULTI_SNAP_META',
  NoMedia = 'NO_MEDIA',
  URLChecker = 'URL_CHECKER',
  VideoShortLength = 'VIDEO_SHORT_LENGTH',
  ContainURL = 'CONTAIN_URL',
  NonVertical = 'NON_VERTICAL',
  Image = 'IMAGE',
  VideoNoSound = 'VIDEO_NO_SOUND',
}

export function getModerationReasonFromString(value: string): SpotlightModerationRejectionReason {
  const enumValue = Object.values(SpotlightModerationRejectionReason).find(enumItem => enumItem === value);
  return enumValue
    ? (enumValue as SpotlightModerationRejectionReason)
    : SpotlightModerationRejectionReason.NoRejectionReasonGiven;
}

export function getRejectionParamFromString(rejection: string): SpotlightModerationRejectionParam {
  const enumValue = Object.entries(SpotlightModerationRejectionParam).find(([key, value]) => key === rejection)!;
  return enumValue?.length > 1 ? enumValue[1] : SpotlightModerationRejectionParam.Default;
}

const publicProfileIssue = (
  <FormattedMessage
    id="public-profile-issue"
    description="Public Profile Issue"
    defaultMessage="It looks like there may be an issue with your Public Profile, so we can't make your Snap visible to Snapchatters."
  />
);

const contentPromotionIssue = (
  <FormattedMessage
    id="content-promotion-issue"
    description="Content Promotion Issue"
    defaultMessage="It looks like your snap may feature content that we can't promote to Snapchatters on Spotlight."
  />
);

export const getMultipleViolationMessage = (rejectionReason: string) => (
  <FormattedMessage
    id="content-promotion-issue-multiple"
    description="Multiple Violation Issue"
    defaultMessage="It looks like your snap may feature {rejectionReason}, so it won’t get promoted to Snapchatters on Spotlight."
    values={{ rejectionReason }}
  />
);

export type SpotlightModerationRejectionContent = {
  link: string;
  message: JSX.Element;
};

export const SpotlightModerationRejectionMessage: Record<
  SpotlightModerationRejectionReason,
  SpotlightModerationRejectionContent
> = {
  [SpotlightModerationRejectionReason.NoRejectionReasonGiven]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.InvasionOfPrivacy]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#harassment%20and%20bullying',
    message: (
      <FormattedMessage
        id="invasion-of-privacy"
        description="Invasion of Privacy"
        defaultMessage="It looks like your snap may feature content related to privacy invasion, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.HiveOtherBlood]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="hive-other-blood"
        description="Hive Other Blood"
        defaultMessage="It looks like your snap may feature disturbing or violent content, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.HiveVeryBloody]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="hive-very-bloody"
        description="Hive Very Bloody"
        defaultMessage="It looks like your snap may feature disturbing or violent content, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.HiveSelfHarm]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="hive-self-harm"
        description="Hive Self Harm"
        defaultMessage="It looks like your snap may feature disturbing or violent content, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.QualityDriving]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: (
      <FormattedMessage
        id="quality-driving"
        description="Quality Driving"
        defaultMessage="It looks like your snap may feature driving while filming, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.HiveNudity]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: (
      <FormattedMessage
        id="hive-nudity"
        description="Hive Nudity"
        defaultMessage="It looks like your snap may feature nudity, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.DangerousOrHarmfulActivities]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.HiveSexualActivity]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.HiveSexualIntent]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: (
      <FormattedMessage
        id="hive-sexual-intent"
        description="Hive Sexual Intent"
        defaultMessage="It looks like your snap may feature sexualized content, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.HiveSmoking]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="hive-smoking"
        description="Hive Smoking"
        defaultMessage="It looks like your snap may feature smoking, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.MultipleViolations]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.HiveGunInHand]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="hive-gun-in-hand"
        description="Hive Gun In Hand"
        defaultMessage="It looks like your snap may feature weapons, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.PublicProfileModerationRejected]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#hateful%20content',
    message: publicProfileIssue,
  },
  [SpotlightModerationRejectionReason.PublicProfileModerationRestrictedDistribution]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#hateful%20content',
    message: publicProfileIssue,
  },
  [SpotlightModerationRejectionReason.PublicProfileModerationRestrictedDistributionNonALD]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#hateful%20content',
    message: publicProfileIssue,
  },
  [SpotlightModerationRejectionReason.SimilarContent]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="similar-content"
        description="Similar Content"
        defaultMessage="It looks like this Snap's been submitted to Spotlight before. Try again with something original!
"
      />
    ),
  },
  [SpotlightModerationRejectionReason.CreativeToolsAndQuality]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="creative-tools-and-quality"
        description="Creative Tools And Quality"
        defaultMessage="It looks like your snap has a content quality issue. Make sure it follows our Content Guidelines and submit it again"
      />
    ),
  },
  [SpotlightModerationRejectionReason.AbusiveMentionChecker]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#harassment%20and%20bullying',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.BadWord]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.CreatorScoreChecker]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.HiveGeneralNSFW]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.HiveNSFWChecker]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.MLReject]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.NSFW]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.PIEChecks]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.QualityRiskAccount]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.RLAttachment]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.SnapDeletion]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.TextModeration]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.TextModerationOCR]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.Timesformer]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.UserAgeGating]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.UserReportAgeGating]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.LowContentQuality]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.QualityFPS]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.QualitySharpness]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="quality-sharpness"
        description="Quality Sharpness"
        defaultMessage="It looks like your Snap is blurry. Try submitting again without the blurriness.
"
      />
    ),
  },
  [SpotlightModerationRejectionReason.QualityColorCroppableRatio]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="quality-color-croppable-ratio"
        description="Quality Color Croppable Ratio"
        defaultMessage="Snaps on Spotlight don't need borders to look great! Try submitting again without them."
      />
    ),
  },
  [SpotlightModerationRejectionReason.QualityCroppableRatio]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="quality-croppable-ratio"
        description="Quality Croppable Ratio"
        defaultMessage="Snaps on Spotlight don't need borders to look great! Try submitting again without them.
"
      />
    ),
  },
  [SpotlightModerationRejectionReason.URLAttachment]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#commercial%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.QualityAspectRatio]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.CommercialPromotionAndSolicitation]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#commercial%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.ContainLogo]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.ContainSnapchatLogo]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.QualityHorizontal]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.QualityMotion]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.VideoLongLength]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.QualityLuminance]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.MultipleViolations]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.AbusiveLanguage]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#harassment%20and%20bullying',
    message: (
      <FormattedMessage
        id="abusive-language"
        description="Abusive Language"
        defaultMessage="It looks like your snap may feature abusive language, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Alcohol]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="alcohol"
        description="Alcohol"
        defaultMessage="It looks like your snap may feature alcohol, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.UnsafeURL]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: (
      <FormattedMessage
        id="unsafe-url"
        description="Unsafe URL"
        defaultMessage="It looks like your snap may feature an unsafe URL, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.AnimalViolence]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="animal-violence"
        description="Animal Violence"
        defaultMessage="It looks like your snap may feature animal suffering, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.BodilyFluids]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="bodily-fluids"
        description="Bodily Fluids"
        defaultMessage="It looks like your snap may feature bodily fluids, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Clickbait]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#commercial%20content',
    message: (
      <FormattedMessage
        id="clickbait"
        description="Clickbait"
        defaultMessage="It looks like your snap may feature clickbait, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.CommercialSalesAndSolicitations]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#commercial%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.CommercialSpam]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#commercial%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.PrivacyInvasion]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#harassment%20and%20bullying',
    message: (
      <FormattedMessage
        id="privacy-invasion"
        description="Privacy Invasion"
        defaultMessage="It looks like your snap may feature content related to privacy invasion, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Harassment]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#harassment%20and%20bullying',
    message: (
      <FormattedMessage
        id="harassment"
        description="Harassment"
        defaultMessage="It looks like your snap may feature content relating to bullying, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.TerrorismAndViolentExtremismContent]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#hateful%20content',
    message: (
      <FormattedMessage
        id="terrorism-and-violent-extremism-content"
        description="Terrorism and Violent Extremism Content"
        defaultMessage="It looks like your snap may feature content relating to or referencing extremism, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Terrorist]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#hateful%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.Drugs]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="drugs"
        description="Drugs"
        defaultMessage="It looks like your snap may feature controlled substances, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.DangerousDriving]: {
    link: '',
    message: (
      <FormattedMessage
        id="dangerous-driving"
        description="Dangerous Driving"
        defaultMessage="It looks like your snap may feature dangerous driving, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.HateSpeech]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.DangerousActivities]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.HumanViolence]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="human-violence"
        description="Human Violence"
        defaultMessage="It looks like your snap may feature human or animal harm, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.SelfHarmSuicide]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#false%20or%20deceptive%20informaiton',
    message: (
      <FormattedMessage
        id="self-harm-suicide"
        description="Self-Harm Suicide"
        defaultMessage="It looks like your snap may feature self-harm or suicide, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.ViolentOrDisturbingContent]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#false%20or%20deceptive%20informaiton',
    message: (
      <FormattedMessage
        id="violent-or-disturbing-content"
        description="Violent or Disturbing Content"
        defaultMessage="It looks like your snap may feature violent or disturbing content, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.SelfHarmAndSuicide]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.FraudAndSpam]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#false%20or%20deceptive%20informaiton',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.HumanOrAnimalViolence]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="human-or-animal-violence"
        description="Human or Animal Violence"
        defaultMessage="It looks like your snap may feature human or animal harm, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.IllegalGoodsAndActivities]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.Marijuana]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="marijuana"
        description="Marijuana"
        defaultMessage="It looks like your snap may feature marijuana, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.HarmfulFalseInformation]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#false%20or%20deceptive%20informaiton',
    message: (
      <FormattedMessage
        id="harmful-false-information"
        description="Harmful False Information"
        defaultMessage="It looks like your snap may feature misleading information, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.PoliticalContent]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#public%20interest%20content',
    message: (
      <FormattedMessage
        id="political-content"
        description="Political Content"
        defaultMessage="It looks like your snap may feature political content, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Profanity]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.RegulatedGoodsAndActivities]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.BodyImagery]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: (
      <FormattedMessage
        id="body-imagery"
        description="Body Imagery"
        defaultMessage="It looks like your snap may feature sensitive content related to body imagery, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.SexualCaption]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: (
      <FormattedMessage
        id="sexual-caption"
        description="Sexual Caption"
        defaultMessage="It looks like your snap may feature a sexually suggestive caption, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.RelationshipsIntimacy]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: (
      <FormattedMessage
        id="relationships-intimacy"
        description="Relationships Intimacy"
        defaultMessage="It looks like your snap may feature content about relationships or intimacy, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.SexActs]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.SexualContentReason]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.SexualSolicitation]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.SexualizedObjects]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#sexual%20content',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.Threats]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#disturbing%20or%20violent%20content',
    message: (
      <FormattedMessage
        id="threats"
        description="Threats"
        defaultMessage="It looks like your snap may feature content related to threats, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Tobacco]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="tobacco"
        description="Tobacco"
        defaultMessage="It looks like your snap may feature tobacco, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Weapons]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="weapons"
        description="Weapons"
        defaultMessage="It looks like your snap may feature weapons, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.EditorialQuality]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.IntellectualProperty]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.MinorsUnder13YearsOld]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#illegal%20or%20regulated%20activities',
    message: (
      <FormattedMessage
        id="minors-under-13-years-old"
        description="Minors Under 13 Years Old"
        defaultMessage="It looks like your snap may feature minors under 13 years old, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.AppVersionMissingMultiSnapMeta]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: contentPromotionIssue,
  },
  [SpotlightModerationRejectionReason.NoMedia]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: (
      <FormattedMessage
        id="no-media"
        description="No Media"
        defaultMessage="It looks like there is no media in your Snap, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.URLChecker]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines',
    message: (
      <FormattedMessage
        id="url-checker"
        description="URL Checker"
        defaultMessage="It looks like your snap may feature a link to an external website, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.VideoShortLength]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="video-short-length"
        description="Video Short Length"
        defaultMessage="It looks like your video is too short, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.ContainURL]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="contain-url"
        description="Contain URL"
        defaultMessage="It looks like your snap may contain a URL, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.NonVertical]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="non-vertical"
        description="Non-Vertical"
        defaultMessage="It looks like your snap is not vertical, so it won’t get promoted to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.Image]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="image"
        description="Image"
        defaultMessage="It looks like your Snap is an image, but we only promote videos to Snapchatters on Spotlight."
      />
    ),
  },
  [SpotlightModerationRejectionReason.VideoNoSound]: {
    link: 'https://snap.com/en-US/content-recommendation-guidelines#creative%20quality',
    message: (
      <FormattedMessage
        id="video-no-sound"
        description="Video No Sound"
        defaultMessage="It looks like your video has no sound, but we prioritize promoting videos with sound to Snapchatters on Spotlight."
      />
    ),
  },
};
