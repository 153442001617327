import React from 'react';
import { FormattedMessage } from 'react-intl';

import { check } from 'icons/SDS/allIcons';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SharingList from 'views/common/components/SharingList/SharingList';

import type { Edition } from 'types/editions';

type Props = {
  onDismiss: () => void;
  edition: Edition;
};

export default class SharingModal extends React.PureComponent<Props> {
  render() {
    return (
      <SDSCustomModal
        visible
        onClose={this.props.onDismiss}
        title={
          <FormattedMessage
            id="story-snapcode-sharing"
            defaultMessage="Sharing"
            description="The title of the Sharing Snapcode modal"
          />
        }
        footer={
          <SDSButton
            type={ButtonType.PRIMARY}
            shape={ButtonShape.CIRCLE}
            onClick={this.props.onDismiss}
            inlineIcon={check}
            data-test="sharing.modal.confirm"
          />
        }
        data-test="modal.SharingModal"
      >
        <FormattedMessage
          id="sharing-modal-copy"
          defaultMessage={'Once your story is available, use these links to share your story outside of Snapchat.'}
          description="Copy for the sharing modal describing to the user how to share their story"
        />
        <SharingList edition={this.props.edition} />
      </SDSCustomModal>
    );
  }
}
