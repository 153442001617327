import _ from 'lodash';
import React from 'react';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import AudienceBreakdownGraph from './AudienceBreakdownGraph';
import AudienceCategories from './AudienceCategories';

import type { DailyAnalytics, DailyMetrics } from 'types/analytics';

type Props = {
  dailyAnalytics: DailyAnalytics;
  tags: string[];
};

const mapGenderDataToName = {
  unique_female_viewers: getMessageFromId('analytics-insights-report-gender-group-female'),
  unique_male_viewers: getMessageFromId('analytics-insights-report-gender-group-male'),
  unique_unknown_gender_viewers: '?',
};

const mapAgeDataToName = {
  unique_13_17_viewers: getMessageFromId('analytics-insights-report-age-group-13-to-17'),
  unique_18_24_viewers: getMessageFromId('analytics-insights-report-age-group-18-to-24'),
  unique_25_34_viewers: getMessageFromId('analytics-insights-report-age-group-25-to-34'),
  unique_35_plus_viewers: getMessageFromId('analytics-insights-report-age-group-35-plus'),
  unique_unknown_age_viewers: '?',
};

const mapDataToName = { ...mapGenderDataToName, ...mapAgeDataToName };

const mapGenderDataToColor = {
  unique_unknown_gender_viewers: '#D4D4D5',
  unique_female_viewers: '#FAB2BD',
  unique_male_viewers: '#66C0EF',
};

const mapAgeDataToColor = {
  unique_13_17_viewers: '#A186FF',
  unique_18_24_viewers: '#66C7AF',
  unique_25_34_viewers: '#FFD19A',
  unique_35_plus_viewers: '#E86683',
  unique_unknown_age_viewers: '#D4D4D5',
};

const mapDataToColor = { ...mapGenderDataToColor, ...mapAgeDataToColor };

export class AudienceBreakdown extends React.PureComponent<Props> {
  getGenderData = () => {
    return this.parseBarData(
      this.reduceBarData(this.props.dailyAnalytics.dailyMetrics, [
        'unique_unknown_gender_viewers',
        'unique_female_viewers',
        'unique_male_viewers',
      ]),
      'unique_unknown_gender_viewers'
    );
  };

  getAgeRangeData = () => {
    return this.parseBarData(
      this.reduceBarData(this.props.dailyAnalytics.dailyMetrics, [
        'unique_13_17_viewers',
        'unique_18_24_viewers',
        'unique_25_34_viewers',
        'unique_35_plus_viewers',
        'unique_unknown_age_viewers',
      ]),
      'unique_unknown_age_viewers'
    );
  };

  parseBarData = (barData: any, omitKey: string) => {
    const sumByKey = _.sum(Object.values(barData));

    return _.keys(barData)
      .filter(key => key !== omitKey)
      .map(key => ({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        percent: parseFloat(barData[key]) / parseFloat(sumByKey),
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        group: mapDataToName[key],
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        col: mapDataToColor[key],
      }));
  };

  reduceBarData = (dailyMetrics: DailyMetrics, keys: string[]) => {
    const reducedBarData = {};
    keys.forEach(key => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      reducedBarData[key] = this.sumDailyDataOverRange(dailyMetrics, key);
    });
    return reducedBarData;
  };

  sumDailyDataOverRange = (dailyMetrics: DailyMetrics, key: string) => {
    const values = _.values(dailyMetrics)
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      .map(a => parseInt(a[key], 10))
      .filter(a => !Number.isNaN(Number(a)) && a > 0);
    return values.length > 0 ? values.reduce((a, b) => a + b) : 0;
  };

  render() {
    return (
      <div>
        <AudienceCategories tags={this.props.tags} dataAgeRanges={this.getAgeRangeData()} />
        <AudienceBreakdownGraph sortByName sort={false} data={this.getAgeRangeData()} />
        <AudienceBreakdownGraph sortByName={false} sort data={this.getGenderData()} />
      </div>
    );
  }
}

export default AudienceBreakdown;
