import React, { memo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { getAllowedLanguageCodesByLanguageCode, getDisplayMessageForLanguageCode } from 'constants/subtitles';

import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptionGroups } from 'views/common/components/SDSDropdownOptionGroups/SDSDropdownOptionGroups';

type Props = {
  defaultLanguage: string;
  selectedLanguage: string;
  existingLanguages: string[];
  onSelectedChange: (a: string) => void;
  disabled?: boolean;
};

const EXISTING_SUBTITLES_GROUP_HEADER = (
  <FormattedMessage
    id="subtitles.existing-tracks"
    description="Dropdown group header"
    defaultMessage="Existing subtitle tracks"
  />
);

const AVAILABLE_LANGUAGES_GROUP_HEADER = (
  <FormattedMessage
    id="subtitles.available-languages"
    description="Dropdown group header"
    defaultMessage="Available languages"
  />
);

function SubtitlesLanguagesDropdown({
  defaultLanguage,
  selectedLanguage,
  existingLanguages,
  onSelectedChange,
  disabled,
}: Props) {
  const existingSubtitlesGroup = {
    key: 'existing-subtitles',
    label: EXISTING_SUBTITLES_GROUP_HEADER,
    children: existingLanguages.map(value => ({ value, label: getDisplayMessageForLanguageCode(value) })),
  };

  const allowedLanguages = [defaultLanguage, ...getAllowedLanguageCodesByLanguageCode(defaultLanguage)];

  const isSelectedValueInDropdown =
    allowedLanguages.includes(selectedLanguage) || existingLanguages.includes(selectedLanguage);

  useEffect(() => {
    if (!isSelectedValueInDropdown) {
      onSelectedChange(defaultLanguage);
    }
  }, [onSelectedChange, defaultLanguage, isSelectedValueInDropdown]);

  if (!isSelectedValueInDropdown) {
    return null;
  }

  const availableLanguages = allowedLanguages
    .filter(languageCode => !existingLanguages.includes(languageCode))
    .map(value => ({ value, label: getDisplayMessageForLanguageCode(value) }));

  const availableLanguagesGroup = {
    key: 'available-languages',
    label: AVAILABLE_LANGUAGES_GROUP_HEADER,
    children: availableLanguages,
  };

  const dropdownGroups = [existingSubtitlesGroup, availableLanguagesGroup];

  return (
    <SDSDropdown
      disableClear
      defaultValue={selectedLanguage}
      onChange={onSelectedChange}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      showArrow
      disabled={disabled}
      type={DropdownType.GREY}
      size={DropdownSize.MEDIUM}
      data-test="SubtitlesLanguageDropdown"
    >
      {createSDSDropdownOptionGroups(dropdownGroups)}
    </SDSDropdown>
  );
}

export default memo(SubtitlesLanguagesDropdown);
