import _ from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { getMessageFromId, getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as numberFormatter from 'utils/numberFormatter';

import AnalyticsBarChart from 'views/analytics/components/AnalyticsBarChart/AnalyticsBarChart';
import AnalyticsTooltip from 'views/analytics/components/AnalyticsTooltip/AnalyticsTooltip';
import { GRAPH_SHADES_OF_BLUE, GRAPH_SHADES_OF_GREEN } from 'views/analytics/utils/chartConfigs';
import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';

import type { StorySnapMetrics } from 'types/analytics';

type Props = {
  isLoading: boolean;
  metrics: StorySnapMetrics[];
  isOnPromotionTab: boolean;
  useAnalyticsV2?: boolean;
};

export const DEFAULT_COLOR = GRAPH_SHADES_OF_BLUE.NORMAL_BLUE;
export const SEGMENT_COLORS = [
  GRAPH_SHADES_OF_BLUE.EXTRA_STRONG_BLUE,
  GRAPH_SHADES_OF_BLUE.EXTRA_LIGHT_BLUE,
  GRAPH_SHADES_OF_BLUE.STRONG_BLUE,
  GRAPH_SHADES_OF_BLUE.NORMAL_BLUE,
  GRAPH_SHADES_OF_BLUE.MED_BLUE,
];
export const DEFAULT_SUBSCRIBERS_COLOR = GRAPH_SHADES_OF_GREEN.NORMAL_GREEN;
export const SEGMENT_SUBSCRIBERS_COLORS = [
  GRAPH_SHADES_OF_GREEN.EXTRA_STRONG_GREEN,
  GRAPH_SHADES_OF_GREEN.EXTRA_LIGHT_GREEN,
  GRAPH_SHADES_OF_GREEN.STRONG_GREEN,
  GRAPH_SHADES_OF_GREEN.NORMAL_GREEN,
  GRAPH_SHADES_OF_GREEN.MED_GREEN,
];
export const DEFAULT_COLOR_INDEX = 3; // NORMAL

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="unique-viewers-organic"
      defaultMessage="Unique Viewers Organic"
      description="Number of organic unique views the snap has received"
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="unique-viewers-paid"
      defaultMessage="Unique Viewers Paid"
      description="Number of paid unique viewers the snap has received"
    />
  ),
  params: [],
});

export class AnalyticsUniqueViewersGraph extends React.PureComponent<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  getColorForIndex = (index: number, colors: string[] = SEGMENT_COLORS) => {
    return colors[index % colors.length];
  };

  barColorFunc = (barCharData: any, key: string) => {
    const colors = key === 'uniqueSubscriberViewers' ? SEGMENT_SUBSCRIBERS_COLORS : SEGMENT_COLORS;
    return this.getColorForIndex(barCharData.colorIndex, colors);
  };

  getMetrics = () => {
    if (this.props.isOnPromotionTab) {
      return this.props.metrics.map(metric => ({
        paidUniques: metric.paidUniques,
        organicUniques: metric.uniques - (metric.paidUniques || 0),
        ...metric,
      }));
    }

    let previousSegmentId: any;
    let colorIndex = -1;

    return _.map(this.props.metrics, (snapMetrics: StorySnapMetrics) => {
      const { segmentId } = snapMetrics;

      if (segmentId !== null) {
        if (previousSegmentId !== segmentId) {
          // we found a new segment, change color
          colorIndex += 1;
        }

        // update tracking id
        previousSegmentId = segmentId;
      }

      return {
        ...snapMetrics,
        colorIndex: colorIndex === -1 ? DEFAULT_COLOR_INDEX : colorIndex,
      };
    });
  };

  getUniquesConfig = () => {
    if (this.props.isOnPromotionTab) {
      return [
        {
          key: 'organicUniques',
          fill: DEFAULT_COLOR,
          name: getLocalisedMessageFromId(this.context, 'unique-viewers-organic'),
          stackId: 'x',
        },
        {
          key: 'paidUniques',
          fill: DEFAULT_SUBSCRIBERS_COLOR,
          name: getLocalisedMessageFromId(this.context, 'unique-viewers-paid'),
          stackId: 'x',
        },
      ];
    }

    return [
      {
        key: 'uniqueSubscriberViewers',
        fill: DEFAULT_SUBSCRIBERS_COLOR,
        name: getLocalisedMessageFromId(this.context, 'publisher-story-chart-unique-follower-viewers'),
        stackId: 'x',
      },
      {
        key: 'uniqueNonSubscriberViewers',
        fill: DEFAULT_COLOR,
        name: getLocalisedMessageFromId(this.context, 'publisher-story-chart-unique-non-follower-viewers'),
        stackId: 'x',
      },
    ];
  };

  renderChartTooltip = (tooltipData: any) => {
    const payload = _.get(tooltipData, 'payload[0].payload');
    if (_.isEmpty(payload)) {
      return null;
    }

    const {
      name,
      uniques,
      uniqueSubscriberViewers,
      uniqueNonSubscriberViewers,
      snapId,
      mediaId,
      mediaType,
      organicUniques,
      paidUniques,
      mediaUrl,
    } = payload;
    let metricRows;

    const totalUniqueViewsMetricRow = {
      metricName: getLocalisedMessageFromId(this.context, 'publisher-story-chart-unique-viewers'),
      metricValue: numberFormatter.prettyPrintNumber(uniques || organicUniques + paidUniques),
    };

    if (this.props.isOnPromotionTab) {
      metricRows = [
        {
          metricName: getLocalisedMessageFromId(this.context, 'unique-viewers-organic'),
          metricValue: numberFormatter.prettyPrintNumber(organicUniques),
        },
        {
          metricName: getLocalisedMessageFromId(this.context, 'unique-viewers-paid'),
          metricValue: numberFormatter.prettyPrintNumber(paidUniques),
        },
        totalUniqueViewsMetricRow,
      ];
    } else {
      metricRows = [
        totalUniqueViewsMetricRow,
        {
          metricName: getLocalisedMessageFromId(this.context, 'publisher-story-chart-unique-follower-viewers'),
          metricValue: numberFormatter.prettyPrintNumber(uniqueSubscriberViewers),
        },
        {
          metricName: getLocalisedMessageFromId(this.context, 'publisher-story-chart-unique-non-follower-viewers'),
          metricValue: numberFormatter.prettyPrintNumber(uniqueNonSubscriberViewers),
        },
      ];
    }
    return (
      <AnalyticsTooltip
        title={name}
        snapId={snapId}
        mediaId={mediaId}
        mediaType={mediaType}
        metricRows={metricRows}
        generatedPreviewUrl={mediaUrl}
      />
    );
  };

  render() {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <AnalyticsBarChart
        barChartData={this.getMetrics()}
        barChartTitle={getMessageFromId('publisher-story-chart-unique-viewers')}
        bars={this.getUniquesConfig()}
        graphTooltip={getMessageFromId('publisher-story-chart-unique-viewers-explanation')}
        graphHelpCenterLink={HelpCenterDestination.ANALYTICS_OPTIMIZING_FOR_UNIQUE_VIEWERS}
        isLoading={this.props.isLoading}
        tooltipRenderer={this.renderChartTooltip}
        yAxisTickFormatter={numberFormatter.prettyPrintNumber}
        isStackedInitiallySelected
        barColorFunc={this.barColorFunc}
      />
    );
  }
}

export default AnalyticsUniqueViewersGraph;
