import React from 'react';

import { BadgeOptionsType } from 'views/badges/utils/badgesConfig';
import Icon from 'views/common/components/Icon/Icon';

import style from './Badge.scss';

export enum BadgeType {
  PRIMARY = 'primary', // blue on a white background
  WHITE_ON_GREY = 'grey', // white on a grey background
}

export default function Badge({ name, icon, 'data-test': dataTest, badgeType = BadgeType.PRIMARY }: BadgeOptionsType) {
  const badgeClass = getAdditionalClassName(badgeType);
  const badgeIconClass = getIconClassName(badgeType);
  return (
    <div style={{ fontSize: 10 }} data-test={`${dataTest}.badge`} className={badgeClass}>
      {icon && <Icon inlineIcon={icon} className={badgeIconClass} />}
      {name}
    </div>
  );
}

function getAdditionalClassName(badgeType: BadgeType) {
  switch (badgeType) {
    case BadgeType.WHITE_ON_GREY:
      return style.whiteOnGrey;
    case BadgeType.PRIMARY:
      return style.badge;
    default:
      return style.badge;
  }
}

function getIconClassName(badgeType: BadgeType) {
  switch (badgeType) {
    case BadgeType.WHITE_ON_GREY:
      return style.badgeIconGrey;
    case BadgeType.PRIMARY:
      return style.badgeIcon;
    default:
      return style.badgeIcon;
  }
}
